<template>
        <svg :width="width" :height="height" viewBox="0 0 162 163" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" d="M22.0923 22.5923L44.1846 0.5L62.9292 7.09158C68.7233 5.68943 74.7747 4.94643 80.9997 4.94643C123.279 4.94643 157.553 39.2206 157.553 81.4998C157.553 87.7248 156.81 93.7762 155.408 99.5703L162 118.316L139.907 140.408L117.815 162.5L99.0696 155.908C93.2757 157.31 87.2245 158.053 80.9997 158.053C38.7204 158.053 4.44626 123.779 4.44626 81.4998C4.44626 75.2747 5.18929 69.2232 6.5915 63.429L0 44.6846L22.0923 22.5923ZM80.9997 142.292C114.574 142.292 141.792 115.075 141.792 81.4998C141.792 47.9251 114.574 20.7074 80.9997 20.7074C47.4249 20.7074 20.2073 47.9251 20.2073 81.4998C20.2073 115.075 47.4249 142.292 80.9997 142.292Z" fill="#CCD2EA"/>
            <mask  :id="this.$props.type" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="100%" height="100%">
                <rect transform="scale(1 -1) translate(0 -163)" y="0" width="100%" :height="this.$props.percent + '%'" fill="#D9D9D9"/>
            </mask>
            <g :mask="'url(#' + this.$props.type + ')'">
                <path opacity="0.9" fill-rule="evenodd" clip-rule="evenodd" d="M44.1844 0.5L62.9301 7.09194C68.7239 5.68989 74.7751 4.94695 80.9999 4.94695C123.279 4.94695 157.553 39.2211 157.553 81.5004C157.553 87.7252 156.81 93.7765 155.408 99.5705L162 118.316L117.815 162.5L99.0707 155.909C93.2765 157.311 87.225 158.054 80.9999 158.054C38.7206 158.054 4.44652 123.78 4.44652 81.5004C4.44652 75.2754 5.18952 69.2239 6.59167 63.4298L-0.00012207 44.6846L44.1844 0.5ZM80.9996 142.293C114.574 142.293 141.792 115.075 141.792 81.5003C141.792 47.9256 114.574 20.7079 80.9996 20.7079C47.4249 20.7079 20.2073 47.9256 20.2073 81.5003C20.2073 115.075 47.4249 142.293 80.9996 142.293Z" :fill="this.$props.iconColor"/>
            </g>
            <path v-if="type === 'car'" d="M73.1034 50.2078C66.9156 51.7696 61.3298 55.1313 57.0526 59.8677C52.7753 64.604 49.9985 70.5024 49.0735 76.8169C48.1484 83.1314 49.1166 89.5784 51.8556 95.3427C54.5946 101.107 58.9814 105.929 64.4612 109.201C69.941 112.472 76.2678 114.044 82.6414 113.72C89.0151 113.395 95.1493 111.188 100.268 107.377C105.388 103.566 109.262 98.3226 111.401 92.3099C113.54 86.2972 113.848 79.7852 112.286 73.5974C110.183 65.305 104.877 58.1854 97.5313 53.8006C90.1856 49.4157 81.4005 48.1236 73.1034 50.2078ZM74.8306 57.0508C80.7005 55.5561 86.9128 56.2329 92.323 58.9566C97.7333 61.6804 101.977 66.2675 104.272 71.8729L84.2094 74.5988C83.4918 73.9959 82.6412 73.5721 81.7277 73.3623C80.8142 73.1525 79.864 73.1627 78.9552 73.3921C78.0464 73.6215 77.2051 74.0635 76.5006 74.6816C75.7961 75.2998 75.2485 76.0765 74.9029 76.9478L55.9497 84.0696C55.3099 78.0463 56.8683 71.9947 60.3379 67.0297C63.8075 62.0647 68.9545 58.5208 74.8306 57.0508ZM82.0886 85.8065C81.2357 86.0215 80.3381 85.9788 79.5095 85.6837C78.6808 85.3886 77.9583 84.8545 77.4332 84.1488C76.9081 83.4431 76.6041 82.5976 76.5595 81.7191C76.5149 80.8406 76.7318 79.9686 77.1828 79.2134C77.6338 78.4582 78.2986 77.8537 79.0931 77.4762C79.8876 77.0988 80.7762 76.9655 81.6465 77.093C82.5168 77.2206 83.3298 77.6034 83.9826 78.1929C84.6354 78.7825 85.0987 79.5524 85.314 80.4052C85.6011 81.5491 85.4228 82.7602 84.8182 83.7728C84.2135 84.7854 83.2319 85.5168 82.0886 85.8065ZM57.7218 91.1022L75.5212 88.4526C75.9979 88.8535 76.5362 89.1748 77.1152 89.4042L83.5778 106.553C78.1808 107.091 72.7538 105.88 68.098 103.098C63.4423 100.316 59.8045 96.1101 57.7218 91.1022ZM90.6216 104.768L88.1718 86.6062C88.5727 86.1295 88.894 85.5912 89.1234 85.0121L106.048 78.8973C106.592 84.2937 105.385 89.7218 102.607 94.3802C99.8297 99.0386 95.6276 102.68 90.6216 104.768Z" :fill="this.$props.iconColor"/>
            <path v-if="type === 'gun'" d="M79.9229 79.0413C81.1946 80.3298 81.4558 82.3851 80.4902 84.0575L80.2401 84.4907C79.0897 86.4832 76.5969 87.2415 74.7152 86.1551L71.546 84.3253C69.5776 83.1889 68.9886 80.6542 70.1382 78.6584L70.3883 78.2252C71.5375 76.2348 74.0303 75.4765 75.9141 76.5642L77.1135 77.2566C77.9707 80.9818 73.6259 83.1005 73.6259 83.1005C73.4271 83.445 74.0254 83.7904 74.6534 83.6937C74.6534 83.6937 78.7074 83.1403 79.9229 79.0413ZM56.4595 46.5744C56.281 46.4713 56.091 46.3895 55.8935 46.3304C54.4965 45.9127 53.0285 46.7033 52.6147 48.0962L50.6532 54.6986C50.0172 56.8395 50.9382 59.1411 52.8795 60.2619L60.9379 64.9144C64.8951 67.1991 66.7486 71.909 65.4018 76.2577C64.0551 80.6063 65.9085 85.3162 69.8657 87.6009L80.0653 93.4897L77.224 106.316C76.8163 107.813 77.5148 109.372 78.8003 110.114L90.1469 116.549C90.1469 116.549 92.252 117.649 94.045 115.335C95.0165 114.047 95.4625 109.916 95.4625 109.916L98.4491 94.6599C98.7734 93.1118 100.259 92.1211 101.742 92.5155C104.488 93.2921 106.478 96.1736 108.421 93.5992C110.127 91.2345 104.914 87.4165 104.258 86.5756C103.895 86.0191 105.693 82.5078 106.259 81.3327C107.263 81.566 108.528 81.9494 109.126 82.2948C110.327 82.9881 112.224 82.4662 111.211 80.2646C110.199 78.063 105.254 78.327 105.254 78.327L105.377 77.1274C105.427 75.6545 104.579 74.356 103.382 73.6651L56.4595 46.5744Z" :fill="this.$props.iconColor"/>
        </svg>
</template>
<script>
export default {
    name: 'ProfileMasteryIcon',
    props: {
        type: String,
        iconColor: String,
        iconBackground: String,
        width: String,
        height: String,
        percent: Number
    }
};
</script>
