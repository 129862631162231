<template>
    <div>
        <svg v-if="icon === 'bottom'" class="bottom" width="526" height="24" preserveAspectRatio="xMinYMax meet" viewBox="0 0 526 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M525.667 24V12.3077H440.361L421.822 0L407.918 12.3077L216.906 0L209.954 12.3076L184.794 0L157.649 12.3076L0.333496 5.54968V23.9999L525.667 24Z" :fill="fill"/>
        </svg>
        <svg v-if="icon === 'left'" class="left" width="24" height="176" viewBox="0 0 24 176" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 176H20.2353L24 125.837L11.6924 119.869L24 98.2687L11.6924 74.9632L15.4432 0H0V176Z" :fill="fill"/>
        </svg>
    </div>
</template>

<script>
export default {
    props: {
        fill: { type: String, default: '#B784C9' },
        icon: String,
        className: String
    }
};
</script>

<style lang="scss" scoped>
.bottom{
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.left{
    height: 100%;
    width: auto;
    position: absolute;
    bottom: 0;
    left: 0;
}
</style>
