<template>
    <div class="icon-social">
        <svg v-if="social === 'google'" width="146" height="146" viewBox="0 0 146 146" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="146" height="146" rx="4" fill="#F1F1F1"/>
            <g clip-path="url(#clip0_101_5071)">
                <path d="M72.6973 65.736V79.1074H91.1166C90.3078 83.4076 87.8806 87.0488 84.2404 89.4971L95.3479 98.1917C101.82 92.1654 105.553 83.3138 105.553 72.7987C105.553 70.3504 105.336 67.9961 104.931 65.7363L72.6973 65.736Z" fill="#4285F4"/>
                <path d="M53.5163 78.5854L51.0111 80.5201L42.1436 87.4882C47.7751 98.7565 59.3175 106.541 72.6964 106.541C81.937 106.541 89.6842 103.465 95.3471 98.1917L84.2395 89.4971C81.1903 91.5687 77.301 92.8244 72.6964 92.8244C63.7979 92.8244 56.2374 86.7664 53.5303 78.6054L53.5163 78.5854Z" fill="#34A853"/>
                <path d="M42.1438 56.5392C39.8104 61.1845 38.4727 66.4264 38.4727 72.0134C38.4727 77.6005 39.8104 82.8424 42.1438 87.4877C42.1438 87.5189 53.5317 78.5734 53.5317 78.5734C52.8472 76.5018 52.4426 74.3047 52.4426 72.0131C52.4426 69.7214 52.8472 67.5244 53.5317 65.4528L42.1438 56.5392Z" fill="#FBBC05"/>
                <path d="M72.6971 51.2346C77.7376 51.2346 82.2179 52.9923 85.796 56.3822L95.5968 46.495C89.654 40.908 81.938 37.4865 72.6971 37.4865C59.3182 37.4865 47.7751 45.2394 42.1436 56.5392L53.5311 65.4535C56.2378 57.2925 63.7986 51.2346 72.6971 51.2346Z" fill="#EA4335"/>
            </g>
            <defs>
                <clipPath id="clip0_101_5071">
                    <rect width="67.0811" height="69.0541" fill="white" transform="translate(38.4727 37.4865)"/>
                </clipPath>
            </defs>
        </svg>

        <svg v-if="social === 'apple'" width="146" height="146" viewBox="0 0 146 146" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="146" height="146" rx="4" fill="#F1F1F1"/>
            <path d="M106.182 53.6407C105.662 54.0489 96.48 59.2826 96.48 70.9202C96.48 84.381 108.164 89.143 108.514 89.2609C108.46 89.5512 106.658 95.7827 102.353 102.132C98.5155 107.72 94.5073 113.298 88.4098 113.298C82.3122 113.298 80.743 109.715 73.7039 109.715C66.8442 109.715 64.4052 113.416 58.8278 113.416C53.2503 113.416 49.3587 108.246 44.8841 101.896C39.7012 94.4403 35.5137 82.8571 35.5137 71.8635C35.5137 54.2303 46.8479 44.8785 58.0028 44.8785C63.93 44.8785 68.8708 48.8151 72.592 48.8151C76.134 48.8151 81.6576 44.6426 88.4008 44.6426C90.9564 44.6426 100.139 44.8785 106.182 53.6407ZM85.1996 37.1775C87.9883 33.8305 89.961 29.1863 89.961 24.5422C89.961 23.8982 89.9072 23.2451 89.7907 22.719C85.2534 22.8913 79.8553 25.7758 76.6003 29.5945C74.0447 32.5334 71.6595 37.1775 71.6595 41.8852C71.6595 42.5927 71.7761 43.3002 71.8298 43.527C72.1168 43.5814 72.5831 43.6449 73.0494 43.6449C77.1204 43.6449 82.2405 40.8874 85.1996 37.1775Z" fill="#222222"/>
        </svg>

        <svg v-if="social === 'discord'" width="146" height="146" viewBox="0 0 146 146" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="146" height="146" rx="4" fill="#F1F1F1"/>
            <path d="M103.817 45.8986C98.0696 43.3513 91.9242 41.5 85.4998 40.4459C84.7109 41.7973 83.7892 43.6149 83.1535 45.0608C76.324 44.0878 69.5575 44.0878 62.8535 45.0608C62.2183 43.6149 61.2752 41.7973 60.4794 40.4459C54.0478 41.5 47.8958 43.3581 42.1487 45.9122C30.5566 62.5066 27.4142 78.6892 28.9854 94.6419C36.6738 100.081 44.1249 103.385 51.4501 105.547C53.2588 103.189 54.8719 100.683 56.2617 98.0406C53.6148 97.0879 51.08 95.9123 48.6848 94.5471C49.3205 94.1015 49.9419 93.6352 50.5424 93.1554C65.1514 99.6284 81.0235 99.6284 95.4582 93.1554C96.0655 93.6352 96.687 94.1015 97.3154 94.5471C94.9134 95.9188 92.3713 97.0945 89.7248 98.0475C91.1142 100.683 92.7204 103.196 94.536 105.554C101.868 103.392 109.326 100.088 117.015 94.6419C118.858 76.1488 113.865 60.1147 103.817 45.8986ZM58.2517 84.8313C53.8662 84.8313 50.27 80.9525 50.27 76.2297C50.27 71.507 53.7895 67.6216 58.2517 67.6216C62.7139 67.6216 66.31 71.5 66.2334 76.2297C66.2406 80.9525 62.7139 84.8313 58.2517 84.8313ZM87.7485 84.8313C83.363 84.8313 79.7668 80.9525 79.7668 76.2297C79.7668 71.507 83.2863 67.6216 87.7485 67.6216C92.2107 67.6216 95.8072 71.5 95.7302 76.2297C95.7302 80.9525 92.2107 84.8313 87.7485 84.8313Z" fill="#5865F2"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: {
        social: String
    }
};
</script>
