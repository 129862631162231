<template>
    <div class="shop">
        <AppHeader back-button  @getBackHeader="handleBackHeader">
            <template #title>{{ title }}</template>
        </AppHeader>

        <div class="shop__wrapper">

            <div class="shop__information">
                <div class="shop__information-item" v-if="selectedItem">
                    <div class="shop__information-top">
                        <div class="shop__information-title">{{ $t(selectedItem.name) }}</div>
                        <div class="shop__information-labels">
                            <div class="shop__information-put-on" v-if="selectedItem.isEquipped">
                                <img src="../../assets/images/checkmark.svg" alt="">
                            </div>
                            <div class="shop__information-label" :class="selectedItem.rarity">{{ rarityMap[selectedItem.rarity] }}</div>
                        </div>
                        <div class="shop__information-desc">
                            {{ $t(selectedItem.desc) }}
                        </div>
                    </div>

                    <div class="shop__information-btns">
                        <BaseButton class="shop__order-button" yellow @click = "buyItem(selectedItem)">
                            {{ $t('shop.buy') }}
                            <div class="shop__order-price">
                                <span v-if="!selectedItem.isDonate" class="shop__order-price-dollar">$</span>
                                <IconDonate v-else class="shop__order-price-donate"/>
                                {{ GetFullPrice(selectedItem.price) }}
                            </div>
                        </BaseButton>
                        <base-button @click = "tryItem(selectedItem)" white class="white">
                            <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M40.1794 18.4936C35.0374 11.0236 27.2301 6.66016 20.4999 6.66016C13.7697 6.66016 5.96252 11.0237 0.819629 18.4936C0.653771 18.7345 0.5 19.0553 0.5 19.3762C0.5 19.6974 0.653771 20.0176 0.819629 20.2588C5.9626 27.7288 13.7697 32.0922 20.4999 32.0922C27.2301 32.0922 35.0373 27.7287 40.1794 20.2588C40.3462 20.0176 40.5 19.6974 40.5 19.3762C40.5 19.0553 40.3462 18.7345 40.1794 18.4936ZM20.4999 28.5603C15.428 28.5603 11.3157 24.4482 11.3157 19.3761C11.3157 14.304 15.4278 10.1919 20.4999 10.1919C25.572 10.1919 29.6841 14.304 29.6841 19.3761C29.6841 24.4482 25.572 28.5603 20.4999 28.5603Z" fill="#141A1E"/>
                            <path d="M23.9881 18.3969C22.6036 18.3969 21.48 17.2737 21.48 15.8888C21.48 14.9163 22.0343 14.0749 22.8425 13.6585C22.1196 13.3615 21.3293 13.1953 20.4994 13.1953C17.0857 13.1953 14.3179 15.9632 14.3179 19.3769C14.3179 22.7902 17.0857 25.5584 20.4997 25.5584C23.914 25.5584 26.6813 22.7909 26.6813 19.3769C26.6813 18.5466 26.5151 17.7564 26.2181 17.0334C25.8024 17.8423 24.961 18.3969 23.9881 18.3969Z" fill="#141A1E"/>
                            </svg>
                            {{ $t('shop.try_cloth') }}
                        </base-button>
                    </div>
                </div>

                <div v-else class="shop__information-empty">
                    <img src="../../assets/images/enter-finger.svg" alt="">
                    <p>{{ $t('shop.info') }}</p>
                </div>
            </div>

            <div class="shop__cards">
                <div class="shop__cards-wrapper">
                    <InventoryItem
                        v-for="(item, index) in filteredItems"
                        :key="index || 'empty' + index"
                        :item="item"
                        class="shop__item"
                        :selectedItem="selectedItem"
                        @select-item="selectItem"
                    />
                </div>
            </div>

            <BaseTabMenu :items="tabDefinitions" :activeTab="selectedType" isScrollable :handleTabChange="changeTab" />
        </div>

    </div>
</template>

<script>
import AppHeader from '@/components/AppHeader/AppHeader.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import InventoryItem from '@/components/popups/components/inventoryItem/InventoryItem.vue';
import { mapActions, mapGetters } from 'vuex';
import BaseTabMenu from '@/components/ui/BaseTabMenu.vue';
import { shopItems } from '@/components/popups/content/shop/data/shopItems.js';
import IconDonate from '@/components/icons/IconDonate.vue';

export default {
    name: 'ShopClother',
    components: { BaseTabMenu, InventoryItem, BaseButton, AppHeader, IconDonate },
    data () {
        return {
            countSlot: 30,
            selectedType: 'all',
            selectedItem: null,
            title: this.$t('shop.shop_clothes'),
            discount: 0,
            isMale: true,

            rarityMap: {
                legend: this.$t('shop.rarity_map.legend'),
                ordinary: this.$t('shop.rarity_map.ordinary'),
                unusual: this.$t('shop.rarity_map.unusual'),
                rare: this.$t('shop.rarity_map.rare'),
                epic: this.$t('shop.rarity_map.epic')
            },

            // нейминг иконок = наименование файла, если нет, то нужно пополнять BaseIcon -> задаче к Диме
            tabDefinitions: [
                { type: 'all', baseIcon: 'clothes-1' },
                { type: 'hat', baseIcon: 'filter-hat' },
                { type: 'sunglasses', baseIcon: 'filter-sunglasses' },
                { type: 'masks', baseIcon: 'filter-mask' },
                { type: 'top', baseIcon: 'filter-outerwear' },
                { type: 'gloves', baseIcon: 'filter-gloves' },
                { type: 'jeans', baseIcon: 'filter-short' },
                { type: 'accessories', baseIcon: 'filter-pow' },
                { type: 'boots', baseIcon: 'filter-shoes-2' }
            ],

            shopItems

            // isEquipped: true,
        };
    },
    methods: {
        ...mapActions(['updateInventoryItemsPool']),
        handleBackHeader () {
            this.sendclient('shopMain');
        },
        changeTab (tabType) {
            this.selectedType = tabType;
            this.selectedItem = null; // reset selected item
        },
        selectItem (item) {
            this.selectedItem = item;
        },
        buyItem (item) {
            this.sendclient('BuyDonateClothes', JSON.stringify({ a: item.index }));
        },
        tryItem (item) {
            this.sendclient('TryDonateClothes', JSON.stringify({ a: item.index }));
        },
        /*
        window.modifyVueComponent({
            App: {
                showPopup: true,
                popupContent: 'MyShop',
                contentSubFolder: 'shop'
            },
            MyShop: {
                InitializeShop: [
                    [
                        {"count":89,"additional":0,"itemID":2,"price":100},{"count":9,"additional":0,"itemID":3,"price":100},
                        ],
                    [],
                ]
            }
        });
        */
        InitializeShop (itemList, title, discount, type) {
            this.title = title;
            this.discount = discount;
            this.type = type;
            this.shopItems = new Array(this.countSlot);// [];
            for (let i = 0; i < itemList.length; i++) {
                if (itemList[i].itemID) {
                    this.shopItems[i] = Object.assign({}, this.inventoryItemsPool[itemList[i].itemID - 1]);
                    this.shopItems[i].count = itemList[i].count;
                    this.shopItems[i].index = i + 1;
                    this.shopItems[i].additional = itemList[i].additional;
                    this.shopItems[i].price = itemList[i].price;
                    // if (activeSlots && activeSlots.indexOf(i + 1) !== -1) this.shopItems[i].isEquipped = true;
                } else this.shopItems[i] = { id: null, name: '', rarity: '', filterType: '', desc: '', img: '', count: 0, index: i + 1 };
            }
        },
        GetFullPrice (price) {
            const correctPriceToMinus = Math.floor(this.discount / 100 * price);
            return this.$formatNum(price - correctPriceToMinus);
        },
        Rerender () {
            const lastType = this.selectedType;
            this.selectedType = '';
            this.selectedType = lastType;
        }
    },
    computed: {
        ...mapGetters('inventory', ['inventoryItemsPool']),
        filteredItems () {
            let toFilter = {};

            if (this.selectedType === 'all') {
                toFilter = this.shopItems;
            } else {
                toFilter = this.shopItems.filter(item => item.shopType === this.selectedType);
            }

            if (this.isMale) {
                return toFilter.filter(item => item.gender === 0 || item.gender === 2);
            } else {
                return toFilter.filter(item => item.gender === 1 || item.gender === 2);
            }
        }
    }

};
</script>

<style lang="scss">
.shop__information-btns{
    .white{
        max-width: 313px;
        margin: 0 auto;
        height: 112px;

        svg{
            width: 40px;
            height: 40px;
            flex-shrink: 0;
            margin-right: 18px;
            path{
                fill: #141A1E;
            }
        }
    }
}
</style>

<style lang="scss">
.shop{
    display: flex;
    height: 100%;
    flex-direction: column;
    background: url(../../assets/images/shop.svg) no-repeat left/1300px, radial-gradient(138.02% 65.96% at 50.00% -0.00%, #FFC683 0%, #C30542 100%);

    //&__item{
    //    margin: 0 12px 24px;
    //}
    &__wrapper{
        padding: 140px 128px;
        display: grid;
        grid-template-columns: 580px 1fr 298px;
        grid-gap: 80px;
        height: 100%;
    }

    &__order{
        &-button {
            height: 4.45vw;
            padding: 0 1.75vw;
            font-size: 1.45vw;
            white-space: nowrap;
        }
        &-price {
            margin-left: .725vw;
            background-color: var(--dark);
            color: var(--white);
            padding: .25vw .5vw;
            border-radius: .25vw;
            font-weight: 700;
            font-size: 1.6vw;
            &-dollar {
                display: inline-block;
                color: var(--gold);
                margin-right: .16vw;
            }
        }
    }

    &__cards{
        overflow-y: auto;
        margin: -5vw 0;
        padding: 5vw 0.2vw;

        &::-webkit-scrollbar{
            width: 0;
            height: 0;
            display: none;
        }

        &-count{
            padding: 0 .7vw ;
            height: 1.7vw;
            background: var(--white);
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            clip-path: polygon(0 0, 100% 0, 91% 100%, 0% 100%);
            font-size: 1.3vw;
            line-height: 100%;
            letter-spacing: -0.03em;
            font-weight: 700;
            color: #141A1E;

        }

        &-put-on{
            padding: 13px;
            width: 1.7vw;
            height: 1.7vw;
            background: #27AE60;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 0;
            top: 0;
            clip-path: polygon(19% 0, 100% 0, 100% 100%, 0% 100%);

            img{
                height: 70% !important;
            }
        }

        &-wrapper{
            display: flex;
            flex-wrap: wrap;
        }

        &-update{
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-gap: 1vw;
            grid-column: 1/-1;
            position: relative;

            .btn{
                svg{
                    height: 30%;
                    width: auto;
                    margin-left: .5vw;
                }
            }

            .shop__cards-item--empty{
                opacity: .3;
            }
        }

        &-shop{
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 3vw;

            h5{
                font-weight: 700;
                font-size: 1.3vw;
                line-height: 140%;

                letter-spacing: 0.05em;
                text-transform: uppercase;

                color: var(--white);
            }
            p{
                font-size: 1.3vw;
                line-height: 140%;
                display: flex;
                align-items: flex-end;

                color: var(--white);

                opacity: 0.6;
            }
        }

        &-item{
            position: relative;
            width: 220px;
            height: 280px;
            display: flex;
            border-radius: .2vw;
            padding: .5vw;
            align-items: flex-end;
            overflow: hidden;
            flex-shrink: 0;

            &--empty{
                background: url(../../assets/images/empty.svg) no-repeat center/contain;
            }

            &--legend{
                background: url(../../assets/images/legend.svg) no-repeat center/cover;
            }
            &--epic{
                background: url(../../assets/images/epic.svg) no-repeat center/cover;
            }
            &--rare{
                background: url(../../assets/images/rare.svg) no-repeat center/cover;
            }
            &--ordinary{
                background: url(../../assets/images/ordinary.svg) no-repeat center/cover;
            }
            &--unusual{
                background: url(../../assets/images/unusual.svg) no-repeat center/cover;
            }

            &--active{
                box-shadow: 0 0 0 .2vw var(--white);
                &::before{
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    content: "";
                    display: block;
                    width: 100%;
                    height: 100%;
                    background: url(../../assets/images/active.svg) no-repeat center/cover;
                }

            }

            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
                position: absolute;
            }

            p{
                font-weight: 700;
                font-size: 1vw;
                line-height: 100%;
                position: relative;
                z-index: 3;
            }

        }
    }

    &__information{
        height: 100%;

        &-btns{
            width: 100%;
            display: grid;
            grid-gap: 32px;
        }

        &-top{
            text-align: center;
        }

        &-item{
            padding-top: 1vw;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        &-put-on{
            padding: 13px;
            width: 1.7vw;
            height: 1.7vw;
            background: #27AE60;
            margin-right: .5vw;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-labels{
            display: flex;
        }

        &-label{
            font-weight: 700;
            font-size: .9vw;
            padding: 0.4vw 0.7vw;
            line-height: 100%;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: var(--white) ;
            width: max-content;
            margin: 0 auto;

            &.legend{
                background: linear-gradient(211.69deg, #FFCD4C 33.85%, #FFA800 100%);
            }
            &.epic{
                background: linear-gradient(211.69deg, #BE6AFF 20.31%, #7A28FF 100%);
            }
            &.rare{
                background: linear-gradient(211.69deg, #48FDC7 0%, #009D6E 100%);
            }
            &.ordinary{
                background: linear-gradient(212deg, #96ABB5 43.23%, #72848B 100%);
            }
            &.unusual{
                background: linear-gradient(211.69deg, #5FCFFF 35.94%, #2780A6 100%);
            }
        }

        &-title{
            font-weight: 400;
            font-size: 3vw;
            line-height: 100%;
            margin-bottom: 1vw;

            justify-content: center;

            display: flex;
            align-items: flex-end;

            color: #FFFFFF;
        }
        &-desc{
            margin-top: 1vw;
            font-weight: 500;
            font-size: 1vw;
            line-height: 140%;
            color: var(--white);

            opacity: 0.6;
        }
        &-empty{
            display: flex;
            height: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            img{
                height: 8vw;
                margin-bottom: 1vw;
            }
            p{
                font-weight: 500;
                font-size: 1.5vw;
                line-height: 140%;
                display: flex;
                align-items: flex-end;
                text-align: center;

                color: var(--white);

                opacity: 0.8;

            }
        }
    }

    &__buttons{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: .5vw;
        &-item{
            background: linear-gradient(119.36deg, rgba(241, 241, 241, 0.19) 0%, rgba(241, 241, 241, 0) 100%);
            border-radius: .1vw;
            height: 6.5vw;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            font-weight: 700;
            font-size: 1vw;
            line-height: 120%;

            text-align: center;
            letter-spacing: 0.04em;
            text-transform: uppercase;

            color: #F1F1F1;

            &--use{
                background: #FFED4C;
                color: #141A1E;
                box-shadow: inset 0px -26px 80px rgba(241, 241, 241, 0.4);
            }
            &--clothes{
                background: #F1F1F1;
                color: #141A1E;
                box-shadow: inset 0px -26px 80px rgba(241, 241, 241, 0.4);
            }

            svg{
                height: 2vw;
                width: auto;
                margin-bottom: .5vw;
            }
        }
    }
}
</style>
