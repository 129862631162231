<template>
    <div class="gas">
        <AppHeader back-button>
            <template #title>
                {{ stationName }}
            </template>
        </AppHeader>
        <div class="gas-content">
            <div class="gas-info">
                <div class="gas-info__cards">
                    <div class="gas-info__card">
                        <div class="gas-info__card-title">{{ $t('gas_station.inside_tank') }}</div>
                        <div class="gas-info__card-litre">
                            {{ fuelInside }}
                            {{ $t('gas_station.liter') }}
                        </div>
                        <IconGasTank
                            class="gas-info__card-fuel"
                            variant="gasStation"
                            :fuelPercent="fuelPercentage"
                        />
                    </div>
                    <div class="gas-info__card">
                        <div class="gas-info__card-title">
                            {{ $t('gas_station.not_enough') }}
                        </div>
                        <div class="gas-info__card-litre">
                            {{ tankCapacity - fuelInside }}
                            {{ $t('gas_station.liter') }}
                        </div>
                        <IconGasTank
                            class="gas-info__card-fuel"
                            variant="gasStation"
                            yellow
                            :fuelPercent="fuelPercentage"
                        />
                    </div>
                </div>
                <BaseButton
                    class="gas-info__button"
                    white
                    @click="refill"
                >
                    {{ $t('gas_station.fill_car') }}
                    <div class="gas-info__button-price"><span>$</span> {{ $formatNum(refillPrice)}}</div>
                </BaseButton>
            </div>
            <div class="gas-control">
                <div class="gas-control__item">
                    <div class="gas-control__item-title">
                        {{ $t('gas_station.buy_canister') }}
                        {{ canisterCapacity }}
                        {{ $t('gas_station.liter') }}
                    </div>
                    <BaseButton
                        class="gas-control__item-button"
                        white
                        @click="buyCanister"
                    >
                        {{ $t('gas_station.buy') }}
                        <div class="gas-control__item-button-price">
                            <span>$</span> {{ $formatNum(canisterPrice) }}
                        </div>
                    </BaseButton>
                    <img class="gas-control__item-image" src="@/assets/images/garage/canister.png" alt="" />
                </div>
                <div class="gas-control__item">
                    <div class="gas-control__item-title">{{ $t('gas_station.buy_repair_kit') }}</div>
                    <BaseButton
                        class="gas-control__item-button"
                        white
                        @click="buyRepairKit"
                    >
                        {{ $t('gas_station.buy') }}
                        <div class="gas-control__item-button-price">
                            <span>$</span> {{ $formatNum(repairPrice) }}
                        </div>
                    </BaseButton>
                    <img class="gas-control__item-image gas-control__item-image--repair-kit" src="@/assets/images/garage/repair-kit.png" alt="" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppHeader from '@/components/AppHeader/AppHeader.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import IconGasTank from '@/components/icons/IconGasTank.vue';

export default {
    name: 'GasStation',
    components: {
        IconGasTank,
        BaseButton,
        AppHeader
    },
    data () {
        return {
            stationName: 'VINEWOOD GAS STATION',
            refillPrice: 5000,
            tankCapacity: 60, // В литрах
            fuelInside: 14, // В литрах
            canisterPrice: 2000,
            canisterCapacity: 20, // В литрах
            repairPrice: 2000
        };
    },
    computed: {
        fuelPercentage () {
            return this.fuelInside / this.tankCapacity * 100;
        }
    },
    methods: {
        /*
        window.modifyVueComponent({
            App: {
                showPopup: true,
                popupContent: 'GasStation',
                contentSubFolder: 'garage'
            },
            GasStation:{
                initialize:[
                    500,80,20,500,600
                ]
            }
        });
        */
        initialize (refillPrice, tank, myFuel, canisterPrice, repairKitPrice) {
            this.refillPrice = refillPrice;
            this.tankCapacity = tank;
            this.fuelInside = myFuel;
            this.canisterPrice = canisterPrice;
            this.repairPrice = repairKitPrice;
        },
        fullRefill () {
            this.refillPrice = 0;
            this.fuelInside = this.tankCapacity;
        },
        refill () {
            this.sendclient('refillVeh');
            // console.log('Заправляем бак, цена:', this.refillPrice);
        },
        buyCanister () {
            this.sendclient('buyCanister');
            // console.log('Покупаем канистру, цена:', this.canisterPrice, 'размер канистры:', this.canisterCapacity);
        },
        buyRepairKit () {
            this.sendclient('buyRepairKit');
            // console.log('Покупаем ремкомплект, цена:', this.repairPrice);
        }
    }
};
</script>

<style lang="scss" scoped>
.gas {
    height: 100%;
    background: linear-gradient(180deg, rgba(20, 26, 30, 0.79) 0%, #141A1E 100%);
    &-content {
        height: calc(100% - 5vw);
        display: grid;
        grid-template-columns: 1328px 1fr;
        grid-gap: 0 128px;
    }
    &-info {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        border-radius: 0 300px 0 0;
        background: url("@/assets/images/garage/oil.png") bottom -321px center/contain no-repeat, linear-gradient(180deg, rgba(255, 117, 39, 0.00) 0%, #FFB627 100%);
        &__button {
            padding: 0 48px;
            font-size: 48px;
            font-weight: 500;
            grid-column: 2 span;
            &-price {
                font-weight: 700;
                margin-left: 32px;
                padding: 7px 12px;
                border-radius: 6px;
                background: var(--dark);
                color: var(--white);
                span {
                    color: var(--gold);
                }
            }
        }
        &__cards {
            display: flex;
            margin-bottom: 40px;
        }
        &__card {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 100px 44px;
            width: 380px;
            border-radius: 32px;
            background: radial-gradient(125.31% 111.03% at 25.97% 17.25%, rgba(255, 237, 76, 0.30) 0%, rgba(255, 237, 76, 0.00) 100%);
            &:first-child {
                background: radial-gradient(125.31% 111.03% at 25.97% 17.25%, rgba(241, 241, 241, 0.30) 0%, rgba(241, 241, 241, 0.00) 100%);
                margin-right: 40px;
                .gas-info__card-title {
                    color: rgba(241, 241, 241, 0.50);
                }
                .gas-info__card-litre {
                    color: #fff;
                }
            }
            &-title {
                color: rgba(255, 237, 76, 0.50);
                text-align: center;
                font-size: 28px;
                font-weight: 700;
                line-height: 120%;
                letter-spacing: 1.12px;
                text-transform: uppercase;
            }
            &-litre {
                margin: 32px 0;
                color: var(--yellow);
                font-size: 80px;
                font-weight: 700;
                line-height: 100%;
                letter-spacing: -1.6px;
            }
            &-fuel {
                &::v-deep svg {
                    width: 237px;
                    height: 100%;
                }
            }
        }
    }
    &-control {
        display: flex;
        justify-content: center;
        flex-direction: column;
        &__item {
            overflow: hidden;
            padding: 64px;
            position: relative;
            border-radius: 32px;
            width: 948px;
            background: radial-gradient(188.58% 111.03% at 25.97% 17.25%, rgba(255, 154, 72, 0.30) 0%, rgba(255, 154, 72, 0.00) 100%);
            &:first-child {
                background: radial-gradient(188.58% 111.03% at 25.97% 17.25%, rgba(195, 246, 30, 0.30) 0%, rgba(195, 246, 30, 0.00) 100%);
                margin-bottom: 40px;
            }
            &-title {
                max-width: 440px;
                color: var(--white);
                margin-bottom: 24px;
                font-size: 52px;
                font-weight: 400;
                line-height: 110%;
            }
            &-button {
                padding: 0 44px;
                font-size: 36px;
                height: 112px;
                font-weight: 500;
                &-price {
                    font-weight: 700;
                    margin-left: 18px;
                    background: var(--dark);
                    letter-spacing: -0.8px;
                    color: var(--white);
                    padding: 7px 12px;
                    border-radius: 6px;
                    span {
                        color: var(--gold);
                    }
                }
            }
            &-image {
                position: absolute;
                right: -31px;
                bottom: -84px;
                width: 456px;
                height: auto;
                &--repair-kit {
                    width: 409px;
                    right: -52px;
                }
            }
        }
    }
}
</style>
