<template>
    <div v-if="isOwned" class="estate__info estate__info--house">
        <div class="estate__info-house">
            <EstateHouseBadge :level=estate.level />
            <div class="estate__info-title">{{ estate.name }}</div>
        </div>
        <EstateControls @controlClicked="handleControlClick" />
    </div>
    <div v-else class="estate__info estate__info--business estate__info--own">
        <div class="estate__own-title">
            <IconNotice icon="alert" />
            {{ $t('estate.house.no_house') }}
        </div>
        <div class="estate__own-text">
            {{ $t('estate.house.buy_house') }} <span>{{ $t('estate.house.other_players') }}</span>
            {{ $t('estate.house.or') }} <span>{{ $t('estate.house.auction')}}</span>:</div>
        <BaseButton white class="estate__own-button" @click="openAuction">
            <IconSign />
            {{ $t('estate.open_auction') }}
        </BaseButton>
    </div>
        <!-- Шаблон для варианта без премиума  -->
<!--    <div v-else class="estate__info estate__info&#45;&#45;business estate__info&#45;&#45;own">
        <div class="estate__own-title">
            <img class="estate__own-image" src="@/assets/images/level-icon-checked.png" alt="">
        </div>
        <div class="estate__own-text estate__own-text&#45;&#45;house">{{ $t('estate.house.buy_more_house') }} <span>{{ $t('estate.premium') }}</span></div>
        <BaseButton yellow class="estate__own-button">
            <IconStarChecked />
            {{ $t('estate.buy_prime') }}
        </BaseButton>
    </div>-->
</template>
<script>

import EstateHouseBadge from '@/components/popups/content/estate/components/EstateHouseBadge.vue';
import EstateControls from '@/components/popups/content/estate/components/EstateControls.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import IconNotice from '@/components/icons/IconNotice.vue';
import IconSign from '@/components/icons/IconSign.vue';

export default {
    name: 'EstateHouse',
    components: { IconSign, IconNotice, BaseButton, EstateControls, EstateHouseBadge },
    props: {
        estate: Object,
        isOwned: Boolean
    },
    methods: {
        handleControlClick (action) {
            this.$emit('controlClicked', action);
        },
        openAuction () {
            this.sendclient('ClientOpenAuction');
        }
    }
};
</script>
