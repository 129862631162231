<template>
        <transition-group tag="div" class="kill-list" name="list">
            <div v-for="(item) in list" :key="item.key" class="kill-list__item">
                <div class="kill-list__person kill-list__person--killer">{{ item.killer }}</div>
                <IconSkull class="kill-list__icon" />
                <div class="kill-list__person kill-list__person--victim">
                    {{ item.victim }}
                </div>
            </div>
        </transition-group>
</template>

<script>
import IconSkull from '@/components/hud/components/killlist/icons/IconSkull.vue';

export default {
    name: 'VKillList',
    components: {
        IconSkull
    },
    data () {
        return {
            list: [
                /*
                {
                    killer: 'Wow killer',
                    victim: 'AUNT KILLER',
                    key: '0'
                },
                {
                    killer: 'Johny Bravo',
                    victim: 'Loop Cooper',
                    key: '1'
                },
                {
                    killer: 'Jeremy clarkson',
                    victim: 'Steve Anderson',
                    key: '2'
                },
                {
                    killer: 'Stieve winky',
                    victim: 'kev fooley',
                    key: '3'
                }
                */
            ],
            number: 0
        };
    },
    methods: {
        addElement (killer, victim) {
            const el = { killer, victim, key: `${Date.now()}-${victim}` };
            this.list.push(el);
            if (this.list.length > 4) {
                this.list.shift();
            }
        },
        annulateKillList () {
            this.list = [];
        }
    }
};
</script>

<style lang="scss" scoped>
.kill-list {
    position: absolute;
    right: 65px;
    top: 395px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    max-width: 500px;
    &__item {
        padding: 8px 0;
        display: flex;
        align-items: center;
        text-align: right;
        width: 100%;
        max-width: fit-content;
        justify-content: flex-end;
        transition: all 500ms ease-in-out;
    }
    &__person {
        color: var(--white);
        font-family: var(--font-stretch);
        font-size: 30px;
        font-weight: 700;
        line-height: 120%;
        text-transform: uppercase;
        text-shadow:
            2px 0 0 #494865,
            0 2px 0 #494865,
            -2px 0 0 #494865,
            0 -2px 0 #494865;
        &--victim {
            color: #FFC0C0;
        }
    }
    &__icon {
        width: 36px;
        height: 36px;
        margin: 0 10px;
    }
}
.list-enter-active,
.list-leave-active,
.list-move {
    transition: 500ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
    transition-property: opacity, transform;
}

.list-enter {
    opacity: 0;
    transform: translateY(30px);
}

.list-enter-to {
    opacity: 1;
    transform: translateY(0);
}

.list-leave-active {
    position: absolute;
    top: 0;
}

.list-leave-to {
    opacity: 0;
    width: 100%;
    transform: translateY(-30px);
}

</style>
