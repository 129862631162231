<template>
    <svg width="346" height="346" viewBox="0 0 346 346" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M173 330.892C152.205 330.892 134.058 317.707 97.7635 291.337L83.7038 281.122C47.4094 254.753 29.2621 241.568 22.8362 221.791C16.4102 202.014 23.3418 180.681 37.2051 138.014L42.5754 121.486C56.4387 78.8192 63.3703 57.4859 80.1937 45.263C97.0171 33.04 119.448 33.04 164.311 33.04H181.689C226.552 33.04 248.983 33.04 265.806 45.263C282.63 57.4859 289.561 78.8192 303.425 121.486L308.795 138.014C322.658 180.681 329.59 202.014 323.164 221.791C316.738 241.568 298.591 254.753 262.296 281.122L248.237 291.337C211.942 317.707 193.795 330.892 173 330.892ZM173 294.252C155.454 294.252 140.143 283.128 109.519 260.878L98.5777 252.929C67.9542 230.68 52.6425 219.555 47.2206 202.868C41.7987 186.181 47.6473 168.181 59.3444 132.181L63.5237 119.319C75.2208 83.3187 81.0694 65.3187 95.2641 55.0056C109.459 44.6925 128.385 44.6925 166.238 44.6925H179.762C217.615 44.6925 236.541 44.6925 250.736 55.0056C264.931 65.3187 270.779 83.3187 282.476 119.319L286.656 132.181C298.353 168.181 304.201 186.181 298.78 202.868C293.358 219.555 278.046 230.68 247.423 252.929L247.422 252.929L236.481 260.878C205.857 283.128 190.546 294.252 173 294.252Z" fill="url(#paint0_radial_4338_10262)"/>
        <mask id="mask0_4338_10262" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" :width="346" :height="346">
            <path :d="calculateArc(currentExp / fullExp * 100)" class="arc"></path>
        </mask>
        <g mask="url(#mask0_4338_10262)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M172.581 330.892C151.786 330.892 133.639 317.707 97.3441 291.337L83.2844 281.122C46.9899 254.753 28.8427 241.568 22.4167 221.791C15.9908 202.014 22.9224 180.681 36.7857 138.014L42.156 121.486C56.0192 78.8192 62.9508 57.4859 79.7742 45.263C96.5976 33.04 119.029 33.04 163.891 33.04H181.27C226.132 33.04 248.564 33.04 265.387 45.263C282.21 57.4859 289.142 78.8192 303.005 121.486L308.376 138.014C322.239 180.681 329.17 202.014 322.745 221.791C316.319 241.568 298.171 254.753 261.877 281.122L247.817 291.337C211.523 317.707 193.375 330.892 172.581 330.892ZM172.581 294.252C155.035 294.252 139.723 283.128 109.1 260.878L98.1582 252.929C67.5348 230.68 52.2231 219.555 46.8012 202.868C41.3793 186.181 47.2278 168.181 58.925 132.181L63.1043 119.319C74.8014 83.3187 80.6499 65.3187 94.8447 55.0056C109.039 44.6925 127.966 44.6925 165.818 44.6925H179.343C217.196 44.6925 236.122 44.6925 250.317 55.0056C264.511 65.3187 270.36 83.3187 282.057 119.319L286.236 132.181C297.933 168.181 303.782 186.181 298.36 202.868C292.938 219.555 277.626 230.68 247.003 252.929L247.003 252.929L236.061 260.878C205.438 283.128 190.126 294.252 172.581 294.252Z" fill="#4BE7A1"/>
        </g>
        <defs>
            <radialGradient id="paint0_radial_4338_10262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(144 154) rotate(70.0055) scale(214.956 214.956)">
                <stop stop-color="#735AFF"/>
                <stop offset="1" stop-color="#735AFF" stop-opacity="0.35"/>
            </radialGradient>
        </defs>
    </svg>

</template>
<script>
export default {
    name: 'IconBattlePassLevel',
    props: {
        currentExp: Number,
        fullExp: Number
    },
    methods: {
        calculateArc (value) {
            const radius = 173;
            const x = Math.cos((2 * Math.PI) / (100 / value));
            const y = Math.sin((2 * Math.PI) / (100 / value));

            const longArc = (value <= 50) ? 0 : 1;

            return 'M' + radius + ',' + radius + ' L' + radius + ',' + 0 + ', A' + radius + ',' + radius + ' 0 ' + longArc + ',1 ' + (radius + y * radius) + ',' + (radius - x * radius) + ' z';
        }
    }
};
</script>
<style>
.arc {
    fill: #000;
}
</style>
