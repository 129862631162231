export default {
    data () {
        return {
            isRequestSent: false
        };
    },
    methods: {
        sendclient (name, jsoninfo) {
            console.log(name, jsoninfo);
            if (window.ue && window.ue.interface) window.ue.interface.broadcast(name, jsoninfo);
        },
        sendnotif (type, msg) {
            let typeText = '';
            if (type === 1)typeText = 'alert';
            else if (type === 2)typeText = 'info';
            else if (type === 3)typeText = 'success';
            else if (type === 4)typeText = 'warning';
            else return;
            window.callVueMethod('App', 'addNotice', {
                type: typeText,
                text: msg
            });
        }
    },

    created () {
        if (this.$options.name) {
            window.vueComponents[this.$options.name] = this;
        }
    }
};
