<template>
    <div class="offer">
        <div class="offer__hand">
            <img src="./assets/images/hand.svg" alt="">
        </div>
        <div class="offer__content">
            <p>{{title}}</p>
            <span>{{description}}</span>
        </div>
        <div class="offer__btn">
            <button @click="hundlerDecline">
                <img src="./assets/images/close.svg" alt="">
            </button>
            <button @click="hundlerAccept">
                <img src="./assets/images/accept.svg" alt="">
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VOffer',
    data () {
        return {
            title: '',
            description: ''
        };
    },
    methods: {
        hundlerAccept () {
            this.sendclient('DealAnswer', JSON.stringify({ a: true }));
        },
        hundlerDecline () {
            this.sendclient('DealAnswer', JSON.stringify({ a: false }));
        },
        Initialize (title, description) {
            this.title = title;
            this.description = description;
        }
    }
};
</script>

<style lang="scss">
.offer{
    background: rgba(32, 36, 39, 0.96);
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05), 0px 14px 100px rgba(0, 0, 0, 0.05);
    border-radius: .5vw;
    max-width: max-content;
    width: 100%;
    position: absolute;
    z-index: 15;
    padding: 1.3vw 1.5vw;
    top: 15vw;

    left: 0;
    right: 0;
    margin: 0 auto;

    display: flex;
    align-items: center;

    &__hand{
        margin-right: 1.5vw;
        img{
            height: 3vw;
        }
    }
    &__content{
        margin-right: 1.5vw;
        p{
            font-weight: 900;
            font-size: 1.5vw;
            line-height: 100%;

            font-family: var(--font-stretch);

            letter-spacing: 0.06em;
            text-transform: uppercase;

            color: var(--white);
            margin-bottom: .2vw;
        }

        span{
            font-weight: 700;
            font-size: 1.3vw;
            line-height: 110%;
            /* identical to box height, or 11px */

            display: flex;
            align-items: flex-end;

            color: #F1F1F1;

            opacity: 0.6;

        }
    }

    &__btn{
        display: flex;

        button{
            border: none;
            background: none;

            &:nth-child(1){
                margin-right: .5vw;
            }
        }

        img{
            height: 4.3vw;
        }
    }
}
</style>
