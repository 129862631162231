<template>
    <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M48.001 53.6578L69.1724 74.8292L74.8292 69.1724L53.6578 48.001L74.8292 26.8296L69.1724 21.1727L48.001 42.3441L26.8292 21.1724L21.1724 26.8292L42.3441 48.001L21.1724 69.1727L26.8292 74.8296L48.001 53.6578Z" :fill="fill"/>
    </svg>
</template>

<script>
export default {
    name: 'IconClose',
    props: {
        fill: {
            type: String,
            default: '#CF4941'
        }
    }
};
</script>
