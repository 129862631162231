<template>
    <div class="task">
        <AppHeader back-button>
            <template #title>{{ $t('task.header') }}</template>
        </AppHeader>
        <div class="task__wrapper">
            <BaseTabMenu class="task-menu" :items="tabDefinitions" :activeTab="selectedType" isScrollable :handleTabChange="changeTab" />
            <div class="task__content">
<!--                <section-->
<!--                    class="task__gift"-->
<!--                    :class="{'task__gift&#45;&#45;one': inventoryItems.length === 0}"-->
<!--                    v-if="selectedType === 'gift'">-->
<!--                    <template v-if="inventoryItems.length > 0">-->
<!--                        <div class="task__gift-info">-->
<!--                            <h3>{{ inventoryItems.length }}</h3>-->
<!--                            &lt;!&ndash; eslint-disable-next-line &ndash;&gt;-->
<!--                            <span>{{ $t('task.no_awards') }}</span>-->
<!--                            <p>-->
<!--                                {{ $t('task.awards_here') }}-->
<!--                                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                                    <g clip-path="url(#clip0_377_12724)">-->
<!--                                        <path d="M23.8333 11.2821C26.8708 11.2821 29.3333 8.75648 29.3333 5.64103C29.3333 2.52557 26.8708 0 23.8333 0C20.7957 0 18.3333 2.52557 18.3333 5.64103C18.3333 8.75648 20.7957 11.2821 23.8333 11.2821Z" fill="#F1F1F1"/>-->
<!--                                        <path d="M21.9999 13.9141C27.4999 13.9141 28.8343 17.2423 28.2175 21.3481L25.6666 38.3585C25.6666 38.3585 27.4999 38.3585 31.1666 36.4782C31.1666 36.4782 29.3333 43.9995 21.9999 43.9995C16.4999 43.9995 15.1655 40.6713 15.7823 36.5655L18.3333 19.5551C18.3333 19.5551 16.4999 19.5551 12.8333 21.4354C12.8333 21.4354 14.6666 13.9141 21.9999 13.9141Z" fill="#F1F1F1"/>-->
<!--                                    </g>-->
<!--                                    <defs>-->
<!--                                        <clipPath id="clip0_377_12724">-->
<!--                                            <rect width="18.3333" height="44" fill="white" transform="translate(12.8333)"/>-->
<!--                                        </clipPath>-->
<!--                                    </defs>-->
<!--                                </svg>-->
<!--                            </p>-->
<!--                        </div>-->
<!--                        <div class="task__gift-items">-->
<!--                            <div class="task__gift-wrapper">-->
<!--                                <div v-for="(item, index) in inventoryItems" :key="index" class="task__gift-item"-->
<!--                                :class="`${item.rarity}`">-->
<!--                                    <InventoryItem :item="item" />-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <BaseButton white @click="claimReward" class="task__gift-btn">Забрать награду</BaseButton>-->
<!--                        </div>-->
<!--                    </template>-->
<!--                    <template v-else>-->
<!--                        <div class="task__gift-done">-->
<!--                            <h4>{{ $t('task.awards_granted') }}</h4>-->
<!--                            <p>-->
<!--                                {{ $t('task.awards_here') }}-->
<!--                                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                                    <g clip-path="url(#clip0_377_12724)">-->
<!--                                        <path d="M23.8333 11.2821C26.8708 11.2821 29.3333 8.75648 29.3333 5.64103C29.3333 2.52557 26.8708 0 23.8333 0C20.7957 0 18.3333 2.52557 18.3333 5.64103C18.3333 8.75648 20.7957 11.2821 23.8333 11.2821Z" fill="#F1F1F1"/>-->
<!--                                        <path d="M21.9999 13.9141C27.4999 13.9141 28.8343 17.2423 28.2175 21.3481L25.6666 38.3585C25.6666 38.3585 27.4999 38.3585 31.1666 36.4782C31.1666 36.4782 29.3333 43.9995 21.9999 43.9995C16.4999 43.9995 15.1655 40.6713 15.7823 36.5655L18.3333 19.5551C18.3333 19.5551 16.4999 19.5551 12.8333 21.4354C12.8333 21.4354 14.6666 13.9141 21.9999 13.9141Z" fill="#F1F1F1"/>-->
<!--                                    </g>-->
<!--                                    <defs>-->
<!--                                        <clipPath id="clip0_377_12724">-->
<!--                                            <rect width="18.3333" height="44" fill="white" transform="translate(12.8333)"/>-->
<!--                                        </clipPath>-->
<!--                                    </defs>-->
<!--                                </svg>-->
<!--                            </p>-->
<!--                        </div>-->
<!--                    </template>-->
<!--                </section>-->
<!--                <section class="task__daily" v-if="selectedType === 'daily'">-->
<!--                    <div class="task__daily-top">-->
<!--                        <div class="task__daily-item" :class="{ complete: item?.isComplete }" v-for="item in dailyTasks" :key="item.id">-->
<!--                            <div class="task__daily-item-complete" v-if="item?.isComplete">-->
<!--                                <IconCheckmark fill="#F1F1F1" />-->
<!--                            </div>-->
<!--                            <img :src="imageSource(`task-${item?.img}.png`, 'works')" alt="">-->
<!--                            <h3>{{ item?.title }}</h3>-->
<!--                            <p>{{ item?.text }}</p>-->
<!--                            <IconBorder className="svg" icon="bottom" :fill="item?.isComplete ? '#27AE60' : '#B784C9'"/>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="task__daily-bottom">-->
<!--                        <div class="task__daily-bottom-left">-->
<!--                            <svg width="120" height="120" class="circle" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                                <g opacity="0.1">-->
<!--                                    <path d="M22.4562 46.1676L5.02539 36.0723C2.65072 41.5107 1.05339 47.3651 0.377279 53.5017C0.134603 55.6979 0 57.9276 0 60.1893C0 62.4512 0.134603 64.6808 0.377279 66.8771C3.45329 94.7726 25.5254 116.914 53.3333 120V99.4469C34.4152 96.2731 20 79.82 20 60C20 55.1379 20.8675 50.4783 22.4562 46.1676Z" fill="#F1F1F1"/>-->
<!--                                    <path d="M60 0C79.8133 0 97.3773 9.63827 108.303 24.4916L90.8851 34.5795C83.5487 25.6763 72.4373 20 60 20C47.5627 20 36.4513 25.6763 29.1149 34.5795L11.6973 24.4916C22.6226 9.63827 40.1865 0 60 0Z" fill="#F1F1F1"/>-->
<!--                                    <path d="M65.3333 99.6476C84.9032 97.0402 99.9999 80.2832 99.9999 60C99.9999 55.0193 99.0896 50.2512 97.4262 45.853L114.849 36C117.283 41.4431 118.92 47.3026 119.613 53.4444C119.862 55.6426 120 57.8742 120 60.1379C120 62.4015 119.862 64.6331 119.613 66.8314C116.46 94.751 93.8365 116.912 65.3333 120V99.6476Z" fill="#F1F1F1"/>-->
<!--                                </g>-->
<!--                                <path v-if="dailyTasks && dailyTasks[2]?.isComplete" d="M22.4562 46.1676L5.02539 36.0723C2.65072 41.5107 1.05339 47.3651 0.377279 53.5017C0.134603 55.6979 0 57.9276 0 60.1893C0 62.4512 0.134603 64.6808 0.377279 66.8771C3.45329 94.7726 25.5254 116.914 53.3333 120V99.4469C34.4152 96.2731 20 79.82 20 60C20 55.1379 20.8675 50.4783 22.4562 46.1676Z" fill="#27AE60"/>-->
<!--                                <path v-if="dailyTasks && dailyTasks[1]?.isComplete" d="M60 0C79.8133 0 97.3773 9.63827 108.303 24.4916L90.8851 34.5795C83.5487 25.6763 72.4373 20 60 20C47.5627 20 36.4513 25.6763 29.1149 34.5795L11.6973 24.4916C22.6226 9.63827 40.1865 0 60 0Z" fill="#27AE60"/>-->
<!--                                <path v-if="dailyTasks && dailyTasks[0]?.isComplete" d="M65.3335 99.6476C84.9035 97.0402 100 80.2832 100 60C100 55.0193 99.0898 50.2512 97.4264 45.853L114.849 36C117.283 41.4431 118.921 47.3026 119.613 53.4444C119.862 55.6426 120 57.8742 120 60.1379C120 62.4015 119.862 64.6331 119.613 66.8314C116.46 94.751 93.8368 116.912 65.3335 120V99.6476Z" fill="#27AE60"/>-->
<!--                            </svg>-->

<!--                            <img class="img" src="../../../../assets/images/works/save.png" >-->

<!--                            <div class="task__daily-bottom-info">-->
<!--                                <h4>{{ $t('task.daily_reward') }}</h4>-->
<!--                                <p>{{ $t('task.complete_all') }}</p>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        &lt;!&ndash;<BaseButton white class="btn" @click="pickUpDaily"> Забрать приз</BaseButton>&ndash;&gt;-->

<!--                        <img src="../../../../assets/images/works/task-fill.svg" class="task-fill" alt="">-->
<!--                    </div>-->
<!--                </section>-->

<!--                <section class="task__list" v-if="selectedType === 'newbies'">-->
<!--                    <PhoneTaskList :array="newbieTasks" />-->
<!--                    <PhoneTaskProgress-->
<!--                        :completedTasks="completedTasks"-->
<!--                        :percent="percent"-->
<!--                        :tabLength="GetLengthOfTab"-->
<!--                        @claimCategoryReward="claimCategoryReward"-->
<!--                    />-->
<!--                </section>-->

<!--                <section class="task__list" v-if="selectedType === 'general'">-->
<!--                    <PhoneTaskList :array="generalTasks" />-->
<!--                    <PhoneTaskProgress-->
<!--                        :completedTasks="completedTasks"-->
<!--                        :percent="percent"-->
<!--                        :tabLength="GetLengthOfTab"-->
<!--                        @claimCategoryReward="claimCategoryReward"-->
<!--                    />-->
<!--                </section>-->

<!--                <section class="task__list" v-if="selectedType === 'continent'">-->
<!--                    <PhoneTaskList :array="continentTasks" />-->
<!--                    <PhoneTaskProgress-->
<!--                        :completedTasks="completedTasks"-->
<!--                        :percent="percent"-->
<!--                        :tabLength="GetLengthOfTab"-->
<!--                        @claimCategoryReward="claimCategoryReward"-->
<!--                    />-->
<!--                </section>-->

<!--                <section class="task__list" v-if="selectedType === 'family'">-->
<!--                    <PhoneTaskList :array="familyTasks" />-->
<!--                    <PhoneTaskProgress-->
<!--                        :completedTasks="completedTasks"-->
<!--                        :percent="percent"-->
<!--                        :tabLength="GetLengthOfTab"-->
<!--                        @claimCategoryReward="claimCategoryReward"-->
<!--                    />-->
<!--                </section>-->
<!--                <section class="task__list" v-if="selectedType === 'team'">-->
<!--                    <PhoneTaskList :array="teamTasks" />-->
<!--                    <PhoneTaskProgress-->
<!--                        :completedTasks="completedTasks"-->
<!--                        :percent="percent"-->
<!--                        :tabLength="GetLengthOfTab"-->
<!--                        @claimCategoryReward="claimCategoryReward"-->
<!--                    />-->
<!--                </section>-->

                <section :class="`task__${tabsData.class ? tabsData.class : 'list'}${inventoryItems.length === 0 && tabsData.class === 'gift' ? ' task__gift--one' : ''}`" v-show="tabsData">
                    <template v-if="tabsData.class === 'daily'">
                        <div class="task__daily-top">
                            <div class="task__daily-item" :class="{ complete: item?.isComplete }" v-for="item in tabsData.arr" :key="item.id">
                                <div class="task__daily-item-complete" v-if="item?.isComplete">
                                    <IconCheckmark fill="#F1F1F1" />
                                </div>
                                <img :src="imageSource(`task-${item?.img}.png`, 'works')" alt="">
                                <h3>{{ item?.title }}</h3>
                                <p>{{ item?.text }}</p>
                                <IconBorder className="svg" icon="bottom" :fill="item?.isComplete ? '#27AE60' : '#B784C9'"/>
                            </div>
                        </div>
                        <div class="task__daily-bottom">
                            <div class="task__daily-bottom-left">
                                <svg width="120" height="120" class="circle" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.1">
                                        <path d="M22.4562 46.1676L5.02539 36.0723C2.65072 41.5107 1.05339 47.3651 0.377279 53.5017C0.134603 55.6979 0 57.9276 0 60.1893C0 62.4512 0.134603 64.6808 0.377279 66.8771C3.45329 94.7726 25.5254 116.914 53.3333 120V99.4469C34.4152 96.2731 20 79.82 20 60C20 55.1379 20.8675 50.4783 22.4562 46.1676Z" fill="#F1F1F1"/>
                                        <path d="M60 0C79.8133 0 97.3773 9.63827 108.303 24.4916L90.8851 34.5795C83.5487 25.6763 72.4373 20 60 20C47.5627 20 36.4513 25.6763 29.1149 34.5795L11.6973 24.4916C22.6226 9.63827 40.1865 0 60 0Z" fill="#F1F1F1"/>
                                        <path d="M65.3333 99.6476C84.9032 97.0402 99.9999 80.2832 99.9999 60C99.9999 55.0193 99.0896 50.2512 97.4262 45.853L114.849 36C117.283 41.4431 118.92 47.3026 119.613 53.4444C119.862 55.6426 120 57.8742 120 60.1379C120 62.4015 119.862 64.6331 119.613 66.8314C116.46 94.751 93.8365 116.912 65.3333 120V99.6476Z" fill="#F1F1F1"/>
                                    </g>
                                    <path v-if="dailyTasks && dailyTasks[2]?.isComplete" d="M22.4562 46.1676L5.02539 36.0723C2.65072 41.5107 1.05339 47.3651 0.377279 53.5017C0.134603 55.6979 0 57.9276 0 60.1893C0 62.4512 0.134603 64.6808 0.377279 66.8771C3.45329 94.7726 25.5254 116.914 53.3333 120V99.4469C34.4152 96.2731 20 79.82 20 60C20 55.1379 20.8675 50.4783 22.4562 46.1676Z" fill="#27AE60"/>
                                    <path v-if="dailyTasks && dailyTasks[1]?.isComplete" d="M60 0C79.8133 0 97.3773 9.63827 108.303 24.4916L90.8851 34.5795C83.5487 25.6763 72.4373 20 60 20C47.5627 20 36.4513 25.6763 29.1149 34.5795L11.6973 24.4916C22.6226 9.63827 40.1865 0 60 0Z" fill="#27AE60"/>
                                    <path v-if="dailyTasks && dailyTasks[0]?.isComplete" d="M65.3335 99.6476C84.9035 97.0402 100 80.2832 100 60C100 55.0193 99.0898 50.2512 97.4264 45.853L114.849 36C117.283 41.4431 118.921 47.3026 119.613 53.4444C119.862 55.6426 120 57.8742 120 60.1379C120 62.4015 119.862 64.6331 119.613 66.8314C116.46 94.751 93.8368 116.912 65.3335 120V99.6476Z" fill="#27AE60"/>
                                </svg>

                                <img class="img" src="../../../../assets/images/works/save.png" >

                                <div class="task__daily-bottom-info">
                                    <h4>{{ $t('task.daily_reward') }}</h4>
                                    <p>{{ $t('task.complete_all') }}</p>
                                </div>
                            </div>
                            <!--<BaseButton white class="btn" @click="pickUpDaily"> Забрать приз</BaseButton>-->

                            <img src="../../../../assets/images/works/task-fill.svg" class="task-fill" alt="">
                        </div>
                    </template>
                    <template v-if="tabsData.class === 'social'">
                        <div class="task__social">
                            <div class="task__social-title">{{ $t('task.social.title') }}</div>
                            <div class="task__social-item" v-for="(item, index) in social" :key="index" :class="`task__social-item-${item.title}` ">
                                <div class="task__social-left">
                                    <img :src="$imageSrc(`${item.title}.svg`, 'tasks')" alt="">
                                    <div class="task__social-content">
                                        <h4>{{ item.title }}</h4>
                                        <p>{{ item.desc }}</p>
                                    </div>
                                </div>
                                <div class="task__social-right">
                                    <div class="task__social-gift">
                                        <svg class="plus" width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M29.0004 18.9035H18.7604V29.1435H10.9524V18.9035H0.648438V11.0955H10.9524V0.855469H18.7604V11.0955H29.0004V18.9035Z" fill="#F1F1F1"/>
                                        </svg>
                                        <IconDonate class="donate"/>
                                        {{ item.gift }}
                                    </div>
                                    <BaseButton white @click="clickFollow(item)" v-if="item.check === 0">
                                        <BaseIcon class="icon-link-chain" name="link-chain"/>
                                        {{ $t('task.social.subscribe') }}
                                    </BaseButton>
                                    <BaseButton glass @click="clickCheck(item)" v-if="item.check === 1">
                                        <BaseIcon class="icon-refrash" name="refrash" color="#F1F1F1"/>
                                        {{ $t('task.social.check') }}
                                    </BaseButton>
                                    <BaseButton yellow @click="clickGift(item)" v-if="item.check === 2">
                                        <BaseIcon class="icon-checkmark" name="checkmark"/>
                                        {{ $t('task.social.receive_award') }}</BaseButton>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-if="tabsData.class === 'gift'">
                        <template v-if="inventoryItems.length > 0">
                            <div class="task__gift-info">
                                <h3>{{ inventoryItems.length }}</h3>
                                <!-- eslint-disable-next-line -->
                                <span>{{ $t('task.no_awards') }}</span>
                                <p>
                                    {{ $t('task.awards_here') }}
                                    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_377_12724)">
                                            <path d="M23.8333 11.2821C26.8708 11.2821 29.3333 8.75648 29.3333 5.64103C29.3333 2.52557 26.8708 0 23.8333 0C20.7957 0 18.3333 2.52557 18.3333 5.64103C18.3333 8.75648 20.7957 11.2821 23.8333 11.2821Z" fill="#F1F1F1"/>
                                            <path d="M21.9999 13.9141C27.4999 13.9141 28.8343 17.2423 28.2175 21.3481L25.6666 38.3585C25.6666 38.3585 27.4999 38.3585 31.1666 36.4782C31.1666 36.4782 29.3333 43.9995 21.9999 43.9995C16.4999 43.9995 15.1655 40.6713 15.7823 36.5655L18.3333 19.5551C18.3333 19.5551 16.4999 19.5551 12.8333 21.4354C12.8333 21.4354 14.6666 13.9141 21.9999 13.9141Z" fill="#F1F1F1"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_377_12724">
                                                <rect width="18.3333" height="44" fill="white" transform="translate(12.8333)"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </p>
                            </div>
                            <div class="task__gift-items">
                                <div class="task__gift-wrapper">
                                    <div v-for="(item, index) in inventoryItems" :key="index" class="task__gift-item"
                                         :class="`${item.rarity}`">
                                        <InventoryItem :item="item" />
                                    </div>
                                </div>
                                <BaseButton white @click="claimReward" class="task__gift-btn">Забрать награду</BaseButton>
                            </div>
                        </template>
                        <template v-else>
                            <div class="task__gift-done">
                                <h4>{{ $t('task.awards_granted') }}</h4>
                                <p>
                                    {{ $t('task.awards_here') }}
                                    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_377_12724)">
                                            <path d="M23.8333 11.2821C26.8708 11.2821 29.3333 8.75648 29.3333 5.64103C29.3333 2.52557 26.8708 0 23.8333 0C20.7957 0 18.3333 2.52557 18.3333 5.64103C18.3333 8.75648 20.7957 11.2821 23.8333 11.2821Z" fill="#F1F1F1"/>
                                            <path d="M21.9999 13.9141C27.4999 13.9141 28.8343 17.2423 28.2175 21.3481L25.6666 38.3585C25.6666 38.3585 27.4999 38.3585 31.1666 36.4782C31.1666 36.4782 29.3333 43.9995 21.9999 43.9995C16.4999 43.9995 15.1655 40.6713 15.7823 36.5655L18.3333 19.5551C18.3333 19.5551 16.4999 19.5551 12.8333 21.4354C12.8333 21.4354 14.6666 13.9141 21.9999 13.9141Z" fill="#F1F1F1"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_377_12724">
                                                <rect width="18.3333" height="44" fill="white" transform="translate(12.8333)"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </p>
                            </div>
                        </template>
                    </template>
                    <template v-if="!tabsData.class">
                        <PhoneTaskList :array="tabsData.arr" />
                        <PhoneTaskProgress
                            :completedTasks="completedTasks"
                            :percent="percent"
                            :tabLength="GetLengthOfTab"
                            @claimCategoryReward="claimCategoryReward"
                        />
                    </template>
                </section>
            </div>
        </div>
    </div>
</template>
<script>
import AppHeader from '@/components/AppHeader/AppHeader.vue';
import BaseTabMenu from '@/components/ui/BaseTabMenu.vue';
import IconBorder from '../works/icons/IconBorder.vue';
import IconCheckmark from '@/components/icons/IconCheckmark.vue';
import InventoryItem from '@/components/popups/components/inventoryItem/InventoryItem.vue';
import { mapGetters } from 'vuex';
import BaseButton from '@/components/ui/BaseButton.vue';
import PhoneTaskProgress from '@/components/popups/content/task/components/PhoneTaskProgress.vue';
import PhoneTaskList from '@/components/popups/content/task/components/PhoneTaskList.vue';
import IconDonate from '@/components/icons/IconDonate.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';

export default {
    name: 'PhoneTask',
    components: { BaseIcon, IconDonate, PhoneTaskList, PhoneTaskProgress, BaseButton, InventoryItem, /* BaseButton, */IconCheckmark, IconBorder, BaseTabMenu, AppHeader },
    data () {
        return {
            postalLoaded: false,
            selectedType: 'daily',
            inventoryItems: [
                // { id: 2, name: 'Штурмовая винтовка', rarity: 'unusual', type: 1, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/m4' },
                // { id: 3, name: 'Пистолет', rarity: 'unusual', type: 1, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/pistol' },
                // { id: 4, name: 'Верхняя одежда', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 1, gender: 0 }
            ],
            tabDefinitions: [
                { type: 'gift', text: this.$t('task.tabs.my'), icon: 'box' },
                { type: 'daily', text: this.$t('task.tabs.daily'), icon: 'daily' },
                { type: 'social', text: this.$t('task.tabs.social'), icon: 'gift' },
                { type: 'newbies', text: this.$t('task.tabs.newbies'), icon: 'newbie' },
                { type: 'general', text: this.$t('task.tabs.general'), icon: 'task' },
                { type: 'continent', text: this.$t('task.tabs.continent'), icon: 'pin' },
                { type: 'family', text: this.$t('task.tabs.family'), icon: 'family' },
                { type: 'team', text: this.$t('task.tabs.team'), icon: 'team' }
            ],
            social: [
                { title: 'discord', desc: this.$t('task.social.discord'), gift: 100, check: 0 },
                { title: 'facebook', desc: this.$t('task.social.facebook'), gift: 100, check: 0 },
                { title: 'instagram', desc: this.$t('task.social.instagram'), gift: 100, check: 2 },
                { title: 'telegram', desc: this.$t('task.social.telegram'), gift: 100, check: 1 }
            ],
            allDailyTasks: [
                { id: 1, title: this.$t('task.allDailyTasks.active_player'), text: this.$t('task.allDailyTasks.desc_active_player'), img: 'active_player' },
                { id: 2, title: this.$t('task.allDailyTasks.benefactor'), text: this.$t('task.allDailyTasks.desc_benefactor'), img: 'benefactor' },
                { id: 3, title: this.$t('task.allDailyTasks.item_creation'), text: this.$t('task.allDailyTasks.desc_item_creation'), img: 'item_creation' },
                { id: 4, title: this.$t('task.allDailyTasks.gas_station'), text: this.$t('task.allDailyTasks.desc_gas_station'), img: 'gas_station' },
                { id: 5, title: this.$t('task.allDailyTasks.bus_driver'), text: this.$t('task.allDailyTasks.desc_bus_driver'), img: 'bus_driver' },
                { id: 6, title: this.$t('task.allDailyTasks.miner'), text: this.$t('task.allDailyTasks.desc_miner'), img: 'miner' },
                { id: 7, title: this.$t('task.allDailyTasks.farmer'), text: this.$t('task.allDailyTasks.desc_farmer'), img: 'farmer' },
                { id: 8, title: this.$t('task.allDailyTasks.fisherman'), text: this.$t('task.allDailyTasks.desc_fisherman'), img: 'fisherman' },
                { id: 9, title: this.$t('task.allDailyTasks.plastic_surgery'), text: this.$t('task.allDailyTasks.desc_plastic_surgery'), img: 'plastic_surgery' },
                { id: 10, title: this.$t('task.allDailyTasks.medical_kit'), text: this.$t('task.allDailyTasks.desc_medical_kit'), img: 'medical_kit' },
                { id: 11, title: this.$t('task.allDailyTasks.sms'), text: this.$t('task.allDailyTasks.desc_sms'), img: 'sms' },
                { id: 12, title: this.$t('task.allDailyTasks.passport'), text: this.$t('task.allDailyTasks.desc_passport'), img: 'passport' },
                { id: 13, title: this.$t('task.allDailyTasks.repair_kit'), text: this.$t('task.allDailyTasks.desc_repair_kit'), img: 'repair_kit' },
                { id: 14, title: this.$t('task.allDailyTasks.house'), text: this.$t('task.allDailyTasks.desc_house'), img: 'house' },
                { id: 15, title: this.$t('task.allDailyTasks.separation'), text: this.$t('task.allDailyTasks.desc_separation'), img: 'separation' },
                { id: 16, title: this.$t('task.allDailyTasks.delate'), text: this.$t('task.allDailyTasks.desc_delate'), img: 'delate' },
                { id: 17, title: this.$t('task.allDailyTasks.ambulance_service'), text: this.$t('task.allDailyTasks.desc_ambulance_service'), img: 'ambulance_service' },
                { id: 18, title: this.$t('task.allDailyTasks.barbershop'), text: this.$t('task.allDailyTasks.desc_barbershop'), img: 'barbershop' },
                { id: 19, title: this.$t('task.allDailyTasks.atm'), text: this.$t('task.allDailyTasks.desc_atm'), img: 'atm' },
                { id: 20, title: this.$t('task.allDailyTasks.street_market'), text: this.$t('task.allDailyTasks.desc_street_market'), img: 'street_market' },
                { id: 21, title: this.$t('task.allDailyTasks.media'), text: this.$t('task.allDailyTasks.desc_media'), img: 'media' },
                { id: 22, title: this.$t('task.allDailyTasks.garage'), text: this.$t('task.allDailyTasks.desc_garage'), img: 'garage' },
                { id: 23, title: this.$t('task.allDailyTasks.lighthouse'), text: this.$t('task.allDailyTasks.desc_lighthouse'), img: 'lighthouse' },
                { id: 24, title: this.$t('task.allDailyTasks.purchase'), text: this.$t('task.allDailyTasks.desc_purchase'), img: 'purchase' },
                { id: 25, title: this.$t('task.allDailyTasks.canister'), text: this.$t('task.allDailyTasks.desc_canister'), img: 'canister' },
                { id: 26, title: this.$t('task.allDailyTasks.race'), text: this.$t('task.allDailyTasks.desc_race'), img: 'finish' },
                { id: 27, title: this.$t('task.allDailyTasks.clothing_store'), text: this.$t('task.allDailyTasks.desc_clothing_store'), img: 'clothing_store' }
            ],
            dailyTasks: [
                // --
            ],
            newbieTasks: [
                { id: 1, title: 'Тестовое задание 1', text: 'Тестовое описание', start: 5000, finish: 5000, isComplete: true },
                { id: 2, title: 'Тестовое задание 2', text: 'Тестовое описание', start: 0, finish: 1, isComplete: true },
                { id: 7, title: 'Тестовое задание 2', text: 'Тестовое описание', start: 0, finish: 1, isComplete: true },
                { id: 8, title: 'Тестовое задание 1', text: 'Тестовое описание', start: 5000, finish: 5000, isComplete: true },
                { id: 228, title: 'Тестовое задание 2', text: 'Тестовое описание', start: 0, finish: 1, isComplete: true },
                { id: 2761, title: 'Тестовое задание 2', text: 'Тестовое описание', start: 0, finish: 1, isComplete: true },
                { id: 2141, title: 'Тестовое задание 1', text: 'Тестовое описание', start: 5000, finish: 5000, isComplete: true },
                { id: 2241, title: 'Тестовое задание 2', text: 'Тестовое описание', start: 0, finish: 1, isComplete: true },
                { id: 2171, title: 'Тестовое задание 2', text: 'Тестовое описание', start: 0, finish: 1, isComplete: true },
                { id: 2121, title: 'Тестовое задание 1', text: 'Тестовое описание', start: 5000, finish: 5000, isComplete: true },
                { id: 2222, title: 'Тестовое задание 2', text: 'Тестовое описание', start: 0, finish: 1, isComplete: true },
                { id: 71444, title: 'Тестовое задание 2', text: 'Тестовое описание', start: 0, finish: 1, isComplete: true }
            ],
            generalTasks: [
                { id: 3, title: 'Тестовое задание 3', text: 'Тестовое описание', start: 0, finish: 1, isComplete: true },
                { id: 23, title: 'Тестовое задание 3', text: 'Тестовое описание', start: 0, finish: 1, isComplete: false }
            ],
            continentTasks: [
                { id: 4, title: 'Тестовое задание 4', text: 'Тестовое описание', start: 0, finish: 1, isComplete: false }
            ],
            familyTasks: [
                { id: 5, title: 'Тестовое задание 5', text: 'Тестовое описание', start: 0, finish: 1, isComplete: false }
            ],
            teamTasks: [
                { id: 6, title: 'Тестовое задание 6', text: 'Тестовое описание', start: 0, finish: 1, isComplete: false }
            ]
        };
    },
    computed: {
        completedTasks () {
            if (this.selectedType === 'daily') return this.dailyTasks.filter(task => task.isComplete).length;
            else if (this.selectedType === 'newbies') return this.newbieTasks.filter(task => task.isComplete).length;
            else if (this.selectedType === 'general') return this.generalTasks.filter(task => task.isComplete).length;
            else if (this.selectedType === 'continent') return this.continentTasks.filter(task => task.isComplete).length;
            else if (this.selectedType === 'family') return this.familyTasks.filter(task => task.isComplete).length;
            else if (this.selectedType === 'team') return this.teamTasks.filter(task => task.isComplete).length;
            return 0;
        },
        percent () {
            return this.completedTasks / this.GetLengthOfTab() * 100;
        },
        tabsData () {
            const data = {
                gift: { class: 'gift' },
                daily: { arr: this.dailyTasks, class: 'daily' },
                social: { arr: this.social, class: 'social' },
                newbies: { arr: this.newbieTasks },
                general: { arr: this.generalTasks },
                continent: { arr: this.continentTasks },
                family: { arr: this.familyTasks },
                team: { arr: this.teamTasks }
            };
            return data[this.selectedType] || 'daily';
        },
        ...mapGetters('inventory', ['inventoryItemsPool']),
        firstItemRarityColors () {
            if (this.inventoryItems.length > 0) {
                const firstItemRarity = this.inventoryItems[0].rarity;
                if (firstItemRarity === 'ordinary') {
                    return { color1: '#7A8489', color2: '#2E3538' };
                }
                if (firstItemRarity === 'epic') {
                    return { color1: '#9547F0', color2: '#40118C' };
                }
                if (firstItemRarity === 'legend') {
                    return { color1: '#FFC93C', color2: '#936600' };
                }
                if (firstItemRarity === 'unusual') {
                    return { color1: '#2780A6', color2: '#005174' };
                }
                if (firstItemRarity === 'rare') {
                    return { color1: '#2EBC92', color2: '#045941' };
                }
            }
            return { color1: '#7A8489', color2: '#2E3538' };
        }
    },
    methods: {
        InitializeInv (itemList) {
            this.inventoryItems = [];
            for (let i = 0; i < itemList.length; i++) {
                if (itemList[i].itemID) {
                    const newItem = Object.assign({}, this.inventoryItemsPool[itemList[i].itemID - 1]);
                    newItem.count = itemList[i].count;
                    newItem.index = this.inventoryItems.length + 1;
                    newItem.additional = itemList[i].additional;
                    this.inventoryItems.push(newItem);
                }
            }
            this.selectedType = 'gift';
            this.postalLoaded = true;
        },
        claimReward () {
            if (this.inventoryItems.length > 0) {
                this.sendclient('getLastReward');
                /*
                const firstItem = this.inventoryItems[0];
                console.log(`Забрал награду ${firstItem.name} id: ${firstItem.id} `);
                const indexToRemove = 0;
                this.inventoryItems.splice(indexToRemove, 1);
                */
            }
        },
        // window.callVueMethod('PhoneTask','InitializeInv', [{itemID:1,count:1,additional:1},{itemID:2,count:1,additional:1},{itemID:3,count:1,additional:1},]);
        /*
        window.modifyVueComponent({
            App: {
                showPopup: true,
                popupContent: 'PhoneTask',
                contentSubFolder: 'task'
            },
            PhoneTask: {
                initialize: [
                    [1,2,3],
                    [1,0,1],
                    [1,0,1,0,1,0]
                ]
            }
        });
        */
        initialize (dailyIds, completeDailyIds, allCompletedQuests) {
            this.dailyTasks = [];
            for (let i = 0; i < dailyIds.length; i++) {
                this.dailyTasks[i] = Object.assign({}, this.allDailyTasks[dailyIds[i] - 1]);
                if (completeDailyIds[i]) this.dailyTasks[i].isComplete = true;
            }
            for (let i = 0; i < this.newbieTasks.length; i++) {
                this.newbieTasks[i].isComplete = !!allCompletedQuests[this.newbieTasks[i].id - 1];
                this.newbieTasks[i].start = allCompletedQuests[this.newbieTasks[i].id - 1];
            }
            for (let i = 0; i < this.generalTasks.length; i++) {
                this.generalTasks[i].isComplete = !!allCompletedQuests[this.generalTasks[i].id - 1];
                this.generalTasks[i].start = allCompletedQuests[this.generalTasks[i].id - 1];
            }
            for (let i = 0; i < this.continentTasks.length; i++) {
                this.continentTasks[i].isComplete = !!allCompletedQuests[this.continentTasks[i].id - 1];
                this.continentTasks[i].start = allCompletedQuests[this.continentTasks[i].id - 1];
            }
            for (let i = 0; i < this.familyTasks.length; i++) {
                this.familyTasks[i].isComplete = !!allCompletedQuests[this.familyTasks[i].id - 1];
                this.familyTasks[i].start = allCompletedQuests[this.familyTasks[i].id - 1];
            }
            for (let i = 0; i < this.teamTasks.length; i++) {
                this.teamTasks[i].isComplete = !!allCompletedQuests[this.teamTasks[i].id - 1];
                this.teamTasks[i].start = allCompletedQuests[this.teamTasks[i].id - 1];
            }
            this.postalLoaded = false;
        },
        changeTab (tabType) {
            if (tabType === 'gift' && this.postalLoaded === false && !this.inventoryItems.length) {
                this.sendclient('loadPostalItems');
                return;
            }
            this.selectedType = tabType;
        },
        GetLengthOfTab () {
            if (this.selectedType === 'daily') return this.dailyTasks.length;
            else if (this.selectedType === 'newbies') return this.newbieTasks.length;
            else if (this.selectedType === 'general') return this.generalTasks.length;
            else if (this.selectedType === 'continent') return this.continentTasks.length;
            else if (this.selectedType === 'family') return this.familyTasks.length;
            else if (this.selectedType === 'team') return this.teamTasks.length;
        },
        pickUpDaily () {
            this.sendclient('GetDailyPrize');
        },
        claimCategoryReward () {
            console.log('Забрал награду за категорию ', this.selectedType);
        },
        clickFollow (item) {
            console.log(`Подписаться ${item.title}`);
        },
        clickCheck (item) {
            console.log(`Проверить ${item.title}`);
        },
        clickGift (item) {
            console.log(`Получить подарок ${item.title}`);
        }
    }

};
</script>

<style lang="scss">
.task {
    &__social {
        .icon {
            &-link-chain, &-refrash, &-checkmark {
                display: flex;
                align-items: center;
                svg {
                    width: 40px;
                    height: 40px;
                    margin-right: 18px;
                }

            }
        }
    }
}
</style>
<style lang="scss" scoped>
    .task{
        background: url(@/assets/images/works/task-bg.svg) no-repeat center / cover;
        height: 100%;

        &-menu {
            padding: 90px 0;
        }

        &__gift{
            display: grid;
            grid-template-columns: 446px 1fr;
            grid-gap: 80px;
            height: 80%;
            align-items: center;
            z-index: 1;
            position: relative;
            &--one{
                grid-template-columns: 1fr;
                text-align: center;
                background: url(./assets/images/box.svg) no-repeat center / 891px;
                h4{
                    color: #F1F1F1;
                    font-size: 80px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 100%; /* 80px */
                    letter-spacing: -1.6px;
                }
                p{
                    color: #F1F1F1;
                    text-align: center;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    max-width: 636px;
                    margin: 64px auto 0;
                    opacity: .5;
                    svg{
                        width: 44px;
                        height: 44px;
                        display: block;
                        margin: 24px auto 0;
                    }
                }
            }
            &-done{
                h4{
                    color: #F1F1F1;
                    font-size: 80px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 100%; /* 80px */
                    letter-spacing: -1.6px;
                }
            }

            &-info{
                display: flex;
                flex-direction: column;
                text-align: center;
                z-index: 1;
                position: relative;
                h3{
                    color: #F1F1F1;
                    font-size: 80px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 100%;
                    letter-spacing: -1.6px;
                    margin-bottom: 24px;
                }
                span{
                    color: #F1F1F1;
                    text-align: center;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%; /* 28.8px */
                    letter-spacing: 1.44px;
                    text-transform: uppercase;
                    margin: 0 auto 64px;
                    max-width: 175px;
                }
                p{
                    margin-bottom: 64px;
                    color: #F1F1F1;
                    text-align: center;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    opacity: 0.5;

                    svg{
                        display: block;
                        width: 44px;
                        height: 44px;
                        margin: 24px auto 0;
                    }
                }

            }
            &-wrapper{
                display: flex;
                align-items: center;
                .inventory__cards-item{
                    width: 220px;
                    height: 280px;
                }
            }

            &-item{
                padding: 60px;
                border-radius: 12px;
                background: rgba(241, 241, 241, 0.03);
                margin-right: 80px;
                position: relative;

                &:nth-child(1){
                    padding: 128px 110px;
                    width: 540px;
                    height: 600px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;

                    @mixin item-block {
                        position: absolute;
                        display: block;
                        content: "";
                        width: 1800px;
                        height: 1800px;
                        z-index: 0;
                        pointer-events: none;
                    }

                    &.ordinary{
                        &::after{
                            @include item-block;
                            background: radial-gradient(50% 50.00% at 50% 50.00%, rgba(241, 241, 241, 0.50) 0%, rgba(74, 74, 74, 0.00) 100%);
                        }
                    }
                    &.epic{
                        &::after{
                            @include item-block;
                            background: radial-gradient(50% 50.00% at 50% 50.00%, rgba(176, 74, 255, 0.50) 0%, rgba(74, 12, 122, 0.00) 100%);
                        }
                    }
                    &.legend{
                        &::after{
                            @include item-block;
                            background: radial-gradient(50% 50.00% at 50% 50.00%, rgba(255, 205, 76, 0.50) 0%, rgba(86, 62, 0, 0.00) 100%);
                        }
                    }
                    &.unusual{
                        &::after{
                            @include item-block;
                            background: radial-gradient(50% 50.00% at 50% 50.00%, rgba(95, 207, 255, 0.50) 0%, rgba(0, 54, 78, 0.00) 100%);
                        }
                    }
                    &.rare{
                        &::after{
                            @include item-block;
                            background: radial-gradient(50% 50.00% at 50% 50.00%, rgba(72, 253, 199, 0.50) 0%, rgba(0, 47, 33, 0.00) 100%);
                        }
                    }
                }

            }

            &-btn{
                width: 540px;
                height: 112px;
                margin-top: 64px;
                z-index: 1;
                position: relative;

            }
        }

        &__list{
            display: grid;
            grid-template-columns: 1fr 284px;
            position: relative;
            grid-gap: 0 100px;
        }

        &__daily{
            &-top{
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 44px;
            }

            &-bottom{
                margin-top: 52px;
                display: flex;
                align-items: center;
                padding: 0 64px;
                justify-content: space-between;
                position: relative;
                height: 243px;

                .circle{
                    width: 120px;
                    height: 120px;
                }

                .btn{
                    width: 310px;
                    height: 112px;
                    font-size: 36px;
                    padding: 0;
                }

                .task-fill{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    right: 0;
                }

                &-info{
                    h4{
                        color: #FFF;
                        font-size: 64px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 100%;
                        letter-spacing: -1.28px;
                        margin-bottom: 16px;
                    }
                    p{
                        color: #27AE60;
                        text-align: center;
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 120%;
                        letter-spacing: 1.92px;
                        text-transform: uppercase;
                    }
                }

                &-left{
                    display: flex;
                    align-items: center;

                    .img{
                        width: 175px;
                        height: 175px;
                        margin: 0 44px;
                    }

                }
            }
            &-item{
                position: relative;
                text-align: center;
                padding: 0px 32px 100px 32px;
                border-radius: 4px;
                background: linear-gradient(180deg, rgba(20, 26, 30, 0.00) 0%, rgba(255, 255, 255, 0.10) 100%);

                img{
                    width: 200px;
                    height: 200px;
                    margin-bottom: 24px;
                }

                h3{
                    color: #B784C9;
                    text-align: center;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    letter-spacing: 1.92px;
                    text-transform: uppercase;
                    margin-bottom: 24px;
                }
                p{
                    color: #F1F1F1;
                    text-align: center;
                    font-size: 36px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                }

                &-complete{
                    width: 76px;
                    height: 60px;
                    display: flex;
                    padding: 8px 16px;
                    align-items: center;
                    justify-content: center;
                    gap: 10px;
                    border-radius: 4px;
                    background: #27AE60;
                    position: absolute;
                    top: 128px;
                    left: 0;
                    right: 0;
                    margin: 0 auto;

                    svg{
                        width: 44px;
                        height: 44px;
                    }
                }

                &.complete{
                    background: linear-gradient(180deg, rgba(39, 174, 96, 0.00) 0%, rgba(39, 174, 96, 0.40) 100%);

                    h3{
                        color: #27AE60;

                    }
                }
            }
        }

        &__wrapper{
            padding: 0 160px 0 128px;
            height: calc(100% - 5vw);
            display: grid;
            grid-template-columns: 480px 1fr;
            grid-gap: 100px;
        }

        &__social {
            display: grid;
            grid-gap: 24px;
            width: 100%;
            margin-top: 50px;
            margin-bottom: 24px;

            &-title{
                color: #F1F1F1;
                text-align: left;
                font-family: DIN Pro;
                font-size: 24px;
                font-weight: 700;
                line-height: 120%; /* 28.8px */
                letter-spacing: 0.96px;
                text-transform: uppercase;
            }

            &-item{
                justify-content: right;
                height: 200px;
                border-radius: 40px;
                overflow: hidden;

                &-discord {
                    background: radial-gradient(149.71% 116.01% at 28.52% 25.64%, rgba(88, 101, 242, 0.30) 0%, rgba(88, 101, 242, 0.00) 100%);

                    h4 {
                        color: #5865F2;
                    }
                }
                &-facebook {
                    background: radial-gradient(149.71% 116.01% at 28.52% 25.64%, rgba(24, 119, 242, 0.30) 0%, rgba(24, 119, 242, 0.00) 100%);

                    h4 {
                        color: #1BB2FF;
                    }
                }
                &-instagram {
                    background: radial-gradient(149.71% 116.01% at 28.52% 25.64%, rgba(99, 28, 249, 0.30) 0%, rgba(205, 0, 198, 0.00) 100%);

                    h4{
                        color: #FA9619;
                    }
                }
                &-telegram {
                    background: radial-gradient(149.71% 116.01% at 28.52% 25.64%, rgba(55, 187, 254, 0.30) 0%, rgba(55, 187, 254, 0.00) 100%);

                    h4{
                        color: #1BB2FF;
                    }
                }
            }

            &-content {
                h4 {
                    font-size: 32px;
                    font-weight: 700;
                    line-height: 120%; /* 38.4px */
                    letter-spacing: 1.92px;
                    text-transform: uppercase;
                    margin-bottom: 12px;
                }
                p{
                    color: #F1F1F1;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 110%; /* 35.2px */
                }
            }
            &-left {
                height: 200px;
                img{
                    height: 100%;
                    margin-right: 24px;
                }
            }

            &-gift {
                display: flex;
                align-items: center;
                color: #F1F1F1;
                font-size: 64px;
                font-weight: 700;
                margin-right: 44px;
                line-height: 100%; /* 64px */
                .plus {
                    width: 29px;
                    margin-right: 12px;
                }
                .donate {
                    width: 64px;
                    right: 12px;
                }
            }

            &-right{
                justify-content: flex-end;
                padding-right: 38px;
            }
            &-item, &-right, &-left {
                display: flex;
                align-items: center;
                width: 100%;
            }
        }
    }
</style>
