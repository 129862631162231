<template>
    <div class="disconnect">
        <div v-if="type === '404'" class="disconnect__error">
            <div class="disconnect__title">{{ $t('errors.disconnect.connection_lost') }}</div>
            <div class="disconnect__subtitle">{{ $t('errors.disconnect.try_reconnect') }}</div>
            <BaseButton white @click="closeGame">{{ $t('errors.disconnect.close_game') }}</BaseButton>
        </div>
        <div v-if="type === 'interaction'" class="disconnect__interaction">
            <div class="disconnect__title disconnect__title">{{ punishmentText }}</div>
            <div class="disconnect__grid">
                <div class="disconnect__item">
                    <div class="disconnect__item-title">{{ offenseAdmin }}</div>
                    <div class="disconnect__item-text">{{ $t('errors.disconnect.admin') }}</div>
                </div>
                <div class="disconnect__item">
                    <div class="disconnect__item-title">{{ playerName }}</div>
                    <div class="disconnect__item-text">{{ $t('errors.disconnect.player') }}</div>
                </div>
                <div class="disconnect__item" v-if = "offenseTimeLeft > 0">
                    <div class="disconnect__item-title">{{ offenseTimeLeft }} {{ $t('errors.disconnect.days') }}</div>
                    <div class="disconnect__item-text">{{ $t('errors.disconnect.till_end') }}</div>
                </div>
                <div class="disconnect__item">
                    <div class="disconnect__item-title">{{ offenseDate }}</div>
                    <div class="disconnect__item-text">{{ $t('errors.disconnect.created') }}</div>
                </div>
            </div>
            <BaseButton v-if = "needCloseGameButton" white @click="closeGame">{{ $t('errors.disconnect.close_game') }}</BaseButton>
        </div>
    </div>
</template>

<script>
import BaseButton from '@/components/ui/BaseButton.vue';

export default {
    name: 'ErrorDisconnect',
    components: {
        BaseButton
    },
    data () {
        return {
            type: '404', // '404', 'interaction'
            offenseDate: '14.12.2021 17:23',
            offenseAdmin: 'Andrew Rikimaru',
            punishmentText: '',
            buttonDisplay: false,
            offenseTimeLeft: 14 // В днях
        };
    },
    computed: {
        playerName () {
            return this.$store.state.player.playerName;
        }
    },
    methods: {
        /*
        window.modifyVueComponent({
            App: {
                showError: true,
                techPopup : 'ErrorDisconnect'
            },
            ErrorDisconnect : {
                initialize: [
                    'interaction',
                    'Вы были заблокированы',
                    '07.06.2023',
                    'Napal Fedorov',
                    20
                ]
            }
        });
        */
        initialize (type, punishmentText, date = '', admin = '', timeLeft = '', buttonDisplay = false) {
            this.type = type;
            if (this.type !== '404') {
                this.punishmentText = punishmentText;
                this.offenseDate = date;
                this.offenseAdmin = admin;
                this.offenseTimeLeft = timeLeft;
                this.needCloseGameButton = buttonDisplay;
            }
        },
        closeGame () {
            this.sendclient('closeGame');
        }
    }
};
</script>

<style lang="scss" scoped>
.disconnect {
    height: 100%;
    &__error {
        height: 100%;
        background: url("@/assets/images/errors/404grid.svg") center/cover no-repeat, url("@/assets/images/errors/404sharp.png") center/cover no-repeat, #000;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &__interaction {
        background: url("@/assets/images/errors/interactionbg.svg") center/cover no-repeat, radial-gradient(104.63% 50% at 50% -0.00%, rgba(219, 99, 61, 0.90) 0%, rgba(133, 21, 14, 0.90) 100%);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &__title {
        color: var(--white);
        text-align: center;
        font-size: 90px;
        font-weight: 300;
        line-height: 120%;
    }
    &__subtitle {
        color: rgba(241, 241, 241, 0.70);
        text-align: center;
        font-size: 44px;
        font-weight: 400;
        line-height: 120%;
        margin: 32px 0 128px;
    }
    &__grid {
        display: grid;
        margin: 128px 0;
        justify-items: center;
        text-align: center;
        width: 1764px;
        grid-template-columns: 1fr 1fr;
        grid-gap: 80px 32px;
    }
    &__item {
        &-title {
            color: var(--white);
            font-size: 64px;
            font-weight: 400;
            line-height: 120%;
            margin-bottom: 6px;
        }
        &-text {
            color: rgba(241, 241, 241, 0.70);
            font-size: 44px;
            font-weight: 400;
            line-height: 120%;
        }
    }
}
</style>
