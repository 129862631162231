<template>
    <div class="estate__menu-icon-container">
        <svg v-if="icon === 'business'" width="44" height="45" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5 5.89844C14.4749 5.89844 12.8333 7.54006 12.8333 9.5651V13.2318H5.49996C4.48744 13.2318 3.66663 14.0526 3.66663 15.0651V37.0651C3.66663 38.0776 4.48744 38.8984 5.49996 38.8984H38.5C39.5125 38.8984 40.3333 38.0776 40.3333 37.0651V15.0651C40.3333 14.0526 39.5125 13.2318 38.5 13.2318H31.1666V9.5651C31.1666 7.54006 29.525 5.89844 27.5 5.89844H16.5ZM27.5 13.2318V9.5651H16.5V13.2318H27.5ZM7.33329 35.2318V16.8984H36.6666V35.2318H7.33329Z" fill="#F1F1F1"/>
        </svg>
        <svg v-if="icon === 'miniBusiness'"  width="44" height="45" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.6667 22.1979H29.3334V25.8646H25.6667V22.1979Z" fill="#F1F1F1"/>
            <path d="M29.3334 14.8646H25.6667V18.5313H29.3334V14.8646Z" fill="#F1F1F1"/>
            <path d="M14.6667 22.1979H11.0001V25.8646H14.6667V22.1979Z" fill="#F1F1F1"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3334 9.36458C18.3334 8.35208 19.1542 7.53125 20.1667 7.53125H38.5001C39.5126 7.53125 40.3334 8.35208 40.3334 9.36458V35.0313C40.3334 36.0438 39.5126 36.8646 38.5001 36.8646H5.50008C4.48757 36.8646 3.66675 36.0438 3.66675 35.0313V16.6979C3.66675 15.6854 4.48757 14.8646 5.50008 14.8646H18.3334V9.36458ZM25.6667 33.1979H22.0001V11.1979H36.6667V14.8646H33.0001V18.5313H36.6667V22.1979H33.0001V25.8646H36.6667V33.1979H29.3334V29.5313H25.6667V33.1979ZM7.33341 18.5313H18.3334V33.1979H14.6667V29.5313H11.0001V33.1979H7.33341V18.5313Z" fill="#F1F1F1"/>
        </svg>
        <svg v-if="icon === 'house'" width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.1667 25.668H16.5V31.168H20.1667V25.668Z" fill="#F1F1F1"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.7551 4.15544C21.4577 3.50548 22.5423 3.50548 23.2449 4.15544L37.9116 17.7221C38.2867 18.0691 38.5 18.557 38.5 19.0679V22.0013C38.5 23.0138 37.6792 23.8346 36.6667 23.8346H34.8333V36.668C34.8333 37.6805 34.0125 38.5013 33 38.5013H11C9.98749 38.5013 9.16667 37.6805 9.16667 36.668V23.8346H7.33333C6.84708 23.8346 6.3808 23.6415 6.037 23.2976C5.69314 22.9538 5.5 22.4875 5.5 22.0013V19.0679C5.5 18.557 5.71328 18.0691 6.08841 17.7221L20.7551 4.15544ZM9.16667 20.168H12.8333V34.8346H23.8333V23.8346H27.5V34.8346H31.1667V20.168H34.8333V19.8695L22 7.99868L9.16667 19.8695V20.168Z" fill="#F1F1F1"/>
        </svg>
    </div>
</template>
<script>
export default {
    props: {
        icon: String
    }
};
</script>
