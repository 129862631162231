import Vue from 'vue';
import Vuex from 'vuex';
import vehicles from './modules/vehicles';
import player from './modules/player';
import inventory from './modules/inventory';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        vehicles,
        inventory,
        player
    }
});
