<template>
    <div class="prison">
        <p>{{ $t('prison.arrest') }}</p>
        <div class="prison__name">{{ prison.nickname }}</div>
        <div class="prison__time">{{ formattedTime }}</div>
        <p>{{ $t('prison.time_left') }}</p>
        <div class="prison__rules" v-if = "prison.rules && prison.rules.length > 0">{{ prison.rules }}</div>
    </div>
</template>

<script>
export default {
    name: 'InPrison',
    props: {
        prison: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            timeRemaining: this.prison.time,
            isPrison: true
        };
    },
    computed: {
        formattedTime () {
            const minutes = Math.floor(this.timeRemaining / 60);
            const seconds = this.timeRemaining % 60;
            const formattedMinutes = String(minutes).padStart(2, '0');
            const formattedSeconds = String(seconds).padStart(2, '0');
            return `${formattedMinutes}:${formattedSeconds}`;
        }
    },
    mounted () {
        this.startCountdown();
    },
    methods: {
        startCountdown () {
            const timer = setInterval(() => {
                this.timeRemaining--;
                if (this.timeRemaining === 0) {
                    clearInterval(timer);
                    this.isPrison = false;
                }
            }, 1000);
        }
    }
};
</script>

<style lang="scss" scoped>
.prison{
    max-width: 35vw;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    padding: 1vw;
    &::after{
        background: url(@/assets/images/hud/prison.png) no-repeat center/cover;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0.7;
    }

    p{
        font-weight: 700;
        text-align: center;
        font-size: 1.2vw;
        line-height: 120%;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        color: rgba(241, 241, 241, 0.7);
    }

    &__name{
        color: #F1F1F1;
        font-weight: 700;
        text-align: center;
        font-size: 1.2vw;
        line-height: 120%;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        margin: .2vw 0;
    }

    &__time{
        font-weight: 900;
        font-size: 3vw;
        line-height: 100%;
        margin: .5vw 0;
        text-align: center;
        text-transform: uppercase;

        color: #F1F1F1;

        opacity: 0.96;
    }
    &__rules{
        font-weight: 500;
        font-size: 1.6vw;
        line-height: 100%;

        text-align: center;
        text-transform: uppercase;

        color: #F1F1F1;
        margin-top: 1vw;
    }

    *{
        font-family: var(--font-stretch);
    }
}
</style>
