const state = () => ({
    inventoryItemsPool: [
        { id: 1, name: 'inventory.medical_kit', rarity: 'ordinary', type: 3, filterType: 'food', desc: 'inventory.desc.medical_kit', img: 'other_items/first_kit' },
        { id: 2, name: 'inventory.assault_rifle', rarity: 'unusual', type: 1, filterType: 'weapon', desc: 'inventory.desc.weapon', img: 'other_items/m4' },
        { id: 3, name: 'inventory.pistol', rarity: 'unusual', type: 1, filterType: 'weapon', desc: 'inventory.desc.weapon', img: 'other_items/pistol' },
        { id: 4, name: 'inventory.outer_clothing', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 1, gender: 0 },
        { id: 5, name: 'inventory.outer_clothing', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 2, gender: 0 },
        { id: 6, name: 'inventory.outer_clothing', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 3, gender: 0 },
        { id: 7, name: 'inventory.outer_clothing', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 4, gender: 0 },
        { id: 8, name: 'inventory.pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 1, gender: 0 },
        { id: 9, name: 'inventory.pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 2, gender: 0 },
        { id: 10, name: 'inventory.shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 1, gender: 0 },
        { id: 11, name: 'inventory.shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 2, gender: 0 },
        { id: 12, name: 'inventory.shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 3, gender: 0 },
        { id: 13, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 1, gender: 1 },
        { id: 14, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 1, gender: 1 },
        { id: 15, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 1, gender: 1 },
        { id: 16, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 2, gender: 1 },
        { id: 17, name: 'inventory.outer_clothing', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 5, gender: 0 },
        { id: 18, name: 'inventory.outer_clothing', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 6, gender: 0 },
        { id: 19, name: 'inventory.outer_clothing', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 7, gender: 0 },
        { id: 20, name: 'inventory.shotgun', rarity: 'unusual', type: 1, filterType: 'weapon', desc: 'inventory.desc.firearms', img: 'other_items/shotgun' },
        { id: 21, name: 'inventory.repair_kit', rarity: 'ordinary', type: 0, filterType: 'other', desc: 'inventory.desc.repair_kit', img: 'other_items/repair-box' },
        { id: 22, name: 'inventory.canister', rarity: 'ordinary', type: 0, filterType: 'other', desc: 'inventory.desc.canister', img: 'other_items/canister' },
        { id: 23, name: 'inventory.spray_paint', rarity: 'ordinary', type: 0, filterType: 'other', desc: 'inventory.desc.spray_paint', img: 'other_items/spraycan' },
        { id: 24, name: 'inventory.stone', rarity: 'ordinary', type: 0, filterType: 'other', desc: 'inventory.desc.other_items_stone', img: 'other_items/ore' },
        { id: 25, name: 'inventory.nails', rarity: 'ordinary', type: 0, filterType: 'other', desc: 'inventory.desc.other_items_nails', img: 'other_items/nails' },
        { id: 26, name: 'inventory.seeds', rarity: 'ordinary', type: 0, filterType: 'other', desc: 'inventory.desc.other_items_seeds', img: 'other_items/seeds' },
        { id: 27, name: 'inventory.sand', rarity: 'ordinary', type: 0, filterType: 'other', desc: 'inventory.desc.other_items_sand', img: 'other_items/sand' },
        { id: 28, name: 'inventory.timbers', rarity: 'ordinary', type: 0, filterType: 'other', desc: 'inventory.desc.other_items_timbers', img: 'other_items/logs' },
        { id: 29, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 8, gender: 0 },
        { id: 30, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 9, gender: 0 },
        { id: 31, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 10, gender: 0 },
        { id: 32, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 11, gender: 0 },
        { id: 33, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 12, gender: 0 },
        { id: 34, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 13, gender: 0 },
        { id: 35, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 14, gender: 0 },
        { id: 36, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 15, gender: 0 },
        { id: 37, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 16, gender: 0 },
        { id: 38, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 17, gender: 0 },
        { id: 39, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 18, gender: 0 },
        { id: 40, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 19, gender: 0 },
        { id: 41, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 20, gender: 0 },
        { id: 42, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 21, gender: 0 },
        { id: 43, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 22, gender: 0 },
        { id: 44, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 23, gender: 0 },
        { id: 45, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 24, gender: 0 },
        { id: 46, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 25, gender: 0 },
        { id: 47, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 26, gender: 0 },
        { id: 48, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 27, gender: 0 },
        { id: 49, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 28, gender: 0 },
        { id: 50, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 29, gender: 0 },
        { id: 51, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 30, gender: 0 },
        { id: 52, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 31, gender: 0 },
        { id: 53, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 32, gender: 0 },
        { id: 54, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 33, gender: 0 },
        { id: 55, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 34, gender: 0 },
        { id: 56, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 35, gender: 0 },
        { id: 57, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 36, gender: 0 },
        { id: 58, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 37, gender: 0 },
        { id: 59, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 38, gender: 0 },
        { id: 60, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 39, gender: 0 },
        { id: 61, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 40, gender: 0 },
        { id: 62, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 41, gender: 0 },
        { id: 63, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 42, gender: 0 },
        { id: 64, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 43, gender: 0 },
        { id: 65, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 44, gender: 0 },
        { id: 66, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 45, gender: 0 },
        { id: 67, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 46, gender: 0 },
        { id: 68, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 47, gender: 0 },
        { id: 69, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 48, gender: 0 },
        { id: 70, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 49, gender: 0 },
        { id: 71, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 50, gender: 0 },
        { id: 72, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 51, gender: 0 },
        { id: 73, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 52, gender: 0 },
        { id: 74, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 53, gender: 0 },
        { id: 75, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 54, gender: 0 },
        { id: 76, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 55, gender: 0 },
        { id: 77, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 56, gender: 0 },
        { id: 78, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 57, gender: 0 },
        { id: 79, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 58, gender: 0 },
        { id: 80, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 59, gender: 0 },
        { id: 81, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 60, gender: 0 },
        { id: 82, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 61, gender: 0 },
        { id: 83, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 62, gender: 0 },
        { id: 84, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 63, gender: 0 },
        { id: 85, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 64, gender: 0 },
        { id: 86, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 65, gender: 0 },
        { id: 87, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 66, gender: 0 },
        { id: 88, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 67, gender: 0 },
        { id: 89, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 68, gender: 0 },
        { id: 90, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 69, gender: 0 },
        { id: 91, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 70, gender: 0 },
        { id: 92, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 71, gender: 0 },
        { id: 93, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 72, gender: 0 },
        { id: 94, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 73, gender: 0 },
        { id: 95, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 74, gender: 0 },
        { id: 96, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 75, gender: 0 },
        { id: 97, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 76, gender: 0 },
        { id: 98, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 77, gender: 0 },
        { id: 99, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 78, gender: 0 },
        { id: 100, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 79, gender: 0 },
        { id: 101, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 80, gender: 0 },
        { id: 102, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 81, gender: 0 },
        { id: 103, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 82, gender: 0 },
        { id: 104, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 83, gender: 0 },
        { id: 105, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 84, gender: 0 },
        { id: 106, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 85, gender: 0 },
        { id: 107, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 86, gender: 0 },
        { id: 108, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 87, gender: 0 },
        { id: 109, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 88, gender: 0 },
        { id: 110, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 89, gender: 0 },
        { id: 111, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 90, gender: 0 },
        { id: 112, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 91, gender: 0 },
        { id: 113, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 92, gender: 0 },
        { id: 114, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 93, gender: 0 },
        { id: 115, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 94, gender: 0 },
        { id: 116, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 95, gender: 0 },
        { id: 117, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 96, gender: 0 },
        { id: 118, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 97, gender: 0 },
        { id: 119, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 98, gender: 0 },
        { id: 120, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 99, gender: 0 },
        { id: 121, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 100, gender: 0 },
        { id: 122, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 101, gender: 0 },
        { id: 123, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 102, gender: 0 },
        { id: 124, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 103, gender: 0 },
        { id: 125, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 104, gender: 0 },
        { id: 126, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 105, gender: 0 },
        { id: 127, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 106, gender: 0 },
        { id: 128, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 107, gender: 0 },
        { id: 129, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 108, gender: 0 },
        { id: 130, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 109, gender: 0 },
        { id: 131, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 110, gender: 0 },
        { id: 132, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 111, gender: 0 },
        { id: 133, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 112, gender: 0 },
        { id: 134, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 113, gender: 0 },
        { id: 135, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 114, gender: 0 },
        { id: 136, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 115, gender: 0 },
        { id: 137, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 116, gender: 0 },
        { id: 138, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 117, gender: 0 },
        { id: 139, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 118, gender: 0 },
        { id: 140, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 119, gender: 0 },
        { id: 141, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 120, gender: 0 },
        { id: 142, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 121, gender: 0 },
        { id: 143, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 122, gender: 0 },
        { id: 144, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 123, gender: 0 },
        { id: 145, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 124, gender: 0 },
        { id: 146, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 125, gender: 0 },
        { id: 147, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 126, gender: 0 },
        { id: 148, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 127, gender: 0 },
        { id: 149, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 128, gender: 0 },
        { id: 150, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 129, gender: 0 },
        { id: 151, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 130, gender: 0 },
        { id: 152, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 131, gender: 0 },
        { id: 153, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 132, gender: 0 },
        { id: 154, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 133, gender: 0 },
        { id: 155, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 134, gender: 0 },
        { id: 156, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 135, gender: 0 },
        { id: 157, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 136, gender: 0 },
        { id: 158, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 137, gender: 0 },
        { id: 159, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 138, gender: 0 },
        { id: 160, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 139, gender: 0 },
        { id: 161, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 140, gender: 0 },
        { id: 162, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 141, gender: 0 },
        { id: 163, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 142, gender: 0 },
        { id: 164, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 143, gender: 0 },
        { id: 165, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 144, gender: 0 },
        { id: 166, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 145, gender: 0 },
        { id: 167, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 146, gender: 0 },
        { id: 168, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 147, gender: 0 },
        { id: 169, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 148, gender: 0 },
        { id: 170, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 149, gender: 0 },
        { id: 171, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 150, gender: 0 },
        { id: 172, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 2, gender: 1 },
        { id: 173, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 3, gender: 1 },
        { id: 174, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 4, gender: 1 },
        { id: 175, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 5, gender: 1 },
        { id: 176, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 6, gender: 1 },
        { id: 177, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 7, gender: 1 },
        { id: 178, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 8, gender: 1 },
        { id: 179, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 9, gender: 1 },
        { id: 180, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 10, gender: 1 },
        { id: 181, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 11, gender: 1 },
        { id: 182, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 12, gender: 1 },
        { id: 183, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 13, gender: 1 },
        { id: 184, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 14, gender: 1 },
        { id: 185, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 15, gender: 1 },
        { id: 186, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 16, gender: 1 },
        { id: 187, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 17, gender: 1 },
        { id: 188, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 18, gender: 1 },
        { id: 189, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 19, gender: 1 },
        { id: 190, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 20, gender: 1 },
        { id: 191, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 21, gender: 1 },
        { id: 192, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 22, gender: 1 },
        { id: 193, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 23, gender: 1 },
        { id: 194, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 24, gender: 1 },
        { id: 195, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 25, gender: 1 },
        { id: 196, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 26, gender: 1 },
        { id: 197, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 27, gender: 1 },
        { id: 198, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 28, gender: 1 },
        { id: 199, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 29, gender: 1 },
        { id: 200, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 30, gender: 1 },
        { id: 201, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 31, gender: 1 },
        { id: 202, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 32, gender: 1 },
        { id: 203, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 33, gender: 1 },
        { id: 204, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 34, gender: 1 },
        { id: 205, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 35, gender: 1 },
        { id: 206, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 36, gender: 1 },
        { id: 207, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 37, gender: 1 },
        { id: 208, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 38, gender: 1 },
        { id: 209, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 39, gender: 1 },
        { id: 210, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 40, gender: 1 },
        { id: 211, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 41, gender: 1 },
        { id: 212, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 42, gender: 1 },
        { id: 213, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 43, gender: 1 },
        { id: 214, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 44, gender: 1 },
        { id: 215, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 45, gender: 1 },
        { id: 216, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 46, gender: 1 },
        { id: 217, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 47, gender: 1 },
        { id: 218, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 48, gender: 1 },
        { id: 219, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 49, gender: 1 },
        { id: 220, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 50, gender: 1 },
        { id: 221, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 51, gender: 1 },
        { id: 222, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 52, gender: 1 },
        { id: 223, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 53, gender: 1 },
        { id: 224, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 54, gender: 1 },
        { id: 225, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 55, gender: 1 },
        { id: 226, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 56, gender: 1 },
        { id: 227, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 57, gender: 1 },
        { id: 228, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 58, gender: 1 },
        { id: 229, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 59, gender: 1 },
        { id: 230, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 60, gender: 1 },
        { id: 231, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 61, gender: 1 },
        { id: 232, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 62, gender: 1 },
        { id: 233, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 63, gender: 1 },
        { id: 234, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 64, gender: 1 },
        { id: 235, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 65, gender: 1 },
        { id: 236, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 66, gender: 1 },
        { id: 237, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 67, gender: 1 },
        { id: 238, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 68, gender: 1 },
        { id: 239, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 69, gender: 1 },
        { id: 240, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 70, gender: 1 },
        { id: 241, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 71, gender: 1 },
        { id: 242, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 72, gender: 1 },
        { id: 243, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 73, gender: 1 },
        { id: 244, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 74, gender: 1 },
        { id: 245, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 75, gender: 1 },
        { id: 246, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 76, gender: 1 },
        { id: 247, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 77, gender: 1 },
        { id: 248, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 78, gender: 1 },
        { id: 249, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 79, gender: 1 },
        { id: 250, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 80, gender: 1 },
        { id: 251, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 81, gender: 1 },
        { id: 252, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 82, gender: 1 },
        { id: 253, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 83, gender: 1 },
        { id: 254, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 84, gender: 1 },
        { id: 255, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 85, gender: 1 },
        { id: 256, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 86, gender: 1 },
        { id: 257, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 87, gender: 1 },
        { id: 258, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 88, gender: 1 },
        { id: 259, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 89, gender: 1 },
        { id: 260, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 90, gender: 1 },
        { id: 261, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 91, gender: 1 },
        { id: 262, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 92, gender: 1 },
        { id: 263, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 93, gender: 1 },
        { id: 264, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 94, gender: 1 },
        { id: 265, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 95, gender: 1 },
        { id: 266, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 96, gender: 1 },
        { id: 267, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 97, gender: 1 },
        { id: 268, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 98, gender: 1 },
        { id: 269, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 99, gender: 1 },
        { id: 270, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 100, gender: 1 },
        { id: 271, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 101, gender: 1 },
        { id: 272, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 102, gender: 1 },
        { id: 273, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 103, gender: 1 },
        { id: 274, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 104, gender: 1 },
        { id: 275, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 105, gender: 1 },
        { id: 276, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 106, gender: 1 },
        { id: 277, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 107, gender: 1 },
        { id: 278, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 108, gender: 1 },
        { id: 279, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 109, gender: 1 },
        { id: 280, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 110, gender: 1 },
        { id: 281, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 111, gender: 1 },
        { id: 282, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 112, gender: 1 },
        { id: 283, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 113, gender: 1 },
        { id: 284, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 114, gender: 1 },
        { id: 285, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 115, gender: 1 },
        { id: 286, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 116, gender: 1 },
        { id: 287, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 117, gender: 1 },
        { id: 288, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 118, gender: 1 },
        { id: 289, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 119, gender: 1 },
        { id: 290, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 120, gender: 1 },
        { id: 291, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 121, gender: 1 },
        { id: 292, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 122, gender: 1 },
        { id: 293, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 123, gender: 1 },
        { id: 294, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 124, gender: 1 },
        { id: 295, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 125, gender: 1 },
        { id: 296, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 126, gender: 1 },
        { id: 297, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 127, gender: 1 },
        { id: 298, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 128, gender: 1 },
        { id: 299, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 129, gender: 1 },
        { id: 300, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 130, gender: 1 },
        { id: 301, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 131, gender: 1 },
        { id: 302, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 132, gender: 1 },
        { id: 303, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 133, gender: 1 },
        { id: 304, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 134, gender: 1 },
        { id: 305, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 135, gender: 1 },
        { id: 306, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 136, gender: 1 },
        { id: 307, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 137, gender: 1 },
        { id: 308, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 138, gender: 1 },
        { id: 309, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 139, gender: 1 },
        { id: 310, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 140, gender: 1 },
        { id: 311, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 141, gender: 1 },
        { id: 312, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 142, gender: 1 },
        { id: 313, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 143, gender: 1 },
        { id: 314, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 144, gender: 1 },
        { id: 315, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 145, gender: 1 },
        { id: 316, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 146, gender: 1 },
        { id: 317, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 147, gender: 1 },
        { id: 318, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 148, gender: 1 },
        { id: 319, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 149, gender: 1 },
        { id: 320, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 150, gender: 1 },
        { id: 321, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 3, gender: 0 },
        { id: 322, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 4, gender: 0 },
        { id: 323, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 5, gender: 0 },
        { id: 324, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 6, gender: 0 },
        { id: 325, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 7, gender: 0 },
        { id: 326, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 8, gender: 0 },
        { id: 327, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 9, gender: 0 },
        { id: 328, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 10, gender: 0 },
        { id: 329, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 11, gender: 0 },
        { id: 330, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 12, gender: 0 },
        { id: 331, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 13, gender: 0 },
        { id: 332, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 14, gender: 0 },
        { id: 333, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 15, gender: 0 },
        { id: 334, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 16, gender: 0 },
        { id: 335, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 17, gender: 0 },
        { id: 336, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 18, gender: 0 },
        { id: 337, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 19, gender: 0 },
        { id: 338, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 20, gender: 0 },
        { id: 339, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 21, gender: 0 },
        { id: 340, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 22, gender: 0 },
        { id: 341, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 23, gender: 0 },
        { id: 342, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 24, gender: 0 },
        { id: 343, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 25, gender: 0 },
        { id: 344, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 26, gender: 0 },
        { id: 345, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 27, gender: 0 },
        { id: 346, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 28, gender: 0 },
        { id: 347, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 29, gender: 0 },
        { id: 348, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 30, gender: 0 },
        { id: 349, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 31, gender: 0 },
        { id: 350, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 32, gender: 0 },
        { id: 351, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 33, gender: 0 },
        { id: 352, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 34, gender: 0 },
        { id: 353, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 35, gender: 0 },
        { id: 354, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 36, gender: 0 },
        { id: 355, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 37, gender: 0 },
        { id: 356, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 38, gender: 0 },
        { id: 357, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 39, gender: 0 },
        { id: 358, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 40, gender: 0 },
        { id: 359, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 41, gender: 0 },
        { id: 360, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 42, gender: 0 },
        { id: 361, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 43, gender: 0 },
        { id: 362, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 44, gender: 0 },
        { id: 363, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 45, gender: 0 },
        { id: 364, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 46, gender: 0 },
        { id: 365, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 47, gender: 0 },
        { id: 366, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 48, gender: 0 },
        { id: 367, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 49, gender: 0 },
        { id: 368, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 50, gender: 0 },
        { id: 369, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 2, gender: 1 },
        { id: 370, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 3, gender: 1 },
        { id: 371, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 4, gender: 1 },
        { id: 372, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 5, gender: 1 },
        { id: 373, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 6, gender: 1 },
        { id: 374, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 7, gender: 1 },
        { id: 375, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 8, gender: 1 },
        { id: 376, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 9, gender: 1 },
        { id: 377, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 10, gender: 1 },
        { id: 378, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 11, gender: 1 },
        { id: 379, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 12, gender: 1 },
        { id: 380, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 13, gender: 1 },
        { id: 381, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 14, gender: 1 },
        { id: 382, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 15, gender: 1 },
        { id: 383, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 16, gender: 1 },
        { id: 384, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 17, gender: 1 },
        { id: 385, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 18, gender: 1 },
        { id: 386, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 19, gender: 1 },
        { id: 387, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 20, gender: 1 },
        { id: 388, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 21, gender: 1 },
        { id: 389, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 22, gender: 1 },
        { id: 390, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 23, gender: 1 },
        { id: 391, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 24, gender: 1 },
        { id: 392, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 25, gender: 1 },
        { id: 393, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 26, gender: 1 },
        { id: 394, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 27, gender: 1 },
        { id: 395, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 28, gender: 1 },
        { id: 396, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 29, gender: 1 },
        { id: 397, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 30, gender: 1 },
        { id: 398, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 31, gender: 1 },
        { id: 399, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 32, gender: 1 },
        { id: 400, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 33, gender: 1 },
        { id: 401, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 34, gender: 1 },
        { id: 402, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 35, gender: 1 },
        { id: 403, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 36, gender: 1 },
        { id: 404, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 37, gender: 1 },
        { id: 405, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 38, gender: 1 },
        { id: 406, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 39, gender: 1 },
        { id: 407, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 40, gender: 1 },
        { id: 408, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 41, gender: 1 },
        { id: 409, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 42, gender: 1 },
        { id: 410, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 43, gender: 1 },
        { id: 411, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 44, gender: 1 },
        { id: 412, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 45, gender: 1 },
        { id: 413, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 46, gender: 1 },
        { id: 414, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 47, gender: 1 },
        { id: 415, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 48, gender: 1 },
        { id: 416, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 49, gender: 1 },
        { id: 417, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 50, gender: 1 },
        { id: 418, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 4, gender: 0 },
        { id: 419, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 5, gender: 0 },
        { id: 420, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 6, gender: 0 },
        { id: 421, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 7, gender: 0 },
        { id: 422, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 8, gender: 0 },
        { id: 423, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 9, gender: 0 },
        { id: 424, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 10, gender: 0 },
        { id: 425, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 11, gender: 0 },
        { id: 426, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 12, gender: 0 },
        { id: 427, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 13, gender: 0 },
        { id: 428, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 14, gender: 0 },
        { id: 429, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 15, gender: 0 },
        { id: 430, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 16, gender: 0 },
        { id: 431, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 17, gender: 0 },
        { id: 432, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 18, gender: 0 },
        { id: 433, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 19, gender: 0 },
        { id: 434, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 20, gender: 0 },
        { id: 435, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 21, gender: 0 },
        { id: 436, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 22, gender: 0 },
        { id: 437, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 23, gender: 0 },
        { id: 438, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 24, gender: 0 },
        { id: 439, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 25, gender: 0 },
        { id: 440, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 26, gender: 0 },
        { id: 441, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 27, gender: 0 },
        { id: 442, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 28, gender: 0 },
        { id: 443, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 29, gender: 0 },
        { id: 444, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 30, gender: 0 },
        { id: 445, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 31, gender: 0 },
        { id: 446, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 32, gender: 0 },
        { id: 447, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 33, gender: 0 },
        { id: 448, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 34, gender: 0 },
        { id: 449, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 35, gender: 0 },
        { id: 450, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 36, gender: 0 },
        { id: 451, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 37, gender: 0 },
        { id: 452, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 38, gender: 0 },
        { id: 453, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 39, gender: 0 },
        { id: 454, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 40, gender: 0 },
        { id: 455, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 41, gender: 0 },
        { id: 456, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 42, gender: 0 },
        { id: 457, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 43, gender: 0 },
        { id: 458, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 44, gender: 0 },
        { id: 459, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 45, gender: 0 },
        { id: 460, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 46, gender: 0 },
        { id: 461, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 47, gender: 0 },
        { id: 462, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 48, gender: 0 },
        { id: 463, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 49, gender: 0 },
        { id: 464, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 50, gender: 0 },
        { id: 465, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 3, gender: 1 },
        { id: 466, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 4, gender: 1 },
        { id: 467, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 5, gender: 1 },
        { id: 468, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 6, gender: 1 },
        { id: 469, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 7, gender: 1 },
        { id: 470, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 8, gender: 1 },
        { id: 471, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 9, gender: 1 },
        { id: 472, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 10, gender: 1 },
        { id: 473, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 11, gender: 1 },
        { id: 474, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 12, gender: 1 },
        { id: 475, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 13, gender: 1 },
        { id: 476, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 14, gender: 1 },
        { id: 477, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 15, gender: 1 },
        { id: 478, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 16, gender: 1 },
        { id: 479, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 17, gender: 1 },
        { id: 480, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 18, gender: 1 },
        { id: 481, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 19, gender: 1 },
        { id: 482, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 20, gender: 1 },
        { id: 483, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 21, gender: 1 },
        { id: 484, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 22, gender: 1 },
        { id: 485, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 23, gender: 1 },
        { id: 486, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 24, gender: 1 },
        { id: 487, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 25, gender: 1 },
        { id: 488, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 26, gender: 1 },
        { id: 489, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 27, gender: 1 },
        { id: 490, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 28, gender: 1 },
        { id: 491, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 29, gender: 1 },
        { id: 492, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 30, gender: 1 },
        { id: 493, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 31, gender: 1 },
        { id: 494, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 32, gender: 1 },
        { id: 495, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 33, gender: 1 },
        { id: 496, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 34, gender: 1 },
        { id: 497, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 35, gender: 1 },
        { id: 498, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 36, gender: 1 },
        { id: 499, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 37, gender: 1 },
        { id: 500, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 38, gender: 1 },
        { id: 501, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 39, gender: 1 },
        { id: 502, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 40, gender: 1 },
        { id: 503, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 41, gender: 1 },
        { id: 504, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 42, gender: 1 },
        { id: 505, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 43, gender: 1 },
        { id: 506, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 44, gender: 1 },
        { id: 507, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 45, gender: 1 },
        { id: 508, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 46, gender: 1 },
        { id: 509, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 47, gender: 1 },
        { id: 510, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 48, gender: 1 },
        { id: 511, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 49, gender: 1 },
        { id: 512, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 50, gender: 1 },
        { id: 513, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 51, gender: 1 },
        { id: 514, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 52, gender: 1 },
        { id: 515, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 1, gender: 2 },
        { id: 516, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 2, gender: 2 },
        { id: 517, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 3, gender: 2 },
        { id: 518, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 4, gender: 2 },
        { id: 519, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 5, gender: 2 },
        { id: 520, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 6, gender: 2 },
        { id: 521, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 7, gender: 2 },
        { id: 522, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 8, gender: 2 },
        { id: 523, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 9, gender: 2 },
        { id: 524, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 10, gender: 2 },
        { id: 525, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 11, gender: 2 },
        { id: 526, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 12, gender: 2 },
        { id: 527, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 13, gender: 2 },
        { id: 528, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 14, gender: 2 },
        { id: 529, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 15, gender: 2 },
        { id: 530, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 16, gender: 2 },
        { id: 531, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 17, gender: 2 },
        { id: 532, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 18, gender: 2 },
        { id: 533, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 19, gender: 2 },
        { id: 534, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 20, gender: 2 },
        { id: 535, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 21, gender: 2 },
        { id: 536, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 22, gender: 2 },
        { id: 537, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 23, gender: 2 },
        { id: 538, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 24, gender: 2 },
        { id: 539, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 25, gender: 2 },
        { id: 540, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 26, gender: 2 },
        { id: 541, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 27, gender: 2 },
        { id: 542, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 28, gender: 2 },
        { id: 543, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 29, gender: 2 },
        { id: 544, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 30, gender: 2 },
        { id: 545, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 31, gender: 2 },
        { id: 546, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 32, gender: 2 },
        { id: 547, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 33, gender: 2 },
        { id: 548, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 34, gender: 2 },
        { id: 549, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 35, gender: 2 },
        { id: 550, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 36, gender: 2 },
        { id: 551, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 37, gender: 2 },
        { id: 552, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 38, gender: 2 },
        { id: 553, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 39, gender: 2 },
        { id: 554, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 40, gender: 2 },
        { id: 555, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 41, gender: 2 },
        { id: 556, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 42, gender: 2 },
        { id: 557, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 43, gender: 2 },
        { id: 558, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 44, gender: 2 },
        { id: 559, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 45, gender: 2 },
        { id: 560, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 46, gender: 2 },
        { id: 561, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 47, gender: 2 },
        { id: 562, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 48, gender: 2 },
        { id: 563, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 49, gender: 2 },
        { id: 564, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 50, gender: 2 },
        { id: 565, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 1, gender: 2 },
        { id: 566, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 2, gender: 2 },
        { id: 567, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 3, gender: 2 },
        { id: 568, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 4, gender: 2 },
        { id: 569, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 5, gender: 2 },
        { id: 570, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 6, gender: 2 },
        { id: 571, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 7, gender: 2 },
        { id: 572, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 8, gender: 2 },
        { id: 573, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 9, gender: 2 },
        { id: 574, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 10, gender: 2 },
        { id: 575, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 11, gender: 2 },
        { id: 576, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 12, gender: 2 },
        { id: 577, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 13, gender: 2 },
        { id: 578, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 14, gender: 2 },
        { id: 579, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 15, gender: 2 },
        { id: 580, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 16, gender: 2 },
        { id: 581, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 17, gender: 2 },
        { id: 582, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 18, gender: 2 },
        { id: 583, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 19, gender: 2 },
        { id: 584, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 20, gender: 2 },
        { id: 585, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 21, gender: 2 },
        { id: 586, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 22, gender: 2 },
        { id: 587, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 23, gender: 2 },
        { id: 588, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 24, gender: 2 },
        { id: 589, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 25, gender: 2 },
        { id: 590, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 26, gender: 2 },
        { id: 591, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 27, gender: 2 },
        { id: 592, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 28, gender: 2 },
        { id: 593, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 29, gender: 2 },
        { id: 594, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 30, gender: 2 },
        { id: 595, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 31, gender: 2 },
        { id: 596, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 32, gender: 2 },
        { id: 597, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 33, gender: 2 },
        { id: 598, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 34, gender: 2 },
        { id: 599, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 35, gender: 2 },
        { id: 600, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 36, gender: 2 },
        { id: 601, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 37, gender: 2 },
        { id: 602, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 38, gender: 2 },
        { id: 603, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 39, gender: 2 },
        { id: 604, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 40, gender: 2 },
        { id: 605, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 41, gender: 2 },
        { id: 606, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 42, gender: 2 },
        { id: 607, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 43, gender: 2 },
        { id: 608, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 44, gender: 2 },
        { id: 609, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 45, gender: 2 },
        { id: 610, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 46, gender: 2 },
        { id: 611, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 47, gender: 2 },
        { id: 612, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 48, gender: 2 },
        { id: 613, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 49, gender: 2 },
        { id: 614, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 50, gender: 2 },
        { id: 615, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 1, gender: 2 },
        { id: 616, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 2, gender: 2 },
        { id: 617, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 3, gender: 2 },
        { id: 618, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 4, gender: 2 },
        { id: 619, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 5, gender: 2 },
        { id: 620, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 6, gender: 2 },
        { id: 621, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 7, gender: 2 },
        { id: 622, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 8, gender: 2 },
        { id: 623, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 9, gender: 2 },
        { id: 624, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 10, gender: 2 },
        { id: 625, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 11, gender: 2 },
        { id: 626, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 12, gender: 2 },
        { id: 627, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 13, gender: 2 },
        { id: 628, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 14, gender: 2 },
        { id: 629, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 15, gender: 2 },
        { id: 630, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 16, gender: 2 },
        { id: 631, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 17, gender: 2 },
        { id: 632, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 18, gender: 2 },
        { id: 633, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 19, gender: 2 },
        { id: 634, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 20, gender: 2 },
        { id: 635, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 21, gender: 2 },
        { id: 636, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 22, gender: 2 },
        { id: 637, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 23, gender: 2 },
        { id: 638, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 24, gender: 2 },
        { id: 639, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 25, gender: 2 },
        { id: 640, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 26, gender: 2 },
        { id: 641, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 27, gender: 2 },
        { id: 642, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 28, gender: 2 },
        { id: 643, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 29, gender: 2 },
        { id: 644, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 30, gender: 2 },
        { id: 645, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 31, gender: 2 },
        { id: 646, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 32, gender: 2 },
        { id: 647, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 33, gender: 2 },
        { id: 648, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 34, gender: 2 },
        { id: 649, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 35, gender: 2 },
        { id: 650, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 36, gender: 2 },
        { id: 651, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 37, gender: 2 },
        { id: 652, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 38, gender: 2 },
        { id: 653, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 39, gender: 2 },
        { id: 654, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 40, gender: 2 },
        { id: 655, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 41, gender: 2 },
        { id: 656, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 42, gender: 2 },
        { id: 657, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 43, gender: 2 },
        { id: 658, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 44, gender: 2 },
        { id: 659, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 45, gender: 2 },
        { id: 660, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 46, gender: 2 },
        { id: 661, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 47, gender: 2 },
        { id: 662, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 48, gender: 2 },
        { id: 663, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 49, gender: 2 },
        { id: 664, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 50, gender: 2 },
        { id: 665, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 1, gender: 2 },
        { id: 666, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 2, gender: 2 },
        { id: 667, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 3, gender: 2 },
        { id: 668, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 4, gender: 2 },
        { id: 669, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 5, gender: 2 },
        { id: 670, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 6, gender: 2 },
        { id: 671, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 7, gender: 2 },
        { id: 672, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 8, gender: 2 },
        { id: 673, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 9, gender: 2 },
        { id: 674, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 10, gender: 2 },
        { id: 675, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 11, gender: 2 },
        { id: 676, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 12, gender: 2 },
        { id: 677, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 13, gender: 2 },
        { id: 678, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 14, gender: 2 },
        { id: 679, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 15, gender: 2 },
        { id: 680, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 16, gender: 2 },
        { id: 681, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 17, gender: 2 },
        { id: 682, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 18, gender: 2 },
        { id: 683, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 19, gender: 2 },
        { id: 684, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 20, gender: 2 },
        { id: 685, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 21, gender: 2 },
        { id: 686, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 22, gender: 2 },
        { id: 687, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 23, gender: 2 },
        { id: 688, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 24, gender: 2 },
        { id: 689, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 25, gender: 2 },
        { id: 690, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 26, gender: 2 },
        { id: 691, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 27, gender: 2 },
        { id: 692, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 28, gender: 2 },
        { id: 693, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 29, gender: 2 },
        { id: 694, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 30, gender: 2 },
        { id: 695, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 31, gender: 2 },
        { id: 696, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 32, gender: 2 },
        { id: 697, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 33, gender: 2 },
        { id: 698, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 34, gender: 2 },
        { id: 699, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 35, gender: 2 },
        { id: 700, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 36, gender: 2 },
        { id: 701, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 37, gender: 2 },
        { id: 702, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 38, gender: 2 },
        { id: 703, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 39, gender: 2 },
        { id: 704, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 40, gender: 2 },
        { id: 705, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 41, gender: 2 },
        { id: 706, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 42, gender: 2 },
        { id: 707, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 43, gender: 2 },
        { id: 708, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 44, gender: 2 },
        { id: 709, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 45, gender: 2 },
        { id: 710, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 46, gender: 2 },
        { id: 711, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 47, gender: 2 },
        { id: 712, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 48, gender: 2 },
        { id: 713, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 49, gender: 2 },
        { id: 714, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 50, gender: 2 },
        { id: 715, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 1, gender: 2 },
        { id: 716, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 2, gender: 2 },
        { id: 717, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 3, gender: 2 },
        { id: 718, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 4, gender: 2 },
        { id: 719, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 5, gender: 2 },
        { id: 720, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 6, gender: 2 },
        { id: 721, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 7, gender: 2 },
        { id: 722, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 8, gender: 2 },
        { id: 723, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 9, gender: 2 },
        { id: 724, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 10, gender: 2 },
        { id: 725, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 11, gender: 2 },
        { id: 726, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 12, gender: 2 },
        { id: 727, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 13, gender: 2 },
        { id: 728, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 14, gender: 2 },
        { id: 729, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 15, gender: 2 },
        { id: 730, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 16, gender: 2 },
        { id: 731, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 17, gender: 2 },
        { id: 732, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 18, gender: 2 },
        { id: 733, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 19, gender: 2 },
        { id: 734, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 20, gender: 2 },
        { id: 735, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 21, gender: 2 },
        { id: 736, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 22, gender: 2 },
        { id: 737, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 23, gender: 2 },
        { id: 738, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 24, gender: 2 },
        { id: 739, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 25, gender: 2 },
        { id: 740, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 26, gender: 2 },
        { id: 741, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 27, gender: 2 },
        { id: 742, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 28, gender: 2 },
        { id: 743, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 29, gender: 2 },
        { id: 744, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 30, gender: 2 },
        { id: 745, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 31, gender: 2 },
        { id: 746, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 32, gender: 2 },
        { id: 747, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 33, gender: 2 },
        { id: 748, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 34, gender: 2 },
        { id: 749, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 35, gender: 2 },
        { id: 750, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 36, gender: 2 },
        { id: 751, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 37, gender: 2 },
        { id: 752, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 38, gender: 2 },
        { id: 753, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 39, gender: 2 },
        { id: 754, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 40, gender: 2 },
        { id: 755, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 1, gender: 2 },
        { id: 756, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 2, gender: 2 },
        { id: 757, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 3, gender: 2 },
        { id: 758, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 4, gender: 2 },
        { id: 759, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 5, gender: 2 },
        { id: 760, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 6, gender: 2 },
        { id: 761, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 7, gender: 2 },
        { id: 762, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 8, gender: 2 },
        { id: 763, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 9, gender: 2 },
        { id: 764, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 10, gender: 2 },
        { id: 765, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 11, gender: 2 },
        { id: 766, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 12, gender: 2 },
        { id: 767, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 13, gender: 2 },
        { id: 768, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 14, gender: 2 },
        { id: 769, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 15, gender: 2 },
        { id: 770, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 16, gender: 2 },
        { id: 771, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 17, gender: 2 },
        { id: 772, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 18, gender: 2 },
        { id: 773, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 19, gender: 2 },
        { id: 774, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 20, gender: 2 },
        { id: 775, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 21, gender: 2 },
        { id: 776, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 22, gender: 2 },
        { id: 777, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 23, gender: 2 },
        { id: 778, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 24, gender: 2 },
        { id: 779, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 25, gender: 2 },
        { id: 780, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 26, gender: 2 },
        { id: 781, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 27, gender: 2 },
        { id: 782, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 28, gender: 2 },
        { id: 783, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 29, gender: 2 },
        { id: 784, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 30, gender: 2 },
        { id: 785, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 31, gender: 2 },
        { id: 786, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 32, gender: 2 },
        { id: 787, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 33, gender: 2 },
        { id: 788, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 34, gender: 2 },
        { id: 789, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 35, gender: 2 },
        { id: 790, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 36, gender: 2 },
        { id: 791, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 37, gender: 2 },
        { id: 792, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 38, gender: 2 },
        { id: 793, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 39, gender: 2 },
        { id: 794, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 40, gender: 2 },
        { id: 795, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 41, gender: 2 },
        { id: 796, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 42, gender: 2 },
        { id: 797, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 43, gender: 2 },
        { id: 798, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 44, gender: 2 },
        { id: 799, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 45, gender: 2 },
        { id: 800, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 46, gender: 2 },
        { id: 801, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 47, gender: 2 },
        { id: 802, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 48, gender: 2 },
        { id: 803, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 49, gender: 2 },
        { id: 804, name: 'inventory.body_armor', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 9, clothesIndex: 50, gender: 2 },
        { id: 805, name: 'inventory.explosive', rarity: 'ordinary', type: 0, filterType: 'other', desc: 'inventory.desc.explosive', img: 'other_items/dynamite' },
        { id: 806, name: 'inventory.magic_stone1', rarity: 'ordinary', type: 0, filterType: 'other', desc: 'inventory.desc.magic_stone1_description', img: 'other_items/craft-rock-1' },
        { id: 807, name: 'inventory.magic_stone2', rarity: 'ordinary', type: 0, filterType: 'other', desc: 'inventory.desc.magic_stone2_description', img: 'other_items/craft-rock-2' },
        { id: 808, name: 'inventory.magic_stone3', rarity: 'ordinary', type: 0, filterType: 'other', desc: 'inventory.desc.magic_stone3_description', img: 'other_items/craft-rock-3' },
        { id: 809, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 51, gender: 2 },
        { id: 810, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 52, gender: 2 },
        { id: 811, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 53, gender: 2 },
        { id: 812, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 54, gender: 2 },
        { id: 813, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 55, gender: 2 },
        { id: 814, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 56, gender: 2 },
        { id: 815, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 57, gender: 2 },
        { id: 816, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 58, gender: 2 },
        { id: 817, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 59, gender: 2 },
        { id: 818, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 60, gender: 2 },
        { id: 819, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 61, gender: 2 },
        { id: 820, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 62, gender: 2 },
        { id: 821, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 63, gender: 2 },
        { id: 822, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 64, gender: 2 },
        { id: 823, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 65, gender: 2 },
        { id: 824, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 66, gender: 2 },
        { id: 825, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 67, gender: 2 },
        { id: 826, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 68, gender: 2 },
        { id: 827, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 69, gender: 2 },
        { id: 828, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 70, gender: 2 },
        { id: 829, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 71, gender: 2 },
        { id: 830, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 72, gender: 2 },
        { id: 831, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 73, gender: 2 },
        { id: 832, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 74, gender: 2 },
        { id: 833, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 75, gender: 2 },
        { id: 834, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 76, gender: 2 },
        { id: 835, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 77, gender: 2 },
        { id: 836, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 78, gender: 2 },
        { id: 837, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 79, gender: 2 },
        { id: 838, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 80, gender: 2 },
        { id: 839, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 81, gender: 2 },
        { id: 840, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 82, gender: 2 },
        { id: 841, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 83, gender: 2 },
        { id: 842, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 84, gender: 2 },
        { id: 843, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 85, gender: 2 },
        { id: 844, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 86, gender: 2 },
        { id: 845, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 87, gender: 2 },
        { id: 846, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 88, gender: 2 },
        { id: 847, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 89, gender: 2 },
        { id: 848, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 90, gender: 2 },
        { id: 849, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 91, gender: 2 },
        { id: 850, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 92, gender: 2 },
        { id: 851, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 93, gender: 2 },
        { id: 852, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 94, gender: 2 },
        { id: 853, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 95, gender: 2 },
        { id: 854, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 96, gender: 2 },
        { id: 855, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 97, gender: 2 },
        { id: 856, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 98, gender: 2 },
        { id: 857, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 99, gender: 2 },
        { id: 858, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 100, gender: 2 },
        { id: 859, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 101, gender: 2 },
        { id: 860, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 102, gender: 2 },
        { id: 861, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 103, gender: 2 },
        { id: 862, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 104, gender: 2 },
        { id: 863, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 105, gender: 2 },
        { id: 864, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 106, gender: 2 },
        { id: 865, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 107, gender: 2 },
        { id: 866, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 108, gender: 2 },
        { id: 867, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 109, gender: 2 },
        { id: 868, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 110, gender: 2 },
        { id: 869, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 111, gender: 2 },
        { id: 870, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 112, gender: 2 },
        { id: 871, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 113, gender: 2 },
        { id: 872, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 114, gender: 2 },
        { id: 873, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 115, gender: 2 },
        { id: 874, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 116, gender: 2 },
        { id: 875, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 117, gender: 2 },
        { id: 876, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 118, gender: 2 },
        { id: 877, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 119, gender: 2 },
        { id: 878, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 120, gender: 2 }
    ]
});

const getters = {
    inventoryItemsPool: state => state.inventoryItemsPool
};

const actions = {
    updateInventoryItemsPool (context, inventoryItemsPool) {
        context.commit('setInventoryItemsPool', inventoryItemsPool);
    }
};

const mutations = {
    setInventoryItemsPool (state, inventoryItemsPool) {
        state.inventoryItemsPool = inventoryItemsPool;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
