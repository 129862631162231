<template>
    <div class="dialog dialog-pay">
        <div class="dialog__wrapper dialog-pay__wrapper">
            <div class="dialog-pay__left">
                <div class="dialog__title-wrapper">
                    <div class="dialog__title">
                        {{ title }}
                    </div>
                    <div class="dialog-pay__description">
                        {{ description }}
                    </div>
                </div>
                <div class="dialog-pay__method">
                    <button
                        class="dialog-pay__method-button"
                        v-if="useCash"
                        :class="{ active: activePaymentMethod === 'cash' }"
                        @click="selectPaymentMethod('cash')"
                    >
                        <span class="dialog-pay__method-button-name">{{ $t('dialog_pay.bank') }} <i v-if="total > cash">{{ $t('dialog_pay.no_money') }}</i></span>
                        <span class="dollar"><span class="dollar__symbol dollar__symbol--yellow">$</span>{{ $formatNum(cash) }}</span>
                    </button>

                    <button
                        class="dialog-pay__method-button"
                        v-if="useBank"
                        :class="{ active: activePaymentMethod === 'bank' }"
                        @click="selectPaymentMethod('bank')"
                    >
                        <span class="dialog-pay__method-button-name"
                            >{{ $t('dialog_pay.cash') }} <i v-if="total > money">{{ $t('dialog_pay.no_money') }}</i></span
                        >
                        <span class="dollar"><span class="dollar__symbol dollar__symbol--yellow">$</span>{{ $formatNum(money) }}</span>
                    </button>

                    <button class="dialog-pay__method-button"
                            v-if="useDonate"
                            :class="{ active: activePaymentMethod === 'donate' }"
                            @click="selectPaymentMethod('donate')">
                        <span class="dialog-pay__method-button-name">{{ $t('dialog_pay.grand_coins') }}</span>
                        <span class="donate"><IconDonate /> {{ $formatNum(donate) }}</span>
                    </button>
                </div>
            </div>
            <div class="dialog-pay__right">
                <div class="dialog-pay__right__top">
                    <ul class="dialog-pay__pay-list">
                        <li>
                            {{ $t('dialog_pay.cost') }}
                            <span class="dollar" v-if="activePaymentMethod !== 'donate'">
                                <span class="dollar__symbol dollar__symbol--yellow">$</span>{{ $formatNum(price) }}
                            </span>
                            <span class="donate" v-else><IconDonate /> {{ $formatNum(donatePrice) }}</span>
                        </li>
                        <li v-if="calculatedTax > 0">
                            {{ $t('dialog_pay.tax') }}
                            <span class="dollar" v-if="activePaymentMethod !== 'donate'">
                                <span class="dollar__symbol dollar__symbol--yellow">$</span>{{ $formatNum(calculatedTax) }}
                            </span>
                            <span class="donate" v-else><IconDonate /> {{ $formatNum(calculatedTax) }}</span>
                        </li>

                        <!-- Показывать скидку только если она больше нуля -->
                        <li v-if="calculatedDiscount > 0" class="dialog-pay__discount">
                            <p>{{ $t('dialog_pay.vip_discount') }}</p>
                            <span class="dollar" v-if="activePaymentMethod !== 'donate'">
                                <span class="dollar__symbol dollar__symbol--yellow">$</span>{{ $formatNum(calculatedDiscount) }}
                            </span>
                            <span class="donate" v-else><IconDonate /> {{ $formatNum(calculatedDiscount) }}</span>
                        </li>
                    </ul>
                    <div class="dialog-pay__total">
                        {{ $t('dialog_pay.summary') }}
                        <span class="dollar money" v-if="activePaymentMethod !== 'donate'">
                            <span class="dollar__symbol dollar__symbol--yellow">$</span>{{ $formatNum(total) }}
                        </span>
                        <span class="donate" v-else><IconDonate /> {{ $formatNum(total) }}</span>
                    </div>
                    <div class="dialog-pay__alert" v-if="insufficientFunds">
                        <p><img src="@/assets/images/warnIcon.svg" alt="">{{ $t('dialog_pay.not_enough') }}</p>
                        <span class="dollar money">
                            <span class="dollar__symbol dollar__symbol--yellow" v-if="activePaymentMethod !== 'donate'">$</span>
                            <IconDonate v-else />
                            {{ $formatNum(total - getCurrentPaymentMethodAmount) }}
                        </span>
                    </div>
                </div>
                <BaseButton green class="dialog-pay__btn" @click="goShop" v-if="insufficientFunds">
                    {{ $t('dialog_pay.deposit') }}
                </BaseButton>
                <BaseButton yellow class="dialog-pay__btn" @click="getPay" v-else><IconTag class="icon"/>
                    {{ $t('dialog_pay.pay') }}
                </BaseButton>
            </div>
        </div>
    </div>
</template>

<script>
import BaseButton from '@/components/ui/BaseButton.vue';
import IconDonate from '@/components/icons/IconDonate.vue';
import IconTag from '@/components/icons/IconTag.vue';

export default {
    name: 'DialogPay',
    components: { IconTag, IconDonate, BaseButton },
    data () {
        return {
            show: false, // для тестов
            title: this.$t('dialog_pay.title'),
            description: 'Автомобиль Mercedes Benz S-class 240w',
            price: 2000000,
            donatePrice: 2000000,
            tax: 0,
            discount: 0,
            activePaymentMethod: 'cash',
            useCash: false,
            useBank: true,
            useDonate: true
        };
    },
    computed: {
        cash () {
            return this.$store.getters['player/formattedCash'];
        },
        money () {
            return this.$store.getters['player/formattedCurrency'];
        },
        donate () {
            return this.$store.getters['player/formattedDonation'];
        },
        calculatedTax () {
            return this.price * (this.tax / 100);
        },
        calculatedDiscount () {
            return this.price * (this.discount / 100);
        },
        total () {
            if (this.activePaymentMethod !== 'donate') {
                return this.price + this.calculatedTax - this.calculatedDiscount;
            } else {
                return this.donatePrice + this.calculatedTax - this.calculatedDiscount;
            }
        },
        getCurrentPaymentMethodAmount () {
            if (this.activePaymentMethod === 'cash') return this.cash;
            if (this.activePaymentMethod === 'bank') return this.money;
            if (this.activePaymentMethod === 'donate') return this.donate;
            return 0;
        },
        insufficientFunds () {
            return this.total > this.getCurrentPaymentMethodAmount;
        }
    },
    methods: {
        /*
        window.modifyVueComponent({
            App: {
                showDialog: true,
                dialogName: 'DialogPay',
            },
            DialogPay: {
                initialize: [
                    'Диалог 1',
                    'Вы действительно хотите купить эту машину?',
                    100
                ]
            }
        });
        */
        initialize (title, description, price, donate = 0) {
            this.title = title;
            this.description = description;
            this.price = price;
            this.activePaymentMethod = 'bank';
            if (donate > 0) {
                this.useBank = false;
                this.donatePrice = donate;
                this.useDonate = true;
                this.activePaymentMethod = 'donate';
            } else {
                this.useBank = true;
                this.useDonate = false;
            }
            if (price > 0) {
                this.useBank = true;
            }
        },
        selectPaymentMethod (method) {
            this.activePaymentMethod = method;
        },
        goShop () {
            this.sendclient('OpenShopWithMoney');
        },
        getPay () {
            const paymentNumber = this.activePaymentMethod === 'bank' ? 1 : 2;
            this.sendclient('DialogResponse', JSON.stringify({ a: paymentNumber }));
        }
    }
};
</script>

<style lang="scss" scoped>
.dialog-pay{
    padding: 140px 277px;

    &__btn{
        width: 100%;
        .icon{
            height: 2vw;
            width: auto;
            margin-right: .5vw;
        }
    }

    &__right{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__wrapper{
        display: grid;
        grid-template-columns: 1.2fr 1fr;
        grid-gap: 5vw;
        height: 100%;
    }
    &__description{
        color: rgba(241, 241, 241, 0.70);
        font-size: 1.5vw;
        line-height: 120%;
        padding-left: 1.2vw;
        border-left: .2vw solid rgba(241, 241, 241, 0.2);
    }
    &__method{
        display: grid;
        grid-gap: 1vw;
        &-button{
            padding: 2vw;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 4px;
            background: linear-gradient(90deg, rgba(241, 241, 241, 0.10) 0%, rgba(241, 241, 241, 0.04) 100%);
            border: .1vw solid rgba(255, 255, 255, 0.3);

            &-name{
                color: rgba(241, 241, 241, 0.60);
                font-size: 1.8vw;
                font-weight: 500;
                line-height: 100%;
                text-align: left;

                i{
                    margin-top: .6vw;
                    color: #F1F1F1;
                    font-size: 1.1vw;
                    font-weight: 600;
                    font-style: normal;
                    line-height: 90%;
                    letter-spacing: 2.56px;
                    text-transform: uppercase;
                    border-radius: .4vw;
                    display: block;
                    background: #CF4941;
                    padding: .5vw;
                }
            }
            .dollar, .donate{
                font-size: 2.2vw;
                font-weight: 700;
                line-height: 100%;
                color: #f1f1f1;
                align-items: center;
                display: flex;
                font-family: var(--font-family);
            }

            &.active{
                background: linear-gradient(90deg, rgba(241, 241, 241, 0.20) 0%, rgba(241, 241, 241, 0.09) 100%);
                box-shadow: 0px 0px 64px 0px rgba(241, 241, 241, 0.60) inset;
                border: .1vw solid rgba(255, 255, 255, 0.7);

                .dialog-pay__method-button-name{
                    color: #f1f1f1;
                }
            }
        }
    }

    &__discount{
        p{
            color: #9FD035;
        }
    }

    &__pay-list{
        border-top: .15vw solid rgba(241, 241, 241, 0.30);
        border-bottom: .15vw solid rgba(241, 241, 241, 0.30);
        padding: 1.5vw 0;
        display: grid;
        grid-gap: 1vw;

        li{
            display: flex;
            justify-content: space-between;
            font-size: 1.8vw;
            font-weight: 500;
            line-height: 100%;

            .dollar{
                font-family: var(--font-family);
            }
        }
    }

    &__total{
        padding: 1.5vw 0;
        display: flex;
        justify-content: space-between;
        font-size: 1.8vw;
        font-weight: 500;
        line-height: 100%;
        position: relative;
        &::after{
            position: absolute;
            display: block;
            content: "";
            height: 2vw;
            width: 100%;
            bottom: -1.5vw;
            background: url(@/assets/images/separator.svg) no-repeat center/100%;

        }
    }

    .money, .donate{
        font-size: 2.2vw;
        font-weight: 700;
        line-height: 100%;
        color: #f1f1f1;
        align-items: center;
        display: flex;
        font-family: var(--font-family);
        &::v-deep {
            svg {
                width: 64px;
                height: 64px;
                margin-right: 8px;
            }
        }
    }
    &__alert{
        padding: 3vw 0 1.5vw;
        display: flex;
        justify-content: space-between;
        font-size: 1.8vw;
        font-weight: 500;
        line-height: 100%;
        p{
            align-items: center;
            display: flex;
            img{
                height: 2vw;
                margin-right: 1vw;
            }
        }
    }
}
</style>
