<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.40904 2.66797C5.30652 2.67305 4.26072 3.15744 3.5438 3.99506C2.95126 4.68738 2.61592 5.5706 2.67273 6.48009C3.03085 12.2123 5.39973 17.8417 9.77956 22.2216C14.1594 26.6014 19.7889 28.9702 25.521 29.3284C26.4305 29.3852 27.3137 29.0498 28.0061 28.4573C28.8437 27.7404 29.3281 26.6946 29.3332 25.5921V22.9065C29.3332 21.6832 28.5009 20.6168 27.3142 20.3197L23.1774 19.2837C22.4824 19.1097 21.7466 19.2226 21.1358 19.597L18.059 21.4833C17.6064 21.7609 17.0306 21.745 16.6132 21.417C15.5433 20.5766 14.2602 19.4122 13.4245 18.5766C12.5889 17.7409 11.4245 16.4578 10.5841 15.388C10.2561 14.9705 10.2403 14.3948 10.5178 13.9421L12.404 10.8652C12.7785 10.2545 12.8914 9.51872 12.7174 8.82376L11.6815 4.68689C11.3843 3.50025 10.318 2.66797 9.09469 2.66797H6.40904Z" :fill="fillColor ? fillColor : '#F1F1F1'"/>
    </svg>
</template>
<script>
export default {
    name: 'IconCall',
    props: {
        fillColor: String
    }
};
</script>
