const player = {
    namespaced: true,
    state: {
        cash: 0, // Наличичка
        currency: 0, // Значение игровой валюты
        donations: 0, // Значение донатов
        playerName: 'Kevin Mackalister', // Имя игрока
        playerLevel: 10, // Уровень игрока,
        exp: 5,
        id: 0,
        pid: 0,
        phone: 12345,
        vip: false,
        partner: '',
        family: '',
        organization: '',
        gangId: 7,
        regDate: '12 марта 2021', // продумать по хорошему
        regPlace: 'Отель',
        daysInState: 388,
        fine: 0,
        warn: 0,
        gunSkill: 20,
        driveSkill: 95,
        craftLevel: 1,
        craftExp: 12923,
        event: {
            show: 1,
            money: 499
        },
        isAdmin: 0,
        ownBattlePass: 0,
        doubleCurrency: 0
    },
    mutations: {
        setDoubleCurrency (state, amount) {
            state.doubleCurrency = amount;
        },
        setOwnBattlePass (state, amount) {
            state.ownBattlePass = amount;
        },
        setAdmin (state, amount) {
            state.isAdmin = amount;
        },
        setCurrency (state, amount) {
            state.currency = amount;
        },
        setId (state, amount) {
            state.id = amount;
        },
        setPId (state, amount) {
            state.pid = amount;
        },
        setFine (state, amount) {
            state.fine = amount;
        },
        setVip (state, vip) {
            state.vip = vip;
        },
        setPartner (state, name) {
            state.partner = name;
        },
        setFamily (state, name) {
            state.family = name;
        },
        setPhone (state, phone) {
            state.phone = phone;
        },
        setDonations (state, amount) {
            state.donations = amount;
        },
        setCash (state, amount) { // Мутация для cash
            state.cash = amount;
        },
        setPlayerName (state, name) {
            state.playerName = name;
        },
        setPlayerLevel (state, level) {
            state.playerLevel = level;
        },
        setCraftLevel (state, level) {
            state.craftLevel = level;
        },
        setPlayerExp (state, exp) {
            state.exp = exp;
        },
        setPhoneNumber (state, number) {
            state.phone = number;
        },
        setOrgId (state, id) {
            state.gangId = id;
        },
        setOrgName (state, name) {
            state.organization = name;
        },
        setWarns (state, amount) {
            state.warn = amount;
        },
        setEventMoney (state, amount) {
            state.event.money = amount;
        },
        setCraftExp (state, amount) {
            state.craftExp = amount;
        }
    },
    getters: {
        formattedCash: (state) => {
            return state.cash;
        },
        formattedCurrency: (state) => {
            return state.currency;
        },
        formattedDonation: (state) => {
            return state.donations;
        },
        formattedPhone: (state) => {
            const str = state.phone.toString();
            return str.slice(0, 2) + '-' + str.slice(2, 4) + '-' + str.slice(4);
        }
    }
};

export default player;
