<template>
    <svg width="62" height="63" viewBox="0 0 62 63" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M31.0001 5.66602C32.0318 5.66602 32.9646 6.27994 33.3727 7.22757L39.7509 22.0382L55.8076 23.5274C56.8349 23.6227 57.7071 24.3202 58.0259 25.3014C58.3447 26.2827 58.0491 27.3596 57.274 28.0405L45.1592 38.6833L48.7046 54.4143C48.9315 55.4208 48.5377 56.4658 47.7029 57.0722C46.8682 57.6787 45.7527 57.7303 44.8656 57.2035L31.0001 48.9704L17.1345 57.2035C16.2474 57.7303 15.1319 57.6787 14.2972 57.0722C13.4624 56.4658 13.0686 55.4208 13.2955 54.4143L16.841 38.6833L4.72613 28.0405C3.95099 27.3596 3.65537 26.2827 3.9742 25.3014C4.29303 24.3202 5.16517 23.6227 6.19252 23.5274L22.2492 22.0382L28.6274 7.22757C29.0355 6.27994 29.9683 5.66602 31.0001 5.66602Z" :fill="fill"/>
    </svg>

</template>

<script>
export default {
    props: {
        fill: {
            type: String,
            default: '#141A1E'
        }
    }
};
</script>

<style scoped>
svg{
    width: 63px;
    height: 63px;
    margin-right: 18px;
}
</style>
