export const MINI_WORKS_CONFIG = {
    1: { // дальнобойщик
        image: '2.png',
        gradient: {
            startColor: 'rgba(56, 60, 70, 0.94)',
            finishColor: 'rgba(31, 31, 43, 0.94)'
        },
        title: 'mini_works.truckerorders',
        ordersText: 'mini_works.orders',
        tabDefinitions: [
            { type: 'gov', text: 'mini_works.trucker_list.govlist', requiredLevel: 0 }
        ]
    },
    2: { // такси
        image: '1.png',
        gradient: {
            startColor: 'rgba(203, 158, 0, 0.94)',
            finishColor: 'rgba(104, 75, 0, 0.94)'
        },
        title: 'mini_works.taxiorders',
        ordersText: 'mini_works.calls',
        tabDefinitions: [
            { type: 'gov', text: 'mini_works.trucker_list.govlist', requiredLevel: 0 }
        ]
    },
    3: { // больница
        image: '0.png',
        gradient: {
            startColor: 'rgba(163, 30, 54, 0.94)',
            finishColor: 'rgba(60, 8, 33, 0.94)'
        },
        title: 'mini_works.medicorders',
        ordersText: 'mini_works.calls',
        tabDefinitions: [
            { type: 'gov', text: 'mini_works.trucker_list.privatelist', requiredLevel: 0 },
            { type: 'clothes', text: 'mini_works.trucker_list.orglist', requiredLevel: 0 }
        ]
    },
    4: { // СМИ
        image: '4.png',
        gradient: {
            startColor: '#F39200',
            finishColor: '#763200'
        },
        title: 'mini_works.news',
        ordersText: 'mini_works.about',
        tabDefinitions: [
            { type: 'gov', text: 'mini_works.adlist', requiredLevel: 0 }
        ]
    },
    5: { // Армия
        image: '5.png',
        gradient: {
            startColor: 'rgba(26, 141, 127, 0.94)',
            finishColor: 'rgba(21, 52, 41, 0.94)'
        },
        title: 'mini_works.army',
        ordersText: 'mini_works.orders',
        tabDefinitions: [
            { type: 'gov', text: 'mini_works.trucker_list.orglist', requiredLevel: 0 }
        ]
    }
};
