<template>
    <div class="dialog dialog-settings">
        <div class="dialog__left">
            <div class="dialog-settings__title">{{ title }}</div>
            <div class="dialog-settings__info" v-if = "needPicture">
                <div class="dialog-settings__state">{{ $t('settings.state.medium') }}</div>
                <div class="dialog-settings__about">{{ $t('settings.about') }}</div>
            </div>
            <img v-if="needPicture" src="@/assets/images/settings/preset.png" alt="" class="dialog-settings__image">
            <button class="dialog-settings__save" @click="saveSettings">{{ $t('settings.save') }}</button>
        </div>
        <div class="dialog-settings__active" ref="active"></div>
        <div class="dialog__right" ref="container">
            <swiper :options="swiperOptions" ref="mySwiper" @slideChange="handleSlideChange">
                <swiper-slide v-for="element in elements" :key="element.name">
                    <div
                        :class="['dialog-settings__element', { 'dialog-settings__element--active': activeElement === element.name }]"
                    >
                        {{ element.name }}
                    </div>
                </swiper-slide>
            </swiper>

        </div>
    </div>
</template>
<script>
import awesomeSwiperBuilder from 'vue-awesome-swiper/dist/exporter';
import SwiperConstructor from 'swiper';
const { Swiper, SwiperSlide } = awesomeSwiperBuilder(SwiperConstructor);
export default {
    name: 'DialogSettings',
    components: {
        Swiper,
        SwiperSlide
    },
    data () {
        return {
            activeElement: null,
            needPicture: false,
            title: '',
            elements: [
                { name: this.$t('settings.state.minimum'), value: 1 },
                { name: this.$t('settings.state.standard'), value: 2 },
                { name: this.$t('settings.state.medium'), value: 3 },
                { name: this.$t('settings.state.high'), value: 4 },
                { name: this.$t('settings.state.ultra'), value: 5 }
            ],
            savedValue: null,
            swiperOptions: {
                slidesPerView: 'auto',
                centeredSlides: true,
                direction: 'vertical',
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
                touchStartPreventDefault: false,
                touchMoveStopPropagation: false,
                simulateTouch: true,
                touchRatio: 1,
                touchAngle: 45,
                grabCursor: true
            }

        };
    },
    mounted () {
        this.$nextTick(() => {
            this.$refs.mySwiper.$swiper.on('slideChange', this.handleSlideChange);
        });
    },

    beforeDestroy () {
        if (this.$refs.mySwiper && this.$refs.mySwiper.$swiper) {
            this.$refs.mySwiper.$swiper.off('slideChange', this.handleSlideChange);
        }
    },
    methods: {
        Initialize (title, elements) {
            this.elements = elements;
            this.title = title;
        },
        handleSlideChange () {
            const activeSlideIndex = this.$refs.mySwiper.$swiper.activeIndex;
            if (this.elements[activeSlideIndex]) {
                this.activeElement = this.elements[activeSlideIndex];
            }
        },
        saveSettings () {
            if (this.activeElement) {
                this.savedValue = this.activeElement.value;
            }
            this.sendclient('SettingsDialog', JSON.stringify({ a: this.savedValue }));
        }
    }

};

</script>

<style lang="scss">
@import "swiper/swiper.scss";

.dialog-settings{
    .swiper-container {
        margin: -100vh 0;
        padding: 100vh 0;
    }

}
</style>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
.dialog {
    &-settings {
        display: grid;
        grid-template-columns: repeat(13, 1fr);
        // padding: 4vw 8.3vw 4vw 5vw; // Паддинги 1 версия
        padding: 0 8.3vw 0 5vw;
        grid-gap: 0 5.2vw;
        position: relative;
        &__title {
            font-size: 3.6vw;
            color: var(--white);
            font-weight: 300;
            margin-bottom: 1.5vw;
        }
        &__info {
            display: flex;
            align-items: center;
            margin: 1.5vw 0;
        }
        &__state {
            color: var(--white);
            font-weight: 500;
            font-size: 1.75vw;
            padding-right: 1.3vw;
        }
        &__about {
            font-size: 1.8vw;
            color: rgba(241, 241, 241, 0.7);
            padding-left: 1.3vw;
            line-height: 120%;
            font-weight: 400;
            border-left: .18vw solid rgba(#f1f1f1, .2);
        }
        &__save {
            color: #202427;
            background-color: var(--yellow);
            font-weight: 500;
            font-size: 1.9vw;
            padding: 1.7vw 2.6vw;
            width: fit-content;
            margin-top: 1.5vw;
            &:active {
                background-color: #FFF742;
            }
        }
        &__image {
            width: 100%;
            margin: 1.5vw 0;
        }
        &__element {
            text-align: center;
            height: 194px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2vw;
            opacity: 0.6;
            transition: opacity .3s ease, font-size .3s ease;
            &--active {
                opacity: 1;
                font-size: 2.5vw;
            }
        }
        &__active {
            position: absolute;
            right: 8.3vw;
            top: 50%;
            transform: translateY(-50%);
            width: 37.216vw;
            height: 194px;
            background-image: url(@/assets/images/settings/active.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
    &__left {
        // padding-bottom: 2.6vw; // Паддинги 1 версия
        padding-top: 4vw; // Паддинги 2 версия
        padding-bottom: 6.6vw; // Паддинг 2 версия
        padding-left: 5.5vw;
        grid-column: 7 span;
        display: flex;
        flex-direction: column;
        justify-content: center;

    }

    &__right {
        grid-column: 6 span;
        position: relative;
        overflow-y: auto;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    &__right {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        position: fixed;
        top: 0;
        bottom: 0;
        width: 37.216vw;
        right: 8.3vw;

        .swiper-container {
            width: 100%;
        }
    }
}
</style>
