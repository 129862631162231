import { render, staticRenderFns } from "./BattlePassItemDescription.vue?vue&type=template&id=29317215&scoped=true&"
import script from "./BattlePassItemDescription.vue?vue&type=script&lang=js&"
export * from "./BattlePassItemDescription.vue?vue&type=script&lang=js&"
import style0 from "./BattlePassItemDescription.vue?vue&type=style&index=0&id=29317215&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29317215",
  null
  
)

export default component.exports