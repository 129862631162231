<template>
    <div class="blank">
        <IconBaseTabMenu :iconSize=4.3 class="tabs__item-icon" v-if="icon" :type="icon" />
        <div v-if="title" class="blank__title">{{ title }}</div>
        <div v-if="text" class="blank__text" :style="{maxWidth: textWidth + 'vw'}"> {{ text }}</div>
        <slot></slot>
    </div>
</template>
<script>

import IconBaseTabMenu from '@/components/icons/IconBaseTabMenu.vue';

export default {
    name: 'BlankTab',
    components: { IconBaseTabMenu },
    props: {
        title: String,
        text: String,
        icon: String,
        textWidth: String
    }
};
</script>
<style lang="scss" scoped>
.blank {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    grid-column: 14 span;
    &__title {
        color: var(--white);
        font-weight: 300;
        line-height: 120%;
        font-size: 1.75vw;
        padding: 1.3vw 0;
    }
    &__text {
        font-weight: 400;
        font-size: 1.3vw;
        line-height: 130%;
    }
}
</style>
