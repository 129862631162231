<template>
    <div class="preload-designs" :style="`background: ${loaderInfo.color};`" v-if="isLoading">
        <div class="preload-designs__loader">
            <img src="@/assets/images/servers/bg1.png" class="testing" alt="">
            <img src="@/assets/images/servers/bg2.png" class="testing" alt="">
            <img src="@/assets/images/servers/bg3.png" class="testing" alt="">
            <img src="@/assets/images/servers/skin1.png" class="testing" alt="">
            <img src="@/assets/images/servers/skin2.png" class="testing" alt="">
            <img src="@/assets/images/servers/skin3.png" class="testing" alt="">
            <svg class="preload-designs__loader-svg right" preserveAspectRatio="none" width="2532" height="1210" viewBox="0 0 2532 1210" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.1" clip-path="url(#clip0_282_21006)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2355.67 -97.9185L2629.93 102.747L1964.52 -231.173L2367.3 68.3842L2418.82 805.221L2536.69 735.628L2424.12 880.94L2502.94 2000.85L2822.82 1331.78L2532.42 2429.69L2615.93 3623.9L2878.37 3145.82L2637.84 3937.24L2297.49 5149.14L2656.52 4240.71L2783.34 6020.8L3464.03 5973.2L3366.59 4466.2L3104.42 716.867L3322.96 456.491L2874.95 839.269L3093.18 556.201L2946.42 -1542.52L2258.02 -1494.39L2355.67 -97.9185Z" fill="#F1F1F1"/>
                </g>
                <defs>
                    <clipPath id="clip0_282_21006">
                        <rect width="2532" height="1210" fill="white"/>
                    </clipPath>
                </defs>
            </svg>

            <svg class="preload-designs__loader-svg left" preserveAspectRatio="none" width="2532" height="1210" viewBox="0 0 2532 1210" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.1" clip-path="url(#clip0_281_20215)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M-580.329 -45.9185L-306.07 154.747L-971.478 -179.173L-568.7 120.384L-517.175 857.221L-399.312 787.628L-511.881 932.94L-433.061 2052.85L-113.179 1383.78L-403.582 2481.69L-320.074 3675.9L-57.6277 3197.82L-298.164 3989.24L-638.508 5201.14L-279.479 4292.71L-152.664 6072.8L528.028 6025.2L430.595 4518.2L168.416 768.867L386.961 508.491L-61.0451 891.269L157.181 608.201L10.4243 -1490.52L-677.98 -1442.39L-580.329 -45.9185Z" fill="#F1F1F1"/>
                </g>
                <defs>
                    <clipPath id="clip0_281_20215">
                        <rect width="2532" height="1210" fill="white"/>
                    </clipPath>
                </defs>
            </svg>

            <transition name="fade" mode="out-in">
                <div :key="infoStyle">
                    <h4>{{ loaderInfo.heading }}</h4>
                    <p>{{ loaderInfo.text }}</p>
                </div>
            </transition>
            <img src="@/assets/images/loading.png" class="preload-designs__loader-img" alt="">

        </div>
        <div class="preload-designs__items">

            <component v-for="(component, name) in components" :key="name" :is="name"></component>
            <component v-for="(hud, name) in hud" :key="name" :is="name"></component>
        </div>
    </div>
</template>

<script>
import components from './popups/content';
import hud from './hud';
export default {
    name: 'PreloadDesigns',
    components: { ...components, ...hud },
    data () {
        return {
            infoStyle: 1,
            infoStyleChanged: null,
            isLoading: true,
            components,
            hud
        };
    },
    computed: {
        loaderInfo () {
            const information = {
                1: {
                    heading: 'Семьи и организации',
                    text: 'Вступайте в семью, чтобы быстрее набрать опыт и получить дополнительные бонусы при прокачке. Вступайте в семью, чтобы быстрее набрать опыт бонусы при прокачке',
                    color: '#2E459E'
                },
                2: {
                    heading: 'собственный дом',
                    text: 'Владение домом открываем вам новые возможности: собственный автомобиль, новые работы, хранилище, вечеринки',
                    color: '#009444'
                },
                3: {
                    heading: 'уникальная история',
                    text: 'Каждый игрок создаёт свою уникальную и неповторимую роль, ты можешь быть кем угодно. Но не забывай что твой авторитет выстраивается с каждым днем',
                    color: '#E32754'
                }

            };
            return information[this.infoStyle];
        }
    },
    methods: {
        checkAllImagesLoaded () {
            const images = this.$el.querySelectorAll('img');
            const loadedOrErroredImages = Array.from(images).filter(img => img.complete || img.naturalWidth === 0);

            if (loadedOrErroredImages.length === images.length) {
                setTimeout(() => { this.isLoading = false; }, 20000);
            } else if (this.isLoading) {
                setTimeout(this.checkAllImagesLoaded, 100);
            }
        }

    },
    mounted () {
        this.infoStyleChanged = setInterval(() => {
            this.infoStyle = this.infoStyle < 3 ? this.infoStyle + 1 : 1;
        }, 2500);
        this.$el.addEventListener('error', (event) => {
            if (event.target.tagName === 'IMG') {
                console.log('ошибка');
                this.checkAllImagesLoaded();
            }
        }, true);

        this.checkAllImagesLoaded();
    },
    beforeDestroy () {
        clearInterval(this.infoStyleChanged);
    }
};
</script>

<style lang="scss">
.testing {
    opacity: 0;
    visibility: hidden;
}
.preload-designs {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;

    &__loader{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        height: 100%;
        padding: 200px 466px;

        img{
            width: 96px;
            height: 96px;
            position: fixed;
            bottom: 200px;
            right: 466px;
            animation: loader 2.5s infinite linear;

            @keyframes loader {
                0% {
                    transform: rotate(0);
                }
                100% {
                    transition: round(360deg);
                }
            }
        }

        h4 {
            font-family: var(--font-stretch);
            font-size: 92px;
            margin-bottom: 44px;
            background: #141A1E;
            display: inline-block;
            padding: 8px 24px;
            text-transform: uppercase;
        }

        p{
            color: #F1F1F1;
            font-size: 40px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            max-width: 1200px;
        }

        &-svg{
            position: fixed;
            top: 0;
            bottom: 0;
            height: 100%;
            pointer-events: none;

            &.left {
                left: 0;
            }
            &.right {
                right: 0;
            }
        }
    }
    &__items{
        pointer-events: none;
        opacity: 0;
        width: 1px;
        height: 1px;
        position: absolute;
    }

}
</style>
