<template>
    <div>
        <svg v-if="variant === 'hud'" width="55" height="46" viewBox="0 0 55 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.5599 5.52886L19.51 1.73251C19.2 1.44192 18.6901 1.44192 18.3801 1.73251L17.8151 2.26223C17.5051 2.55282 17.5051 3.03081 17.8151 3.3214L19.7 5.08827V7.99879C19.7 9.31579 20.7449 10.4031 22.1 10.5858V18.1222C22.1 18.7407 21.5601 19.247 20.9002 19.247C20.2402 19.247 19.7002 18.7407 19.7002 18.1222V16.6224C19.7126 14.1921 17.4622 12.3197 14.9001 12.4981V3.49952C14.8999 1.84511 13.4649 0.5 11.6999 0.5H3.69998C1.93501 0.5 0.5 1.84511 0.5 3.49952V21.4999H14.8999V14.7478H15.3001C16.4051 14.7478 17.3 15.5867 17.3 16.6225V17.9255C17.3 19.6923 18.65 21.2999 20.5251 21.4827C22.675 21.6842 24.5 20.1 24.5 18.1223V7.6521C24.5 6.85541 24.16 6.09147 23.5601 5.52899L23.5599 5.52886ZM11.6999 9.49855H3.69998V3.49952H11.6999V9.49855Z" fill="#F1F1F1"/>
            <rect x="2.00049" y="34.9999" width="50.9992" height="9" fill="#F1F1F1" fill-opacity="0.2" stroke="#F1F1F1" stroke-width="3"/>
            <rect x="0.5" y="33.5" :width="fuelPercent + '%'" height="12" fill="#F1F1F1"/>
        </svg>
        <svg v-if="variant === 'gasStation' && !yellow" width="238" height="22" viewBox="0 0 238 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.3099 5.52886L19.26 1.73251C18.95 1.44192 18.4401 1.44192 18.1301 1.73251L17.5651 2.26223C17.2551 2.55282 17.2551 3.03081 17.5651 3.3214L19.45 5.08827V7.99879C19.45 9.31579 20.4949 10.4031 21.85 10.5858V18.1222C21.85 18.7407 21.3101 19.247 20.6502 19.247C19.9902 19.247 19.4502 18.7407 19.4502 18.1222V16.6224C19.4626 14.1921 17.2122 12.3197 14.6501 12.4981V3.49952C14.6499 1.84511 13.2149 0.5 11.4499 0.5H3.44998C1.68501 0.5 0.25 1.84511 0.25 3.49952V21.4999H14.6499V14.7478H15.0501C16.1551 14.7478 17.05 15.5867 17.05 16.6225V17.9255C17.05 19.6923 18.4 21.2999 20.2751 21.4827C22.425 21.6842 24.25 20.1 24.25 18.1223V7.6521C24.25 6.85541 23.91 6.09147 23.3101 5.52899L23.3099 5.52886ZM11.4499 9.49855H3.44998V3.49952H11.4499V9.49855Z" fill="#F1F1F1"/>
            <rect x="37.75" y="4.5" width="198" height="13" fill="#F1F1F1" fill-opacity="0.2" stroke="#F1F1F1" stroke-width="3"/>
            <rect x="36.25" y="3" :width="fuelPercent + '%'" height="16" fill="#F1F1F1"/>
        </svg>
        <svg v-if="variant ==='gasStation' && yellow" width="238" height="22" viewBox="0 0 238 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.8099 5.52886L19.76 1.73251C19.45 1.44192 18.9401 1.44192 18.6301 1.73251L18.0651 2.26223C17.7551 2.55282 17.7551 3.03081 18.0651 3.3214L19.95 5.08827V7.99879C19.95 9.31579 20.9949 10.4031 22.35 10.5858V18.1222C22.35 18.7407 21.8101 19.247 21.1502 19.247C20.4902 19.247 19.9502 18.7407 19.9502 18.1222V16.6224C19.9626 14.1921 17.7122 12.3197 15.1501 12.4981V3.49952C15.1499 1.84511 13.7149 0.5 11.9499 0.5H3.94998C2.18501 0.5 0.75 1.84511 0.75 3.49952V21.4999H15.1499V14.7478H15.5501C16.6551 14.7478 17.55 15.5867 17.55 16.6225V17.9255C17.55 19.6923 18.9 21.2999 20.7751 21.4827C22.925 21.6842 24.75 20.1 24.75 18.1223V7.6521C24.75 6.85541 24.41 6.09147 23.8101 5.52899L23.8099 5.52886ZM11.9499 9.49855H3.94998V3.49952H11.9499V9.49855Z" fill="#F1F1F1"/>
            <rect x="38.25" y="4.5" width="198" height="13" fill="#F1F1F1" fill-opacity="0.2" stroke="#F1F1F1" stroke-width="3"/>
            <rect x="36.75" y="3" :width="fuelPercent + '%'" height="16" fill="#F1F1F1"/>
            <rect :width="gasYellowPercent" height="16" transform="matrix(-1 0 0 1 237.75 3)" fill="#FFED4C" />
        </svg>
    </div>
</template>
<script>
export default {
    props: {
        fuelPercent: Number,
        variant: {
            type: String,
            default: 'hud'
        },
        yellow: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        gasYellowPercent () {
            return `calc(201px - ${this.fuelPercent}%)`;
        }
    }
};
</script>
