<template>
    <div class="street-market">
        <AppHeader back-button>
            <template #title>{{ $t('street_market.header') }} <span class="street-market__header-name">“{{ ownerInfo.marketName }}”</span></template>
        </AppHeader>

        <div class="street-market__wrapper">

            <div class="street-market__information" v-if="owner === 0 && ownerInfo.active === 1">
                <div class="street-market__information-item" v-if="selectedItem">
                    <div class="street-market__information-top">
                        <div class="street-market__information-title">{{ selectedItem.name }}</div>
                        <div class="street-market__information-labels">
                            <div class="street-market__information-label" :class="selectedItem.rarity">{{ rarityMap[selectedItem.rarity] }}</div>
                        </div>
                        <div class="street-market__information-desc">
                            {{ $t(selectedItem.desc) }}
                        </div>
                    </div>

                    <div class="street-market__information-btns">
                        <BaseButton class="street-market__order-button" yellow @click = "buyItem(selectedItem.index)">
                            {{ $t('shop.buy') }}
                            <div class="street-market__order-price">
                                <span class="street-market__order-price-dollar">$</span>{{ GetFullPrice(selectedItem.price) }}
                            </div>
                        </BaseButton>
                        <base-button white class="white" v-if = "selectedItem.filterType === 'clothes'" @click = "wearItem(selectedItem.index)">
                            <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M40.1794 18.4936C35.0374 11.0236 27.2301 6.66016 20.4999 6.66016C13.7697 6.66016 5.96252 11.0237 0.819629 18.4936C0.653771 18.7345 0.5 19.0553 0.5 19.3762C0.5 19.6974 0.653771 20.0176 0.819629 20.2588C5.9626 27.7288 13.7697 32.0922 20.4999 32.0922C27.2301 32.0922 35.0373 27.7287 40.1794 20.2588C40.3462 20.0176 40.5 19.6974 40.5 19.3762C40.5 19.0553 40.3462 18.7345 40.1794 18.4936ZM20.4999 28.5603C15.428 28.5603 11.3157 24.4482 11.3157 19.3761C11.3157 14.304 15.4278 10.1919 20.4999 10.1919C25.572 10.1919 29.6841 14.304 29.6841 19.3761C29.6841 24.4482 25.572 28.5603 20.4999 28.5603Z" fill="#141A1E"/>
                                <path d="M23.9881 18.3969C22.6036 18.3969 21.48 17.2737 21.48 15.8888C21.48 14.9163 22.0343 14.0749 22.8425 13.6585C22.1196 13.3615 21.3293 13.1953 20.4994 13.1953C17.0857 13.1953 14.3179 15.9632 14.3179 19.3769C14.3179 22.7902 17.0857 25.5584 20.4997 25.5584C23.914 25.5584 26.6813 22.7909 26.6813 19.3769C26.6813 18.5466 26.5151 17.7564 26.2181 17.0334C25.8024 17.8423 24.961 18.3969 23.9881 18.3969Z" fill="#141A1E"/>
                            </svg>
                            {{ $t('street_market.try_cloth') }}
                        </base-button>
                    </div>
                </div>

                <div v-else class="street-market__information-empty">
                    <img src="./assets/images/cart.png"  class="street-market__information-empty-image" alt="">
                    <p class="street-market__information-empty-title">{{ $t('street_market.owner') }}</p>
                    <p class="street-market__information-empty-name">{{ ownerInfo.ownerName }}</p>
                    <p class="street-market__information-empty-end">{{ $t('street_market.payed_till') }} <span>{{ ownerInfo.endDate }}</span></p>
                </div>
            </div>

            <div class="street-market__information" v-else-if="owner === 0 && ownerInfo.active === 0">
                <div class="street-market__information-empty">
                    <div class="street-market__information-empty-heading">{{ $t('street_market.tent_free') }}</div>
                    <div class="street-market__information-empty-desc">{{ $t('street_market.choose_sell') }}</div>

                    <img src="./assets/images/market.png" class="street-market__information-empty-image" alt="">

                    <div class="street-market__information-btns">
                        <BaseButton class="street-market__order-button" white @click="buyMarket">
                            {{ $t('street_market.rent') }}
                            <div class="street-market__order-price">
                                <span class="street-market__order-price-dollar">$</span>{{ $formatNum(ownerInfo.price) }}
                            </div>
                        </BaseButton>
                    </div>
                </div>
            </div>

            <div class="street-market__information" v-else-if="owner === 1 && ownerInfo.active === 1">
                <div class="street-market__information-empty street-market__information-empty--active">
                    <div>
                        <div class="street-market__information-empty-heading">{{ $t('street_market.tent_active') }}</div>
                        <div class="street-market__information-empty-desc">{{ $t('street_market.choose_remove') }}</div>
                        <p class="street-market__information-empty-end">{{ $t('street_market.payed_for') }} {{ ownerInfo.endDate }}</p>
                    </div>

                    <div class="street-market__information-btns street-market__information-btns--active">
                        <BaseButton class="street-market__order-button" border @click="removeMarket">
                            {{ $t('street_market.tent_remove') }}
                        </BaseButton>
                        <BaseButton class="street-market__order-button" border @click="renewMarket">
                            {{ $t('street_market.extend') }}
                            <div class="street-market__order-price">
                                <span class="street-market__order-price-dollar">$</span>{{ $formatNum(ownerInfo.renewPrice) }}
                            </div>
                        </BaseButton>

                        <BaseButton class="btn-inv" white @click="isInInv ? loadMarketInv() : loadMyInv()">
                            {{ buttonText }}
                            <img src="./assets/images/switch-arrow.svg" class="svg-button" alt="">
                        </BaseButton>
                    </div>
                </div>
            </div>

            <div class="street-market__cards">
                <div class="street-market__cards-wrapper">
                    <InventoryItem
                        v-for="(item, index) in filteredItems"
                        :key="index || 'empty' + index"
                        :item="item"
                        class="street-market__item"
                        :selectedItem="selectedItem"
                        @select-item="selectItem"
                    />
                </div>
            </div>

            <BaseTabMenu :items="tabDefinitions" :activeTab="selectedType" isScrollable :handleTabChange="changeTab" />
        </div>

    </div>
</template>

<script>
import AppHeader from '@/components/AppHeader/AppHeader.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import InventoryItem from '@/components/popups/components/inventoryItem/InventoryItem.vue';
import { mapActions, mapGetters } from 'vuex';
import BaseTabMenu from '@/components/ui/BaseTabMenu.vue';

export default {
    name: 'StreetMarket',
    components: { BaseTabMenu, InventoryItem, BaseButton, AppHeader },
    data () {
        return {
            owner: 1, // Владелец или нет
            ownerInfo: {
                active: 1, // арендована или нет
                renewPrice: 100,
                price: 100,
                endDate: '18:00 12.12.2024', // в каком формате будет?
                marketName: 'Kevin Mackalister',
                ownerName: 'Kevin Mackalister'
            },
            isInInv: false,
            selectedType: 'all',
            selectedItem: null,
            title: this.$t('street_market.title'),

            rarityMap: {
                legend: this.$t('shop.rarity_map.legend'),
                ordinary: this.$t('shop.rarity_map.ordinary'),
                unusual: this.$t('shop.rarity_map.unusual'),
                rare: this.$t('shop.rarity_map.rare'),
                epic: this.$t('shop.rarity_map.epic')
            },

            tabDefinitions: [
                { type: 'all', icon: 'all', text: this.$t('shop.tabs.all') },
                { type: 'weapon', icon: 'weapon', text: this.$t('shop.tabs.weapon') },
                { type: 'clothes', icon: 'clothes', text: this.$t('shop.tabs.clothes') },
                { type: 'food', icon: 'food', text: this.$t('shop.tabs.food') },
                { type: 'other', icon: 'other', text: this.$t('shop.tabs.other') }
            ],

            shopItems: [
                /*
                { id: 2, name: 'Штурмовая винтовка', rarity: 'legend', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/m4', index: 1 },
                { id: 3, name: 'Пистолет', rarity: 'unusual', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/pistol', index: 2 },
                { id: 2, name: 'Штурмовая винтовка', rarity: 'legend', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/m4', index: 3 },
                { id: 3, name: 'Пистолет', rarity: 'unusual', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/pistol', index: 4 },
                { id: 2, name: 'Штурмовая винтовка', rarity: 'legend', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/m4' },
                { id: 3, name: 'Пистолет', rarity: 'unusual', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/pistol' },
                { id: 2, name: 'Штурмовая винтовка', rarity: 'legend', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/m4' },
                { id: 3, name: 'Пистолет', rarity: 'unusual', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/pistol' },
                { id: 2, name: 'Штурмовая винтовка', rarity: 'legend', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/m4' },
                { id: 3, name: 'Пистолет', rarity: 'unusual', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/pistol' }
                */
            ],

            invItem: [

            ]
            // isEquipped: true,
        };
    },
    computed: {
        ...mapGetters('inventory', ['inventoryItemsPool']),
        buttonText () {
            return this.isInInv ? this.$t('street_market.market') : this.$t('street_market.personal');
        },
        filteredItems () {
            if (this.selectedType === 'all') {
                if (this.isInInv) return this.invItem;
                else return this.shopItems;
            }
            if (this.isInInv) return this.invItem.filter(item => item.filterType === this.selectedType);
            else return this.shopItems.filter(item => item.filterType === this.selectedType);
        }
    },
    methods: {
        ...mapActions(['updateInventoryItemsPool']),
        changeTab (tabType) {
            this.selectedType = tabType;
            this.selectedItem = null; // reset selected item
        },
        selectItem (item) {
            if (this.owner === 1) {
                // item.isEquipped = !item.isEquipped;

                if (this.ownerInfo.active === 1 && item.count && this.isInInv) {
                    this.sendclient('marketSetItem', JSON.stringify({ a: item.index }));
                } else if (this.ownerInfo.active === 1 && item.count && !this.isInInv) {
                    this.sendclient('marketDeleteItem', JSON.stringify({ a: item.index }));
                } else if (this.ownerInfo.active === 0) {
                    console.log('открываем попапы');
                }
            } else {
                if (this.ownerInfo.active === 1 && item.count) {
                    // this.sendclient('marketBuyItem', JSON.stringify({ a: item.index }));
                    this.selectedItem = item;
                }
            }
        },
        buyItem (index) {
            this.sendclient('marketBuyItem', JSON.stringify({ a: index }));
        },
        wearItem (index) {
            this.sendclient('marketWearItem', JSON.stringify({ a: index }));
        },
        /*
        window.modifyVueComponent({
            App: {
                showPopup: true,
                popupContent: 'StreetMarket',
                contentSubFolder: 'shop'
            },
            StreetMarket: {
                InitializeShop: [
                    [
                        {"count":89,"additional":0,"itemID":2,"price":100},{"count":9,"additional":0,"itemID":3,"price":100},
                    ],
                    'Рынок',
                    1,
                    1,
                    3
                ]
            }
        });
        */
        InitializeShop (itemList, title, owner, isBought, hours, myInvList) {
            this.title = title;
            this.owner = owner;
            this.ownerInfo.active = isBought;
            this.ownerInfo.endDate = this.$t('leftHours', { hours });// `${hours} часов`;
            if (this.owner === 1 && isBought === 1) this.isInInv = true;
            else this.isInInv = false;
            this.shopItems = [];
            for (let i = 0; i < itemList.length; i++) {
                if (itemList[i].itemID) {
                    this.shopItems[i] = Object.assign({}, this.inventoryItemsPool[itemList[i].itemID - 1]);
                    this.shopItems[i].count = itemList[i].count;
                    this.shopItems[i].index = i + 1;
                    this.shopItems[i].additional = itemList[i].additional;
                    this.shopItems[i].price = itemList[i].price;
                    // if (activeSlots && activeSlots.indexOf(i + 1) !== -1) this.shopItems[i].isEquipped = true;
                } else this.shopItems[i] = { id: null, name: '', rarity: '', filterType: '', desc: '', img: '', count: 0, index: i + 1 };
            }

            this.invItem = [];
            if (myInvList && myInvList.length) {
                for (let i = 0; i < myInvList.length; i++) {
                    if (myInvList[i].itemID) {
                        this.invItem[i] = Object.assign({}, this.inventoryItemsPool[myInvList[i].itemID - 1]);
                        this.invItem[i].count = myInvList[i].count;
                        this.invItem[i].index = i + 1;
                        this.invItem[i].additional = myInvList[i].additional;
                        this.invItem[i].price = myInvList[i].price;
                        // if (activeSlots && activeSlots.indexOf(i + 1) !== -1) this.invItem[i].isEquipped = true;
                    } else this.invItem[i] = { id: null, name: '', rarity: '', filterType: '', desc: '', img: '', count: 0, index: i + 1 };
                }
            }
        },
        MakeOwner (myInvList, hours) {
            this.owner = 1;
            this.ownerInfo.active = 1;
            this.ownerInfo.endDate = this.$t('leftHours', { hours });// `${hours} часов`;
            this.invItem = [];
            if (myInvList && myInvList.length) {
                for (let i = 0; i < myInvList.length; i++) {
                    if (myInvList[i].itemID) {
                        this.invItem[i] = Object.assign({}, this.inventoryItemsPool[myInvList[i].itemID - 1]);
                        this.invItem[i].count = myInvList[i].count;
                        this.invItem[i].index = i + 1;
                        this.invItem[i].additional = myInvList[i].additional;
                        this.invItem[i].price = myInvList[i].price;
                        // if (activeSlots && activeSlots.indexOf(i + 1) !== -1) this.invItem[i].isEquipped = true;
                    } else this.invItem[i] = { id: null, name: '', rarity: '', filterType: '', desc: '', img: '', count: 0, index: i + 1 };
                }
            }
            this.isInInv = true;
        },
        updateHours (hours) {
            this.ownerInfo.endDate = this.$t('leftHours', { hours });// `${hours} часов`;
        },
        deleteSlotInfo (number) {
            this.invItem[number - 1] = { id: null, name: '', rarity: '', filterType: '', desc: '', img: '', index: number };

            this.Rerender();
        },
        updateSlotInfo (number, itemInfo) {
            if (!itemInfo || itemInfo.itemID === 0) {
                this.shopItems[number - 1] = { id: null, name: '', rarity: '', filterType: '', desc: '', img: '', index: number };
            } else {
                this.shopItems[number - 1] = Object.assign({}, this.inventoryItemsPool[itemInfo.itemID - 1]);
                this.shopItems[number - 1].count = itemInfo.count;
                this.shopItems[number - 1].index = number;
                this.shopItems[number - 1].additional = itemInfo.additional;
                this.shopItems[number - 1].price = itemInfo.price;
            }

            this.Rerender();
        },
        GetFullPrice (price) {
            return this.$formatNum(price);
        },
        Rerender () {
            const lastType = this.selectedType;
            this.selectedType = '';
            this.selectedType = lastType;
        },
        buyMarket () {
            this.sendclient('marketArend');
        },
        renewMarket () {
            this.sendclient('marketReNew');
        },
        removeMarket () {
            this.sendclient('marketCancel');
        },
        loadMyInv () {
            this.isInInv = true;
            // this.sendclient('marketGoToInv');
        },
        loadMarketInv () {
            this.isInInv = false;
            // this.sendclient('marketInv');
        }
    }

};
</script>

<style lang="scss">
.street-market__information-btns{
    .white{
        max-width: 313px;
        margin: 0 auto;
        height: 112px;

        svg{
            width: 40px;
            height: 40px;
            flex-shrink: 0;
            margin-right: 18px;
            path{
                fill: #141A1E;
            }
        }
    }
}
</style>

<style lang="scss">
.street-market{
    display: flex;
    height: 100%;
    flex-direction: column;
    background: radial-gradient(138.02% 65.96% at 50.00% -0.00%, rgba(161, 129, 91, 0.80) 0%, rgba(39, 32, 20, 0.80) 100%);

    .svg-button{
        height: 50px;
        margin-bottom: 0;
        margin-left: 12px;
    }
    &__header-name{
        color: #FFC67E;
    }
    &__item{
        margin: 0 12px 24px;
    }
    &__wrapper{
        padding: 140px 128px;
        display: grid;
        grid-template-columns: 580px 1fr 298px;
        grid-gap: 80px;
        height: 100%;
    }

    &__order{
        &-button {
            height: 4.45vw;
            padding: 0 1.75vw;
            font-size: 1.45vw;
            white-space: nowrap;
        }
        &-price {
            margin-left: .725vw;
            background-color: var(--dark);
            color: var(--white);
            padding: .25vw .5vw;
            border-radius: .25vw;
            font-weight: 700;
            font-size: 1.6vw;
            &-dollar {
                display: inline-block;
                color: var(--gold);
                margin-right: .16vw;
            }
        }
    }

    &__cards{
        overflow-y: auto;
        margin: -5vw 0;
        padding: 5vw 0.2vw;

        &::-webkit-scrollbar{
            width: 0;
            height: 0;
            display: none;
        }

        &-count{
            padding: 0 .7vw ;
            height: 1.7vw;
            background: var(--white);
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            clip-path: polygon(0 0, 100% 0, 91% 100%, 0% 100%);
            font-size: 1.3vw;
            line-height: 100%;
            letter-spacing: -0.03em;
            font-weight: 700;
            color: #141A1E;

        }

        &-put-on{
            padding: 13px;
            width: 1.7vw;
            height: 1.7vw;
            background: #27AE60;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 0;
            top: 0;
            clip-path: polygon(19% 0, 100% 0, 100% 100%, 0% 100%);

            img{
                height: 70% !important;
            }
        }

        &-wrapper{
            display: flex;
            flex-wrap: wrap;
        }

        &-update{
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-gap: 1vw;
            grid-column: 1/-1;
            position: relative;

            .btn{
                svg{
                    height: 30%;
                    width: auto;
                    margin-left: .5vw;
                }
            }

            .street-market__cards-item--empty{
                opacity: .3;
            }
        }

        &-shop{
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 3vw;

            h5{
                font-weight: 700;
                font-size: 1.3vw;
                line-height: 140%;

                letter-spacing: 0.05em;
                text-transform: uppercase;

                color: var(--white);
            }
            p{
                font-size: 1.3vw;
                line-height: 140%;
                display: flex;
                align-items: flex-end;

                color: var(--white);

                opacity: 0.6;
            }
        }

        &-item{
            position: relative;
            width: 220px;
            height: 280px;
            display: flex;
            border-radius: .2vw;
            padding: .5vw;
            align-items: flex-end;
            overflow: hidden;
            flex-shrink: 0;

            &--empty{
                background: url(./assets/images/empty.svg) no-repeat center/contain;
            }

            &--legend{
                background: url(./assets/images/legend.svg) no-repeat center/cover;
            }
            &--epic{
                background: url(./assets/images/epic.svg) no-repeat center/cover;
            }
            &--rare{
                background: url(./assets/images/rare.svg) no-repeat center/cover;
            }
            &--ordinary{
                background: url(./assets/images/ordinary.svg) no-repeat center/cover;
            }
            &--unusual{
                background: url(./assets/images/unusual.svg) no-repeat center/cover;
            }

            &--active{
                box-shadow: 0 0 0 .2vw var(--white);
                &::before{
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    content: "";
                    display: block;
                    width: 100%;
                    height: 100%;
                    background: url(./assets/images/active.svg) no-repeat center/cover;
                }

            }

            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
                position: absolute;
            }

            p{
                font-weight: 700;
                font-size: 1vw;
                line-height: 100%;
                position: relative;
                z-index: 3;
            }

        }
    }

    &__information{
        height: 100%;

        &-btns{
            width: 100%;
            display: grid;
            grid-gap: 32px;

            &--active{
                grid-gap: 12px;
            }
        }

        &-top{
            text-align: center;
        }

        &-item{
            padding-top: 1vw;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        &-put-on{
            padding: 13px;
            width: 1.7vw;
            height: 1.7vw;
            background: #27AE60;
            margin-right: .5vw;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-labels{
            display: flex;
        }

        &-label{
            font-weight: 700;
            font-size: 34px;

            padding: 10px 24px;
            line-height: 100%;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            font-family: var(--font-stretch);
            color: var(--white) ;
            width: max-content;
            margin: 0 auto;

            &.legend{
                background: linear-gradient(211.69deg, #FFCD4C 33.85%, #FFA800 100%);
            }
            &.epic{
                background: linear-gradient(211.69deg, #BE6AFF 20.31%, #7A28FF 100%);
            }
            &.rare{
                background: linear-gradient(211.69deg, #48FDC7 0%, #009D6E 100%);
            }
            &.ordinary{
                background: linear-gradient(212deg, #96ABB5 43.23%, #72848B 100%);
            }
            &.unusual{
                background: linear-gradient(211.69deg, #5FCFFF 35.94%, #2780A6 100%);
            }
        }

        &-title{
            font-weight: 400;
            font-size: 3vw;
            line-height: 100%;
            margin-bottom: 1vw;

            justify-content: center;

            display: flex;
            align-items: flex-end;

            color: #FFFFFF;
        }
        &-desc{
            margin-top: 1vw;
            font-weight: 500;
            font-size: 1vw;
            line-height: 140%;
            color: var(--white);

            opacity: 0.6;
        }
        &-empty{
            display: flex;
            height: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &--active{
                justify-content: space-between;
            }
            img{
                height: 283px;
                margin-bottom: 46px;
            }

            &--image{
                margin-top: 82px;
            }

            &-heading{
                color: #FFF;
                text-align: center;
                font-size: 54px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%; /* 54px */
                margin-bottom: 24px;
            }

            &-desc{
                color: #F1F1F1;
                text-align: center;
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%; /* 44.8px */
                opacity: .6;
                margin-bottom: 24px;
            }

            &-title{
                color: #F1F1F1;
                text-align: center;
                font-size: 32px;
                font-weight: 500;
                opacity: 0.6;
                line-height: 140%; /* 44.8px */
                margin-bottom: 16px;
            }
            &-name{
                color: #FFF;
                text-align: center;
                font-size: 54px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%; /* 54px */
                margin-bottom: 16px;

            }
            &-end{
                color: #FFED4C;
                text-align: center;
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%; /* 44.8px */
                span{
                    display: block;
                }
            }

        }
    }

    &__buttons{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: .5vw;
        &-item{
            background: linear-gradient(119.36deg, rgba(241, 241, 241, 0.19) 0%, rgba(241, 241, 241, 0) 100%);
            border-radius: .1vw;
            height: 6.5vw;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            font-weight: 700;
            font-size: 1vw;
            line-height: 120%;

            text-align: center;
            letter-spacing: 0.04em;
            text-transform: uppercase;

            color: #F1F1F1;

            &--use{
                background: #FFED4C;
                color: #141A1E;
                box-shadow: inset 0px -26px 80px rgba(241, 241, 241, 0.4);
            }
            &--clothes{
                background: #F1F1F1;
                color: #141A1E;
                box-shadow: inset 0px -26px 80px rgba(241, 241, 241, 0.4);
            }

            svg{
                height: 2vw;
                width: auto;
                margin-bottom: .5vw;
            }
        }
    }
}
</style>
