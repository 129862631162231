<template>
    <div>
    <svg v-if="border === 'tabMenuBorder'" preserveAspectRatio="none" width="480" height="142" viewBox="0 0 480 142" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M-1.47268e-05 2.39538e-05L480 -8.82149e-06L480 142L-4.76837e-06 142L-1.47268e-05 2.39538e-05ZM471.654 135.274L468.045 80.5289L473.684 35.1263L468.045 20.5526L473.684 3.54999L376.692 3.54999L364.06 7.28684L354.586 3.54999L224.436 7.28684L219.699 3.55001L202.556 7.28684L184.06 3.55001L76.6918 5.60528L4.73682 3.55002L12.406 12.1448L4.73682 12.1448L10.3759 54.7447L7.66918 63.5263L12.406 79.5947L7.66918 127.8L12.406 136.395L122.707 131.163L131.504 136.395L149.323 131.163L149.323 138.263L155.188 131.163L213.383 136.395L323.008 131.163L408.496 138.263L471.654 135.274Z" fill="#F1F1F1"/>
    </svg>
    <svg v-if="border === 'gradientBorder'" preserveAspectRatio="xMinYMax meet" width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x=".08vw" y=".08vw" width="calc(100% - .16vw)" height="calc(100% - .16vw)" rx=".05vw" stroke="url(#paint0_linear_1644_17503)" stroke-opacity="0.4" stroke-width=".16vw"/>
        <defs>
            <linearGradient id="paint0_linear_1644_17503" x1="1542" y1="188" x2="1447.86" y2="-286.96" gradientUnits="userSpaceOnUse">
                <stop stop-color="white"/>
                <stop offset="1" stop-color="white" stop-opacity="0"/>
            </linearGradient>
        </defs>
    </svg>
    <svg v-if="border === 'borderLine'" width="526" height="24" preserveAspectRatio="xMinYMax meet" viewBox="0 0 526 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M525.667 24V12.3077H440.361L421.822 0L407.918 12.3077L216.906 0L209.954 12.3076L184.794 0L157.649 12.3076L0.333496 5.54968V23.9999L525.667 24Z" :fill="fill"/>
    </svg>
    </div>
</template>
<script>
export default {
    props: {
        border: String,
        fill: { type: String, default: '#B784C9' }
    }
};
</script>
<style lang="scss" scoped>
svg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
}
</style>
