<template>
    <div class="estate__menu">
        <div v-for="item in items"
             :key="item.id"
             class="estate__menu-item"
             :class="{'estate__menu-item--active': activeItem === item.id}"
             @click="selectItem(item.id)">
            <IconEstateList :icon=item.icon />
            {{ item.name }}
        </div>
    </div>
</template>

<script>
import IconEstateList from '@/components/icons/IconEstateList.vue';

export default {
    name: 'EstateMenu',
    components: { IconEstateList },
    props: {
        items: Array,
        activeItem: Number
    },
    methods: {
        selectItem (id) {
            this.$emit('change', id);
        }
    }
};
</script>

<style lang="scss">
.estate {
    &__menu {
        grid-column: 3 span;
        display: flex;
        flex-direction: column;
        &-icon-container {
            margin-right: .35vw;
        }
        &-item {
            height: 6vw;
            max-width: 19.8vw;
            position: relative;
            margin-top: 1vw;
            padding-left: 1.5vw;
            text-transform: uppercase;
            font-family: var(--font-stretch);
            font-weight: 700;
            font-size: 1.75vw;
            display: flex;
            align-items: center;
            background-color: var(--dark);
            svg {
                width: 1.75vw;
                height: auto;
            }
            &:first-child {
                margin-top: 0;
            }
            &::after {
                content:"";
                position: absolute;
                right: -1.95vw;
                bottom: 0;
                top: 0;
                background-image: url("@/assets/images/estate/menu-bg-1.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                width: 2vw;
            }
            &:nth-of-type(even) {
                &::after {
                    background-image: url("@/assets/images/estate/menu-bg-2.svg");
                }
            }
            &:nth-of-type(even).estate__menu-item--active {
                color: var(--dark);
                &::after {
                    background-image: url("@/assets/images/estate/menu-bg-2-actvie.svg");
                }
            }
            &--active {
                color: var(--dark);
                background: var(--white);
                &::after {
                    background-image: url("@/assets/images/estate/menu-bg-1-active.svg");
                }
                svg{
                    path{
                        fill: var(--dark);
                    }
                }
            }
        }
    }
}
</style>
