<template>
    <div class="pda" :style="{background: `url(${imageSource('bg.png', 'pda')}) no-repeat center/cover, radial-gradient(66.94% 140.08% at 50% 0%, ${gradient.startColor} 0%, ${gradient.finishColor} 100%)`}">
        <AppHeader backButton>
            <template #title>{{ $t('pda.header') }}</template>
        </AppHeader>
        <div v-if="!isSecondaryMenuOpened" class="pda-content">
            <div class="pda-menu">
                <div class="pda-menu__controls">
                    <button class="pda-menu__controls-button" @click="getBtnControls('main')">{{ $t('pda.controls.main') }}</button>
                    <button class="pda-menu__controls-button" @click="getBtnControls('local')">{{ $t('pda.controls.sub') }}</button>
                </div>
                <BaseTabMenu class="pda-menu__tabs" :items=tabDefinitions :activeTab=selectedType :handleTabChange=changeTab />
            </div>
            <div v-if="selectedType === 'search'" class="org__info pda-info org__info--team pda-info--search">
                <template v-if="searchTab === 'extra'">
                    <BaseInput
                        class="pda-info__search-input"
                        :label="$t('pda.search_citizen')"
                        v-model="search"
                        placeholder="Kevin Mackalister / ID 100"
                    >
                        <div class="pda-info__search-input-button" @click="searchPlayer"></div>
                    </BaseInput>
                    <div v-for="(item, index) in filteredSearchResults('extra')" :key="index" class="org__info-member">
                        <div class="org__info-member-item">
                            <!--<IconWanted :level="item.wantedMultiplier" />
                            <div class="pda-info__multiplier">X{{ item.wantedMultiplier }}</div>-->
                            <div class="org__info-member-info">
                                <div class="org__info-member-name">{{ item.title }}</div>
                                <div class="org__info-member-rank">{{ item.info }}</div>
                            </div>
                        </div>
                        <div class="org__info-member-item">
                            <BaseButton class="pda-info__info-button" glass @click="openSecondaryMenu(item)">
                                <IconInfoTransparent class="org__info-member-button-icon" />
                            </BaseButton>
                            <BaseButton class="pda-info__info-button" white @click="getorder(item.pid)">
                                <IconNotice icon="success" class="pda-info__approve-icon" />
                            </BaseButton>
                        </div>
                    </div>
                </template>
                <template v-if="searchTab === 'wanted'">
                    <BaseInput
                        class="pda-info__search-input"
                        :label="$t('pda.search_citizen')"
                        v-model="search"
                        placeholder="Kevin Mackalister / ID 100"
                    >
                        <div class="pda-info__search-input-button" @click="searchPlayer"></div>
                    </BaseInput>
                    <div v-for="(item, index) in filteredSearchResults('wanted')" :key="index" class="org__info-member">
                        <div class="org__info-member-item">
                            <IconWanted :level="item.wantedMultiplier" />
                            <div class="pda-info__multiplier">X{{ item.wantedMultiplier }}</div>
                            <div class="org__info-member-info">
                                <div class="org__info-member-name">{{ item.title }}</div>
                                <div class="org__info-member-rank">{{ item.info }}</div>
                            </div>
                        </div>
                        <div class="org__info-member-item">
                            <BaseButton class="pda-info__info-button" glass @click="openSecondaryMenu(item)">
                                <IconInfoTransparent class="org__info-member-button-icon" />
                            </BaseButton>
                            <BaseButton class="pda-info__info-button" white @click="getWantedPlayer(item.pid)">
                                <IconNotice icon="success" class="pda-info__approve-icon" />
                            </BaseButton>
                        </div>
                    </div>
                </template>
                <template v-if="searchTab === 'auto'">
                    <BaseInput
                        class="pda-info__search-input"
                        :label="$t('pda.search_citizen')"
                        v-model="search"
                        placeholder="Kevin Mackalister / ID 100"
                    >
                        <div class="pda-info__search-input-button" @click="searchPlayer"></div>
                    </BaseInput>
                    <div v-for="(item, index) in filteredSearchResults('auto')" :key="index" class="org__info-member">
                        <div class="org__info-member-item">
                            <!--
                            <IconWanted :level="item.wantedMultiplier" />
                            <div class="pda-info__multiplier">X{{ item.wantedMultiplier }}</div>-->
                            <div class="org__info-member-info">
                                <div class="org__info-member-name">{{ item.title }}</div>
                                <div class="org__info-member-rank">{{ item.info }}</div>
                            </div>
                        </div>
                        <div class="org__info-member-item">
                            <BaseButton class="pda-info__info-button" glass @click="openSecondaryMenu(item)">
                                <IconInfoTransparent class="org__info-member-button-icon" />
                            </BaseButton>
                            <BaseButton class="pda-info__info-button" white @click="getauto(item.uniqueid)">
                                <IconNotice icon="success" class="pda-info__approve-icon" />
                            </BaseButton>
                        </div>
                    </div>
                </template>
                <!-- Что угодно, но не div-->
                <section class="org__info-search pda-info__search">
                    <div class="org__info-search-menu">
                        <div class="org__info-search-menu-item"
                             :class="{ 'org__info-search-menu-item--active': searchTab === 'extra' }"
                             @click="ChangeSearchTab('extra')">
                            {{ $t('pda.search_menu.extra') }}
                        </div>
                        <div class="org__info-search-menu-item"
                             :class="{ 'org__info-search-menu-item--active': searchTab === 'wanted' }"
                             @click="ChangeSearchTab('wanted')">
                            {{ $t('pda.search_menu.wanted') }}
                        </div>
                        <div class="org__info-search-menu-item"
                             :class="{ 'org__info-search-menu-item--active': searchTab === 'auto' }"
                             @click="ChangeSearchTab('auto')">
                            {{ $t('pda.search_menu.cars') }}
                        </div>
                    </div>
                </section>
            </div>
            <div v-if="selectedType === 'fine'" class="pda-info pda-info--fine">
                <div class="pda-info__title">{{ $t('pda.fine_creation') }}</div>
                <div class="pda-info__form">
                    <BaseInput v-model="fineName" class="pda-info__input pda-info__input--name" type="text" name="name" :label="$t('pda.insert_name')" placeholder="Kevin Mackalister / ID 100" />
                    <BaseInput v-model="fineInput" class="pda-info__input pda-info__input--fine" type="text" name="fine" :label="$t('pda.fine_size')" placeholder="$" :dollar="true" />
                </div>
                <div class="pda-info__text">{{ $t('pda.fine_set_amount') }}. {{ $t('pda.fine_size_from') }} ${{ $formatNum(fine.minimum) }}
                    {{ $t('pda.fine_size_to') }} ${{ $formatNum(fine.maximum) }}</div>
                <BaseButton white class="pda-info__submit-fine" @click="finePlayer(fineName, fineInput)">
                    <IconBaseTabMenu :iconSize="2.44866" type="fine" />
                    {{ $t('pda.fine_submit') }}
                </BaseButton>
            </div>
            <div v-if="selectedType === 'wanted'" class="pda-info pda-info--wanted">
                <div class="pda-info__title">{{ $t('pda.wanted_submit') }}</div>
                <div class="pda-info__form">
                    <BaseInput v-model="wantedName" class="pda-info__input pda-info__input--name" type="text" name="name" :label="$t('pda.insert_name')" placeholder="Kevin Mackalister / ID 100" />
                </div>
                <BaseButton white class="pda-info__submit-fine" @click="wantedPlayer(wantedName)">
                    <IconBaseTabMenu :iconSize="2.44866" type="fine" />
                    {{ $t('pda.wanted_submit') }}
                </BaseButton>
            </div>
            <div v-if="selectedType === 'database'" class="pda-info pda-info--calls">
                <div v-for="(item, index) in searchListWanted" :key="index" class="org__info-member">
                    <div class="org__info-member-item">
                        <div class="org__info-member-id">{{ getDistanceUnits(item.distance) }}</div>
                        <div class="org__info-member-info">
                            <div class="org__info-member-name">{{ item.title }}</div>
                            <div class="org__info-member-rank">{{ item.info }}</div>
                        </div>
                        <BaseButton class="pda-info__info-button" white @click="getWantedPlayer(item.pid)">
                            <IconNotice icon="success" class="pda-info__approve-icon" />
                        </BaseButton>
                    </div>
                </div>
            </div>
            <div v-else-if="selectedType === 'calls'" class="pda-info pda-info--calls">
                <div v-for="(item, index) in searchListExtra" :key="index" class="org__info-member">
                    <div class="org__info-member-item">
                        <div class="org__info-member-id">{{ getDistanceUnits(item.distance) }}</div>
                        <div class="org__info-member-info">
                            <div class="org__info-member-name">{{ item.title }}</div>
                            <div class="org__info-member-rank">{{ item.info }}</div>
                        </div>
                        <BaseButton class="pda-info__info-button" white @click="getorder(item.pid)">
                            <IconNotice icon="success" class="pda-info__approve-icon" />
                        </BaseButton>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="pda-content pda-content--secondary">
            <div class="pda-secondary-menu">
                <div class="pda-secondary-menu__back" @click="closeSecondaryMenu">
                    <IconDropdownBack />
                </div>
                <BaseTabMenu class="pda-secondary-menu__list" :items=secondaryMenuTabDefinitions :activeTab=selectedType :handleTabChange=changeTab isScrollable />
            </div>
            <div class="pda-info pda-info--search">
                <div class="pda-info__heading">
                    <div class="org__info-member-item">
                        <IconWanted v-if = "currentUserData.wantedMultiplier > 0" class="pda-info__level" :level="currentUserData.wantedMultiplier" />
                        <div v-if = "currentUserData.wantedMultiplier > 0" class="pda-info__multiplier pda-info__multiplier--secondary">X{{ currentUserData.wantedMultiplier }}</div>
                        <div class="org__info-member-info pda-info__about">
                            <div class="org__info-member-name">{{ currentUserData.title }}</div>
                            <div class="org__info-member-rank">{{ currentUserData.info }}</div>
                        </div>
                    </div>
                    <div class="org__info-member-item">
                        <BaseButton class="pda-info__secondary-button" glass>{{ $t('pda.remove_wanted') }}</BaseButton>
                        <BaseButton class="pda-info__secondary-button" white>{{ $t('pda.classify') }}</BaseButton>
                    </div>
                </div>
                <div v-if="selectedType === 'info'" class="pda-info__grid">
                    <div v-for="(item, index) in currentUserInfo" :key="index" class="pda-info__grid-item">
                        <div class="org__info-member-rank">{{ item.title }}</div>
                        <div class="org__info-member-name pda-info__grid-item-name">{{ item.definition }}</div>
                    </div>
                </div>
                <div v-if="selectedType === 'transport'" class="pda-info__table">
                    <div class="pda-info__table-heading">
                        <div class="pda-info__table-heading-title">{{ $t('pda.car_name') }}</div>
                        <div class="pda-info__table-heading-states">
                            <div class="pda-info__table-heading-state">{{ $t('pda.gov_price') }}</div>
                            <div class="pda-info__table-heading-state">{{ $t('pda.plate') }}</div>
                        </div>
                    </div>
                    <div class="pda-info__table-content">
                        <div v-for="(item, index) in transportList" :key="index" class="pda-info__table-item">
                            <div class="pda-info__table-title">{{ item.title }}</div>
                            <div class="pda-info__table-info">
                                <div class="pda-info__table-price"><span>$</span>{{$formatNum(item.price)}}</div>
                                <div class="pda-info__table-price">{{ item.plate ? item.plate : $t('pda.absent') }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="selectedType === 'penalties'" class="pda-info__table">
                    <div class="pda-info__table-heading">
                        <div class="pda-info__table-heading-title">{{ $t('pda.fine_creator') }}</div>
                        <div class="pda-info__table-heading-states">
                            <div class="pda-info__table-heading-state">{{ $t('pda.fine_size') }}</div>
                            <!--<div class="pda-info__table-heading-state">{{ $t('pda.fine_creator') }}</div>-->
                        </div>
                    </div>
                    <div class="pda-info__table-content">
                        <div v-for="(item, index) in penaltiesList" :key="index" class="pda-info__table-item">
                            <div class="pda-info__table-title">{{ item.officer }}</div>
                            <div class="pda-info__table-info">
                                <div class="pda-info__table-price"><span>$</span>{{$formatNum(item.price)}}</div>
                                <!--<div class="pda-info__table-price">{{ item.officer ? item.officer : $t('pda.absent') }}</div>-->
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="selectedType === 'crimes'" class="pda-info__table">
                    <div class="pda-info__table-heading">
                        <div class="pda-info__table-heading-title">{{ $t('pda.crime_about') }}</div>
                        <div class="pda-info__table-heading-states">
                            <div class="pda-info__table-heading-state">{{ $t('pda.officerName') }}</div>
                        </div>
                    </div>
                    <div class="pda-info__table-content">
                        <div v-for="(item, index) in crimesList" :key="index" class="pda-info__table-item">
                            <div class="pda-info__table-title">{{ item.title }}</div>
                            <div class="pda-info__table-info">
                                <div class="pda-info__table-price">{{ item.officer ? item.officer : $t('pda.absent') }}</div>
                                <!--
                                <div class="pda-info__table-wanted">
                                    <IconWanted class="pda-info__level" :level="item.wantedMultiplier" />
                                    <div class="pda-info__multiplier pda-info__multiplier--secondary">X{{ item.wantedMultiplier }}</div>
                                </div>
                                -->
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="selectedType === 'licenses'" class="pda-info__table">
                    <div class="pda-info__table-heading">
                        <div class="pda-info__table-heading-title">{{ $t('pda.license_name') }}</div>
                        <div class="pda-info__table-heading-states">
                            <div class="pda-info__table-heading-state">{{ $t('pda.availability') }}</div>
                        </div>
                    </div>
                    <div class="pda-info__table-content">
                        <div v-for="(item, index) in licensesList" :key="index" class="pda-info__table-item">
                            <div class="pda-info__table-title">{{ item.title }}</div>
                            <div class="pda-info__table-info">
                                <div class="pda-info__table-price">{{ item.acquired ? $t('pda.available') : $t('pda.absent') }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AppHeader from '@/components/AppHeader/AppHeader.vue';
import BaseTabMenu from '@/components/ui/BaseTabMenu.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import IconWanted from '@/components/icons/IconWanted.vue';
import IconInfoTransparent from '@/components/icons/IconInfoTransparent.vue';
import IconNotice from '@/components/icons/IconNotice.vue';
import BaseInput from '@/components/ui/BaseInput.vue';
import IconBaseTabMenu from '@/components/icons/IconBaseTabMenu.vue';
import IconDropdownBack from '@/components/icons/IconDropdownBack.vue';

export default {
    name: 'myPda',
    components: {
        IconDropdownBack,
        IconBaseTabMenu,
        BaseInput,
        IconNotice,
        IconInfoTransparent,
        IconWanted,
        BaseButton,
        BaseTabMenu,
        AppHeader
    },
    data () {
        return {
            selectedType: 'search',
            searchPlayerText: '',
            fineName: '',
            wantedName: '',
            fineInput: '',
            search: '',
            isSecondaryMenuOpened: false,
            currentUserData: null,
            // currentUserData: { wantedMultiplier: 4, title: 'Kevin Mackalister', info: 'В зоне видимости' },
            currentUserInfo: [
                /*
                { title: this.$t('pda.address'), definition: 'Tosin Bunar 18/32' },
                { title: this.$t('pda.was_online'), definition: 'Вчера в 18:30' },
                { title: this.$t('pda.was_online'), definition: 'Вчера в 18:30' },
                { title: this.$t('pda.address'), definition: 'Tosin Bunar 18/32' },
                { title: this.$t('pda.was_online'), definition: 'Вчера в 18:30' },
                { title: this.$t('pda.was_online'), definition: 'Вчера в 18:30' },
                { title: this.$t('pda.address'), definition: 'Tosin Bunar 18/32' },
                { title: this.$t('pda.was_online'), definition: 'Вчера в 18:30' },
                { title: this.$t('pda.was_online'), definition: 'Вчера в 18:30' },
                { title: this.$t('pda.address'), definition: 'Tosin Bunar 18/32' },
                { title: this.$t('pda.was_online'), definition: 'Вчера в 18:30' },
                { title: this.$t('pda.address'), definition: 'Tosin Bunar 18/32' },
                { title: this.$t('pda.was_online'), definition: 'Вчера в 18:30' },
                { title: this.$t('pda.was_online'), definition: 'Вчера в 18:30' },
                { title: this.$t('pda.address'), definition: 'Tosin Bunar 18/32' },
                { title: this.$t('pda.was_online'), definition: 'Вчера в 18:30' },
                { title: this.$t('pda.was_online'), definition: 'Вчера в 18:30' },
                { title: this.$t('pda.address'), definition: 'Tosin Bunar 18/32' },
                { title: this.$t('pda.was_online'), definition: 'Вчера в 18:30' }
                */
            ],
            fine: {
                minimum: 100,
                maximum: 10000
            },
            tabDefinitions: [
                { type: 'search', text: this.$t('pda.tabs.search'), icon: 'search' },
                { type: 'fine', text: this.$t('pda.tabs.fine'), icon: 'fine' },
                { type: 'wanted', text: this.$t('pda.tabs.wanted'), icon: 'wanted' },
                { type: 'calls', text: this.$t('pda.tabs.calls'), icon: 'call' },
                // { type: 'nearby', text: this.$t('pda.tabs.nearby'), icon: 'family' },
                { type: 'database', text: this.$t('pda.tabs.database'), icon: 'database' }
            ],
            secondaryMenuTabDefinitions: [
                { type: 'info', text: this.$t('pda.secondary_tabs.info') },
                { type: 'transport', text: this.$t('pda.secondary_tabs.transport') },
                { type: 'licenses', text: this.$t('pda.secondary_tabs.licenses') },
                { type: 'crimes', text: this.$t('pda.secondary_tabs.crimes') },
                { type: 'penalties', text: this.$t('pda.secondary_tabs.penalties') }
            ],
            gradient: {
                startColor: '#041115',
                finishColor: '#000'
            },
            searchTab: 'extra',
            searchListExtra: [
                // { title: 'Kevin Mackalister1', info: 'В зоне видимости' },
                // { title: 'Kevin Mackalister', info: 'В зоне видимости' },
                // { title: 'Kevin Mackalister', info: '24 года' },
                // { title: 'Kevin Mackalister', info: 'В зоне видимости' },
                // { title: 'Kevin Mackalister', info: 'В зоне видимости' },
                // { title: 'Kevin Mackalister', info: '24 года' },
                // { title: 'Kevin Mackalister', info: 'В зоне видимости' }
            ],
            searchListWanted: [
                // { wantedMultiplier: 4, title: 'Kevin Wanted', info: 'В зоне видимости' },
                // { wantedMultiplier: 1, title: 'Kevin Mackalister', info: 'В зоне видимости' },
                // { wantedMultiplier: 5, title: 'Kevin Mackalister', info: '24 года' },
                // { wantedMultiplier: 3, title: 'Kevin Mackalister', info: 'В зоне видимости' },
                // { wantedMultiplier: 2, title: 'Kevin Mackalister', info: 'В зоне видимости' },
                // { wantedMultiplier: 4, title: 'Kevin Mackalister', info: '24 года' },
                // { wantedMultiplier: 3, title: 'Kevin Mackalister', info: 'В зоне видимости' }
            ],
            searchListAuto: [
                // { wantedMultiplier: 4, title: 'Kevin Auto', info: 'В зоне видимости' },
                // { wantedMultiplier: 1, title: 'Kevin Mackalister', info: 'В зоне видимости' },
                // { wantedMultiplier: 5, title: 'Kevin Mackalister', info: '24 года' },
                // { wantedMultiplier: 3, title: 'Kevin Mackalister', info: 'В зоне видимости' },
                // { wantedMultiplier: 2, title: 'Kevin Mackalister', info: 'В зоне видимости' },
                // { wantedMultiplier: 4, title: 'Kevin Mackalister', info: '24 года' },
                // { wantedMultiplier: 3, title: 'Kevin Mackalister', info: 'В зоне видимости' }
            ],
            transportList: [
                /*
                { title: 'Mustang', price: '14500', plate: '9444-KH-4' },
                { title: 'Camaro', price: '134500', plate: '1337-AI-1' },
                { title: 'Honda NSX', price: '22150', plate: null },
                { title: 'Polo Sedan', price: '500', plate: '1488-ME-2' },
                { title: 'Golf ', price: '1400', plate: null },
                { title: 'Citroen C4', price: '134500', plate: '1337-AI-1' },
                { title: 'Honda NSX', price: '22150', plate: null }
                */
            ],
            penaltiesList: [
                /*
                { title: 'Баловался', price: '199500', officer: 'Flazy Fad' },
                { title: 'Кока-Кола', price: '1119500', officer: 'El Capone' },
                { title: 'Превышение полномочий', price: '22150', officer: 'Donny Hayes' },
                { title: 'Баловался', price: '199500', officer: 'Flazy Fad' },
                { title: 'Кока-Кола', price: '1119500', officer: 'El Capone' },
                { title: 'Превышение полномочий', price: '22150', officer: 'Donny Hayes' },
                { title: 'Баловался', price: '199500', officer: 'Flazy Fad' }
                */
            ],
            crimesList: [
                /*
                { title: 'Избил прохожего ногами', wantedMultiplier: 1 },
                { title: 'Наезд на пешехода', wantedMultiplier: 2 },
                { title: 'Разбой', wantedMultiplier: 3 },
                { title: 'Взял в заложники девушку', wantedMultiplier: 4 },
                { title: 'Убийство', wantedMultiplier: 5 },
                { title: 'Избил прохожего ногами', wantedMultiplier: 1 },
                { title: 'Наезд на пешехода', wantedMultiplier: 2 },
                { title: 'Разбой', wantedMultiplier: 3 },
                { title: 'Взял в заложники девушку', wantedMultiplier: 4 },
                { title: 'Убийство', wantedMultiplier: 5 }
                */
            ],
            licensesList: [
                /*
                { title: 'Лицензия на оружие', acquired: true },
                { title: 'Водительское удостоверение', acquired: true },
                { title: 'Лицензия на ловлю рыбы', acquired: false }
                */
            ],
            transportLoaded: false,
            penaltiesLoaded: false,
            crimeListLoaded: false,
            licensesListLoaded: false,
            wantedListLoaded: false,
            vehicleListLoaded: false
        };
    },
    computed: {
        filteredSearchResults () {
            return (type) => {
                const searchResults = [];

                switch (type) {
                case 'extra':
                    searchResults.push(
                        ...this.searchListExtra.filter(member => {
                            return member.title.toLowerCase().includes(this.search.toLowerCase());
                        })
                    );
                    break;
                case 'wanted':
                    searchResults.push(
                        ...this.searchListWanted.filter(member => {
                            return member.title.toLowerCase().includes(this.search.toLowerCase());
                        })
                    );
                    break;
                case 'auto':
                    searchResults.push(
                        ...this.searchListAuto.filter(member => {
                            return member.title.toLowerCase().includes(this.search.toLowerCase());
                        })
                    );
                    break;
                default:
                    break;
                }

                return searchResults;
            };
        }
    },

    methods: {
        /*
        window.modifyVueComponent({
            App: {
                showPopup: true,
                popupContent: 'MyPda',
                contentSubFolder: 'pda'
            },
            myPda: {
                initialize: [
                    [
                        {pid:1,distance:2000,title: 'Napal Fedorov', info: 'в зоне видимости'},
                        {pid:1,distance:200,title: 'Aasdsa Fedorov', info: 'в зоне видимости'},
                    ]
                ]
            }
        });
        */
        initialize (calls) {
            this.searchListExtra = calls;
            this.wantedListLoaded = false;
            this.vehicleListLoaded = false;
            //
            this.transportLoaded = false;
            this.penaltiesLoaded = false;
            this.crimeListLoaded = false;
            this.licensesListLoaded = false;
        },
        /*
        window.callVueMethod('myPda','loadWantedList', [{distance:2000,pid:1,wantedMultiplier:1,title: 'Napal Fedorov', info: 'в зоне видимости'},
        {distance:200,pid:3,wantedMultiplier:1,title: 'Asds Fedorov', info: 'в зоне видимости'},],2);
        */
        loadWantedList (list, type) {
            this.searchListWanted = list;
            this.wantedListLoaded = true;
            if (type === 1) this.searchTab = 'wanted';
            else if (type === 2) this.selectedType = 'database';
        },
        // window.callVueMethod('myPda','loadAutoList', [{uniqueid:1,wantedMultiplier:1,title: 'Napal Fedorov', info: 'в зоне видимости'}, ]);
        loadAutoList (list) {
            this.searchListAuto = list;
            this.vehicleListLoaded = true;
            this.searchTab = 'auto';
        },
        getWantedPlayer (pid) {
            this.sendclient('kpkGetPlayer', JSON.stringify({ a: pid }));
        },
        getorder (pid) {
            this.sendclient('kpkGetOrder', JSON.stringify({ a: pid }));
        },
        getauto (uniqueid) {
            this.sendclient('kpkGetAuto', JSON.stringify({ a: uniqueid }));
        },
        ChangeSearchTab (tabName) {
            if (tabName === 'wanted' && !this.searchListWanted.length && this.wantedListLoaded === false) {
                this.sendclient('kpkLoadWanted', JSON.stringify({ a: 1 }));
                return;
            } else if (tabName === 'auto' && !this.searchListAuto.length && this.vehicleListLoaded === false) {
                this.sendclient('kpkLoadAuto');
                return;
            }
            this.searchTab = tabName;
        },
        getBtnControls (value) {
            if (value === 'main') this.sendclient('kpkCallHelp', JSON.stringify({ a: 1 }));
            else if (value === 'local') this.sendclient('kpkCallHelp', JSON.stringify({ a: 2 }));
        },
        changeTab (tabType) {
            if (tabType === 'database' && !this.searchListWanted.length && this.wantedListLoaded === false) {
                this.sendclient('kpkLoadWanted', JSON.stringify({ a: 2 }));
                return;
            }
            // console.log(tabType);
            if (tabType === 'transport' && !this.transportLoaded) {
                this.sendclient('kpkLoadTransport');
                return;
            }
            if (tabType === 'licenses' && !this.licensesListLoaded) {
                this.sendclient('kpkLoadLicenses');
                return;
            }
            if (tabType === 'crimes' && !this.crimeListLoaded) {
                this.sendclient('kpkLoadCrimes');
                return;
            }
            if (tabType === 'penalties' && !this.penaltiesLoaded) {
                this.sendclient('kpkLoadPenlties');
                return;
            }
            this.selectedType = tabType;
        },
        getDistanceUnits (meters) {
            meters = meters.toFixed(0);
            if (meters < 1000) {
                return `${meters} M`;
            }
            const km = String(meters / 1000);
            const roundedKm = Math.floor(Number(km)).toFixed(0);
            const meter = km.split('.').length !== 1 ? Number(String(km).split('.')[1][0]) : null;
            return meter ? `${roundedKm}.${meter} KM` : `${roundedKm} KM`;
        },
        wantedPlayer (name) {
            if (name === '') return;
            this.sendclient('wantedPlayer', JSON.stringify({ a: name }));
        },
        finePlayer (name, fine) {
            if (name === '') return;
            fine = parseInt(fine);
            if (fine < 1 || isNaN(fine)) return;
            this.sendclient('finePlayer', JSON.stringify({ a: name, b: fine }));
        },
        // window.callVueMethod('myPda','OpenSearchPlayer', { wantedMultiplier: 4, title: 'Kevin Mackalister', info: 'В зоне видимости' }, [{title:'пол', definition:'мужской'}]);
        OpenSearchPlayer (userInfo, userListInfo) {
            // { wantedMultiplier: 4, title: 'Kevin Mackalister', info: 'В зоне видимости' }
            this.isSecondaryMenuOpened = true;
            this.currentUserData = userInfo;
            this.selectedType = this.secondaryMenuTabDefinitions[0].type;
            //
            this.currentUserInfo = userListInfo;
            // { title: this.$t('pda.address'), definition: 'Tosin Bunar 18/32' },
        },
        openTransportList (transport) {
            // { title: 'Mustang', price: '14500', plate: '9444-KH-4' },
            this.transportLoaded = true;
            this.transportList = transport;
            this.selectedType = this.secondaryMenuTabDefinitions[1].type;
        },
        openPenaltyList (penalty) {
            // { title: 'Баловался', price: '199500', officer: 'Flazy Fad' },
            this.penaltiesLoaded = true;
            this.penaltiesList = penalty;
            this.selectedType = this.secondaryMenuTabDefinitions[4].type;
        },
        openCrimeList (crimes) {
            // { title: 'Баловался', price: '199500', officer: 'Flazy Fad' },
            this.crimeListLoaded = true;
            this.crimesList = crimes;
            this.selectedType = this.secondaryMenuTabDefinitions[3].type;
        },
        openLicensesList (licenses) {
            /*
            { title: 'Водительское удостоверение', acquired: true },
            { title: 'Лицензия на оружие', acquired: true },
            */
            this.licensesListLoaded = true;
            this.licensesList = licenses;
            this.selectedType = this.secondaryMenuTabDefinitions[2].type;
        },
        openSecondaryMenu (item) {
            this.isSecondaryMenuOpened = true;
            this.currentUserData = item;
            this.selectedType = this.secondaryMenuTabDefinitions[0].type;
        },
        closeSecondaryMenu () {
            this.isSecondaryMenuOpened = false;
            this.currentUserData = null;
            this.selectedType = this.tabDefinitions[0].type;
        },
        searchPlayer () {
            if (this.search === '') return;
            this.sendclient('kpkSearchPlayer', JSON.stringify({ a: this.search }));
        }
    }
};
</script>
<style lang="scss">
.pda {
    height: 100%;
    &-content {
        height: calc(100% - 5vw);
        padding-left: 84px;
        padding-right: 88px;
        display: grid;
        grid-template-columns: 580px 1fr;
        border: 44px solid #000;
        border-top: 0;
        &--secondary {
            grid-template-columns: 664px 1fr;
        }
    }
    &-content .pda-menu .pda-menu__tabs {
        padding-bottom: 0;
    }
    &-secondary-menu {
        padding: 90px 128px 46px 0;
        grid-column: 1 span;
        display: grid;
        grid-template-columns: 144px 1fr;
        grid-gap: 0 32px;
        overflow: hidden;
        &__list .tabs__item.tabs__item--active {
            padding: 32px 40px;
            background: #16829A;
            box-shadow: 0 0 200px 0 rgba(22, 130, 154, 0.60);
        }
        &__list {
            .tabs__item {
                padding: 32px 40px;
                min-height: 122px;
                svg {
                    display: none;
                }
            }
        }
        &__back {
            height: 100%;
            border-radius: 4px;
            background: linear-gradient(225deg, rgba(241, 241, 241, 0.20) 0%, rgba(241, 241, 241, 0.00) 100%);
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                width: 64px;
                height: 64px;
            }
        }
    }
    &-menu {
        padding: 90px 100px 46px 0;
        overflow-y: scroll;
        grid-column: 1 span;
        &::-webkit-scrollbar {
            display: none;
        }
        &__controls {
            display: flex;
            margin-bottom: 32px;
            &-button {
                width: 100%;
                padding: 32px 0;
                border-radius: 12px 0 0 12px;
                background: #FF1A38;
                box-shadow: 0 4px 83px 0 #FF6363 inset, 0 8px 0 0 #941B27;
                color: var(--white);
                font-size: 24px;
                font-weight: 700;
                line-height: 100%;
                letter-spacing: 0.96px;
                text-transform: uppercase;
                &:last-child {
                    border-radius: 0 12px 12px 0;
                    background: #007CB5;
                    box-shadow: 0 4px 83px 0 #4FC8FF inset, 0 8px 0 0 #15698E;
                }
            }
        }
    }
    &-info {
        grid-column: 1 span;
        padding: 90px 0 46px;
        &--search {
            overflow-y: scroll;
            &::-webkit-scrollbar {
                display: none;
            }
        }
        &--fine {
            padding-top: 180px;
        }
        &--wanted {
            padding-top: 180px;
            .pda-info__form {
                display: block;
                margin-bottom: 84px;
            }
        }
        &--calls {
            overflow-y: auto;
            .org__info-member-item {
                width: 100%;
                .pda-info__info-button {
                    margin-left: auto;
                }
            }
            &::-webkit-scrollbar {
                display: none;
            }
            .org__info-member:last-of-type {
                margin-bottom: 0;
            }
        }
        &--info {
            padding-top: 90px;
            padding-bottom: 0;
            overflow: hidden;
        }
        &__multiplier {
            margin: 0 24px 0 12px;
            font-family: var(--font-stretch);
            color: var(--white);
            font-size: 42px;
            font-weight: 900;
            line-height: 100%;
            text-transform: uppercase;
            &--secondary {
                font-size: 56px;
                margin: 0 24px 0 16px;
            }
        }
        &__submit-fine {
            padding: 0 64px;
            .icon {
                margin-right: 18px;
                path {
                    fill: var(--dark);
                }
            }
        }
        &__info-button {
            width: 128px;
            height: 112px;
            padding: 0;
            margin-right: 24px;
            &:last-child {
                margin-right: 0;
            }
        }
        &__approve-icon {
            width: 40px;
            height: 40px;
            svg {
                width: 40px;
                height: 40px;
                path {
                    fill: var(--dark);
                }
            }
        }
        &__search {
            .org__info-search-menu-item {
                width: 100%;
            }
            .org__info-search-menu {
                grid-column: 22 span;
            }
        }
        &__form {
            display: grid;
            grid-template-columns: repeat(15, 1fr);
            grid-gap: 0 32px;
            margin-bottom: 32px;
        }
        &__title {
            margin-bottom: 84px;
            color: var(--white);
            font-size: 90px;
            font-weight: 300;
            line-height: 120%;
        }
        &__text {
            color: var(--white);
            font-size: 32px;
            font-weight: 500;
            line-height: 140%;
            opacity: .6;
            margin-bottom: 84px;
        }
        &__input {
            .input-label {
                font-size: 32px;
            }
            input {
                text-align: left;
                padding-left: 64px;
                padding-right: 64px;
                font-size: 64px;
                height: 158px;
            }
            &--name {
                grid-column: 11 span;
            }
            &--fine {
                grid-column: 4 span;
            }
        }
        &__heading {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 64px;
        }
        &__level {
            svg {
                width: 96px;
                height: 96px;
            }
        }
        &__about {
            .org__info-member-name {
                font-size: 70px;
            }
            .org__info-member-rank {
                font-size: 40px;
            }
        }
        &__secondary-button {
            font-size: 36px;
            padding: 0 44px;
            &:last-child {
                margin-left: 18px;
            }
        }
        &__grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 44px 64px;
            &-item {
                &-name {
                    margin: 18px 0 0;
                }
            }
        }
        &__table {
            &-heading {
                display: flex;
                justify-content: space-between;
                padding: 32px 24px;
                border-bottom: 4px solid rgba(241, 241, 241, 0.12);
                &-title {
                    color: var(--white);
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    letter-spacing: 0.96px;
                    text-transform: uppercase;
                }
                &-states {
                    display: flex;
                }
                &-state {
                    margin-right: 48px;
                    text-transform: uppercase;
                    opacity: .4;
                    color: var(--white);
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 120%;
                    letter-spacing: 0.96px;
                    position: relative;
                    &::after {
                        content: "/";
                        position: absolute;
                        right: -26px;
                    }
                    &:last-child {
                        margin-right: 0;
                        &::after {
                            display: none;
                        }
                    }
                }
            }
            &-title {
                color: var(--white);
                font-size: 44px;
                font-weight: 400;
                line-height: 100%;
            }
            &-price {
                font-size: 55px;
                font-weight: 700;
                line-height: 100%;
                letter-spacing: -1.1px;
                margin-right: 44px;
                &:last-child {
                    margin-right: 0;
                }
                span {
                    color: var(--light-green);
                }
            }
            &-info {
                display: flex;
            }
            &-item {
                padding: 32px 24px;
                border-bottom: 4px solid rgba(241, 241, 241, 0.12);
                display: flex;
                justify-content: space-between;
                align-items: center;
                &:last-child {
                    border-bottom: none;
                }
            }
            &-wanted {
                display: flex;
                align-items: center;
                svg {
                    width: 62px;
                    height: 62px;
                }
                .pda-info__multiplier--secondary {
                    margin-right: 0;
                }
            }
        }
        &__search-input {
            position: relative;
            &-button {
                position: absolute;
                right: 64px;
                width: 70px;
                height: 70px;
                background-image: url("@/assets/images/pda/search-icon.svg");
                background-position: center;
                background-size: cover;
                top: 79px;
                z-index: 4;
            }
            input {
                font-weight: 300;
                font-size: 64px;
                height: 158px;
                text-align: left;
                padding-left: 64px;
                padding-right: 134px;
                min-width: 100%;
            }
        }
    }
}
.pda .pda-secondary-menu .tabs {
    padding-bottom: 0;
}
</style>
