<template>
    <div class="shop">
        <AppHeader back-button @getBackHeader="handleBackHeader">
            <template #title>{{ title }}</template>
        </AppHeader>

        <template v-if="selectedItem">
            <div class="shop__cases-selected">
                <div class="shop__cases-selected-info">
                    <img :src="imageSource(`${selectedItem.img}.png`, 'case')" alt="">
                    <BaseButton class="shop__order-button" yellow @click="buyItem(selectedItem.name)">
                        {{ $t('shop.buy') }}
                        <div class="shop__order-price">
                            <IconDonate v-if="selectedItem.donate === 1" />
                            <span class="dollar-symbol" v-else>$</span>{{ $formatNum(selectedItem.money) }}
                        </div>
                    </BaseButton>
                </div>
                <div class="shop__cards">
                    <p class="shop__cases-selected-title">{{ $t('shop.inside_case') }}</p>
                    <div class="shop__cards-wrapper">
                        <InventoryItem
                            v-for="(item, index) in shopItems"
                            :key="index || 'empty' + index"
                            :item="item"
                            class="shop__item"
                        />
                    </div>
                </div>
            </div>
        </template>

        <div class="shop__wrapper" v-else>
            <div class="shop__tabs">
                <BaseTabMenu :items="tabDefinitions" :activeTab="selectedType" isScrollable :handleTabChange="changeTab" />
            </div>

            <div class="shop__cases-grid">

                <div class="shop__cases">
                    <div class="shop__cases-item" v-for="(item, index) in filteredCases" :key="index"  @click="selectItem(item)">
                        <img :src="imageSource(`${item.img}.png`, 'case')" alt="">
                        <p>{{ item.name }}</p>
                        <span class="shop__cases-item-free" v-if="item.money === 0 && item.donate === 0">
                            {{ $t('shop.free') }}
                        </span>
                        <span v-else>
                            <IconDonate v-if="item.donate === 1" />
                            <span class="dollar-symbol" v-else>$</span>
                            {{ $formatNum(item.money) }}
                        </span>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import AppHeader from '@/components/AppHeader/AppHeader.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import InventoryItem from '@/components/popups/components/inventoryItem/InventoryItem.vue';
import { mapActions, mapGetters } from 'vuex';
import BaseTabMenu from '@/components/ui/BaseTabMenu.vue';
import IconDonate from '@/components/icons/IconDonate.vue';

export default {
    name: 'ShopCase',
    components: { IconDonate, BaseTabMenu, InventoryItem, BaseButton, AppHeader },
    data () {
        return {
            show: false,
            selectedType: 'all',
            selectedItem: null,
            title: this.$t('shop.shop_case'),
            discount: 0,

            tabDefinitions: [
                { type: 'all', text: 'Все' },
                { type: 'rare', text: 'Редкие' },
                { type: 'special', text: 'Особые' },
                { type: 'auto', text: 'автомобильные' },
                { type: 'kefir', text: 'кефирные' }
            ],

            cases: [
                { type: 'all', img: '1', name: 'red apple', money: 0, donate: 0 },
                { type: 'all', img: '1', name: 'red apple', money: 20000, donate: 0 },
                { type: 'rare', img: '2', name: 'red apple', money: 20000, donate: 1 },
                { type: 'special', img: '3', name: 'red apple', money: 20000, donate: 0 },
                { type: 'auto', img: '4', name: 'red apple', money: 100, donate: 1 },
                { type: 'kefir', img: '5', name: 'red apple', money: 20000, donate: 0 },
                { type: 'special', img: '6', name: 'red apple', money: 20000, donate: 0 },
                { type: 'special', img: '7', name: 'red apple', money: 20000, donate: 0 },
                { type: 'special', img: '8', name: 'red apple', money: 20000, donate: 0 },
                { type: 'special', img: '9', name: 'red apple', money: 20000, donate: 0 },
                { type: 'special', img: '10', name: 'red apple', money: 20000, donate: 0 }
            ],

            shopItems: [
                { id: 2, name: 'Штурмовая винтовка', rarity: 'legend', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/m4' },
                { id: 3, name: 'Пистолет', rarity: 'unusual', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/pistol' },
                { id: 2, name: 'Штурмовая винтовка', rarity: 'legend', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/m4' },
                { id: 3, name: 'Пистолет', rarity: 'unusual', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/pistol' },
                { id: 2, name: 'Штурмовая винтовка', rarity: 'legend', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/m4' },
                { id: 3, name: 'Пистолет', rarity: 'unusual', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/pistol' },
                { id: 2, name: 'Штурмовая винтовка', rarity: 'legend', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/m4' },
                { id: 3, name: 'Пистолет', rarity: 'unusual', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/pistol' }
            ]

            // isEquipped: true,
        };
    },
    methods: {
        ...mapActions(['updateInventoryItemsPool']),
        handleBackHeader () {
            this.sendclient('shopMain');
        },
        changeTab (tabType) {
            this.selectedType = tabType;
            this.selectedItem = null; // reset selected item
        },
        selectItem (item) {
            this.selectedItem = item;
        },
        buyItem (item) {
            console.log(item);
        },
        /*
        window.modifyVueComponent({
            App: {
                showPopup: true,
                popupContent: 'MyShop',
                contentSubFolder: 'shop'
            },
            MyShop: {
                InitializeShop: [
                    [
                        {"count":89,"additional":0,"itemID":2,"price":100},{"count":9,"additional":0,"itemID":3,"price":100},
                        ],
                    [],
                ]
            }
        });
        */
        InitializeShop (itemList, title, discount, type) {
            this.title = title;
            this.discount = discount;
            this.type = type;
            this.shopItems = new Array(this.countSlot);// [];
            for (let i = 0; i < itemList.length; i++) {
                if (itemList[i].itemID) {
                    this.shopItems[i] = Object.assign({}, this.inventoryItemsPool[itemList[i].itemID - 1]);
                    this.shopItems[i].count = itemList[i].count;
                    this.shopItems[i].index = i + 1;
                    this.shopItems[i].additional = itemList[i].additional;
                    this.shopItems[i].price = itemList[i].price;
                    // if (activeSlots && activeSlots.indexOf(i + 1) !== -1) this.shopItems[i].isEquipped = true;
                } else this.shopItems[i] = { id: null, name: '', rarity: '', filterType: '', desc: '', img: '', count: 0, index: i + 1 };
            }
        },
        GetFullPrice (price) {
            const correctPriceToMinus = Math.floor(this.discount / 100 * price);
            return this.$formatNum(price - correctPriceToMinus);
        },
        Rerender () {
            const lastType = this.selectedType;
            this.selectedType = '';
            this.selectedType = lastType;
        }
    },
    computed: {
        ...mapGetters('inventory', ['inventoryItemsPool']),
        filteredCases () {
            if (this.selectedType === 'all') {
                return this.cases;
            }
            return this.cases.filter(item => item.type === this.selectedType);
        }
    }

};
</script>

<style lang="scss" scoped>
.dollar-symbol{
    color: #FFCD4C;
}
.shop{
    display: flex;
    height: 100%;
    flex-direction: column;
    background: url(@/assets/images/bg-popup-vector-dark.svg) no-repeat center/cover, radial-gradient(138.02% 65.96% at 50.00% -0.00%, #30554E 0%, #1D2028 100%);

    &__item{
        margin: 0 12px 24px;
    }
    &__wrapper{
        padding: 140px 128px;
        display: grid;
        grid-template-columns: 480px 1fr;
        grid-gap: 80px;
        height: 100%;
    }

    &__cases{
        display: grid;
        grid-gap: 24px;
        grid-template-columns: repeat(4, 1fr);

        &-selected{
            padding: 90px 128px;
            display: grid;
            grid-template-columns: 910px 1fr;
            grid-gap: 128px;
            height: 90%;

            &-title{
                color: #F1F1F1;
                font-size: 32px;
                margin-left: 12px;
                margin-bottom: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%; /* 38.4px */
                letter-spacing: 3.84px;
                text-transform: uppercase;
            }

            &-info{
                position: relative;
                img{
                    max-width: 100%;
                    width: 90%;
                    margin: 0 auto;
                    display: block;

                }
                .shop__order-button{
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    width: max-content;
                }
            }
        }

        &-grid{
            overflow: scroll;
            height: 120%;
            padding: 135px 0 ;
            margin: -135px 0;

            &::-webkit-scrollbar{
                width: 0;
                height: 0;
                display: none;
            }
        }

        &-item{
            border-radius: 4px;
            background: radial-gradient(157.83% 141.42% at 0.00% 0%, rgba(20, 26, 30, 0.81) 0%, rgba(20, 26, 30, 0.44) 100%);
            display: flex;
            padding: 32px 16px;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            img{
                width: 100%;
            }

            p{
                color: #F1F1F1;
                text-align: center;
                font-size: 24px;
                margin-top: 32px;
                margin-bottom: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 24px */
                letter-spacing: 1.92px;
                text-transform: uppercase;
            }
            span{
                border-radius: 6px;
                background: #141A1E;
                padding: 7px 12px 7px 7px;
                align-items: center;
                display: flex;
                color: #F1F1F1;
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%; /* 48px */
                letter-spacing: -0.96px;
                &.shop__cases-item-free {
                    border-radius: 6px;
                    background: var(--red);
                    box-shadow: 0px -20px 140px 0px rgba(195, 5, 66, 0.55), 0px -10px 50px 0px rgba(240, 34, 100, 0.55);
                }
            }
        }
    }

    &__order{
        &-button {
            height: 4.45vw;
            padding: 0 1.75vw;
            font-size: 1.45vw;
            white-space: nowrap;
        }
        &-price {
            margin-left: .725vw;
            background-color: var(--dark);
            color: var(--white);
            padding: .25vw .5vw;
            border-radius: .25vw;
            font-weight: 700;
            font-size: 1.6vw;
            display: flex;
            align-items: center;
            &-dollar {
                display: inline-block;
                color: var(--gold);
                margin-right: .16vw;
            }
        }
    }

    &__cards{
        overflow-y: auto;
        margin: -5vw 0;
        padding: 5vw 0.2vw;

        &::-webkit-scrollbar{
            width: 0;
            height: 0;
            display: none;
        }

        &-count{
            padding: 0 .7vw ;
            height: 1.7vw;
            background: var(--white);
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            clip-path: polygon(0 0, 100% 0, 91% 100%, 0% 100%);
            font-size: 1.3vw;
            line-height: 100%;
            letter-spacing: -0.03em;
            font-weight: 700;
            color: #141A1E;

        }

        &-put-on{
            padding: 13px;
            width: 1.7vw;
            height: 1.7vw;
            background: #27AE60;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 0;
            top: 0;
            clip-path: polygon(19% 0, 100% 0, 100% 100%, 0% 100%);

            img{
                height: 70% !important;
            }
        }

        &-wrapper{
            display: flex;
            flex-wrap: wrap;
        }

        &-update{
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-gap: 1vw;
            grid-column: 1/-1;
            position: relative;

            .btn{
                svg{
                    height: 30%;
                    width: auto;
                    margin-left: .5vw;
                }
            }

            .shop__cards-item--empty{
                opacity: .3;
            }
        }

        &-shop{
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 3vw;

            h5{
                font-weight: 700;
                font-size: 1.3vw;
                line-height: 140%;

                letter-spacing: 0.05em;
                text-transform: uppercase;

                color: var(--white);
            }
            p{
                font-size: 1.3vw;
                line-height: 140%;
                display: flex;
                align-items: flex-end;

                color: var(--white);

                opacity: 0.6;
            }
        }

        &-item{
            position: relative;
            width: 220px;
            height: 280px;
            display: flex;
            border-radius: .2vw;
            padding: .5vw;
            align-items: flex-end;
            overflow: hidden;
            flex-shrink: 0;

            &--empty{
                background: url(../../assets/images/empty.svg) no-repeat center/contain;
            }

            &--legend{
                background: url(../../assets/images/legend.svg) no-repeat center/cover;
            }
            &--epic{
                background: url(../../assets/images/epic.svg) no-repeat center/cover;
            }
            &--rare{
                background: url(../../assets/images/rare.svg) no-repeat center/cover;
            }
            &--ordinary{
                background: url(../../assets/images/ordinary.svg) no-repeat center/cover;
            }
            &--unusual{
                background: url(../../assets/images/unusual.svg) no-repeat center/cover;
            }

            &--active{
                box-shadow: 0 0 0 .2vw var(--white);
                &::before{
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    content: "";
                    display: block;
                    width: 100%;
                    height: 100%;
                    background: url(../../assets/images/active.svg) no-repeat center/cover;
                }

            }

            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
                position: absolute;
            }

            p{
                font-weight: 700;
                font-size: 1vw;
                line-height: 100%;
                position: relative;
                z-index: 3;
            }

        }
    }

    &__information{
        height: 100%;

        &-top{
            text-align: center;
        }

        &-item{
            padding-top: 1vw;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        &-put-on{
            padding: 13px;
            width: 1.7vw;
            height: 1.7vw;
            background: #27AE60;
            margin-right: .5vw;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-labels{
            display: flex;
        }

        &-label{
            font-weight: 700;
            font-size: .9vw;
            padding: 0.4vw 0.7vw;
            line-height: 100%;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: var(--white) ;
            width: max-content;
            margin: 0 auto;

            &.legend{
                background: linear-gradient(211.69deg, #FFCD4C 33.85%, #FFA800 100%);
            }
            &.epic{
                background: linear-gradient(211.69deg, #BE6AFF 20.31%, #7A28FF 100%);
            }
            &.rare{
                background: linear-gradient(211.69deg, #48FDC7 0%, #009D6E 100%);
            }
            &.ordinary{
                background: linear-gradient(212deg, #96ABB5 43.23%, #72848B 100%);
            }
            &.unusual{
                background: linear-gradient(211.69deg, #5FCFFF 35.94%, #2780A6 100%);
            }
        }

        &-title{
            font-weight: 400;
            font-size: 3vw;
            line-height: 100%;
            margin-bottom: 1vw;

            justify-content: center;

            display: flex;
            align-items: flex-end;

            color: #FFFFFF;
        }
        &-desc{
            margin-top: 1vw;
            font-weight: 500;
            font-size: 1vw;
            line-height: 140%;
            color: var(--white);

            opacity: 0.6;
        }
        &-empty{
            display: flex;
            height: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            img{
                height: 8vw;
                margin-bottom: 1vw;
            }
            p{
                font-weight: 500;
                font-size: 1.5vw;
                line-height: 140%;
                display: flex;
                align-items: flex-end;
                text-align: center;

                color: var(--white);

                opacity: 0.8;

            }
        }
    }

    &__buttons{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: .5vw;
        &-item{
            background: linear-gradient(119.36deg, rgba(241, 241, 241, 0.19) 0%, rgba(241, 241, 241, 0) 100%);
            border-radius: .1vw;
            height: 6.5vw;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            font-weight: 700;
            font-size: 1vw;
            line-height: 120%;

            text-align: center;
            letter-spacing: 0.04em;
            text-transform: uppercase;

            color: #F1F1F1;

            &--use{
                background: #FFED4C;
                color: #141A1E;
                box-shadow: inset 0px -26px 80px rgba(241, 241, 241, 0.4);
            }
            &--clothes{
                background: #F1F1F1;
                color: #141A1E;
                box-shadow: inset 0px -26px 80px rgba(241, 241, 241, 0.4);
            }

            svg{
                height: 2vw;
                width: auto;
                margin-bottom: .5vw;
            }
        }
    }
}
</style>
