<template>
    <div class="promo">
        <IconGifts class="promo__icon" />
        <div class="promo__title">{{ $t('dialog_promo.type_promo') }}</div>
        <BaseInput
            :maxLength="length"
            class="promo__input"
            :class="{'promo__input--active' : length === inputValue.length}"
            :placeholder="$t('dialog_promo.promo')"
            v-model="inputValue"
        />
        <transition name="fade">
            <BaseButton
                v-if="length > 0"
                class="promo__button"
                yellow
                @click="confirmPromo"
            >
                {{ $t('dialog_promo.confirm') }}
            </BaseButton>
        </transition>
        <img src="@/assets/images/dialogPromo/dialog-promo-box.png" alt="" class="promo__image">
    </div>
</template>

<script>
import BaseInput from '@/components/ui/BaseInput.vue';
import IconGifts from '@/components/icons/IconGifts.vue';
import BaseButton from '@/components/ui/BaseButton.vue';

export default {
    name: 'DialogPromo',
    components: {
        BaseButton,
        IconGifts,
        BaseInput
    },
    data () {
        return {
            inputValue: '',
            length: 20
        };
    },
    methods: {
        confirmPromo () {
            this.sendclient('PromoEntered', JSON.stringify({ a: this.inputValue.toLowerCase() }));
        }
    }
};
</script>

<style lang="scss" scoped>
.promo {
    display: flex;
    flex-direction: column;
    padding-top: 170px;
    align-items: center;
    height: 100%;
    background: url("@/assets/images/dialogPromo/dialog-promo-bg.png") no-repeat center/cover;
    position: relative;
    &__input {
        &::v-deep {
            .input {
                width: 1040px;
                height: 158px;
                font-size: 64px;
                font-weight: 300;
                text-transform: uppercase;
                &::placeholder {
                    text-transform: none;
                }
            }
        }
        &--active {
            &::v-deep {
                .input {
                    color: var(--light-green);
                    background: linear-gradient(180deg, rgba(159, 208, 53, 0.00) 40.63%, rgba(159, 208, 53, 0.16) 100%);
                    border-color: var(--light-green);
                }
            }
        }
    }
    &__icon {
        width: 294px;
        height: 191px;
    }
    &__title {
        margin: 60px 0;
        text-transform: uppercase;
        font-family: var(--font-stretch);
        color: var(--white);
        text-align: center;
        font-size: 100px;
        font-weight: 900;
        line-height: 80%;
    }
    &__image {
        position: absolute;
        bottom: 0;
        width: 1023px;
        height: 390px;
    }
    &__button {
        margin-top: 60px;
        z-index: 3;
        padding: 0 64px;
    }
}
</style>
