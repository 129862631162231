<template>
    <div v-if="isOwned" class="estate__info estate__info--business" >
        <div class="estate__info-heading">
            <div class="estate__info-heading-detail">
                <div class="estate__info-title">{{ estate.name }}</div>
                <div class="estate__info-name">{{ estate.type }}</div>
            </div>
            <EstateLevel v-if="isMiniBusiness" :level=estate.level />
        </div>
        <div class="estate__info-stats">
            <BaseGraph :progress=graphProgress />
            <div class="estate__info-descr">
                <div class="estate__info-price">
                    <div class="estate__info-price-text">
                        <div class="estate__info-price-symbol estate__info-price-dollar">$</div>
                        <div class="estate__info-price-number">{{ $formatNum(estate.currentProfit) }}</div>
                    </div>
                    <div v-if="!isDone" class="estate__info-price-text estate__info-price-text--full">
                        <div class="estate__info-price-symbol estate__info-price-number">/</div>
                        <div class="estate__info-price-number ">{{ $formatNum(estate.maxProfit) }}</div>
                    </div>
                </div>
                <div v-if="!isDone && isMiniBusiness" class="estate__info-data">
                    <div class="estate__info-status">{{ $t('estate.business.online') }}</div>
                    <div class="estate__info-time">
                        <div class="estate__info-clock"></div>
                        <div class="estate__info-wait">{{ formattedTime(estate.timeRemaining) }}</div>
                    </div>
                    <div class="estate__info-text">{{ $t('estate.business.time_left') }}<br>
                        {{ $t('estate.business.till_profit') }}</div>
                </div>
                <div v-if="!isDone && !isMiniBusiness" class="estate__info-data">
                    <div class="estate__info-text estate__info-text--business">
                        {{ $t('estate.business.text') }}
                    </div>
                </div>
            </div>
        </div>
        <div class="estate__nav">
            <EstateControls @controlClicked="handleControlClick" />
            <EstateProfit :pickUpMoney="estate.pickUpMoney" :upgradeEstateMoney="estate.upgradeEstateMoney" @pickUp="emitPickUp" :isDone=isDone />
        </div>
    </div>
    <div v-else class="estate__info estate__info--business estate__info--own">
        <div v-if="isMiniBusiness" class="estate__own-title">
            <IconNotice icon="alert" />
            {{ $t('estate.business.no_mini_business') }}
        </div>
        <div v-else class="estate__own-title">
            <IconNotice icon="alert" />
            {{ $t('estate.business.no_business') }}
        </div>
        <div class="estate__own-text">
            {{ $t('estate.business.buy_business') }}
            <span>{{ $t('estate.business.other_players') }}</span>
            {{ $t('estate.business.or') }} <span>{{ $t('estate.business.auction')}}</span>:</div>
        <BaseButton white class="estate__own-button" @click="openAuction">
            <IconSign />
            {{ $t('estate.open_auction') }}
        </BaseButton>
    </div>
</template>

<script>

import BaseGraph from '@/components/ui/BaseGraph.vue';
import EstateControls from '@/components/popups/content/estate/components/EstateControls.vue';
import EstateProfit from '@/components/popups/content/estate/components/EstateProfit.vue';
import EstateLevel from '@/components/ui/EstateLevel.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import IconNotice from '@/components/icons/IconNotice.vue';
import IconSign from '@/components/icons/IconSign.vue';

export default {
    name: 'EstateBusiness',
    components: { IconSign, IconNotice, BaseButton, EstateLevel, EstateProfit, EstateControls, BaseGraph },
    props: {
        estate: Object,
        isDone: Boolean,
        graphProgress: Number,
        isMiniBusiness: Boolean,
        isOwned: Boolean,
        pickUpMoney: Number,
        upgradeEstateMoney: Number
    },

    methods: {
        formattedTime (timeRemaining) {
            const minutes = Math.floor(timeRemaining / 60);
            const seconds = timeRemaining % 60;
            const formattedMinutes = String(minutes).padStart(1, '0');
            const formattedSeconds = String(seconds).padStart(2, '0');
            return `${formattedMinutes}:${formattedSeconds}`;
        },
        handleControlClick (action) {
            this.$emit('controlClicked', action);
        },
        openAuction () {
            this.sendclient('ClientOpenAuction');
        },
        emitPickUp () {
            this.$emit('pickUp');
        }
    }
};
</script>
