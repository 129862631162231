<template>
    <div class="task__list-progress">
        <div v-if="percent === 100" class="task__list-progress-text">{{ $t('task.completed') }}</div>
        <IconZeroLevel :percent="percent" class="svg"/>
        <img class="img" src="@/assets/images/works/save.png" alt="">
        <template v-if="percent !== 100">
            <span>{{ completedTasks }}</span> <p> / {{ tabLength() }}</p>
        </template>
        <BaseButton v-else white class="task__list-progress-button" @click="$emit('claimCategoryReward')">{{ $t('task.take') }}</BaseButton>
    </div>
</template>

<script>
import BaseButton from '@/components/ui/BaseButton.vue';
import IconZeroLevel from '@/components/popups/content/works/icons/IconZeroLevel.vue';

export default {
    name: 'PhoneTaskProgress',
    components: { IconZeroLevel, BaseButton },
    props: {
        percent: Number,
        tabLength: Function,
        completedTasks: Number
    }
};
</script>

<style lang="scss" scoped>
.task__list {
    &-progress{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img{
            position: absolute;
            width: 244px;
            height: 244px;
            left: 0;
            right: 0;
            bottom: 150px;
            margin: 0 auto;
        }

        svg{
            width: 284px;
            height: auto;
        }

        span{
            color: #F1F1F1;
            font-size: 64px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            text-transform: uppercase;
            margin-bottom: 12px;
            text-align: center;
            display: block;
            z-index: 1;
            position: relative;
        }
        p{
            color: #B784C9;
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            letter-spacing: 1.92px;
            text-transform: uppercase;
            text-align: center;
        }

        &-text {
            writing-mode: vertical-rl;
            position: absolute;
            left: 50%;
            top: 31%;
            transform: translateX(-50%);
            text-transform: uppercase;
            color: #27AE60;
            font-size: 44px;
            font-weight: 900;
            line-height: 120%;
            letter-spacing: 2.64px;
        }

        &-button {
            position: relative;
            z-index: 2;
            font-size: 36px;
            width: 284px;
            height: 112px;
            border-radius: 4px;
        }
    }
}
</style>
