<template>
    <div class="sim-buy">
        <button class="sim-buy__close" @click="handleClose">
            <IconClose fill="#f1f1f1"/>
        </button>
        <div class="sim-buy__number">
            {{ handleFormatNumber(card.number) }}
        </div>
        <div class="sim-buy__text">
            {{ $t('sim_card.selling') }} {{ card.seller }}
        </div>
        <BaseButton
            yellow
            class="sim-button"
            @click="handleBuyNumber(card)"
        >
            {{ $t('sim_card.buy_number') }}
            <div class="sim-button__price">
                <span class="sim-button__dollar">$</span>
                {{ $formatNum(card.price) }}
            </div>
        </BaseButton>
    </div>
</template>

<script>
import BaseButton from '@/components/ui/BaseButton.vue';
import IconClose from '@/components/icons/IconClose.vue';

export default {
    name: 'SimBuy',
    components: { IconClose, BaseButton },
    props: {
        card: Object,
        handleClose: Function,
        handleFormatNumber: Function,
        handleBuyNumber: Function
    }
};
</script>
