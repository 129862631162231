<template>
    <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M78.8992 33.6567L42.8279 69.6568C40.8279 67.6568 41.5616 68.4617 39.9995 66.8996L19.9995 46.8996L25.6564 41.2428L42.8279 58.4143L73.2423 27.9999L78.8992 33.6567Z" :fill="fill"/>
    </svg>
</template>

<script>
export default {
    props: {
        fill: {
            type: String,
            default: '#9FD035'
        }
    }
};
</script>
