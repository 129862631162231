<template>
    <div class="main-shop" :class="{'main-shop--bg' : type !== 'number'}">
        <template v-if="type === 'main'">
            <AppHeader back-button>
                <template #title>{{ $t('shop.header') }}</template>
            </AppHeader>
            <div class="main-shop__wrapper">
                <div class="main-shop__menu">
                    <div class="main-shop__menu-item" @click="goClothes">
                        <img src="./assets/images/clothes.png" alt="">
                        <div class="main-shop__menu-info">
                            <p>{{ $t('shop.cloth') }}</p>
                            <span>{{ $t('shop.cloth_message') }}</span>
                        </div>
                    </div>
                    <div class="main-shop__menu-item" @click="goMoney">
                        <img src="./assets/images/money.png" alt="">
                        <div class="main-shop__menu-info">
                            <p>{{ $t('shop.currency') }}</p>
                            <span>{{ $t('shop.currency_message') }}</span>
                        </div>
                    </div>
                    <div class="main-shop__menu-item" @click="goNumber">
                        <img src="./assets/images/basic-plate.png" alt="">
                        <div class="main-shop__menu-info">
                            <p>{{ $t('shop.plate') }}</p>
                            <span>{{ $t('shop.plate_message') }}</span>
                        </div>
                    </div>
                    <div class="main-shop__menu-item" @click="goVip">
                        <img src="./assets/images/vip.png" alt="">
                        <div class="main-shop__menu-info">
                            <p>{{ $t('shop.vip') }}</p>
                            <span>{{ $t('shop.vip_message') }}</span>
                        </div>
                    </div>
                    <div class="main-shop__promo" @click="goPromo">
                        <IconTicket />
                        {{ $t('shop.type_promo') }}
                    </div>
                </div> <!-- main-shop__menu -->

                <div class="main-shop__banners">
                    <div class="main-shop__banners-item main-shop__banners-item--battle-pass" @click="goBattlePass">
                        <IconNew class="icon-new"/>
                        <img src="./assets/images/battle-pass.svg" alt="">
                        <span>{{ $t('shop.new_awards') }}</span>
                    </div>
                    <div class="main-shop__banners-item main-shop__banners-item--case" @click="goCase">
                        <IconNew class="icon-new"/>
                        <p>{{ $t('shop.cases') }}</p>
                        <span class="green">{{ $t('shop.free_case') }}</span>
                    </div>
                    <div class="menu__big">
                        <swiper ref="swiperMenu" :options="swiperOptions">
                            <swiper-slide v-for="(slide, index) in sliderLeft" :key="index">
                                <div class="menu__big-item">
                                    <img :src="imageSrc(slide)" class="menu__big-item-image" alt="">
                                    <div class="menu__big-item-info">
                                        <div class="menu__big-item-title">{{ slide.title }}</div>
                                        <div class="menu__big-item-name">{{ slide.name }}</div>
                                    </div>
                                </div>
                            </swiper-slide>
                            <div class="swiper-pagination" slot="pagination"></div>
                        </swiper>
                    </div>
                </div>
            </div>
        </template>
        <ShopClothes v-if="type === 'clothes'"/>
        <ShopMoney v-if="type === 'money'" />
        <ShopVip v-if="type === 'vip'" />
        <ShopCase v-if="type === 'case'"/>
        <ShopNumber v-if="type === 'number'" />
    </div>
</template>

<script>
import AppHeader from '@/components/AppHeader/AppHeader.vue';
import ShopClothes from '@/components/popups/content/shop/components/shop/ShopClothes.vue';

import awesomeSwiperBuilder from 'vue-awesome-swiper/dist/exporter';
import SwiperConstructor from 'swiper';
import ShopMoney from '@/components/popups/content/shop/components/shop/ShopMoney.vue';
import ShopVip from '@/components/popups/content/shop/components/shop/ShopVip.vue';
import IconNew from '@/components/icons/IconNew.vue';
import ShopCase from '@/components/popups/content/shop/components/shop/ShopCase.vue';
import ShopNumber from '@/components/popups/content/shop/components/shop/ShopNumber.vue';
import IconTicket from '@/components/icons/IconTicket.vue';
const { Swiper, SwiperSlide } = awesomeSwiperBuilder(SwiperConstructor);

export default {
    name: 'MainShop',
    components: {
        IconTicket,
        ShopNumber,
        ShopCase,
        IconNew,
        ShopVip,
        ShopMoney,
        ShopClothes,
        AppHeader,
        Swiper,
        SwiperSlide
    },
    data () {
        return {
            type: 'main',
            sliderLeft: [
                {
                    image: '1.png',
                    title: 'новый сет одежды',
                    name: 'Yellow destroyer'
                },
                {
                    image: '2.png',
                    title: 'редкий транспорт',
                    name: 'survival track 200'
                },
                {
                    image: '2.png',
                    title: 'редкий транспорт',
                    name: 'survival track 200'
                },
                {
                    image: '2.png',
                    title: 'редкий транспорт',
                    name: 'survival track 200'
                },
                {
                    image: '2.png',
                    title: 'редкий транспорт',
                    name: 'survival track 200'
                },
                {
                    image: '2.png',
                    title: 'редкий транспорт',
                    name: 'survival track 200'
                }
            ],
            swiperOptions: {
                slidesPerView: '1',
                spaceBetween: 0,
                loop: true,
                autoplay: {
                    delay: 1000000,
                    enabled: true,
                    disableOnInteraction: false
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                }
            }
        };
    },
    computed: {
        swiper () {
            return this.$refs.swiperMenu.$swiper;
        },
        imageSrc () {
            return (slide) => {
                return require(`@/assets/images/menu/${slide.image}`);
            };
        }
    },
    methods: {
        /*
        window.modifyVueComponent({
            App: {
                showPopup: true,
                popupContent: 'MainShop',
                contentSubFolder: 'shop'
            },
        });
        */
        goMain () {
            this.type = 'main';
        },
        goBattlePass () {
            this.sendclient('BattlePass');
        },
        goCase () {
            this.type = 'case';
        },
        goClothes () {
            this.type = 'clothes';
        },
        goMoney () {
            this.type = 'money';
        },
        goVip () {
            this.type = 'vip';
        },
        goNumber () {
            this.sendclient('goToNumberPlate');
            // this.type = 'number';
        },
        goPromo () {
            this.sendclient('openPromoDialog');
            // console.log('открываем диалог DialogPromo');
        }
    }
};
</script>
<style lang="scss">
@import "swiper/swiper.scss";
.main-shop{
    .swiper-pagination {
        position: absolute;
        max-width: 100%;
        z-index: 3;
        bottom: 1vw;
        left: 1vw;
        right: 1vw;
        display: flex;
        align-items: center;
        &-bullet {
            border-radius: 50%;
            background-color: var(--white);
            display: block;
            width: .5vw;
            min-width: .5vw;
            height: .5vw;
            margin-left: .65vw;
            &:first-child {
                margin-left: 0;
            }
            &-active {
                transition: width .3s linear;
                border-radius: .5vw;
                background-color: var(--white);
                width: 100%;
            }
        }
    }
}
</style>
<style lang="scss" scoped>

.menu {
    &__big {
        position: relative;
        border: .15vw solid rgba(241, 241, 241, 0.2);
        border-radius: .2vw;
        min-width: 0;

        grid-column: 2/-1;
        grid-row: 1/-1;
        &-item {
            height: 100%;
            background: linear-gradient(180deg, rgba(32, 36, 39, 0.007) 43.75%, rgba(32, 36, 39, 0.7) 100%);
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-direction: column;
            padding-left: 1.8vw;
            padding-right: 1.8vw;
            &-info {
                padding-bottom: 5.5vw;
                text-align: center;
                color: var(--white);
            }
            &-title {
                margin-bottom: .4vw;
                text-transform: uppercase;
                font-family: var(--font-stretch);
                font-weight: 900;
                font-size: 1.8vw;
            }
            &-name {
                font-size: 3.95vw;
                text-transform: uppercase;
                font-family: var(--font-stretch);
                font-weight: 900;
            }
            &-image {
                position: absolute;
                max-width: 100%;
                width: 100%;
                height: 100%;
                object-fit: cover;
                left: 0;
                right: 0;
                z-index: -1;
            }
        }
    }
}
.main-shop{
    display: flex;
    height: 100%;
    flex-direction: column;
    &--bg {
        background: url("./assets/images/bg.svg") no-repeat center/cover, radial-gradient(138.02% 65.96% at 50.00% -0.00%, #FFC683 0%, #C30542 100%);
    }
    &__wrapper{
        padding: 60px 90px 90px;
        display: grid;
        grid-template-columns: 960px 1fr;
        align-items: center;
        height: calc(100% - 5vw);
    }
    &__menu{
        display: grid;
        padding: 0 66px;
        grid-template-rows: 1fr 1fr 1fr 1fr 100px;
        &-item{
            display: flex;
            align-items: center;
            padding: 32px 24px;
            border-bottom: 4px solid rgba(241, 241, 241, 0.20);

            &:last-child{
                border-bottom: none;
            }

            *{
                pointer-events: none;
            }

            p{
                color: #F1F1F1;
                font-family: var(--font-stretch);
                font-size: 64px;
                font-style: normal;
                font-weight: 900;
                line-height: 100%;
                letter-spacing: 2.56px;
                text-transform: uppercase;
                margin-bottom: 16px;
            }
            span{
                color: rgba(241, 241, 241, 0.70);
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 32px */
            }
            img{
                height: 142px;
                margin-right: 40px;
            }
        }
    }

    &__banners{
        display: grid;
        min-width: 0;
        height: 100%;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 32px;

        &-item{
            background: #000;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: relative;

            .icon-new{
                position: absolute;
                top: -10px;
                left: -10px;
                width: 93px;
                height: auto;
            }

            img{
                max-width: 336px;
                width: 100%;
            }

            p{
                color: #F1F1F1;
                text-align: center;
                font-family: var(--font-stretch);
                font-size: 100px;
                font-style: normal;
                font-weight: 900;
                line-height: 80%; /* 80px */
                text-transform: uppercase;
                margin-bottom: 32px;
            }
            span{
                display: flex;
                padding: 10px 24px;
                justify-content: center;
                align-items: center;
                background: #C30542;
                color: #F1F1F1;
                text-align: center;
                font-size: 44.049px;
                font-style: normal;
                font-weight: 900;
                line-height: 120%; /* 52.859px */
                text-transform: uppercase;
                font-family: var(--font-stretch);

                &.green{
                    background: #27AE60;
                }
            }

            &--battle-pass{
                background: url(./assets/images/battle-pass.png) no-repeat center / cover;
            }
            &--case{
                background: url(./assets/images/case.png) no-repeat center/cover;
            }
        }
    }

    &__promo {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        background: rgba(241, 241, 241, 0.10);
        color: var(--white);
        font-size: 36px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: 1.44px;
        text-transform: uppercase;
        &::v-deep {
            svg {
                width: 44px;
                height: 44px;
                margin-right: 24px;
            }
        }
    }

}

.swiper-container{
    height: 100%;
}

</style>
