import MainButtons from './components/buttons/MainButtons.vue';
import PlayerInteractive from './components/buttons/PlayerInteractive.vue';
import VChat from './components/chat/VChat.vue';
import EventBadge from './components/events/EventBadge.vue';
import VMap from './components/map/VMap.vue';
import VOffer from './components/offer/VOffer.vue';
import Phone from './components/phone/VMobile.vue';
import TimePrizes from './components/prizes/TimePrizes.vue';
import Reports from './components/reports/VReports.vue';
import IdCard from './components/IdCard.vue';
import LevelUp from './components/LevelUp.vue';
import PayDay from './components/PayDay.vue';
import ProgressAction from './components/ProgressAction.vue';
import TakeReward from './TakeReward.vue';

const hud = {
    MainButtons,
    PlayerInteractive,
    VChat,
    EventBadge,
    VMap,
    VOffer,
    Phone,
    TimePrizes,
    Reports,
    IdCard,
    LevelUp,
    PayDay,
    ProgressAction,
    TakeReward
};

export default hud;
