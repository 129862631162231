<template>
    <div class="shop">
        <AppHeader back-button>
            <template #title>{{ title }}</template>
        </AppHeader>

        <div class="shop__wrapper">

            <div class="shop__information">
                <div class="shop__information-item" v-if="selectedItem">
                    <div class="shop__information-top">
                        <div class="shop__information-title">{{ selectedItem.name }}</div>
                        <div class="shop__information-labels">
                            <div class="shop__information-put-on" v-if="selectedItem.isEquipped">
                                <img src="./assets/images/checkmark.svg" alt="">
                            </div>
                            <div class="shop__information-label" :class="selectedItem.rarity">{{ rarityMap[selectedItem.rarity] }}</div>
                        </div>
                        <div class="shop__information-desc">
                            {{ $t(selectedItem.desc) }}
                        </div>
                    </div>

                    <BaseButton class="shop__order-button" yellow @click = "buyItem(selectedItem)">
                        {{ $t('shop.buy') }}
                        <div class="shop__order-price">
                            <span class="shop__order-price-dollar">$</span>{{ GetFullPrice(selectedItem.price) }}
                        </div>
                    </BaseButton>
                </div>

                <div v-else class="shop__information-empty">
                    <img src="./assets/images/enter-finger.svg" alt="">
                    <p>{{ $t('shop.info') }}</p>
                </div>
            </div>

            <div class="shop__cards">
                <div class="shop__cards-wrapper">
                    <InventoryItem
                        v-for="(item, index) in computedInventoryItems"
                        :key="index || 'empty' + index"
                        :item="item"
                        :selectedItem="selectedItem"
                        @select-item="selectItem"
                    />
                </div>
            </div>

            <div class="shop__tabs">
                <div class="shop__money-filter">
                    <div
                        class="shop__money-filter-donate"
                        :class="{ 'active': filterType === 'donate' }"
                        @click="setFilter('donate')">
                        <IconDonate />
                    </div>
                    <div
                        class="shop__money-filter-cash"
                        :class="{ 'active': filterType === 'cash' }"
                        @click="setFilter('cash')">
                        $
                    </div>
                </div>

                <div class="shop__tabs-wrapper">
                    <div v-for="tab in tabDefinitions"
                         :key="tab.type"
                         class="shop__tabs-item"
                         :class="{ 'shop__tabs-item--active': selectedType === tab.type }"
                         @click="changeTab(tab.type)">
                        <IconInventory :type="tab.type"/>
                        {{ tab.text }}
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import AppHeader from '@/components/AppHeader/AppHeader.vue';
import IconInventory from '@/components/popups/content/shop/icons/IconInventory.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import InventoryItem from '@/components/popups/components/inventoryItem/InventoryItem.vue';
import { mapActions, mapGetters } from 'vuex';
import IconDonate from '@/components/icons/IconDonate.vue';

export default {
    name: 'MyShop',
    components: { IconDonate, InventoryItem, BaseButton, IconInventory, AppHeader },
    data () {
        return {
            countSlot: 30,
            selectedType: 'all',
            selectedItem: null,
            filterType: null,
            title: '',
            discount: 0,

            rarityMap: {
                legend: this.$t('shop.rarity_map.legend'),
                ordinary: this.$t('shop.rarity_map.ordinary'),
                unusual: this.$t('shop.rarity_map.unusual'),
                rare: this.$t('shop.rarity_map.rare'),
                epic: this.$t('shop.rarity_map.epic')
            },

            tabDefinitions: [
                { type: 'all', text: this.$t('shop.tabs.all') },
                { type: 'weapon', text: this.$t('shop.tabs.weapon') },
                { type: 'clothes', text: this.$t('shop.tabs.clothes') },
                { type: 'food', text: this.$t('shop.tabs.food') },
                { type: 'other', text: this.$t('shop.tabs.other') }
            ],

            shopItems: [
                { id: 2, name: 'Штурмовая винтовка', rarity: 'unusual', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/m4' },
                { id: 3, name: 'Пистолет', rarity: 'unusual', type: 1, donate: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/pistol' }
            ]

            // isEquipped: true,
        };
    },
    methods: {
        ...mapActions(['updateInventoryItemsPool']),
        setFilter (type) {
            if (this.filterType === type) {
                this.filterType = null;
            } else {
                this.filterType = type;
            }
        },
        changeTab (tabType) {
            this.selectedType = tabType;
            this.selectedItem = null; // reset selected item
        },
        selectItem (item) {
            if (this.selectedType === 'all' && this.selectedItem && !item.id) {
                this.sendclient('changeSlot', JSON.stringify({ a: this.selectedItem.index, b: item.index }));
            } else if (this.selectedItem && item.id && this.selectedItem.id === item.id && this.selectedItem.additional === item.additional) {
                if (this.selectedItem === item) this.selectedItem = null;
                else this.sendclient('changeSlot', JSON.stringify({ a: this.selectedItem.index, b: item.index }));
            } else if (this.selectedType !== 'all' || (!this.selectedItem && (item && item.id)) || (this.selectedItem && (this.selectedItem.id !== item.id || this.selectedItem.additional !== item.additional))) {
                this.selectedItem = item;
            }
        },
        buyItem (item) {
            if (this.type === 1) this.sendclient('ClientGunShopBuy', JSON.stringify({ a: item.index }));
        },
        /*
        window.modifyVueComponent({
            App: {
                showPopup: true,
                popupContent: 'MyShop',
                contentSubFolder: 'shop'
            },
            MyShop: {
                InitializeShop: [
                    [
                        {"count":89,"additional":0,"itemID":2,"price":100},{"count":9,"additional":0,"itemID":3,"price":100},
                        ],
                    [],
                ]
            }
        });
        */
        InitializeShop (itemList, title, discount, type) {
            this.title = title;
            this.discount = discount;
            this.type = type;
            this.shopItems = new Array(this.countSlot);// [];
            for (let i = 0; i < itemList.length; i++) {
                if (itemList[i].itemID) {
                    this.shopItems[i] = Object.assign({}, this.inventoryItemsPool[itemList[i].itemID - 1]);
                    this.shopItems[i].count = itemList[i].count;
                    this.shopItems[i].index = i + 1;
                    this.shopItems[i].additional = itemList[i].additional;
                    this.shopItems[i].price = itemList[i].price;
                    // if (activeSlots && activeSlots.indexOf(i + 1) !== -1) this.shopItems[i].isEquipped = true;
                } else this.shopItems[i] = { id: null, name: '', rarity: '', filterType: '', desc: '', img: '', count: 0, index: i + 1 };
            }
        },
        updateSlotInfo (number, itemInfo) {
            if (!itemInfo || itemInfo.itemID === 0) {
                this.shopItems[number - 1] = { id: null, name: '', rarity: '', filterType: '', desc: '', img: '', index: number };
            } else {
                this.shopItems[number - 1] = Object.assign({}, this.inventoryItemsPool[itemInfo.itemID - 1]);// { ...this.shopItemsPool[itemInfo.itemID - 1] };
                this.shopItems[number - 1].count = itemInfo.count;
                this.shopItems[number - 1].index = number;
                this.shopItems[number - 1].additional = itemInfo.additional;
            }
            // console.log(this.shopItems[number - 1]);
            this.selectedItem = null;

            this.Rerender();
            // this.$forceUpdate();
        },
        updateActiveSlot (number, state) {
            this.shopItems[number - 1].isEquipped = state;
            this.Rerender();
        },
        GetFullPrice (price) {
            const correctPriceToMinus = Math.floor(this.discount / 100 * price);
            return this.$formatNum(price - correctPriceToMinus);
        },
        Rerender () {
            const lastType = this.selectedType;
            this.selectedType = '';
            this.selectedType = lastType;
        }
    },
    computed: {
        ...mapGetters('inventory', ['inventoryItemsPool']),
        computedInventoryItems () {
            let filteredItems = this.shopItems;

            if (this.filterType === 'donate') {
                filteredItems = filteredItems.filter(item => 'donate' in item);
            } else if (this.filterType === 'cash') {
                filteredItems = filteredItems.filter(item => 'price' in item);
            }

            return filteredItems.filter(item => this.selectedType === 'all' || item.filterType === this.selectedType);
        }
    }

};
</script>

<style lang="scss">
.shop{
    display: flex;
    height: 100%;
    flex-direction: column;
    background: radial-gradient(46.15% 100% at 50.06% 100%, rgba(225, 71, 23, 0.94) 0%, rgba(86, 25, 16, 0.94) 100%);

    &__wrapper{
        padding: 3vw 5vw;
        display: grid;
        grid-template-columns: 3fr 6fr 1.5fr;
        grid-gap: 4vw;
        height: calc(100% - 5vw);
    }

    &__order{
        &-button {
            height: 4.45vw;
            padding: 0 1.75vw;
            font-size: 1.45vw;
            white-space: nowrap;
        }
        &-price {
            display: flex;
            align-items: center;
            margin-left: .725vw;
            background-color: var(--dark);
            color: var(--white);
            padding: .25vw .5vw;
            border-radius: .25vw;
            font-weight: 700;
            font-size: 1.6vw;
            &-dollar {
                display: inline-block;
                color: var(--gold);
                margin-right: .16vw;
            }
            &-donate {
                margin-right: 4px;
                svg {
                    width: 80px;
                    height: 80px;
                }
            }
        }
    }

    &__money-filter {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
        margin-bottom: 24px;

        div {
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 116px;
            width: 100%;
            border: 6px solid rgba(241, 241, 241, 0.10);
            color: #FFF;
            font-size: 52px;
            font-weight: 700;
            line-height: 120%; /* 62.4px */
            letter-spacing: 2.08px;
            text-transform: uppercase;

            svg {
                height: 52px;
                width: 52px;
            }

            &.active {
                border-radius: 4px;
                border: 6px solid rgba(241, 241, 241, 0.40);
                background: radial-gradient(84.15% 84.15% at 35.77% 35.34%, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%);
            }
        }
    }

    &__tabs{

        &-wrapper{
            display: grid;
            grid-gap: .5vw;
        }

        &-item{
            background: linear-gradient(224.44deg, rgba(241, 241, 241, 0.2) 0%, rgba(241, 241, 241, 0) 100%);
            border-radius: 4px;
            display: flex;
            align-items: center;
            padding: 0 2vw;
            height: 5.6vw;

            font-weight: 700;
            font-size: 1vw;
            line-height: 120%;

            letter-spacing: 0.04em;
            text-transform: uppercase;
            position: relative;

            color: #F1F1F1;

            *{
                transition: .2s ease-out;
            }

            svg{
                height: 2vw;
                width: auto;
                margin-right: 1vw;
                opacity: .5;
            }

            &--active{
                background: linear-gradient(224.44deg, rgba(241, 241, 241, 0.5) 0%, rgba(241, 241, 241, 0) 100%);

                &::before{
                    position: absolute;
                    content: "";
                    background: url(./assets/images/tab.svg) no-repeat center/cover;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }

                svg{
                    opacity: 1;
                }
            }

        }
    }

    &__cards{
        overflow-y: auto;
        margin: -5vw 0;
        padding: 5vw 0.2vw;

        &::-webkit-scrollbar{
            width: 0;
            height: 0;
            display: none;
        }

        &-count{
            padding: 0 .7vw ;
            height: 1.7vw;
            background: var(--white);
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            clip-path: polygon(0 0, 100% 0, 91% 100%, 0% 100%);
            font-size: 1.3vw;
            line-height: 100%;
            letter-spacing: -0.03em;
            font-weight: 700;
            color: #141A1E;

        }

        &-put-on{
            padding: 13px;
            width: 1.7vw;
            height: 1.7vw;
            background: #27AE60;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 0;
            top: 0;
            clip-path: polygon(19% 0, 100% 0, 100% 100%, 0% 100%);

            img{
                height: 70% !important;
            }
        }

        &-wrapper{
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-gap: 1vw;
        }

        &-update{
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-gap: 1vw;
            grid-column: 1/-1;
            position: relative;

            .btn{
                svg{
                    height: 30%;
                    width: auto;
                    margin-left: .5vw;
                }
            }

            .shop__cards-item--empty{
                opacity: .3;
            }
        }

        &-shop{
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 3vw;

            h5{
                font-weight: 700;
                font-size: 1.3vw;
                line-height: 140%;

                letter-spacing: 0.05em;
                text-transform: uppercase;

                color: var(--white);
            }
            p{
                font-size: 1.3vw;
                line-height: 140%;
                display: flex;
                align-items: flex-end;

                color: var(--white);

                opacity: 0.6;
            }
        }

        &-item{
            position: relative;
            height: 11vw;
            display: flex;
            border-radius: .2vw;
            padding: .5vw;
            align-items: flex-end;
            overflow: hidden;

            &--empty{
                background: url(./assets/images/empty.svg) no-repeat center/contain;
            }

            &--legend{
                background: url(./assets/images/legend.svg) no-repeat center/cover;
            }
            &--epic{
                background: url(./assets/images/epic.svg) no-repeat center/cover;
            }
            &--rare{
                background: url(./assets/images/rare.svg) no-repeat center/cover;
            }
            &--ordinary{
                background: url(./assets/images/ordinary.svg) no-repeat center/cover;
            }
            &--unusual{
                background: url(./assets/images/unusual.svg) no-repeat center/cover;
            }

            &--active{
                box-shadow: 0 0 0 .2vw var(--white);
                &::before{
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    content: "";
                    display: block;
                    width: 100%;
                    height: 100%;
                    background: url(./assets/images/active.svg) no-repeat center/cover;
                }

            }

            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
                position: absolute;
            }

            p{
                font-weight: 700;
                font-size: 1vw;
                line-height: 100%;
                position: relative;
                z-index: 3;
            }

        }
    }

    &__information{
        height: 100%;

        &-top{
            text-align: center;
        }

        &-item{
            padding-top: 1vw;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        &-put-on{
            padding: 13px;
            width: 1.7vw;
            height: 1.7vw;
            background: #27AE60;
            margin-right: .5vw;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-labels{
            display: flex;
        }

        &-label{
            font-weight: 700;
            font-size: .9vw;
            padding: 0.4vw 0.7vw;
            line-height: 100%;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: var(--white) ;
            width: max-content;
            margin: 0 auto;

            &.legend{
                background: linear-gradient(211.69deg, #FFCD4C 33.85%, #FFA800 100%);
            }
            &.epic{
                background: linear-gradient(211.69deg, #BE6AFF 20.31%, #7A28FF 100%);
            }
            &.rare{
                background: linear-gradient(211.69deg, #48FDC7 0%, #009D6E 100%);
            }
            &.ordinary{
                background: linear-gradient(212deg, #96ABB5 43.23%, #72848B 100%);
            }
            &.unusual{
                background: linear-gradient(211.69deg, #5FCFFF 35.94%, #2780A6 100%);
            }
        }

        &-title{
            font-weight: 400;
            font-size: 3vw;
            line-height: 100%;
            margin-bottom: 1vw;

            justify-content: center;

            display: flex;
            align-items: flex-end;

            color: #FFFFFF;
        }
        &-desc{
            margin-top: 1vw;
            font-weight: 500;
            font-size: 1vw;
            line-height: 140%;
            color: var(--white);

            opacity: 0.6;
        }
        &-empty{
            display: flex;
            height: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            img{
                height: 8vw;
                margin-bottom: 1vw;
            }
            p{
                font-weight: 500;
                font-size: 1.5vw;
                line-height: 140%;
                display: flex;
                align-items: flex-end;
                text-align: center;

                color: var(--white);

                opacity: 0.8;

            }
        }
    }

    &__buttons{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: .5vw;
        &-item{
            background: linear-gradient(119.36deg, rgba(241, 241, 241, 0.19) 0%, rgba(241, 241, 241, 0) 100%);
            border-radius: .1vw;
            height: 6.5vw;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            font-weight: 700;
            font-size: 1vw;
            line-height: 120%;

            text-align: center;
            letter-spacing: 0.04em;
            text-transform: uppercase;

            color: #F1F1F1;

            &--use{
                background: #FFED4C;
                color: #141A1E;
                box-shadow: inset 0px -26px 80px rgba(241, 241, 241, 0.4);
            }
            &--clothes{
                background: #F1F1F1;
                color: #141A1E;
                box-shadow: inset 0px -26px 80px rgba(241, 241, 241, 0.4);
            }

            svg{
                height: 2vw;
                width: auto;
                margin-bottom: .5vw;
            }
        }
    }
}
</style>
