<template>
    <div class="shop shop-money">
        <AppHeader back-button @getBackHeader="handleBackHeader">
            <template #title>{{ title }}</template>
        </AppHeader>

        <div class="shop-money__wrapper">
            <div v-for="(offer, index) in offers" :key="index" class="shop-money__item">
                <BaseHaveProperty
                    class="shop-money__economy"
                    :class="{'shop-money__economy--huge' : offer.economy >= 40}"
                    v-if="offer.economy"
                    :isConditionComplete="true"
                    :text="$t('shop.economy') + ' ' + offer.economy + '%'"
                    isShop
                />
                <img class="shop-money__image" :src="imageSource(`money-${offer.iconId}.png`, 'shop')" alt="" />
                <div class="shop-money__price">
                    <span>$</span>{{ $formatNum(offer.dollars) }}
                </div>
                <BaseButton white class="shop-money__button" @click="buy(index+1)">
                    {{ $t('shop.buy') }}
                    <div class="shop-money__button-price">
                        <IconDonate />
                        {{ $formatNum(offer.price) }}
                    </div>
                </BaseButton>
            </div>
        </div>

    </div>
</template>

<script>
import AppHeader from '@/components/AppHeader/AppHeader.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import BaseHaveProperty from '@/components/ui/BaseHaveProperty.vue';
import IconDonate from '@/components/icons/IconDonate.vue';

export default {
    name: 'ShopMoney',
    components: {
        IconDonate,
        BaseHaveProperty,
        BaseButton,
        AppHeader
    },
    data () {
        return {
            title: this.$t('shop.shop_currency'),
            offers: [
                {
                    dollars: 1000,
                    iconId: 1,
                    price: 4
                },
                {
                    dollars: 10000,
                    iconId: 2,
                    price: 40,
                    economy: 10
                },
                {
                    dollars: 100000,
                    iconId: 3,
                    price: 400,
                    economy: 40
                },
                {
                    dollars: 1000000,
                    iconId: 4,
                    price: 4000,
                    economy: 100
                }
            ]
        };
    },
    methods: {
        handleBackHeader () {
            this.sendclient('shopMain');
        },
        buy (index) {
            this.sendclient('BuyDonate', JSON.stringify({ a: index }));
        }
    }
};
</script>

<style lang="scss" scoped>
.shop-money {
    &__wrapper {
        height: calc(100% - 5vw);
        padding: 160px;
        display: grid;
        grid-gap: 0 60px;
        overflow-y: hidden;
        overflow-x: auto;
        grid-auto-flow: column;
        grid-auto-columns: 600px;
        justify-content: safe center;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    &__item {
        padding: 65px 38px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }
    &__image {
        width: 100%;
        object-fit: contain;
        transform: translateY(30px);
        height: 400px;
        z-index: 0;
    }
    &__price {
        z-index: 1;
        color: #FFF;
        font-size: 84px;
        font-weight: 700;
        line-height: 100%;
        letter-spacing: -1.68px;
        span {
            color: var(--gold);
        }
    }
    &__button {
        margin-top: auto;
        padding: 0 48px;
        &-price {
            padding: 7px 12px 7px 7px;
            background-color: var(--dark);
            color: var(--white);
            margin-left: 32px;
            display: flex;
            align-items: center;
            border-radius: 6px;
            svg {
                width: 48px;
                height: 48px;
                margin-right: 6px;
            }
        }
    }
    &__economy {
        position: absolute;
        top: 0;
        &--huge {
            background: #C30542;
        }
    }
}
</style>
