<template>
    <div class="player-menu">
        <button class="main-buttons__item main-buttons__item--menu" @click="handleOpenMenu($event)">
            <svg class="svg" width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="27" cy="27" r="27" fill="#F1F1F1"/>
            </svg>
        </button>
        <transition name="fade">
            <div class="player-menu__wrapper" v-if="openMenu">
                <transition name="slide-fade">
                    <ul class="player-menu__menu" v-if="!currentSubMenu">
                        <li v-for="item in itemMenu"
                            :key="item.index"
                            :class="{ lock: item.lock }"
                            @click="item.submenu ? openSubmenu(item, $event) : handleItemClick(item, $event)"
                        >
                            <PlayerIcons :icon="item.icon" />
                            <div>
                                {{ $t(item.title) }}
                                <span v-if="item.subtitle">{{ $t(item.subtitle) }}</span>
                            </div>
                        </li>
                    </ul>
                </transition>
                <transition name="slide-fade">
                    <ul class="player-menu__menu" v-if="currentSubMenu">
                        <button class="btn-menu" v-if="currentSubMenu" @click="getBack()">
                            <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.0723 20.1669L20.804 9.95314L18.2761 7.29712L4.2364 20.6593C3.47777 21.3813 3.4771 22.5911 4.23493 23.3139L18.2746 36.7059L20.8054 34.0527L10.0921 23.8336L40.3335 23.8355L40.3338 20.1689L25.203 20.1679L10.0723 20.1669Z" fill="#141A1E"/>
                            </svg>
                            {{ $t('player_interactive.back') }}
                        </button>
                        <li v-for="item in currentSubMenu"
                            :key="item.index"
                            :class="{ lock: item.lock }"
                            @click="item.submenu ? openSubmenu(item, $event) : handleItemClick(item, $event)"
                        >
                            <PlayerIcons :icon="item.icon" />
                            <div>
                                {{  $t(item.title) }}
                                <span v-if="item.subtitle">{{ $t(item.subtitle) }}</span>
                            </div>
                        </li>
                    </ul>
                </transition>
            </div>
        </transition>
    </div>
</template>

<script>
import PlayerIcons from '@/components/hud/components/buttons/icons/PlayerIcons.vue';

export default {
    name: 'PlayerInteractive',
    components: { PlayerIcons },
    data () {
        return {
            openMenu: false,
            currentSubMenu: null,
            itemMenu: [
                { title: 'player_interactive.call_for_transport.title', subtitle: 'player_interactive.call_for_transport.subtitle', icon: 'tp-car', lock: false, callback: 'GetTransport' },
                { title: 'player_interactive.interaction_with_players.title', subtitle: 'player_interactive.interaction_with_players.subtitle', icon: 'communication', lock: false, callback: 'PlayerInteract' },
                { title: 'player_interactive.interaction_with_transport.title', subtitle: 'player_interactive.interaction_with_transport.subtitle', icon: 'car-handle', lock: false, callback: 'VehicleInteract' },
                { title: 'player_interactive.report', subtitle: 'player_interactive.report_disc', icon: 'support', lock: false, callback: 'openReport' }
                /*
                {
                    title: 'взаимодействие с автомобилем',
                    icon: 'get-car',
                    lock: false,
                    submenu: [
                        { title: 'взаимодействие с автомобилем', icon: 'get-car', lock: false },
                        { title: 'вызов транспорта', icon: 'out-car', lock: false }
                    ]
                },
                { title: 'вызов транспорта', icon: 'out-car', lock: false },
                { title: 'управление домом', subtitle: 'Нужно находится рядом с домом', icon: 'lock', lock: true }
                */
            ]
        };
    },
    methods: {
        openInteractive () {
            this.sendclient('openInteractive');
        },
        openSubmenu (item) {
            this.currentSubMenu = item.submenu;
        },
        getBack () {
            this.currentSubMenu = null;
        },
        handleItemClick (item, event) {
            if (event) event.stopPropagation();
            this.sendclient(item.callback);
            this.handleOpenMenu(event);
        },
        handleOpenMenu (event) {
            if (event) event.stopPropagation();
            this.openMenu = !this.openMenu;
            this.currentSubMenu = null;

            if (this.openMenu) {
                document.addEventListener('click', this.boundCloseMenuOnClickOutside);
            } else {
                document.removeEventListener('click', this.boundCloseMenuOnClickOutside);
            }
        },

        closeMenuOnClickOutside (event) {
            if (event && this.$el) {
                const menuElement = this.$el.querySelector('.player-menu__wrapper');
                if (!menuElement.contains(event.target)) {
                    this.openMenu = false;
                    this.currentSubMenu = null;
                    document.removeEventListener('click', this.boundCloseMenuOnClickOutside);
                }
            }
        }
    },
    created () {
        this.boundCloseMenuOnClickOutside = this.closeMenuOnClickOutside.bind(this);
    }
};
</script>

<style lang="scss" scoped>
.main-buttons{
    &__item{
        width: 117px;
        background: transparent;
        height: 217px;
        border-radius: 62px;
        cursor: pointer;
        transition: .2s;
        position: relative;
        &--menu{
            width: 118px;
            height: 118px;
            padding: 32px;

            svg{
                width: 50px;
                height: 53px;
            }
            .svg{
                width: 54px;
                height: 54px;
            }

            &::before{
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background: url(@/assets/images/hud/hud_menu_item-circle.svg) no-repeat center/contain;
                content: "";
                position: absolute;
                display: block;
                opacity: .3;
                transition: .3s;
            }
            &:active::before{
                opacity: 1;
            }
        }
    }
}

.player-menu{
    position: relative;

    .btn-menu{
        background: rgba(20, 26, 30, 0.12);
        display: flex;
        padding: 24px 44px;
        align-items: center;
        color: #141A1E;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: 0.96px;
        text-transform: uppercase;
        border-bottom: 4px solid rgba(20, 26, 30, 0.12);

        svg{
            width: 44px;
            height: 44px;
            margin-right: 24px;
        }

    }
    &__menu{
        margin-top: 26px;
        right: 63px;
        position: fixed;
        display: flex;
        max-height: 764px;
        height: auto;
        overflow-y: scroll;
        border-radius: 24px;
        flex-direction: column;
        max-width: 756px;
        width: 100%;
        background: radial-gradient(141.10% 140.17% at 20.11% 15.71%, #EFF6FA 0%, rgba(222, 232, 239, 0.68) 100%);

        &::-webkit-scrollbar{
            width: 0;
            height: 0;
            display: none;
        }

        li{
            padding: 44px;
            display: flex;
            align-items: center;
            color: #141A1E;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: 0.96px;
            text-transform: uppercase;
            border-bottom: 4px solid rgba(20, 26, 30, 0.12);

            &:last-child {
                border-bottom: none;
            }

            &.lock{
                opacity: .4;
                pointer-events: none;
            }
            span{
                margin-top: 4px;
                color: rgba(20, 26, 30, 0.60);
                font-size: 24px;
                font-family: var(--font-stretch);
                display: block;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                letter-spacing: 0.96px;
                text-transform: uppercase;
            }
        }
    }
}
</style>

<style>
.slide-fade-enter-active, .slide-fade-leave-active {
    transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
}
</style>
