<template>
    <div id="app" class="games">
        <PreloadDesigns v-if="preload"/>
        <section class="notice-container">
            <BaseNotice
                v-for="notice in notices"
                :key="notice.id"
                :id="notice.id"
                :type="notice.type"
                :text="notice.text"
            />
        </section>
        <VHud v-show="showHud" />

        <base-popup v-model="showLoader" popupContent="errors" style="z-index: 1001">
            <component :is="errorContent" v-if="showLoader"></component>
        </base-popup>

        <base-popup v-model="showError" popupContent="errors" style="z-index: 1000">
            <component :is="errorContent" v-if="showError"></component>
        </base-popup>

        <base-popup v-model="showDialog" style="z-index: 999">
            <component :is="dialogContent" v-if="showDialog"></component>
        </base-popup>

        <base-popup v-model="showPopup" :popupContent="popupContent">
            <component :is="dynamicPopupContent" v-if="showPopup"></component>
        </base-popup>
    </div>
</template>

<script>
import VHud from '@/components/hud/VHud.vue';
import BaseNotice from '@/components/ui/BaseNotice.vue';
import PreloadDesigns from '@/components/PreloadDesigns.vue';
const BasePopup = () => import(/* webpackPrefetch: true */ '@/components/popups/BasePopup.vue');

export default {
    name: 'App',
    components: { PreloadDesigns, BaseNotice, VHud, BasePopup },
    data () {
        return {
            preload: true,
            showHud: true,
            showPopup: true,
            showDialog: false,
            showLoader: false,
            showError: false,
            techPopup: 'PopupLoader',
            dialogName: 'DialogWhere',
            popupContent: 'ServerSelect',
            contentSubFolder: 'login',
            // popupContent: 'ServerSelect', // Наименование файла
            // contentSubFolder: 'login',
            notices: []
        };
    },
    computed: {
        dynamicPopupContent () {
            if (this.popupContent) {
                return () => import(/* webpackPrefetch: true */ `@/components/popups/content/${this.contentSubFolder}/${this.popupContent}.vue`);
            }
            return null;
        },
        dialogContent () {
            if (this.dialogName) {
                return () => import(/* webpackPrefetch: true */ `@/components/popups/content/dialogs/${this.dialogName}.vue`);
            }
            return null;
        },
        errorContent () {
            if (this.techPopup) {
                return () => import(/* webpackPrefetch: true */ `@/components/popups/content/technical/${this.techPopup}.vue`);
            }
            return null;
        }

    },
    mounted () {
        this.showHud = this.showPopup || this.showDialog || this.showError ? false : this.showHud;
    },
    methods: {
        onDesignsLoaded () {
            this.preload = false;
        },
        addNotice (notice) {
            this.notices.push(notice);
        }
    },

    watch: {
        showPopup (newVal) {
            this.showHud = !newVal;
        },
        showDialog (newVal) {
            if (newVal === false && this.showPopup) {
                this.showHud = false;
            } else {
                this.showHud = !newVal;
            }
        },
        showError (newVal) {
            this.showHud = !newVal;
        }
    }
};

</script>

<style lang='scss'>
.notice-container{
    pointer-events: none;
}
</style>
