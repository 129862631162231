<template>
    <div class="numpad">
        <div
            class="numpad__item"
            v-for="item in items"
            :key="item"
            @click="handleItemClick(item)"
        >
            <template v-if="typeof item === 'number'">
                {{ item }}
            </template>
            <template v-else-if="item === 'clear'">
                <IconClear />
            </template>
            <template v-else>
                <IconClearDelete />
            </template>
        </div>
    </div>
</template>

<script>
import IconClear from '@/components/icons/IconClear.vue';
import IconClearDelete from '@/components/icons/IconClearDelete.vue';

export default {
    name: 'NumPad',

    components: { IconClear, IconClearDelete },
    data () {
        return {
            items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 'clear', 0, 'delete']
        };
    },
    methods: {
        handleItemClick (item) {
            this.$emit('item-clicked', item);
        }
    }
};
</script>

<style lang="scss">
.numpad{
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: .8vw;
    width: max-content;
    &__item{
        width: 7vw;
        height: 7vw;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #F1F1F1;
        font-weight: 500;
        font-size: 2.5vw;

        background: linear-gradient(180deg, #1B1E21 0%, rgba(16, 16, 17, 0.4) 100%);
        border-radius: 1000px;

        &:active{
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.19) 100%), linear-gradient(180deg, #1B1E21 0%, rgba(16, 16, 17, 0.4) 100%);
        }

        svg{
            height: 3vw;
            width: auto;
        }
    }
}
</style>
