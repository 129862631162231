<template>
    <div class="showroom showroom--color"
         :style="`background: linear-gradient(0deg, rgba(22, 28, 44, 0.5), rgba(22, 28, 44, 0.5)), radial-gradient(60.84% 127.31% at 50% 0%, ${getCarColor} 0%, #161C2C 100%);`">
        <AppHeader back-button>
            <template #title>{{ $t('garage.header') }}</template>
        </AppHeader>
        <div class="showroom__content"
        :class="{'showroom__content--garage': selectType === 1}">
            <div class="showroom__top">
                <div class="showroom__top-nav">
                    <button class="showroom__top-fav" v-if="selectedCar && selectType === 1" @click = "changeFavoriteStatus(!selectedCar.favorite)">
                        <IconStar :checked="selectedCar.favorite" className="showroom__top-fav-image" />
                        {{ $t('garage.to_fav') }}
                    </button>
                    <button class="showroom__top-button showroom__top-button--left"
                            @click="goGarage"
                            :class="{'showroom__top-button--active': selectType === 1}"
                    >
                        {{ $t('garage.top_menu.my_garage') }}
                    </button>
                    <button class="showroom__top-button showroom__top-button--regular"
                        :class="{'showroom__top-button--active': selectType === 4}"
                        @click="goFamGarage">
                        {{ $t('garage.top_menu.family_garage') }}
                    </button>
                    <button class="showroom__top-button showroom__top-button--regular"
                        :class="{'showroom__top-button--active': selectType === 3}"
                        @click="goOrgGarage">
                        {{ $t('garage.top_menu.org_garage') }}
                    </button>
                    <div class="showroom__top-divider"></div>
                    <button class="showroom__top-button showroom__top-button--right"
                            :class="{'showroom__top-button--active': selectType === 2}"
                            @click="goShowroom">{{ $t('garage.shop') }}</button>
                </div>
            </div>
            <div class="showroom__middle" v-if="selectedCar">
                <div class="showroom__middle-info">
                    <div class="showroom__middle-status"
                         :class="'showroom__middle-status--' + selectedCar.rarity "
                    >
                        {{rarityMap[selectedCar.rarity]}}
                    </div>
                    <div class="showroom__middle-about">
                        <div class="showroom__middle-title">{{ selectedCar.name }}</div>
                        <div class="showroom__middle-tip" v-if="selectedCar.rarity === 'free'">
                            {{ $t('garage.cant_sell_to_player') }}
                        </div>
                    </div>
                    <div class="showroom__middle-stats">
                        <div class="showroom__middle-stats-item">
                            <div class="showroom__middle-stats-boxes">
                                <div v-for="index in 8" :key="index"
                                     class="showroom__middle-stats-box"
                                     :class="{ 'showroom__middle-stats-box--filled': index <= selectedCar.controllability }">
                                </div>
                            </div>
                            <div class="showroom__middle-stats-title">
                                {{ $t('garage.car_stats.mobility') }}
                            </div>
                        </div>
                        <div class="showroom__middle-stats-item">
                            <div class="showroom__middle-stats-boxes">
                                <div v-for="index in 8" :key="index"
                                     class="showroom__middle-stats-box"
                                     :class="{ 'showroom__middle-stats-box--filled': index <= selectedCar.power }">
                                </div>
                            </div>
                            <div class="showroom__middle-stats-title">
                                 {{ $t('garage.car_stats.power') }}
                            </div>
                        </div>
                        <div class="showroom__middle-stats-item">
                            <div class="showroom__middle-stats-boxes">
                                <div v-for="index in 8" :key="index"
                                     class="showroom__middle-stats-box"
                                     :class="{ 'showroom__middle-stats-box--filled': index <= selectedCar.speed }">
                                </div>
                            </div>
                            <div class="showroom__middle-stats-title">
                                {{ $t('garage.car_stats.speed') }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="showroom__middle-car">
                    <img :src="imageSrc(selectedCar)" class="showroom__middle-car-image" alt="">
                </div>
                <div class="showroom__middle-order showroom__middle-order--garage" v-if="selectType === 1 || selectType === 3 || selectType === 4">
                    <button class="glass-button glass-button--flex showroom__middle-manage" @click="getSettings" >
                        <span class="glass-button__border"></span>
                        <span class="glass-button__border"></span>
                        <span class="glass-button__border"></span>
                        <span class="glass-button__border"></span>
                        {{ $t('garage.manage') }}
                        <IconSettings />
                    </button>
                    <button class="glass-button glass-button--flex showroom__middle-manage" @click="getDelivery">
                        <span class="glass-button__border"></span>
                        <span class="glass-button__border"></span>
                        <span class="glass-button__border"></span>
                        <span class="glass-button__border"></span>
                        {{ $t('garage.select') }}
                    </button>
                    <button class="showroom__middle-checkout" v-if = "selectType === 1" @click="getTuning">
                        {{ $t('garage.tuning_shop') }}
                        <IconTuning />
                    </button>
                </div>
                <div class="showroom__middle-order" v-if="selectType === 2">
                    <div class="showroom__middle-price">
                        <div class="showroom__middle-price-value" v-if="!isDonate">
                            <span class="dollar dollar__symbol--yellow">$</span>{{ $formatNum(selectedCar.price) }}
                        </div>
                        <div class="showroom__middle-price-value showroom__middle-price-value--donate" v-else>
                            <IconDonate /> {{ $formatNum(selectedCar.donate) }}
                        </div>
                        <div class="showroom__middle-price-text">
                            {{ $t('garage.price') }}
                        </div>
                    </div>
                    <button class="showroom__middle-checkout" @click="buyCar">
                        {{ $t('garage.buy') }}
                        <IconCheckout />
                    </button>
                </div>
            </div>
            <div class="showroom__bottom">
                <div class="showroom__catalog"
                     :class="{'showroom__catalog--small': filteredVehicles.length < 9}"
                     ref="catalog">

                    <div v-for="(item, index) in filteredVehicles"
                         :key="index"
                         @click="selectCar(item)"
                         class="showroom__catalog-item"
                         :class="{ 'showroom__catalog-item--active': item === selectedCar }">
                        <IconStar :checked="item.favorite" v-if="selectType === 1" className="showroom__catalog-fav" />
                        <img class="showroom__catalog-image" :src="imageSrc(item)" alt="">
                        <div class="showroom__catalog-title">{{ item.name }}</div>
                        <div class="showroom__catalog-status" :class="'showroom__catalog-status--' + item.rarity"></div>
                    </div>
                </div>
                <div class="showroom__filter">
                    <div v-for="(item, index) in rarityMap"
                         :key="index"
                         class="showroom__filter-item"
                         :class="['showroom__filter-item--' + index, { 'showroom__filter-item--active': selectedRarity === index }]"
                         @click="selectRarity(index)">
                        {{ item }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import IconTuning from '@/components/icons/IconTuning.vue';
import IconSettings from '@/components/icons/IconSettings.vue';
import IconStar from '@/components/icons/IconStar.vue';
import AppHeader from '@/components/AppHeader/AppHeader.vue';
import IconCheckout from '@/components/icons/IconCheckout.vue';
import IconDonate from '@/components/icons/IconDonate.vue';

export default {
    name: 'MyGarage',
    components: { IconDonate, IconCheckout, AppHeader, IconStar, IconSettings, IconTuning },
    data () {
        return {
            selectedCar: null,
            selectType: 2, // 1, 2
            extraType: 0,
            isDonate: false,

            // lastModels: [],

            selectedRarity: 'all',
            filteredVehicles: [],

            rarityMap: {
                all: this.$t('garage.rarity_map.all'),
                free: this.$t('garage.rarity_map.free'),
                regular: this.$t('garage.rarity_map.regular'),
                rare: this.$t('garage.rarity_map.rare'),
                unique: this.$t('garage.rarity_map.unique'),
                epic: this.$t('garage.rarity_map.epic'),
                legend: this.$t('garage.rarity_map.legend')
            },

            vehicleInformation: [
                { model: 1, name: 'Lysithea Huracan ST', newCar: false, color: '#D99507', rarity: 'legend', price: 3000000, donate: 0, speed: 9, power: 9, controllability: 9, img: 'vehicles/01_Huracan.png' },
                { model: 2, name: 'Hathor Impala 67', newCar: false, color: '#160EC9', rarity: 'regular', price: 10000, donate: 0, speed: 2, power: 3, controllability: 5, img: 'vehicles/02_Impala.png' },
                { model: 3, name: 'Peggy 911', newCar: false, color: '#118508', rarity: 'rare', price: 500000, donate: 0, speed: 8, power: 7, controllability: 7, img: 'vehicles/03_Por911.png' },
                { model: 4, name: 'Dionysus Hellcat', newCar: false, color: '#343791', rarity: 'regular', price: 200000, donate: 0, speed: 7, power: 5, controllability: 7, img: 'vehicles/04_Hellcat.png' },
                { model: 5, name: 'Dionysu RAM 15', newCar: false, color: '#3B3C3B', rarity: 'regular', price: 200000, donate: 0, speed: 6, power: 5, controllability: 8, img: 'vehicles/05_Ram1500.png' },
                { model: 6, name: 'Chau Camaro', newCar: false, color: '#C9AB0D', rarity: 'regular', price: 200000, donate: 0, speed: 7, power: 7, controllability: 7, img: 'vehicles/06_Camaro.png' },
                { model: 7, name: 'Grand Truck', newCar: false, color: '#C7C7C7', rarity: 'regular', price: 0, donate: 0, speed: 1, power: 1, controllability: 1, img: 'vehicles/07_Truck.png' },
                { model: 8, name: 'Aparktias Fusion 16', newCar: false, color: '#8A070D', rarity: 'regular', price: 70000, donate: 0, speed: 5, power: 6, controllability: 5, img: 'vehicles/08_Fusion.png' },
                { model: 9, name: 'Chau C7', newCar: false, color: '#100A8F', rarity: 'rare', price: 550000, donate: 0, speed: 1, power: 2, controllability: 4, img: 'vehicles/09_CorC7.png' },
                { model: 10, name: 'Mercury MG GLS', newCar: false, color: '#464645', rarity: 'unique', price: 1000000, donate: 0, speed: 7, power: 8, controllability: 9, img: 'vehicles/10_GLS63.png' },
                { model: 11, name: 'Maiz CX60', newCar: false, color: '#801011', rarity: 'rare', price: 500000, donate: 0, speed: 5, power: 4, controllability: 7, img: 'vehicles/11_MazCX60.png' },
                { model: 12, name: 'Fobos 812 Super', newCar: false, color: '#E9060B', rarity: 'epic', price: 2900000, donate: 0, speed: 8, power: 8, controllability: 6, img: 'vehicles/12_F812.png' },
                { model: 13, name: 'Hydra Civic', newCar: false, color: '#1714A7', rarity: 'regular', price: 60000, donate: 0, speed: 5, power: 4, controllability: 7, img: 'vehicles/13_Civic.png' },
                { model: 14, name: 'Onsis Insignia', newCar: false, color: '#2A910B', rarity: 'regular', price: 40000, donate: 0, speed: 5, power: 3, controllability: 5, img: 'vehicles/14_Insignia.png' },
                { model: 15, name: 'Genirate GV70', newCar: false, color: '#A48211', rarity: 'rare', price: 400000, donate: 0, speed: 6, power: 4, controllability: 7, img: 'vehicles/15_GV70.png' },
                { model: 16, name: 'Bairiga Centodieci', newCar: false, color: '#EDEDEC', rarity: 'legend', price: 3100000, donate: 0, speed: 10, power: 9, controllability: 8, img: 'vehicles/16_Centodieci.png' },
                { model: 17, name: 'Maiz MX5', newCar: false, color: '#DDDDDD', rarity: 'regular', price: 80000, donate: 0, speed: 4, power: 4, controllability: 5, img: 'vehicles/17_MazMX5.png' },
                { model: 18, name: 'Benny Bentayaga', newCar: false, color: '#1F6C09', rarity: 'rare', price: 700000, donate: 0, speed: 6, power: 6, controllability: 9, img: 'vehicles/18_Bentayaga.png' },
                { model: 19, name: 'Mercury F60', newCar: false, color: '#7B7B7B', rarity: 'regular', price: 200000, donate: 0, speed: 9, power: 7, controllability: 8, img: 'vehicles/19_MinF60.png' },
                { model: 20, name: 'Fobos SF90', newCar: false, color: '#E9060B', rarity: 'epic', price: 3000000, donate: 0, speed: 9, power: 8, controllability: 8, img: 'vehicles/20_SF90.png' },
                { model: 21, name: 'Lion XE300', newCar: false, color: '#292929', rarity: 'regular', price: 60000, donate: 0, speed: 4, power: 3, controllability: 6, img: 'vehicles/21_JagEX300.png' },
                { model: 22, name: 'Teuto Camry V70', newCar: false, color: '#7A7A7A', rarity: 'regular', price: 20000, donate: 0, speed: 3, power: 3, controllability: 5, img: 'vehicles/22_Camry.png' },
                { model: 23, name: 'Neptune Ariya', newCar: false, color: '#BE9114', rarity: 'regular', price: 30000, donate: 0, speed: 5, power: 7, controllability: 4, img: 'vehicles/23_Ariya.png' },
                { model: 24, name: 'Volume Tiguan', newCar: false, color: '#08067B', rarity: 'rare', price: 500000, donate: 0, speed: 5, power: 7, controllability: 7, img: 'vehicles/24_Tiguan.png' },
                { model: 25, name: 'Grand Taxi', newCar: false, color: '#B14F00', rarity: 'free', price: 0, donate: 0, speed: 1, power: 1, controllability: 1, img: 'vehicles/25_Taxi.png' },
                { model: 26, name: 'Army One', newCar: false, color: '#48453F', rarity: 'free', price: 0, donate: 0, speed: 1, power: 1, controllability: 1, img: 'vehicles/26_Army1.png' },
                { model: 27, name: 'Army Two', newCar: false, color: '#48453F', rarity: 'free', price: 0, donate: 0, speed: 1, power: 1, controllability: 1, img: 'vehicles/27_Army2.png' },
                { model: 28, name: 'Army Three', newCar: false, color: '#48453F', rarity: 'free', price: 0, donate: 0, speed: 1, power: 1, controllability: 1, img: 'vehicles/28_Army3.png' },
                { model: 29, name: 'Chau C8', newCar: false, color: '#B96408', rarity: 'rare', price: 700000, donate: 0, speed: 1, power: 2, controllability: 4, img: 'vehicles/29_CorC8.png' },
                { model: 30, name: 'Mercury MG GT63', newCar: false, color: '#2F2F2F', rarity: 'unique', price: 2500000, donate: 0, speed: 7, power: 7, controllability: 9, img: 'vehicles/30_Gt63AMG.png' },
                { model: 31, name: 'Ambulance', newCar: false, color: '#FFFFFF', rarity: 'free', price: 0, donate: 0, speed: 1, power: 1, controllability: 1, img: 'vehicles/31_Ambulance.png' },
                { model: 32, name: 'Volume Passat', newCar: false, color: '#4D4D4D', rarity: 'regular', price: 15000, donate: 0, speed: 5, power: 4, controllability: 7, img: 'vehicles/32_Passat.png' },
                { model: 33, name: 'Aton E-Tron', newCar: false, color: '#0060E2', rarity: 'regular', price: 70000, donate: 0, speed: 7, power: 8, controllability: 6, img: 'vehicles/33_ETron.png' },
                { model: 34, name: 'Rose Nevera', newCar: false, color: '#424242', rarity: 'legend', price: 3000000, donate: 0, speed: 9, power: 9, controllability: 8, img: 'vehicles/34_Nevera.png' },
                { model: 35, name: 'Moon Evoque', newCar: false, color: '#C00000', rarity: 'regular', price: 200000, donate: 0, speed: 6, power: 7, controllability: 6, img: 'vehicles/35_Evoque.png' },
                { model: 36, name: 'Rose Wraith', newCar: false, color: '#C00000', rarity: 'epic', price: 2000000, donate: 0, speed: 7, power: 8, controllability: 8, img: 'vehicles/36_Wraith.png' },
                { model: 37, name: 'Isonoe Q60', newCar: false, color: '#00648E', rarity: 'regular', price: 100000, donate: 0, speed: 6, power: 5, controllability: 6, img: 'vehicles/37_InfQ60.png' },
                { model: 38, name: 'Dionysu Daytona', newCar: false, color: '#00BB94', rarity: 'regular', price: 15000, donate: 0, speed: 3, power: 4, controllability: 8, img: 'vehicles/38_Daytona.png' },
                { model: 39, name: 'Rose BoatTail', newCar: false, color: '#00BB94', rarity: 'epic', price: 2000000, donate: 0, speed: 8, power: 9, controllability: 8, img: 'vehicles/39_BoatTail.png' },
                { model: 40, name: 'Trion Mod Y', newCar: false, color: '#DBDBDB', rarity: 'regular', price: 170000, donate: 0, speed: 5, power: 7, controllability: 9, img: 'vehicles/40_ModelY.png' },
                { model: 41, name: 'Hathor Chevelle', newCar: false, color: '#DBC700', rarity: 'regular', price: 12000, donate: 0, speed: 3, power: 3, controllability: 6, img: 'vehicles/41_Chevelle.png' },
                { model: 42, name: 'Mercury MG One', newCar: false, color: '#3A3A7B', rarity: 'legend', price: 3000000, donate: 0, speed: 8, power: 8, controllability: 9, img: 'vehicles/42_ProOne.png' },
                { model: 43, name: 'Aoraki DBS V12', newCar: false, color: '#9AA0A0', rarity: 'rare', price: 500000, donate: 0, speed: 6, power: 7, controllability: 6, img: 'vehicles/43_DBSV12.png' },
                { model: 44, name: 'Never Skyline G34', newCar: false, color: '#00A097', rarity: 'regular', price: 100000, donate: 0, speed: 7, power: 7, controllability: 7, img: 'vehicles/44_GTR_34.png' },
                { model: 45, name: 'Rose Spectre', newCar: false, color: '#007A88', rarity: 'epic', price: 2000000, donate: 0, speed: 8, power: 7, controllability: 9, img: 'vehicles/45_Spectre.png' },
                { model: 46, name: 'Rose Phantom', newCar: false, color: '#884F37', rarity: 'epic', price: 2200000, donate: 0, speed: 8, power: 7, controllability: 9, img: 'vehicles/46_Phantom.png' },
                { model: 47, name: 'Anubis Senat', newCar: false, color: '#712100', rarity: 'legend', price: 2000000, donate: 0, speed: 7, power: 6, controllability: 8, img: 'vehicles/47_Senat.png' },
                { model: 48, name: 'Miranda Artura', newCar: false, color: '#D4A500', rarity: 'epic', price: 2700000, donate: 0, speed: 8, power: 8, controllability: 6, img: 'vehicles/48_Artura.png' },
                { model: 49, name: 'Fobos 488', newCar: false, color: '#E01800', rarity: 'epic', price: 2800000, donate: 0, speed: 8, power: 9, controllability: 8, img: 'vehicles/49_Fer488.png' },
                { model: 50, name: 'Libery Town', newCar: false, color: '#8BE0B2', rarity: 'regular', price: 10000, donate: 0, speed: 3, power: 5, controllability: 6, img: 'vehicles/50_Town.png' },
                { model: 51, name: 'Mgla MC20', newCar: false, color: '#56A300', rarity: 'epic', price: 2500000, donate: 0, speed: 8, power: 7, controllability: 7, img: 'vehicles/51_MC20.png' },
                { model: 52, name: 'Trion Roadster', newCar: false, color: '#A6A6A6', rarity: 'rare', price: 600000, donate: 0, speed: 7, power: 9, controllability: 8, img: 'vehicles/52_Roadster.png' },
                { model: 53, name: 'Bast 750 Li', newCar: false, color: '#585858', rarity: 'regular', price: 200000, donate: 0, speed: 8, power: 7, controllability: 8, img: 'vehicles/53_750Li.png' },
                { model: 54, name: 'Bairiga Bolide', newCar: false, color: '#585858', rarity: 'legend', price: 3500000, donate: 0, speed: 9, power: 10, controllability: 8, img: 'vehicles/54_Bolide.png' },
                { model: 55, name: 'Mercury MG GLE', newCar: false, color: '#001443', rarity: 'unique', price: 1500000, donate: 0, speed: 7, power: 7, controllability: 8, img: 'vehicles/55_GLE63.png' },
                { model: 56, name: 'Lysithea Urus', newCar: false, color: '#CABD00', rarity: 'epic', price: 2000000, donate: 0, speed: 8, power: 7, controllability: 8, img: 'vehicles/56_Urus.png' },
                { model: 57, name: 'Lysithea Aventador', newCar: false, color: '#FCEC00', rarity: 'epic', price: 2400000, donate: 0, speed: 8, power: 8, controllability: 9, img: 'vehicles/57_Aventador.png' },
                { model: 58, name: 'Bast M5-34', newCar: false, color: '#7E9297', rarity: 'regular', price: 70000, donate: 0, speed: 4, power: 5, controllability: 6, img: 'vehicles/58_M5E34.png' },
                { model: 59, name: 'Teuto Supra', newCar: false, color: '#559759', rarity: 'regular', price: 100000, donate: 0, speed: 5, power: 5, controllability: 7, img: 'vehicles/59_Supra.png' },
                { model: 60, name: 'Aparktias Camper', newCar: false, color: '#7E3F43', rarity: 'regular', price: 10000, donate: 0, speed: 3, power: 2, controllability: 8, img: 'vehicles/60_Camper.png' },
                { model: 61, name: 'Mirand Senna', newCar: false, color: '#FF7700', rarity: 'legend', price: 3000000, donate: 0, speed: 8, power: 8, controllability: 8, img: 'vehicles/61_Senna.png' },
                { model: 62, name: 'Dionysu Viper', newCar: false, color: '#00C740', rarity: 'unique', price: 2100000, donate: 0, speed: 6, power: 7, controllability: 7, img: 'vehicles/62_Viper.png' },
                { model: 63, name: 'Peggy Cayman', newCar: false, color: '#D2FF00', rarity: 'rare', price: 500000, donate: 0, speed: 7, power: 6, controllability: 8, img: 'vehicles/63_Cayman.png' },
                { model: 64, name: 'Fobos F8', newCar: false, color: '#FF3100', rarity: 'epic', price: 3000000, donate: 0, speed: 8, power: 8, controllability: 7, img: 'vehicles/64_FerF8.png' },
                { model: 65, name: 'Peggy Spyder', newCar: false, color: '#BEC0D8', rarity: 'rare', price: 500000, donate: 0, speed: 7, power: 7, controllability: 8, img: 'vehicles/65_Por918.png' },
                { model: 66, name: 'Mirand P1', newCar: false, color: '#FF6D00', rarity: 'rare', price: 600000, donate: 0, speed: 9, power: 8, controllability: 8, img: 'vehicles/66_McP1.png' },
                { model: 67, name: 'Fobos FXX', newCar: false, color: '#FF3100', rarity: 'rare', price: 300000, donate: 0, speed: 8, power: 9, controllability: 7, img: 'vehicles/67_FerFXX.png' },
                { model: 68, name: 'Fobos Roma', newCar: false, color: '#CD001C', rarity: 'rare', price: 400000, donate: 0, speed: 7, power: 7, controllability: 7, img: 'vehicles/68_Roma.png' },
                { model: 69, name: 'Aoraki DB5', newCar: false, color: '#35652C', rarity: 'regular', price: 50000, donate: 0, speed: 6, power: 5, controllability: 6, img: 'vehicles/69_DB5.png' },
                { model: 70, name: 'Mercury 190E', newCar: false, color: '#545352', rarity: 'regular', price: 15000, donate: 0, speed: 3, power: 4, controllability: 5, img: 'vehicles/70_190E.png' },
                { model: 71, name: 'Mirand 765', newCar: false, color: '#FF6D00', rarity: 'rare', price: 600000, donate: 0, speed: 8, power: 7, controllability: 6, img: 'vehicles/71_MC765.png' },
                { model: 72, name: 'Never Silvia', newCar: false, color: '#D23C36', rarity: 'regular', price: 20000, donate: 0, speed: 5, power: 5, controllability: 5, img: 'vehicles/72_S15.png' },
                { model: 73, name: 'Povel 406', newCar: false, color: '#D23C36', rarity: 'regular', price: 10000, donate: 0, speed: 3, power: 4, controllability: 7, img: 'vehicles/73_Peu406.png' },
                { model: 74, name: 'Lysithea Centenario', newCar: false, color: '#00A425', rarity: 'legend', price: 3100000, donate: 0, speed: 8, power: 9, controllability: 9, img: 'vehicles/74_Centenario.png' },
                { model: 75, name: 'Aparktias Mustang VI', newCar: false, color: '#173B78', rarity: 'rare', price: 500000, donate: 0, speed: 7, power: 7, controllability: 8, img: 'vehicles/75_Mustnew.png' },
                { model: 76, name: 'Hathor Express', newCar: false, color: '#173B78', rarity: 'regular', price: 50000, donate: 0, speed: 2, power: 3, controllability: 5, img: 'vehicles/76_Express.png' },
                { model: 77, name: 'Alfa Tazio', newCar: false, color: '#008D24', rarity: 'legend', price: 3000000, donate: 0, speed: 8, power: 7, controllability: 7, img: 'vehicles/77_Tazio.png' },
                { model: 78, name: 'Aparktias 300', newCar: false, color: '#8D8D8D', rarity: 'regular', price: 50000, donate: 0, speed: 6, power: 6, controllability: 7, img: 'vehicles/78_Chry300.png' },
                { model: 79, name: 'King Jesko', newCar: false, color: '#55638D', rarity: 'legend', price: 3200000, donate: 0, speed: 8, power: 9, controllability: 9, img: 'vehicles/79_Jesko.png' },
                { model: 80, name: 'Dionysu F150', newCar: false, color: '#A6A6A6', rarity: 'regular', price: 200000, donate: 0, speed: 5, power: 6, controllability: 7, img: 'vehicles/80_F150.png' },
                { model: 81, name: 'Bast M3-46', newCar: false, color: '#86A686', rarity: 'regular', price: 70000, donate: 0, speed: 4, power: 3, controllability: 6, img: 'vehicles/81_E46.png' },
                { model: 82, name: 'Peggy Macan', newCar: false, color: '#5D5D5D', rarity: 'unique', price: 1800000, donate: 0, speed: 7, power: 7, controllability: 7, img: 'vehicles/82_Macan.png' },
                { model: 83, name: 'Teuto Hilux', newCar: false, color: '#5D5D5D', rarity: 'regular', price: 180000, donate: 0, speed: 6, power: 6, controllability: 8, img: 'vehicles/83_Hilux.png' },
                { model: 84, name: 'Aparktias Mustang GT5', newCar: false, color: '#455D5B', rarity: 'regular', price: 70000, donate: 0, speed: 6, power: 5, controllability: 6, img: 'vehicles/84_GT500.png' },
                { model: 85, name: 'Aoraki Rapid', newCar: false, color: '#3D5D41', rarity: 'regular', price: 150000, donate: 0, speed: 4, power: 5, controllability: 8, img: 'vehicles/85_Rapid.png' },
                { model: 86, name: 'Bast 750I-38', newCar: false, color: '#3D5D41', rarity: 'regular', price: 65000, donate: 0, speed: 7, power: 7, controllability: 8, img: 'vehicles/86_750I.png' },
                { model: 87, name: 'Mercury MG CLS63', newCar: false, color: '#424242', rarity: 'regular', price: 200000, donate: 0, speed: 7, power: 7, controllability: 9, img: 'vehicles/87_CLS63.png' },
                { model: 88, name: 'Peggy Taycan', newCar: false, color: '#717FFF', rarity: 'rare', price: 800000, donate: 0, speed: 7, power: 10, controllability: 9, img: 'vehicles/88_Taycan.png' },
                { model: 89, name: 'Bast M8-Coupe', newCar: false, color: '#3D5D41', rarity: 'regular', price: 300000, donate: 0, speed: 7, power: 7, controllability: 7, img: 'vehicles/89_M8Cou.png' },
                { model: 90, name: 'Genirate G70', newCar: false, color: '#92263A', rarity: 'regular', price: 220000, donate: 0, speed: 7, power: 8, controllability: 9, img: 'vehicles/90_G70.png' },
                { model: 91, name: 'Mercury Brab GLC600', newCar: false, color: '#424242', rarity: 'unique', price: 900000, donate: 0, speed: 7, power: 7, controllability: 9, img: 'vehicles/91_GLC600.png' },
                { model: 92, name: 'Dionysu Expedition', newCar: false, color: '#424242', rarity: 'regular', price: 250000, donate: 0, speed: 6, power: 6, controllability: 7, img: 'vehicles/92_Expedition.png' },
                { model: 93, name: 'Saturn BRZ', newCar: false, color: '#1038B3', rarity: 'regular', price: 90000, donate: 0, speed: 5, power: 6, controllability: 8, img: 'vehicles/93_BRZ.png' },
                { model: 94, name: 'Hydra NSX', newCar: false, color: '#88B34D', rarity: 'rare', price: 650000, donate: 0, speed: 8, power: 7, controllability: 9, img: 'vehicles/94_NSX.png' },
                { model: 95, name: 'Peggy Panamera Tur', newCar: false, color: '#005E79', rarity: 'rare', price: 450000, donate: 0, speed: 7, power: 7, controllability: 8, img: 'vehicles/95_Panamera.png' },
                { model: 96, name: 'Peggy Panamera', newCar: false, color: '#005E79', rarity: 'rare', price: 430000, donate: 0, speed: 7, power: 7, controllability: 8, img: 'vehicles/96_Panamera2.png' },
                { model: 97, name: 'Dionysu Grand Cher', newCar: false, color: '#3B7B5D', rarity: 'regular', price: 250000, donate: 0, speed: 5, power: 6, controllability: 10, img: 'vehicles/97_Chero.png' },
                { model: 98, name: 'Teuto Evolution', newCar: false, color: '#D6C800', rarity: 'regular', price: 55000, donate: 0, speed: 4, power: 5, controllability: 7, img: 'vehicles/98_Evolutio.png' },
                { model: 99, name: 'Aton R8 19', newCar: false, color: '#890018', rarity: 'rare', price: 690000, donate: 0, speed: 8, power: 8, controllability: 8, img: 'vehicles/99_AR8.png' },
                { model: 100, name: 'Moon Sport SVR', newCar: false, color: '#3B7B5D', rarity: 'rare', price: 650000, donate: 0, speed: 6, power: 7, controllability: 8, img: 'vehicles/100_SVR.png' },
                { model: 101, name: 'Never 400Z', newCar: false, color: '#F1D100', rarity: 'regular', price: 140000, donate: 0, speed: 6, power: 6, controllability: 7, img: 'vehicles/101_400z.png' },
                { model: 102, name: 'Mgla Alfieri', newCar: false, color: '#CD001C', rarity: 'regular', price: 190000, donate: 0, speed: 7, power: 7, controllability: 6, img: 'vehicles/102_Alfieri.png' },
                { model: 103, name: 'Hydra LX600', newCar: false, color: '#424242', rarity: 'rare', price: 630000, donate: 0, speed: 6, power: 6, controllability: 9, img: 'vehicles/103_LX600.png' },
                { model: 104, name: 'Grand Police One', newCar: false, color: '#424242', rarity: 'free', price: 0, donate: 0, speed: 8, power: 8, controllability: 8, img: 'vehicles/104_Police1.png' },
                { model: 105, name: 'Grand Fire One', newCar: false, color: '#424242', rarity: 'free', price: 0, donate: 0, speed: 5, power: 5, controllability: 8, img: 'vehicles/105_Fire.png' },
                { model: 106, name: 'Mercury MG GT', newCar: false, color: '#424242', rarity: 'unique', price: 1300000, donate: 0, speed: 7, power: 7, controllability: 8, img: 'vehicles/106_MerGT.png' },
                { model: 107, name: 'Aoraki DB 11', newCar: false, color: '#223E75', rarity: 'rare', price: 500000, donate: 0, speed: 5, power: 6, controllability: 7, img: 'vehicles/107_DB11.png' },
                { model: 108, name: 'Hydra LX300', newCar: false, color: '#67000F', rarity: 'rare', price: 570000, donate: 0, speed: 6, power: 6, controllability: 7, img: 'vehicles/108_RX300.png' },
                { model: 109, name: 'Blindado', newCar: false, color: '#67000F', rarity: 'free', price: 0, donate: 0, speed: 4, power: 4, controllability: 4, img: 'vehicles/109_Blindado.png' },
                { model: 110, name: 'Mercury SLR', newCar: false, color: '#494B67', rarity: 'regular', price: 200000, donate: 0, speed: 7, power: 6, controllability: 8, img: 'vehicles/110_SLR.png' },
                { model: 111, name: 'Aoraki DBS ', newCar: false, color: '#518945', rarity: 'rare', price: 500000, donate: 0, speed: 7, power: 6, controllability: 8, img: 'vehicles/111_DBS.png' },
                { model: 112, name: 'Aton RS4', newCar: false, color: '#7F7F7F', rarity: 'regular', price: 270000, donate: 0, speed: 6, power: 6, controllability: 7, img: 'vehicles/112_RS4.png' },
                { model: 113, name: 'Teuto Optima', newCar: false, color: '#EEEEEE', rarity: 'regular', price: 40000, donate: 0, speed: 4, power: 5, controllability: 5, img: 'vehicles/113_Optima.png' },
                { model: 114, name: 'Mercury C63', newCar: false, color: '#424242', rarity: 'regular', price: 260000, donate: 0, speed: 7, power: 7, controllability: 8, img: 'vehicles/114_C63.png' },
                { model: 115, name: 'Mercury MayB Pullman', newCar: false, color: '#515456', rarity: 'epic', price: 2300000, donate: 0, speed: 6, power: 7, controllability: 9, img: 'vehicles/115_Pullman.png' },
                { model: 116, name: 'Volume S90 Recharge', newCar: false, color: '#354270', rarity: 'regular', price: 230000, donate: 0, speed: 6, power: 7, controllability: 6, img: 'vehicles/116_s90.png' },
                { model: 117, name: 'GC Sierra 3500', newCar: false, color: '#B58100', rarity: 'rare', price: 350000, donate: 0, speed: 5, power: 5, controllability: 7, img: 'vehicles/117_Siera35.png' }
            ],

            sellVehicles: [
                1,
                2,
                3,
                4,
                5,
                6
            ],

            vehicles: []
        };
    },
    computed: {
        getCarColor () {
            if (this.selectedCar) {
                return this.selectedCar.color;
            }
            return '#000000'; // дефолтный цвет, если выбранная машина не определена
        }
    },
    mounted () {
        this.filteredVehicles = this.vehicles;
        if (this.vehicles.length > 0) {
            this.selectCar(this.vehicles[0]);
        }
        this.sortVehicles();
    },

    methods: {
        /*
        window.modifyVueComponent({
            App: {
                showPopup: true,
                popupContent: 'MyGarage',
                contentSubFolder: 'garage'
            },
            MyGarage: {
                initialize: [
                    [1,2,3],
                    0,
                    3,
                ]
            }
        });
        */
        initialize (vehicleModels, favoriteVehicleCount, type, extraType = 0) {
            // this.lastModels = vehicleModels;
            this.selectType = type;
            this.extraType = extraType;
            this.vehicles = [];
            if (type === 1 || type === 3 || type === 4) {
                for (let i = 0; i < vehicleModels.length; i++) {
                    const foundedJSon = this.vehicleInformation.find(item => item.model === vehicleModels[i]);
                    const json = Object.assign({}, foundedJSon);
                    json.index = i + 1;
                    if (favoriteVehicleCount >= i + 1)json.favorite = true;
                    this.vehicles.push(json);
                }
            } else if (type === 2) {
                for (let i = 0; i < this.sellVehicles.length; i++) {
                    const foundedJSon = this.vehicleInformation.find(item => item.model === this.sellVehicles[i]);
                    const json = Object.assign({}, foundedJSon);
                    json.index = i + 1;
                    this.vehicles.push(json);
                }
            }
            if (this.vehicles.length > 0) {
                this.selectCar(this.vehicles[0]);
            }
            this.reRender();
        },
        /*
        changeFavCount (favoriteVehicleCount) {
            this.initialize(this.lastModels, favoriteVehicleCount, this.selectType);
        },
        */
        reRender () {
            this.selectRarity(this.selectedRarity);
        },
        imageSrc (vehicles) {
            return `https://launcher.gta5grand.com/game/mobile/images/${vehicles.img}`;// require(`@/assets/images/garage/${vehicles.img}.png`);
        },
        selectCar (vehicles) {
            this.selectedCar = vehicles;
            if (vehicles.donate > 0) this.isDonate = true;
            else this.isDonate = false;
        },
        selectRarity (rarity) {
            this.selectedRarity = rarity;
            if (rarity === 'all') {
                this.filteredVehicles = this.vehicles;
            } else {
                this.filteredVehicles = this.vehicles.filter(vehicle => vehicle.rarity === rarity);
            }
            this.sortVehicles();
        },
        sortVehicles () {
            this.filteredVehicles.sort((a, b) => b.favorite - a.favorite);
        },
        goShowroom () {
            this.sendclient('loadShowroom');
        },
        getSettings () {
            if (!this.selectedCar || !this.selectedCar.index) return;
            if (this.selectType === 1) this.sendclient('myVehicleAction', JSON.stringify({ a: this.selectedCar.index }));
            else if (this.selectType === 3) this.sendclient('OrgVehAction', JSON.stringify({ a: this.selectedCar.index }));
            else if (this.selectType === 4) this.sendclient('FamVehAction', JSON.stringify({ a: this.selectedCar.index }));
        },
        getDelivery () {
            if (!this.selectedCar || !this.selectedCar.index) return;
            if (this.selectType === 1) this.sendclient('myVehicleDelivery', JSON.stringify({ a: this.selectedCar.index }));
            else if (this.selectType === 3) this.sendclient('OrgVehDelivery', JSON.stringify({ a: this.selectedCar.index }));
            else if (this.selectType === 4) this.sendclient('FamVehDelivery', JSON.stringify({ a: this.selectedCar.index }));
        },
        getTuning () {
            if (!this.selectedCar || !this.selectedCar.index) return;
            this.sendclient('myVehicleTuning', JSON.stringify({ a: this.selectedCar.index }));
        },
        goGarage () {
            this.sendclient('goGarage');
        },
        goFamGarage () {
            this.sendclient('goFamGarage');
        },
        goOrgGarage () {
            this.sendclient('goOrgGarage');
        },
        buyCar () {
            // console.log('Продаем тачку');
            this.sendclient('buyVehicle', JSON.stringify({ a: this.selectedCar.index }));
        },
        changeFavoriteStatus (newStatus) {
            if (!this.selectedCar || !this.selectedCar.index) return;
            this.sendclient('myVehicleFavStatus', JSON.stringify({ a: this.selectedCar.index, b: newStatus }));
            this.selectCar(null);
        }

    }
};
</script>
