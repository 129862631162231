import { render, staticRenderFns } from "./IconDonate.vue?vue&type=template&id=4bb50443&scoped=true&"
var script = {}
import style0 from "./IconDonate.vue?vue&type=style&index=0&id=4bb50443&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bb50443",
  null
  
)

export default component.exports