<template>
    <div class="header">
        <div class="header__wrapper">
            <div class="header__left-side">
                <div class="header__return" v-if="backButton" @click="getBackHeader">
                    <img src="./assets/images/back.svg" alt="back">
                </div>
                <div class="header__nickname" v-if="nickName" @click="getProfile">
                    <div class="header__nickname-level">
                        <span>{{ playerLevel }}</span>
                    </div>
                    <div class="header__nickname-name">{{ playerName }}</div>
                    <div class="header__nickname-id"><span>ID</span> {{ $store.state.player.id }}</div>
                </div>
                <div class="header__title" v-if="$slots.title">
                    <slot name="title"></slot>
                </div>
                <div
                    v-if="notifications !== undefined"
                    @click="$emit('toggleNotifications')"
                    class="header__notifications"
                    :class="{'header__notifications--disabled' : notifications === 0}"
                >
                    <IconNotify :isActive="!!notifications" />
                    <div class="header__notifications-text">
                        {{ notifications === 1 ? $t('header.notifications_on') : $t('header.notifications_off') }}
                    </div>
                </div>
            </div>
            <div class="header__right-side">
                <div class="header__shop" @click="clickShop">
                    <IconDonatShop />
                    {{ $t('header.shop') }}
                </div>
                <div class="header__balance">
                    <div v-if="showEvent && currentEvent.show > 0" class="header__balance-points">
                        <IconEvents />
                        <div class="header__balance-points-text"> {{ $formatNum(currentEvent.money) }}</div>
                    </div>
                    <div class="header__balance-points">
                        <IconDonate />
                        <div class="header__balance-points-text"> {{ $formatNum(donate) }}</div>
                    </div>
                    <div class="header__balance-money">
                        <span class="header__balance-money-text header__balance-money-text--gold">$</span>
                        <span class="header__balance-money-text"> {{ $formatNum(money) }}</span>
                    </div>
                    <div class="header__currency">
                        <DoubleCurrency v-if="doubleCurrency" />
                        <button class="header__balance-button">
                            <img src="@/assets/images/hud/phone/plus-button.svg" alt="" >
                        </button>
                    </div>
                </div>
                <div class="header__slot" v-if="$slots.countSlot">
                    <p>{{ $t('header.slots') }}</p>
                    <span><slot name="countSlot"></slot></span>
                </div>
                <div class="header__close" @click="closeDialog">
                    <img src="./assets/images/closeButton.svg" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconDonatShop from '@/components/icons/IconDonatShop.vue';
import IconDonate from '@/components/icons/IconDonate.vue';
import IconEvents from '@/components/icons/IconEvents.vue';
import IconNotify from '@/components/icons/IconNotify.vue';
import DoubleCurrency from '@/components/AppHeader/icons/DoubleCurrency.vue';
export default {
    name: 'AppHeader',
    components: { DoubleCurrency, IconNotify, IconEvents, IconDonate, IconDonatShop },
    data () {
        return {
            cancelCloseDesigns: false
        };
    },
    props: {
        backButton: {
            type: Boolean,
            default: false
        },
        nickName: {
            type: Boolean,
            default: false
        },
        showEvent: {
            type: Boolean,
            default: false
        },
        notifications: {
            type: Number
        }
    },

    computed: {
        doubleCurrency () {
            return this.$store.state.player.doubleCurrency;
        },
        playerName () {
            return this.$store.state.player.playerName;
        },
        playerLevel () {
            return this.$store.state.player.playerLevel;
        },
        donate () {
            return this.$store.getters['player/formattedDonation'];
        },
        money () {
            return this.$store.getters['player/formattedCurrency'];
        },
        currentEvent () {
            return this.$store.state.player.event;
        }
    },

    methods: {
        clickShop () {
            this.sendclient('openShop');
        },
        getBackHeader () {
            this.$listeners.getBackHeader ? this.$emit('getBackHeader') : this.sendclient('closeDesigns');
        },

        closeDialog () {
            /* if (this.isCharacter === true) this.sendclient('LeaveBarber');
            else */
            this.sendclient('closeDesigns');
        },
        getProfile () {
            this.sendclient('MyProfile');
        }
    }
};
</script>

<style lang="scss" scoped>
.header{
    height: 5vw;
    background: var(--dark) url(./assets/images/header-bg.svg) no-repeat center/cover;
    box-shadow: inset 0 -84px 74px rgba(61, 76, 100, 0.2);
    position: relative;
    border-width: 5px;
    border-style: solid;
    border-image-source: linear-gradient(90deg, rgba(241, 241, 241, 0.063) 0%, rgba(241, 241, 241, 0.3) 25%, rgba(241, 241, 241, 0.075) 50%, rgba(241, 241, 241, 0.237) 77.08%, rgba(241, 241, 241, 0.03) 100%);
    border-image-slice: 1;
    z-index: 9;
    flex-shrink: 0;
    &__wrapper{
        display: flex;
        justify-content: space-between;
        height: 100%;
        padding-left: 90px;
    }
    &__left-side{
        height: 100%;
        display: flex;
        align-items: center;
    }
    &__right-side{
        height: 100%;
        display: flex;
        align-items: center;
    }
    &__return{
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;

        img{
            height: 100%;
        }
    }
    &__title{
        font-weight: 900;
        font-size: 64px;
        line-height: 100%;
        font-family: var(--font-stretch);
        text-transform: uppercase;
        color: #FFF;
        text-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);
    }
    &__nickname{
        height: 100%;
        display: flex;
        align-items: center;

        &-id{
            border-radius: 6px;
            opacity: 0.8;
            background: #141A1E;
            display: flex;
            padding: 6px 8px;
            justify-content: center;
            align-items: center;
            color: #FFF;
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;

            span{
                color: #FFF;
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                margin-right: 4px;
                opacity: 0.4;
            }
        }

        &-level{
            background: url(@/assets/images/level-icon.png) no-repeat center/contain;
            height: 100%;
            display: flex;
            width: 84px;
            align-items: center;
            justify-content: center;
            text-align: center;
            span {
                letter-spacing: -1.44px;
                transform: translateX(-2.44px);
                text-shadow: 0 4px 16px rgba(0, 0, 0, 0.25);
                color: var(--white);
                font-weight: 900;
                font-size: 36px;
                line-height: 100%;
            }
        }

        &-name{
            font-weight: 400;
            font-size: 44px;
            line-height: 100%;
            color: #FFF;
            margin: 0 16px;
        }
    }
    &__balance {
        display: grid;
        //grid-template-columns: repeat(3, auto);
        grid-auto-flow: column;
        grid-gap: 0 44px;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        margin: 0 64px;
        &-points {
            display: flex;
            align-items: center;
            &::v-deep {
                svg {
                    width: 48px;
                    height: 48px;
                }
            }
            &-text {
                font-size: 48px;
                color: var(--white);
                font-weight: 700;
                margin-left: 12px;
                line-height: 100%;
            }
            &-icon {
                svg {
                    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.25));
                    width: 1.9vw;
                    height: auto;
                }
            }
        }
        &-money {
            &-text {
                font-size: 48px;
                font-weight: 700;
                &--gold {
                    color: var(--gold);
                }
            }
        }
        &-button {
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25), inset 0 2px 0 rgba(241, 241, 241, 0.55);
            border-radius: 12px;
            border: none;
            outline: none;
            padding: 0;
            width: 68px;
            height: 68px;
            background-color: var(--gold);
            //background: var(--gold) url(@/assets/images/hud/phone/plus-button.svg) no-repeat center/44px;
            img {
                width: 44px;
                height: 44px;
                object-fit: cover;
            }
        }
    }

    &__shop{
        background: linear-gradient(180deg, rgba(195, 5, 66, 0.4) 0%, rgba(195, 5, 66, 0) 100%);
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 40px;

        font-weight: 700;
        font-size: 32px;

        text-transform: uppercase;
        letter-spacing: 1.28px;

        color: #FFF;

        &::v-deep svg {
            margin-right: 12px;
            width: 68px;
            height: 68px;
        }
    }

    &__slot{
        display: flex;
        align-items: center;
        padding: 0 44px;
        height: 100%;

        border-left: 6px solid;
        border-image-source: linear-gradient(-45deg, var(--white) 0%, rgba(241, 241, 241, 0) 100%);
        border-image-slice: 1;
        p{
            font-weight: 700;
            font-size: 32px;
            line-height: 100%;
            letter-spacing: 0.04em;
            color: rgba(255, 255, 255, 0.4);
            margin-right: 1vw;

        }

        span{
            font-weight: 700;
            font-size: 48px;
            color: var(--white);
        }
    }
    &__close{
        height: 100%;
        display: flex;
        align-items: center;

        border-left: 6px solid;
        border-image-source: linear-gradient(-45deg, var(--white) 0%, rgba(241, 241, 241, 0) 100%);
        border-image-slice: 1;

        img{
            height: 100%;
        }
    }
    &__notifications {
        border-radius: 6px;
        background: #3B4A57;
        margin-left: 24px;
        padding: 10px 24px;
        text-transform: uppercase;
        color: var(--white);
        font-size: 24px;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: 0.96px;
        display: flex;
        align-items: center;
        &::v-deep {
            svg {
                margin-right: 12px;
                width: 44px;
                height: 44px;
                path {
                    fill: var(--white);
                }
            }
        }
        &--disabled {
            color: rgba(241,241,241, .4);
            &::v-deep {
                svg {
                    path {
                       fill: rgba(241,241,241, .4);
                    }
                }
            }
        }
    }
    &__currency {
        display: flex;
        align-items: center;
        &::v-deep {
            svg {
                margin-right: 12px;
                width: 94px;
                height: 52px;
            }
        }
    }
}
</style>
