<template>
    <div class="work-list">
        <AppHeader backButton>
            <template #title>{{ $t('work_list.header') }}</template>
        </AppHeader>
        <div
            class="work-list__grid"
            :class="{'work-list__grid--small' : this.workList.length <= 3}"
        >
            <div
                v-for="item in workList"
                :key="item.index"
                class="work-list__item"
                @click="selectWork(item)"
                :style="{background: `linear-gradient(180deg, rgba(20, 26, 30, 0.45) 35.94%, rgba(20, 26, 30, 0.80) 100%), url(${imageSource(item.image, 'works')}) no-repeat center/cover, lightgray 50% / cover no-repeat`}"
            >
                <BaseLevel
                    :showText="false" :level="String(item.level)"
                    :levelIcon="item.level"
                    :width="3.94945"
                    :fontSize="2.29068"
                />
                <div class="work-list__title">{{ item.name }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import AppHeader from '@/components/AppHeader/AppHeader.vue';
import BaseLevel from '@/components/ui/BaseLevel.vue';

export default {
    name: 'WorkList',
    components: { BaseLevel, AppHeader },
    data () {
        return {
            workList: [
                { id: 1, level: 1, name: this.$t('work_list.bus_driver'), image: 'work-bus.jpg' },
                { id: 2, level: 1, name: this.$t('work_list.loader'), image: 'loader.jpg' },
                { id: 3, level: 2, name: this.$t('work_list.farmer'), image: 'work-fermer.jpg' },
                { id: 4, level: 1, name: this.$t('work_list.woodcutter'), image: 'work-woodman.jpg' },
                { id: 5, level: 3, name: this.$t('work_list.miner'), image: 'work-miner.jpg' },
                { id: 6, level: 1, name: this.$t('work_list.fisherman'), image: 'work-fishman.jpg' },
                { id: 7, level: 1, name: this.$t('work_list.trucker'), image: 'work-track.jpg' },
                { id: 8, level: 1, name: this.$t('work_list.taxist'), image: 'work-taxi.jpg' }
            ]
        };
    },
    methods: {
        /*
        window.modifyVueComponent({
            App: {
                showPopup: true,
                popupContent: 'WorkList',
                contentSubFolder: 'works'
            },
            WorkList: {
                initialize: [
                    [1,2,3,3,1,2,1,2],
                ]
            }
        });
        */
        initialize (levels) {
            for (let i = 0; i < this.workList.length; i++) {
                this.workList[i].level = levels[i];
            }
        },
        selectWork (item) {
            this.sendclient('WorkList', JSON.stringify({ a: item.id }));
        }
    }
};
</script>
<style lang="scss" scoped>
.work-list {
    background: url('@/assets/images/bg-popup-vector-dark.svg') no-repeat center/cover, radial-gradient(66.94% 140.08% at 50% 0%, #6B25FF 0%, #372093 100%);
    height: 100%;
    &__grid {
        height: calc(100% - 5vw);
        display: grid;
        grid-gap: 24px;
        overflow-y: hidden;
        overflow-x: auto;
        grid-auto-flow: column;
        grid-auto-columns: 600px;
        grid-template-rows: 1fr 1fr;
        justify-content: safe center;
        padding: 90px 128px;
        &::-webkit-scrollbar {
            display: none;
        }
        &--small {
            grid-template-rows: repeat(4, 1fr);
            .work-list__item {
                grid-row: 2 / 4;
            }
        }
    }
    &__item {
        border-radius: 12px;
        padding: 64px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &__title {
        margin-top: 24px;
        color: var(--white);
        text-align: center;
        font-size: 44px;
        line-height: 120%;
    }
}
</style>
