<template>
    <div class="contacts" @click="toggleSubMenu(true)">
        <AppHeader :notifications="notifications" @toggleNotifications="toggleNotifications">
            <template #title>{{ $t('contacts.header') }}</template>
        </AppHeader>
        <div class="contacts-content">
            <div class="contacts-sidebar">
                <div class="contacts-calls">
                    <div v-for="(button, index) in buttons"
                         :key="index"
                         class="contacts-calls__item contacts-calls__item--police"
                         :class="{
                             'contacts-calls__item--police' : button.type === 1,
                             'contacts-calls__item--med' : button.type === 2,
                             'contacts-calls__item--news' : button.type === 3
                         }"
                         @click="openChat(button)"
                    >
                        {{ button.text }}
                    </div>
                </div>
                <BaseInput
                    class="contacts-search"
                    v-model="search"
                    :placeholder="$t('contacts.search_placeholder')"
                    @enter-pressed="searchPlayer"
                >
                    <div class="contacts-search__button" @click="searchPlayer"></div>
                </BaseInput>
                <div class="contacts-list">
                    <div
                        v-for="(contact, index) in contacts"
                        :key="index"
                        class="contacts-list__item"
                        :class="{'contacts-list__item--active' : activeChat === contact}"
                        @click="selectChat(contact)"
                    >
                        <div class="contacts-list__item-circle" :style="{background: stringToRGB(contact.playerName)}">
                            {{ GetCorrectName(contact.playerName,contact.simCard) }}
                            <div v-if="contact.messages.unread.length > 0" class="contacts-list__item-unread">{{ contact.messages.unread.length }}</div>
                        </div>
                        <div class="contacts-list__item-info">
                            <div class="contacts-list__item-heading">
                                <div class="contacts-list__item-title">
                                    {{ contact.playerName }}
                                </div>
                                <div class="contacts-list__item-time">
                                    {{ GetLastTime(contact.messages) }}
                                </div>
                            </div>
                            <div class="contacts-list__item-message">
                                {{ GetLastMessage(contact) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="activeChat" class="contacts-main">
                <div class="contacts-list__item">
                    <div class="contacts-list__item-circle" :style="{background: stringToRGB(activeChat.playerName)}">
                        {{ GetCorrectName(activeChat.playerName,activeChat.simCard) }}
                    </div>
                    <div class="contacts-list__item-info contacts-main__info">
                        <div class="contacts-list__item-title">
                            {{ activeChat.playerName }}
                        </div>
                        <!--
                        <div class="contacts-list__item-message">
                            Был в сети вчера в 13:30
                        </div>
                        -->
                    </div>
                    <div class="contacts-main__sim">
                        {{ formatSimNumber(activeChat.simCard) }}
                        <IconContactsSim />
                    </div>
                    <div class="contacts-main__menu" @click.stop="toggleSubMenu()">
                        <div class="contacts-main__menu-dot"></div>
                        <div class="contacts-main__menu-dot"></div>
                        <div class="contacts-main__menu-dot"></div>
                    </div>
                    <transition name="fade">
                        <div v-if="this.submenu.isActive" class="contacts-main__submenu">
                            <div
                                v-for="(item, index) in submenu.items" :key="index"
                                class="contacts-main__submenu-item"
                                @click="item.callback(item)"
                            >
                                <IconTrash v-if="item.icon === 'trash'" />
                                <IconPin v-if="item.icon === 'pin'" />
                                <IconNotify v-if="item.icon === 'notify'" :isActive="item.isActive" />
                                {{ item.title }}
                            </div>
                        </div>
                    </transition>
                </div>
                <div class="contacts-chat" ref="chat">
                    <div
                        v-for="(message, index) in activeChat.messages.read"
                        :key="index"
                        class="contacts-chat__message"
                        :class="{'contacts-chat__message--own' : message.isOwn,
                        'contacts-chat__message--first' : activeChat.messages.read[index + 1] && activeChat.messages.read[index - 1] && message.isOwn && !activeChat.messages.read[index - 1].isOwn || !activeChat.messages.read[index + 1] && message.isOwn && !activeChat.messages.read[index - 1].isOwn,
                        'contacts-chat__message--last' : activeChat.messages.read[index + 1] && message.isOwn && !activeChat.messages.read[index + 1].isOwn || !activeChat.messages.read[index + 1], 'contacts-chat__message--geo' : message.isGeo}"
                    >
                        <template v-if="message.isGeo">
                            <div class="contacts-chat__message-time">
                                {{ message.time }}
                            </div>
                            <BaseButton white class="contacts-chat__message-geo" @click="clickGeo(message.text)">
                                <IconPin />
                                {{ $t('contacts.geo') }}
                            </BaseButton>
                        </template>
                        <template v-else>
                            <div class="contacts-chat__message-text">
                                {{ message.text }}
                            </div>
                            <div class="contacts-chat__message-time">
                                {{ message.time }}
                            </div>
                        </template>
                    </div>
                    <template v-if="activeChat.messages.unread.length > 0">
                        <div class="contacts-chat__unread">
                            <IconContactsUnread />
                            <div>{{ $t('contacts.unread') }}</div>
                            <IconContactsUnread style="transform: rotate(180deg)" />
                        </div>
<!--                        TODO: Если будет баг с отступами, добавить в условие как в массиве с .read-->
                        <div v-for="(message, index) in activeChat.messages.unread"
                             :key="index + message"
                             class="contacts-chat__message"
                             :class="{'contacts-chat__message--own' : message.isOwn,
                        'contacts-chat__message--first' : activeChat.messages.unread[index + 1] && activeChat.messages.unread[index - 1] && message.isOwn && !activeChat.messages.unread[index - 1].isOwn || !activeChat.messages.unread[index + 1] && message.isOwn,
                        'contacts-chat__message--last' : activeChat.messages.unread[index + 1] && message.isOwn && !activeChat.messages.unread[index + 1].isOwn || !activeChat.messages.unread[index + 1], 'contacts-chat__message--geo' : message.isGeo}">
                            <template v-if="message.isGeo">
                                <div class="contacts-chat__message-time">
                                    {{ message.time }}
                                </div>
                                <BaseButton white class="contacts-chat__message-geo" @click="clickGeo(message.text)">
                                    <IconPin />
                                    {{ $t('contacts.geo') }}
                                </BaseButton>
                            </template>
                            <template v-else>
                                <div class="contacts-chat__message-text">
                                    {{ message.text }}
                                </div>
                                <div class="contacts-chat__message-time">
                                    {{ message.time }}
                                </div>
                            </template>
                        </div>
                    </template>
                </div>
                <div class="contacts-bottom">
                    <BaseInput
                        mini
                        focused
                        autofocus
                        type="text"
                        :placeholder="$t('contacts.chat_message_placeholder')"
                        class="contacts-bottom__input"
                        v-model="chatMessage"
                        @enter-pressed="sendMessage(chatMessage)"
                    />
                    <button @click="sendMessage(chatMessage)">
                        <ChatSvg :icon="10"/>
                    </button>
                </div>
            </div>
        </div>
        <transition name="fade">
            <BasePopupChat
                v-if="isChatOpen"
                :closeChat="closeChat"
                :placeholder="$t('contacts.chat_placeholder')"
                @send-answer="sendButtonMessage"
                style="z-index: 15"
            >
                <template #icon>
                    <div
                        class="contacts-calls__item contacts-calls__item--chat"
                        :class="{
                             'contacts-calls__item--police' : activeButton.type === 1,
                             'contacts-calls__item--med' : activeButton.type === 2,
                             'contacts-calls__item--news' : activeButton.type === 3
                         }">
                        {{ activeButton.text }}
                    </div>
                </template>
                <template #info v-if="activeButton.type === 3">
                    <div class="contacts-calls__info">
                        <div class="contacts-calls__info-item">
                            <IconInfoTransparent />
                            <div class="contacts-calls__info-text">
                                {{ $t('contacts.info') }}
                            </div>
                        </div>
                        <div class="contacts-calls__info-item">
                            <div class="contacts-calls__info-text">
                               {{ $t('contacts.price') }}
                            </div>
                            <div class="contacts-calls__info-sum">
                                <span>$</span>
                                {{ $formatNum(newsAddPrice) }}
                            </div>
                        </div>
                    </div>
                </template>
            </BasePopupChat>
        </transition>
    </div>
</template>

<script>
import AppHeader from '@/components/AppHeader/AppHeader.vue';
import BasePopupChat from '@/components/ui/BasePopupChat.vue';
import IconInfoTransparent from '@/components/icons/IconInfoTransparent.vue';
import BaseInput from '@/components/ui/BaseInput.vue';
import IconContactsSim from '@/components/popups/content/contacts/icons/IconContactsSim.vue';
import ChatSvg from '@/components/hud/components/chat/icons/ChatSvg.vue';
import IconContactsUnread from '@/components/popups/content/contacts/icons/IconContactsUnread.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import IconPin from '@/components/icons/IconPin.vue';
import IconTrash from '@/components/icons/IconTrash.vue';
import IconNotify from '@/components/icons/IconNotify.vue';

export default {
    name: 'MyContacts',
    components: {
        IconNotify,
        IconTrash,
        IconPin,
        BaseButton,
        IconContactsUnread,
        ChatSvg,
        IconContactsSim,
        BaseInput,
        IconInfoTransparent,
        BasePopupChat,
        AppHeader
    },
    data () {
        return {
            notifications: 0,
            isChatOpen: false,
            newsAddPrice: 900,
            blockedSims: [],
            buttons: [
                {
                    type: 1,
                    text: this.$t('contacts.call_police')
                },
                {
                    type: 2,
                    text: this.$t('contacts.call_med')
                },
                {
                    type: 3,
                    text: this.$t('contacts.call_news')
                }
            ],
            activeButton: null,
            submenu: {
                isActive: false,
                items: [
                    {
                        title: this.$t('contacts.submenu.delete_dialog'),
                        icon: 'trash',
                        callback: this.deleteDialog
                    },
                    {
                        title: this.$t('contacts.submenu.disable_notification'),
                        icon: 'notify',
                        isActive: false,
                        callback: this.toggleMenuNotifications
                    },
                    {
                        title: this.$t('contacts.submenu.send_geo'),
                        icon: 'pin',
                        callback: this.sendGeo
                    }
                ]
            },
            search: '',
            chatMessage: '',
            activeChat: undefined,
            /*
            {
                playerName: 'Kevin Mckalister',
                simCard: 5555,
                messages: {
                    read: [
                        {
                            text: 'Не следует, однако забывать, что рамки и место обуче.',
                            time: '02:11'
                        },
                        {
                            text: 'Таким образом дальнейшее развитие различных форм деятельности требуют от нас анализа соответствующий условий активизации.',
                            time: '02:15'
                        },
                        {
                            text: 'Ты просто зануда отдай бабки за биз',
                            time: '02:11',
                            isOwn: true
                        },
                        {
                            text: 'Ты просто зануда отдай бабки за биз',
                            time: '02:11',
                            isOwn: true
                        },
                        {
                            text: 'Ты просто зануда отдай бабки за биз',
                            time: '02:11',
                            isOwn: true
                        },
                        {
                            text: 'Амено.',
                            time: '02:15'
                        },
                        {
                            text: 'Ты просто зануда отдай бабки за биз',
                            time: '02:11',
                            isOwn: true
                        }
                    ],
                    unread: [
                        {
                            text: 'Жук навозный на связи, приём!',
                            time: '02:22'
                        },
                        {
                            text: 'Доброе утро, ты куда пропал жучара лысый',
                            time: '03:29'
                        }
                    ]
                }
            },
            */
            contacts: [
                /*
                {
                    playerName: 'Kevin Mckalister',
                    simCard: 5555,
                    messages: {
                        read: [
                            {
                                text: 'Не следует, однако забывать, что рамки и место обуче.',
                                time: '02:11'
                            },
                            {
                                text: 'Таким образом дальнейшее развитие различных форм деятельности требуют от нас анализа соответствующий условий активизации.',
                                time: '02:15'
                            },
                            {
                                text: 'Ты просто зануда отдай бабки за биз',
                                time: '02:11',
                                isOwn: true
                            },
                            {
                                text: 'Ты просто зануда отдай бабки за биз',
                                time: '02:11',
                                isOwn: true
                            },
                            {
                                text: 'Ты просто зануда отдай бабки за биз',
                                time: '02:11',
                                isOwn: true
                            },
                            {
                                text: 'Амено.',
                                time: '02:15'
                            },
                            {
                                text: 'Ты просто зануда отдай бабки за биз',
                                time: '02:11',
                                isOwn: true
                            }
                        ],
                        unread: [
                            {
                                text: 'Жук навозный на связи, приём!',
                                time: '02:22'
                            },
                            {
                                text: 'Доброе утро, ты куда пропал жучара лысый',
                                time: '03:29'
                            }
                        ]
                    }
                },
                {
                    playerName: 'Dan Ser',
                    simCard: 1255,
                    messages: {
                        read: [
                            {
                                text: 'Не следует, однако забывать, что рамки и место обуче.',
                                time: '02:11'
                            },
                            {
                                text: 'Таким образом дальнейшее развитие различных форм деятельности требуют от нас анализа соответствующий условий активизации.',
                                time: '02:15'
                            },
                            {
                                text: 'Ты выполнил ивент?',
                                time: '02:22'
                            }
                        ],
                        unread: [
                            {
                                text: 'Жук навозный на связи, приём!',
                                time: '02:22'
                            },
                            {
                                text: 'Доброе утро, ты куда пропал жучара лысый',
                                time: '03:29'
                            },
                            {
                                text: 'Жук навозный на связи, приём!',
                                time: '02:22'
                            },
                            {
                                text: 'Жук навозный на связи, приём!',
                                time: '02:22'
                            }
                        ]
                    }
                },
                {
                    playerName: 'Johny Buy',
                    simCard: 143355,
                    messages: {
                        read: [
                            {
                                text: 'Не следует, однако забывать, что рамки и место обуче.',
                                time: '02:11'
                            },
                            {
                                text: 'Таким образом дальнейшее развитие различных форм деятельности требуют от нас анализа соответствующий условий активизации.',
                                time: '02:15'
                            },
                            {
                                text: 'Казел!',
                                time: '02:22'
                            },
                            {
                                text: 'Ты просто зануда отдай бабки за биз',
                                time: '02:11',
                                isOwn: true
                            }
                        ],
                        unread: []
                    }
                },
                {
                    playerName: 'Salmon Krew',
                    simCard: 143355,
                    messages: {
                        read: [
                            {
                                text: 'Не следует, однако забывать, что рамки и место обуче.',
                                time: '02:11'
                            },
                            {
                                text: 'Таким образом дальнейшее развитие различных форм деятельности требуют от нас анализа соответствующий условий активизации.',
                                time: '02:15'
                            },
                            {
                                text: 'Жук навозный на связи, приём',
                                time: '02:22'
                            }
                        ],
                        unread: []
                    }
                },
                {
                    playerName: 'Jason Mathew',
                    simCard: 1435,
                    messages: {
                        read: [
                            {
                                text: 'Не следует, однако забывать, что рамки и место обуче.',
                                time: '02:11'
                            },
                            {
                                text: 'Таким образом дальнейшее развитие различных форм деятельности требуют от нас анализа соответствующий условий активизации.',
                                time: '02:15'
                            },
                            {
                                text: 'Казел!',
                                time: '02:22'
                            }
                        ],
                        unread: []
                    }
                }
                 */
            ]
        };
    },
    methods: {
        /*
        window.modifyVueComponent({
            App: {
                showPopup: true,
                popupContent: 'MyContacts',
                contentSubFolder: 'contacts'
            },
            MyContacts: {
                initialize: [
                    120,
                    [
                        {
                    playerName: 'Kevin Mckalister',
                    simCard: 5555,
                    messages: {
                        read: [
                            {
                                text: 'Не следует, однако забывать, что рамки и место обуче.',
                                time: '02:11'
                            },
                        ],
                        unread: [
                        ]
                    }
                },
                    ],
                    1,
                    [55556],
                ]
            }
        });
        */
        initialize (adPrice, contacts, notifs, blockedSims) {
            this.newsAddPrice = adPrice;
            this.contacts = contacts;
            this.notifications = notifs;
            this.blockedSims = blockedSims;
        },
        /*
        window.callVueMethod('MyContacts','loadDialog', 5555,'Kevin', [
            {
                text: 'нормас',
                time: '02:11'
            },
            {
                text: 'грузим сообщения',
                time: '02:12'
            },
        ]);
        */
        loadDialog (simcard, name, dialog) {
            simcard = parseInt(simcard);
            const contactObject = this.contacts.find(item => item.simCard === simcard);
            if (contactObject) {
                contactObject.messages.read = dialog.reverse();
                contactObject.loadedChat = true;
                this.activeChat = contactObject;
                this.scrollToEnd();
            } else {
                if (!name)name = simcard.toString();
                const json = {
                    playerName: name,
                    simCard: simcard,
                    loadedChat: true,
                    messages: {
                        read: dialog.reverse(),
                        unread: []
                    }
                };
                this.contacts.push(json);
                const contactObject = this.contacts.find(item => item.simCard === simcard);
                if (contactObject) {
                    this.activeChat = contactObject;
                    this.scrollToEnd();
                }
            }
        },
        updateBlackList (blockList) {
            this.blockedSims = blockList;
        },
        sendButtonMessage (text) {
            if (!text || !text.length) return;
            if (this.activeButton.type === 1) {
                this.sendclient('PoliceOrder', JSON.stringify({ a: text }));
            }
            if (this.activeButton.type === 2) {
                this.sendclient('AmbulanceOrder', JSON.stringify({ a: text }));
            }
            if (this.activeButton.type === 3) {
                this.sendclient('SendAD', JSON.stringify({ a: text }));
            }
        },
        GetCorrectName (name, simCard) {
            const splittedName = name.split(' ');
            if (splittedName.length > 1) return name.split(' ')[0][0] + name.split(' ')[1][0];
            else if (name.length > 0) return name[0] + name[1];
            else {
                const simText = simCard.toString();
                return `${simText[0]}${simText[1]}`;
            }
        },
        openChat (button) {
            this.isChatOpen = true;
            this.activeButton = button;
        },
        closeChat () {
            this.isChatOpen = false;
        },
        searchPlayer () {
            if (this.search === '') {
                return;
            }
            this.sendclient('searchContact', JSON.stringify({ a: this.search }));
            // console.log('Ищем игрока под ником: ', this.search);
            // чистим инпут
            this.search = '';
        },
        stringToRGB (str) {
            let hash = 0;
            for (let i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }

            let r = (hash & 0xFF0000) >> 16;
            let g = (hash & 0x00FF00) >> 8;
            let b = hash & 0x0000FF;

            // Яркость можно подкрутить
            const brightnessFactor = 1.1; // коэфицент, надо больше единицы
            r = Math.min(255, r * brightnessFactor);
            g = Math.min(255, g * brightnessFactor);
            b = Math.min(255, b * brightnessFactor);

            // Чтоб теплее был
            r = Math.min(255, r * 1.1); // увеличиваем красный на 10%
            b = b * 0.9; // уменьшаем синий на 10%

            return `rgb(${Math.round(r)}, ${Math.round(g)}, ${Math.round(b)})`;
        },
        formatSimNumber (number) {
            const str = String(number);
            if (str.length === 4) {
                return str.slice(0, 2) + '-' + str.slice(2);
            }
            if (str.length === 5) {
                return str.slice(0, 3) + '-' + str.slice(3);
            }
            if (str.length === 6) {
                return str.slice(0, 2) + '-' + str.slice(2, 4) + '-' + str.slice(4);
            }
            if (str.length === 7) {
                return str.slice(0, 3) + '-' + str.slice(3, 5) + '-' + str.slice(5);
            }
            if (str.length === 8) {
                return str.slice(0, 4) + '-' + str.slice(4);
            }
        },
        selectChat (item) {
            if (!item.loadedChat) return this.sendclient('loadContactDialog', JSON.stringify({ value: item.simCard }));
            this.activeChat = item;
            this.scrollToEnd();
        },
        GetLastTime (messages) {
            if (messages.unread.length > 0) return messages.unread[messages.unread.length - 1].time;
            else if (messages.read.length > 0) return messages.read[messages.read.length - 1].time;
            else return '';
        },
        GetLastMessage (contact) {
            if (contact.messages.unread.length > 0) {
                if (contact.messages.unread[contact.messages.unread.length - 1].isGeo) return 'GPS';
                else return contact.messages.unread[contact.messages.unread.length - 1].text;
            } else if (contact.messages.read.length > 0) {
                if (contact.messages.read[contact.messages.read.length - 1].isGeo) return 'GPS';
                else return contact.messages.read[contact.messages.read.length - 1].text;
            } else return '';
        },
        sendMessage (text) {
            // защищаемся от пустых сообщений
            if (text.length > 0) {
                /*
                this.activeChat.messages.read.push(...this.activeChat.messages.unread);
                this.activeChat.messages.unread = [];
                const time = this.getTime();
                this.activeChat.messages.read.push({ text, time, isOwn: true });
                */
                this.sendclient('SendSMS', JSON.stringify({ a: this.activeChat.simCard, b: text }));
                this.chatMessage = '';
                this.scrollToEnd();
            }
        },
        /*
        window.callVueMethod('MyContacts','addMessageToDialog', 5553, {
            text: 'нормас',
            time: '02:11',
            isOwn:1
        },);
        */
        addMessageToDialog (simcard, message) {
            simcard = parseInt(simcard);
            const contactObject = this.contacts.find(item => item.simCard === simcard);
            if (contactObject) {
                contactObject.messages.read.push(message);
            }
        },
        getTime () {
            const date = new Date();
            const hours = date.getHours();
            const minutes = date.getMinutes() > 10 ? date.getMinutes() : '0' + String(date.getMinutes());
            return `${hours}:${minutes}`;
        },
        scrollToEnd () {
            this.$nextTick(() => { if (this.$refs.chat && this.$refs.chat.lastElementChild) this.$refs.chat.scrollTop = this.$refs.chat.lastElementChild.offsetTop; });
        },
        clickGeo (position) {
            // console.log('geo pos:', position);
            this.sendclient('mobileGPS', JSON.stringify({ a: position }));
        },
        toggleSubMenu (wrapper) {
            if (wrapper) {
                this.submenu.isActive = false;
            } else {
                this.submenu.isActive = !this.submenu.isActive;
            }
            if (this.submenu.isActive === true) {
                const state = this.blockedSims.indexOf(parseInt(this.activeChat.simCard)) !== -1;
                this.submenu.items[1].isActive = state;
                if (!state) this.submenu.items[1].title = this.$t('contacts.submenu.disable_notification');
                else this.submenu.items[1].title = this.$t('contacts.submenu.enable_notification');
            }
        },
        toggleMenuNotifications (item) {
            if (item.isActive) {
                // item.isActive = false;
                // item.title = this.$t('contacts.submenu.disable_notification');
                // console.log('врубили нотификейшены, обьект:', this.activeChat);
                console.log('разблокаем:', this.activeChat);
                this.sendclient('mobileBlacklist', JSON.stringify({ a: this.activeChat.simCard, b: 0 }));
            } else {
                // item.isActive = true;
                // item.title = this.$t('contacts.submenu.enable_notification');
                console.log('блокируем:', this.activeChat);
                this.sendclient('mobileBlacklist', JSON.stringify({ a: this.activeChat.simCard, b: 1 }));
            }
        },
        deleteDialog () {
            this.sendclient('deleteDialog', JSON.stringify({ a: this.activeChat.simCard }));
            this.contacts = this.contacts.filter(item => item.simCard !== this.activeChat.simCard);
            this.activeChat = null;
            // console.log('удаляем диалог обьект:', this.activeChat);
        },
        sendGeo () {
            this.sendclient('sendGeo', JSON.stringify({ a: this.activeChat.simCard }));
        },
        toggleNotifications () {
            if (this.notifications === 0) {
                this.sendclient('MobileNotifs', JSON.stringify({ a: 1 }));
                // this.notifications = 1;
                // console.log('включили уведомленгия');
            } else {
                this.sendclient('MobileNotifs', JSON.stringify({ a: 0 }));
                // this.notifications = 0;
                // console.log('выключили уведомленгия');
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.contacts {
    height: 100%;
    &-content {
        height: calc(100% - 5vw);
        background: radial-gradient(122.93% 111.23% at 91.22% 14.31%, rgba(65, 78, 97, 0.96) 0%, rgba(21, 28, 37, 0.96) 100%);
        padding: 90px 128px 0;
        display: grid;
        grid-template-columns: 686px 1fr;
        grid-gap: 0 125px;
    }
    &-sidebar {
        height: 100%;
        display: grid;
        grid-auto-flow: row;
        grid-auto-rows: 154px 118px 1fr;
        grid-gap: 24px 0;
    }
    &-calls {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 0 12px;
        &__item {
            display: flex;
            align-items: center;
            border-radius: 12px;
            background-position: center;
            background-size: contain;
            color: var(--white);
            font-family: var(--font-stretch);
            font-size: 32px;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: 0.64px;
            text-transform: uppercase;
            padding-left: 28px;
            padding-right: 22px;
            height: 100%;
            &--police {
                background-image: url("@/assets/images/contacts/police-btn.svg");
            }
            &--news {
                background-image: url("@/assets/images/contacts/news-btn.svg");
            }
            &--med {
                background-image: url("@/assets/images/contacts/med-btn.svg");
            }
            &--chat {
                border-radius: 12px 0 0 12px;
            }
        }
        &__info {
            margin-top: 37px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            &-item {
                display: flex;
                align-items: center;
                svg {
                    opacity: .4;
                    width: 90px;
                    height: 90px;
                    margin-right: 12px;
                }
            }
            &-text {
                color: var(--white);
                opacity: .6;
                font-size: 32px;
                font-weight: 500;
                line-height: 140%;
                max-width: 1378px;
            }
            &-sum {
                margin-left: 24px;
                border-radius: 6px;
                background: var(--dark);
                padding: 7px 12px;
                color: var(--white);
                font-size: 48px;
                font-weight: 700;
                line-height: 100%;
                letter-spacing: -0.96px;
                span {
                    color: var(--gold);
                    margin-right: 4px;
                }
            }
        }
    }
    &-search {
        position: relative;
        &__button {
            position: absolute;
            right: 32px;
            width: 70px;
            height: 70px;
            background-image: url("@/assets/images/pda/search-icon.svg");
            background-position: center;
            background-size: cover;
            top: 24px;
            z-index: 4;
        }
        &::v-deep input {
            font-weight: 300;
            font-size: 40px;
            height: 118px;
            text-align: left;
            padding-left: 32px;
            padding-right: 32px;
            min-width: 100%;
            //border-radius: 0;
        }
    }
    &-list {
        //display: grid;
        //grid-auto-flow: row;
        //grid-auto-rows: 160px;
        overflow-y: auto;
        min-height: 100%;
        height: 0;
        &::-webkit-scrollbar {
            display: none;
        }
        &__item {
            border-bottom: 3px solid rgba(255, 255, 255, 0.16);
            display: flex;
            align-items: center;
            padding: 40px 36px;
            //overflow: hidden;
            position: relative;
            &--active {
                background: #58627A;
            }
            &-circle {
                position: relative;
                color: var(--white);
                font-family: var(--font-stretch);
                font-size: 36px;
                font-weight: 900;
                line-height: 100%;
                min-width: 80px;
                width: 80px;
                height: 80px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 24px;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.06) 35.42%, rgba(255, 255, 255, 0.50) 100%);
                box-shadow: 0 4px 12px 0 rgba(255, 255, 255, 0.35) inset;
            }
            &-unread {
                position: absolute;
                width: 52px;
                height: 52px;
                background-color: #fff;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: var(--dark);
                font-size: 32px;
                font-weight: 700;
                line-height: 100%;
                left: -20px;
                top: -20px;
                letter-spacing: -1.28px;
            }
            &-info {
                width: 100%;
                overflow: hidden;
            }
            &-heading {
                display: flex;
                justify-content: space-between;
                align-items: start;
                margin-bottom: 8px;
            }
            &-title {
                color: var(--white);
                font-size: 36px;
                font-weight: 500;
                line-height: 100%;
            }
            &-time {
                color: var(--white);
                font-size: 28px;
                font-weight: 500;
                line-height: 100%;
                opacity: .4;
            }
            &-message {
                color: var(--white);
                font-size: 28px;
                font-weight: 400;
                line-height: 100%;
                opacity: .6;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
    &-main {
        display: grid;
        grid-template-rows: 160px 619px 136px;
        &__sim {
            margin-left: auto;
            color: var(--white);
            text-align: right;
            font-family: var(--font-stretch);
            font-size: 40px;
            font-weight: 900;
            line-height: 100%;
            letter-spacing: 1.6px;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            svg {
                margin-left: 12px;
                width: 40px;
                height: 40px;
            }
        }
        &__info {
            width: auto;
        }
        &__submenu {
            border-radius: 24px 0 24px 24px;
            background: radial-gradient(141.1% 140.17% at 20.11% 15.71%, #EFF6FA 0%, rgba(222, 232, 239, 0.68) 100%);
            position: absolute;
            top: calc(100% - 28px);
            right: 36px;
            z-index: 5;
            width: 526px;
            &-item {
                border-bottom: 4px solid rgba(20, 26, 30, 0.12);
                padding: 32px;
                text-transform: uppercase;
                color: var(--dark);
                font-size: 24px;
                font-weight: 700;
                line-height: 120%;
                letter-spacing: 0.96px;
                display: flex;
                align-items: center;
                &:first-child {
                    color: #CF4941;
                    &::v-deep {
                        svg {
                            fill: #CF4941;
                            path {
                                fill: #CF4941;
                            }
                        }
                    }
                }
                &::v-deep {
                    svg {
                        margin-right: 24px;
                        fill: var(--dark);
                        width: 44px;
                        height: 44px;
                        path {
                            fill: var(--dark);
                        }
                    }
                }
                &:last-child {
                    border-bottom: none;
                }
            }
        }
        &__menu {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 24px;
            height: 80px;
            width: 128px;
            border-radius: 8px;
            background: rgba(241, 241, 241, 0.10);
            transition: .3s ease-out;
            &:active {
                opacity: .8;
            }
            &-dot {
                width: 15px;
                height: 15px;
                border-radius: 50%;
                background-color: var(--white);
                &:nth-child(2) {
                    margin: 0 11px;
                }
            }
        }
    }
    &-bottom {
        display: flex;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        &__input {
            flex-grow: 1;
        }
        &::v-deep input {
            font-weight: 300;
            font-size: 44px;
            height: 136px;
            text-align: left;
            padding-left: 64px;
            padding-right: 64px;
            min-width: 100%;
            //border-radius: 0;
            &::placeholder {
                opacity: .4;
            }
        }
        button {
            display: flex;
            width: 238px;
            height: 136px;
            padding: 32px 24px;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            background: linear-gradient(225deg, rgba(241, 241, 241, 0.20) 0%, rgba(241, 241, 241, 0.00) 100%);
            &::v-deep svg {
                width: 84px;
                height: 84px;
            }
        }
    }
    &-chat {
        padding: 32px 0;
        overflow-y: scroll;
        overflow-x: hidden;
        min-height: 100%;
        height: 0;
        &::-webkit-scrollbar {
            display: none;
        }
        &__message {
            padding: 14px 24px;
            border-radius: 24px;
            background-color: var(--dark);
            max-width: 1048px;
            margin-bottom: 8px;
            margin-left: 13px;
            position: relative;
            width: fit-content;
            &::after {
                content: "";
                position: absolute;
                width: 26px;
                height: 24px;
                bottom: 0;
                left: -13px;
                background-image: url("@/assets/images/contacts/foreign-message.svg");
                background-position: center;
                background-size: contain;
            }
            &--geo {
                padding: 24px;
                display: flex;
                align-items: center;
                &.contacts-chat__message--own {
                    flex-direction: row-reverse;
                    .contacts-chat__message-geo {
                        margin-right: 24px;
                        margin-left: 0;
                    }
                }
            }
            &--own {
                background: #58627A;
                margin-left: auto;
                margin-right: 13px;
                &::after {
                    content: "";
                    left: auto;
                    right: -13px;
                    background-image: url("@/assets/images/contacts/own-message.svg");
                }
            }
            &--last {
                margin-bottom: 24px;
            }
            &--first {
                margin-top: 24px;
            }
            &-text {
                color: var(--white);
                font-size: 32px;
                font-weight: 400;
                line-height: 130%;
            }
            &-time {
                text-align: right;
                color: var(--white);
                font-size: 22px;
                font-weight: 500;
                line-height: 130%;
                opacity: .4;
            }
            &:last-child {
                margin-bottom: 0;
            }
            &-geo {
                padding: 0 44px;
                height: 112px;
                margin-left: 24px;
                font-size: 36px;
                &::v-deep {
                    svg {
                        width: 40px;
                        height: 40px;
                        margin-right: 22px;
                    }
                }
            }
        }
        &__unread {
            margin-bottom: 24px;
            text-align: center;
            color: var(--white);
            font-family: var(--font-stretch);
            font-size: 24px;
            font-weight: 700;
            line-height: 100%;
            opacity: .2;
            letter-spacing: 2.4px;
            text-transform: uppercase;
            display: grid;
            align-items: center;
            grid-template-columns: 1fr 171px 1fr;
            grid-gap: 0 44px;
            svg {
                width: 100%;
                height: 12px;
            }
        }
    }
}
</style>
