<template>
    <div class="banner">
        <div v-if="type === 'battle-pass'" class="banner-content banner-bp">
            <div class="banner-bp__title">
                <div class="banner-bp__title-word">{{ $t('menu.banners.battle_pass.battle') }}</div>
                <div class="banner-bp__title-word">{{ $t('menu.banners.battle_pass.pass') }}</div>
            </div>
            <div class="banner-bp__season">
                {{ season }} {{ $t('menu.banners.battle_pass.season') }}
            </div>
            <IconNew v-if="isNew" />
        </div>
        <div v-if="type === 'season-event'" class="banner-content banner-season">
            <div class="banner-season__word">{{ $t('menu.banners.season_tasks.seasonal') }}</div>
            <div class="banner-season__word">{{ $t('menu.banners.season_tasks.tasks') }}</div>
            <IconNew v-if="isNew" />
        </div>
    </div>
</template>

<script>
import IconNew from '@/components/icons/IconNew.vue';

export default {
    name: 'MainMenuBanner',
    components: {
        IconNew
    },
    props: {
        type: String,
        season: Number,
        isNew: Number
    }
};
</script>

<style lang="scss" scoped>
.banner {
    border-radius: 12px;
    &-content {
        height: 100%;
        border-radius: 12px;
        padding: 50px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;
    }
    &-bp {
        background: url("@/assets/images/menu/bg-banner-person.png") no-repeat right 35px bottom/539px, url("@/assets/images/menu/bp-banner-fill.svg") no-repeat center/cover, #FF473C;
        &__title {
            display: flex;
            align-items: center;
            color: #fff;
            font-family: var(--font-stretch);
            font-size: 82px;
            font-weight: 900;
            line-height: 100%;
            text-transform: uppercase;
            margin-bottom: 12px;
            &-word {
                &:first-child {
                    padding: 6px 8px;
                    margin-right: 8px;
                    background: none;
                }
                padding: 4px 8px 8px;
                background: var(--red);
            }
        }
        &__season {
            background-color: #000;
            color: var(--white);
            font-size: 32px;
            font-weight: 500;
            line-height: 100%;
            letter-spacing: 2.56px;
            text-transform: uppercase;
            width: fit-content;
            padding: 10px;
        }
    }
    &-season {
        background: url("@/assets/images/menu/bg-banner-season.png") no-repeat center/cover;
        &__word {
            font-size: 82px;
            font-style: normal;
            font-weight: 900;
            line-height: 100%;
            font-family: var(--font-stretch);
            text-transform: uppercase;
            padding: 4px 8px 8px;
            width: fit-content;
            color: var(--dark);
            background-color: #2BF2FF;
            &:first-child {
                color: #fff;
                background-color: var(--dark);
            }
        }
    }
    &::v-deep svg {
        position: absolute;
        left: -10px;
        top: -10px;
        width: 94px;
        height: 48px;
    }
}
</style>
