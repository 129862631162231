<template>
    <div class="dialog">
        <div class="dialog__wrapper dialog__info-wrapper"
             :class="{ 'dialog__info-wrapper--center': infoItems.length <= 2 }">
            <div class="dialog__info">
                <div v-for="(infoItem, index) in infoItems" :key="index" class="dialog__info-item">
                    <span class="dialog__info-title">{{ infoItem.label }}</span>
                    <p :class="{dollar: infoItem.currency}">
                        <span v-if="infoItem.currency" class="dollar__symbol dollar__symbol--yellow">$</span>
                        {{ infoItem.value }}
                    </p>
                </div>
            </div>
            <div class="dialog__info-btn">
                <BaseButton yellow @click="handleButtonOne" v-if = "button1.length > 0">{{button1}}</BaseButton>
                <BaseButton border @click="handleButtonTwo" v-if = "button2.length > 0">{{button2}}</BaseButton>
                <BaseButton border @click="handleButtonThree" v-if = "button3.length > 0">{{button3}}</BaseButton>
            </div>
        </div>
    </div>
</template>
<script>

import BaseButton from '@/components/ui/BaseButton.vue';

export default {
    name: 'DialogInfo',
    components: { BaseButton },
    data () {
        return {
            button1: '',
            button2: '',
            button3: '',
            infoItems: []
        };
    },
    methods: {
        Initialize (array, button1 = '', button2 = '', button3 = '') {
            this.infoItems = array;
            this.button1 = button1;
            this.button2 = button2;
            this.button3 = button3;
            console.log('Initialize', array);
        },
        handleButtonOne () {
            this.sendclient('DialogResponse', JSON.stringify({ value: 1 }));
        },
        handleButtonTwo () {
            this.sendclient('DialogResponse', JSON.stringify({ value: 2 }));
        },
        handleButtonThree () {
            this.sendclient('DialogResponse', JSON.stringify({ value: 3 }));
        }
    }
};
</script>
