<template>
    <div class="chat" v-if="isChatVisible" @click="toggleChat">
            <div class="chat__wrapper">
                <div
                    class="chat__message"
                    :class="`chat__message--type-${message.type}`"
                    v-for="(message, index) in messages"
                    :key="index"
                    @click="clickMessage(message)"
                >
                    <template v-if="[1, 2].includes(message.isAdmin)">
                        <div class="chat__message-admin"
                             :class="`chat__message-admin--type-${message.isAdmin}`"
                        >
                            <!--
                            <span class="uppercase">Админ {{ message.name }}</span>
                            <span v-if="message.action">{{ message.action }}</span>
                            -->
                            <p class="uppercase" v-html="message.message"></p>
                        </div>
                    </template>

                    <template v-else-if="message.isAdd">
                        <div class="chat__message-add">
                            <div class="chat__message-add-icon">
                                <IconCall fillColor="#416414" />
                            </div>
                            <div class="chat__message-add-content">
                                <div class="chat__message-add-text">
                                    {{ message.name }}
                                </div>
                                <div class="chat__message-add-text">
                                    {{ message.message }}
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-else-if="message.type === 10">
                        <ChatSvg :icon="9" class="chat__message-svg"/>
                        <span v-if="message.type === 10" class="admin">{{ $t('chat.popup_items.admin') }}</span>
                        <span v-if="message.name && message.name.length > 0">{{ message.name }}</span>
                        <span v-if="message.action">{{ message.action }}</span>
                        <span class="admin-action"> {{ message.player }} </span>
                        <p class="admin-action" v-html="message.message"></p>
                    </template>

                    <template v-else-if="message.type === 11">
                        <span v-if="message.type === 11" class="admin">{{ $t('chat.name_chat') }}</span>
                        <span v-if="message.name && message.name.length > 0">{{ message.name }}<i>:</i></span>
                        <p v-html="message.message"></p>
                    </template>

                    <template v-else-if="message.type === 12">
                        <span>{{ $t('chat.announcement') }}</span>
                        <ChatSvg :icon="9" class="chat__message-svg"/>
                        <span v-if="message.name && message.name.length > 0">{{ message.name }}<i>:</i></span>
                        <p v-html="message.message"></p>
                    </template>

                    <template v-else-if="message.type === 13">
                        <ChatSvg :icon="13" class="chat__message-svg"/>
                        <span>{{ $t('chat.report') }} #{{message.report}}</span>
                        <ChatSvg :icon="9" class="chat__message-svg"/>
                        <span v-if="message.name && message.name.length > 0">{{ message.name }}<i>:</i></span>
                        <p v-html="message.message"></p>
                    </template>

                    <template v-else>
                        <ChatSvg v-if="[2, 4, 3, 9].includes(message.type)" :icon="message.type" class="chat__message-svg"/>
                        <span v-if="message.type === 9" class="admin">{{ $t('chat.popup_items.admin') }}</span>
                        <span v-if="message.name && message.name.length > 0">{{ message.name }}<i v-if = "!message.withoutColon">:</i></span>
                        <span v-if="message.action" class="chat__message-action">{{ message.action }}</span>
                        <p v-html="message.message"></p>
                    </template>
                </div>
            </div>
            <div class="chat__open" @click.stop="toggleChat">
                <IconChat />
                {{ $t('chat.open_chat') }}
            </div>

            <transition name="fade">
                <div class="chat__popup" v-if="isChatOpen" @click.stop="closeChat">
                    <div class="chat__popup-wrapper" @click.stop>
                        <div class="chat__popup-icon" :class="`chat__popup-type-${typeChat}`">
                            <ChatSvg :icon="typeChat"/>
                        </div>
                        <BaseInput
                            ref="inputField"
                            mini
                            focused
                            autofocus
                            type="text"
                            :placeholder="$t('chat.write_in_chat')"
                            class="chat__popup-input"
                            v-model="inputValue"
                            @enter-pressed="sendMessage"
                        />
                        <button @click.stop="sendMessage" :class="`chat__popup-type-${typeChat}`">
                            <ChatSvg :icon="10"/>
                        </button>
                    </div>
                    <div class="chat__popup-types" @click.stop>
                        <div
                            class="chat__popup-item"
                            v-for="item in chatPopupItems"
                            :key="item.value"
                            :class="{ 'selected': item.value === typeChat, 'admin': item.value === 9 }"
                            @click="selectChatType(item.value); focusInputField()"
                        >
                            <ChatSvg :icon="item.value"/>
                            {{ item.label }}
                        </div>
                        <div class="chat__popup-item chat__popup-item--skip" @click="skipPrevious">
                            <svg width="45" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22.5 15.5833L39 42.1667L6 42.1667L22.5 15.5833Z" fill="#F1F1F1"/>
                                <path d="M6 9.16667L6 13.75L39 13.75L39 9.16667L6 9.16667Z" fill="#F1F1F1"/>
                                <path d="M6 2.75L6 7.33333L39 7.33333L39 2.75L6 2.75Z" fill="#F1F1F1"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
</template>
<script>
import IconChat from '@/components/icons/IconChat.vue';
import BaseInput from '@/components/ui/BaseInput.vue';
import ChatSvg from '@/components/hud/components/chat/icons/ChatSvg.vue';
import IconCall from '@/components/icons/IconCall.vue';

export default {
    name: 'VChat',
    components: { IconCall, ChatSvg, BaseInput, IconChat },
    data () {
        return {
            isChatVisible: true,
            isChatOpen: false,
            inputValue: '',
            typeChat: 1,
            inputHistory: [],
            historyIndex: -1,
            // 1 -- сообщение, 2 -- организация, 3 -- работа, 4 -- семья, 5 -- действие, 6 -- попытка, 7 -- 3 лицо
            // 9 -- админ, 10 -- админ забанил, 11 -- админ-чат, 12 -- объявление админ, 13 -- репорт админ
            messages: [
                {
                    name: '',
                    type: 1,
                    message: ''
                },
                {
                    name: '',
                    type: 1,
                    message: ''
                },
                {
                    name: '',
                    type: 1,
                    message: ''
                },
                {
                    name: '',
                    type: 1,
                    message: ''
                },
                {
                    name: '',
                    type: 1,
                    message: ''
                },
                {
                    name: '',
                    type: 1,
                    message: ''
                }
                // {
                //     name: 'Joy Li',
                //     type: 6,
                //     message: 'налил кофе'
                // },
                // {
                //     name: 'PapaCarlo',
                //     type: 5,
                //     action: 'удачно',
                //     message: 'вздохнул'
                // },
                // {
                //     name: 'Kevin Mackalister',
                //     type: 4,
                //     message: 'Моя семья – лучшая на свете'
                // },
                // {
                //     name: 'No Name',
                //     type: 3,
                //     message: 'Надоело работать, спать хочу'
                // },
                // {
                //     name: 'Kevin Mackalister',
                //     type: 2,
                //     message: 'Что то вякнул в организации '
                // },
                // {
                //     name: 'Kevin Mackalister',
                //     type: 1,
                //     message: 'Сказано в чате'
                // },
                // {
                //     name: 'Kevin Mackalister',
                //     type: 9,
                //     message: 'johny molecule'
                // },
                // {
                //     name: 'Kevin Mackalister',
                //     type: 10,
                //     action: 'забанил',
                //     player: 'johny molecule',
                //     message: 'за нарушение порядка — ДМ'
                // },
                // {
                //     name: 'Kevin Mackalister',
                //     type: 11,
                //     message: 'johny molecule'
                // },
                // {
                //     name: 'Kevin Mackalister',
                //     type: 12,
                //     message: 'johny molecule'
                // },
                // {
                //     name: 'Kevin Mackalister',
                //     type: 13,
                //     report: 23211,
                //     message: 'johny molecule'
                // },
                // {
                //     name: 'Kevin Mackalister',
                //     isAdmin: 2, // 1, 2
                //     action: 'искал аккаунт',
                //     message: 'johny molecule'
                // },
                // {
                //     name: 'Kev Shredinger',
                //     isAdd: 1,
                //     message: 'Продам гараж на Vinewood Avenue 244',
                //     number: 4554
                // }
            ]
        };
    },
    computed: {
        isAdmin () {
            return this.$store.state.player.isAdmin;
        },
        playerName () {
            return this.$store.state.player.playerName;
        },
        chatPopupItems () {
            return [
                { label: this.$t('chat.popup_items.message'), value: 1 },
                { label: this.$t('chat.popup_items.organization'), value: 2 },
                { label: this.$t('chat.popup_items.job'), value: 3 },
                { label: this.$t('chat.popup_items.family'), value: 4 },
                { label: this.$t('chat.popup_items.action'), value: 5 },
                { label: this.$t('chat.popup_items.attempt'), value: 6 },
                { label: this.$t('chat.popup_items.third_person'), value: 7 }
            ];
        }
    },
    watch: {
        isAdmin (newVal) {
            if (newVal) {
                this.chatPopupItems.push({ label: this.$t('chat.popup_items.admin'), value: 9 });
            } else {
                const adminIndex = this.chatPopupItems.findIndex(item => item.value === 9);
                if (adminIndex !== -1) {
                    this.chatPopupItems.splice(adminIndex, 1);
                }
            }
        },
        immediate: true
    },
    methods: {
        toggleChat () {
            this.isChatOpen = !this.isChatOpen;
        },

        forceChatState (state) {
            this.isChatOpen = state;
        },
        selectChatType (value) {
            this.typeChat = value;
        },
        sendMessage () {
            if (this.inputValue.trim()) {
                // Система команд в чат
                const isCommand = this.inputValue.indexOf('/');
                if (isCommand !== -1 && isCommand === 0) {
                    const EndOfCommand = this.inputValue.indexOf(' ');
                    const FullCommand = EndOfCommand !== -1 ? this.inputValue.substr(1, EndOfCommand - 1) : this.inputValue.substr(1, this.inputValue.length);
                    // Поиск аргументов
                    const CommandArguments = [];
                    if (EndOfCommand !== -1) {
                        this.inputValue.split(' ').forEach(function (word) {
                            if (!isNaN(word)) {
                                CommandArguments.push(Number(word));
                            }
                        });
                    }
                    if (!FullCommand.length) return this.annulateChatInput();
                    this.sendclient('OnPlayerCommand', JSON.stringify({ command: FullCommand, arguments: CommandArguments }));
                } else {
                    if (this.typeChat === 2) this.sendclient('OnOrgChat', JSON.stringify({ message: this.inputValue }));
                    else if (this.typeChat === 3) this.sendclient('OnJobChat', JSON.stringify({ message: this.inputValue }));
                    else if (this.typeChat === 4) this.sendclient('OnFamilyChat', JSON.stringify({ message: this.inputValue }));
                    else if (this.typeChat >= 5 && this.typeChat <= 7) this.sendclient('OnRPAction', JSON.stringify({ message: this.inputValue, type: this.typeChat - 4 }));
                    else if (this.typeChat === 9) this.sendclient('AdminMsg', JSON.stringify({ message: this.inputValue }));
                    else this.sendclient('OnPlayerChat', JSON.stringify({ message: this.inputValue }));
                }

                if (this.inputHistory.length === 0 || this.inputValue !== this.inputHistory[this.inputHistory.length - 1]) {
                    this.inputHistory.push(this.inputValue);

                    while (this.inputHistory.length > 10) {
                        this.inputHistory.shift();
                    }
                }

                this.historyIndex = -1;
                this.annulateChatInput();
            }
        },

        focusInputField () {
            this.$nextTick(() => {
                if (this.isChatOpen) {
                    this.$refs.inputField.autofocus = true;
                    this.$refs.inputField.$refs.inputRef.focus();
                    this.inputValue = '';
                }
            });
        },

        annulateChatInput () {
            this.inputValue = '';
            this.isChatOpen = false;
        },

        addMesageToChat (name, message, type, action = '', isAdmin = 0, isAdd = 0) {
            let withoutColon;
            if (type === 4 && action !== '') {
                withoutColon = true;
                action = '';
            }
            const newMessage2 = { name, type, message, action, isAdmin, isAdd, withoutColon };
            this.messages.push(newMessage2);
            if (this.messages.length > 10) {
                this.messages.shift();
            }
        },
        closeChat () {
            this.isChatOpen = false;
        },
        skipPrevious () {
            if (this.inputHistory.length === 0) {
                return;
            }

            if (this.historyIndex < this.inputHistory.length - 1) {
                this.historyIndex++;
            } else {
                this.historyIndex = 0;
            }

            this.inputValue = this.inputHistory[this.inputHistory.length - 1 - this.historyIndex];
        },
        clickMessage (message) {
            if (message.isAdd) {
                this.sendclient('openDialogFromAD', JSON.stringify({ a: message.isAdd }));
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.chat{
    max-width: 100%;
    width: 100%;
    &__wrapper{
        min-height: 300px;
        max-height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        &::-webkit-scrollbar{
            display: none;
            width: 0;
            height: 0;
        }
    }
    &__message{
        display: flex;
        margin-bottom: .4vw;
        font-size: 30px;
        align-items: center;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        min-height: fit-content;

        .admin {
            color: #FF385C;
            text-shadow:
                2px 0 0 #611D29CC,
                0 2px 0 #611D29CC,
                -2px 0 0 #611D29CC,
                0 -2px 0 #611D29CC;

            &-action{
                color: #FF8159;
                text-shadow:
                    2px 0 0 #4D2113CC,
                    0 2px 0 #4D2113CC,
                    -2px 0 0 #4D2113CC,
                    0 -2px 0 #4D2113CC;
            }
        }

        &-add {
            display: flex;
            &-icon {
                margin-right: 24px;
                width: 48px;
                border-radius: 4px;
                border: 2px solid rgba(65, 100, 20, 0.80);
                background: #D3FF9B;
                display: flex;
                justify-content: center;
                align-items: center;
                svg {
                    width: 24px;
                    height: 24px;
                }
            }
            &-text {
                color: #D3FF9B;
            }
        }

        &-admin{
            display: flex;

            &--type-1{
                color: #FF5757;
                text-transform: uppercase;
                text-shadow:
                    2px 0 0 #7C1919,
                    0 2px 0 #7C1919,
                    -2px 0 0 #7C1919,
                    0 -2px 0 #7C1919;
            }
            &--type-2{
                color: #B5B5B5;
                text-shadow:
                    2px 0 0 #3E3E3E,
                    0 2px 0 #3E3E3E,
                    -2px 0 0 #3E3E3E,
                    0 -2px 0 #3E3E3E;

                .uppercase{
                    text-transform: uppercase;
                }
            }
        }

        &-action{
            color: #9BFFCF;
            text-shadow:
                2px 0 0 #1A6D46CC,
                0 2px 0 #1A6D46CC,
                -2px 0 0 #1A6D46CC,
                0 -2px 0 #1A6D46CC;
        }

        i{
            font-style: normal;
        }

        &-svg{
            margin-right: 8px;
        }
        p{
            word-break: break-all;
        }

        span{
            margin-right: .5vw;
            flex-shrink: 0;
        }

        &--type-1{
            text-shadow:
                2px 0 0 #494865CC,
                0 2px 0 #494865CC,
                -2px 0 0 #494865CC,
                0 -2px 0 #494865CC;
        }

        &--type-9, &--type-10, &--type-11, &--type-12, &--type-13{
            text-shadow:
                2px 0 0 #494865CC,
                0 2px 0 #494865CC,
                -2px 0 0 #494865CC,
                0 -2px 0 #494865CC;
        }

        &--type-2{
            color: #78FFCE;
            text-shadow:
                2px 0 0 #123A2CCC,
                0 2px 0 #123A2CCC,
                -2px 0 0 #123A2CCC,
                0 -2px 0 #123A2CCC;
        }

        &--type-3{
            color: #78CEFF;
            text-shadow:
                2px 0 0 #152732CC,
                0 2px 0 #152732CC,
                -2px 0 0 #152732CC,
                0 -2px 0 #152732CC;
        }

        &--type-4{
            color: #FFB978;
            text-shadow:
                2px 0 0 #5E221ACC,
                0 2px 0 #5E221ACC,
                -2px 0 0 #5E221ACC,
                0 -2px 0 #5E221ACC;
        }

        &--type-5, &--type-6, &--type-7{
            color: #DF9BFF;
            text-shadow:
                2px 0 0 #422451CC,
                0 2px 0 #422451CC,
                -2px 0 0 #422451CC,
                0 -2px 0 #422451CC;

            i{
                display: none;
            }
        }
    }

    &__open{
        padding: 12px 0;
        cursor: pointer;
        font-size: 30px;
        font-weight: 700;
        line-height: 100%;
        text-transform: uppercase;
        color: #F1F1F1;
        display: flex;
        align-items: center;
        margin-top: 6px;
        max-width: max-content;

        svg{
            width: 1.2vw;
            height: auto;
            margin-right: 1vw;
        }
    }

    &__popup{
        background: rgba(20, 26, 30, 0.94);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        padding: 90px 128px 0;
        z-index: 4;
        &-wrapper{
            display: grid;
            grid-template-columns: 238px 1fr 238px;
            max-width: 2276px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }

        &-types{
            margin-top: 24px;
            display: flex;
        }

        &-item{
            display: flex;
            padding: 24px 32px;
            justify-content: center;
            align-items: center;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            margin-right: 18px;
            height: 92px;
            border-radius: 12px;
            transition: .2s ease-out;
            box-shadow: inset 0 0 0 0 transparent;

            &:nth-child(1){
                order: 0;
                background: linear-gradient(225deg, rgba(241, 241, 241, 0.20) 0%, rgba(241, 241, 241, 0.00) 100%);
                text-shadow:
                    2px 0 0 #494865CC,
                    0 2px 0 #494865CC,
                    -2px 0 0 #494865CC,
                    0 -2px 0 #494865CC;
                &.selected{
                    box-shadow: inset 0 0 0 4px #F1F1F1;
                }
            }
            &:nth-child(2){
                order: 1;
                background: linear-gradient(225deg, rgba(120, 255, 206, 0.20) 0%, rgba(0, 150, 95, 0.00) 100%);
                color: #78FFCE;
                text-shadow:
                    2px 0 0 #123A2CCC,
                    0 2px 0 #123A2CCC,
                    -2px 0 0 #123A2CCC,
                    0 -2px 0 #123A2CCC;
                &.selected{
                    box-shadow: inset 0 0 0 4px #78FFCE;
                }
            }
            &:nth-child(3){
                order: 2;
                background: linear-gradient(225deg, rgba(120, 206, 255, 0.20) 0%, rgba(4, 51, 78, 0.00) 100%);
                color: #78CEFF;
                text-shadow:
                    2px 0 0 #152732CC,
                    0 2px 0 #152732CC,
                    -2px 0 0 #152732CC,
                    0 -2px 0 #152732CC;
                &.selected{
                    box-shadow: inset 0 0 0 4px #78CEFF;
                }
            }
            &:nth-child(4){
                order: 3;
                background: linear-gradient(225deg, rgba(255, 185, 120, 0.20) 0%, rgba(101, 49, 0, 0.00) 100%);
                color: #FFB978;
                text-shadow:
                    2px 0 0 #5E221ACC,
                    0 2px 0 #5E221ACC,
                    -2px 0 0 #5E221ACC,
                    0 -2px 0 #5E221ACC;
                &.selected{
                    box-shadow: inset 0 0 0 4px #FFB978;
                }
            }
            &:nth-child(5), &:nth-child(6), &:nth-child(7){
                order: 4;
                background: linear-gradient(225deg, rgba(223, 155, 255, 0.20) 0%, rgba(107, 0, 157, 0.00) 100%);
                color: #DF9BFF;
                margin-right: 0;
                text-shadow:
                    2px 0 0 #422451CC,
                    0 2px 0 #422451CC,
                    -2px 0 0 #422451CC,
                    0 -2px 0 #422451CC;
                &.selected{
                    box-shadow: inset 0 0 0 4px #DF9BFF;
                }
            }

            &:nth-child(5){
                border-radius: 12px 0 0 12px;
            }
            &:nth-child(6){
                border-radius: 0;
                .chat-svg{
                    display: none;
                }
            }
            &:nth-child(7){
                border-radius: 0 12px 12px 0;
                .chat-svg{
                    display: none;
                }
            }

            &.admin{
                border-radius: 12px;
                background: linear-gradient(225deg, rgba(255, 56, 92, 0.20) 0%, rgba(97, 29, 41, 0.00) 100%);

                color: #FF385C;
                text-shadow:
                    2px 0 0 #611D29CC,
                    0 2px 0 #611D29CC,
                    -2px 0 0 #611D29CC,
                    0 -2px 0 #611D29CC;
                &.selected{
                    box-shadow: inset 0 0 0 4px #FF385C;
                }
            }

            &--skip{
                order: 5;
                flex: auto;
                margin-left: 18px;
                margin-right: 0;
                border-radius: 12px;
                border: 3px dashed rgba(241, 241, 241, 0.20);
                display: flex;
                padding: 24px 32px;
                justify-content: center;
                align-items: center;
                svg{
                    margin: 0;
                }
            }

        }

        &-type{
            &-1{
                background: linear-gradient(225deg, rgba(241, 241, 241, 0.20) 0%, rgba(241, 241, 241, 0.00) 100%);
            }
            &-2{
                background: linear-gradient(225deg, rgba(120, 255, 206, 0.20) 0%, rgba(0, 150, 95, 0.00) 100%);
            }
            &-3{
                background: linear-gradient(225deg, rgba(120, 206, 255, 0.20) 0%, rgba(4, 51, 78, 0.00) 100%);
            }
            &-4{
                background: linear-gradient(225deg, rgba(255, 185, 120, 0.20) 0%, rgba(101, 49, 0, 0.00) 100%);
            }
            &-5, &-6, &-7{
                background: linear-gradient(225deg, rgba(223, 155, 255, 0.20) 0%, rgba(107, 0, 157, 0.00) 100%);
            }
            &-9{
                background: linear-gradient(225deg, rgba(255, 56, 92, 0.20) 0%, rgba(97, 29, 41, 0.00) 100%);
            }
        }

        &-icon, button{
            display: flex;
            width: 238px;
            height: 154px;
            padding: 32px 24px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
        }

    }
}

</style>

<style lang="scss">
.chat{
    &__message-svg{
        svg{
            width: 36px;
            height: 36px;
        }
    }
    &__popup{
        &-input .input{
            height: 151px;
            font-size: 64px;
            text-align: left;
            padding: 42px 64px;
            width: 100%;
            max-width: 100%;
            border-radius: 0;
        }

        &-item{
            svg{
                width: 44px;
                height: 44px;
                margin-right: 32px;
            }
        }

        &-icon{
            svg{
                width: 64px;
                height: 64px;
            }
        }
        button{
            svg{
                width: 84px;
                height: 84px;
            }
        }
    }

}
</style>
