<template>
    <div class="social">
        <p>{{ $t('auth.use_social') }}</p>
        <div class="social__icons">
            <IconSocial social="google"/>
            <IconSocial social="apple"/>
            <IconSocial social="discord"/>
        </div>
    </div>
</template>

<script>
import IconSocial from '@/components/icons/IconSocial.vue';

export default {
    name: 'AuthSocial',
    components: { IconSocial }
};
</script>

<style lang="scss">
.social{
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__icons{
        display: flex;
        align-items: center;

        svg {
            width: 100%;
            height: 4.5vw;
            margin-left: .5vw;
        }
    }
    p{
        font-weight: 400;
        font-size: 1vw;
        line-height: 120%;

        letter-spacing: 0.12em;
        text-transform: uppercase;

        color: #F1F1F1;

        opacity: 0.6;
    }
}
</style>
