<template>
    <div class="sim-content sim-main">
        <div class="sim-item sim-item--left">
            <div class="sim-item__container">
                <div class="sim-item__title">{{ $t('sim_card.buy_random_sim.title') }}</div>
                <div class="sim-item__descr">{{ $t('sim_card.buy_random_sim.descr') }}</div>
                <BaseButton
                    white
                    class="sim-button"
                    @click="handleBuyRandomSim"
                >
                    {{ $t('sim_card.buy_random_sim.button') }}
                    <div class="sim-button__price">
                        <span class="sim-button__dollar">$</span>
                        {{ $formatNum(randomSimPrice) }}
                    </div>
                </BaseButton>
            </div>
        </div>
        <div class="sim-item">
            <div class="sim-item__container">
                <div class="sim-item__title">{{ $t('sim_card.shop.title') }}</div>
                <div class="sim-item__descr">{{ $t('sim_card.shop.descr') }}</div>
                <BaseButton white class="sim-button" @click="changeScreen('shop')">
                    {{ $t('sim_card.shop.button') }}
                    <IconBackArrow class="sim-button__arrow" />
                </BaseButton>
            </div>
        </div>
    </div>
</template>
<script>
import BaseButton from '@/components/ui/BaseButton.vue';
import IconBackArrow from '@/components/icons/IconBackArrow.vue';

export default {
    name: 'SimMain',
    components: { IconBackArrow, BaseButton },
    props: {
        randomSimPrice: Number,
        changeScreen: Function,
        handleBuyRandomSim: Function
    }
};
</script>
