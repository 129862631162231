<template>
    <div class="dialog dialog--control">
        <div class="dialog__page-title">
            <h2>{{title}}</h2>
            <p>{{description}}</p>
        </div>

        <div
            class="dialog__control-wrapper"
            :class="{
                'dialog__control-wrapper--four': controlItems.length <= 4,
                'dialog__control-wrapper--eight': controlItems.length > 8,
            }"
        >
            <template v-if="controlItems.length > 8">
                <div
                    class="dialog__control-column"
                    v-for="(column, columnIndex) in dividedControlItems"
                    :key="columnIndex"
                >
                    <div
                        class="dialog__control-item"
                        v-for="(item, index) in column"
                        :key="index"
                        @click = "handleClick(columnIndex+1, item.realIndex)"
                    >
                        <div class="dialog__control-title">{{ item.title }}</div>
                        <div class="dialog__control-value" :class="{ dollar: item.valueType === 'dollar' }">
                            <template v-if="item.valueType === 'dollar'">
                                <span
                                    :class="['dollar__symbol', `dollar__symbol--${item.dollarSymbol}`]">
                                    {{ item.positive ? '+' : '' }}$
                                </span>
                                {{ item.value | formatNumber }}
                            </template>
                            <template v-else-if="item.valueType === 'icon'">
                                <IconHit />
                            </template>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div
                    class="dialog__control-item"
                    v-for="(item, index) in controlItems"
                    :key="index" @click = "handleClick(index+1, item.realIndex)"
                >
                    <div class="dialog__control-title">{{ item.title }}</div>
                    <div class="dialog__control-value" :class="{ dollar: item.valueType === 'dollar' }">
                        <template v-if="item.valueType === 'dollar'">
                            <span
                                :class="['dollar__symbol', `dollar__symbol--${item.dollarSymbol}`]">
                                {{ item.positive ? '+' : '' }}$
                            </span>
                            {{ item.value | formatNumber }}
                        </template>
                        <template v-else-if="item.valueType === 'icon'">
                            <IconHit />
                        </template>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import IconHit from '@/components/icons/IconHit.vue';

export default {
    name: 'DialogControl',
    components: { IconHit },
    data () {
        return {
            title: 'Test',
            description: 'description',
            controlItems: [
                /*
                {
                    title: 'Отправить на стоянку',
                    value: 400,
                    valueType: 'dollar',
                    dollarSymbol: 'yellow'
                },
                {
                    title: 'Отремонтировать',
                    value: 400,
                    valueType: 'dollar',
                    dollarSymbol: 'yellow'
                },
                {
                    title: 'Назначить семейным'
                    // valueType: 'icon'
                },
                {
                    title: 'Назначить семейным',
                    valueType: 'icon'
                },
                {
                    title: 'Отремонтировать',
                    value: 99999400,
                    valueType: 'dollar',
                    dollarSymbol: 'green',
                    positive: true
                },
                {
                    title: 'Назначить семейным',
                    valueType: 'icon'
                },
                {
                    title: 'Отремонтировать',
                    value: 99999400,
                    valueType: 'dollar',
                    dollarSymbol: 'green',
                    positive: true
                },
                {
                    title: 'Назначить семейным',
                    valueType: 'icon'
                },
                {
                    title: 'Отремонтировать',
                    value: 99999400,
                    valueType: 'dollar',
                    dollarSymbol: 'green',
                    positive: true
                }
                */
            ]
        };
    },
    computed: {
        dividedControlItems () {
            const chunkSize = 2;
            const result = [];

            for (let i = 0; i < this.controlItems.length; i += chunkSize) {
                result.push(this.controlItems.slice(i, i + chunkSize));
            }

            return result;
        }
    },
    methods: {
        handleClick (index, realindex) {
            console.log(realindex);
            const needSendIndex = realindex !== undefined ? realindex : index;
            this.sendclient('DialogResponse', JSON.stringify({ value: needSendIndex }));
        }
    }
};
</script>
