<template>
    <div v-if=isDone class="estate__nav-item">
        <div class="estate__nav-info estate__nav-info--done">
            <div class="estate__nav-money">
                <div class="estate__nav-dollar estate__info-price-symbol">$</div>
                <div class="estate__nav-sum">{{ $formatNum(pickUpMoney) }}</div>
            </div>
            <div class="estate__nav-message">{{ $t('estate.take_profit') }}</div>
        </div>
        <BaseButton green class="estate__nav-control" @click="emitPickUp">
            <IconPlus />
            {{ $t('estate.take') }}
        </BaseButton>
    </div>
    <div v-else class="estate__nav-item">
        <div class="estate__nav-info" v-if = "upgradeEstateMoney !== undefined && upgradeEstateMoney !== 0">
            <div class="estate__nav-money">
                <div class="estate__nav-dollar estate__info-price-symbol">$</div>
                <div class="estate__nav-sum">{{ $formatNum(upgradeEstateMoney) }}</div>
            </div>
            <div class="estate__nav-message">{{ $t('estate.upgrade_price') }}</div>
        </div>
        <BaseButton yellow class="estate__nav-control" @click="upgradeEstate" v-if = "upgradeEstateMoney !== undefined && upgradeEstateMoney !== 0">
            <IconTopArrow />
            {{ $t('estate.upgrade') }}
        </BaseButton>
    </div>
</template>

<script>
import BaseButton from '@/components/ui/BaseButton.vue';
import IconTopArrow from '@/components/icons/IconTopArrow.vue';
import IconPlus from '@/components/icons/IconPlus.vue';

export default {
    name: 'EstateProfit',
    components: { IconPlus, IconTopArrow, BaseButton },
    props: {
        isDone: Boolean,
        pickUpMoney: Number,
        upgradeEstateMoney: Number
    },
    methods: {
        upgradeEstate () {
            this.sendclient('ClientUpgradeMiniBiz');
        },
        emitPickUp () {
            this.$emit('pickUp');
        }
    }
};
</script>
