<template>
    <div class="level-up">
        <transition name="fade" mode="out-in">
            <div class="level-up__level" v-if="showLevel">
                <div class="level-up__number" :class="{'level-up__number--vip': isVip}">{{ level }}</div>
                <div class="level-up__text">{{ $t('level_up.text') }}</div>
            </div>
        </transition>
        <transition name="slide-up" mode="out-in">
            <div class="level-up__element level-up__element-first" v-if="showFirstElement"></div>
        </transition>
        <transition name="slide-up" mode="out-in">
            <div class="level-up__element level-up__element-second" v-if="showSecondElement"></div>
        </transition>
        <transition name="slide-up" mode="out-in">
            <div class="level-up__element level-up__element-second level-up__element-second--right" v-if="showSecondRightElement"></div>
        </transition>
        <transition name="slide-up" mode="out-in">
            <div class="level-up__element level-up__element-white" v-if="showWhiteElement"></div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'LevelUp',
    data () {
        return {
            showLevel: false,
            showFirstElement: false,
            showSecondElement: false,
            showSecondRightElement: false,
            showWhiteElement: false
        };
    },
    props: {
        isVip: Boolean,
        level: Number
    },
    mounted () {
        setTimeout(() => { this.showLevel = true; }, 1000);
        setTimeout(() => { this.showFirstElement = true; }, 400);
        setTimeout(() => { this.showSecondElement = true; }, 600);
        setTimeout(() => { this.showSecondRightElement = true; }, 600);
        setTimeout(() => { this.showWhiteElement = true; }, 1000);
    }
};
</script>

<style lang="scss" scoped>

.level-up {
    z-index: 5000;
    width: 1117px;
    height: 716px;
    position: fixed;
    left: 50%;
    bottom: -260px;
    transform: translateX(-50%);
    background-image: url("@/assets/images/hud/lvlup/bg.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__level {
        position: absolute;
        bottom: 275px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    &__number {
        background-image: url("@/assets/images/hud/lvlup/lvl-icon.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 194px;
        height: 194px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--white);
        font-family: var(--font-stretch);
        text-align: center;
        text-shadow: 0 0 100px 0 var(--white), 0 0 200px 0 var(--white), 0 0 200px 0 var(--white);
        font-size: 120px;
        font-weight: 900;
        line-height: 100%;
        text-transform: uppercase;
        &--vip {
            background-image: url("@/assets/images/level-icon.png");
            opacity: .85;
        }
    }
    &__text {
        margin-top: 16px;
        color: #FFF;
        text-align: center;
        font-size: 48px;
        font-style: normal;
        font-family: var(--font-stretch);
        font-weight: 900;
        line-height: 100%;
        text-transform: uppercase;
    }
    &__element {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        &-first {
            width: 848px;
            height: 560px;
            bottom: 156px;
            background-image: url("@/assets/images/hud/lvlup/element-1.svg");
        }
        &-second {
            width: 297px;
            height: 283px;
            left: 0;
            bottom: 228px;
            background-image: url("@/assets/images/hud/lvlup/element-2.svg");
            &--right {
                right: 0;
                left: auto;
                background-image: url("@/assets/images/hud/lvlup/element-2-right.svg");
            }
        }
        &-white {
            position: absolute;
            width: 708px;
            height: 467px;
            bottom: 0;
            background-image: url("@/assets/images/hud/lvlup/element-white.svg");
        }
    }
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}

.slide-up-enter-active {
    animation: slide-up .5s;
}

.slide-up-leave-active {
    animation: slide-up .5s reverse;
}

@keyframes slide-up {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slide-up {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

</style>
