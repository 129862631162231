<template>
    <div class="map">
        <img src="@/assets/images/hud/map.svg" alt="" />
        <div v-if="wantedLevel !== 0" class="map__wanted">
            <img src="@/assets/images/hud/wanted.svg" alt="" />
            X <span>{{ wantedLevel }}</span>
        </div>
        <div v-if="greenArea" class="map__green-area">{{ $t('map.green_area') }}</div>
    </div>
</template>
<script>
export default {
    name: 'VMap',

    data () {
        return {
            greenArea: Boolean(false),
            wantedLevel: 2
        };
    }
};
</script>
