import { render, staticRenderFns } from "./VChat.vue?vue&type=template&id=171a1e88&scoped=true&"
import script from "./VChat.vue?vue&type=script&lang=js&"
export * from "./VChat.vue?vue&type=script&lang=js&"
import style0 from "./VChat.vue?vue&type=style&index=0&id=171a1e88&prod&lang=scss&scoped=true&"
import style1 from "./VChat.vue?vue&type=style&index=1&id=171a1e88&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "171a1e88",
  null
  
)

export default component.exports