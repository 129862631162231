<template>
    <div class="tabs"
         :class="{'tabs--scrollable': isScrollable,}">
        <div
             v-for="item in items"
             :key="item.type"
             class="tabs__item"
             :class="{
                 'tabs__item--active': activeTab === item.type,
                 'tabs__item--disabled': item.requiredLevel > playerLevel,
                 'tabs__item--has-icon': item.icon,
                 'tabs__item--hidden': currentSubmenu
             }"
             @click="changeTab(item)">
            <IconBaseTabMenu :iconSize="2.1" class="tabs__item-icon" v-if="item.icon" :type="item.icon" />
            <BaseIcon :name="item.baseIcon" color="#F1F1F1" v-if="item.baseIcon" class="tabs__base-icon"/>
            {{ item.requiredLevel > playerLevel ? `available from level ${item.requiredLevel}` : item.text }}
            <BaseLevel class="tabs__level" v-if="item.requiredLevel > playerLevel" :levelIcon="item.requiredLevel < 3 ? item.requiredLevel : 3" :width=1.75 />
            <IconBorder v-if="activeTab === item.type" border="tabMenuBorder" />
            <IconNew v-if="item.isNew" class="tabs__new" />
        </div>
        <template v-if="currentSubmenu">
            <div
                class="tabs__item tabs__item--back tabs__item--has-icon"
                @click="getBack"
            >
                <IconBaseTabMenu :iconSize="2.1" class="tabs__item-icon" type="back" />
                {{ backButtonText }}
            </div>
            <div
                v-for="item in currentSubmenu"
                :key="item.type"
                class="tabs__item"
                :class="{
                    'tabs__item--active': activeTab === item.type,
                    'tabs__item--disabled': item.requiredLevel > playerLevel,
                    'tabs__item--has-icon': item.icon
                }"
                @click="changeTab(item)"
            >
                <IconBaseTabMenu :iconSize=2.1 class="tabs__item-icon" v-if="item.icon" :type="item.icon" />
                {{ item.requiredLevel > playerLevel ? `available from level ${item.requiredLevel}` : item.text }}
                <BaseLevel class="tabs__level" v-if="item.requiredLevel > playerLevel" :levelIcon="item.requiredLevel < 3 ? item.requiredLevel : 3" :width=1.75 />
                <IconBorder v-if="activeTab === item.type" border="tabMenuBorder" />
                <IconNew v-if="item.isNew" class="tabs__new" />
            </div>
        </template>
    </div>
</template>
<script>

import IconBorder from '@/components/icons/IconBorder.vue';
import IconBaseTabMenu from '@/components/icons/IconBaseTabMenu.vue';
import BaseLevel from '@/components/ui/BaseLevel.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import IconNew from '@/components/icons/IconNew.vue';

export default {
    name: 'BaseTabMenu',
    components: { IconNew, BaseLevel, IconBaseTabMenu, IconBorder, BaseIcon },
    data () {
        return {
            currentSubmenu: null,
            backButtonText: null
        };
    },
    props: {
        items: Array,
        activeTab: String,
        isScrollable: Boolean,
        handleTabChange: Function
    },
    computed: {
        playerLevel () {
            return this.$store.state.player.playerLevel;
        }
    },
    methods: {
        changeTab (item) {
            item.submenu ? this.openSubmenu(item) : this.openTab(item.type);
        },
        openSubmenu (item) {
            console.log('submenu opened');
            this.currentSubmenu = item.submenu;
            this.handleTabChange(item.submenu[0].type);
            this.backButtonText = item.backButtonText;
        },
        openTab (type) {
            return this.handleTabChange(type);
        },
        getBack () {
            this.handleTabChange(this.items[0].type);
            this.currentSubmenu = null;
        }
    }
};
</script>

<style lang="scss">
.tabs {
    &__base-icon {
        margin: 0 auto;
        opacity: .5;
        svg {
            width: 52px;
            height: 52px;
        }

    }
}
</style>
<style lang="scss" scoped>
.tabs {
    display: flex;
    flex-direction: column;
    padding-bottom: 3.95vw;
    &--scrollable {
        overflow-y: auto;
        overflow-x: hidden;
        display: grid;
        align-content: start;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    &__item {
        background: linear-gradient(224.44deg, rgba(241, 241, 241, 0.2) 0%, rgba(241, 241, 241, 0) 100%);
        border-radius: .16vw;
        padding: 32px 40px;
        position: relative;
        z-index: 4;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.04em;
        color: var(--white);
        line-height: 120%;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1vw;
        transition: padding .2s ease-out;
        &:last-child {
            margin-bottom: 0;
        }
        &-icon {
            margin-right: 1.3vw;
            opacity: .5;
            transition: .2s ease-out;
            display: flex;
            align-items: center;
            justify-content: center
        }
        &--active {
            padding: 42px 40px;
            background: linear-gradient(224.44deg, rgba(241, 241, 241, 0.5) 0%, rgba(241, 241, 241, 0) 100%);
            .tabs__item-icon {
                opacity: 1;
            }
            .tabs__base-icon {
                opacity: 1;
            }
        }
        &--hidden {
            display: none;
        }
        &--disabled {
            pointer-events: none;
            color: rgba(241, 241, 241, 0.5);
        }
        &--has-icon {
            justify-content: start;
        }
        &--back {
            border: 4px dashed rgba(241, 241, 241, 0.20);
            background: transparent;
        }
        svg {
            width: 1.6vw;
            height: 1.6vw;
        }
        .tabs__new {
                position: absolute;
                right: -1vw;
                top: 50%;
                transform: translateY(-50%);
                width: 4vw;
                height: 2vw;
        }
    }
    &__level {
        margin-left: auto;
    }
}
</style>
