import { render, staticRenderFns } from "./MyClothes.vue?vue&type=template&id=ac540872&scoped=true&"
import script from "./MyClothes.vue?vue&type=script&lang=js&"
export * from "./MyClothes.vue?vue&type=script&lang=js&"
import style0 from "./MyClothes.vue?vue&type=style&index=0&id=ac540872&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac540872",
  null
  
)

export default component.exports