<template>
    <div class="progress-action">
        <p>{{ title }}</p>
        <div class="progress-action__wrapper">
            <i :style="`width: ${progress}%`"></i>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProgressAction',
    props: {
        title: String,
        timer: Number
    },
    data () {
        return {
            progress: 0
        };
    },
    methods: {
        updateProgress () {
            const intervalMs = 100;
            const stepsPerSecond = 1000 / intervalMs;
            const step = 100 / (this.timer * stepsPerSecond);

            if (this.intervalId !== undefined) {
                clearInterval(this.intervalId);
            }
            this.intervalId = setInterval(() => {
                this.progress += step;
                if (this.progress >= 100) {
                    clearInterval(this.intervalId);
                    setTimeout(() => {
                        this.$emit('completed');
                    }, 1500);
                }
            }, intervalMs);
        }
    },
    mounted () {
        this.updateProgress();
    },
    beforeDestroy () {
        clearInterval(this.intervalId);
    }
};
</script>

<style lang="scss" scoped>
.progress-action{
    position: absolute;
    bottom: 305px;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: grid;
    grid-gap: 24px;
    max-width: 524px;
    text-align: center;

    p{
        color: #F1F1F1;
        text-align: center;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 32px */
    }

    &__wrapper{
        border-radius: 300px;
        border: 4px solid #F1F1F1;
        background: rgba(241, 241, 241, 0.20);
        width: 524px;
        height: 24px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow: hidden;
        i{
            display: block;
            height: 24px;
            border-radius: 300px 0px 0px 300px;
            background: #F1F1F1;
            transition: width 1s linear;
        }
    }

}
</style>
