<template>
    <div class="org family" :style="{background: `url(${imageSource('family-creation.png', 'organization')}) no-repeat right bottom/100%, url(${imageSource('bg-popup-vector-dark.svg', null)}) no-repeat center/cover, radial-gradient(66.94% 140.08% at 50% 0%, ${gradient.startColor} 0%, ${gradient.finishColor} 100%)`}">
        <AppHeader back-button>
            <template #title>{{ organization }}</template>
        </AppHeader>
        <div class="org__content">
            <div class="org__tabs family__tabs">
                <BaseTabMenu :items="tabDefinitions" :activeTab="selectedType" :handleTabChange="changeTab" />
                <div class="family__tabs-item">
                    <IconClose />
                    <div class="family__tabs-title">{{ this.$t('family.no_family') }}</div>
                    <div class="family__tabs-text">{{ this.$t('family.how_to_join') }}</div>
                </div>
            </div>
            <div class="org__info family-creation">
                <div class="family-creation__info">
                    <BaseInput class="family-creation__input" placeholder="Mackalister" :label="$t('family.family_name')" v-model="familyName" />
                    <div class="family-creation__condition">
                        <BaseHaveProperty :text="forCreate.estate" :isConditionComplete="forCreate.isAvailable" />
                        <div class="family-creation__condition-text">{{ $t('family.conditions_complete') }}</div>
                    </div>
                    <BaseButton class="family-creation__button" white @click="createFamily">{{ $t('family.create_family') }} <IconPlus/></BaseButton>
                    <div class="family-creation__price">
                        <div class="family-creation__price-money">
                            <IconDonate />
                            {{ $formatNum(forCreate.donate) }}
                        </div>
                        <div class="family-creation__price-text">{{ $t('family.or') }}</div>
                        <div class="family-creation__price-money">
                            <span class="family-creation__price-dollar">$</span>
                            {{ $formatNum(forCreate.money) }}
                        </div>
                    </div>
                </div>
                <div class="family-creation__list">
                    <div class="family-creation__list-item">
                        <IconBaseTabMenu :iconSize="2.05371" type="event" />
                        <div class="family-creation__list-text">{{ $t('family.advantages.events') }}</div>
                    </div>
                    <div class="family-creation__list-item">
                        <IconBaseTabMenu :iconSize="2.05371" type="chat" />
                        <div class="family-creation__list-text">{{ $t('family.advantages.chat') }}</div>
                    </div>
                    <div class="family-creation__list-item">
                        <IconBaseTabMenu :iconSize="2.05371" type="team" />
                        <div class="family-creation__list-text">{{ $t('family.advantages.system') }}</div>
                    </div>
                    <div class="family-creation__list-item">
                        <IconBaseTabMenu :iconSize="2.05371" type="flag" />
                        <div class="family-creation__list-text">{{ $t('family.advantages.estate') }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppHeader from '@/components/AppHeader/AppHeader.vue';
import BaseTabMenu from '@/components/ui/BaseTabMenu.vue';
import IconClose from '@/components/icons/IconClose.vue';
import BaseInput from '@/components/ui/BaseInput.vue';
import BaseHaveProperty from '@/components/ui/BaseHaveProperty.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import IconPlus from '@/components/icons/IconPlus.vue';
import IconBaseTabMenu from '@/components/icons/IconBaseTabMenu.vue';
import IconDonate from '@/components/icons/IconDonate.vue';

export default {
    name: 'FamilyCreation',
    components: { IconDonate, IconBaseTabMenu, IconPlus, BaseButton, BaseHaveProperty, BaseInput, IconClose, BaseTabMenu, AppHeader },
    data () {
        return {
            selectedType: 'about',
            organization: this.$t('family.family'),
            forCreate: {
                money: 999222,
                donate: 2222,
                estate: this.$t('family.house'),
                isAvailable: true
            },
            tabDefinitions: [
                { type: 'about', text: this.$t('family.family_creation'), icon: 'about' }
            ],
            gradient: {
                startColor: '#35717B',
                finishColor: '#321959'
            },
            familyName: ''
        };
    },
    mounted () {},
    computed: {},
    methods: {
        /*
        window.modifyVueComponent({
            App: {
                showPopup: true,
                popupContent: 'FamilyCreation',
                contentSubFolder: 'organization'
            },
            FamilyCreation:{
                initialize:[
                    true
                ]
            }
        });
        */
        initialize (isHouse) {
            this.forCreate.isAvailable = isHouse;
        },
        changeTab (tabType) {
            this.selectedType = tabType;
        },
        createFamily () {
            this.sendclient('RequestFam', JSON.stringify({ a: this.familyName }));
        }
    }
};
</script>
