<template>
    <transition name="fade">
        <div class="time-prizes" v-if="show">
            <div
                class="time-prizes__images"
                :class="
                    [
                        { 'time-prizes__images--money' : type === 2 },
                        { 'time-prizes__images--donate' : type === 3 },
                        { 'time-prizes__images--car' : type === 1 },
                    ]
                    ">
                <span class="dollar" v-if="type === 2">
                    <span class="dollar__symbol dollar__symbol--yellow">$</span>
                    <i>{{ $formatNum(money) }}</i>
                </span>
                <span class="donate" v-if="type === 3">
                    <IconDonate />
                    <i>{{ $formatNum(donate) }}</i>
                </span>
            </div>
            <div class="time-prizes__time">
                <p>{{ $t('time_prizes.description_1')}}</p>
                <div class="time-prizes__time-count">
                    <template v-if="totalSeconds >= 3600">
                        <span>{{ Math.floor(totalSeconds / 3600 / 10) }}</span>
                        <span>{{ Math.floor(totalSeconds / 3600) % 10 }}</span>
                        <i>:</i>
                    </template>
                    <span>{{ Math.floor((totalSeconds % 3600) / 60 / 10) }}</span>
                    <span>{{ Math.floor((totalSeconds % 3600) / 60) % 10 }}</span>
                    <i>:</i>
                    <span>{{ Math.floor(totalSeconds % 60 / 10) }}</span>
                    <span>{{ totalSeconds % 60 % 10 }}</span>
                </div>

                <p>{{ $t('time_prizes.description_2')}}</p>
            </div>
        </div>
    </transition>
</template>

<script>
import IconDonate from '@/components/icons/IconDonate.vue';

export default {
    name: 'TimePrizes',
    components: { IconDonate },
    data () {
        return {
            show: false,
            type: 3, // 1 -- car, 2 -- money, 3 -- donate
            totalSeconds: 3600, // Общее время для отсчета в секундах
            money: 10000,
            donate: 100000,
            participants: 10,
            timer: null
        };
    },
    mounted () {
        // this.startTimer();
    },
    beforeDestroy () {
        this.stopTimer();
    },
    methods: {
        startTimer (type, amount, time) {
            if (this.timer) {
                this.stopTimer();
            }
            this.type = type;
            if (type === 2) this.money = amount;
            else if (type === 3) this.donate = amount;
            this.totalSeconds = time;
            this.show = true;
            this.timer = setInterval(() => {
                if (this.totalSeconds > 0) {
                    this.totalSeconds--;
                } else {
                    this.stopTimer();
                }
            }, 1000);
        },
        stopTimer () {
            clearInterval(this.timer);
            this.timer = null;
            this.show = false;
        },
        showChange (state) {
            this.show = state;
            if (state === true) {
                setTimeout(() => {
                    this.show = false;
                }, 10 * 1000);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.time-prizes{
    position: absolute;
    bottom: -80px;
    display: flex;
    left: 34px;
    &__images{
        width: 300px;
        height: 168px;
        display: flex;
        align-items: center;
        justify-content: center;

        &--car{
            background: url(./assets/images/car.png) no-repeat center / contain;
        }
        &--money{
            background: url(./assets/images/money.png) no-repeat center / contain;
        }
        &--donate{
            background: url(./assets/images/donate.png) no-repeat center / contain;
        }
    }
    .dollar, .donate{
        font-size: 48px;
        font-style: normal;
        font-family: var(--font-family);
        font-weight: 700;
        line-height: 48px;
        letter-spacing: -0.96px;
        display: flex;
        align-items: center;
        &::v-deep {
            svg {
                width: 48px;
                height: 48px;
            }
        }
        i{
            color: #F1F1F1;
            font-style: normal;
            text-shadow: 0px 0px 34px 0px rgba(0, 0, 0, 0.25);
        }
    }
    &__time{
        display: flex;
        flex-direction: column;
        &-section{
            margin-right: 64px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            &.member{
                p{
                    color: #FFCD4C !important;
                    opacity: 1;
                }
                span{
                    background: #FFCD4C !important;
                    color: #141A1E !important;
                }
            }
        }
        &-count, &-member{
            display: flex;
            margin: 14px 0;
        }
        p{
            color: #F1F1F1;
            font-size: 26px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%; /* 26px */
            letter-spacing: 1.56px;
            text-transform: uppercase;
            font-family: var(--font-stretch);
        }
        span{
            text-align: center;
            display: flex;
            padding: 8px 8px 12px 8px;
            flex-direction: column;
            align-items: flex-start;
            border-radius: 4px;
            background: #000;
            margin-right: 4px;
            color: #FFF;
            font-size: 52px;
            font-style: normal;
            font-weight: 700;
            line-height: 80%; /* 41.6px */
            letter-spacing: -1.04px;
        }
        i{
            color: #FFF;
            font-size: 52px;
            font-style: normal;
            font-weight: 700;
            line-height: 80%; /* 41.6px */
            letter-spacing: -1.04px;
            margin-right: 4px;
        }
    }
}
</style>
