<template>
    <div class="profile">
        <AppHeader backButton>
            <template #title>{{ $t('profile.header') }}</template>
        </AppHeader>
        <div class="profile-grid">
            <div
                class="profile-item profile-item--info"
                :class="{'profile-item--info-vip' : vip}"
            >
                <div
                    class="profile-item__level"
                    :class="{'profile-item__level--vip': vip}"
                >
                    <div class="profile-item__level-text">{{ playerLevel }}</div>
                </div>
                <div class="profile-item__info">
                    <div class="profile-item__title">{{ playerName }}</div>
                    <div class="profile-item__status" v-if="vip">{{ $t('profile.vip_acc') }}</div>
                </div>
            </div>
            <div class="profile-item profile-item--data">
                <div class="profile-item__data profile-item__data--lg" v-if = "family && family.length > 0">
                    <div class="profile-item__data-title">{{ $t('profile.family') }}</div>
                    <div class="profile-item__data-text">{{ family }}</div>
                </div>
                <div class="profile-item__data profile-item__data--lg" v-else>
                    <div class="profile-item__data-title">{{ $t('profile.family') }}</div>
                    <div class="profile-item__data-text">{{ $t('profile.no_family') }}</div>
                </div>
                <div class="profile-item__data profile-item__data--lg" v-if = "formattedPhone != '0--'">
                    <div class="profile-item__data-title">{{ $t('profile.phone_number') }}</div>
                    <div class="profile-item__data-text">{{ formattedPhone }}</div>
                </div>
                <div class="profile-item__data profile-item__data--lg" v-else>
                    <div class="profile-item__data-title">{{ $t('profile.phone_number') }}</div>
                    <div class="profile-item__data-text">{{ $t('profile.no_family') }}</div>
                </div>
                <div class="profile-item__data profile-item__data--lg" v-if = "partner && partner.length > 0">
                    <div class="profile-item__data-title">{{ $t('profile.partner') }}</div>
                    <div class="profile-item__data-text">{{ partner }}</div>
                </div>
                <div class="profile-item__data profile-item__data--lg" v-else>
                    <div class="profile-item__data-title">{{ $t('profile.partner') }}</div>
                    <div class="profile-item__data-text">{{ $t('profile.single') }}</div>
                </div>
                <div class="profile-item__data">
                    <div class="profile-item__data-title">{{ $t('profile.warns') }}</div>
                    <div class="profile-item__data-text">{{ warn }}</div>
                </div>
                <div class="profile-item__data">
                    <div class="profile-item__data-title">{{ $t('profile.fines') }}</div>
                    <div class="profile-item__data-text">${{ $formatNum(fine) }}</div>
                </div>
            </div>
            <div class="profile-item profile-item--skills">
                <div class="profile-item__skills-heading">
                    <div class="profile-item__skills-info">
                        <div class="profile-item__skills-number">{{ craftLevel }}</div>
                        <div class="profile-item__skills-text">{{ $t('profile.level') }}</div>
                    </div>
                    <IconSkillsCraft class="profile-item__skills-icon" />
                </div>
                <div class="profile-item__skills-status">
                    <div class="profile-item__skills-data">
                        <div class="profile-item__skills-subtitle">{{ $t('profile.craft') }}</div>
                        <div class="profile-item__skills-points">{{$formatNum(craftExp)}} <span>/ {{$formatNum(maxSkillPoints)}}</span></div>
                    </div>
                    <BaseChart
                        :percent="skillPercentage"
                        chartColor="#E2FFBE"
                        chartBackground="#141A1E"
                    />
                </div>
            </div>
            <div class="profile-item profile-item--mastery profile-item--mastery-gun">
                <ProfileMasteryIcon
                    width="6.4vw"
                    height="6.4vw"
                    type="gun"
                    iconColor="#CCD2EA"
                    :percent="gunSkill"
                />
                <div class="profile-item__mastery-title">{{ $t('profile.shooting') }}</div>
                <div class="profile-item__mastery-number">{{ gunSkill }}%</div>
            </div>
            <div class="profile-item profile-item--mastery profile-item--mastery-car">
                <ProfileMasteryIcon
                    width="6.4vw"
                    height="6.4vw"
                    type="car"
                    iconColor="#EADECC"
                    :percent="driveSkill"
                />
                <div class="profile-item__mastery-title">{{ $t('profile.car_skill') }}</div>
                <div class="profile-item__mastery-number">{{ driveSkill }}%</div>
            </div>
            <div class="profile-item profile-item--org" v-if = "gangId > 0">
                <div class="profile-item__org-title">{{ $t('profile.organization') }}</div>
                <img class="profile-item__org-image" :src="imageSource(`organization-${gangId}.png`, 'organization')" alt="" />
                <div class="profile-item__org-name">{{ organization }}</div>
            </div>
        </div>
        <div class="profile-id">{{ pid }} {{ $t('profile.id') }}</div>
    </div>
</template>
<script>
import AppHeader from '@/components/AppHeader/AppHeader.vue';
import IconSkillsCraft from '@/components/icons/IconSkillsCraft.vue';
import BaseChart from '@/components/ui/BaseChart.vue';
import ProfileMasteryIcon from '@/components/popups/content/character/components/ProfileMasteryIcon.vue';
import { mapState, mapGetters } from 'vuex';
export default {
    name: 'MyProfile',
    components: {
        ProfileMasteryIcon,
        BaseChart,
        IconSkillsCraft,
        AppHeader
    },
    data () {
        return {
            maxSkillPoints: 99000
        };
    },
    computed: {
        ...mapState('player', [
            'playerName',
            'playerLevel',
            'id',
            'pid',
            'partner',
            'vip',
            'family',
            'organization',
            'fine',
            'warn',
            'gunSkill',
            'driveSkill',
            'craftLevel',
            'craftExp',
            'gangId'
        ]),
        ...mapGetters('player', ['formattedPhone']),
        skillPercentage () {
            return Math.round((this.craftExp / this.maxSkillPoints) * 100);
        }
    }
};
</script>
<style lang="scss">
.profile {
    height: 100%;
    background: radial-gradient(70.39% 152.52% at 50.06% 100.00%, #1A383D 0%, #09121A 100%);
    position: relative;
    &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-image: url("@/assets/images/character/profile-bg.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        opacity: .3;
        z-index: 1;
    }
    &-item {
        border-radius: 32px;
        box-shadow: 0 -36px 114px 0 rgba(241, 241, 241, 0.07) inset;
        &--info {
            background: rgba(20, 26, 30, 0.90);
            box-shadow: 0px -84px 74px 0px rgba(61, 76, 100, 0.20) inset;
            //grid-column: 9 span;
            grid-column: 12 span;
            grid-row: 3 span;
            padding: 64px 20px 64px 80px;
            display: flex;
            align-items: center;
            &-vip {
                background: radial-gradient(240.75% 123.04% at 0.00% 100.00%, #FFBA9F 0%, #B8DBDF 36.46%, #FFF 100%);
                box-shadow: 0px -36px 114px 0px rgba(241, 241, 241, 0.07) inset;
                .profile-item__title {
                    color: var(--dark);
                }
                .profile-item__status {
                    color: var(--dark);
                }
            }
        }
        &--data {
            grid-column: 12 span;
            grid-row: 4 span;
            padding: 80px;
            background: radial-gradient(240.75% 123.04% at 0.00% 100.00%, rgba(255, 255, 255, 0.19) 0%, rgba(14, 70, 77, 0.08) 44.27%, rgba(255, 255, 255, 0.04) 100%);
            display: grid;
            grid-gap: 64px 100px;
            grid-template-columns: auto min-content;
            grid-template-rows: 1fr 1fr;
            grid-auto-flow: column;
        }
        &--skills {
            grid-column: 8 span;
            grid-row: 3 span;
            background: radial-gradient(125.31% 111.03% at 25.97% 17.25%, rgba(226, 255, 190, 0.30) 0%, rgba(226, 255, 190, 0.00) 100%);
            padding: 44px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        &--mastery {
            grid-row: 4 span;
            grid-column: 4 span;
            padding: 44px 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            &-gun {
                color: #CCD2EA;
                background: radial-gradient(125.31% 111.03% at 25.97% 17.25%, rgba(204, 210, 234, 0.30) 0%, rgba(204, 210, 234, 0.00) 100%);
            }
            &-car {
                color: #EADECC;
                background: radial-gradient(125.31% 111.03% at 25.97% 17.25%, rgba(234, 222, 204, 0.30) 0%, rgba(234, 222, 204, 0.00) 100%);
            }
        }
        &--org {
            border: 4px solid rgba(241, 241, 241, 0.1);
            box-shadow: none;
            grid-column: 7 span;
            grid-row: 7 span;
            background: transparent;
            padding: 80px 50px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
        }
        &__title {
            color: var(--white);
            font-size: 72px;
            font-weight: 300;
            white-space: nowrap;
            line-height: 120%;
            margin-bottom: 12px;
        }
        &__status {
            opacity: .6;
            color: var(--white);
            font-size: 40px;
            font-weight: 400;
            line-height: 100%;
        }
        &__level {
            background-image: url("@/assets/images/level-icon-standart.png");
            width: 158px;
            height: 158px;
            background-position: center;
            background-size: cover;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 44px;
            &--vip {
                background-image: url("@/assets/images/level-icon.png");
            }
            &-text {
                color: var(--white);
                text-shadow: 0 7.523809909820557px 30.095239639282227px 0 rgba(0, 0, 0, 0.25);
                font-size: 67px;
                font-weight: 900;
                line-height: 100%;
                letter-spacing: -2.709px;
                transform: translateX(-2.709px);
            }
        }
        &__data {
            grid-row: 1 span;
            &--lg {
                grid-column: 2 span;
            }
            &-title {
                color: var(--white);
                opacity: .5;
                font-size: 28px;
                font-weight: 700;
                line-height: 120%;
                letter-spacing: 1.12px;
                text-transform: uppercase;
                margin-bottom: 12px;
            }
            &-text {
                color: var(--white);
                font-size: 52px;
                font-weight: 400;
                line-height: 110%;
                white-space: nowrap;
            }
        }
        &__skills {
            &-heading {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            &-info {
                display: flex;
            }
            &-number {
                color: var(--skills-green);
                font-size: 64px;
                font-weight: 700;
                line-height: 100%;
                margin-right: 12px;
            }
            &-text {
                color: var(--skills-green);
                opacity: .4;
                font-size: 64px;
                font-weight: 400;
                line-height: 100%;
            }
            &-icon {
                width: 74px;
                height: 74px;
            }
            &-data {
                display: flex;
                justify-content: space-between;
                margin-bottom: 24px;
            }
            &-subtitle {
                opacity: .4;
                color: var(--skills-green);
                font-size: 28px;
                font-weight: 700;
                line-height: 100%;
                letter-spacing: 1.12px;
                text-transform: uppercase;
            }
            &-points {
                color: var(--skills-green);
                font-size: 28px;
                font-weight: 700;
                line-height: 100%;
                letter-spacing: 1.12px;
                text-transform: uppercase;
                span {
                    opacity: .4;
                }
            }
        }
        &__mastery {
            &-title {
                margin: 32px 0 12px;
                text-align: center;
                font-size: 28px;
                font-weight: 700;
                line-height: 120%;
                letter-spacing: 1.12px;
                text-transform: uppercase;
                opacity: .4;
            }
            &-number {
                font-size: 52px;
                font-weight: 700;
                line-height: 110%;
            }
        }
        &__org {
            &-title {
                color: var(--white);
                text-align: center;
                font-size: 28px;
                font-weight: 700;
                line-height: 100%;
                letter-spacing: 1.12px;
                text-transform: uppercase;
                opacity: .4;
            }
            &-image {
                width: 374px;
            }
            &-name {
                color: var(--white);
                text-align: center;
                font-size: 54px;
                font-weight: 700;
                line-height: 100%;
            }
        }
    }
    &-grid {
        position: relative;
        z-index: 2;
        padding: 173px 164px 149px;
        height: calc(100% - 5vw);
        display: grid;
        grid-gap: 52px;
        //grid-template-columns: repeat(20, 60.5px);
        grid-template-columns: auto 100px;
        grid-template-rows: repeat(7, 1fr);
        overflow-y: hidden;
        overflow-x: auto;
        grid-auto-flow: column;
        grid-auto-columns: auto 60.5px;
        //grid-auto-columns: auto 127px;
        justify-content: safe center;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    &-id {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 54px;
        color: rgba(241, 241, 241, 0.50);
        opacity: .6;
        font-size: 40px;
        font-weight: 400;
        line-height: 100%;
    }
}
</style>
