<template>
    <div class="icon-wrapper">
        <svg v-if="icon === 'alert'" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.33325 8.66683V4.66683H8.66659V8.66683H7.33325Z" fill="#FFCD4C"/>
            <path d="M7.99992 11.3335C7.53968 11.3335 7.16659 10.9604 7.16659 10.5002C7.16659 10.0399 7.53968 9.66683 7.99992 9.66683C8.46016 9.66683 8.83325 10.0399 8.83325 10.5002C8.83325 10.9604 8.46016 11.3335 7.99992 11.3335Z" fill="#FFCD4C"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99992 14.6668C4.31802 14.6668 1.33325 11.6821 1.33325 8.00016C1.33325 4.31826 4.31802 1.3335 7.99992 1.3335C11.6818 1.3335 14.6666 4.31826 14.6666 8.00016C14.6666 11.6821 11.6818 14.6668 7.99992 14.6668ZM7.99992 13.3335C10.9454 13.3335 13.3333 10.9457 13.3333 8.00016C13.3333 5.05464 10.9454 2.66683 7.99992 2.66683C5.0544 2.66683 2.66659 5.05464 2.66659 8.00016C2.66659 10.9457 5.0544 13.3335 7.99992 13.3335Z" fill="#FFCD4C"/>
        </svg>

        <svg v-if="icon === 'warning'" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 8.94305L11.5286 12.4716L12.4714 11.5288L8.94281 8.00024L12.4714 4.47168L11.5286 3.52887L8 7.05744L4.47137 3.52881L3.52856 4.47162L7.05719 8.00024L3.52856 11.5289L4.47137 12.4717L8 8.94305Z" fill="#CF4941"/>
        </svg>

        <svg v-if="icon === 'success'" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1499 5.60937L7.13799 11.6094C6.80466 11.2761 6.92693 11.4102 6.66659 11.1499L3.33325 7.81652L4.27606 6.87371L7.13799 9.73564L12.2071 4.66657L13.1499 5.60937Z" fill="#27AE60"/>
        </svg>

        <svg v-if="icon === 'info'" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.66674 8.66667H13.6569L13.6569 7.33333L8.66674 7.33333L8.66674 2.34319L7.33341 2.34319L7.33341 7.33333L2.34318 7.33333L2.34318 8.66667L7.33341 8.66667L7.33341 13.6569L8.66675 13.6569L8.66674 8.66667Z" fill="#2F80ED"/>
        </svg>
    </div>

</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            required: true
        }
    }
};
</script>
