<template>
    <div class="main-buttons">
        <button class="main-buttons__item main-buttons__item--shop" @click = "openShop">
            <IconShop />
        </button>
        <div class="main-buttons__double" v-if="isAdmin">
            <button class="main-buttons__item main-buttons__item--menu" @click="openAdmin">
                <IconAdmin />
            </button>
            <button class="main-buttons__item main-buttons__item--menu" @click="openMobile">
                <IconPhone />
            </button>
        </div>
        <button class="main-buttons__item main-buttons__item--phone" @click="openMobile" v-else>
            <IconPhone />
        </button>
        <div class="main-buttons__double">
            <button class="main-buttons__item main-buttons__item--menu" @click="openMenu">
                <IconMenu />
            </button>
            <PlayerInteractive />
        </div>
    </div>
</template>
<script>
import IconShop from '@/components/icons/IconShop.vue';
import IconPhone from '@/components/icons/IconPhone.vue';
import IconMenu from '@/components/icons/IconMenu.vue';
import PlayerInteractive from '@/components/hud/components/buttons/PlayerInteractive.vue';
import IconAdmin from '@/components/icons/IconAdmin.vue';

export default {
    name: 'HudButtons',
    components: { IconAdmin, PlayerInteractive, IconMenu, IconPhone, IconShop },
    computed: {
        isAdmin () {
            return this.$store.state.player.isAdmin;
        }
    },
    methods: {
        openMobile () {
            this.$emit('open-mobile');
        },
        openMenu () {
            this.sendclient('menuOpen');
        },
        openAdmin () {
            this.sendclient('openAdmin');
        },
        openShop () {
            this.sendclient('openShop');
        }
    }
};
</script>

<style lang="scss" scoped>
.main-buttons{
    margin: 62px 63px 0 0;
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 37px;
    max-width: 429px;

    &__double{
        display: grid;
        grid-gap: 22px;
    }
    &__item{
        width: 117px;
        background: transparent;
        height: 217px;
        border-radius: 62px;
        cursor: pointer;
        transition: .2s;
        position: relative;

        &::before{
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background: url(@/assets/images/hud/hud_menu_item.svg) no-repeat center/contain;
            content: "";
            position: absolute;
            display: block;
            opacity: .3;
            transition: .3s;
        }
        &:active::before{
            opacity: 1;
        }

        svg{
            width: 2.4vw;
            height: auto;
        }

        &--menu{
            width: 118px;
            height: 118px;
            padding: 32px;

            svg{
                width: 50px;
                height: 53px;
            }
            .svg{
                width: 54px;
                height: 54px;
            }

            &::before{
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background: url(@/assets/images/hud/hud_menu_item-circle.svg) no-repeat center/contain;
                content: "";
                position: absolute;
                display: block;
                opacity: .3;
                transition: .3s;
            }
            &:active::before{
                opacity: 1;
            }
        }
    }
}
</style>
