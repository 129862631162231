import MyAuction from './auction/MyAuction.vue';
import BuyoutAuction from './auction/BuyoutAuction.vue';
import Inventory from './inventory/MyInventory.vue';
import CraftInventory from './inventory/CraftInventory.vue';
import MyBank from './bank/MyBank.vue';
import BattlePass from './battlePass/BattlePass.vue';
import GasStation from './garage/GasStation.vue';
import MyGarage from './garage/MyGarage.vue';
import AcceptWork from './works/AcceptWork.vue';
import MiniWorks from './works/MiniWorks.vue';
import WorkList from './works/WorkList.vue';
import MainMenu from './menu/MainMenu.vue';
import BuyerSystem from './buyer/BuyerSystem.vue';
import MyClothes from './character/MyClothes.vue';
import MyProfile from './character/MyProfile.vue';
import MyContacts from './contacts/MyContacts.vue';
import DialogControl from './dialogs/DialogControl.vue';
import DialogCounter from './dialogs/DialogCounter.vue';
import DialogInfo from './dialogs/DialogInfo.vue';
// import DialogInput from './dialogs/DialogInput.vue';
import DialogNumpad from './dialogs/DialogNumpad.vue';
import DialogPay from './dialogs/DialogPay.vue';
import DialogPromo from './dialogs/DialogPromo.vue';
import DialogReview from './dialogs/DialogReview.vue';
import DialogSettings from './dialogs/DialogSettings.vue';
import DialogWhere from './dialogs/DialogWhere.vue';
import DialogYesNo from './dialogs/DialogYesNo.vue';
import MyEstate from './estate/MyEstate.vue';
import EventSeason from './event/EventSeason.vue';
import EnterRewards from './information/EnterRewards.vue';
import GetItem from './information/GetItem.vue';
import X2Modal from './information/X2Modal.vue';
import AuthStep from './login/AuthStep.vue';
import RegisterStep from './login/RegisterStep.vue';
import ServerSelect from './login/ServerSelect.vue';
import SpawnSelect from './login/SpawnSelect.vue';
import FamilyCreation from './organization/FamilyCreation.vue';
import MyFamily from './organization/MyFamily.vue';
import WareHouse from './organization/WareHouse.vue';
import MyPda from './pda/MyPda.vue';
import RatingSystem from './rating/RatingSystem.vue';
import MainSettings from './settings/MainSettings.vue';
import MainShop from './shop/MainShop.vue';
import MyShop from './shop/MyShop.vue';
import SimCard from './shop/SimCard.vue';
import StreetMarket from './shop/StreetMarket.vue';
import PhoneTask from './task/PhoneTask.vue';
import ErrorDisconnect from './technical/ErrorDisconnect.vue';
import PopupLoader from './technical/PopupLoader.vue';

const components = {
    MyAuction,
    BuyoutAuction,
    Inventory,
    CraftInventory,
    MyBank,
    BattlePass,
    GasStation,
    MyGarage,
    AcceptWork,
    MiniWorks,
    WorkList,
    MainMenu,
    BuyerSystem,
    MyClothes,
    MyProfile,
    MyContacts,
    DialogControl,
    DialogCounter,
    DialogInfo,
    // DialogInput,
    DialogNumpad,
    DialogPay,
    DialogPromo,
    DialogReview,
    DialogSettings,
    DialogWhere,
    DialogYesNo,
    MyEstate,
    EventSeason,
    EnterRewards,
    GetItem,
    X2Modal,
    AuthStep,
    RegisterStep,
    ServerSelect,
    SpawnSelect,
    FamilyCreation,
    MyFamily,
    WareHouse,
    MyPda,
    RatingSystem,
    MainSettings,
    MainShop,
    MyShop,
    SimCard,
    StreetMarket,
    PhoneTask,
    ErrorDisconnect,
    PopupLoader
};

export default components;
