<template>
    <div class="auction__info-timer">
        <div class="auction__info-clock">
            <div v-if="hour" class="auction__info-clock-number">{{hour.split('')[0]}}</div>
            <div v-if="hour" class="auction__info-clock-number">{{hour.split('')[1]}}</div>
            <div v-if="hour && minute" class="auction__info-clock-divider">:</div>
            <div v-if="minute" class="auction__info-clock-number">{{minute.split('')[0]}}</div>
            <div v-if="minute" class="auction__info-clock-number">{{minute.split('')[1]}}</div>
            <div v-if="minute && second" class="auction__info-clock-divider">:</div>
            <div v-if="second" class="auction__info-clock-number">{{second.split('')[0]}}</div>
            <div v-if="second" class="auction__info-clock-number">{{second.split('')[1]}}</div>
        </div>
        <div v-if="text" class="auction__info-timer-text">{{ text }}</div>
    </div>
</template>
<script>
export default {
    name: 'AuctionTimer',
    props: {
        hour: String,
        minute: String,
        second: String,
        text: String
    }
};
</script>
<style lang="scss" scoped>
.auction__info {
    &-clock {
        display: flex;
        margin-bottom: .55vw;
        &-number {
            background-color: #000;
            padding: .32vw .32vw .48vw;
            line-height: 80%;
            font-weight: 700;
            font-size: 2.05vw;
            border-radius: .15vw;
            &:nth-child(even) {
                margin-left: .17vw;
            }
            &:nth-child(4) {
                margin-left: 0;
                margin-right: .17vw;
            }
        }
        &-divider {
            padding-left: .160vw;
            padding-right: .160vw;
            font-weight: 700;
            font-size: 2.05vw;
            display: flex;
            align-items: end;
            justify-content: center;
            padding-bottom: .4vw;
        }
    }
    &-timer {
        &-text {
            text-transform: uppercase;
            font-family: var(--font-stretch);
            font-weight: 700;
            line-height: 120%;
            opacity: .6;
            font-size: 1vw;
            letter-spacing: 0.06em;
            text-align: center;
            &--no-center {
                text-align: start;
            }
        }
    }
}
</style>
