export const shopItems = [
    { id: 29, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 8, gender: 0, additional: 0, shopType: 'top', index: 40, price: 2000, isDonate: false },
    { id: 29, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 8, gender: 0, additional: 1, shopType: 'top', index: 41, price: 2000, isDonate: false },
    { id: 29, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 8, gender: 0, additional: 2, shopType: 'top', index: 42, price: 2000, isDonate: false },
    { id: 29, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 8, gender: 0, additional: 3, shopType: 'top', index: 43, price: 2000, isDonate: false },
    { id: 29, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 8, gender: 0, additional: 4, shopType: 'top', index: 44, price: 2000, isDonate: false },
    { id: 29, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 8, gender: 0, additional: 5, shopType: 'top', index: 45, price: 2000, isDonate: false },
    { id: 29, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 8, gender: 0, additional: 6, shopType: 'top', index: 46, price: 3000, isDonate: false },
    { id: 29, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 8, gender: 0, additional: 7, shopType: 'top', index: 47, price: 3000, isDonate: false },
    { id: 29, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 8, gender: 0, additional: 8, shopType: 'top', index: 48, price: 3000, isDonate: false },
    { id: 29, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 8, gender: 0, additional: 9, shopType: 'top', index: 49, price: 3000, isDonate: false },

    { id: 30, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 9, gender: 0, additional: 0, shopType: 'top', index: 50, price: 4500, isDonate: false },
    { id: 30, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 9, gender: 0, additional: 1, shopType: 'top', index: 51, price: 4500, isDonate: false },
    { id: 30, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 9, gender: 0, additional: 2, shopType: 'top', index: 52, price: 4500, isDonate: false },
    { id: 30, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 9, gender: 0, additional: 3, shopType: 'top', index: 53, price: 4500, isDonate: false },
    { id: 30, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 9, gender: 0, additional: 4, shopType: 'top', index: 54, price: 4500, isDonate: false },
    { id: 30, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 9, gender: 0, additional: 5, shopType: 'top', index: 55, price: 4500, isDonate: false },
    { id: 30, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 9, gender: 0, additional: 6, shopType: 'top', index: 56, price: 4500, isDonate: false },
    { id: 30, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 9, gender: 0, additional: 7, shopType: 'top', index: 57, price: 4500, isDonate: false },
    { id: 30, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 9, gender: 0, additional: 8, shopType: 'top', index: 58, price: 4500, isDonate: false },

    { id: 31, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 10, gender: 0, additional: 0, shopType: 'top', index: 60, price: 1200, isDonate: false },
    { id: 31, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 10, gender: 0, additional: 1, shopType: 'top', index: 61, price: 1200, isDonate: false },
    { id: 31, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 10, gender: 0, additional: 2, shopType: 'top', index: 62, price: 1200, isDonate: false },
    { id: 31, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 10, gender: 0, additional: 3, shopType: 'top', index: 63, price: 1200, isDonate: false },
    { id: 31, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 10, gender: 0, additional: 4, shopType: 'top', index: 64, price: 1200, isDonate: false },
    { id: 31, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 10, gender: 0, additional: 5, shopType: 'top', index: 65, price: 1200, isDonate: false },
    { id: 31, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 10, gender: 0, additional: 6, shopType: 'top', index: 66, price: 2100, isDonate: false },

    { id: 32, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 11, gender: 0, additional: 0, shopType: 'top', index: 70, price: 2000, isDonate: false },
    { id: 32, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 11, gender: 0, additional: 1, shopType: 'top', index: 71, price: 2000, isDonate: false },
    { id: 32, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 11, gender: 0, additional: 2, shopType: 'top', index: 72, price: 2000, isDonate: false },
    { id: 32, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 11, gender: 0, additional: 6, shopType: 'top', index: 73, price: 2000, isDonate: false },
    { id: 32, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 11, gender: 0, additional: 7, shopType: 'top', index: 74, price: 2000, isDonate: false },
    { id: 32, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 11, gender: 0, additional: 8, shopType: 'top', index: 75, price: 2000, isDonate: false },
    { id: 32, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 11, gender: 0, additional: 9, shopType: 'top', index: 76, price: 2000, isDonate: false },
    { id: 32, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 11, gender: 0, additional: 10, shopType: 'top', index: 77, price: 2300, isDonate: false },
    { id: 32, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 11, gender: 0, additional: 11, shopType: 'top', index: 78, price: 2400, isDonate: false },
    { id: 32, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 11, gender: 0, additional: 12, shopType: 'top', index: 79, price: 2400, isDonate: false },

    { id: 33, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 12, gender: 0, additional: 0, shopType: 'top', index: 80, price: 1800, isDonate: false },
    { id: 33, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 12, gender: 0, additional: 1, shopType: 'top', index: 81, price: 1800, isDonate: false },
    { id: 33, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 12, gender: 0, additional: 2, shopType: 'top', index: 82, price: 1800, isDonate: false },
    { id: 33, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 12, gender: 0, additional: 3, shopType: 'top', index: 83, price: 1800, isDonate: false },
    { id: 33, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 12, gender: 0, additional: 4, shopType: 'top', index: 84, price: 2500, isDonate: false },
    { id: 33, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 12, gender: 0, additional: 5, shopType: 'top', index: 85, price: 3500, isDonate: false },
    { id: 33, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 12, gender: 0, additional: 6, shopType: 'top', index: 86, price: 2000, isDonate: false },

    { id: 34, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 13, gender: 0, additional: 0, shopType: 'top', index: 90, price: 5000, isDonate: false },
    { id: 34, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 13, gender: 0, additional: 1, shopType: 'top', index: 91, price: 5000, isDonate: false },
    { id: 34, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 13, gender: 0, additional: 2, shopType: 'top', index: 92, price: 5000, isDonate: false },
    { id: 34, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 13, gender: 0, additional: 3, shopType: 'top', index: 93, price: 5000, isDonate: false },
    { id: 34, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 13, gender: 0, additional: 4, shopType: 'top', index: 94, price: 5000, isDonate: false },

    { id: 35, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 14, gender: 0, additional: 0, shopType: 'top', index: 100, price: 2500, isDonate: false },
    { id: 35, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 14, gender: 0, additional: 1, shopType: 'top', index: 101, price: 2500, isDonate: false },
    { id: 35, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 14, gender: 0, additional: 2, shopType: 'top', index: 102, price: 2500, isDonate: false },
    { id: 35, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 14, gender: 0, additional: 3, shopType: 'top', index: 103, price: 2500, isDonate: false },
    { id: 35, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 14, gender: 0, additional: 4, shopType: 'top', index: 104, price: 2500, isDonate: false },

    { id: 36, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 15, gender: 0, additional: 0, shopType: 'top', index: 110, price: 700, isDonate: false },
    { id: 36, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 15, gender: 0, additional: 1, shopType: 'top', index: 111, price: 700, isDonate: false },
    { id: 36, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 15, gender: 0, additional: 2, shopType: 'top', index: 112, price: 700, isDonate: false },
    { id: 36, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 15, gender: 0, additional: 3, shopType: 'top', index: 113, price: 700, isDonate: false },
    { id: 36, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 15, gender: 0, additional: 4, shopType: 'top', index: 114, price: 700, isDonate: false },

    // { id: 37, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 16, gender: 0, additional: 0, shopType: 'top', index: 120, price: 0, isDonate: false }, bp
    { id: 38, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 17, gender: 0, additional: 0, shopType: 'top', index: 130, price: 3000, isDonate: false },
    { id: 38, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 17, gender: 0, additional: 1, shopType: 'top', index: 131, price: 3000, isDonate: false },
    { id: 38, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 17, gender: 0, additional: 2, shopType: 'top', index: 132, price: 3000, isDonate: false },
    { id: 38, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 17, gender: 0, additional: 3, shopType: 'top', index: 133, price: 3000, isDonate: false },
    { id: 38, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 17, gender: 0, additional: 4, shopType: 'top', index: 134, price: 3000, isDonate: false },
    { id: 38, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 17, gender: 0, additional: 5, shopType: 'top', index: 135, price: 3000, isDonate: false },
    { id: 38, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 17, gender: 0, additional: 6, shopType: 'top', index: 136, price: 3000, isDonate: false },
    { id: 38, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 17, gender: 0, additional: 7, shopType: 'top', index: 137, price: 3000, isDonate: false },
    { id: 38, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 17, gender: 0, additional: 8, shopType: 'top', index: 138, price: 3000, isDonate: false },

    { id: 39, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 18, gender: 0, additional: 0, shopType: 'top', index: 140, price: 3500, isDonate: false },
    { id: 39, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 18, gender: 0, additional: 1, shopType: 'top', index: 141, price: 3500, isDonate: false },
    { id: 39, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 18, gender: 0, additional: 2, shopType: 'top', index: 142, price: 3500, isDonate: false },
    { id: 39, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 18, gender: 0, additional: 3, shopType: 'top', index: 143, price: 3500, isDonate: false },
    { id: 39, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 18, gender: 0, additional: 4, shopType: 'top', index: 144, price: 3500, isDonate: false },
    { id: 39, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 18, gender: 0, additional: 5, shopType: 'top', index: 145, price: 3500, isDonate: false },
    { id: 39, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 18, gender: 0, additional: 6, shopType: 'top', index: 146, price: 3500, isDonate: false },
    { id: 39, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 18, gender: 0, additional: 7, shopType: 'top', index: 147, price: 3500, isDonate: false },
    { id: 39, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 18, gender: 0, additional: 8, shopType: 'top', index: 148, price: 3500, isDonate: false },

    { id: 40, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 19, gender: 0, additional: 0, shopType: 'top', index: 150, price: 200, isDonate: true },
    { id: 40, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 19, gender: 0, additional: 3, shopType: 'top', index: 151, price: 200, isDonate: true },
    { id: 40, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 19, gender: 0, additional: 4, shopType: 'top', index: 152, price: 200, isDonate: true },
    { id: 40, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 19, gender: 0, additional: 5, shopType: 'top', index: 153, price: 200, isDonate: true },
    { id: 40, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 19, gender: 0, additional: 6, shopType: 'top', index: 154, price: 300, isDonate: true },
    { id: 40, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 19, gender: 0, additional: 7, shopType: 'top', index: 155, price: 300, isDonate: true },
    { id: 40, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 19, gender: 0, additional: 8, shopType: 'top', index: 156, price: 300, isDonate: true },
    { id: 40, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 19, gender: 0, additional: 9, shopType: 'top', index: 157, price: 300, isDonate: true },
    { id: 40, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 19, gender: 0, additional: 10, shopType: 'top', index: 158, price: 300, isDonate: true },
    { id: 40, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 19, gender: 0, additional: 11, shopType: 'top', index: 159, price: 300, isDonate: true },

    { id: 41, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 20, gender: 0, additional: 0, shopType: 'top', index: 160, price: 3000, isDonate: false },
    { id: 41, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 20, gender: 0, additional: 1, shopType: 'top', index: 161, price: 3000, isDonate: false },
    { id: 41, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 20, gender: 0, additional: 2, shopType: 'top', index: 162, price: 3000, isDonate: false },
    { id: 41, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 20, gender: 0, additional: 3, shopType: 'top', index: 163, price: 3000, isDonate: false },
    { id: 41, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 20, gender: 0, additional: 4, shopType: 'top', index: 164, price: 3000, isDonate: false },
    { id: 41, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 20, gender: 0, additional: 5, shopType: 'top', index: 165, price: 3000, isDonate: false },
    { id: 41, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 20, gender: 0, additional: 6, shopType: 'top', index: 166, price: 3000, isDonate: false },
    { id: 41, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 20, gender: 0, additional: 7, shopType: 'top', index: 167, price: 3000, isDonate: false },
    { id: 41, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 20, gender: 0, additional: 8, shopType: 'top', index: 168, price: 3000, isDonate: false },
    { id: 41, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 20, gender: 0, additional: 9, shopType: 'top', index: 169, price: 3000, isDonate: false },

    { id: 42, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 21, gender: 0, additional: 0, shopType: 'top', index: 170, price: 100, isDonate: true },
    { id: 42, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 21, gender: 0, additional: 1, shopType: 'top', index: 171, price: 100, isDonate: true },
    { id: 42, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 21, gender: 0, additional: 2, shopType: 'top', index: 172, price: 100, isDonate: true },
    { id: 42, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 21, gender: 0, additional: 3, shopType: 'top', index: 173, price: 100, isDonate: true },
    { id: 42, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 21, gender: 0, additional: 4, shopType: 'top', index: 174, price: 100, isDonate: true },
    { id: 42, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 21, gender: 0, additional: 5, shopType: 'top', index: 175, price: 100, isDonate: true },
    { id: 42, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 21, gender: 0, additional: 6, shopType: 'top', index: 176, price: 300, isDonate: true },
    { id: 42, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 21, gender: 0, additional: 7, shopType: 'top', index: 177, price: 300, isDonate: true },
    { id: 42, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 21, gender: 0, additional: 8, shopType: 'top', index: 178, price: 300, isDonate: true },
    { id: 42, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 21, gender: 0, additional: 9, shopType: 'top', index: 179, price: 300, isDonate: true },

    { id: 43, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 22, gender: 0, additional: 0, shopType: 'top', index: 180, price: 1500, isDonate: false },
    { id: 43, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 22, gender: 0, additional: 1, shopType: 'top', index: 181, price: 1500, isDonate: false },
    { id: 43, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 22, gender: 0, additional: 2, shopType: 'top', index: 182, price: 1500, isDonate: false },
    { id: 43, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 22, gender: 0, additional: 3, shopType: 'top', index: 183, price: 1500, isDonate: false },
    { id: 43, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 22, gender: 0, additional: 4, shopType: 'top', index: 184, price: 1500, isDonate: false },
    { id: 43, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 22, gender: 0, additional: 5, shopType: 'top', index: 185, price: 1500, isDonate: false },
    { id: 43, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 22, gender: 0, additional: 6, shopType: 'top', index: 186, price: 1500, isDonate: false },
    { id: 43, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 22, gender: 0, additional: 7, shopType: 'top', index: 187, price: 1500, isDonate: false },
    { id: 43, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 22, gender: 0, additional: 8, shopType: 'top', index: 188, price: 1500, isDonate: false },

    { id: 44, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 23, gender: 0, additional: 0, shopType: 'top', index: 190, price: 3000, isDonate: false },
    { id: 44, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 23, gender: 0, additional: 1, shopType: 'top', index: 191, price: 3000, isDonate: false },
    { id: 44, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 23, gender: 0, additional: 2, shopType: 'top', index: 192, price: 3000, isDonate: false },
    { id: 44, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 23, gender: 0, additional: 3, shopType: 'top', index: 193, price: 3000, isDonate: false },
    { id: 44, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 23, gender: 0, additional: 4, shopType: 'top', index: 194, price: 3000, isDonate: false },
    { id: 44, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 23, gender: 0, additional: 5, shopType: 'top', index: 195, price: 3000, isDonate: false },
    { id: 44, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 23, gender: 0, additional: 6, shopType: 'top', index: 196, price: 3000, isDonate: false },
    { id: 44, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 23, gender: 0, additional: 7, shopType: 'top', index: 197, price: 5000, isDonate: false },
    { id: 44, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 23, gender: 0, additional: 8, shopType: 'top', index: 198, price: 5000, isDonate: false },
    { id: 44, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 23, gender: 0, additional: 9, shopType: 'top', index: 199, price: 3000, isDonate: false },
    // { id: 45, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 24, gender: 0, additional: 2 and 4, shopType: 'top', index: 200, price: 0, isDonate: false }, <<- BP
    { id: 45, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 24, gender: 0, additional: 1, shopType: 'top', index: 200, price: 150, isDonate: true },
    { id: 45, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 24, gender: 0, additional: 3, shopType: 'top', index: 201, price: 150, isDonate: true },
    { id: 45, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 24, gender: 0, additional: 5, shopType: 'top', index: 202, price: 150, isDonate: true },
    { id: 45, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 24, gender: 0, additional: 6, shopType: 'top', index: 203, price: 400, isDonate: true },
    { id: 45, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 24, gender: 0, additional: 7, shopType: 'top', index: 204, price: 400, isDonate: true },
    { id: 45, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 24, gender: 0, additional: 8, shopType: 'top', index: 205, price: 400, isDonate: true },
    { id: 45, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 24, gender: 0, additional: 9, shopType: 'top', index: 206, price: 400, isDonate: true },
    { id: 45, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 24, gender: 0, additional: 10, shopType: 'top', index: 207, price: 400, isDonate: true },
    { id: 45, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 24, gender: 0, additional: 11, shopType: 'top', index: 208, price: 400, isDonate: true },
    { id: 45, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 24, gender: 0, additional: 12, shopType: 'top', index: 209, price: 400, isDonate: true },

    // { id: 46, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 25, gender: 0, additional: 0, shopType: 'top', index: 210, price: 0, isDonate: false }, police
    // { id: 47, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 26, gender: 0, additional: 0, shopType: 'top', index: 220, price: 0, isDonate: false }, medical
    // { id: 48, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 27, gender: 0, additional: 0, shopType: 'top', index: 230, price: 0, isDonate: false }, medical
    // { id: 49, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 28, gender: 0, additional: 0, shopType: 'top', index: 240, price: 0, isDonate: false }, BP
    { id: 49, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 28, gender: 0, additional: 0, shopType: 'top', index: 240, price: 100, isDonate: true },
    { id: 49, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 28, gender: 0, additional: 1, shopType: 'top', index: 241, price: 100, isDonate: true },
    { id: 49, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 28, gender: 0, additional: 2, shopType: 'top', index: 242, price: 100, isDonate: true },
    { id: 49, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 28, gender: 0, additional: 3, shopType: 'top', index: 243, price: 100, isDonate: true },
    { id: 49, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 28, gender: 0, additional: 4, shopType: 'top', index: 244, price: 100, isDonate: true },
    { id: 49, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 28, gender: 0, additional: 6, shopType: 'top', index: 245, price: 200, isDonate: true },
    { id: 49, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 28, gender: 0, additional: 7, shopType: 'top', index: 246, price: 200, isDonate: true },
    { id: 49, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 28, gender: 0, additional: 8, shopType: 'top', index: 247, price: 200, isDonate: true },
    { id: 49, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 28, gender: 0, additional: 9, shopType: 'top', index: 248, price: 200, isDonate: true },
    { id: 49, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 28, gender: 0, additional: 10, shopType: 'top', index: 249, price: 250, isDonate: true },

    { id: 50, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 29, gender: 0, additional: 0, shopType: 'top', index: 250, price: 5000, isDonate: false },
    { id: 50, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 29, gender: 0, additional: 1, shopType: 'top', index: 251, price: 5000, isDonate: false },
    { id: 50, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 29, gender: 0, additional: 2, shopType: 'top', index: 252, price: 5000, isDonate: false },
    { id: 50, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 29, gender: 0, additional: 3, shopType: 'top', index: 253, price: 5000, isDonate: false },
    { id: 50, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 29, gender: 0, additional: 4, shopType: 'top', index: 254, price: 5000, isDonate: false },

    { id: 51, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 30, gender: 0, additional: 0, shopType: 'top', index: 260, price: 1800, isDonate: false },
    { id: 51, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 30, gender: 0, additional: 1, shopType: 'top', index: 261, price: 1800, isDonate: false },
    { id: 51, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 30, gender: 0, additional: 2, shopType: 'top', index: 262, price: 1800, isDonate: false },
    { id: 51, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 30, gender: 0, additional: 3, shopType: 'top', index: 263, price: 1800, isDonate: false },
    { id: 51, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 30, gender: 0, additional: 4, shopType: 'top', index: 264, price: 1800, isDonate: false },
    { id: 51, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 30, gender: 0, additional: 5, shopType: 'top', index: 265, price: 3000, isDonate: false },

    { id: 52, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 31, gender: 0, additional: 0, shopType: 'top', index: 270, price: 2000, isDonate: false },
    { id: 52, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 31, gender: 0, additional: 1, shopType: 'top', index: 271, price: 2000, isDonate: false },
    { id: 52, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 31, gender: 0, additional: 2, shopType: 'top', index: 272, price: 2000, isDonate: false },
    { id: 52, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 31, gender: 0, additional: 3, shopType: 'top', index: 273, price: 2000, isDonate: false },
    { id: 52, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 31, gender: 0, additional: 4, shopType: 'top', index: 274, price: 2000, isDonate: false },

    { id: 53, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 32, gender: 0, additional: 0, shopType: 'top', index: 280, price: 5000, isDonate: false },
    { id: 53, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 32, gender: 0, additional: 1, shopType: 'top', index: 281, price: 5000, isDonate: false },
    { id: 53, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 32, gender: 0, additional: 2, shopType: 'top', index: 282, price: 5000, isDonate: false },
    { id: 53, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 32, gender: 0, additional: 3, shopType: 'top', index: 283, price: 5000, isDonate: false },
    { id: 53, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 32, gender: 0, additional: 4, shopType: 'top', index: 284, price: 5000, isDonate: false },
    { id: 53, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 32, gender: 0, additional: 5, shopType: 'top', index: 285, price: 5000, isDonate: false },
    { id: 53, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 32, gender: 0, additional: 6, shopType: 'top', index: 286, price: 5000, isDonate: false },

    // { id: 54, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 33, gender: 0, additional: 0, shopType: 'top', index: 290, price: 0, isDonate: false }, army
    // { id: 55, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 34, gender: 0, additional: 0, shopType: 'top', index: 300, price: 0, isDonate: false }, fib
    // { id: 56, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 35, gender: 0, additional: 0, shopType: 'top', index: 310, price: 0, isDonate: false }, BP
    { id: 56, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 35, gender: 0, additional: 1, shopType: 'top', index: 310, price: 600, isDonate: false },
    { id: 56, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 35, gender: 0, additional: 2, shopType: 'top', index: 311, price: 600, isDonate: false },
    { id: 56, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 35, gender: 0, additional: 3, shopType: 'top', index: 312, price: 600, isDonate: false },
    { id: 56, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 35, gender: 0, additional: 4, shopType: 'top', index: 313, price: 600, isDonate: false },

    // { id: 57, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 36, gender: 0, additional: 0, shopType: 'top', index: 320, price: 0, isDonate: true }, no donate
    // { id: 57, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 36, gender: 0, additional: 2, shopType: 'top', index: 321, price: 0, isDonate: true }, no donate
    // { id: 57, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 36, gender: 0, additional: 3, shopType: 'top', index: 322, price: 0, isDonate: true }, no donate
    // { id: 57, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 36, gender: 0, additional: 4, shopType: 'top', index: 323, price: 0, isDonate: true }, no donate
    { id: 57, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 36, gender: 0, additional: 5, shopType: 'top', index: 324, price: 100, isDonate: true },
    { id: 57, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 36, gender: 0, additional: 6, shopType: 'top', index: 325, price: 200, isDonate: true },
    { id: 57, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 36, gender: 0, additional: 7, shopType: 'top', index: 326, price: 280, isDonate: true },
    { id: 57, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 36, gender: 0, additional: 8, shopType: 'top', index: 327, price: 300, isDonate: true },
    { id: 57, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 36, gender: 0, additional: 9, shopType: 'top', index: 328, price: 100, isDonate: true },
    { id: 57, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 36, gender: 0, additional: 10, shopType: 'top', index: 329, price: 300, isDonate: true },

    // { id: 58, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 37, gender: 0, additional: 0, shopType: 'top', index: 330, price: 500, isDonate: false }, fib

    { id: 59, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 38, gender: 0, additional: 0, shopType: 'top', index: 340, price: 800, isDonate: false },
    { id: 59, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 38, gender: 0, additional: 1, shopType: 'top', index: 341, price: 800, isDonate: false },
    { id: 59, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 38, gender: 0, additional: 2, shopType: 'top', index: 342, price: 800, isDonate: false },
    { id: 59, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 38, gender: 0, additional: 3, shopType: 'top', index: 343, price: 800, isDonate: false },
    { id: 59, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 38, gender: 0, additional: 4, shopType: 'top', index: 344, price: 800, isDonate: false },
    { id: 59, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 38, gender: 0, additional: 5, shopType: 'top', index: 345, price: 1200, isDonate: false },

    { id: 60, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 39, gender: 0, additional: 0, shopType: 'top', index: 350, price: 6000, isDonate: false },
    { id: 60, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 39, gender: 0, additional: 1, shopType: 'top', index: 351, price: 6000, isDonate: false },
    { id: 60, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 39, gender: 0, additional: 2, shopType: 'top', index: 352, price: 6000, isDonate: false },
    { id: 60, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 39, gender: 0, additional: 3, shopType: 'top', index: 353, price: 6000, isDonate: false },
    { id: 60, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 39, gender: 0, additional: 4, shopType: 'top', index: 354, price: 6000, isDonate: false },
    { id: 60, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 39, gender: 0, additional: 5, shopType: 'top', index: 355, price: 6000, isDonate: false },

    // { id: 61, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 40, gender: 0, additional: 0, shopType: 'top', index: 360, price: 0, isDonate: false }, fire departament
    // { id: 62, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 41, gender: 0, additional: 0, shopType: 'top', index: 370, price: 0, isDonate: false }, medic

    { id: 63, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 42, gender: 0, additional: 0, shopType: 'top', index: 380, price: 2800, isDonate: false },
    { id: 63, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 42, gender: 0, additional: 1, shopType: 'top', index: 381, price: 2800, isDonate: false },
    { id: 63, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 42, gender: 0, additional: 2, shopType: 'top', index: 382, price: 2800, isDonate: false },
    { id: 63, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 42, gender: 0, additional: 3, shopType: 'top', index: 383, price: 2800, isDonate: false },
    { id: 63, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 42, gender: 0, additional: 4, shopType: 'top', index: 384, price: 2800, isDonate: false },
    { id: 63, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 42, gender: 0, additional: 5, shopType: 'top', index: 385, price: 2800, isDonate: false },
    { id: 63, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 42, gender: 0, additional: 6, shopType: 'top', index: 386, price: 3200, isDonate: false },

    { id: 64, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 43, gender: 0, additional: 0, shopType: 'top', index: 390, price: 1450, isDonate: false },
    { id: 64, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 43, gender: 0, additional: 1, shopType: 'top', index: 391, price: 1450, isDonate: false },
    { id: 64, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 43, gender: 0, additional: 2, shopType: 'top', index: 392, price: 1450, isDonate: false },
    { id: 64, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 43, gender: 0, additional: 3, shopType: 'top', index: 393, price: 1450, isDonate: false },
    { id: 64, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 43, gender: 0, additional: 4, shopType: 'top', index: 394, price: 1450, isDonate: false },
    { id: 64, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 43, gender: 0, additional: 5, shopType: 'top', index: 395, price: 1450, isDonate: false },

    // { id: 65, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 44, gender: 0, additional: 0, shopType: 'top', index: 400, price: 0, isDonate: false }, medic
    // { id: 66, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 45, gender: 0, additional: 0, shopType: 'top', index: 410, price: 0, isDonate: false }, army
    { id: 67, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 46, gender: 0, additional: 0, shopType: 'top', index: 420, price: 4200, isDonate: false },
    { id: 67, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 46, gender: 0, additional: 1, shopType: 'top', index: 421, price: 4200, isDonate: false },
    { id: 67, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 46, gender: 0, additional: 2, shopType: 'top', index: 422, price: 4200, isDonate: false },
    { id: 67, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 46, gender: 0, additional: 3, shopType: 'top', index: 423, price: 4200, isDonate: false },
    { id: 67, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 46, gender: 0, additional: 4, shopType: 'top', index: 424, price: 4200, isDonate: false },
    { id: 67, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 46, gender: 0, additional: 5, shopType: 'top', index: 425, price: 4200, isDonate: false },
    { id: 67, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 46, gender: 0, additional: 6, shopType: 'top', index: 426, price: 4200, isDonate: false },

    // { id: 68, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 47, gender: 0, additional: 0, shopType: 'top', index: 430, price: 0, isDonate: false }, medic jetix

    { id: 69, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 48, gender: 0, additional: 0, shopType: 'top', index: 440, price: 2800, isDonate: false },
    { id: 69, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 48, gender: 0, additional: 1, shopType: 'top', index: 441, price: 2800, isDonate: false },
    { id: 69, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 48, gender: 0, additional: 2, shopType: 'top', index: 442, price: 2800, isDonate: false },
    { id: 69, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 48, gender: 0, additional: 3, shopType: 'top', index: 443, price: 2800, isDonate: false },
    { id: 69, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 48, gender: 0, additional: 4, shopType: 'top', index: 444, price: 2800, isDonate: false },
    { id: 69, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 48, gender: 0, additional: 5, shopType: 'top', index: 445, price: 2800, isDonate: false },
    { id: 69, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 48, gender: 0, additional: 6, shopType: 'top', index: 446, price: 3200, isDonate: false },

    // { id: 70, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 49, gender: 0, additional: 0, shopType: 'top', index: 450, price: 0, isDonate: false }, medic jetix

    { id: 71, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 50, gender: 0, additional: 2, shopType: 'top', index: 460, price: 2500, isDonate: false },
    { id: 71, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 50, gender: 0, additional: 4, shopType: 'top', index: 461, price: 2500, isDonate: false },
    { id: 71, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 50, gender: 0, additional: 5, shopType: 'top', index: 462, price: 2500, isDonate: false },
    { id: 71, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 50, gender: 0, additional: 6, shopType: 'top', index: 463, price: 5000, isDonate: false },
    { id: 71, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 50, gender: 0, additional: 7, shopType: 'top', index: 464, price: 5000, isDonate: false },
    { id: 71, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 50, gender: 0, additional: 8, shopType: 'top', index: 465, price: 5000, isDonate: false },
    { id: 71, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 50, gender: 0, additional: 9, shopType: 'top', index: 466, price: 5000, isDonate: false },
    { id: 71, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 50, gender: 0, additional: 10, shopType: 'top', index: 467, price: 5000, isDonate: false },
    { id: 71, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 50, gender: 0, additional: 11, shopType: 'top', index: 468, price: 5000, isDonate: false },
    { id: 71, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 50, gender: 0, additional: 12, shopType: 'top', index: 469, price: 5000, isDonate: false },

    // { id: 72, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 51, gender: 0, additional: 0, shopType: 'top', index: 470, price: 0, isDonate: false }, no donate
    // { id: 72, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 51, gender: 0, additional: 1, shopType: 'top', index: 471, price: 0, isDonate: false },
    // { id: 72, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 51, gender: 0, additional: 3, shopType: 'top', index: 472, price: 0, isDonate: false },
    // { id: 72, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 51, gender: 0, additional: 4, shopType: 'top', index: 473, price: 0, isDonate: false },
    { id: 72, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 51, gender: 0, additional: 5, shopType: 'top', index: 474, price: 150, isDonate: true },
    { id: 72, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 51, gender: 0, additional: 6, shopType: 'top', index: 475, price: 200, isDonate: true },
    { id: 72, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 51, gender: 0, additional: 7, shopType: 'top', index: 476, price: 200, isDonate: true },
    { id: 72, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 51, gender: 0, additional: 8, shopType: 'top', index: 477, price: 450, isDonate: true },
    { id: 72, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 51, gender: 0, additional: 9, shopType: 'top', index: 478, price: 400, isDonate: true },
    { id: 72, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 51, gender: 0, additional: 10, shopType: 'top', index: 479, price: 400, isDonate: true },

    { id: 73, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 52, gender: 0, additional: 0, shopType: 'top', index: 480, price: 2100, isDonate: false },
    { id: 73, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 52, gender: 0, additional: 2, shopType: 'top', index: 481, price: 2100, isDonate: false },
    { id: 73, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 52, gender: 0, additional: 3, shopType: 'top', index: 482, price: 2100, isDonate: false },
    { id: 73, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 52, gender: 0, additional: 5, shopType: 'top', index: 483, price: 2100, isDonate: false },
    { id: 73, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 52, gender: 0, additional: 6, shopType: 'top', index: 484, price: 2100, isDonate: false },
    { id: 73, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 52, gender: 0, additional: 7, shopType: 'top', index: 485, price: 2100, isDonate: false },
    { id: 73, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 52, gender: 0, additional: 9, shopType: 'top', index: 486, price: 2100, isDonate: false },
    { id: 73, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 52, gender: 0, additional: 11, shopType: 'top', index: 487, price: 3000, isDonate: false },
    { id: 73, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 52, gender: 0, additional: 14, shopType: 'top', index: 488, price: 3000, isDonate: false },
    { id: 73, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 52, gender: 0, additional: 15, shopType: 'top', index: 489, price: 3000, isDonate: false },

    // { id: 74, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 53, gender: 0, additional: 0, shopType: 'top', index: 490, price: 0, isDonate: false }, fib jetix

    { id: 75, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 54, gender: 0, additional: 0, shopType: 'top', index: 500, price: 3000, isDonate: false },
    { id: 75, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 54, gender: 0, additional: 1, shopType: 'top', index: 501, price: 3000, isDonate: false },
    { id: 75, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 54, gender: 0, additional: 2, shopType: 'top', index: 502, price: 3000, isDonate: false },
    { id: 75, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 54, gender: 0, additional: 3, shopType: 'top', index: 503, price: 3000, isDonate: false },
    { id: 75, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 54, gender: 0, additional: 4, shopType: 'top', index: 504, price: 3000, isDonate: false },
    { id: 75, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 54, gender: 0, additional: 5, shopType: 'top', index: 505, price: 4500, isDonate: false },
    { id: 75, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 54, gender: 0, additional: 6, shopType: 'top', index: 506, price: 4500, isDonate: false },

    { id: 76, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 55, gender: 0, additional: 0, shopType: 'top', index: 510, price: 3500, isDonate: false },
    { id: 76, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 55, gender: 0, additional: 1, shopType: 'top', index: 511, price: 3500, isDonate: false },
    { id: 76, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 55, gender: 0, additional: 3, shopType: 'top', index: 512, price: 3500, isDonate: false },
    { id: 76, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 55, gender: 0, additional: 4, shopType: 'top', index: 513, price: 3500, isDonate: false },
    { id: 76, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 55, gender: 0, additional: 5, shopType: 'top', index: 514, price: 3500, isDonate: false },
    { id: 76, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 55, gender: 0, additional: 6, shopType: 'top', index: 515, price: 4000, isDonate: false },
    { id: 76, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 55, gender: 0, additional: 7, shopType: 'top', index: 516, price: 5000, isDonate: false },
    { id: 76, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 55, gender: 0, additional: 8, shopType: 'top', index: 517, price: 5000, isDonate: false },
    { id: 76, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 55, gender: 0, additional: 9, shopType: 'top', index: 518, price: 5000, isDonate: false },
    { id: 76, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 55, gender: 0, additional: 10, shopType: 'top', index: 519, price: 5000, isDonate: false },

    // { id: 77, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 56, gender: 0, additional: 0, shopType: 'top', index: 520, price: 0, isDonate: false }, police
    { id: 78, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 57, gender: 0, additional: 0, shopType: 'top', index: 530, price: 500, isDonate: false },
    { id: 78, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 57, gender: 0, additional: 1, shopType: 'top', index: 531, price: 500, isDonate: false },
    { id: 78, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 57, gender: 0, additional: 2, shopType: 'top', index: 532, price: 500, isDonate: false },
    { id: 78, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 57, gender: 0, additional: 3, shopType: 'top', index: 533, price: 500, isDonate: false },
    { id: 78, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 57, gender: 0, additional: 4, shopType: 'top', index: 534, price: 500, isDonate: false },
    { id: 78, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 57, gender: 0, additional: 5, shopType: 'top', index: 535, price: 1500, isDonate: false },
    { id: 78, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 57, gender: 0, additional: 6, shopType: 'top', index: 536, price: 1500, isDonate: false },
    { id: 78, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 57, gender: 0, additional: 7, shopType: 'top', index: 537, price: 1500, isDonate: false },

    // { id: 79, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 58, gender: 0, additional: 0, shopType: 'top', index: 540, price: 0, isDonate: false },
    { id: 80, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 59, gender: 0, additional: 0, shopType: 'top', index: 550, price: 5500, isDonate: false },
    { id: 80, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 59, gender: 0, additional: 1, shopType: 'top', index: 551, price: 5500, isDonate: false },
    { id: 80, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 59, gender: 0, additional: 2, shopType: 'top', index: 552, price: 5500, isDonate: false },
    { id: 80, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 59, gender: 0, additional: 3, shopType: 'top', index: 553, price: 5500, isDonate: false },
    { id: 80, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 59, gender: 0, additional: 4, shopType: 'top', index: 554, price: 5500, isDonate: false },

    { id: 81, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 60, gender: 0, additional: 0, shopType: 'top', index: 560, price: 2000, isDonate: false },
    { id: 81, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 60, gender: 0, additional: 1, shopType: 'top', index: 561, price: 2000, isDonate: false },
    { id: 81, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 60, gender: 0, additional: 2, shopType: 'top', index: 562, price: 2000, isDonate: false },
    { id: 81, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 60, gender: 0, additional: 3, shopType: 'top', index: 563, price: 2000, isDonate: false },
    { id: 81, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 60, gender: 0, additional: 4, shopType: 'top', index: 564, price: 2000, isDonate: false },
    { id: 81, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 60, gender: 0, additional: 5, shopType: 'top', index: 565, price: 2000, isDonate: false },
    { id: 81, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 60, gender: 0, additional: 6, shopType: 'top', index: 566, price: 6000, isDonate: false },
    { id: 81, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 60, gender: 0, additional: 7, shopType: 'top', index: 567, price: 6000, isDonate: false },

    { id: 82, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 61, gender: 0, additional: 0, shopType: 'top', index: 570, price: 1800, isDonate: false },
    { id: 82, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 61, gender: 0, additional: 1, shopType: 'top', index: 571, price: 1800, isDonate: false },
    { id: 82, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 61, gender: 0, additional: 2, shopType: 'top', index: 572, price: 1800, isDonate: false },
    { id: 82, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 61, gender: 0, additional: 3, shopType: 'top', index: 573, price: 1800, isDonate: false },
    { id: 82, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 61, gender: 0, additional: 4, shopType: 'top', index: 574, price: 1800, isDonate: false },

    { id: 83, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 62, gender: 0, additional: 0, shopType: 'top', index: 580, price: 4500, isDonate: false },
    { id: 83, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 62, gender: 0, additional: 1, shopType: 'top', index: 581, price: 4500, isDonate: false },
    { id: 83, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 62, gender: 0, additional: 2, shopType: 'top', index: 582, price: 4500, isDonate: false },
    { id: 83, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 62, gender: 0, additional: 3, shopType: 'top', index: 583, price: 4500, isDonate: false },
    { id: 83, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 62, gender: 0, additional: 4, shopType: 'top', index: 584, price: 4500, isDonate: false },

    // { id: 84, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 63, gender: 0, additional: 0, shopType: 'top', index: 590, price: 0, isDonate: false }, army

    { id: 85, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 64, gender: 0, additional: 0, shopType: 'top', index: 600, price: 1400, isDonate: false },
    { id: 85, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 64, gender: 0, additional: 1, shopType: 'top', index: 601, price: 1400, isDonate: false },
    { id: 85, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 64, gender: 0, additional: 2, shopType: 'top', index: 602, price: 1400, isDonate: false },
    { id: 85, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 64, gender: 0, additional: 3, shopType: 'top', index: 603, price: 1400, isDonate: false },
    { id: 85, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 64, gender: 0, additional: 4, shopType: 'top', index: 604, price: 1400, isDonate: false },

    // { id: 86, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 65, gender: 0, additional: 0, shopType: 'top', index: 610, price: 0, isDonate: false }, Bus driver

    { id: 87, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 66, gender: 0, additional: 0, shopType: 'top', index: 620, price: 4700, isDonate: false },
    { id: 87, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 66, gender: 0, additional: 1, shopType: 'top', index: 621, price: 4700, isDonate: false },
    { id: 87, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 66, gender: 0, additional: 2, shopType: 'top', index: 622, price: 4700, isDonate: false },
    { id: 87, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 66, gender: 0, additional: 3, shopType: 'top', index: 623, price: 4700, isDonate: false },
    { id: 87, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 66, gender: 0, additional: 4, shopType: 'top', index: 624, price: 4700, isDonate: false },

    { id: 88, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 67, gender: 0, additional: 0, shopType: 'top', index: 630, price: 1300, isDonate: false },
    { id: 88, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 67, gender: 0, additional: 1, shopType: 'top', index: 631, price: 1300, isDonate: false },
    { id: 88, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 67, gender: 0, additional: 2, shopType: 'top', index: 632, price: 1300, isDonate: false },
    { id: 88, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 67, gender: 0, additional: 3, shopType: 'top', index: 633, price: 1300, isDonate: false },
    { id: 88, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 67, gender: 0, additional: 4, shopType: 'top', index: 634, price: 1300, isDonate: false },

    { id: 89, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 68, gender: 0, additional: 0, shopType: 'top', index: 640, price: 1200, isDonate: false },
    { id: 89, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 68, gender: 0, additional: 1, shopType: 'top', index: 641, price: 1200, isDonate: false },
    { id: 89, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 68, gender: 0, additional: 2, shopType: 'top', index: 642, price: 1200, isDonate: false },
    { id: 89, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 68, gender: 0, additional: 3, shopType: 'top', index: 643, price: 1200, isDonate: false },
    { id: 89, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 68, gender: 0, additional: 4, shopType: 'top', index: 644, price: 1200, isDonate: false },

    // { id: 90, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 69, gender: 0, additional: 0, shopType: 'top', index: 650, price: 0, isDonate: false }, army +-
    { id: 91, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 70, gender: 0, additional: 0, shopType: 'top', index: 660, price: 500, isDonate: false },
    { id: 91, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 70, gender: 0, additional: 1, shopType: 'top', index: 661, price: 500, isDonate: false },
    { id: 91, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 70, gender: 0, additional: 2, shopType: 'top', index: 662, price: 500, isDonate: false },
    { id: 91, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 70, gender: 0, additional: 3, shopType: 'top', index: 663, price: 500, isDonate: false },
    { id: 91, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 70, gender: 0, additional: 4, shopType: 'top', index: 664, price: 500, isDonate: false },

    // { id: 92, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 71, gender: 0, additional: 0, shopType: 'top', index: 670, price: 0, isDonate: false }, police
    { id: 93, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 72, gender: 0, additional: 0, shopType: 'top', index: 680, price: 200, isDonate: true },
    { id: 93, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 72, gender: 0, additional: 1, shopType: 'top', index: 681, price: 200, isDonate: true },
    { id: 93, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 72, gender: 0, additional: 2, shopType: 'top', index: 682, price: 200, isDonate: true },
    { id: 93, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 72, gender: 0, additional: 3, shopType: 'top', index: 683, price: 200, isDonate: true },
    { id: 93, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 72, gender: 0, additional: 4, shopType: 'top', index: 684, price: 200, isDonate: true },
    { id: 93, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 72, gender: 0, additional: 5, shopType: 'top', index: 685, price: 250, isDonate: true },

    { id: 94, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 73, gender: 0, additional: 0, shopType: 'top', index: 690, price: 2000, isDonate: false },
    { id: 94, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 73, gender: 0, additional: 1, shopType: 'top', index: 691, price: 2000, isDonate: false },
    { id: 94, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 73, gender: 0, additional: 2, shopType: 'top', index: 692, price: 2000, isDonate: false },
    { id: 94, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 73, gender: 0, additional: 3, shopType: 'top', index: 693, price: 2000, isDonate: false },
    { id: 94, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 73, gender: 0, additional: 4, shopType: 'top', index: 694, price: 2000, isDonate: false },

    { id: 95, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 74, gender: 0, additional: 0, shopType: 'top', index: 700, price: 200, isDonate: true },
    { id: 95, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 74, gender: 0, additional: 1, shopType: 'top', index: 701, price: 200, isDonate: true },
    { id: 95, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 74, gender: 0, additional: 2, shopType: 'top', index: 702, price: 200, isDonate: true },
    { id: 95, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 74, gender: 0, additional: 3, shopType: 'top', index: 703, price: 200, isDonate: true },
    { id: 95, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 74, gender: 0, additional: 4, shopType: 'top', index: 704, price: 200, isDonate: true },
    { id: 95, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 74, gender: 0, additional: 5, shopType: 'top', index: 705, price: 250, isDonate: true },

    { id: 96, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 75, gender: 0, additional: 0, shopType: 'top', index: 710, price: 2400, isDonate: false },
    { id: 96, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 75, gender: 0, additional: 1, shopType: 'top', index: 711, price: 2400, isDonate: false },
    { id: 96, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 75, gender: 0, additional: 2, shopType: 'top', index: 712, price: 2400, isDonate: false },
    { id: 96, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 75, gender: 0, additional: 3, shopType: 'top', index: 713, price: 2400, isDonate: false },
    { id: 96, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 75, gender: 0, additional: 4, shopType: 'top', index: 714, price: 2400, isDonate: false },

    { id: 97, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 76, gender: 0, additional: 0, shopType: 'top', index: 720, price: 750, isDonate: false },
    { id: 97, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 76, gender: 0, additional: 1, shopType: 'top', index: 721, price: 750, isDonate: false },
    { id: 97, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 76, gender: 0, additional: 2, shopType: 'top', index: 722, price: 750, isDonate: false },
    { id: 97, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 76, gender: 0, additional: 3, shopType: 'top', index: 723, price: 750, isDonate: false },
    { id: 97, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 76, gender: 0, additional: 4, shopType: 'top', index: 724, price: 750, isDonate: false },

    { id: 98, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 77, gender: 0, additional: 0, shopType: 'top', index: 730, price: 2600, isDonate: false },
    { id: 98, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 77, gender: 0, additional: 1, shopType: 'top', index: 731, price: 2600, isDonate: false },
    { id: 98, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 77, gender: 0, additional: 2, shopType: 'top', index: 732, price: 2600, isDonate: false },
    { id: 98, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 77, gender: 0, additional: 3, shopType: 'top', index: 733, price: 2600, isDonate: false },
    { id: 98, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 77, gender: 0, additional: 4, shopType: 'top', index: 734, price: 2600, isDonate: false },

    { id: 99, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 78, gender: 0, additional: 0, shopType: 'top', index: 740, price: 900, isDonate: false },
    { id: 99, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 78, gender: 0, additional: 1, shopType: 'top', index: 741, price: 900, isDonate: false },
    { id: 99, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 78, gender: 0, additional: 2, shopType: 'top', index: 742, price: 900, isDonate: false },
    { id: 99, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 78, gender: 0, additional: 3, shopType: 'top', index: 743, price: 900, isDonate: false },
    { id: 99, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 78, gender: 0, additional: 4, shopType: 'top', index: 744, price: 900, isDonate: false },
    { id: 99, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 78, gender: 0, additional: 5, shopType: 'top', index: 745, price: 900, isDonate: false },
    { id: 99, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 78, gender: 0, additional: 6, shopType: 'top', index: 746, price: 900, isDonate: false },

    // { id: 100, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 79, gender: 0, additional: 0, shopType: 'top', index: 750, price: 0, isDonate: false }, army
    // { id: 101, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 80, gender: 0, additional: 0, shopType: 'top', index: 760, price: 0, isDonate: false }, army
    // { id: 102, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 81, gender: 0, additional: 0, shopType: 'top', index: 770, price: 0, isDonate: false }, army
    { id: 103, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 82, gender: 0, additional: 0, shopType: 'top', index: 780, price: 550, isDonate: false },
    { id: 103, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 82, gender: 0, additional: 1, shopType: 'top', index: 781, price: 550, isDonate: false },
    { id: 103, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 82, gender: 0, additional: 2, shopType: 'top', index: 782, price: 550, isDonate: false },
    { id: 103, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 82, gender: 0, additional: 3, shopType: 'top', index: 783, price: 550, isDonate: false },
    { id: 103, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 82, gender: 0, additional: 4, shopType: 'top', index: 784, price: 550, isDonate: false },

    { id: 104, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 83, gender: 0, additional: 0, shopType: 'top', index: 790, price: 1200, isDonate: false },
    { id: 104, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 83, gender: 0, additional: 1, shopType: 'top', index: 791, price: 650, isDonate: false },
    { id: 104, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 83, gender: 0, additional: 2, shopType: 'top', index: 792, price: 650, isDonate: false },
    { id: 104, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 83, gender: 0, additional: 3, shopType: 'top', index: 793, price: 650, isDonate: false },
    { id: 104, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 83, gender: 0, additional: 4, shopType: 'top', index: 794, price: 650, isDonate: false },

    { id: 105, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 84, gender: 0, additional: 0, shopType: 'top', index: 800, price: 2800, isDonate: false },
    { id: 105, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 84, gender: 0, additional: 1, shopType: 'top', index: 800, price: 2800, isDonate: false },
    { id: 105, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 84, gender: 0, additional: 2, shopType: 'top', index: 800, price: 2800, isDonate: false },
    { id: 105, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 84, gender: 0, additional: 3, shopType: 'top', index: 800, price: 2800, isDonate: false },
    { id: 105, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 84, gender: 0, additional: 4, shopType: 'top', index: 800, price: 2800, isDonate: false },

    // { id: 106, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 85, gender: 0, additional: 0, shopType: 'top', index: 810, price: 0, isDonate: false }, police
    // { id: 107, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 86, gender: 0, additional: 0, shopType: 'top', index: 820, price: 0, isDonate: false }, police
    // { id: 108, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 87, gender: 0, additional: 0, shopType: 'top', index: 830, price: 0, isDonate: false }, police
    // { id: 109, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 88, gender: 0, additional: 0, shopType: 'top', index: 840, price: 0, isDonate: false }, police
    { id: 110, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 89, gender: 0, additional: 0, shopType: 'top', index: 850, price: 1750, isDonate: false },
    { id: 110, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 89, gender: 0, additional: 1, shopType: 'top', index: 851, price: 1750, isDonate: false },
    { id: 110, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 89, gender: 0, additional: 2, shopType: 'top', index: 852, price: 1750, isDonate: false },
    { id: 110, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 89, gender: 0, additional: 3, shopType: 'top', index: 853, price: 1750, isDonate: false },
    { id: 110, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 89, gender: 0, additional: 4, shopType: 'top', index: 854, price: 3400, isDonate: false },

    { id: 111, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 90, gender: 0, additional: 0, shopType: 'top', index: 860, price: 700, isDonate: false },
    { id: 111, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 90, gender: 0, additional: 1, shopType: 'top', index: 861, price: 700, isDonate: false },
    { id: 111, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 90, gender: 0, additional: 2, shopType: 'top', index: 862, price: 700, isDonate: false },
    { id: 111, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 90, gender: 0, additional: 3, shopType: 'top', index: 863, price: 700, isDonate: false },
    { id: 111, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 90, gender: 0, additional: 4, shopType: 'top', index: 864, price: 700, isDonate: false },

    { id: 112, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 91, gender: 0, additional: 0, shopType: 'top', index: 870, price: 3900, isDonate: false },
    { id: 112, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 91, gender: 0, additional: 1, shopType: 'top', index: 871, price: 3900, isDonate: false },
    { id: 112, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 91, gender: 0, additional: 2, shopType: 'top', index: 872, price: 3900, isDonate: false },
    { id: 112, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 91, gender: 0, additional: 3, shopType: 'top', index: 873, price: 3900, isDonate: false },
    { id: 112, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 91, gender: 0, additional: 4, shopType: 'top', index: 874, price: 3900, isDonate: false },
    { id: 112, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 91, gender: 0, additional: 5, shopType: 'top', index: 875, price: 3900, isDonate: false },
    { id: 112, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 91, gender: 0, additional: 6, shopType: 'top', index: 876, price: 3900, isDonate: false },

    // { id: 113, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 92, gender: 0, additional: 0, shopType: 'top', index: 880, price: 0, isDonate: false }, Не готово
    // { id: 113, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 92, gender: 0, additional: 1, shopType: 'top', index: 881, price: 0, isDonate: false },
    // { id: 113, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 92, gender: 0, additional: 2, shopType: 'top', index: 882, price: 0, isDonate: false },
    // { id: 113, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 92, gender: 0, additional: 3, shopType: 'top', index: 883, price: 0, isDonate: false },
    // { id: 113, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 92, gender: 0, additional: 4, shopType: 'top', index: 884, price: 0, isDonate: false },

    // { id: 114, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 93, gender: 0, additional: 0, shopType: 'top', index: 890, price: 0, isDonate: false },
    // { id: 114, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 93, gender: 0, additional: 1, shopType: 'top', index: 891, price: 0, isDonate: false },
    // { id: 114, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 93, gender: 0, additional: 2, shopType: 'top', index: 892, price: 0, isDonate: false },
    // { id: 114, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 93, gender: 0, additional: 3, shopType: 'top', index: 893, price: 0, isDonate: false },
    // { id: 114, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 93, gender: 0, additional: 4, shopType: 'top', index: 894, price: 0, isDonate: false },

    // { id: 115, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 94, gender: 0, additional: 0, shopType: 'top', index: 900, price: 0, isDonate: false },
    // { id: 115, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 94, gender: 0, additional: 1, shopType: 'top', index: 901, price: 0, isDonate: false },
    // { id: 115, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 94, gender: 0, additional: 2, shopType: 'top', index: 902, price: 0, isDonate: false },
    // { id: 115, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 94, gender: 0, additional: 3, shopType: 'top', index: 903, price: 0, isDonate: false },
    // { id: 115, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 94, gender: 0, additional: 4, shopType: 'top', index: 904, price: 0, isDonate: false },

    // { id: 116, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 95, gender: 0, additional: 0, shopType: 'top', index: 910, price: 0, isDonate: false }, Ниже резерв
    // { id: 117, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 96, gender: 0, additional: 0, shopType: 'top', index: 920, price: 0, isDonate: false },
    // { id: 118, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 97, gender: 0, additional: 0, shopType: 'top', index: 930, price: 0, isDonate: false },
    // { id: 119, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 98, gender: 0, additional: 0, shopType: 'top', index: 940, price: 0, isDonate: false },
    // { id: 120, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 99, gender: 0, additional: 0, shopType: 'top', index: 950, price: 0, isDonate: false },
    // { id: 121, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 100, gender: 0, additional: 0, shopType: 'top', index: 960, price: 0, isDonate: false },
    // { id: 122, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 101, gender: 0, additional: 0, shopType: 'top', index: 970, price: 0, isDonate: false },
    // { id: 123, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 102, gender: 0, additional: 0, shopType: 'top', index: 980, price: 0, isDonate: false },
    // { id: 124, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 103, gender: 0, additional: 0, shopType: 'top', index: 990, price: 0, isDonate: false },
    // { id: 125, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 104, gender: 0, additional: 0, shopType: 'top', index: 1000, price: 0, isDonate: false },
    // { id: 126, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 105, gender: 0, additional: 0, shopType: 'top', index: 1010, price: 0, isDonate: false },
    // { id: 127, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 106, gender: 0, additional: 0, shopType: 'top', index: 1020, price: 0, isDonate: false },
    // { id: 128, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 107, gender: 0, additional: 0, shopType: 'top', index: 1030, price: 0, isDonate: false },
    // { id: 129, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 108, gender: 0, additional: 0, shopType: 'top', index: 1040, price: 0, isDonate: false },
    // { id: 130, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 109, gender: 0, additional: 0, shopType: 'top', index: 1050, price: 0, isDonate: false },
    // { id: 131, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 110, gender: 0, additional: 0, shopType: 'top', index: 1060, price: 0, isDonate: false },
    // { id: 132, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 111, gender: 0, additional: 0, shopType: 'top', index: 1070, price: 0, isDonate: false },
    // { id: 133, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 112, gender: 0, additional: 0, shopType: 'top', index: 1080, price: 0, isDonate: false },
    // { id: 134, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 113, gender: 0, additional: 0, shopType: 'top', index: 1090, price: 0, isDonate: false },
    // { id: 135, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 114, gender: 0, additional: 0, shopType: 'top', index: 1100, price: 0, isDonate: false },
    // { id: 136, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 115, gender: 0, additional: 0, shopType: 'top', index: 1110, price: 0, isDonate: false },
    // { id: 137, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 116, gender: 0, additional: 0, shopType: 'top', index: 1120, price: 0, isDonate: false },
    // { id: 138, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 117, gender: 0, additional: 0, shopType: 'top', index: 1130, price: 0, isDonate: false },
    // { id: 139, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 118, gender: 0, additional: 0, shopType: 'top', index: 1140, price: 0, isDonate: false },
    // { id: 140, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 119, gender: 0, additional: 0, shopType: 'top', index: 1150, price: 0, isDonate: false },
    // { id: 141, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 120, gender: 0, additional: 0, shopType: 'top', index: 1160, price: 0, isDonate: false },
    // { id: 142, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 121, gender: 0, additional: 0, shopType: 'top', index: 1170, price: 0, isDonate: false },
    // { id: 143, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 122, gender: 0, additional: 0, shopType: 'top', index: 1180, price: 0, isDonate: false },
    // { id: 144, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 123, gender: 0, additional: 0, shopType: 'top', index: 1190, price: 0, isDonate: false },
    // { id: 145, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 124, gender: 0, additional: 0, shopType: 'top', index: 1200, price: 0, isDonate: false },
    // { id: 146, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 125, gender: 0, additional: 0, shopType: 'top', index: 1210, price: 0, isDonate: false },
    // { id: 147, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 126, gender: 0, additional: 0, shopType: 'top', index: 1220, price: 0, isDonate: false },
    // { id: 148, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 127, gender: 0, additional: 0, shopType: 'top', index: 1230, price: 0, isDonate: false },
    // { id: 149, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 128, gender: 0, additional: 0, shopType: 'top', index: 1240, price: 0, isDonate: false },
    // { id: 150, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 129, gender: 0, additional: 0, shopType: 'top', index: 1250, price: 0, isDonate: false },
    // { id: 151, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 130, gender: 0, additional: 0, shopType: 'top', index: 1260, price: 0, isDonate: false },
    // { id: 152, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 131, gender: 0, additional: 0, shopType: 'top', index: 1270, price: 0, isDonate: false },
    // { id: 153, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 132, gender: 0, additional: 0, shopType: 'top', index: 1280, price: 0, isDonate: false },
    // { id: 154, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 133, gender: 0, additional: 0, shopType: 'top', index: 1290, price: 0, isDonate: false },
    // { id: 155, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 134, gender: 0, additional: 0, shopType: 'top', index: 1300, price: 0, isDonate: false },
    // { id: 156, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 135, gender: 0, additional: 0, shopType: 'top', index: 1310, price: 0, isDonate: false },
    // { id: 157, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 136, gender: 0, additional: 0, shopType: 'top', index: 1320, price: 0, isDonate: false },
    // { id: 158, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 137, gender: 0, additional: 0, shopType: 'top', index: 1330, price: 0, isDonate: false },
    // { id: 159, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 138, gender: 0, additional: 0, shopType: 'top', index: 1340, price: 0, isDonate: false },
    // { id: 160, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 139, gender: 0, additional: 0, shopType: 'top', index: 1350, price: 0, isDonate: false },
    // { id: 161, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 140, gender: 0, additional: 0, shopType: 'top', index: 1360, price: 0, isDonate: false },
    // { id: 162, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 141, gender: 0, additional: 0, shopType: 'top', index: 1370, price: 0, isDonate: false },
    // { id: 163, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 142, gender: 0, additional: 0, shopType: 'top', index: 1380, price: 0, isDonate: false },
    // { id: 164, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 143, gender: 0, additional: 0, shopType: 'top', index: 1390, price: 0, isDonate: false },
    // { id: 165, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 144, gender: 0, additional: 0, shopType: 'top', index: 1400, price: 0, isDonate: false },
    // { id: 166, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 145, gender: 0, additional: 0, shopType: 'top', index: 1410, price: 0, isDonate: false },
    // { id: 167, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 146, gender: 0, additional: 0, shopType: 'top', index: 1420, price: 0, isDonate: false },
    // { id: 168, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 147, gender: 0, additional: 0, shopType: 'top', index: 1430, price: 0, isDonate: false },
    // { id: 169, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 148, gender: 0, additional: 0, shopType: 'top', index: 1440, price: 0, isDonate: false },
    // { id: 170, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 149, gender: 0, additional: 0, shopType: 'top', index: 1450, price: 0, isDonate: false },
    // { id: 171, name: 'inventory.mens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 150, gender: 0, additional: 0, shopType: 'top', index: 1460, price: 0, isDonate: false },

    // { id: 172, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 2, gender: 1, additional: 0, shopType: 'top', index: 1470, price: 800, isDonate: false }, prison
    { id: 173, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 3, gender: 1, additional: 0, shopType: 'top', index: 1480, price: 4700, isDonate: false },
    { id: 173, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 3, gender: 1, additional: 1, shopType: 'top', index: 1481, price: 4700, isDonate: false },
    { id: 173, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 3, gender: 1, additional: 2, shopType: 'top', index: 1482, price: 4700, isDonate: false },
    { id: 173, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 3, gender: 1, additional: 3, shopType: 'top', index: 1483, price: 4700, isDonate: false },
    { id: 173, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 3, gender: 1, additional: 4, shopType: 'top', index: 1484, price: 4700, isDonate: false },

    // { id: 174, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 4, gender: 1, additional: 0, shopType: 'top', index: 1490, price: 2100, isDonate: false }, police

    { id: 175, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 5, gender: 1, additional: 0, shopType: 'top', index: 1500, price: 3200, isDonate: false },
    { id: 175, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 5, gender: 1, additional: 1, shopType: 'top', index: 1501, price: 3200, isDonate: false },
    { id: 175, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 5, gender: 1, additional: 2, shopType: 'top', index: 1502, price: 3200, isDonate: false },
    { id: 175, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 5, gender: 1, additional: 3, shopType: 'top', index: 1503, price: 3200, isDonate: false },
    { id: 175, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 5, gender: 1, additional: 4, shopType: 'top', index: 1504, price: 3200, isDonate: false },

    { id: 176, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 6, gender: 1, additional: 0, shopType: 'top', index: 1510, price: 150, isDonate: true },
    { id: 176, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 6, gender: 1, additional: 1, shopType: 'top', index: 1511, price: 150, isDonate: true },
    { id: 176, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 6, gender: 1, additional: 2, shopType: 'top', index: 1512, price: 150, isDonate: true },
    { id: 176, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 6, gender: 1, additional: 3, shopType: 'top', index: 1513, price: 150, isDonate: true },
    { id: 176, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 6, gender: 1, additional: 4, shopType: 'top', index: 1514, price: 150, isDonate: true },

    // { id: 177, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 7, gender: 1, additional: 0, shopType: 'top', index: 1520, price: 0, isDonate: false }, medic
    // { id: 178, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 8, gender: 1, additional: 0, shopType: 'top', index: 1530, price: 0, isDonate: false }, medic
    // { id: 179, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 9, gender: 1, additional: 0, shopType: 'top', index: 1540, price: 0, isDonate: false }, fib

    { id: 180, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 10, gender: 1, additional: 0, shopType: 'top', index: 1550, price: 1500, isDonate: false },
    { id: 180, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 10, gender: 1, additional: 1, shopType: 'top', index: 1551, price: 1500, isDonate: false },
    { id: 180, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 10, gender: 1, additional: 2, shopType: 'top', index: 1552, price: 1500, isDonate: false },
    { id: 180, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 10, gender: 1, additional: 3, shopType: 'top', index: 1553, price: 1500, isDonate: false },
    { id: 180, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 10, gender: 1, additional: 4, shopType: 'top', index: 1554, price: 1500, isDonate: false },

    { id: 181, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 11, gender: 1, additional: 0, shopType: 'top', index: 1560, price: 4200, isDonate: false },
    { id: 181, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 11, gender: 1, additional: 1, shopType: 'top', index: 1561, price: 4200, isDonate: false },
    { id: 181, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 11, gender: 1, additional: 2, shopType: 'top', index: 1562, price: 4200, isDonate: false },
    { id: 181, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 11, gender: 1, additional: 3, shopType: 'top', index: 1563, price: 4200, isDonate: false },
    { id: 181, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 11, gender: 1, additional: 4, shopType: 'top', index: 1564, price: 4200, isDonate: false },

    // { id: 182, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 12, gender: 1, additional: 0, shopType: 'top', index: 1570, price: 0, isDonate: false }, fib
    // { id: 183, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 13, gender: 1, additional: 0, shopType: 'top', index: 1580, price: 0, isDonate: false }, army
    // { id: 184, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 14, gender: 1, additional: 0, shopType: 'top', index: 1590, price: 0, isDonate: false }, army

    // { id: 185, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 15, gender: 1, additional: 1, shopType: 'top', index: 1600, price: 0, isDonate: false }, bp
    { id: 185, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 15, gender: 1, additional: 0, shopType: 'top', index: 1580, price: 1100, isDonate: false },
    { id: 185, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 15, gender: 1, additional: 2, shopType: 'top', index: 1581, price: 1100, isDonate: false },
    { id: 185, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 15, gender: 1, additional: 3, shopType: 'top', index: 1582, price: 1800, isDonate: false },
    { id: 185, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 15, gender: 1, additional: 4, shopType: 'top', index: 1583, price: 1800, isDonate: false },

    { id: 186, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 16, gender: 1, additional: 0, shopType: 'top', index: 1610, price: 1300, isDonate: false },
    { id: 186, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 16, gender: 1, additional: 1, shopType: 'top', index: 1611, price: 1300, isDonate: false },
    { id: 186, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 16, gender: 1, additional: 2, shopType: 'top', index: 1612, price: 1300, isDonate: false },
    { id: 186, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 16, gender: 1, additional: 3, shopType: 'top', index: 1613, price: 1300, isDonate: false },
    { id: 186, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 16, gender: 1, additional: 4, shopType: 'top', index: 1614, price: 1300, isDonate: false },

    // { id: 187, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 17, gender: 1, additional: 0, shopType: 'top', index: 1620, price: 0, isDonate: false }, police

    { id: 188, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 18, gender: 1, additional: 0, shopType: 'top', index: 1630, price: 1600, isDonate: false },
    { id: 188, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 18, gender: 1, additional: 1, shopType: 'top', index: 1631, price: 1600, isDonate: false },
    { id: 188, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 18, gender: 1, additional: 2, shopType: 'top', index: 1632, price: 1600, isDonate: false },
    { id: 188, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 18, gender: 1, additional: 3, shopType: 'top', index: 1633, price: 1600, isDonate: false },
    { id: 188, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 18, gender: 1, additional: 4, shopType: 'top', index: 1634, price: 1600, isDonate: false },
    // { id: 189, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 19, gender: 1, additional: 0, shopType: 'top', index: 1640, price: 0, isDonate: false }, fib
    // { id: 190, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 20, gender: 1, additional: 0, shopType: 'top', index: 1650, price: 0, isDonate: false }, police
    // { id: 191, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 21, gender: 1, additional: 0, shopType: 'top', index: 1660, price: 0, isDonate: false }, medic
    { id: 192, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 22, gender: 1, additional: 0, shopType: 'top', index: 1670, price: 4400, isDonate: false },
    { id: 192, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 22, gender: 1, additional: 1, shopType: 'top', index: 1671, price: 4400, isDonate: false },
    { id: 192, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 22, gender: 1, additional: 2, shopType: 'top', index: 1672, price: 4400, isDonate: false },
    { id: 192, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 22, gender: 1, additional: 3, shopType: 'top', index: 1673, price: 4400, isDonate: false },
    { id: 192, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 22, gender: 1, additional: 4, shopType: 'top', index: 1674, price: 4400, isDonate: false },

    { id: 193, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 23, gender: 1, additional: 0, shopType: 'top', index: 1680, price: 4700, isDonate: false },
    { id: 193, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 23, gender: 1, additional: 1, shopType: 'top', index: 1681, price: 4700, isDonate: false },
    { id: 193, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 23, gender: 1, additional: 2, shopType: 'top', index: 1682, price: 4700, isDonate: false },
    { id: 193, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 23, gender: 1, additional: 3, shopType: 'top', index: 1683, price: 4700, isDonate: false },
    { id: 193, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 23, gender: 1, additional: 4, shopType: 'top', index: 1684, price: 4700, isDonate: false },

    { id: 194, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 24, gender: 1, additional: 0, shopType: 'top', index: 1690, price: 4900, isDonate: false },
    { id: 194, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 24, gender: 1, additional: 1, shopType: 'top', index: 1691, price: 4900, isDonate: false },
    { id: 194, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 24, gender: 1, additional: 2, shopType: 'top', index: 1692, price: 4900, isDonate: false },
    { id: 194, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 24, gender: 1, additional: 3, shopType: 'top', index: 1693, price: 4900, isDonate: false },
    { id: 194, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 24, gender: 1, additional: 4, shopType: 'top', index: 1694, price: 4900, isDonate: false },

    { id: 195, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 25, gender: 1, additional: 0, shopType: 'top', index: 1700, price: 3500, isDonate: false },
    { id: 195, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 25, gender: 1, additional: 1, shopType: 'top', index: 1701, price: 3500, isDonate: false },
    { id: 195, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 25, gender: 1, additional: 2, shopType: 'top', index: 1702, price: 3500, isDonate: false },
    { id: 195, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 25, gender: 1, additional: 3, shopType: 'top', index: 1703, price: 3500, isDonate: false },
    { id: 195, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 25, gender: 1, additional: 4, shopType: 'top', index: 1704, price: 3500, isDonate: false },

    { id: 196, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 26, gender: 1, additional: 0, shopType: 'top', index: 1710, price: 3800, isDonate: false },
    { id: 196, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 26, gender: 1, additional: 1, shopType: 'top', index: 1711, price: 3800, isDonate: false },
    { id: 196, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 26, gender: 1, additional: 2, shopType: 'top', index: 1712, price: 3800, isDonate: false },
    { id: 196, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 26, gender: 1, additional: 3, shopType: 'top', index: 1713, price: 3800, isDonate: false },
    { id: 196, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 26, gender: 1, additional: 4, shopType: 'top', index: 1714, price: 3800, isDonate: false },

    // { id: 197, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 27, gender: 1, additional: 0, shopType: 'top', index: 1720, price: 0, isDonate: false }, medic
    // { id: 198, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 28, gender: 1, additional: 0, shopType: 'top', index: 1730, price: 0, isDonate: false }, bus driver
    { id: 199, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 29, gender: 1, additional: 0, shopType: 'top', index: 1740, price: 900, isDonate: false },
    { id: 199, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 29, gender: 1, additional: 1, shopType: 'top', index: 1741, price: 900, isDonate: false },
    { id: 199, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 29, gender: 1, additional: 2, shopType: 'top', index: 1742, price: 900, isDonate: false },
    { id: 199, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 29, gender: 1, additional: 3, shopType: 'top', index: 1743, price: 900, isDonate: false },
    { id: 199, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 29, gender: 1, additional: 4, shopType: 'top', index: 1744, price: 900, isDonate: false },

    { id: 200, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 30, gender: 1, additional: 0, shopType: 'top', index: 1750, price: 3900, isDonate: false },
    { id: 200, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 30, gender: 1, additional: 1, shopType: 'top', index: 1751, price: 3900, isDonate: false },
    { id: 200, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 30, gender: 1, additional: 2, shopType: 'top', index: 1752, price: 3900, isDonate: false },
    { id: 200, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 30, gender: 1, additional: 3, shopType: 'top', index: 1753, price: 3900, isDonate: false },
    { id: 200, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 30, gender: 1, additional: 4, shopType: 'top', index: 1754, price: 3900, isDonate: false },
    // { id: 201, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 31, gender: 1, additional: 0, shopType: 'top', index: 1760, price: 0, isDonate: false }, bp
    { id: 201, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 31, gender: 1, additional: 0, shopType: 'top', index: 1760, price: 4000, isDonate: false },
    { id: 201, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 31, gender: 1, additional: 2, shopType: 'top', index: 1760, price: 4000, isDonate: false },
    { id: 201, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 31, gender: 1, additional: 3, shopType: 'top', index: 1760, price: 4000, isDonate: false },
    { id: 201, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 31, gender: 1, additional: 4, shopType: 'top', index: 1760, price: 4000, isDonate: false },
    // { id: 202, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 32, gender: 1, additional: 0, shopType: 'top', index: 1770, price: 0, isDonate: false }, bp
    { id: 202, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 32, gender: 1, additional: 0, shopType: 'top', index: 1770, price: 2800, isDonate: false },
    { id: 202, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 32, gender: 1, additional: 1, shopType: 'top', index: 1770, price: 2800, isDonate: false },
    { id: 202, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 32, gender: 1, additional: 2, shopType: 'top', index: 1770, price: 2800, isDonate: false },
    { id: 202, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 32, gender: 1, additional: 3, shopType: 'top', index: 1770, price: 2800, isDonate: false },
    // { id: 203, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 33, gender: 1, additional: 0, shopType: 'top', index: 1780, price: 2800, isDonate: false }, army
    // { id: 204, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 34, gender: 1, additional: 0, shopType: 'top', index: 1790, price: 0, isDonate: false }, bp
    { id: 204, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 34, gender: 1, additional: 0, shopType: 'top', index: 1790, price: 4200, isDonate: false },
    { id: 204, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 34, gender: 1, additional: 1, shopType: 'top', index: 1790, price: 4200, isDonate: false },
    { id: 204, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 34, gender: 1, additional: 2, shopType: 'top', index: 1790, price: 4200, isDonate: false },
    { id: 204, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 34, gender: 1, additional: 3, shopType: 'top', index: 1790, price: 4700, isDonate: false },

    { id: 205, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 35, gender: 1, additional: 0, shopType: 'top', index: 1800, price: 2300, isDonate: false },
    { id: 205, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 35, gender: 1, additional: 1, shopType: 'top', index: 1801, price: 2300, isDonate: false },
    { id: 205, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 35, gender: 1, additional: 2, shopType: 'top', index: 1802, price: 2300, isDonate: false },
    { id: 205, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 35, gender: 1, additional: 3, shopType: 'top', index: 1803, price: 2300, isDonate: false },
    { id: 205, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 35, gender: 1, additional: 4, shopType: 'top', index: 1804, price: 2300, isDonate: false },

    { id: 206, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 36, gender: 1, additional: 0, shopType: 'top', index: 1810, price: 4600, isDonate: false },
    { id: 206, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 36, gender: 1, additional: 1, shopType: 'top', index: 1811, price: 4600, isDonate: false },
    { id: 206, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 36, gender: 1, additional: 2, shopType: 'top', index: 1812, price: 4600, isDonate: false },
    { id: 206, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 36, gender: 1, additional: 3, shopType: 'top', index: 1813, price: 4600, isDonate: false },
    { id: 206, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 36, gender: 1, additional: 4, shopType: 'top', index: 1814, price: 4600, isDonate: false },

    { id: 207, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 37, gender: 1, additional: 0, shopType: 'top', index: 1820, price: 2200, isDonate: false },
    { id: 207, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 37, gender: 1, additional: 1, shopType: 'top', index: 1821, price: 2200, isDonate: false },
    { id: 207, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 37, gender: 1, additional: 2, shopType: 'top', index: 1822, price: 2200, isDonate: false },
    { id: 207, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 37, gender: 1, additional: 3, shopType: 'top', index: 1823, price: 2200, isDonate: false },
    { id: 207, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 37, gender: 1, additional: 4, shopType: 'top', index: 1824, price: 2200, isDonate: false },

    { id: 208, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 38, gender: 1, additional: 0, shopType: 'top', index: 1830, price: 100, isDonate: true },
    { id: 208, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 38, gender: 1, additional: 1, shopType: 'top', index: 1831, price: 100, isDonate: true },
    { id: 208, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 38, gender: 1, additional: 2, shopType: 'top', index: 1832, price: 100, isDonate: true },
    { id: 208, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 38, gender: 1, additional: 3, shopType: 'top', index: 1833, price: 100, isDonate: true },
    { id: 208, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 38, gender: 1, additional: 4, shopType: 'top', index: 1834, price: 100, isDonate: true },

    // { id: 209, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 39, gender: 1, additional: 0, shopType: 'top', index: 1840, price: 0, isDonate: false }, fire departament

    { id: 210, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 40, gender: 1, additional: 0, shopType: 'top', index: 1850, price: 4400, isDonate: false },
    { id: 210, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 40, gender: 1, additional: 1, shopType: 'top', index: 1851, price: 4400, isDonate: false },
    { id: 210, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 40, gender: 1, additional: 2, shopType: 'top', index: 1852, price: 4400, isDonate: false },
    { id: 210, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 40, gender: 1, additional: 3, shopType: 'top', index: 1853, price: 4400, isDonate: false },
    { id: 210, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 40, gender: 1, additional: 4, shopType: 'top', index: 1854, price: 4400, isDonate: false },

    { id: 211, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 41, gender: 1, additional: 0, shopType: 'top', index: 1860, price: 3100, isDonate: false },
    { id: 211, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 41, gender: 1, additional: 1, shopType: 'top', index: 1861, price: 3100, isDonate: false },
    { id: 211, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 41, gender: 1, additional: 2, shopType: 'top', index: 1862, price: 3100, isDonate: false },
    { id: 211, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 41, gender: 1, additional: 3, shopType: 'top', index: 1863, price: 3100, isDonate: false },
    { id: 211, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 41, gender: 1, additional: 4, shopType: 'top', index: 1864, price: 3100, isDonate: false },

    { id: 212, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 42, gender: 1, additional: 0, shopType: 'top', index: 1870, price: 2800, isDonate: false },
    { id: 212, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 42, gender: 1, additional: 1, shopType: 'top', index: 1871, price: 2800, isDonate: false },
    { id: 212, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 42, gender: 1, additional: 2, shopType: 'top', index: 1872, price: 2800, isDonate: false },
    { id: 212, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 42, gender: 1, additional: 3, shopType: 'top', index: 1873, price: 2800, isDonate: false },
    { id: 212, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 42, gender: 1, additional: 4, shopType: 'top', index: 1874, price: 2800, isDonate: false },

    // { id: 213, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 43, gender: 1, additional: 0, shopType: 'top', index: 1880, price: 0, isDonate: false }, ne ponyatno chto

    { id: 214, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 44, gender: 1, additional: 0, shopType: 'top', index: 1890, price: 3600, isDonate: false },
    { id: 214, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 44, gender: 1, additional: 1, shopType: 'top', index: 1891, price: 3600, isDonate: false },
    { id: 214, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 44, gender: 1, additional: 2, shopType: 'top', index: 1892, price: 3600, isDonate: false },
    { id: 214, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 44, gender: 1, additional: 3, shopType: 'top', index: 1893, price: 3600, isDonate: false },
    { id: 214, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 44, gender: 1, additional: 4, shopType: 'top', index: 1894, price: 3600, isDonate: false },

    { id: 215, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 45, gender: 1, additional: 0, shopType: 'top', index: 1900, price: 2100, isDonate: false },
    { id: 215, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 45, gender: 1, additional: 1, shopType: 'top', index: 1901, price: 2100, isDonate: false },
    { id: 215, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 45, gender: 1, additional: 2, shopType: 'top', index: 1902, price: 2100, isDonate: false },
    { id: 215, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 45, gender: 1, additional: 3, shopType: 'top', index: 1903, price: 2100, isDonate: false },
    { id: 215, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 45, gender: 1, additional: 4, shopType: 'top', index: 1904, price: 2100, isDonate: false },

    { id: 216, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 46, gender: 1, additional: 0, shopType: 'top', index: 1910, price: 2750, isDonate: false },
    { id: 216, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 46, gender: 1, additional: 1, shopType: 'top', index: 1911, price: 2750, isDonate: false },
    { id: 216, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 46, gender: 1, additional: 2, shopType: 'top', index: 1912, price: 2750, isDonate: false },
    { id: 216, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 46, gender: 1, additional: 3, shopType: 'top', index: 1913, price: 2750, isDonate: false },
    { id: 216, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 46, gender: 1, additional: 4, shopType: 'top', index: 1914, price: 2750, isDonate: false },

    { id: 217, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 47, gender: 1, additional: 0, shopType: 'top', index: 1920, price: 2300, isDonate: false },
    { id: 217, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 47, gender: 1, additional: 1, shopType: 'top', index: 1921, price: 2300, isDonate: false },
    { id: 217, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 47, gender: 1, additional: 2, shopType: 'top', index: 1922, price: 2300, isDonate: false },
    { id: 217, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 47, gender: 1, additional: 3, shopType: 'top', index: 1923, price: 2300, isDonate: false },
    { id: 217, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 47, gender: 1, additional: 4, shopType: 'top', index: 1924, price: 2300, isDonate: false },

    { id: 218, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 48, gender: 1, additional: 0, shopType: 'top', index: 1930, price: 5000, isDonate: false },
    { id: 218, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 48, gender: 1, additional: 1, shopType: 'top', index: 1931, price: 5000, isDonate: false },
    { id: 218, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 48, gender: 1, additional: 2, shopType: 'top', index: 1932, price: 5000, isDonate: false },
    { id: 218, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 48, gender: 1, additional: 3, shopType: 'top', index: 1933, price: 5000, isDonate: false },
    { id: 218, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 48, gender: 1, additional: 4, shopType: 'top', index: 1934, price: 5000, isDonate: false },
    // { id: 219, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 49, gender: 1, additional: 0, shopType: 'top', index: 1940, price: 0, isDonate: false }, bp
    { id: 220, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 50, gender: 1, additional: 0, shopType: 'top', index: 1950, price: 4800, isDonate: false },
    { id: 220, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 50, gender: 1, additional: 1, shopType: 'top', index: 1951, price: 4800, isDonate: false },
    { id: 220, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 50, gender: 1, additional: 2, shopType: 'top', index: 1952, price: 4800, isDonate: false },
    { id: 220, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 50, gender: 1, additional: 3, shopType: 'top', index: 1953, price: 4800, isDonate: false },
    { id: 220, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 50, gender: 1, additional: 4, shopType: 'top', index: 1954, price: 4800, isDonate: false },

    { id: 221, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 51, gender: 1, additional: 0, shopType: 'top', index: 1960, price: 3400, isDonate: false },
    { id: 221, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 51, gender: 1, additional: 1, shopType: 'top', index: 1961, price: 3400, isDonate: false },
    { id: 221, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 51, gender: 1, additional: 2, shopType: 'top', index: 1962, price: 3400, isDonate: false },
    { id: 221, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 51, gender: 1, additional: 3, shopType: 'top', index: 1963, price: 3400, isDonate: false },
    { id: 221, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 51, gender: 1, additional: 4, shopType: 'top', index: 1964, price: 3400, isDonate: false },

    { id: 222, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 52, gender: 1, additional: 0, shopType: 'top', index: 1970, price: 3200, isDonate: true },
    { id: 222, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 52, gender: 1, additional: 1, shopType: 'top', index: 1971, price: 3200, isDonate: true },
    { id: 222, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 52, gender: 1, additional: 2, shopType: 'top', index: 1972, price: 3200, isDonate: true },
    { id: 222, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 52, gender: 1, additional: 3, shopType: 'top', index: 1973, price: 3200, isDonate: true },
    { id: 222, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 52, gender: 1, additional: 4, shopType: 'top', index: 1974, price: 3200, isDonate: true },

    { id: 223, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 53, gender: 1, additional: 0, shopType: 'top', index: 1980, price: 2700, isDonate: false },
    { id: 223, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 53, gender: 1, additional: 1, shopType: 'top', index: 1981, price: 2700, isDonate: false },
    { id: 223, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 53, gender: 1, additional: 2, shopType: 'top', index: 1982, price: 2700, isDonate: false },
    { id: 223, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 53, gender: 1, additional: 3, shopType: 'top', index: 1983, price: 2700, isDonate: false },
    { id: 223, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 53, gender: 1, additional: 4, shopType: 'top', index: 1984, price: 2700, isDonate: false },

    { id: 224, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 54, gender: 1, additional: 0, shopType: 'top', index: 1990, price: 4300, isDonate: false },
    { id: 224, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 54, gender: 1, additional: 1, shopType: 'top', index: 1991, price: 4300, isDonate: false },
    { id: 224, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 54, gender: 1, additional: 2, shopType: 'top', index: 1992, price: 4300, isDonate: false },
    { id: 224, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 54, gender: 1, additional: 3, shopType: 'top', index: 1993, price: 4300, isDonate: false },
    { id: 224, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 54, gender: 1, additional: 4, shopType: 'top', index: 1994, price: 4300, isDonate: false },

    { id: 225, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 55, gender: 1, additional: 0, shopType: 'top', index: 2000, price: 600, isDonate: false },
    { id: 225, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 55, gender: 1, additional: 1, shopType: 'top', index: 2001, price: 600, isDonate: false },
    { id: 225, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 55, gender: 1, additional: 2, shopType: 'top', index: 2002, price: 600, isDonate: false },
    { id: 225, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 55, gender: 1, additional: 3, shopType: 'top', index: 2003, price: 600, isDonate: false },
    { id: 225, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 55, gender: 1, additional: 4, shopType: 'top', index: 2004, price: 600, isDonate: false },

    { id: 226, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 56, gender: 1, additional: 0, shopType: 'top', index: 2010, price: 3700, isDonate: false },
    { id: 226, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 56, gender: 1, additional: 1, shopType: 'top', index: 2011, price: 3700, isDonate: false },
    { id: 226, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 56, gender: 1, additional: 2, shopType: 'top', index: 2012, price: 3700, isDonate: false },
    { id: 226, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 56, gender: 1, additional: 3, shopType: 'top', index: 2013, price: 3700, isDonate: false },
    { id: 226, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 56, gender: 1, additional: 4, shopType: 'top', index: 2014, price: 3700, isDonate: false },

    { id: 227, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 57, gender: 1, additional: 0, shopType: 'top', index: 2020, price: 3500, isDonate: false },
    { id: 227, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 57, gender: 1, additional: 1, shopType: 'top', index: 2021, price: 3500, isDonate: false },
    { id: 227, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 57, gender: 1, additional: 2, shopType: 'top', index: 2022, price: 3500, isDonate: false },
    { id: 227, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 57, gender: 1, additional: 3, shopType: 'top', index: 2023, price: 3500, isDonate: false },
    { id: 227, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 57, gender: 1, additional: 4, shopType: 'top', index: 2024, price: 3500, isDonate: false },

    { id: 228, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 58, gender: 1, additional: 0, shopType: 'top', index: 2030, price: 500, isDonate: false },
    { id: 228, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 58, gender: 1, additional: 1, shopType: 'top', index: 2031, price: 500, isDonate: false },
    { id: 228, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 58, gender: 1, additional: 2, shopType: 'top', index: 2032, price: 500, isDonate: false },
    { id: 228, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 58, gender: 1, additional: 3, shopType: 'top', index: 2033, price: 500, isDonate: false },
    { id: 228, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 58, gender: 1, additional: 4, shopType: 'top', index: 2034, price: 500, isDonate: false },

    { id: 229, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 59, gender: 1, additional: 0, shopType: 'top', index: 2040, price: 2600, isDonate: false },
    { id: 229, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 59, gender: 1, additional: 1, shopType: 'top', index: 2041, price: 2600, isDonate: false },
    { id: 229, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 59, gender: 1, additional: 2, shopType: 'top', index: 2042, price: 2600, isDonate: false },
    { id: 229, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 59, gender: 1, additional: 3, shopType: 'top', index: 2043, price: 2600, isDonate: false },
    { id: 229, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 59, gender: 1, additional: 4, shopType: 'top', index: 2044, price: 2600, isDonate: false },

    // { id: 230, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 60, gender: 1, additional: 0, shopType: 'top', index: 2050, price: 0, isDonate: false }, police
    // { id: 231, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 61, gender: 1, additional: 0, shopType: 'top', index: 2060, price: 0, isDonate: false }, army

    { id: 232, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 62, gender: 1, additional: 0, shopType: 'top', index: 2070, price: 3200, isDonate: false },
    { id: 232, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 62, gender: 1, additional: 1, shopType: 'top', index: 2071, price: 3200, isDonate: false },
    { id: 232, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 62, gender: 1, additional: 2, shopType: 'top', index: 2072, price: 3200, isDonate: false },
    { id: 232, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 62, gender: 1, additional: 3, shopType: 'top', index: 2073, price: 3200, isDonate: false },

    // { id: 233, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 63, gender: 1, additional: 0, shopType: 'top', index: 2080, price: 0, isDonate: false }, army

    { id: 234, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 64, gender: 1, additional: 0, shopType: 'top', index: 2090, price: 250, isDonate: true },
    { id: 234, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 64, gender: 1, additional: 1, shopType: 'top', index: 2091, price: 250, isDonate: true },
    { id: 234, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 64, gender: 1, additional: 2, shopType: 'top', index: 2092, price: 250, isDonate: true },
    { id: 234, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 64, gender: 1, additional: 3, shopType: 'top', index: 2093, price: 250, isDonate: true },
    { id: 234, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 64, gender: 1, additional: 4, shopType: 'top', index: 2094, price: 250, isDonate: true },

    { id: 235, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 65, gender: 1, additional: 0, shopType: 'top', index: 2100, price: 600, isDonate: false },
    { id: 235, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 65, gender: 1, additional: 1, shopType: 'top', index: 2101, price: 600, isDonate: false },
    { id: 235, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 65, gender: 1, additional: 2, shopType: 'top', index: 2102, price: 600, isDonate: false },
    { id: 235, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 65, gender: 1, additional: 3, shopType: 'top', index: 2103, price: 600, isDonate: false },
    { id: 235, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 65, gender: 1, additional: 4, shopType: 'top', index: 2104, price: 600, isDonate: false },

    // { id: 236, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 66, gender: 1, additional: 0, shopType: 'top', index: 2110, price: 0, isDonate: false }, army

    { id: 237, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 67, gender: 1, additional: 0, shopType: 'top', index: 2120, price: 2800, isDonate: false },
    { id: 237, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 67, gender: 1, additional: 1, shopType: 'top', index: 2121, price: 2800, isDonate: false },
    { id: 237, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 67, gender: 1, additional: 2, shopType: 'top', index: 2122, price: 2800, isDonate: false },
    { id: 237, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 67, gender: 1, additional: 3, shopType: 'top', index: 2123, price: 2800, isDonate: false },
    { id: 237, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 67, gender: 1, additional: 4, shopType: 'top', index: 2124, price: 2800, isDonate: false },
    // { id: 238, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 68, gender: 1, additional: 0, shopType: 'top', index: 2130, price: 0, isDonate: false }, police
    // { id: 239, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 69, gender: 1, additional: 0, shopType: 'top', index: 2140, price: 0, isDonate: false }, police
    { id: 240, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 70, gender: 1, additional: 0, shopType: 'top', index: 2150, price: 3400, isDonate: false },
    { id: 240, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 70, gender: 1, additional: 1, shopType: 'top', index: 2151, price: 3400, isDonate: false },
    { id: 240, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 70, gender: 1, additional: 2, shopType: 'top', index: 2152, price: 3400, isDonate: false },
    { id: 240, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 70, gender: 1, additional: 3, shopType: 'top', index: 2153, price: 3400, isDonate: false },
    { id: 240, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 70, gender: 1, additional: 4, shopType: 'top', index: 2154, price: 3400, isDonate: false },

    // { id: 241, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 71, gender: 1, additional: 0, shopType: 'top', index: 2160, price: 0, isDonate: false }, police
    // { id: 242, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 72, gender: 1, additional: 0, shopType: 'top', index: 2170, price: 0, isDonate: false }, police

    { id: 243, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 73, gender: 1, additional: 0, shopType: 'top', index: 2180, price: 900, isDonate: false },
    { id: 243, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 73, gender: 1, additional: 1, shopType: 'top', index: 2181, price: 900, isDonate: false },
    { id: 243, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 73, gender: 1, additional: 2, shopType: 'top', index: 2182, price: 900, isDonate: false },
    { id: 243, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 73, gender: 1, additional: 3, shopType: 'top', index: 2183, price: 900, isDonate: false },
    { id: 243, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 73, gender: 1, additional: 4, shopType: 'top', index: 2184, price: 900, isDonate: false },

    { id: 244, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 74, gender: 1, additional: 0, shopType: 'top', index: 2190, price: 1500, isDonate: false },
    { id: 244, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 74, gender: 1, additional: 1, shopType: 'top', index: 2191, price: 1500, isDonate: false },
    { id: 244, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 74, gender: 1, additional: 2, shopType: 'top', index: 2192, price: 1500, isDonate: false },
    { id: 244, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 74, gender: 1, additional: 3, shopType: 'top', index: 2193, price: 1500, isDonate: false },
    { id: 244, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 74, gender: 1, additional: 4, shopType: 'top', index: 2194, price: 1500, isDonate: false },

    { id: 245, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 75, gender: 1, additional: 0, shopType: 'top', index: 2200, price: 6000, isDonate: false },
    { id: 245, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 75, gender: 1, additional: 1, shopType: 'top', index: 2200, price: 6000, isDonate: false },
    { id: 245, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 75, gender: 1, additional: 2, shopType: 'top', index: 2200, price: 6000, isDonate: false },
    { id: 245, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 75, gender: 1, additional: 3, shopType: 'top', index: 2200, price: 6000, isDonate: false },
    { id: 245, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 75, gender: 1, additional: 4, shopType: 'top', index: 2200, price: 6000, isDonate: false },

    { id: 246, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 76, gender: 1, additional: 0, shopType: 'top', index: 2210, price: 3200, isDonate: false },
    { id: 246, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 76, gender: 1, additional: 1, shopType: 'top', index: 2210, price: 3200, isDonate: false },
    { id: 246, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 76, gender: 1, additional: 2, shopType: 'top', index: 2210, price: 3200, isDonate: false },
    { id: 246, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 76, gender: 1, additional: 3, shopType: 'top', index: 2210, price: 3200, isDonate: false },

    { id: 247, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 77, gender: 1, additional: 0, shopType: 'top', index: 2220, price: 1000, isDonate: false },
    { id: 247, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 77, gender: 1, additional: 1, shopType: 'top', index: 2220, price: 1000, isDonate: false },
    { id: 247, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 77, gender: 1, additional: 2, shopType: 'top', index: 2220, price: 1000, isDonate: false },
    { id: 247, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 77, gender: 1, additional: 3, shopType: 'top', index: 2220, price: 1000, isDonate: false },
    { id: 247, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 77, gender: 1, additional: 4, shopType: 'top', index: 2220, price: 1000, isDonate: false },

    { id: 248, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 78, gender: 1, additional: 0, shopType: 'top', index: 2230, price: 2800, isDonate: false },
    { id: 248, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 78, gender: 1, additional: 1, shopType: 'top', index: 2230, price: 2800, isDonate: false },
    { id: 248, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 78, gender: 1, additional: 2, shopType: 'top', index: 2230, price: 2800, isDonate: false },
    { id: 248, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 78, gender: 1, additional: 3, shopType: 'top', index: 2230, price: 2800, isDonate: false },
    { id: 248, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 78, gender: 1, additional: 4, shopType: 'top', index: 2230, price: 2800, isDonate: false },

    { id: 249, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 79, gender: 1, additional: 0, shopType: 'top', index: 2240, price: 3100, isDonate: false },
    { id: 249, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 79, gender: 1, additional: 1, shopType: 'top', index: 2240, price: 3100, isDonate: false },
    { id: 249, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 79, gender: 1, additional: 2, shopType: 'top', index: 2240, price: 3100, isDonate: false },
    { id: 249, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 79, gender: 1, additional: 3, shopType: 'top', index: 2240, price: 3100, isDonate: false },
    { id: 249, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 79, gender: 1, additional: 4, shopType: 'top', index: 2240, price: 3100, isDonate: false },

    { id: 250, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 80, gender: 1, additional: 0, shopType: 'top', index: 2250, price: 200, isDonate: true },
    { id: 250, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 80, gender: 1, additional: 1, shopType: 'top', index: 2250, price: 200, isDonate: true },
    { id: 250, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 80, gender: 1, additional: 2, shopType: 'top', index: 2250, price: 200, isDonate: true },
    { id: 250, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 80, gender: 1, additional: 3, shopType: 'top', index: 2250, price: 200, isDonate: true },
    { id: 250, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 80, gender: 1, additional: 4, shopType: 'top', index: 2250, price: 200, isDonate: true },

    // { id: 251, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 81, gender: 1, additional: 0, shopType: 'top', index: 2260, price: 0, isDonate: false }, no render
    // { id: 251, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 81, gender: 1, additional: 1, shopType: 'top', index: 2260, price: 0, isDonate: false },
    // { id: 251, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 81, gender: 1, additional: 2, shopType: 'top', index: 2260, price: 0, isDonate: false },
    // { id: 251, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 81, gender: 1, additional: 3, shopType: 'top', index: 2260, price: 0, isDonate: false },
    // { id: 251, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 81, gender: 1, additional: 4, shopType: 'top', index: 2260, price: 0, isDonate: false },

    // { id: 252, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 82, gender: 1, additional: 0, shopType: 'top', index: 2270, price: 0, isDonate: false }, РЕЗЕРВ ЖЕНСКОЕ
    // { id: 253, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 83, gender: 1, additional: 0, shopType: 'top', index: 2280, price: 0, isDonate: false },
    // { id: 254, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 84, gender: 1, additional: 0, shopType: 'top', index: 2290, price: 0, isDonate: false },
    // { id: 255, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 85, gender: 1, additional: 0, shopType: 'top', index: 2300, price: 0, isDonate: false },
    // { id: 256, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 86, gender: 1, additional: 0, shopType: 'top', index: 2310, price: 0, isDonate: false },
    // { id: 257, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 87, gender: 1, additional: 0, shopType: 'top', index: 2320, price: 0, isDonate: false },
    // { id: 258, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 88, gender: 1, additional: 0, shopType: 'top', index: 2330, price: 0, isDonate: false },
    // { id: 259, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 89, gender: 1, additional: 0, shopType: 'top', index: 2340, price: 0, isDonate: false },
    // { id: 260, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 90, gender: 1, additional: 0, shopType: 'top', index: 2350, price: 0, isDonate: false },
    // { id: 261, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 91, gender: 1, additional: 0, shopType: 'top', index: 2360, price: 0, isDonate: false },
    // { id: 262, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 92, gender: 1, additional: 0, shopType: 'top', index: 2370, price: 0, isDonate: false },
    // { id: 263, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 93, gender: 1, additional: 0, shopType: 'top', index: 2380, price: 0, isDonate: false },
    // { id: 264, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 94, gender: 1, additional: 0, shopType: 'top', index: 2390, price: 0, isDonate: false },
    // { id: 265, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 95, gender: 1, additional: 0, shopType: 'top', index: 2400, price: 0, isDonate: false },
    // { id: 266, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 96, gender: 1, additional: 0, shopType: 'top', index: 2410, price: 0, isDonate: false },
    // { id: 267, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 97, gender: 1, additional: 0, shopType: 'top', index: 2420, price: 0, isDonate: false },
    // { id: 268, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 98, gender: 1, additional: 0, shopType: 'top', index: 2430, price: 0, isDonate: false },
    // { id: 269, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 99, gender: 1, additional: 0, shopType: 'top', index: 2440, price: 0, isDonate: false },
    // { id: 270, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 100, gender: 1, additional: 0, shopType: 'top', index: 2450, price: 0, isDonate: false },
    // { id: 271, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 101, gender: 1, additional: 0, shopType: 'top', index: 2460, price: 0, isDonate: false },
    // { id: 272, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 102, gender: 1, additional: 0, shopType: 'top', index: 2470, price: 0, isDonate: false },
    // { id: 273, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 103, gender: 1, additional: 0, shopType: 'top', index: 2480, price: 0, isDonate: false },
    // { id: 274, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 104, gender: 1, additional: 0, shopType: 'top', index: 2490, price: 0, isDonate: false },
    // { id: 275, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 105, gender: 1, additional: 0, shopType: 'top', index: 2500, price: 0, isDonate: false },
    // { id: 276, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 106, gender: 1, additional: 0, shopType: 'top', index: 2510, price: 0, isDonate: false },
    // { id: 277, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 107, gender: 1, additional: 0, shopType: 'top', index: 2520, price: 0, isDonate: false },
    // { id: 278, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 108, gender: 1, additional: 0, shopType: 'top', index: 2530, price: 0, isDonate: false },
    // { id: 279, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 109, gender: 1, additional: 0, shopType: 'top', index: 2540, price: 0, isDonate: false },
    // { id: 280, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 110, gender: 1, additional: 0, shopType: 'top', index: 2550, price: 0, isDonate: false },
    // { id: 281, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 111, gender: 1, additional: 0, shopType: 'top', index: 2560, price: 0, isDonate: false },
    // { id: 282, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 112, gender: 1, additional: 0, shopType: 'top', index: 2570, price: 0, isDonate: false },
    // { id: 283, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 113, gender: 1, additional: 0, shopType: 'top', index: 2580, price: 0, isDonate: false },
    // { id: 284, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 114, gender: 1, additional: 0, shopType: 'top', index: 2590, price: 0, isDonate: false },
    // { id: 285, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 115, gender: 1, additional: 0, shopType: 'top', index: 2600, price: 0, isDonate: false },
    // { id: 286, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 116, gender: 1, additional: 0, shopType: 'top', index: 2610, price: 0, isDonate: false },
    // { id: 287, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 117, gender: 1, additional: 0, shopType: 'top', index: 2620, price: 0, isDonate: false },
    // { id: 288, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 118, gender: 1, additional: 0, shopType: 'top', index: 2630, price: 0, isDonate: false },
    // { id: 289, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 119, gender: 1, additional: 0, shopType: 'top', index: 2640, price: 0, isDonate: false },
    // { id: 290, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 120, gender: 1, additional: 0, shopType: 'top', index: 2650, price: 0, isDonate: false },
    // { id: 291, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 121, gender: 1, additional: 0, shopType: 'top', index: 2660, price: 0, isDonate: false },
    // { id: 292, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 122, gender: 1, additional: 0, shopType: 'top', index: 2670, price: 0, isDonate: false },
    // { id: 293, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 123, gender: 1, additional: 0, shopType: 'top', index: 2680, price: 0, isDonate: false },
    // { id: 294, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 124, gender: 1, additional: 0, shopType: 'top', index: 2690, price: 0, isDonate: false },
    // { id: 295, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 125, gender: 1, additional: 0, shopType: 'top', index: 2700, price: 0, isDonate: false },
    // { id: 296, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 126, gender: 1, additional: 0, shopType: 'top', index: 2710, price: 0, isDonate: false },
    // { id: 297, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 127, gender: 1, additional: 0, shopType: 'top', index: 2720, price: 0, isDonate: false },
    // { id: 298, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 128, gender: 1, additional: 0, shopType: 'top', index: 2730, price: 0, isDonate: false },
    // { id: 299, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 129, gender: 1, additional: 0, shopType: 'top', index: 2740, price: 0, isDonate: false },
    // { id: 300, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 130, gender: 1, additional: 0, shopType: 'top', index: 2750, price: 0, isDonate: false },
    // { id: 301, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 131, gender: 1, additional: 0, shopType: 'top', index: 2760, price: 0, isDonate: false },
    // { id: 302, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 132, gender: 1, additional: 0, shopType: 'top', index: 2770, price: 0, isDonate: false },
    // { id: 303, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 133, gender: 1, additional: 0, shopType: 'top', index: 2780, price: 0, isDonate: false },
    // { id: 304, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 134, gender: 1, additional: 0, shopType: 'top', index: 2790, price: 0, isDonate: false },
    // { id: 305, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 135, gender: 1, additional: 0, shopType: 'top', index: 2800, price: 0, isDonate: false },
    // { id: 306, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 136, gender: 1, additional: 0, shopType: 'top', index: 2810, price: 0, isDonate: false },
    // { id: 307, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 137, gender: 1, additional: 0, shopType: 'top', index: 2820, price: 0, isDonate: false },
    // { id: 308, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 138, gender: 1, additional: 0, shopType: 'top', index: 2830, price: 0, isDonate: false },
    // { id: 309, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 139, gender: 1, additional: 0, shopType: 'top', index: 2840, price: 0, isDonate: false },
    // { id: 310, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 140, gender: 1, additional: 0, shopType: 'top', index: 2850, price: 0, isDonate: false },
    // { id: 311, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 141, gender: 1, additional: 0, shopType: 'top', index: 2860, price: 0, isDonate: false },
    // { id: 312, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 142, gender: 1, additional: 0, shopType: 'top', index: 2870, price: 0, isDonate: false },
    // { id: 313, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 143, gender: 1, additional: 0, shopType: 'top', index: 2880, price: 0, isDonate: false },
    // { id: 314, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 144, gender: 1, additional: 0, shopType: 'top', index: 2890, price: 0, isDonate: false },
    // { id: 315, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 145, gender: 1, additional: 0, shopType: 'top', index: 2900, price: 0, isDonate: false },
    // { id: 316, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 146, gender: 1, additional: 0, shopType: 'top', index: 2910, price: 0, isDonate: false },
    // { id: 317, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 147, gender: 1, additional: 0, shopType: 'top', index: 2920, price: 0, isDonate: false },
    // { id: 318, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 148, gender: 1, additional: 0, shopType: 'top', index: 2930, price: 0, isDonate: false },
    // { id: 319, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 149, gender: 1, additional: 0, shopType: 'top', index: 2940, price: 0, isDonate: false },
    // { id: 320, name: 'inventory.womens_outerwear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 1, clothesIndex: 150, gender: 1, additional: 0, shopType: 'top', index: 2950, price: 0, isDonate: false },

    { id: 321, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 3, gender: 0, additional: 0, shopType: 'jeans', index: 10_000, price: 2500, isDonate: false },
    { id: 321, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 3, gender: 0, additional: 1, shopType: 'jeans', index: 10_001, price: 2500, isDonate: false },
    { id: 321, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 3, gender: 0, additional: 2, shopType: 'jeans', index: 10_002, price: 2500, isDonate: false },
    { id: 321, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 3, gender: 0, additional: 3, shopType: 'jeans', index: 10_003, price: 2500, isDonate: false },
    { id: 321, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 3, gender: 0, additional: 4, shopType: 'jeans', index: 10_004, price: 2500, isDonate: false },

    // { id: 322, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 4, gender: 0, additional: 0, shopType: 'jeans', index: 10_010, price: 0, isDonate: false }, prison

    { id: 323, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 5, gender: 0, additional: 0, shopType: 'jeans', index: 10_020, price: 4000, isDonate: false },

    // { id: 324, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 6, gender: 0, additional: 0, shopType: 'jeans', index: 10_030, price: 0, isDonate: false }, medic

    { id: 325, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 7, gender: 0, additional: 0, shopType: 'jeans', index: 10_040, price: 3400, isDonate: false },
    { id: 325, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 7, gender: 0, additional: 1, shopType: 'jeans', index: 10_041, price: 3400, isDonate: false },
    { id: 325, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 7, gender: 0, additional: 2, shopType: 'jeans', index: 10_042, price: 3400, isDonate: false },
    { id: 325, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 7, gender: 0, additional: 3, shopType: 'jeans', index: 10_043, price: 3400, isDonate: false },

    { id: 326, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 8, gender: 0, additional: 0, shopType: 'jeans', index: 10_050, price: 900, isDonate: false },
    { id: 326, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 8, gender: 0, additional: 1, shopType: 'jeans', index: 10_051, price: 900, isDonate: false },
    { id: 326, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 8, gender: 0, additional: 2, shopType: 'jeans', index: 10_052, price: 900, isDonate: false },
    { id: 326, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 8, gender: 0, additional: 3, shopType: 'jeans', index: 10_053, price: 900, isDonate: false },
    { id: 326, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 8, gender: 0, additional: 4, shopType: 'jeans', index: 10_054, price: 900, isDonate: false },

    // { id: 327, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 9, gender: 0, additional: 0, shopType: 'jeans', index: 10_060, price: 0, isDonate: false }, army

    { id: 328, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 10, gender: 0, additional: 0, shopType: 'jeans', index: 10_070, price: 4700, isDonate: false },
    { id: 328, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 10, gender: 0, additional: 1, shopType: 'jeans', index: 10_071, price: 4700, isDonate: false },
    { id: 328, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 10, gender: 0, additional: 2, shopType: 'jeans', index: 10_072, price: 4700, isDonate: false },
    { id: 328, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 10, gender: 0, additional: 3, shopType: 'jeans', index: 10_073, price: 4700, isDonate: false },
    { id: 328, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 10, gender: 0, additional: 4, shopType: 'jeans', index: 10_074, price: 4700, isDonate: false },

    { id: 329, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 11, gender: 0, additional: 0, shopType: 'jeans', index: 10_080, price: 2400, isDonate: false },
    { id: 329, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 11, gender: 0, additional: 1, shopType: 'jeans', index: 10_081, price: 2400, isDonate: false },
    { id: 329, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 11, gender: 0, additional: 2, shopType: 'jeans', index: 10_082, price: 2400, isDonate: false },
    { id: 329, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 11, gender: 0, additional: 3, shopType: 'jeans', index: 10_083, price: 2400, isDonate: false },
    { id: 329, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 11, gender: 0, additional: 4, shopType: 'jeans', index: 10_084, price: 2400, isDonate: false },

    { id: 330, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 12, gender: 0, additional: 0, shopType: 'jeans', index: 10_090, price: 1200, isDonate: false },
    { id: 330, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 12, gender: 0, additional: 1, shopType: 'jeans', index: 10_091, price: 1200, isDonate: false },
    { id: 330, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 12, gender: 0, additional: 2, shopType: 'jeans', index: 10_092, price: 1200, isDonate: false },
    { id: 330, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 12, gender: 0, additional: 3, shopType: 'jeans', index: 10_093, price: 1200, isDonate: false },
    { id: 330, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 12, gender: 0, additional: 4, shopType: 'jeans', index: 10_094, price: 1200, isDonate: false },

    { id: 331, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 13, gender: 0, additional: 0, shopType: 'jeans', index: 10_100, price: 1800, isDonate: false },
    { id: 331, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 13, gender: 0, additional: 1, shopType: 'jeans', index: 10_101, price: 1800, isDonate: false },
    { id: 331, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 13, gender: 0, additional: 2, shopType: 'jeans', index: 10_102, price: 1800, isDonate: false },
    { id: 331, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 13, gender: 0, additional: 3, shopType: 'jeans', index: 10_103, price: 1800, isDonate: false },
    { id: 331, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 13, gender: 0, additional: 4, shopType: 'jeans', index: 10_104, price: 1800, isDonate: false },
    // { id: 332, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 14, gender: 0, additional: 0, shopType: 'jeans', index: 10110, price: 0, isDonate: false },
    { id: 332, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 14, gender: 0, additional: 1, shopType: 'jeans', index: 10111, price: 3300, isDonate: false },
    { id: 332, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 14, gender: 0, additional: 2, shopType: 'jeans', index: 10112, price: 3300, isDonate: false },
    { id: 332, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 14, gender: 0, additional: 3, shopType: 'jeans', index: 10113, price: 3300, isDonate: false },
    { id: 332, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 14, gender: 0, additional: 4, shopType: 'jeans', index: 10114, price: 3300, isDonate: false },
    // { id: 333, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 15, gender: 0, additional: 0, shopType: 'jeans', index: 10120, price: 0, isDonate: false },
    { id: 333, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 15, gender: 0, additional: 1, shopType: 'jeans', index: 10120, price: 4500, isDonate: false },
    { id: 333, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 15, gender: 0, additional: 2, shopType: 'jeans', index: 10120, price: 4500, isDonate: false },
    { id: 333, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 15, gender: 0, additional: 3, shopType: 'jeans', index: 10120, price: 4500, isDonate: false },
    { id: 333, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 15, gender: 0, additional: 4, shopType: 'jeans', index: 10120, price: 4500, isDonate: false },

    // { id: 334, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 16, gender: 0, additional: 0, shopType: 'jeans', index: 10_130, price: 0, isDonate: false }, army
    // { id: 335, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 17, gender: 0, additional: 0, shopType: 'jeans', index: 10_140, price: 0, isDonate: false }, army

    // { id: 336, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 18, gender: 0, additional: 0, shopType: 'jeans', index: 10_150, price: 0, isDonate: false }, bp
    { id: 336, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 18, gender: 0, additional: 0, shopType: 'jeans', index: 10_150, price: 1700, isDonate: false },
    { id: 336, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 18, gender: 0, additional: 0, shopType: 'jeans', index: 10_150, price: 1700, isDonate: false },
    { id: 336, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 18, gender: 0, additional: 0, shopType: 'jeans', index: 10_150, price: 1700, isDonate: false },
    { id: 336, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 18, gender: 0, additional: 0, shopType: 'jeans', index: 10_150, price: 1700, isDonate: false },

    { id: 337, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 19, gender: 0, additional: 0, shopType: 'jeans', index: 10_160, price: 1800, isDonate: false },
    { id: 337, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 19, gender: 0, additional: 1, shopType: 'jeans', index: 10_161, price: 1800, isDonate: false },
    { id: 337, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 19, gender: 0, additional: 2, shopType: 'jeans', index: 10_162, price: 1800, isDonate: false },
    { id: 337, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 19, gender: 0, additional: 3, shopType: 'jeans', index: 10_163, price: 1800, isDonate: false },
    { id: 337, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 19, gender: 0, additional: 4, shopType: 'jeans', index: 10_164, price: 1800, isDonate: false },

    // { id: 338, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 20, gender: 0, additional: 0, shopType: 'jeans', index: 10_170, price: 0, isDonate: false }, army

    { id: 339, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 21, gender: 0, additional: 0, shopType: 'jeans', index: 10_180, price: 2200, isDonate: false },
    { id: 339, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 21, gender: 0, additional: 1, shopType: 'jeans', index: 10_181, price: 2200, isDonate: false },
    { id: 339, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 21, gender: 0, additional: 2, shopType: 'jeans', index: 10_182, price: 2200, isDonate: false },
    { id: 339, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 21, gender: 0, additional: 3, shopType: 'jeans', index: 10_183, price: 2200, isDonate: false },
    { id: 339, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 21, gender: 0, additional: 4, shopType: 'jeans', index: 10_184, price: 2200, isDonate: false },

    { id: 340, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 22, gender: 0, additional: 0, shopType: 'jeans', index: 10_190, price: 1900, isDonate: false },
    { id: 340, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 22, gender: 0, additional: 1, shopType: 'jeans', index: 10_191, price: 1900, isDonate: false },
    { id: 340, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 22, gender: 0, additional: 2, shopType: 'jeans', index: 10_192, price: 1900, isDonate: false },
    { id: 340, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 22, gender: 0, additional: 3, shopType: 'jeans', index: 10_193, price: 1900, isDonate: false },
    { id: 340, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 22, gender: 0, additional: 4, shopType: 'jeans', index: 10_194, price: 1900, isDonate: false },

    { id: 341, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 23, gender: 0, additional: 0, shopType: 'jeans', index: 10_200, price: 900, isDonate: false },
    { id: 341, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 23, gender: 0, additional: 1, shopType: 'jeans', index: 10_201, price: 900, isDonate: false },
    { id: 341, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 23, gender: 0, additional: 2, shopType: 'jeans', index: 10_202, price: 900, isDonate: false },
    { id: 341, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 23, gender: 0, additional: 3, shopType: 'jeans', index: 10_203, price: 900, isDonate: false },
    { id: 341, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 23, gender: 0, additional: 4, shopType: 'jeans', index: 10_204, price: 900, isDonate: false },
    { id: 341, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 23, gender: 0, additional: 5, shopType: 'jeans', index: 10_205, price: 900, isDonate: false },
    { id: 341, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 23, gender: 0, additional: 6, shopType: 'jeans', index: 10_206, price: 900, isDonate: false },
    { id: 341, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 23, gender: 0, additional: 7, shopType: 'jeans', index: 10_207, price: 900, isDonate: false },
    { id: 341, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 23, gender: 0, additional: 8, shopType: 'jeans', index: 10_208, price: 900, isDonate: false },
    { id: 341, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 23, gender: 0, additional: 9, shopType: 'jeans', index: 10_209, price: 900, isDonate: false },

    { id: 342, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 24, gender: 0, additional: 0, shopType: 'jeans', index: 10_210, price: 1500, isDonate: false },
    { id: 342, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 24, gender: 0, additional: 1, shopType: 'jeans', index: 10_211, price: 1500, isDonate: false },
    { id: 342, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 24, gender: 0, additional: 2, shopType: 'jeans', index: 10_212, price: 1500, isDonate: false },
    { id: 342, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 24, gender: 0, additional: 3, shopType: 'jeans', index: 10_213, price: 1500, isDonate: false },
    { id: 342, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 24, gender: 0, additional: 4, shopType: 'jeans', index: 10_214, price: 1500, isDonate: false },

    // { id: 343, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 25, gender: 0, additional: 0, shopType: 'jeans', index: 10_220, price: 0, isDonate: false },
    { id: 343, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 25, gender: 0, additional: 0, shopType: 'jeans', index: 10_220, price: 2900, isDonate: false },
    { id: 343, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 25, gender: 0, additional: 1, shopType: 'jeans', index: 10_220, price: 2900, isDonate: false },
    { id: 343, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 25, gender: 0, additional: 3, shopType: 'jeans', index: 10_220, price: 2900, isDonate: false },
    { id: 343, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 25, gender: 0, additional: 4, shopType: 'jeans', index: 10_220, price: 2900, isDonate: false },

    { id: 344, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 26, gender: 0, additional: 0, shopType: 'jeans', index: 10_230, price: 3000, isDonate: false },
    { id: 344, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 26, gender: 0, additional: 1, shopType: 'jeans', index: 10_231, price: 3000, isDonate: false },
    { id: 344, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 26, gender: 0, additional: 2, shopType: 'jeans', index: 10_232, price: 3000, isDonate: false },
    { id: 344, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 26, gender: 0, additional: 3, shopType: 'jeans', index: 10_233, price: 3000, isDonate: false },
    { id: 344, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 26, gender: 0, additional: 4, shopType: 'jeans', index: 10_234, price: 3000, isDonate: false },

    { id: 345, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 27, gender: 0, additional: 0, shopType: 'jeans', index: 10_240, price: 800, isDonate: false },
    { id: 345, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 27, gender: 0, additional: 1, shopType: 'jeans', index: 10_241, price: 800, isDonate: false },
    { id: 345, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 27, gender: 0, additional: 2, shopType: 'jeans', index: 10_242, price: 800, isDonate: false },
    { id: 345, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 27, gender: 0, additional: 3, shopType: 'jeans', index: 10_243, price: 800, isDonate: false },
    { id: 345, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 27, gender: 0, additional: 4, shopType: 'jeans', index: 10_244, price: 800, isDonate: false },

    // { id: 346, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 28, gender: 0, additional: 0, shopType: 'jeans', index: 10_250, price: 0, isDonate: false }, daily reward
    { id: 347, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 29, gender: 0, additional: 0, shopType: 'jeans', index: 10_260, price: 1300, isDonate: false },
    { id: 347, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 29, gender: 0, additional: 1, shopType: 'jeans', index: 10_261, price: 1300, isDonate: false },
    { id: 347, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 29, gender: 0, additional: 2, shopType: 'jeans', index: 10_262, price: 1300, isDonate: false },
    { id: 347, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 29, gender: 0, additional: 3, shopType: 'jeans', index: 10_263, price: 1300, isDonate: false },
    { id: 347, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 29, gender: 0, additional: 4, shopType: 'jeans', index: 10_264, price: 1300, isDonate: false },

    { id: 348, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 30, gender: 0, additional: 0, shopType: 'jeans', index: 10_270, price: 700, isDonate: false },
    { id: 348, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 30, gender: 0, additional: 1, shopType: 'jeans', index: 10_271, price: 700, isDonate: false },
    { id: 348, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 30, gender: 0, additional: 2, shopType: 'jeans', index: 10_272, price: 700, isDonate: false },
    { id: 348, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 30, gender: 0, additional: 3, shopType: 'jeans', index: 10_273, price: 700, isDonate: false },
    { id: 348, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 30, gender: 0, additional: 4, shopType: 'jeans', index: 10_274, price: 700, isDonate: false },

    { id: 349, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 31, gender: 0, additional: 0, shopType: 'jeans', index: 10_280, price: 600, isDonate: false },
    { id: 349, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 31, gender: 0, additional: 1, shopType: 'jeans', index: 10_281, price: 600, isDonate: false },
    { id: 349, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 31, gender: 0, additional: 2, shopType: 'jeans', index: 10_282, price: 600, isDonate: false },
    { id: 349, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 31, gender: 0, additional: 3, shopType: 'jeans', index: 10_283, price: 600, isDonate: false },
    { id: 349, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 31, gender: 0, additional: 4, shopType: 'jeans', index: 10_284, price: 600, isDonate: false },

    { id: 350, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 32, gender: 0, additional: 0, shopType: 'jeans', index: 10_290, price: 2800, isDonate: false },
    { id: 350, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 32, gender: 0, additional: 1, shopType: 'jeans', index: 10_291, price: 2800, isDonate: false },
    { id: 350, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 32, gender: 0, additional: 2, shopType: 'jeans', index: 10_292, price: 2800, isDonate: false },
    { id: 350, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 32, gender: 0, additional: 3, shopType: 'jeans', index: 10_293, price: 2800, isDonate: false },
    { id: 350, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 32, gender: 0, additional: 4, shopType: 'jeans', index: 10_294, price: 2800, isDonate: false },
    // { id: 351, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 33, gender: 0, additional: 0, shopType: 'jeans', index: 10_300, price: 0, isDonate: false }, army
    // { id: 352, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 34, gender: 0, additional: 0, shopType: 'jeans', index: 10_310, price: 0, isDonate: false }, fire dep
    { id: 353, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 35, gender: 0, additional: 0, shopType: 'jeans', index: 10_320, price: 300, isDonate: false },
    { id: 353, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 35, gender: 0, additional: 1, shopType: 'jeans', index: 10_321, price: 300, isDonate: false },
    { id: 353, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 35, gender: 0, additional: 2, shopType: 'jeans', index: 10_322, price: 300, isDonate: false },
    { id: 353, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 35, gender: 0, additional: 3, shopType: 'jeans', index: 10_323, price: 300, isDonate: false },
    { id: 353, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 35, gender: 0, additional: 4, shopType: 'jeans', index: 10_324, price: 300, isDonate: false },

    { id: 354, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 36, gender: 0, additional: 0, shopType: 'jeans', index: 10_330, price: 500, isDonate: false },
    { id: 354, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 36, gender: 0, additional: 1, shopType: 'jeans', index: 10_331, price: 500, isDonate: false },
    { id: 354, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 36, gender: 0, additional: 2, shopType: 'jeans', index: 10_332, price: 500, isDonate: false },
    { id: 354, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 36, gender: 0, additional: 3, shopType: 'jeans', index: 10_333, price: 500, isDonate: false },
    { id: 354, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 36, gender: 0, additional: 4, shopType: 'jeans', index: 10_334, price: 500, isDonate: false },

    { id: 355, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 37, gender: 0, additional: 0, shopType: 'jeans', index: 10_340, price: 1800, isDonate: false },
    { id: 355, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 37, gender: 0, additional: 1, shopType: 'jeans', index: 10_341, price: 1800, isDonate: false },
    { id: 355, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 37, gender: 0, additional: 2, shopType: 'jeans', index: 10_342, price: 1800, isDonate: false },
    { id: 355, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 37, gender: 0, additional: 3, shopType: 'jeans', index: 10_343, price: 1800, isDonate: false },
    { id: 355, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 37, gender: 0, additional: 4, shopType: 'jeans', index: 10_344, price: 1800, isDonate: false },

    { id: 356, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 38, gender: 0, additional: 0, shopType: 'jeans', index: 10_350, price: 3200, isDonate: false },
    { id: 356, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 38, gender: 0, additional: 1, shopType: 'jeans', index: 10_351, price: 3200, isDonate: false },
    { id: 356, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 38, gender: 0, additional: 2, shopType: 'jeans', index: 10_352, price: 3200, isDonate: false },
    { id: 356, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 38, gender: 0, additional: 3, shopType: 'jeans', index: 10_353, price: 3200, isDonate: false },
    { id: 356, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 38, gender: 0, additional: 4, shopType: 'jeans', index: 10_354, price: 3200, isDonate: false },

    { id: 357, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 39, gender: 0, additional: 0, shopType: 'jeans', index: 10_360, price: 200, isDonate: false },
    { id: 357, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 39, gender: 0, additional: 1, shopType: 'jeans', index: 10_361, price: 200, isDonate: false },
    { id: 357, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 39, gender: 0, additional: 2, shopType: 'jeans', index: 10_362, price: 200, isDonate: false },
    { id: 357, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 39, gender: 0, additional: 3, shopType: 'jeans', index: 10_363, price: 200, isDonate: false },
    { id: 357, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 39, gender: 0, additional: 4, shopType: 'jeans', index: 10_364, price: 200, isDonate: false },

    // { id: 358, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 40, gender: 0, additional: 0, shopType: 'jeans', index: 10_370, price: 0, isDonate: false }, army

    { id: 359, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 41, gender: 0, additional: 0, shopType: 'jeans', index: 10_380, price: 3600, isDonate: false },
    { id: 359, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 41, gender: 0, additional: 1, shopType: 'jeans', index: 10_381, price: 3600, isDonate: false },
    { id: 359, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 41, gender: 0, additional: 2, shopType: 'jeans', index: 10_382, price: 3600, isDonate: false },
    { id: 359, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 41, gender: 0, additional: 3, shopType: 'jeans', index: 10_383, price: 3600, isDonate: false },
    { id: 359, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 41, gender: 0, additional: 4, shopType: 'jeans', index: 10_384, price: 3600, isDonate: false },

    { id: 360, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 42, gender: 0, additional: 0, shopType: 'jeans', index: 10_390, price: 1500, isDonate: false },
    { id: 360, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 42, gender: 0, additional: 1, shopType: 'jeans', index: 10_391, price: 1500, isDonate: false },
    { id: 360, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 42, gender: 0, additional: 2, shopType: 'jeans', index: 10_392, price: 1500, isDonate: false },
    { id: 360, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 42, gender: 0, additional: 3, shopType: 'jeans', index: 10_393, price: 1500, isDonate: false },
    { id: 360, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 42, gender: 0, additional: 4, shopType: 'jeans', index: 10_394, price: 1500, isDonate: false },

    { id: 361, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 43, gender: 0, additional: 0, shopType: 'jeans', index: 10_400, price: 4000, isDonate: false },
    { id: 361, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 43, gender: 0, additional: 1, shopType: 'jeans', index: 10_401, price: 4000, isDonate: false },
    { id: 361, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 43, gender: 0, additional: 2, shopType: 'jeans', index: 10_402, price: 4000, isDonate: false },
    { id: 361, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 43, gender: 0, additional: 3, shopType: 'jeans', index: 10_403, price: 4000, isDonate: false },
    { id: 361, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 43, gender: 0, additional: 4, shopType: 'jeans', index: 10_404, price: 4000, isDonate: false },

    // { id: 362, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 44, gender: 0, additional: 0, shopType: 'jeans', index: 10_410, price: 0, isDonate: false }, rezerv
    // { id: 363, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 45, gender: 0, additional: 0, shopType: 'jeans', index: 10_420, price: 0, isDonate: false },
    // { id: 364, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 46, gender: 0, additional: 0, shopType: 'jeans', index: 10_430, price: 0, isDonate: false },
    // { id: 365, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 47, gender: 0, additional: 0, shopType: 'jeans', index: 10_440, price: 0, isDonate: false },
    // { id: 366, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 48, gender: 0, additional: 0, shopType: 'jeans', index: 10_450, price: 0, isDonate: false },
    // { id: 367, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 49, gender: 0, additional: 0, shopType: 'jeans', index: 10_460, price: 0, isDonate: false },
    // { id: 368, name: 'inventory.mens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 50, gender: 0, additional: 0, shopType: 'jeans', index: 10_470, price: 0, isDonate: false },

    // { id: 369, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 2, gender: 1, additional: 0, shopType: 'jeans', index: 10_480, price: 0, isDonate: false }, prison
    { id: 370, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 3, gender: 1, additional: 0, shopType: 'jeans', index: 10_490, price: 3200, isDonate: false },
    { id: 370, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 3, gender: 1, additional: 1, shopType: 'jeans', index: 10_491, price: 3200, isDonate: false },
    { id: 370, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 3, gender: 1, additional: 2, shopType: 'jeans', index: 10_492, price: 3200, isDonate: false },
    { id: 370, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 3, gender: 1, additional: 3, shopType: 'jeans', index: 10_493, price: 3200, isDonate: false },
    { id: 370, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 3, gender: 1, additional: 4, shopType: 'jeans', index: 10_494, price: 3200, isDonate: false },

    { id: 371, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 4, gender: 1, additional: 0, shopType: 'jeans', index: 10_500, price: 4100, isDonate: false },

    { id: 371, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 5, gender: 1, additional: 0, shopType: 'jeans', index: 10_510, price: 2700, isDonate: false },
    { id: 371, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 5, gender: 1, additional: 1, shopType: 'jeans', index: 10_511, price: 2700, isDonate: false },
    { id: 371, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 5, gender: 1, additional: 2, shopType: 'jeans', index: 10_512, price: 2700, isDonate: false },
    { id: 371, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 5, gender: 1, additional: 3, shopType: 'jeans', index: 10_513, price: 2700, isDonate: false },
    { id: 371, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 5, gender: 1, additional: 4, shopType: 'jeans', index: 10_514, price: 2700, isDonate: false },

    { id: 373, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 6, gender: 1, additional: 0, shopType: 'jeans', index: 10_520, price: 200, isDonate: true },
    { id: 373, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 6, gender: 1, additional: 1, shopType: 'jeans', index: 10_521, price: 200, isDonate: true },
    { id: 373, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 6, gender: 1, additional: 2, shopType: 'jeans', index: 10_522, price: 200, isDonate: true },
    { id: 373, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 6, gender: 1, additional: 3, shopType: 'jeans', index: 10_523, price: 200, isDonate: true },
    { id: 373, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 6, gender: 1, additional: 4, shopType: 'jeans', index: 10_524, price: 200, isDonate: true },

    { id: 374, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 7, gender: 1, additional: 0, shopType: 'jeans', index: 10_530, price: 4600, isDonate: false },
    { id: 374, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 7, gender: 1, additional: 1, shopType: 'jeans', index: 10_531, price: 4600, isDonate: false },
    { id: 374, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 7, gender: 1, additional: 2, shopType: 'jeans', index: 10_532, price: 4600, isDonate: false },
    { id: 374, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 7, gender: 1, additional: 3, shopType: 'jeans', index: 10_533, price: 4600, isDonate: false },
    { id: 374, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 7, gender: 1, additional: 4, shopType: 'jeans', index: 10_534, price: 4600, isDonate: false },

    { id: 375, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 8, gender: 1, additional: 0, shopType: 'jeans', index: 10_540, price: 1300, isDonate: false },
    { id: 375, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 8, gender: 1, additional: 1, shopType: 'jeans', index: 10_541, price: 1300, isDonate: false },
    { id: 375, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 8, gender: 1, additional: 2, shopType: 'jeans', index: 10_542, price: 1300, isDonate: false },
    { id: 375, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 8, gender: 1, additional: 3, shopType: 'jeans', index: 10_543, price: 1300, isDonate: false },
    { id: 375, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 8, gender: 1, additional: 4, shopType: 'jeans', index: 10_544, price: 1300, isDonate: false },

    { id: 376, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 9, gender: 1, additional: 0, shopType: 'jeans', index: 10_550, price: 4400, isDonate: false },
    { id: 376, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 9, gender: 1, additional: 1, shopType: 'jeans', index: 10_551, price: 4400, isDonate: false },
    { id: 376, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 9, gender: 1, additional: 2, shopType: 'jeans', index: 10_552, price: 4400, isDonate: false },
    { id: 376, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 9, gender: 1, additional: 3, shopType: 'jeans', index: 10_553, price: 4400, isDonate: false },
    { id: 376, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 9, gender: 1, additional: 4, shopType: 'jeans', index: 10_554, price: 4400, isDonate: false },

    // { id: 377, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 10, gender: 1, additional: 0, shopType: 'jeans', index: 10_560, price: 0, isDonate: false },
    { id: 378, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 11, gender: 1, additional: 0, shopType: 'jeans', index: 10_570, price: 3200, isDonate: false },
    { id: 378, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 11, gender: 1, additional: 1, shopType: 'jeans', index: 10_571, price: 3200, isDonate: false },
    { id: 378, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 11, gender: 1, additional: 2, shopType: 'jeans', index: 10_572, price: 3200, isDonate: false },
    { id: 378, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 11, gender: 1, additional: 3, shopType: 'jeans', index: 10_573, price: 3200, isDonate: false },
    { id: 378, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 11, gender: 1, additional: 4, shopType: 'jeans', index: 10_574, price: 3200, isDonate: false },

    // { id: 379, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 12, gender: 1, additional: 0, shopType: 'jeans', index: 10_580, price: 0, isDonate: false }, army

    { id: 380, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 13, gender: 1, additional: 0, shopType: 'jeans', index: 10_590, price: 1300, isDonate: false },
    { id: 380, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 13, gender: 1, additional: 1, shopType: 'jeans', index: 10_591, price: 1300, isDonate: false },
    { id: 380, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 13, gender: 1, additional: 2, shopType: 'jeans', index: 10_592, price: 1300, isDonate: false },
    { id: 380, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 13, gender: 1, additional: 3, shopType: 'jeans', index: 10_593, price: 1300, isDonate: false },
    { id: 380, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 13, gender: 1, additional: 4, shopType: 'jeans', index: 10_594, price: 1300, isDonate: false },

    // { id: 381, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 14, gender: 1, additional: 0, shopType: 'jeans', index: 10_600, price: 0, isDonate: false },
    // { id: 382, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 15, gender: 1, additional: 0, shopType: 'jeans', index: 10_610, price: 0, isDonate: false },
    { id: 382, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 15, gender: 1, additional: 0, shopType: 'jeans', index: 10_610, price: 2000, isDonate: false },
    { id: 382, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 15, gender: 1, additional: 1, shopType: 'jeans', index: 10_610, price: 2000, isDonate: false },
    { id: 382, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 15, gender: 1, additional: 2, shopType: 'jeans', index: 10_610, price: 2000, isDonate: false },
    { id: 382, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 15, gender: 1, additional: 4, shopType: 'jeans', index: 10_610, price: 2000, isDonate: false },

    { id: 383, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 16, gender: 1, additional: 0, shopType: 'jeans', index: 10_620, price: 2900, isDonate: false },
    { id: 383, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 16, gender: 1, additional: 1, shopType: 'jeans', index: 10_621, price: 2900, isDonate: false },
    { id: 383, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 16, gender: 1, additional: 2, shopType: 'jeans', index: 10_622, price: 2900, isDonate: false },
    { id: 383, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 16, gender: 1, additional: 3, shopType: 'jeans', index: 10_623, price: 2900, isDonate: false },
    { id: 383, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 16, gender: 1, additional: 4, shopType: 'jeans', index: 10_624, price: 2900, isDonate: false },

    { id: 384, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 17, gender: 1, additional: 0, shopType: 'jeans', index: 10_630, price: 3100, isDonate: false },
    { id: 384, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 17, gender: 1, additional: 1, shopType: 'jeans', index: 10_631, price: 3100, isDonate: false },
    { id: 384, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 17, gender: 1, additional: 2, shopType: 'jeans', index: 10_632, price: 3100, isDonate: false },
    { id: 384, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 17, gender: 1, additional: 3, shopType: 'jeans', index: 10_633, price: 3100, isDonate: false },
    { id: 384, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 17, gender: 1, additional: 4, shopType: 'jeans', index: 10_634, price: 3100, isDonate: false },

    { id: 385, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 18, gender: 1, additional: 0, shopType: 'jeans', index: 10_640, price: 2500, isDonate: false },
    { id: 385, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 18, gender: 1, additional: 1, shopType: 'jeans', index: 10_641, price: 2500, isDonate: false },
    { id: 385, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 18, gender: 1, additional: 2, shopType: 'jeans', index: 10_642, price: 2500, isDonate: false },
    { id: 385, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 18, gender: 1, additional: 3, shopType: 'jeans', index: 10_643, price: 2500, isDonate: false },
    { id: 385, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 18, gender: 1, additional: 4, shopType: 'jeans', index: 10_644, price: 2500, isDonate: false },

    { id: 386, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 19, gender: 1, additional: 0, shopType: 'jeans', index: 10_650, price: 1700, isDonate: false },
    { id: 386, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 19, gender: 1, additional: 1, shopType: 'jeans', index: 10_651, price: 1700, isDonate: false },
    { id: 386, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 19, gender: 1, additional: 2, shopType: 'jeans', index: 10_652, price: 1700, isDonate: false },
    { id: 386, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 19, gender: 1, additional: 3, shopType: 'jeans', index: 10_653, price: 1700, isDonate: false },
    { id: 386, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 19, gender: 1, additional: 4, shopType: 'jeans', index: 10_654, price: 1700, isDonate: false },

    { id: 387, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 20, gender: 1, additional: 0, shopType: 'jeans', index: 10_660, price: 2300, isDonate: false },
    { id: 387, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 20, gender: 1, additional: 1, shopType: 'jeans', index: 10_661, price: 2300, isDonate: false },
    { id: 387, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 20, gender: 1, additional: 2, shopType: 'jeans', index: 10_662, price: 2300, isDonate: false },
    { id: 387, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 20, gender: 1, additional: 3, shopType: 'jeans', index: 10_663, price: 2300, isDonate: false },
    { id: 387, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 20, gender: 1, additional: 4, shopType: 'jeans', index: 10_664, price: 2300, isDonate: false },

    // { id: 388, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 21, gender: 1, additional: 0, shopType: 'jeans', index: 10_670, price: 0, isDonate: false }, army
    // { id: 389, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 22, gender: 1, additional: 0, shopType: 'jeans', index: 10_680, price: 0, isDonate: false }, bp
    { id: 390, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 23, gender: 1, additional: 0, shopType: 'jeans', index: 10_690, price: 4200, isDonate: false },
    { id: 390, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 23, gender: 1, additional: 1, shopType: 'jeans', index: 10_691, price: 4200, isDonate: false },
    { id: 390, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 23, gender: 1, additional: 2, shopType: 'jeans', index: 10_692, price: 4200, isDonate: false },
    { id: 390, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 23, gender: 1, additional: 3, shopType: 'jeans', index: 10_693, price: 4200, isDonate: false },
    { id: 390, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 23, gender: 1, additional: 4, shopType: 'jeans', index: 10_694, price: 4200, isDonate: false },

    { id: 391, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 24, gender: 1, additional: 0, shopType: 'jeans', index: 10_700, price: 3100, isDonate: false },
    { id: 391, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 24, gender: 1, additional: 1, shopType: 'jeans', index: 10_701, price: 3100, isDonate: false },
    { id: 391, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 24, gender: 1, additional: 2, shopType: 'jeans', index: 10_702, price: 3100, isDonate: false },
    { id: 391, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 24, gender: 1, additional: 3, shopType: 'jeans', index: 10_703, price: 3100, isDonate: false },
    { id: 391, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 24, gender: 1, additional: 4, shopType: 'jeans', index: 10_704, price: 3100, isDonate: false },

    { id: 392, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 25, gender: 1, additional: 0, shopType: 'jeans', index: 10_710, price: 4300, isDonate: false },
    { id: 392, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 25, gender: 1, additional: 1, shopType: 'jeans', index: 10_711, price: 4300, isDonate: false },
    { id: 392, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 25, gender: 1, additional: 2, shopType: 'jeans', index: 10_712, price: 4300, isDonate: false },
    { id: 392, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 25, gender: 1, additional: 3, shopType: 'jeans', index: 10_713, price: 4300, isDonate: false },
    { id: 392, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 25, gender: 1, additional: 4, shopType: 'jeans', index: 10_714, price: 4300, isDonate: false },

    // { id: 393, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 26, gender: 1, additional: 0, shopType: 'jeans', index: 10_720, price: 0, isDonate: false }, bp
    // { id: 394, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 27, gender: 1, additional: 0, shopType: 'jeans', index: 10_730, price: 0, isDonate: false }, fire dep

    { id: 395, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 28, gender: 1, additional: 0, shopType: 'jeans', index: 10_740, price: 600, isDonate: false },
    { id: 395, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 28, gender: 1, additional: 1, shopType: 'jeans', index: 10_741, price: 600, isDonate: false },
    { id: 395, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 28, gender: 1, additional: 2, shopType: 'jeans', index: 10_742, price: 600, isDonate: false },
    { id: 395, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 28, gender: 1, additional: 3, shopType: 'jeans', index: 10_743, price: 600, isDonate: false },
    { id: 395, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 28, gender: 1, additional: 4, shopType: 'jeans', index: 10_744, price: 600, isDonate: false },

    { id: 396, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 29, gender: 1, additional: 0, shopType: 'jeans', index: 10_750, price: 1200, isDonate: false },
    { id: 396, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 29, gender: 1, additional: 1, shopType: 'jeans', index: 10_751, price: 1200, isDonate: false },
    { id: 396, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 29, gender: 1, additional: 2, shopType: 'jeans', index: 10_752, price: 1200, isDonate: false },
    { id: 396, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 29, gender: 1, additional: 3, shopType: 'jeans', index: 10_753, price: 1200, isDonate: false },
    { id: 396, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 29, gender: 1, additional: 4, shopType: 'jeans', index: 10_754, price: 1200, isDonate: false },

    { id: 397, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 30, gender: 1, additional: 0, shopType: 'jeans', index: 10_760, price: 2000, isDonate: false },
    { id: 397, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 30, gender: 1, additional: 1, shopType: 'jeans', index: 10_761, price: 2000, isDonate: false },
    { id: 397, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 30, gender: 1, additional: 2, shopType: 'jeans', index: 10_762, price: 2000, isDonate: false },
    { id: 397, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 30, gender: 1, additional: 3, shopType: 'jeans', index: 10_763, price: 2000, isDonate: false },
    { id: 397, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 30, gender: 1, additional: 4, shopType: 'jeans', index: 10_764, price: 2000, isDonate: false },

    { id: 398, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 31, gender: 1, additional: 0, shopType: 'jeans', index: 10_770, price: 1500, isDonate: false },
    { id: 398, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 31, gender: 1, additional: 1, shopType: 'jeans', index: 10_771, price: 1500, isDonate: false },
    { id: 398, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 31, gender: 1, additional: 2, shopType: 'jeans', index: 10_772, price: 1500, isDonate: false },
    { id: 398, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 31, gender: 1, additional: 3, shopType: 'jeans', index: 10_773, price: 1500, isDonate: false },
    { id: 398, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 31, gender: 1, additional: 4, shopType: 'jeans', index: 10_774, price: 1500, isDonate: false },

    { id: 399, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 32, gender: 1, additional: 0, shopType: 'jeans', index: 10_780, price: 3900, isDonate: false },
    { id: 399, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 32, gender: 1, additional: 1, shopType: 'jeans', index: 10_781, price: 3900, isDonate: false },
    { id: 399, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 32, gender: 1, additional: 2, shopType: 'jeans', index: 10_782, price: 3900, isDonate: false },
    { id: 399, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 32, gender: 1, additional: 3, shopType: 'jeans', index: 10_783, price: 3900, isDonate: false },
    { id: 399, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 32, gender: 1, additional: 4, shopType: 'jeans', index: 10_784, price: 3900, isDonate: false },

    { id: 400, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 33, gender: 1, additional: 0, shopType: 'jeans', index: 10_790, price: 1200, isDonate: false },
    { id: 400, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 33, gender: 1, additional: 1, shopType: 'jeans', index: 10_791, price: 1200, isDonate: false },
    { id: 400, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 33, gender: 1, additional: 2, shopType: 'jeans', index: 10_792, price: 1200, isDonate: false },
    { id: 400, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 33, gender: 1, additional: 3, shopType: 'jeans', index: 10_793, price: 1200, isDonate: false },
    { id: 400, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 33, gender: 1, additional: 4, shopType: 'jeans', index: 10_794, price: 1200, isDonate: false },

    { id: 401, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 34, gender: 1, additional: 0, shopType: 'jeans', index: 10_800, price: 3500, isDonate: false },
    { id: 401, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 34, gender: 1, additional: 1, shopType: 'jeans', index: 10_801, price: 3500, isDonate: false },
    { id: 401, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 34, gender: 1, additional: 2, shopType: 'jeans', index: 10_802, price: 3500, isDonate: false },
    { id: 401, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 34, gender: 1, additional: 3, shopType: 'jeans', index: 10_803, price: 3500, isDonate: false },

    { id: 402, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 35, gender: 1, additional: 0, shopType: 'jeans', index: 10_810, price: 2700, isDonate: false },
    { id: 402, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 35, gender: 1, additional: 1, shopType: 'jeans', index: 10_811, price: 2700, isDonate: false },
    { id: 402, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 35, gender: 1, additional: 2, shopType: 'jeans', index: 10_812, price: 2700, isDonate: false },
    { id: 402, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 35, gender: 1, additional: 3, shopType: 'jeans', index: 10_813, price: 2700, isDonate: false },
    { id: 402, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 35, gender: 1, additional: 4, shopType: 'jeans', index: 10_814, price: 2700, isDonate: false },

    { id: 403, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 36, gender: 1, additional: 0, shopType: 'jeans', index: 10_820, price: 2900, isDonate: false },
    { id: 403, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 36, gender: 1, additional: 1, shopType: 'jeans', index: 10_821, price: 2900, isDonate: false },
    { id: 403, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 36, gender: 1, additional: 2, shopType: 'jeans', index: 10_822, price: 2900, isDonate: false },
    { id: 403, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 36, gender: 1, additional: 3, shopType: 'jeans', index: 10_823, price: 2900, isDonate: false },
    { id: 403, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 36, gender: 1, additional: 4, shopType: 'jeans', index: 10_824, price: 2900, isDonate: false },

    { id: 404, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 37, gender: 1, additional: 0, shopType: 'jeans', index: 10_830, price: 3400, isDonate: false },
    { id: 404, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 37, gender: 1, additional: 1, shopType: 'jeans', index: 10_831, price: 3400, isDonate: false },
    { id: 404, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 37, gender: 1, additional: 2, shopType: 'jeans', index: 10_832, price: 3400, isDonate: false },
    { id: 404, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 37, gender: 1, additional: 3, shopType: 'jeans', index: 10_833, price: 3400, isDonate: false },
    { id: 404, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 37, gender: 1, additional: 4, shopType: 'jeans', index: 10_834, price: 3400, isDonate: false },

    { id: 405, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 38, gender: 1, additional: 0, shopType: 'jeans', index: 10_840, price: 3700, isDonate: false },
    { id: 405, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 38, gender: 1, additional: 1, shopType: 'jeans', index: 10_841, price: 3700, isDonate: false },
    { id: 405, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 38, gender: 1, additional: 2, shopType: 'jeans', index: 10_842, price: 3700, isDonate: false },
    { id: 405, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 38, gender: 1, additional: 3, shopType: 'jeans', index: 10_843, price: 3700, isDonate: false },
    { id: 405, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 38, gender: 1, additional: 4, shopType: 'jeans', index: 10_844, price: 3700, isDonate: false },

    { id: 406, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 39, gender: 1, additional: 0, shopType: 'jeans', index: 10_850, price: 700, isDonate: false },
    { id: 406, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 39, gender: 1, additional: 1, shopType: 'jeans', index: 10_851, price: 700, isDonate: false },
    { id: 406, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 39, gender: 1, additional: 2, shopType: 'jeans', index: 10_852, price: 700, isDonate: false },
    { id: 406, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 39, gender: 1, additional: 3, shopType: 'jeans', index: 10_853, price: 700, isDonate: false },
    { id: 406, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 39, gender: 1, additional: 4, shopType: 'jeans', index: 10_854, price: 700, isDonate: false },

    { id: 407, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 40, gender: 1, additional: 0, shopType: 'jeans', index: 10_860, price: 4000, isDonate: false },
    { id: 407, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 40, gender: 1, additional: 1, shopType: 'jeans', index: 10_861, price: 4000, isDonate: false },
    { id: 407, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 40, gender: 1, additional: 2, shopType: 'jeans', index: 10_862, price: 4000, isDonate: false },
    { id: 407, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 40, gender: 1, additional: 3, shopType: 'jeans', index: 10_863, price: 4000, isDonate: false },
    { id: 407, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 40, gender: 1, additional: 4, shopType: 'jeans', index: 10_864, price: 4000, isDonate: false },

    { id: 408, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 41, gender: 1, additional: 0, shopType: 'jeans', index: 10_870, price: 2800, isDonate: false },
    { id: 408, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 41, gender: 1, additional: 1, shopType: 'jeans', index: 10_871, price: 2800, isDonate: false },
    { id: 408, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 41, gender: 1, additional: 2, shopType: 'jeans', index: 10_872, price: 2800, isDonate: false },
    { id: 408, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 41, gender: 1, additional: 3, shopType: 'jeans', index: 10_873, price: 2800, isDonate: false },
    { id: 408, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 41, gender: 1, additional: 4, shopType: 'jeans', index: 10_874, price: 2800, isDonate: false },

    { id: 409, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 42, gender: 1, additional: 0, shopType: 'jeans', index: 10_880, price: 500, isDonate: false },
    { id: 409, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 42, gender: 1, additional: 1, shopType: 'jeans', index: 10_881, price: 500, isDonate: false },
    { id: 409, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 42, gender: 1, additional: 2, shopType: 'jeans', index: 10_882, price: 500, isDonate: false },
    { id: 409, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 42, gender: 1, additional: 3, shopType: 'jeans', index: 10_883, price: 500, isDonate: false },
    { id: 409, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 42, gender: 1, additional: 4, shopType: 'jeans', index: 10_884, price: 500, isDonate: false },

    // { id: 410, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 43, gender: 1, additional: 0, shopType: 'jeans', index: 10_890, price: 0, isDonate: false }, army
    // { id: 411, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 44, gender: 1, additional: 0, shopType: 'jeans', index: 10_900, price: 0, isDonate: false }, army

    { id: 412, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 45, gender: 1, additional: 0, shopType: 'jeans', index: 10_910, price: 1900, isDonate: false },
    { id: 412, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 45, gender: 1, additional: 1, shopType: 'jeans', index: 10_911, price: 1900, isDonate: false },
    { id: 412, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 45, gender: 1, additional: 2, shopType: 'jeans', index: 10_912, price: 1900, isDonate: false },
    { id: 412, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 45, gender: 1, additional: 3, shopType: 'jeans', index: 10_913, price: 1900, isDonate: false },
    { id: 412, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 45, gender: 1, additional: 4, shopType: 'jeans', index: 10_914, price: 1900, isDonate: false },

    { id: 413, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 46, gender: 1, additional: 0, shopType: 'jeans', index: 10_920, price: 3500, isDonate: false },
    { id: 413, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 46, gender: 1, additional: 1, shopType: 'jeans', index: 10_921, price: 3500, isDonate: false },
    { id: 413, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 46, gender: 1, additional: 2, shopType: 'jeans', index: 10_922, price: 3500, isDonate: false },
    { id: 413, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 46, gender: 1, additional: 3, shopType: 'jeans', index: 10_923, price: 3500, isDonate: false },
    { id: 413, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 46, gender: 1, additional: 4, shopType: 'jeans', index: 10_924, price: 3500, isDonate: false },

    { id: 414, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 47, gender: 1, additional: 0, shopType: 'jeans', index: 10_930, price: 2200, isDonate: false },
    { id: 414, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 47, gender: 1, additional: 1, shopType: 'jeans', index: 10_931, price: 2200, isDonate: false },
    { id: 414, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 47, gender: 1, additional: 2, shopType: 'jeans', index: 10_932, price: 2200, isDonate: false },
    { id: 414, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 47, gender: 1, additional: 3, shopType: 'jeans', index: 10_933, price: 2200, isDonate: false },
    { id: 414, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 47, gender: 1, additional: 4, shopType: 'jeans', index: 10_934, price: 2200, isDonate: false },

    { id: 415, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 48, gender: 1, additional: 0, shopType: 'jeans', index: 10_940, price: 150, isDonate: true },
    { id: 415, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 48, gender: 1, additional: 1, shopType: 'jeans', index: 10_941, price: 150, isDonate: true },
    { id: 415, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 48, gender: 1, additional: 2, shopType: 'jeans', index: 10_942, price: 150, isDonate: true },
    { id: 415, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 48, gender: 1, additional: 3, shopType: 'jeans', index: 10_943, price: 150, isDonate: true },
    { id: 415, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 48, gender: 1, additional: 4, shopType: 'jeans', index: 10_944, price: 150, isDonate: true },

    { id: 416, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 49, gender: 1, additional: 0, shopType: 'jeans', index: 10_950, price: 100, isDonate: true },
    { id: 416, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 49, gender: 1, additional: 1, shopType: 'jeans', index: 10_951, price: 100, isDonate: true },
    { id: 416, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 49, gender: 1, additional: 2, shopType: 'jeans', index: 10_952, price: 100, isDonate: true },
    { id: 416, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 49, gender: 1, additional: 3, shopType: 'jeans', index: 10_953, price: 100, isDonate: true },
    { id: 416, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 49, gender: 1, additional: 4, shopType: 'jeans', index: 10_954, price: 100, isDonate: true },
    // { id: 417, name: 'inventory.womens_pants', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 50, gender: 1, additional: 0, shopType: 'jeans', index: 10_960, price: 0, isDonate: false }, rezerv

    { id: 418, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 4, gender: 0, additional: 0, shopType: 'boots', index: 15_000, price: 3800, isDonate: false },
    { id: 418, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 4, gender: 0, additional: 1, shopType: 'boots', index: 15_001, price: 3800, isDonate: false },
    { id: 418, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 4, gender: 0, additional: 2, shopType: 'boots', index: 15_002, price: 3800, isDonate: false },
    { id: 418, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 4, gender: 0, additional: 3, shopType: 'boots', index: 15_003, price: 3800, isDonate: false },
    { id: 418, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 4, gender: 0, additional: 4, shopType: 'boots', index: 15_004, price: 3800, isDonate: false },

    { id: 419, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 5, gender: 0, additional: 0, shopType: 'boots', index: 15_000, price: 3200, isDonate: false },
    { id: 419, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 5, gender: 0, additional: 1, shopType: 'boots', index: 15_011, price: 3200, isDonate: false },
    { id: 419, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 5, gender: 0, additional: 2, shopType: 'boots', index: 15_012, price: 3200, isDonate: false },
    { id: 419, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 5, gender: 0, additional: 3, shopType: 'boots', index: 15_013, price: 3200, isDonate: false },
    { id: 419, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 5, gender: 0, additional: 4, shopType: 'boots', index: 15_014, price: 3200, isDonate: false },
    { id: 419, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 5, gender: 0, additional: 5, shopType: 'boots', index: 15_015, price: 3200, isDonate: false },

    { id: 420, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 6, gender: 0, additional: 0, shopType: 'boots', index: 15_020, price: 4000, isDonate: false },
    { id: 420, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 6, gender: 0, additional: 1, shopType: 'boots', index: 15_021, price: 4000, isDonate: false },
    { id: 420, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 6, gender: 0, additional: 2, shopType: 'boots', index: 15_022, price: 4000, isDonate: false },
    { id: 420, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 6, gender: 0, additional: 3, shopType: 'boots', index: 15_023, price: 4000, isDonate: false },
    { id: 420, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 6, gender: 0, additional: 4, shopType: 'boots', index: 15_024, price: 4000, isDonate: false },

    { id: 421, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 7, gender: 0, additional: 0, shopType: 'boots', index: 15_030, price: 2800, isDonate: false },
    { id: 421, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 7, gender: 0, additional: 1, shopType: 'boots', index: 15_031, price: 2800, isDonate: false },
    { id: 421, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 7, gender: 0, additional: 2, shopType: 'boots', index: 15_032, price: 2800, isDonate: false },
    { id: 421, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 7, gender: 0, additional: 3, shopType: 'boots', index: 15_033, price: 2800, isDonate: false },
    { id: 421, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 7, gender: 0, additional: 4, shopType: 'boots', index: 15_034, price: 2800, isDonate: false },
    { id: 421, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 7, gender: 0, additional: 5, shopType: 'boots', index: 15_035, price: 2800, isDonate: false },
    { id: 421, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 7, gender: 0, additional: 6, shopType: 'boots', index: 15_036, price: 2800, isDonate: false },

    { id: 422, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 8, gender: 0, additional: 0, shopType: 'boots', index: 15_040, price: 2200, isDonate: false },
    { id: 422, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 8, gender: 0, additional: 1, shopType: 'boots', index: 15_041, price: 2200, isDonate: false },
    { id: 422, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 8, gender: 0, additional: 2, shopType: 'boots', index: 15_042, price: 2200, isDonate: false },
    { id: 422, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 8, gender: 0, additional: 3, shopType: 'boots', index: 15_043, price: 2200, isDonate: false },
    { id: 422, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 8, gender: 0, additional: 4, shopType: 'boots', index: 15_044, price: 2200, isDonate: false },
    { id: 422, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 8, gender: 0, additional: 5, shopType: 'boots', index: 15_045, price: 2200, isDonate: false },
    { id: 422, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 8, gender: 0, additional: 6, shopType: 'boots', index: 15_046, price: 2200, isDonate: false },

    { id: 423, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 9, gender: 0, additional: 0, shopType: 'boots', index: 15_050, price: 250, isDonate: true },
    { id: 423, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 9, gender: 0, additional: 1, shopType: 'boots', index: 15_051, price: 250, isDonate: true },
    { id: 423, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 9, gender: 0, additional: 2, shopType: 'boots', index: 15_052, price: 250, isDonate: true },
    { id: 423, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 9, gender: 0, additional: 3, shopType: 'boots', index: 15_053, price: 250, isDonate: true },
    { id: 423, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 9, gender: 0, additional: 4, shopType: 'boots', index: 15_054, price: 250, isDonate: true },

    { id: 424, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 10, gender: 0, additional: 0, shopType: 'boots', index: 15_060, price: 1100, isDonate: false },
    { id: 424, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 10, gender: 0, additional: 1, shopType: 'boots', index: 15_061, price: 1100, isDonate: false },
    { id: 424, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 10, gender: 0, additional: 2, shopType: 'boots', index: 15_062, price: 1100, isDonate: false },
    { id: 424, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 10, gender: 0, additional: 3, shopType: 'boots', index: 15_063, price: 1100, isDonate: false },
    { id: 424, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 10, gender: 0, additional: 4, shopType: 'boots', index: 15_064, price: 1100, isDonate: false },

    { id: 425, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 11, gender: 0, additional: 0, shopType: 'boots', index: 15_070, price: 3500, isDonate: false },
    { id: 425, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 11, gender: 0, additional: 1, shopType: 'boots', index: 15_071, price: 3500, isDonate: false },
    { id: 425, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 11, gender: 0, additional: 2, shopType: 'boots', index: 15_072, price: 3500, isDonate: false },
    { id: 425, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 11, gender: 0, additional: 3, shopType: 'boots', index: 15_073, price: 3500, isDonate: false },
    { id: 425, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 11, gender: 0, additional: 4, shopType: 'boots', index: 15_074, price: 3500, isDonate: false },

    // { id: 426, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 12, gender: 0, additional: 0, shopType: 'boots', index: 15_080, price: 0, isDonate: false },
    { id: 426, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 12, gender: 0, additional: 0, shopType: 'boots', index: 15_080, price: 4500, isDonate: false },
    { id: 426, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 12, gender: 0, additional: 2, shopType: 'boots', index: 15_080, price: 4500, isDonate: false },
    { id: 426, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 12, gender: 0, additional: 3, shopType: 'boots', index: 15_080, price: 4500, isDonate: false },
    { id: 426, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 12, gender: 0, additional: 4, shopType: 'boots', index: 15_080, price: 4500, isDonate: false },

    // { id: 427, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 13, gender: 0, additional: 4, shopType: 'boots', index: 15_090, price: 0, isDonate: false },
    { id: 427, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 13, gender: 0, additional: 0, shopType: 'boots', index: 15_090, price: 400, isDonate: true },
    { id: 427, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 13, gender: 0, additional: 1, shopType: 'boots', index: 15_090, price: 350, isDonate: true },
    { id: 427, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 13, gender: 0, additional: 2, shopType: 'boots', index: 15_090, price: 400, isDonate: true },
    { id: 427, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 13, gender: 0, additional: 3, shopType: 'boots', index: 15_090, price: 350, isDonate: true },

    { id: 428, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 14, gender: 0, additional: 0, shopType: 'boots', index: 15_100, price: 500, isDonate: false },
    { id: 428, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 14, gender: 0, additional: 1, shopType: 'boots', index: 15_101, price: 500, isDonate: false },
    { id: 428, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 14, gender: 0, additional: 2, shopType: 'boots', index: 15_102, price: 500, isDonate: false },
    { id: 428, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 14, gender: 0, additional: 3, shopType: 'boots', index: 15_103, price: 500, isDonate: false },
    { id: 428, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 14, gender: 0, additional: 4, shopType: 'boots', index: 15_104, price: 500, isDonate: false },

    // { id: 429, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 15, gender: 0, additional: 0,3, shopType: 'boots', index: 15_110, price: 0, isDonate: false },
    { id: 429, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 15, gender: 0, additional: 1, shopType: 'boots', index: 15_110, price: 300, isDonate: true },
    { id: 429, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 15, gender: 0, additional: 2, shopType: 'boots', index: 15_110, price: 300, isDonate: true },
    { id: 429, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 15, gender: 0, additional: 4, shopType: 'boots', index: 15_110, price: 300, isDonate: true },

    { id: 430, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 16, gender: 0, additional: 0, shopType: 'boots', index: 15_120, price: 600, isDonate: false },
    { id: 430, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 16, gender: 0, additional: 1, shopType: 'boots', index: 15_121, price: 600, isDonate: false },
    { id: 430, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 16, gender: 0, additional: 2, shopType: 'boots', index: 15_122, price: 600, isDonate: false },
    { id: 430, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 16, gender: 0, additional: 3, shopType: 'boots', index: 15_123, price: 600, isDonate: false },
    { id: 430, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 16, gender: 0, additional: 4, shopType: 'boots', index: 15_124, price: 600, isDonate: false },
    // { id: 431, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 17, gender: 0, additional: 0, shopType: 'boots', index: 15_130, price: 0, isDonate: false },
    { id: 431, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 17, gender: 0, additional: 0, shopType: 'boots', index: 15_130, price: 350, isDonate: true },
    { id: 431, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 17, gender: 0, additional: 2, shopType: 'boots', index: 15_130, price: 350, isDonate: true },
    { id: 431, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 17, gender: 0, additional: 3, shopType: 'boots', index: 15_130, price: 350, isDonate: true },
    { id: 431, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 17, gender: 0, additional: 4, shopType: 'boots', index: 15_130, price: 350, isDonate: true },

    { id: 432, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 18, gender: 0, additional: 0, shopType: 'boots', index: 15_140, price: 1200, isDonate: false },
    { id: 432, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 18, gender: 0, additional: 1, shopType: 'boots', index: 15_141, price: 1200, isDonate: false },
    { id: 432, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 18, gender: 0, additional: 2, shopType: 'boots', index: 15_142, price: 1200, isDonate: false },
    { id: 432, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 18, gender: 0, additional: 3, shopType: 'boots', index: 15_143, price: 1200, isDonate: false },
    { id: 432, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 18, gender: 0, additional: 4, shopType: 'boots', index: 15_144, price: 1200, isDonate: false },

    { id: 433, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 19, gender: 0, additional: 0, shopType: 'boots', index: 15_150, price: 3000, isDonate: false },
    { id: 433, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 19, gender: 0, additional: 1, shopType: 'boots', index: 15_151, price: 3000, isDonate: false },
    { id: 433, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 19, gender: 0, additional: 2, shopType: 'boots', index: 15_152, price: 3000, isDonate: false },
    { id: 433, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 19, gender: 0, additional: 3, shopType: 'boots', index: 15_153, price: 3000, isDonate: false },
    { id: 433, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 19, gender: 0, additional: 4, shopType: 'boots', index: 15_154, price: 3000, isDonate: false },

    { id: 434, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 20, gender: 0, additional: 0, shopType: 'boots', index: 15_160, price: 5000, isDonate: false },
    { id: 434, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 20, gender: 0, additional: 1, shopType: 'boots', index: 15_161, price: 5000, isDonate: false },
    { id: 434, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 20, gender: 0, additional: 2, shopType: 'boots', index: 15_162, price: 5000, isDonate: false },
    { id: 434, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 20, gender: 0, additional: 3, shopType: 'boots', index: 15_163, price: 5000, isDonate: false },
    { id: 434, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 20, gender: 0, additional: 4, shopType: 'boots', index: 15_164, price: 5000, isDonate: false },

    { id: 435, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 21, gender: 0, additional: 0, shopType: 'boots', index: 15_170, price: 1000, isDonate: false },
    { id: 435, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 21, gender: 0, additional: 1, shopType: 'boots', index: 15_171, price: 1000, isDonate: false },
    { id: 435, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 21, gender: 0, additional: 2, shopType: 'boots', index: 15_172, price: 1000, isDonate: false },
    { id: 435, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 21, gender: 0, additional: 3, shopType: 'boots', index: 15_173, price: 1000, isDonate: false },
    { id: 435, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 21, gender: 0, additional: 4, shopType: 'boots', index: 15_174, price: 1000, isDonate: false },

    { id: 436, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 22, gender: 0, additional: 0, shopType: 'boots', index: 15_180, price: 4200, isDonate: false },
    { id: 436, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 22, gender: 0, additional: 1, shopType: 'boots', index: 15_181, price: 4200, isDonate: false },
    { id: 436, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 22, gender: 0, additional: 2, shopType: 'boots', index: 15_182, price: 4200, isDonate: false },
    { id: 436, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 22, gender: 0, additional: 3, shopType: 'boots', index: 15_183, price: 4200, isDonate: false },
    { id: 436, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 22, gender: 0, additional: 4, shopType: 'boots', index: 15_184, price: 4200, isDonate: false },

    { id: 437, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 23, gender: 0, additional: 0, shopType: 'boots', index: 15_190, price: 3300, isDonate: false },
    { id: 437, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 23, gender: 0, additional: 1, shopType: 'boots', index: 15_191, price: 3300, isDonate: false },
    { id: 437, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 23, gender: 0, additional: 2, shopType: 'boots', index: 15_192, price: 3300, isDonate: false },
    { id: 437, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 23, gender: 0, additional: 3, shopType: 'boots', index: 15_193, price: 3300, isDonate: false },
    { id: 437, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 23, gender: 0, additional: 4, shopType: 'boots', index: 15_194, price: 3300, isDonate: false },

    { id: 438, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 24, gender: 0, additional: 0, shopType: 'boots', index: 15_200, price: 1900, isDonate: false },
    { id: 438, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 24, gender: 0, additional: 1, shopType: 'boots', index: 15_201, price: 1900, isDonate: false },
    { id: 438, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 24, gender: 0, additional: 2, shopType: 'boots', index: 15_202, price: 1900, isDonate: false },
    { id: 438, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 24, gender: 0, additional: 3, shopType: 'boots', index: 15_203, price: 1900, isDonate: false },
    { id: 438, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 24, gender: 0, additional: 4, shopType: 'boots', index: 15_204, price: 1900, isDonate: false },

    { id: 439, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 25, gender: 0, additional: 0, shopType: 'boots', index: 15_210, price: 1100, isDonate: false },
    { id: 439, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 25, gender: 0, additional: 1, shopType: 'boots', index: 15_211, price: 1100, isDonate: false },
    { id: 439, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 25, gender: 0, additional: 2, shopType: 'boots', index: 15_212, price: 1100, isDonate: false },
    { id: 439, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 25, gender: 0, additional: 3, shopType: 'boots', index: 15_213, price: 1100, isDonate: false },
    { id: 439, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 25, gender: 0, additional: 4, shopType: 'boots', index: 15_214, price: 1100, isDonate: false },

    { id: 440, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 26, gender: 0, additional: 0, shopType: 'boots', index: 15_220, price: 150, isDonate: true },
    { id: 440, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 26, gender: 0, additional: 1, shopType: 'boots', index: 15_221, price: 150, isDonate: true },
    { id: 440, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 26, gender: 0, additional: 2, shopType: 'boots', index: 15_222, price: 150, isDonate: true },
    { id: 440, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 26, gender: 0, additional: 3, shopType: 'boots', index: 15_223, price: 150, isDonate: true },
    { id: 440, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 26, gender: 0, additional: 4, shopType: 'boots', index: 15_224, price: 150, isDonate: true },

    { id: 441, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 27, gender: 0, additional: 0, shopType: 'boots', index: 15_230, price: 3500, isDonate: false },
    { id: 441, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 27, gender: 0, additional: 1, shopType: 'boots', index: 15_231, price: 3500, isDonate: false },
    { id: 441, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 27, gender: 0, additional: 2, shopType: 'boots', index: 15_232, price: 3500, isDonate: false },
    { id: 441, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 27, gender: 0, additional: 3, shopType: 'boots', index: 15_233, price: 3500, isDonate: false },
    { id: 441, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 27, gender: 0, additional: 4, shopType: 'boots', index: 15_234, price: 3500, isDonate: false },

    { id: 442, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 28, gender: 0, additional: 0, shopType: 'boots', index: 15_240, price: 2600, isDonate: false },
    { id: 442, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 28, gender: 0, additional: 1, shopType: 'boots', index: 15_241, price: 2600, isDonate: false },
    { id: 442, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 28, gender: 0, additional: 2, shopType: 'boots', index: 15_242, price: 2600, isDonate: false },
    { id: 442, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 28, gender: 0, additional: 3, shopType: 'boots', index: 15_243, price: 2600, isDonate: false },
    { id: 442, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 28, gender: 0, additional: 4, shopType: 'boots', index: 15_244, price: 2600, isDonate: false },

    { id: 443, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 29, gender: 0, additional: 0, shopType: 'boots', index: 15_250, price: 2200, isDonate: false },
    { id: 443, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 29, gender: 0, additional: 1, shopType: 'boots', index: 15_251, price: 2200, isDonate: false },
    { id: 443, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 29, gender: 0, additional: 2, shopType: 'boots', index: 15_252, price: 2200, isDonate: false },
    { id: 443, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 29, gender: 0, additional: 3, shopType: 'boots', index: 15_253, price: 2200, isDonate: false },
    { id: 443, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 29, gender: 0, additional: 4, shopType: 'boots', index: 15_254, price: 2200, isDonate: false },

    { id: 444, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 30, gender: 0, additional: 0, shopType: 'boots', index: 15_260, price: 1800, isDonate: false },
    { id: 444, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 30, gender: 0, additional: 1, shopType: 'boots', index: 15_261, price: 1800, isDonate: false },
    { id: 444, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 30, gender: 0, additional: 2, shopType: 'boots', index: 15_262, price: 1800, isDonate: false },
    { id: 444, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 30, gender: 0, additional: 3, shopType: 'boots', index: 15_263, price: 1800, isDonate: false },
    { id: 444, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 30, gender: 0, additional: 4, shopType: 'boots', index: 15_264, price: 1800, isDonate: false },

    { id: 445, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 31, gender: 0, additional: 0, shopType: 'boots', index: 15_270, price: 3700, isDonate: false },
    { id: 445, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 31, gender: 0, additional: 1, shopType: 'boots', index: 15_271, price: 3700, isDonate: false },
    { id: 445, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 31, gender: 0, additional: 2, shopType: 'boots', index: 15_272, price: 3700, isDonate: false },
    { id: 445, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 31, gender: 0, additional: 3, shopType: 'boots', index: 15_273, price: 3700, isDonate: false },
    { id: 445, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 31, gender: 0, additional: 4, shopType: 'boots', index: 15_274, price: 3700, isDonate: false },

    // { id: 446, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 32, gender: 0, additional: 0, shopType: 'boots', index: 15_280, price: 0, isDonate: false }, rezerv
    // { id: 447, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 33, gender: 0, additional: 0, shopType: 'boots', index: 15_290, price: 0, isDonate: false },
    // { id: 448, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 34, gender: 0, additional: 0, shopType: 'boots', index: 15_300, price: 0, isDonate: false },
    // { id: 449, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 35, gender: 0, additional: 0, shopType: 'boots', index: 15_310, price: 0, isDonate: false },
    // { id: 450, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 36, gender: 0, additional: 0, shopType: 'boots', index: 15_320, price: 0, isDonate: false },
    // { id: 451, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 37, gender: 0, additional: 0, shopType: 'boots', index: 15_330, price: 0, isDonate: false },
    // { id: 452, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 38, gender: 0, additional: 0, shopType: 'boots', index: 15_340, price: 0, isDonate: false },
    // { id: 453, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 39, gender: 0, additional: 0, shopType: 'boots', index: 15_350, price: 0, isDonate: false },
    // { id: 454, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 40, gender: 0, additional: 0, shopType: 'boots', index: 15_360, price: 0, isDonate: false },
    // { id: 455, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 41, gender: 0, additional: 0, shopType: 'boots', index: 15_370, price: 0, isDonate: false },
    // { id: 456, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 42, gender: 0, additional: 0, shopType: 'boots', index: 15_380, price: 0, isDonate: false },
    // { id: 457, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 43, gender: 0, additional: 0, shopType: 'boots', index: 15_390, price: 0, isDonate: false },
    // { id: 458, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 44, gender: 0, additional: 0, shopType: 'boots', index: 15_400, price: 0, isDonate: false },
    // { id: 459, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 45, gender: 0, additional: 0, shopType: 'boots', index: 15_410, price: 0, isDonate: false },
    // { id: 460, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 46, gender: 0, additional: 0, shopType: 'boots', index: 15_420, price: 0, isDonate: false },
    // { id: 461, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 47, gender: 0, additional: 0, shopType: 'boots', index: 15_430, price: 0, isDonate: false },
    // { id: 462, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 48, gender: 0, additional: 0, shopType: 'boots', index: 15_440, price: 0, isDonate: false },
    // { id: 463, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 49, gender: 0, additional: 0, shopType: 'boots', index: 15_450, price: 0, isDonate: false },
    // { id: 464, name: 'inventory.mens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 50, gender: 0, additional: 0, shopType: 'boots', index: 15_460, price: 0, isDonate: false },

    // { id: 465, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 2, clothesIndex: 3, gender: 1, additional: 0, shopType: 'boots', index: 15_470, price: 0, isDonate: false }, daily reward

    { id: 467, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 4, gender: 1, additional: 0, shopType: 'boots', index: 15_490, price: 150, isDonate: true },
    { id: 467, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 4, gender: 1, additional: 1, shopType: 'boots', index: 15_490, price: 150, isDonate: true },
    { id: 467, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 4, gender: 1, additional: 2, shopType: 'boots', index: 15_490, price: 150, isDonate: true },
    { id: 467, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 4, gender: 1, additional: 3, shopType: 'boots', index: 15_490, price: 150, isDonate: true },
    { id: 467, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 4, gender: 1, additional: 4, shopType: 'boots', index: 15_490, price: 150, isDonate: true },

    { id: 468, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 5, gender: 1, additional: 0, shopType: 'boots', index: 15_490, price: 900, isDonate: false },
    { id: 468, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 5, gender: 1, additional: 1, shopType: 'boots', index: 15_491, price: 900, isDonate: false },
    { id: 468, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 5, gender: 1, additional: 2, shopType: 'boots', index: 15_492, price: 900, isDonate: false },
    { id: 468, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 5, gender: 1, additional: 3, shopType: 'boots', index: 15_493, price: 900, isDonate: false },
    { id: 468, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 5, gender: 1, additional: 4, shopType: 'boots', index: 15_494, price: 900, isDonate: false },

    // { id: 468, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 6, gender: 1, additional: 0, shopType: 'boots', index: 15_500, price: 0, isDonate: false }, bp
    { id: 468, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 6, gender: 1, additional: 1, shopType: 'boots', index: 15_500, price: 1500, isDonate: false },
    { id: 468, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 6, gender: 1, additional: 2, shopType: 'boots', index: 15_500, price: 1500, isDonate: false },
    { id: 468, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 6, gender: 1, additional: 3, shopType: 'boots', index: 15_500, price: 1500, isDonate: false },
    { id: 468, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 6, gender: 1, additional: 4, shopType: 'boots', index: 15_500, price: 1500, isDonate: false },

    { id: 469, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 7, gender: 1, additional: 0, shopType: 'boots', index: 15_510, price: 2100, isDonate: false },
    { id: 469, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 7, gender: 1, additional: 1, shopType: 'boots', index: 15_511, price: 2100, isDonate: false },
    { id: 469, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 7, gender: 1, additional: 2, shopType: 'boots', index: 15_512, price: 2100, isDonate: false },
    { id: 469, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 7, gender: 1, additional: 3, shopType: 'boots', index: 15_513, price: 2100, isDonate: false },
    { id: 469, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 7, gender: 1, additional: 4, shopType: 'boots', index: 15_514, price: 2100, isDonate: false },

    { id: 470, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 8, gender: 1, additional: 0, shopType: 'boots', index: 15_520, price: 900, isDonate: false },
    { id: 470, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 8, gender: 1, additional: 1, shopType: 'boots', index: 15_521, price: 900, isDonate: false },
    { id: 470, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 8, gender: 1, additional: 2, shopType: 'boots', index: 15_522, price: 900, isDonate: false },
    { id: 470, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 8, gender: 1, additional: 3, shopType: 'boots', index: 15_523, price: 900, isDonate: false },
    { id: 470, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 8, gender: 1, additional: 4, shopType: 'boots', index: 15_524, price: 900, isDonate: false },

    { id: 471, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 9, gender: 1, additional: 0, shopType: 'boots', index: 15_530, price: 1900, isDonate: false },
    { id: 471, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 9, gender: 1, additional: 1, shopType: 'boots', index: 15_531, price: 1900, isDonate: false },
    { id: 471, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 9, gender: 1, additional: 2, shopType: 'boots', index: 15_532, price: 1900, isDonate: false },
    { id: 471, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 9, gender: 1, additional: 3, shopType: 'boots', index: 15_533, price: 1900, isDonate: false },
    { id: 471, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 9, gender: 1, additional: 4, shopType: 'boots', index: 15_534, price: 1900, isDonate: false },

    { id: 472, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 10, gender: 1, additional: 0, shopType: 'boots', index: 15_540, price: 2500, isDonate: false },
    { id: 472, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 10, gender: 1, additional: 1, shopType: 'boots', index: 15_541, price: 2500, isDonate: false },
    { id: 472, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 10, gender: 1, additional: 2, shopType: 'boots', index: 15_542, price: 2500, isDonate: false },
    { id: 472, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 10, gender: 1, additional: 3, shopType: 'boots', index: 15_543, price: 2500, isDonate: false },
    { id: 472, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 10, gender: 1, additional: 4, shopType: 'boots', index: 15_544, price: 2500, isDonate: false },

    { id: 473, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 11, gender: 1, additional: 0, shopType: 'boots', index: 15_550, price: 750, isDonate: false },
    { id: 473, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 11, gender: 1, additional: 1, shopType: 'boots', index: 15_551, price: 750, isDonate: false },
    { id: 473, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 11, gender: 1, additional: 2, shopType: 'boots', index: 15_552, price: 750, isDonate: false },
    { id: 473, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 11, gender: 1, additional: 3, shopType: 'boots', index: 15_553, price: 750, isDonate: false },
    { id: 473, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 11, gender: 1, additional: 4, shopType: 'boots', index: 15_554, price: 750, isDonate: false },

    { id: 474, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 12, gender: 1, additional: 0, shopType: 'boots', index: 15_560, price: 2900, isDonate: false },
    { id: 474, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 12, gender: 1, additional: 1, shopType: 'boots', index: 15_561, price: 2900, isDonate: false },
    { id: 474, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 12, gender: 1, additional: 2, shopType: 'boots', index: 15_562, price: 2900, isDonate: false },
    { id: 474, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 12, gender: 1, additional: 3, shopType: 'boots', index: 15_563, price: 2900, isDonate: false },
    { id: 474, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 12, gender: 1, additional: 4, shopType: 'boots', index: 15_564, price: 2900, isDonate: false },

    { id: 475, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 13, gender: 1, additional: 0, shopType: 'boots', index: 15_570, price: 4200, isDonate: false },
    { id: 475, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 13, gender: 1, additional: 1, shopType: 'boots', index: 15_571, price: 4200, isDonate: false },
    { id: 475, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 13, gender: 1, additional: 2, shopType: 'boots', index: 15_572, price: 4200, isDonate: false },
    { id: 475, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 13, gender: 1, additional: 3, shopType: 'boots', index: 15_573, price: 4200, isDonate: false },
    { id: 475, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 13, gender: 1, additional: 4, shopType: 'boots', index: 15_574, price: 4200, isDonate: false },

    { id: 476, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 14, gender: 1, additional: 0, shopType: 'boots', index: 15_580, price: 2400, isDonate: false },
    { id: 476, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 14, gender: 1, additional: 1, shopType: 'boots', index: 15_581, price: 2400, isDonate: false },
    { id: 476, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 14, gender: 1, additional: 2, shopType: 'boots', index: 15_582, price: 2400, isDonate: false },
    { id: 476, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 14, gender: 1, additional: 3, shopType: 'boots', index: 15_583, price: 2400, isDonate: false },
    { id: 476, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 14, gender: 1, additional: 4, shopType: 'boots', index: 15_584, price: 2400, isDonate: false },

    { id: 477, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 15, gender: 1, additional: 0, shopType: 'boots', index: 15_590, price: 2900, isDonate: false },
    { id: 477, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 15, gender: 1, additional: 1, shopType: 'boots', index: 15_591, price: 2900, isDonate: false },
    { id: 477, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 15, gender: 1, additional: 2, shopType: 'boots', index: 15_592, price: 2900, isDonate: false },
    { id: 477, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 15, gender: 1, additional: 3, shopType: 'boots', index: 15_593, price: 2900, isDonate: false },
    { id: 477, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 15, gender: 1, additional: 4, shopType: 'boots', index: 15_594, price: 2900, isDonate: false },

    { id: 478, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 16, gender: 1, additional: 0, shopType: 'boots', index: 15_600, price: 2600, isDonate: false },
    { id: 478, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 16, gender: 1, additional: 1, shopType: 'boots', index: 15_601, price: 2600, isDonate: false },
    { id: 478, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 16, gender: 1, additional: 2, shopType: 'boots', index: 15_602, price: 2600, isDonate: false },
    { id: 478, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 16, gender: 1, additional: 3, shopType: 'boots', index: 15_603, price: 2600, isDonate: false },
    { id: 478, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 16, gender: 1, additional: 4, shopType: 'boots', index: 15_604, price: 2600, isDonate: false },

    { id: 479, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 17, gender: 1, additional: 0, shopType: 'boots', index: 15_610, price: 2000, isDonate: false },
    { id: 479, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 17, gender: 1, additional: 1, shopType: 'boots', index: 15_611, price: 2000, isDonate: false },
    { id: 479, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 17, gender: 1, additional: 2, shopType: 'boots', index: 15_612, price: 2000, isDonate: false },
    { id: 479, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 17, gender: 1, additional: 3, shopType: 'boots', index: 15_613, price: 2000, isDonate: false },
    { id: 479, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 17, gender: 1, additional: 4, shopType: 'boots', index: 15_614, price: 2000, isDonate: false },

    { id: 480, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 18, gender: 1, additional: 0, shopType: 'boots', index: 15_620, price: 2500, isDonate: false },
    { id: 480, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 18, gender: 1, additional: 1, shopType: 'boots', index: 15_621, price: 2500, isDonate: false },
    { id: 480, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 18, gender: 1, additional: 2, shopType: 'boots', index: 15_622, price: 2500, isDonate: false },
    { id: 480, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 18, gender: 1, additional: 3, shopType: 'boots', index: 15_623, price: 2500, isDonate: false },
    { id: 480, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 18, gender: 1, additional: 4, shopType: 'boots', index: 15_624, price: 2500, isDonate: false },
    { id: 480, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 18, gender: 1, additional: 5, shopType: 'boots', index: 15_625, price: 2500, isDonate: false },
    { id: 480, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 18, gender: 1, additional: 6, shopType: 'boots', index: 15_626, price: 2500, isDonate: false },

    { id: 481, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 19, gender: 1, additional: 0, shopType: 'boots', index: 15_630, price: 2100, isDonate: false },
    { id: 481, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 19, gender: 1, additional: 1, shopType: 'boots', index: 15_631, price: 2100, isDonate: false },
    { id: 481, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 19, gender: 1, additional: 2, shopType: 'boots', index: 15_632, price: 2100, isDonate: false },
    { id: 481, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 19, gender: 1, additional: 3, shopType: 'boots', index: 15_633, price: 2100, isDonate: false },
    { id: 481, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 19, gender: 1, additional: 4, shopType: 'boots', index: 15_634, price: 2100, isDonate: false },

    { id: 482, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 20, gender: 1, additional: 0, shopType: 'boots', index: 15_640, price: 1800, isDonate: false },
    { id: 482, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 20, gender: 1, additional: 1, shopType: 'boots', index: 15_641, price: 1800, isDonate: false },
    { id: 482, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 20, gender: 1, additional: 2, shopType: 'boots', index: 15_642, price: 1800, isDonate: false },
    { id: 482, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 20, gender: 1, additional: 3, shopType: 'boots', index: 15_643, price: 1800, isDonate: false },
    { id: 482, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 20, gender: 1, additional: 4, shopType: 'boots', index: 15_644, price: 1800, isDonate: false },

    { id: 483, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 21, gender: 1, additional: 0, shopType: 'boots', index: 15_650, price: 4300, isDonate: false },
    { id: 483, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 21, gender: 1, additional: 1, shopType: 'boots', index: 15_651, price: 4300, isDonate: false },
    { id: 483, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 21, gender: 1, additional: 2, shopType: 'boots', index: 15_652, price: 4300, isDonate: false },
    { id: 483, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 21, gender: 1, additional: 3, shopType: 'boots', index: 15_653, price: 4300, isDonate: false },
    { id: 483, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 21, gender: 1, additional: 4, shopType: 'boots', index: 15_654, price: 4300, isDonate: false },

    { id: 484, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 22, gender: 1, additional: 0, shopType: 'boots', index: 15_660, price: 3600, isDonate: false },
    { id: 484, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 22, gender: 1, additional: 1, shopType: 'boots', index: 15_661, price: 3600, isDonate: false },
    { id: 484, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 22, gender: 1, additional: 2, shopType: 'boots', index: 15_662, price: 3600, isDonate: false },
    { id: 484, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 22, gender: 1, additional: 3, shopType: 'boots', index: 15_663, price: 3600, isDonate: false },
    { id: 484, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 22, gender: 1, additional: 4, shopType: 'boots', index: 15_664, price: 3600, isDonate: false },
    // { id: 485, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 23, gender: 1, additional: 0, shopType: 'boots', index: 15_670, price: 0, isDonate: false }, bp
    { id: 485, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 23, gender: 1, additional: 1, shopType: 'boots', index: 15_670, price: 3800, isDonate: false },
    { id: 485, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 23, gender: 1, additional: 2, shopType: 'boots', index: 15_671, price: 3800, isDonate: false },
    { id: 485, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 23, gender: 1, additional: 3, shopType: 'boots', index: 15_672, price: 3800, isDonate: false },
    { id: 485, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 23, gender: 1, additional: 4, shopType: 'boots', index: 15_673, price: 3800, isDonate: false },
    // { id: 486, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 24, gender: 1, additional: 0, shopType: 'boots', index: 15_680, price: 0, isDonate: false }, bp
    { id: 487, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 25, gender: 1, additional: 0, shopType: 'boots', index: 15_690, price: 4800, isDonate: false },
    { id: 487, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 25, gender: 1, additional: 1, shopType: 'boots', index: 15_691, price: 4800, isDonate: false },
    { id: 487, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 25, gender: 1, additional: 2, shopType: 'boots', index: 15_692, price: 4800, isDonate: false },
    { id: 487, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 25, gender: 1, additional: 3, shopType: 'boots', index: 15_693, price: 4800, isDonate: false },
    { id: 487, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 25, gender: 1, additional: 4, shopType: 'boots', index: 15_694, price: 4800, isDonate: false },

    { id: 488, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 26, gender: 1, additional: 0, shopType: 'boots', index: 15_700, price: 150, isDonate: true },
    { id: 488, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 26, gender: 1, additional: 1, shopType: 'boots', index: 15_701, price: 150, isDonate: true },
    { id: 488, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 26, gender: 1, additional: 2, shopType: 'boots', index: 15_702, price: 150, isDonate: true },
    { id: 488, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 26, gender: 1, additional: 3, shopType: 'boots', index: 15_703, price: 150, isDonate: true },
    { id: 488, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 26, gender: 1, additional: 4, shopType: 'boots', index: 15_704, price: 150, isDonate: true },

    { id: 489, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 27, gender: 1, additional: 0, shopType: 'boots', index: 15_710, price: 3200, isDonate: false },
    { id: 489, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 27, gender: 1, additional: 1, shopType: 'boots', index: 15_711, price: 3200, isDonate: false },
    { id: 489, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 27, gender: 1, additional: 2, shopType: 'boots', index: 15_712, price: 3200, isDonate: false },
    { id: 489, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 27, gender: 1, additional: 3, shopType: 'boots', index: 15_713, price: 3200, isDonate: false },
    { id: 489, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 27, gender: 1, additional: 4, shopType: 'boots', index: 15_714, price: 3200, isDonate: false },

    { id: 490, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 28, gender: 1, additional: 0, shopType: 'boots', index: 15_720, price: 3400, isDonate: false },
    { id: 490, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 28, gender: 1, additional: 1, shopType: 'boots', index: 15_721, price: 3400, isDonate: false },
    { id: 490, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 28, gender: 1, additional: 2, shopType: 'boots', index: 15_722, price: 3400, isDonate: false },
    { id: 490, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 28, gender: 1, additional: 3, shopType: 'boots', index: 15_723, price: 3400, isDonate: false },
    { id: 490, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 28, gender: 1, additional: 4, shopType: 'boots', index: 15_724, price: 3400, isDonate: false },

    { id: 491, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 29, gender: 1, additional: 0, shopType: 'boots', index: 15_730, price: 2700, isDonate: false },
    { id: 491, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 29, gender: 1, additional: 1, shopType: 'boots', index: 15_731, price: 2700, isDonate: false },
    { id: 491, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 29, gender: 1, additional: 2, shopType: 'boots', index: 15_732, price: 2700, isDonate: false },
    { id: 491, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 29, gender: 1, additional: 3, shopType: 'boots', index: 15_733, price: 2700, isDonate: false },
    { id: 491, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 29, gender: 1, additional: 4, shopType: 'boots', index: 15_734, price: 2700, isDonate: false },

    { id: 492, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 30, gender: 1, additional: 0, shopType: 'boots', index: 15_740, price: 5000, isDonate: false },
    { id: 492, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 30, gender: 1, additional: 1, shopType: 'boots', index: 15_741, price: 5000, isDonate: false },
    { id: 492, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 30, gender: 1, additional: 2, shopType: 'boots', index: 15_742, price: 5000, isDonate: false },
    { id: 492, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 30, gender: 1, additional: 3, shopType: 'boots', index: 15_743, price: 5000, isDonate: false },
    { id: 492, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 30, gender: 1, additional: 4, shopType: 'boots', index: 15_744, price: 5000, isDonate: false },

    { id: 493, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 31, gender: 1, additional: 0, shopType: 'boots', index: 15_750, price: 4500, isDonate: false },
    { id: 493, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 31, gender: 1, additional: 1, shopType: 'boots', index: 15_751, price: 4500, isDonate: false },
    { id: 493, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 31, gender: 1, additional: 2, shopType: 'boots', index: 15_752, price: 4500, isDonate: false },
    { id: 493, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 31, gender: 1, additional: 3, shopType: 'boots', index: 15_753, price: 4500, isDonate: false },
    { id: 493, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 31, gender: 1, additional: 4, shopType: 'boots', index: 15_754, price: 4500, isDonate: false },

    // { id: 494, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 32, gender: 1, additional: 0, shopType: 'boots', index: 15_760, price: 0, isDonate: false }, rezerv
    // { id: 495, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 33, gender: 1, additional: 0, shopType: 'boots', index: 15_770, price: 0, isDonate: false },
    // { id: 496, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 34, gender: 1, additional: 0, shopType: 'boots', index: 15_780, price: 0, isDonate: false },
    // { id: 497, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 35, gender: 1, additional: 0, shopType: 'boots', index: 15_790, price: 0, isDonate: false },
    // { id: 498, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 36, gender: 1, additional: 0, shopType: 'boots', index: 15_800, price: 0, isDonate: false },
    // { id: 499, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 37, gender: 1, additional: 0, shopType: 'boots', index: 15_810, price: 0, isDonate: false },
    // { id: 500, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 38, gender: 1, additional: 0, shopType: 'boots', index: 15_820, price: 0, isDonate: false },
    // { id: 501, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 39, gender: 1, additional: 0, shopType: 'boots', index: 15_830, price: 0, isDonate: false },
    // { id: 502, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 40, gender: 1, additional: 0, shopType: 'boots', index: 15_840, price: 0, isDonate: false },
    // { id: 503, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 41, gender: 1, additional: 0, shopType: 'boots', index: 15_850, price: 0, isDonate: false },
    // { id: 504, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 42, gender: 1, additional: 0, shopType: 'boots', index: 15_860, price: 0, isDonate: false },
    // { id: 505, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 43, gender: 1, additional: 0, shopType: 'boots', index: 15_870, price: 0, isDonate: false },
    // { id: 506, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 44, gender: 1, additional: 0, shopType: 'boots', index: 15_880, price: 0, isDonate: false },
    // { id: 507, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 45, gender: 1, additional: 0, shopType: 'boots', index: 15_890, price: 0, isDonate: false },
    // { id: 508, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 46, gender: 1, additional: 0, shopType: 'boots', index: 15_900, price: 0, isDonate: false },
    // { id: 509, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 47, gender: 1, additional: 0, shopType: 'boots', index: 15_910, price: 0, isDonate: false },
    // { id: 510, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 48, gender: 1, additional: 0, shopType: 'boots', index: 15_920, price: 0, isDonate: false },
    // { id: 511, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 49, gender: 1, additional: 0, shopType: 'boots', index: 15_930, price: 0, isDonate: false },
    // { id: 512, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 50, gender: 1, additional: 0, shopType: 'boots', index: 15_940, price: 0, isDonate: false },
    // { id: 513, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 51, gender: 1, additional: 0, shopType: 'boots', index: 15_950, price: 0, isDonate: false },
    // { id: 514, name: 'inventory.womens_shoes', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 3, clothesIndex: 52, gender: 1, additional: 0, shopType: 'boots', index: 15_960, price: 0, isDonate: false },

    // { id: 515, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 1, gender: 2, additional: 0, shopType: 'accessories', index: 20_000, price: 0, isDonate: false }, medic

    // { id: 516, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 2, gender: 2, additional: 0, shopType: 'accessories', index: 20_010, price: 4500, isDonate: false }, farm

    { id: 517, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 3, gender: 2, additional: 0, shopType: 'accessories', index: 20_020, price: 300, isDonate: true },
    { id: 517, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 3, gender: 2, additional: 1, shopType: 'accessories', index: 20_021, price: 300, isDonate: true },
    { id: 517, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 3, gender: 2, additional: 2, shopType: 'accessories', index: 20_022, price: 300, isDonate: true },
    { id: 517, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 3, gender: 2, additional: 3, shopType: 'accessories', index: 20_023, price: 300, isDonate: true },
    { id: 517, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 3, gender: 2, additional: 4, shopType: 'accessories', index: 20_024, price: 300, isDonate: true },
    { id: 517, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 3, gender: 2, additional: 5, shopType: 'accessories', index: 20_025, price: 300, isDonate: true },
    { id: 517, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 3, gender: 2, additional: 6, shopType: 'accessories', index: 20_026, price: 300, isDonate: true },

    // { id: 518, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 4, gender: 2, additional: 0, shopType: 'accessories', index: 20_030, price: 0, isDonate: false },
    { id: 518, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 4, gender: 2, additional: 1, shopType: 'accessories', index: 20_030, price: 350, isDonate: true },
    { id: 518, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 4, gender: 2, additional: 2, shopType: 'accessories', index: 20_031, price: 350, isDonate: true },
    { id: 518, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 4, gender: 2, additional: 3, shopType: 'accessories', index: 20_032, price: 350, isDonate: true },
    { id: 518, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 4, gender: 2, additional: 4, shopType: 'accessories', index: 20_033, price: 350, isDonate: true },
    { id: 518, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 4, gender: 2, additional: 5, shopType: 'accessories', index: 20_034, price: 350, isDonate: true },

    { id: 519, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 5, gender: 2, additional: 0, shopType: 'accessories', index: 20_040, price: 400, isDonate: true },
    { id: 519, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 5, gender: 2, additional: 1, shopType: 'accessories', index: 20_041, price: 400, isDonate: true },
    { id: 519, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 5, gender: 2, additional: 2, shopType: 'accessories', index: 20_042, price: 400, isDonate: true },
    { id: 519, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 5, gender: 2, additional: 3, shopType: 'accessories', index: 20_043, price: 400, isDonate: true },
    { id: 519, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 5, gender: 2, additional: 4, shopType: 'accessories', index: 20_044, price: 400, isDonate: true },

    { id: 520, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 6, gender: 2, additional: 0, shopType: 'accessories', index: 20_050, price: 500, isDonate: true },
    { id: 520, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 6, gender: 2, additional: 1, shopType: 'accessories', index: 20_051, price: 500, isDonate: true },
    { id: 520, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 6, gender: 2, additional: 2, shopType: 'accessories', index: 20_052, price: 500, isDonate: true },
    { id: 520, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 6, gender: 2, additional: 3, shopType: 'accessories', index: 20_053, price: 500, isDonate: true },
    { id: 520, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 6, gender: 2, additional: 4, shopType: 'accessories', index: 20_054, price: 500, isDonate: true },

    { id: 521, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 7, gender: 2, additional: 0, shopType: 'accessories', index: 20_060, price: 350, isDonate: true },
    { id: 521, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 7, gender: 2, additional: 1, shopType: 'accessories', index: 20_061, price: 350, isDonate: true },
    { id: 521, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 7, gender: 2, additional: 2, shopType: 'accessories', index: 20_062, price: 350, isDonate: true },
    { id: 521, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 7, gender: 2, additional: 3, shopType: 'accessories', index: 20_063, price: 350, isDonate: true },
    { id: 521, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 7, gender: 2, additional: 4, shopType: 'accessories', index: 20_064, price: 350, isDonate: true },

    { id: 522, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 8, gender: 2, additional: 0, shopType: 'accessories', index: 20_070, price: 300, isDonate: true },
    { id: 522, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 8, gender: 2, additional: 1, shopType: 'accessories', index: 20_071, price: 300, isDonate: true },
    { id: 522, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 8, gender: 2, additional: 2, shopType: 'accessories', index: 20_072, price: 300, isDonate: true },
    { id: 522, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 8, gender: 2, additional: 3, shopType: 'accessories', index: 20_073, price: 300, isDonate: true },
    { id: 522, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 8, gender: 2, additional: 4, shopType: 'accessories', index: 20_074, price: 300, isDonate: true },

    { id: 523, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 9, gender: 2, additional: 0, shopType: 'accessories', index: 20_080, price: 300, isDonate: true },
    { id: 523, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 9, gender: 2, additional: 1, shopType: 'accessories', index: 20_081, price: 300, isDonate: true },
    { id: 523, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 9, gender: 2, additional: 2, shopType: 'accessories', index: 20_082, price: 300, isDonate: true },
    { id: 523, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 9, gender: 2, additional: 3, shopType: 'accessories', index: 20_083, price: 300, isDonate: true },
    { id: 523, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 9, gender: 2, additional: 4, shopType: 'accessories', index: 20_084, price: 300, isDonate: true },

    // { id: 524, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 10, gender: 2, additional: 0, shopType: 'accessories', index: 20_090, price: 0, isDonate: false }, police
    // { id: 525, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 11, gender: 2, additional: 0, shopType: 'accessories', index: 20_100, price: 0, isDonate: false }, police

    { id: 526, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 12, gender: 2, additional: 0, shopType: 'accessories', index: 20_110, price: 250, isDonate: true },
    { id: 526, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 12, gender: 2, additional: 1, shopType: 'accessories', index: 20_111, price: 250, isDonate: true },
    { id: 526, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 12, gender: 2, additional: 2, shopType: 'accessories', index: 20_112, price: 250, isDonate: true },
    { id: 526, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 12, gender: 2, additional: 3, shopType: 'accessories', index: 20_113, price: 250, isDonate: true },
    { id: 526, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 12, gender: 2, additional: 4, shopType: 'accessories', index: 20_114, price: 250, isDonate: true },

    { id: 527, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 13, gender: 2, additional: 0, shopType: 'accessories', index: 20_120, price: 150, isDonate: true },
    { id: 527, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 13, gender: 2, additional: 1, shopType: 'accessories', index: 20_121, price: 150, isDonate: true },
    { id: 527, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 13, gender: 2, additional: 2, shopType: 'accessories', index: 20_122, price: 150, isDonate: true },
    { id: 527, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 13, gender: 2, additional: 3, shopType: 'accessories', index: 20_123, price: 150, isDonate: true },
    { id: 527, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 13, gender: 2, additional: 4, shopType: 'accessories', index: 20_124, price: 150, isDonate: true },

    { id: 528, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 14, gender: 2, additional: 0, shopType: 'accessories', index: 20_130, price: 200, isDonate: true },
    { id: 528, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 14, gender: 2, additional: 1, shopType: 'accessories', index: 20_131, price: 200, isDonate: true },
    { id: 528, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 14, gender: 2, additional: 2, shopType: 'accessories', index: 20_132, price: 200, isDonate: true },
    { id: 528, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 14, gender: 2, additional: 3, shopType: 'accessories', index: 20_133, price: 200, isDonate: true },
    { id: 528, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 14, gender: 2, additional: 4, shopType: 'accessories', index: 20_134, price: 200, isDonate: true },

    { id: 529, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 15, gender: 2, additional: 0, shopType: 'accessories', index: 20_140, price: 200, isDonate: true },
    { id: 529, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 15, gender: 2, additional: 1, shopType: 'accessories', index: 20_141, price: 200, isDonate: true },
    { id: 529, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 15, gender: 2, additional: 2, shopType: 'accessories', index: 20_142, price: 200, isDonate: true },
    { id: 529, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 15, gender: 2, additional: 3, shopType: 'accessories', index: 20_143, price: 200, isDonate: true },
    { id: 529, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 15, gender: 2, additional: 4, shopType: 'accessories', index: 20_144, price: 200, isDonate: true },

    { id: 530, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 16, gender: 2, additional: 0, shopType: 'accessories', index: 20_150, price: 400, isDonate: true },
    { id: 530, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 16, gender: 2, additional: 1, shopType: 'accessories', index: 20_151, price: 400, isDonate: true },
    { id: 530, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 16, gender: 2, additional: 2, shopType: 'accessories', index: 20_152, price: 400, isDonate: true },
    { id: 530, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 16, gender: 2, additional: 3, shopType: 'accessories', index: 20_153, price: 400, isDonate: true },
    { id: 530, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 16, gender: 2, additional: 4, shopType: 'accessories', index: 20_154, price: 400, isDonate: true },
    { id: 530, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 16, gender: 2, additional: 5, shopType: 'accessories', index: 20_155, price: 400, isDonate: true },
    { id: 530, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 16, gender: 2, additional: 6, shopType: 'accessories', index: 20_156, price: 400, isDonate: true },

    { id: 531, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 17, gender: 2, additional: 0, shopType: 'accessories', index: 20_160, price: 5000, isDonate: false },
    { id: 531, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 17, gender: 2, additional: 1, shopType: 'accessories', index: 20_161, price: 5000, isDonate: false },
    { id: 531, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 17, gender: 2, additional: 2, shopType: 'accessories', index: 20_162, price: 5000, isDonate: false },
    { id: 531, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 17, gender: 2, additional: 3, shopType: 'accessories', index: 20_163, price: 5000, isDonate: false },
    { id: 531, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 17, gender: 2, additional: 4, shopType: 'accessories', index: 20_164, price: 5000, isDonate: false },

    { id: 532, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 18, gender: 2, additional: 0, shopType: 'accessories', index: 20_170, price: 300, isDonate: true },
    { id: 532, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 18, gender: 2, additional: 1, shopType: 'accessories', index: 20_171, price: 300, isDonate: true },
    { id: 532, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 18, gender: 2, additional: 2, shopType: 'accessories', index: 20_172, price: 300, isDonate: true },
    { id: 532, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 18, gender: 2, additional: 3, shopType: 'accessories', index: 20_173, price: 300, isDonate: true },
    { id: 532, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 18, gender: 2, additional: 4, shopType: 'accessories', index: 20_174, price: 300, isDonate: true },

    { id: 533, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 19, gender: 2, additional: 0, shopType: 'accessories', index: 20_180, price: 500, isDonate: true },
    { id: 533, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 19, gender: 2, additional: 1, shopType: 'accessories', index: 20_181, price: 500, isDonate: true },
    { id: 533, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 19, gender: 2, additional: 2, shopType: 'accessories', index: 20_182, price: 500, isDonate: true },
    { id: 533, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 19, gender: 2, additional: 3, shopType: 'accessories', index: 20_183, price: 500, isDonate: true },
    { id: 533, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 19, gender: 2, additional: 4, shopType: 'accessories', index: 20_184, price: 500, isDonate: true },

    { id: 534, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 20, gender: 2, additional: 0, shopType: 'accessories', index: 20_190, price: 4700, isDonate: false },
    { id: 534, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 20, gender: 2, additional: 1, shopType: 'accessories', index: 20_191, price: 4700, isDonate: false },
    { id: 534, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 20, gender: 2, additional: 2, shopType: 'accessories', index: 20_192, price: 4700, isDonate: false },
    { id: 534, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 20, gender: 2, additional: 3, shopType: 'accessories', index: 20_193, price: 4700, isDonate: false },
    { id: 534, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 20, gender: 2, additional: 4, shopType: 'accessories', index: 20_194, price: 4700, isDonate: false },

    { id: 535, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 21, gender: 2, additional: 0, shopType: 'accessories', index: 20_200, price: 500, isDonate: true },
    { id: 535, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 21, gender: 2, additional: 1, shopType: 'accessories', index: 20_201, price: 500, isDonate: true },
    { id: 535, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 21, gender: 2, additional: 2, shopType: 'accessories', index: 20_202, price: 500, isDonate: true },
    { id: 535, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 21, gender: 2, additional: 3, shopType: 'accessories', index: 20_203, price: 500, isDonate: true },
    { id: 535, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 21, gender: 2, additional: 4, shopType: 'accessories', index: 20_204, price: 500, isDonate: true },
    { id: 535, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 21, gender: 2, additional: 5, shopType: 'accessories', index: 20_205, price: 500, isDonate: true },
    { id: 535, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 21, gender: 2, additional: 6, shopType: 'accessories', index: 20_206, price: 500, isDonate: true },

    { id: 536, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 22, gender: 2, additional: 0, shopType: 'accessories', index: 20_210, price: 200, isDonate: false },
    { id: 536, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 22, gender: 2, additional: 1, shopType: 'accessories', index: 20_211, price: 200, isDonate: false },
    { id: 536, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 22, gender: 2, additional: 2, shopType: 'accessories', index: 20_212, price: 200, isDonate: false },
    { id: 536, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 22, gender: 2, additional: 3, shopType: 'accessories', index: 20_213, price: 200, isDonate: false },
    { id: 536, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 22, gender: 2, additional: 4, shopType: 'accessories', index: 20_214, price: 200, isDonate: false },

    { id: 537, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 23, gender: 2, additional: 0, shopType: 'accessories', index: 20_220, price: 150, isDonate: true },
    { id: 537, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 23, gender: 2, additional: 1, shopType: 'accessories', index: 20_221, price: 150, isDonate: true },
    { id: 537, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 23, gender: 2, additional: 2, shopType: 'accessories', index: 20_222, price: 150, isDonate: true },
    { id: 537, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 23, gender: 2, additional: 3, shopType: 'accessories', index: 20_223, price: 150, isDonate: true },
    { id: 537, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 23, gender: 2, additional: 4, shopType: 'accessories', index: 20_224, price: 150, isDonate: true },

    { id: 538, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 24, gender: 2, additional: 0, shopType: 'accessories', index: 20_230, price: 500, isDonate: true },
    { id: 538, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 24, gender: 2, additional: 1, shopType: 'accessories', index: 20_231, price: 500, isDonate: true },
    { id: 538, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 24, gender: 2, additional: 2, shopType: 'accessories', index: 20_232, price: 500, isDonate: true },
    { id: 538, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 24, gender: 2, additional: 3, shopType: 'accessories', index: 20_233, price: 500, isDonate: true },
    { id: 538, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 24, gender: 2, additional: 4, shopType: 'accessories', index: 20_234, price: 500, isDonate: true },

    { id: 539, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 25, gender: 2, additional: 0, shopType: 'accessories', index: 20_240, price: 150, isDonate: true },
    { id: 539, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 25, gender: 2, additional: 1, shopType: 'accessories', index: 20_241, price: 150, isDonate: true },
    { id: 539, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 25, gender: 2, additional: 2, shopType: 'accessories', index: 20_242, price: 150, isDonate: true },
    { id: 539, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 25, gender: 2, additional: 3, shopType: 'accessories', index: 20_243, price: 150, isDonate: true },
    { id: 539, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 25, gender: 2, additional: 4, shopType: 'accessories', index: 20_244, price: 150, isDonate: true },

    { id: 540, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 26, gender: 2, additional: 0, shopType: 'accessories', index: 20_250, price: 150, isDonate: true },
    { id: 540, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 26, gender: 2, additional: 1, shopType: 'accessories', index: 20_251, price: 150, isDonate: true },
    { id: 540, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 26, gender: 2, additional: 2, shopType: 'accessories', index: 20_252, price: 150, isDonate: true },
    { id: 540, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 26, gender: 2, additional: 3, shopType: 'accessories', index: 20_253, price: 150, isDonate: true },
    { id: 540, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 26, gender: 2, additional: 4, shopType: 'accessories', index: 20_254, price: 150, isDonate: true },

    { id: 541, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 27, gender: 2, additional: 0, shopType: 'accessories', index: 20_260, price: 6000, isDonate: false },
    { id: 541, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 27, gender: 2, additional: 1, shopType: 'accessories', index: 20_261, price: 6000, isDonate: false },
    { id: 541, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 27, gender: 2, additional: 2, shopType: 'accessories', index: 20_262, price: 6000, isDonate: false },
    { id: 541, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 27, gender: 2, additional: 3, shopType: 'accessories', index: 20_263, price: 6000, isDonate: false },
    { id: 541, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 27, gender: 2, additional: 4, shopType: 'accessories', index: 20_264, price: 6000, isDonate: false },

    { id: 542, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 28, gender: 2, additional: 0, shopType: 'accessories', index: 20_270, price: 500, isDonate: true },
    { id: 542, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 28, gender: 2, additional: 1, shopType: 'accessories', index: 20_271, price: 500, isDonate: true },
    { id: 542, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 28, gender: 2, additional: 2, shopType: 'accessories', index: 20_272, price: 500, isDonate: true },
    { id: 542, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 28, gender: 2, additional: 3, shopType: 'accessories', index: 20_273, price: 500, isDonate: true },
    { id: 542, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 28, gender: 2, additional: 4, shopType: 'accessories', index: 20_274, price: 500, isDonate: true },

    { id: 543, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 29, gender: 2, additional: 0, shopType: 'accessories', index: 20_280, price: 500, isDonate: true },
    { id: 543, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 29, gender: 2, additional: 1, shopType: 'accessories', index: 20_281, price: 500, isDonate: true },
    { id: 543, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 29, gender: 2, additional: 2, shopType: 'accessories', index: 20_282, price: 500, isDonate: true },
    { id: 543, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 29, gender: 2, additional: 3, shopType: 'accessories', index: 20_283, price: 500, isDonate: true },
    { id: 543, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 29, gender: 2, additional: 4, shopType: 'accessories', index: 20_284, price: 500, isDonate: true },

    { id: 544, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 30, gender: 2, additional: 0, shopType: 'accessories', index: 20_290, price: 500, isDonate: true },
    { id: 544, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 30, gender: 2, additional: 1, shopType: 'accessories', index: 20_291, price: 500, isDonate: true },
    { id: 544, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 30, gender: 2, additional: 2, shopType: 'accessories', index: 20_292, price: 500, isDonate: true },
    { id: 544, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 30, gender: 2, additional: 3, shopType: 'accessories', index: 20_293, price: 500, isDonate: true },
    { id: 544, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 30, gender: 2, additional: 4, shopType: 'accessories', index: 20_294, price: 500, isDonate: true },
    { id: 544, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 30, gender: 2, additional: 5, shopType: 'accessories', index: 20_295, price: 500, isDonate: true },
    { id: 544, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 30, gender: 2, additional: 6, shopType: 'accessories', index: 20_296, price: 500, isDonate: true },

    { id: 545, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 31, gender: 2, additional: 0, shopType: 'accessories', index: 20_300, price: 450, isDonate: true },
    { id: 545, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 31, gender: 2, additional: 1, shopType: 'accessories', index: 20_301, price: 450, isDonate: true },
    { id: 545, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 31, gender: 2, additional: 2, shopType: 'accessories', index: 20_302, price: 450, isDonate: true },
    { id: 545, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 31, gender: 2, additional: 3, shopType: 'accessories', index: 20_303, price: 450, isDonate: true },
    { id: 545, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 31, gender: 2, additional: 4, shopType: 'accessories', index: 20_304, price: 450, isDonate: true },

    { id: 546, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 32, gender: 2, additional: 0, shopType: 'accessories', index: 20_310, price: 400, isDonate: true },
    { id: 546, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 32, gender: 2, additional: 1, shopType: 'accessories', index: 20_311, price: 400, isDonate: true },
    { id: 546, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 32, gender: 2, additional: 2, shopType: 'accessories', index: 20_312, price: 400, isDonate: true },
    { id: 546, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 32, gender: 2, additional: 3, shopType: 'accessories', index: 20_313, price: 400, isDonate: true },
    { id: 546, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 32, gender: 2, additional: 4, shopType: 'accessories', index: 20_314, price: 400, isDonate: true },

    { id: 547, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 33, gender: 2, additional: 0, shopType: 'accessories', index: 20_320, price: 450, isDonate: true },
    { id: 547, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 33, gender: 2, additional: 1, shopType: 'accessories', index: 20_321, price: 450, isDonate: true },
    { id: 547, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 33, gender: 2, additional: 2, shopType: 'accessories', index: 20_322, price: 450, isDonate: true },
    { id: 547, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 33, gender: 2, additional: 3, shopType: 'accessories', index: 20_323, price: 450, isDonate: true },
    { id: 547, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 33, gender: 2, additional: 4, shopType: 'accessories', index: 20_324, price: 450, isDonate: true },

    { id: 548, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 34, gender: 2, additional: 0, shopType: 'accessories', index: 20_330, price: 500, isDonate: true },
    { id: 548, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 34, gender: 2, additional: 1, shopType: 'accessories', index: 20_331, price: 500, isDonate: true },
    { id: 548, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 34, gender: 2, additional: 2, shopType: 'accessories', index: 20_332, price: 500, isDonate: true },
    { id: 548, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 34, gender: 2, additional: 3, shopType: 'accessories', index: 20_333, price: 500, isDonate: true },
    { id: 548, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 34, gender: 2, additional: 4, shopType: 'accessories', index: 20_334, price: 500, isDonate: true },

    // { id: 549, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 35, gender: 2, additional: 0, shopType: 'accessories', index: 20_340, price: 0, isDonate: false }, police
    // { id: 550, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 36, gender: 2, additional: 0, shopType: 'accessories', index: 20_350, price: 0, isDonate: false },
    // { id: 551, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 37, gender: 2, additional: 0, shopType: 'accessories', index: 20_360, price: 0, isDonate: false },

    { id: 552, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 38, gender: 2, additional: 0, shopType: 'accessories', index: 20_370, price: 350, isDonate: true },
    { id: 552, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 38, gender: 2, additional: 1, shopType: 'accessories', index: 20_371, price: 350, isDonate: true },
    { id: 552, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 38, gender: 2, additional: 2, shopType: 'accessories', index: 20_372, price: 350, isDonate: true },
    { id: 552, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 38, gender: 2, additional: 3, shopType: 'accessories', index: 20_373, price: 350, isDonate: true },
    { id: 552, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 38, gender: 2, additional: 4, shopType: 'accessories', index: 20_374, price: 350, isDonate: true },
    { id: 552, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 38, gender: 2, additional: 5, shopType: 'accessories', index: 20_375, price: 350, isDonate: true },
    { id: 552, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 38, gender: 2, additional: 6, shopType: 'accessories', index: 20_376, price: 350, isDonate: true },
    { id: 552, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 38, gender: 2, additional: 7, shopType: 'accessories', index: 20_377, price: 350, isDonate: true },
    { id: 552, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 38, gender: 2, additional: 8, shopType: 'accessories', index: 20_378, price: 350, isDonate: true },

    { id: 553, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 39, gender: 2, additional: 0, shopType: 'accessories', index: 20_380, price: 400, isDonate: true },
    { id: 553, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 39, gender: 2, additional: 1, shopType: 'accessories', index: 20_381, price: 400, isDonate: true },
    { id: 553, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 39, gender: 2, additional: 2, shopType: 'accessories', index: 20_382, price: 400, isDonate: true },
    { id: 553, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 39, gender: 2, additional: 3, shopType: 'accessories', index: 20_383, price: 400, isDonate: true },
    { id: 553, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 39, gender: 2, additional: 4, shopType: 'accessories', index: 20_384, price: 400, isDonate: true },

    { id: 554, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 40, gender: 2, additional: 0, shopType: 'accessories', index: 20_390, price: 450, isDonate: true },
    { id: 554, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 40, gender: 2, additional: 1, shopType: 'accessories', index: 20_391, price: 450, isDonate: true },
    { id: 554, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 40, gender: 2, additional: 2, shopType: 'accessories', index: 20_392, price: 450, isDonate: true },
    { id: 554, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 40, gender: 2, additional: 3, shopType: 'accessories', index: 20_393, price: 450, isDonate: true },
    { id: 554, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 40, gender: 2, additional: 4, shopType: 'accessories', index: 20_394, price: 450, isDonate: true },

    // { id: 555, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 41, gender: 2, additional: 0, shopType: 'accessories', index: 20_400, price: 0, isDonate: false }, gos
    // { id: 556, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 42, gender: 2, additional: 0, shopType: 'accessories', index: 20_410, price: 0, isDonate: false }, gos
    // { id: 557, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 43, gender: 2, additional: 0, shopType: 'accessories', index: 20_420, price: 0, isDonate: false }, gos
    // { id: 558, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 44, gender: 2, additional: 0, shopType: 'accessories', index: 20_430, price: 0, isDonate: false },rezerv
    // { id: 559, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 45, gender: 2, additional: 0, shopType: 'accessories', index: 20_440, price: 0, isDonate: false },
    // { id: 560, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 46, gender: 2, additional: 0, shopType: 'accessories', index: 20_450, price: 0, isDonate: false },
    // { id: 561, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 47, gender: 2, additional: 0, shopType: 'accessories', index: 20_460, price: 0, isDonate: false },
    // { id: 562, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 48, gender: 2, additional: 0, shopType: 'accessories', index: 20_470, price: 0, isDonate: false },
    // { id: 563, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 49, gender: 2, additional: 0, shopType: 'accessories', index: 20_480, price: 0, isDonate: false },
    // { id: 564, name: 'inventory.accessories', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 4, clothesIndex: 50, gender: 2, additional: 0, shopType: 'accessories', index: 20_490, price: 0, isDonate: false },
    { id: 565, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 1, gender: 2, additional: 4, shopType: 'hat', index: 30_004, price: 4500, isDonate: false },

    { id: 566, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 2, gender: 2, additional: 4, shopType: 'hat', index: 30_014, price: 4500, isDonate: false },

    // { id: 567, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 3, gender: 2, additional: 0, shopType: 'hat', index: 30_020, price: 0, isDonate: false }, gos
    // { id: 568, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 4, gender: 2, additional: 0, shopType: 'hat', index: 30_030, price: 0, isDonate: false }, gos
    // { id: 569, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesindex: 5, gender: 2, additional: 0, shopType: 'hat', index: 30_040, price: 0, isDonate: false }, gos

    { id: 570, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 6, gender: 2, additional: 2, shopType: 'hat', index: 30_052, price: 3700, isDonate: false },
    { id: 570, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 6, gender: 2, additional: 3, shopType: 'hat', index: 30_053, price: 3700, isDonate: false },
    { id: 570, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 6, gender: 2, additional: 4, shopType: 'hat', index: 30_054, price: 3700, isDonate: false },

    { id: 571, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 7, gender: 2, additional: 2, shopType: 'hat', index: 30_062, price: 3700, isDonate: false },
    { id: 571, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 7, gender: 2, additional: 3, shopType: 'hat', index: 30_063, price: 3700, isDonate: false },
    { id: 571, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 7, gender: 2, additional: 4, shopType: 'hat', index: 30_064, price: 3700, isDonate: false },

    // { id: 572, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 8, gender: 2, additional: 0, shopType: 'hat', index: 30_070, price: 0, isDonate: false }, medic
    // { id: 573, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 9, gender: 2, additional: 0, shopType: 'hat', index: 30_080, price: 0, isDonate: false }, medic

    { id: 574, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 10, gender: 2, additional: 0, shopType: 'hat', index: 30_090, price: 1200, isDonate: false },
    { id: 574, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 10, gender: 2, additional: 1, shopType: 'hat', index: 30_091, price: 1200, isDonate: false },
    { id: 574, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 10, gender: 2, additional: 2, shopType: 'hat', index: 30_092, price: 1200, isDonate: false },
    { id: 574, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 10, gender: 2, additional: 3, shopType: 'hat', index: 30_093, price: 1200, isDonate: false },
    { id: 574, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 10, gender: 2, additional: 4, shopType: 'hat', index: 30_094, price: 1200, isDonate: false },

    // { id: 575, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 11, gender: 2, additional: 0, shopType: 'hat', index: 30_100, price: 0, isDonate: false },
    { id: 576, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 12, gender: 2, additional: 0, shopType: 'hat', index: 30_110, price: 1100, isDonate: false },
    { id: 576, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 12, gender: 2, additional: 1, shopType: 'hat', index: 30_111, price: 1100, isDonate: false },
    { id: 576, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 12, gender: 2, additional: 2, shopType: 'hat', index: 30_112, price: 1100, isDonate: false },
    { id: 576, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 12, gender: 2, additional: 3, shopType: 'hat', index: 30_113, price: 1100, isDonate: false },
    { id: 576, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 12, gender: 2, additional: 4, shopType: 'hat', index: 30_114, price: 1100, isDonate: false },

    { id: 577, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 13, gender: 2, additional: 0, shopType: 'hat', index: 30_120, price: 800, isDonate: false },
    { id: 577, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 13, gender: 2, additional: 1, shopType: 'hat', index: 30_121, price: 800, isDonate: false },
    { id: 577, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 13, gender: 2, additional: 2, shopType: 'hat', index: 30_122, price: 800, isDonate: false },
    { id: 577, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 13, gender: 2, additional: 3, shopType: 'hat', index: 30_123, price: 800, isDonate: false },
    { id: 577, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 13, gender: 2, additional: 4, shopType: 'hat', index: 30_124, price: 800, isDonate: false },

    { id: 578, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 14, gender: 2, additional: 0, shopType: 'hat', index: 30_130, price: 1400, isDonate: false },
    { id: 578, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 14, gender: 2, additional: 1, shopType: 'hat', index: 30_131, price: 1400, isDonate: false },
    { id: 578, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 14, gender: 2, additional: 2, shopType: 'hat', index: 30_132, price: 1400, isDonate: false },
    { id: 578, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 14, gender: 2, additional: 3, shopType: 'hat', index: 30_133, price: 1400, isDonate: false },
    { id: 578, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 14, gender: 2, additional: 4, shopType: 'hat', index: 30_134, price: 1400, isDonate: false },

    { id: 579, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 15, gender: 2, additional: 0, shopType: 'hat', index: 30_140, price: 2200, isDonate: false },
    { id: 579, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 15, gender: 2, additional: 1, shopType: 'hat', index: 30_141, price: 2200, isDonate: false },
    { id: 579, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 15, gender: 2, additional: 2, shopType: 'hat', index: 30_142, price: 2200, isDonate: false },
    { id: 579, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 15, gender: 2, additional: 3, shopType: 'hat', index: 30_143, price: 2200, isDonate: false },
    { id: 579, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 15, gender: 2, additional: 4, shopType: 'hat', index: 30_144, price: 2200, isDonate: false },

    // { id: 580, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 16, gender: 2, additional: 0, shopType: 'hat', index: 30_150, price: 0, isDonate: false }, media
    // { id: 581, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 17, gender: 2, additional: 0, shopType: 'hat', index: 30_160, price: 0, isDonate: false }, army

    { id: 582, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 18, gender: 2, additional: 0, shopType: 'hat', index: 30_170, price: 1500, isDonate: false },
    { id: 582, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 18, gender: 2, additional: 1, shopType: 'hat', index: 30_171, price: 1500, isDonate: false },
    { id: 582, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 18, gender: 2, additional: 2, shopType: 'hat', index: 30_172, price: 1500, isDonate: false },
    { id: 582, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 18, gender: 2, additional: 3, shopType: 'hat', index: 30_173, price: 1500, isDonate: false },
    { id: 582, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 18, gender: 2, additional: 4, shopType: 'hat', index: 30_174, price: 1500, isDonate: false },

    // { id: 583, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 19, gender: 2, additional: 0, shopType: 'hat', index: 30_180, price: 0, isDonate: false }, police

    // { id: 584, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 20, gender: 2, additional: 0, shopType: 'hat', index: 30_190, price: 0, isDonate: false }, bp
    // { id: 585, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 21, gender: 2, additional: 0, shopType: 'hat', index: 30_200, price: 0, isDonate: false }, police

    { id: 586, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 22, gender: 2, additional: 0, shopType: 'hat', index: 30_210, price: 2200, isDonate: false },
    { id: 586, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 22, gender: 2, additional: 1, shopType: 'hat', index: 30_211, price: 2200, isDonate: false },
    { id: 586, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 22, gender: 2, additional: 2, shopType: 'hat', index: 30_212, price: 2200, isDonate: false },
    { id: 586, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 22, gender: 2, additional: 3, shopType: 'hat', index: 30_213, price: 2200, isDonate: false },
    { id: 586, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 22, gender: 2, additional: 4, shopType: 'hat', index: 30_214, price: 2200, isDonate: false },

    { id: 587, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 23, gender: 2, additional: 0, shopType: 'hat', index: 30_220, price: 1100, isDonate: false },
    { id: 587, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 23, gender: 2, additional: 1, shopType: 'hat', index: 30_221, price: 1100, isDonate: false },
    { id: 587, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 23, gender: 2, additional: 2, shopType: 'hat', index: 30_222, price: 1100, isDonate: false },
    { id: 587, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 23, gender: 2, additional: 3, shopType: 'hat', index: 30_223, price: 1100, isDonate: false },
    { id: 587, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 23, gender: 2, additional: 4, shopType: 'hat', index: 30_224, price: 1100, isDonate: false },

    // { id: 588, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 24, gender: 2, additional: 0, shopType: 'hat', index: 30_230, price: 0, isDonate: false },
    { id: 588, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 24, gender: 2, additional: 1, shopType: 'hat', index: 30_230, price: 1800, isDonate: false },
    { id: 588, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 24, gender: 2, additional: 2, shopType: 'hat', index: 30_231, price: 1800, isDonate: false },
    { id: 588, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 24, gender: 2, additional: 3, shopType: 'hat', index: 30_232, price: 1800, isDonate: false },
    { id: 588, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 24, gender: 2, additional: 4, shopType: 'hat', index: 30_233, price: 1800, isDonate: false },

    { id: 589, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 25, gender: 2, additional: 0, shopType: 'hat', index: 30_240, price: 2500, isDonate: false },
    { id: 589, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 25, gender: 2, additional: 1, shopType: 'hat', index: 30_241, price: 2500, isDonate: false },
    { id: 589, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 25, gender: 2, additional: 2, shopType: 'hat', index: 30_242, price: 2500, isDonate: false },
    { id: 589, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 25, gender: 2, additional: 3, shopType: 'hat', index: 30_243, price: 2500, isDonate: false },
    { id: 589, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 25, gender: 2, additional: 4, shopType: 'hat', index: 30_244, price: 2500, isDonate: false },

    { id: 590, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 26, gender: 2, additional: 0, shopType: 'hat', index: 30_250, price: 2600, isDonate: false },
    { id: 590, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 26, gender: 2, additional: 1, shopType: 'hat', index: 30_251, price: 2600, isDonate: false },
    { id: 590, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 26, gender: 2, additional: 2, shopType: 'hat', index: 30_252, price: 2600, isDonate: false },
    { id: 590, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 26, gender: 2, additional: 3, shopType: 'hat', index: 30_253, price: 2600, isDonate: false },
    { id: 590, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 26, gender: 2, additional: 4, shopType: 'hat', index: 30_254, price: 2600, isDonate: false },
    // { id: 591, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 27, gender: 2, additional: 0, shopType: 'hat', index: 30_260, price: 0, isDonate: false }, police
    // { id: 592, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 28, gender: 2, additional: 0, shopType: 'hat', index: 30_270, price: 0, isDonate: false }, army
    { id: 593, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 29, gender: 2, additional: 0, shopType: 'hat', index: 30_280, price: 1800, isDonate: false },
    { id: 593, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 29, gender: 2, additional: 1, shopType: 'hat', index: 30_281, price: 1800, isDonate: false },
    { id: 593, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 29, gender: 2, additional: 2, shopType: 'hat', index: 30_282, price: 1800, isDonate: false },
    { id: 593, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 29, gender: 2, additional: 3, shopType: 'hat', index: 30_283, price: 1800, isDonate: false },
    { id: 593, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 29, gender: 2, additional: 4, shopType: 'hat', index: 30_284, price: 1800, isDonate: false },

    { id: 594, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 30, gender: 2, additional: 0, shopType: 'hat', index: 30_290, price: 2900, isDonate: false },
    { id: 594, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 30, gender: 2, additional: 1, shopType: 'hat', index: 30_291, price: 2900, isDonate: false },
    { id: 594, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 30, gender: 2, additional: 2, shopType: 'hat', index: 30_292, price: 2900, isDonate: false },
    { id: 594, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 30, gender: 2, additional: 3, shopType: 'hat', index: 30_293, price: 2900, isDonate: false },
    { id: 594, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 30, gender: 2, additional: 4, shopType: 'hat', index: 30_294, price: 2900, isDonate: false },

    { id: 595, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 31, gender: 2, additional: 0, shopType: 'hat', index: 30_300, price: 3100, isDonate: false },
    { id: 595, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 31, gender: 2, additional: 1, shopType: 'hat', index: 30_301, price: 3100, isDonate: false },
    { id: 595, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 31, gender: 2, additional: 2, shopType: 'hat', index: 30_302, price: 3100, isDonate: false },
    { id: 595, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 31, gender: 2, additional: 3, shopType: 'hat', index: 30_303, price: 3100, isDonate: false },
    { id: 595, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 31, gender: 2, additional: 4, shopType: 'hat', index: 30_304, price: 3100, isDonate: false },

    // { id: 596, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 32, gender: 2, additional: 0, shopType: 'hat', index: 30_310, price: 0, isDonate: false },
    { id: 596, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 32, gender: 2, additional: 1, shopType: 'hat', index: 30_310, price: 4000, isDonate: false },
    { id: 596, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 32, gender: 2, additional: 2, shopType: 'hat', index: 30_310, price: 4000, isDonate: false },
    { id: 596, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 32, gender: 2, additional: 3, shopType: 'hat', index: 30_310, price: 4000, isDonate: false },
    { id: 596, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 32, gender: 2, additional: 4, shopType: 'hat', index: 30_310, price: 4000, isDonate: false },

    { id: 597, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 33, gender: 2, additional: 0, shopType: 'hat', index: 30_320, price: 600, isDonate: false },
    { id: 597, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 33, gender: 2, additional: 1, shopType: 'hat', index: 30_321, price: 600, isDonate: false },
    { id: 597, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 33, gender: 2, additional: 2, shopType: 'hat', index: 30_322, price: 600, isDonate: false },
    { id: 597, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 33, gender: 2, additional: 3, shopType: 'hat', index: 30_323, price: 600, isDonate: false },
    { id: 597, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 33, gender: 2, additional: 4, shopType: 'hat', index: 30_324, price: 600, isDonate: false },

    { id: 598, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 34, gender: 2, additional: 0, shopType: 'hat', index: 30_330, price: 2200, isDonate: false },
    { id: 598, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 34, gender: 2, additional: 1, shopType: 'hat', index: 30_331, price: 2200, isDonate: false },
    { id: 598, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 34, gender: 2, additional: 2, shopType: 'hat', index: 30_332, price: 2200, isDonate: false },
    { id: 598, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 34, gender: 2, additional: 3, shopType: 'hat', index: 30_333, price: 2200, isDonate: false },
    { id: 598, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 34, gender: 2, additional: 4, shopType: 'hat', index: 30_334, price: 2200, isDonate: false },

    { id: 599, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 35, gender: 2, additional: 0, shopType: 'hat', index: 30_340, price: 900, isDonate: false },
    { id: 599, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 35, gender: 2, additional: 1, shopType: 'hat', index: 30_341, price: 900, isDonate: false },
    { id: 599, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 35, gender: 2, additional: 2, shopType: 'hat', index: 30_342, price: 900, isDonate: false },
    { id: 599, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 35, gender: 2, additional: 3, shopType: 'hat', index: 30_343, price: 900, isDonate: false },
    { id: 599, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 35, gender: 2, additional: 4, shopType: 'hat', index: 30_344, price: 900, isDonate: false },

    // { id: 600, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 36, gender: 2, additional: 0, shopType: 'hat', index: 30_350, price: 0, isDonate: false }, army
    // { id: 601, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 37, gender: 2, additional: 0, shopType: 'hat', index: 30_360, price: 0, isDonate: false }, work miner

    { id: 602, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 38, gender: 2, additional: 0, shopType: 'hat', index: 30_370, price: 2600, isDonate: false },
    { id: 602, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 38, gender: 2, additional: 1, shopType: 'hat', index: 30_371, price: 2600, isDonate: false },
    { id: 602, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 38, gender: 2, additional: 2, shopType: 'hat', index: 30_372, price: 2600, isDonate: false },
    { id: 602, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 38, gender: 2, additional: 3, shopType: 'hat', index: 30_373, price: 2600, isDonate: false },
    { id: 602, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 38, gender: 2, additional: 4, shopType: 'hat', index: 30_374, price: 2600, isDonate: false },

    { id: 603, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 39, gender: 2, additional: 0, shopType: 'hat', index: 30_380, price: 4500, isDonate: false },
    { id: 603, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 39, gender: 2, additional: 1, shopType: 'hat', index: 30_381, price: 4500, isDonate: false },
    { id: 603, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 39, gender: 2, additional: 2, shopType: 'hat', index: 30_382, price: 4500, isDonate: false },
    { id: 603, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 39, gender: 2, additional: 3, shopType: 'hat', index: 30_383, price: 4500, isDonate: false },
    { id: 603, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 39, gender: 2, additional: 4, shopType: 'hat', index: 30_384, price: 4500, isDonate: false },

    { id: 604, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 40, gender: 2, additional: 0, shopType: 'hat', index: 30_390, price: 150, isDonate: true },
    { id: 604, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 40, gender: 2, additional: 1, shopType: 'hat', index: 30_391, price: 150, isDonate: true },
    { id: 604, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 40, gender: 2, additional: 2, shopType: 'hat', index: 30_392, price: 150, isDonate: true },
    { id: 604, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 40, gender: 2, additional: 3, shopType: 'hat', index: 30_393, price: 150, isDonate: true },
    { id: 604, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 40, gender: 2, additional: 4, shopType: 'hat', index: 30_394, price: 150, isDonate: true },

    { id: 605, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 41, gender: 2, additional: 0, shopType: 'hat', index: 30_400, price: 100, isDonate: true },
    { id: 605, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 41, gender: 2, additional: 1, shopType: 'hat', index: 30_401, price: 100, isDonate: true },
    { id: 605, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 41, gender: 2, additional: 2, shopType: 'hat', index: 30_402, price: 100, isDonate: true },
    { id: 605, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 41, gender: 2, additional: 3, shopType: 'hat', index: 30_403, price: 100, isDonate: true },
    { id: 605, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 41, gender: 2, additional: 4, shopType: 'hat', index: 30_404, price: 100, isDonate: true },

    { id: 606, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 42, gender: 2, additional: 0, shopType: 'hat', index: 30_410, price: 3000, isDonate: false },
    { id: 606, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 42, gender: 2, additional: 1, shopType: 'hat', index: 30_411, price: 3000, isDonate: false },
    { id: 606, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 42, gender: 2, additional: 2, shopType: 'hat', index: 30_412, price: 3000, isDonate: false },
    { id: 606, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 42, gender: 2, additional: 3, shopType: 'hat', index: 30_413, price: 3000, isDonate: false },
    { id: 606, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 42, gender: 2, additional: 4, shopType: 'hat', index: 30_414, price: 3000, isDonate: false },

    { id: 607, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 43, gender: 2, additional: 0, shopType: 'hat', index: 30_420, price: 3200, isDonate: false },
    { id: 607, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 43, gender: 2, additional: 1, shopType: 'hat', index: 30_421, price: 3200, isDonate: false },
    { id: 607, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 43, gender: 2, additional: 2, shopType: 'hat', index: 30_422, price: 3200, isDonate: false },
    { id: 607, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 43, gender: 2, additional: 3, shopType: 'hat', index: 30_423, price: 3200, isDonate: false },
    { id: 607, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 43, gender: 2, additional: 4, shopType: 'hat', index: 30_424, price: 3200, isDonate: false },
    { id: 607, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 43, gender: 2, additional: 5, shopType: 'hat', index: 30_425, price: 3200, isDonate: false },

    { id: 608, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 44, gender: 2, additional: 0, shopType: 'hat', index: 30_430, price: 1000, isDonate: false },
    { id: 608, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 44, gender: 2, additional: 1, shopType: 'hat', index: 30_431, price: 1000, isDonate: false },
    { id: 608, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 44, gender: 2, additional: 2, shopType: 'hat', index: 30_432, price: 1000, isDonate: false },
    { id: 608, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 44, gender: 2, additional: 3, shopType: 'hat', index: 30_433, price: 1000, isDonate: false },
    { id: 608, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 44, gender: 2, additional: 4, shopType: 'hat', index: 30_434, price: 1000, isDonate: false },

    { id: 609, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 45, gender: 2, additional: 0, shopType: 'hat', index: 30_440, price: 2000, isDonate: false },
    { id: 609, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 45, gender: 2, additional: 1, shopType: 'hat', index: 30_441, price: 2000, isDonate: false },
    { id: 609, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 45, gender: 2, additional: 2, shopType: 'hat', index: 30_442, price: 2000, isDonate: false },
    { id: 609, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 45, gender: 2, additional: 3, shopType: 'hat', index: 30_443, price: 2000, isDonate: false },
    { id: 609, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 45, gender: 2, additional: 4, shopType: 'hat', index: 30_444, price: 2000, isDonate: false },

    { id: 610, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 46, gender: 2, additional: 0, shopType: 'hat', index: 30_450, price: 1500, isDonate: false },
    { id: 610, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 46, gender: 2, additional: 1, shopType: 'hat', index: 30_451, price: 1500, isDonate: false },
    { id: 610, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 46, gender: 2, additional: 2, shopType: 'hat', index: 30_452, price: 1500, isDonate: false },
    { id: 610, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 46, gender: 2, additional: 3, shopType: 'hat', index: 30_453, price: 1500, isDonate: false },
    { id: 610, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 46, gender: 2, additional: 4, shopType: 'hat', index: 30_454, price: 1500, isDonate: false },

    { id: 611, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 47, gender: 2, additional: 0, shopType: 'hat', index: 30_460, price: 1500, isDonate: false },
    { id: 611, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 47, gender: 2, additional: 1, shopType: 'hat', index: 30_461, price: 1500, isDonate: false },
    { id: 611, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 47, gender: 2, additional: 2, shopType: 'hat', index: 30_462, price: 1500, isDonate: false },
    { id: 611, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 47, gender: 2, additional: 3, shopType: 'hat', index: 30_463, price: 1500, isDonate: false },
    { id: 611, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 47, gender: 2, additional: 4, shopType: 'hat', index: 30_464, price: 1500, isDonate: false },
    // { id: 612, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 48, gender: 2, additional: 0, shopType: 'hat', index: 30_470, price: 0, isDonate: false },
    // { id: 613, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 49, gender: 2, additional: 0, shopType: 'hat', index: 30_480, price: 0, isDonate: false },
    // { id: 614, name: 'inventory.headgear', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 5, clothesIndex: 50, gender: 2, additional: 0, shopType: 'hat', index: 30_490, price: 0, isDonate: false },
    { id: 615, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 1, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_000, price: 2100, isDonate: false },
    { id: 615, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 1, gender: 2, additional: 1, shopType: 'sunglasses', index: 35_001, price: 2100, isDonate: false },
    { id: 615, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 1, gender: 2, additional: 2, shopType: 'sunglasses', index: 35_002, price: 2100, isDonate: false },
    { id: 615, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 1, gender: 2, additional: 3, shopType: 'sunglasses', index: 35_003, price: 2100, isDonate: false },
    { id: 615, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 1, gender: 2, additional: 4, shopType: 'sunglasses', index: 35_004, price: 2100, isDonate: false },

    { id: 616, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 2, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_010, price: 2400, isDonate: false },
    { id: 616, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 2, gender: 2, additional: 1, shopType: 'sunglasses', index: 35_011, price: 2400, isDonate: false },
    { id: 616, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 2, gender: 2, additional: 2, shopType: 'sunglasses', index: 35_012, price: 2400, isDonate: false },
    { id: 616, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 2, gender: 2, additional: 3, shopType: 'sunglasses', index: 35_013, price: 2400, isDonate: false },
    { id: 616, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 2, gender: 2, additional: 4, shopType: 'sunglasses', index: 35_014, price: 2400, isDonate: false },

    { id: 617, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 3, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_020, price: 2900, isDonate: false },

    { id: 618, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 4, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_030, price: 4000, isDonate: false },
    { id: 618, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 4, gender: 2, additional: 1, shopType: 'sunglasses', index: 35_031, price: 4000, isDonate: false },
    { id: 618, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 4, gender: 2, additional: 2, shopType: 'sunglasses', index: 35_032, price: 4000, isDonate: false },
    { id: 618, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 4, gender: 2, additional: 3, shopType: 'sunglasses', index: 35_033, price: 4000, isDonate: false },
    { id: 618, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 4, gender: 2, additional: 4, shopType: 'sunglasses', index: 35_034, price: 4000, isDonate: false },

    // { id: 619, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesindex: 0, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_040, price: 0, isDonate: false }, bp
    { id: 620, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 6, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_050, price: 2200, isDonate: false },
    { id: 620, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 6, gender: 2, additional: 1, shopType: 'sunglasses', index: 35_051, price: 2200, isDonate: false },
    { id: 620, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 6, gender: 2, additional: 2, shopType: 'sunglasses', index: 35_052, price: 2200, isDonate: false },
    { id: 620, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 6, gender: 2, additional: 3, shopType: 'sunglasses', index: 35_053, price: 2200, isDonate: false },
    { id: 620, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 6, gender: 2, additional: 4, shopType: 'sunglasses', index: 35_054, price: 2200, isDonate: false },

    { id: 621, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 7, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_060, price: 3800, isDonate: false },
    { id: 621, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 7, gender: 2, additional: 1, shopType: 'sunglasses', index: 35_061, price: 3800, isDonate: false },
    { id: 621, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 7, gender: 2, additional: 2, shopType: 'sunglasses', index: 35_062, price: 3800, isDonate: false },
    { id: 621, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 7, gender: 2, additional: 3, shopType: 'sunglasses', index: 35_063, price: 3800, isDonate: false },
    { id: 621, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 7, gender: 2, additional: 4, shopType: 'sunglasses', index: 35_064, price: 3800, isDonate: false },

    { id: 622, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 8, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_070, price: 2900, isDonate: false },
    { id: 622, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 8, gender: 2, additional: 1, shopType: 'sunglasses', index: 35_071, price: 2900, isDonate: false },
    { id: 622, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 8, gender: 2, additional: 2, shopType: 'sunglasses', index: 35_072, price: 2900, isDonate: false },
    { id: 622, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 8, gender: 2, additional: 3, shopType: 'sunglasses', index: 35_073, price: 2900, isDonate: false },
    { id: 622, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 8, gender: 2, additional: 4, shopType: 'sunglasses', index: 35_074, price: 2900, isDonate: false },

    { id: 623, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 9, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_080, price: 3100, isDonate: false },
    { id: 623, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 9, gender: 2, additional: 1, shopType: 'sunglasses', index: 35_081, price: 3100, isDonate: false },
    { id: 623, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 9, gender: 2, additional: 2, shopType: 'sunglasses', index: 35_082, price: 3100, isDonate: false },
    { id: 623, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 9, gender: 2, additional: 3, shopType: 'sunglasses', index: 35_083, price: 3100, isDonate: false },
    { id: 623, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 9, gender: 2, additional: 4, shopType: 'sunglasses', index: 35_084, price: 3100, isDonate: false },

    // { id: 624, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 10, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_090, price: 0, isDonate: false }, bp
    { id: 625, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 11, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_100, price: 3700, isDonate: false },
    { id: 625, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 11, gender: 2, additional: 1, shopType: 'sunglasses', index: 35_101, price: 3700, isDonate: false },
    { id: 625, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 11, gender: 2, additional: 2, shopType: 'sunglasses', index: 35_102, price: 3700, isDonate: false },
    { id: 625, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 11, gender: 2, additional: 3, shopType: 'sunglasses', index: 35_103, price: 3700, isDonate: false },
    { id: 625, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 11, gender: 2, additional: 4, shopType: 'sunglasses', index: 35_104, price: 3700, isDonate: false },

    { id: 626, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 12, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_110, price: 2200, isDonate: false },
    { id: 626, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 12, gender: 2, additional: 1, shopType: 'sunglasses', index: 35_111, price: 2200, isDonate: false },
    { id: 626, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 12, gender: 2, additional: 2, shopType: 'sunglasses', index: 35_112, price: 2200, isDonate: false },
    { id: 626, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 12, gender: 2, additional: 3, shopType: 'sunglasses', index: 35_113, price: 2200, isDonate: false },
    { id: 626, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 12, gender: 2, additional: 4, shopType: 'sunglasses', index: 35_114, price: 2200, isDonate: false },

    { id: 627, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 13, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_120, price: 2100, isDonate: false },
    { id: 627, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 13, gender: 2, additional: 1, shopType: 'sunglasses', index: 35_121, price: 2100, isDonate: false },
    { id: 627, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 13, gender: 2, additional: 2, shopType: 'sunglasses', index: 35_122, price: 2100, isDonate: false },
    { id: 627, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 13, gender: 2, additional: 3, shopType: 'sunglasses', index: 35_123, price: 2100, isDonate: false },
    { id: 627, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 13, gender: 2, additional: 4, shopType: 'sunglasses', index: 35_124, price: 2100, isDonate: false },

    { id: 628, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 14, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_130, price: 4000, isDonate: false },
    { id: 628, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 14, gender: 2, additional: 1, shopType: 'sunglasses', index: 35_131, price: 4000, isDonate: false },
    { id: 628, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 14, gender: 2, additional: 2, shopType: 'sunglasses', index: 35_132, price: 4000, isDonate: false },
    { id: 628, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 14, gender: 2, additional: 3, shopType: 'sunglasses', index: 35_133, price: 4000, isDonate: false },
    { id: 628, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 14, gender: 2, additional: 4, shopType: 'sunglasses', index: 35_134, price: 4000, isDonate: false },

    { id: 629, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 15, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_140, price: 2600, isDonate: false },
    { id: 629, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 15, gender: 2, additional: 1, shopType: 'sunglasses', index: 35_141, price: 2600, isDonate: false },
    { id: 629, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 15, gender: 2, additional: 2, shopType: 'sunglasses', index: 35_142, price: 2600, isDonate: false },
    { id: 629, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 15, gender: 2, additional: 3, shopType: 'sunglasses', index: 35_143, price: 2600, isDonate: false },
    { id: 629, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 15, gender: 2, additional: 4, shopType: 'sunglasses', index: 35_144, price: 2600, isDonate: false },

    { id: 630, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 16, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_150, price: 3500, isDonate: false },
    { id: 630, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 16, gender: 2, additional: 1, shopType: 'sunglasses', index: 35_151, price: 3500, isDonate: false },
    { id: 630, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 16, gender: 2, additional: 2, shopType: 'sunglasses', index: 35_152, price: 3500, isDonate: false },
    { id: 630, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 16, gender: 2, additional: 3, shopType: 'sunglasses', index: 35_153, price: 3500, isDonate: false },
    { id: 630, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 16, gender: 2, additional: 4, shopType: 'sunglasses', index: 35_154, price: 3500, isDonate: false },

    { id: 631, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 17, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_160, price: 3200, isDonate: false },
    { id: 631, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 17, gender: 2, additional: 1, shopType: 'sunglasses', index: 35_161, price: 3200, isDonate: false },
    { id: 631, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 17, gender: 2, additional: 2, shopType: 'sunglasses', index: 35_162, price: 3200, isDonate: false },
    { id: 631, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 17, gender: 2, additional: 3, shopType: 'sunglasses', index: 35_163, price: 3200, isDonate: false },
    { id: 631, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 17, gender: 2, additional: 4, shopType: 'sunglasses', index: 35_164, price: 3200, isDonate: false },

    { id: 632, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 18, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_170, price: 100, isDonate: true },
    { id: 632, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 18, gender: 2, additional: 1, shopType: 'sunglasses', index: 35_171, price: 100, isDonate: true },
    { id: 632, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 18, gender: 2, additional: 2, shopType: 'sunglasses', index: 35_172, price: 100, isDonate: true },
    { id: 632, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 18, gender: 2, additional: 3, shopType: 'sunglasses', index: 35_173, price: 100, isDonate: true },
    { id: 632, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 18, gender: 2, additional: 4, shopType: 'sunglasses', index: 35_174, price: 100, isDonate: true },

    // { id: 633, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 19, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_180, price: 0, isDonate: false },
    { id: 634, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 20, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_190, price: 2400, isDonate: false },
    { id: 634, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 20, gender: 2, additional: 1, shopType: 'sunglasses', index: 35_191, price: 2400, isDonate: false },
    { id: 634, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 20, gender: 2, additional: 2, shopType: 'sunglasses', index: 35_192, price: 2400, isDonate: false },
    { id: 634, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 20, gender: 2, additional: 3, shopType: 'sunglasses', index: 35_193, price: 2400, isDonate: false },
    { id: 634, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 20, gender: 2, additional: 4, shopType: 'sunglasses', index: 35_194, price: 2400, isDonate: false },

    // { id: 635, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 21, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_200, price: 0, isDonate: false },
    { id: 636, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 22, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_210, price: 200, isDonate: true },
    { id: 636, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 22, gender: 2, additional: 1, shopType: 'sunglasses', index: 35_211, price: 200, isDonate: true },
    { id: 636, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 22, gender: 2, additional: 2, shopType: 'sunglasses', index: 35_212, price: 200, isDonate: true },
    { id: 636, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 22, gender: 2, additional: 3, shopType: 'sunglasses', index: 35_213, price: 200, isDonate: true },
    { id: 636, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 22, gender: 2, additional: 4, shopType: 'sunglasses', index: 35_214, price: 200, isDonate: true },

    // { id: 637, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 23, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_220, price: 0, isDonate: false }, rezerv
    // { id: 638, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 24, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_230, price: 0, isDonate: false },
    // { id: 639, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 25, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_240, price: 0, isDonate: false },
    // { id: 640, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 26, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_250, price: 0, isDonate: false },
    // { id: 641, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 27, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_260, price: 0, isDonate: false },
    // { id: 642, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 28, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_270, price: 0, isDonate: false },
    // { id: 643, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 29, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_280, price: 0, isDonate: false },
    // { id: 644, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 30, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_290, price: 0, isDonate: false },
    // { id: 645, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 31, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_300, price: 0, isDonate: false },
    // { id: 646, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 32, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_310, price: 0, isDonate: false },
    // { id: 647, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 33, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_320, price: 0, isDonate: false },
    // { id: 648, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 34, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_330, price: 0, isDonate: false },
    // { id: 649, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 35, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_340, price: 0, isDonate: false },
    // { id: 650, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 36, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_350, price: 0, isDonate: false },
    // { id: 651, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 37, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_360, price: 0, isDonate: false },
    // { id: 652, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 38, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_370, price: 0, isDonate: false },
    // { id: 653, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 39, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_380, price: 0, isDonate: false },
    // { id: 654, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 40, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_390, price: 0, isDonate: false },
    // { id: 655, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 41, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_400, price: 0, isDonate: false },
    // { id: 656, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 42, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_410, price: 0, isDonate: false },
    // { id: 657, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 43, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_420, price: 0, isDonate: false },
    // { id: 658, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 44, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_430, price: 0, isDonate: false },
    // { id: 659, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 45, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_440, price: 0, isDonate: false },
    // { id: 660, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 46, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_450, price: 0, isDonate: false },
    // { id: 661, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 47, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_460, price: 0, isDonate: false },
    // { id: 662, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 48, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_470, price: 0, isDonate: false },
    // { id: 663, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 49, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_480, price: 0, isDonate: false },
    // { id: 664, name: 'inventory.glasses', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 6, clothesIndex: 50, gender: 2, additional: 0, shopType: 'sunglasses', index: 35_490, price: 0, isDonate: false },

    // { id: 665, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 1, gender: 2, additional: 0, shopType: 'masks', index: 40_000, price: 0, isDonate: false }, medic

    { id: 666, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 2, gender: 2, additional: 4, shopType: 'masks', index: 40_014, price: 5000, isDonate: false },

    { id: 667, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 3, gender: 2, additional: 0, shopType: 'masks', index: 40_020, price: 3000, isDonate: false },
    { id: 667, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 3, gender: 2, additional: 1, shopType: 'masks', index: 40_021, price: 3000, isDonate: false },
    { id: 667, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 3, gender: 2, additional: 2, shopType: 'masks', index: 40_022, price: 3000, isDonate: false },
    { id: 667, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 3, gender: 2, additional: 3, shopType: 'masks', index: 40_023, price: 3000, isDonate: false },
    { id: 667, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 3, gender: 2, additional: 4, shopType: 'masks', index: 40_024, price: 3000, isDonate: false },

    { id: 668, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 4, gender: 2, additional: 0, shopType: 'masks', index: 40_030, price: 3500, isDonate: false },
    { id: 668, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 4, gender: 2, additional: 1, shopType: 'masks', index: 40_031, price: 3500, isDonate: false },
    { id: 668, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 4, gender: 2, additional: 2, shopType: 'masks', index: 40_032, price: 3500, isDonate: false },
    { id: 668, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 4, gender: 2, additional: 3, shopType: 'masks', index: 40_033, price: 3500, isDonate: false },
    { id: 668, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 4, gender: 2, additional: 4, shopType: 'masks', index: 40_034, price: 3500, isDonate: false },

    { id: 669, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 5, gender: 2, additional: 0, shopType: 'masks', index: 40_040, price: 4700, isDonate: false },
    { id: 669, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 5, gender: 2, additional: 1, shopType: 'masks', index: 40_041, price: 4700, isDonate: false },
    { id: 669, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 5, gender: 2, additional: 2, shopType: 'masks', index: 40_042, price: 4700, isDonate: false },
    { id: 669, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 5, gender: 2, additional: 3, shopType: 'masks', index: 40_043, price: 4700, isDonate: false },
    { id: 669, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 5, gender: 2, additional: 4, shopType: 'masks', index: 40_044, price: 4700, isDonate: false },

    { id: 670, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 6, gender: 2, additional: 0, shopType: 'masks', index: 40_050, price: 3200, isDonate: false },
    { id: 670, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 6, gender: 2, additional: 1, shopType: 'masks', index: 40_051, price: 3200, isDonate: false },
    { id: 670, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 6, gender: 2, additional: 2, shopType: 'masks', index: 40_052, price: 3200, isDonate: false },
    { id: 670, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 6, gender: 2, additional: 3, shopType: 'masks', index: 40_053, price: 3200, isDonate: false },
    { id: 670, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 6, gender: 2, additional: 4, shopType: 'masks', index: 40_054, price: 3200, isDonate: false },

    // { id: 671, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 7, gender: 2, additional: 0, shopType: 'masks', index: 40_060, price: 0, isDonate: false }, bp
    { id: 672, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 8, gender: 2, additional: 0, shopType: 'masks', index: 40_070, price: 4100, isDonate: false },
    { id: 672, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 8, gender: 2, additional: 1, shopType: 'masks', index: 40_071, price: 4100, isDonate: false },
    { id: 672, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 8, gender: 2, additional: 2, shopType: 'masks', index: 40_072, price: 4100, isDonate: false },
    { id: 672, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 8, gender: 2, additional: 3, shopType: 'masks', index: 40_073, price: 4100, isDonate: false },
    { id: 672, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 8, gender: 2, additional: 4, shopType: 'masks', index: 40_074, price: 4100, isDonate: false },

    { id: 673, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 9, gender: 2, additional: 0, shopType: 'masks', index: 40_080, price: 5000, isDonate: false },
    { id: 673, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 9, gender: 2, additional: 1, shopType: 'masks', index: 40_081, price: 5000, isDonate: false },
    { id: 673, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 9, gender: 2, additional: 2, shopType: 'masks', index: 40_082, price: 5000, isDonate: false },
    { id: 673, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 9, gender: 2, additional: 3, shopType: 'masks', index: 40_083, price: 5000, isDonate: false },
    { id: 673, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 9, gender: 2, additional: 4, shopType: 'masks', index: 40_084, price: 5000, isDonate: false },

    { id: 674, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 10, gender: 2, additional: 0, shopType: 'masks', index: 40_090, price: 100, isDonate: true },
    { id: 674, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 10, gender: 2, additional: 1, shopType: 'masks', index: 40_091, price: 100, isDonate: true },
    { id: 674, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 10, gender: 2, additional: 2, shopType: 'masks', index: 40_092, price: 100, isDonate: true },
    { id: 674, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 10, gender: 2, additional: 3, shopType: 'masks', index: 40_093, price: 100, isDonate: true },
    { id: 674, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 10, gender: 2, additional: 4, shopType: 'masks', index: 40_094, price: 100, isDonate: true },

    { id: 675, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 11, gender: 2, additional: 0, shopType: 'masks', index: 40_100, price: 4300, isDonate: false },
    { id: 675, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 11, gender: 2, additional: 1, shopType: 'masks', index: 40_101, price: 4300, isDonate: false },
    { id: 675, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 11, gender: 2, additional: 2, shopType: 'masks', index: 40_102, price: 4300, isDonate: false },
    { id: 675, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 11, gender: 2, additional: 3, shopType: 'masks', index: 40_103, price: 4300, isDonate: false },
    { id: 675, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 11, gender: 2, additional: 4, shopType: 'masks', index: 40_104, price: 4300, isDonate: false },

    { id: 676, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 12, gender: 2, additional: 0, shopType: 'masks', index: 40_110, price: 150, isDonate: true },
    { id: 676, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 12, gender: 2, additional: 1, shopType: 'masks', index: 40_111, price: 150, isDonate: true },
    { id: 676, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 12, gender: 2, additional: 2, shopType: 'masks', index: 40_112, price: 150, isDonate: true },
    { id: 676, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 12, gender: 2, additional: 3, shopType: 'masks', index: 40_113, price: 150, isDonate: true },
    { id: 676, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 12, gender: 2, additional: 4, shopType: 'masks', index: 40_114, price: 150, isDonate: true },

    { id: 677, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 13, gender: 2, additional: 0, shopType: 'masks', index: 40_120, price: 5000, isDonate: false },
    { id: 677, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 13, gender: 2, additional: 1, shopType: 'masks', index: 40_121, price: 5000, isDonate: false },
    { id: 677, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 13, gender: 2, additional: 2, shopType: 'masks', index: 40_122, price: 5000, isDonate: false },
    { id: 677, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 13, gender: 2, additional: 3, shopType: 'masks', index: 40_123, price: 5000, isDonate: false },
    { id: 677, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 13, gender: 2, additional: 4, shopType: 'masks', index: 40_124, price: 5000, isDonate: false },
    // { id: 678, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 14, gender: 2, additional: 0, shopType: 'masks', index: 40_130, price: 0, isDonate: false }, bp
    // { id: 679, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 15, gender: 2, additional: 0, shopType: 'masks', index: 40_140, price: 0, isDonate: false }, bp
    { id: 680, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 16, gender: 2, additional: 0, shopType: 'masks', index: 40_150, price: 3400, isDonate: false },
    { id: 680, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 16, gender: 2, additional: 1, shopType: 'masks', index: 40_151, price: 3400, isDonate: false },
    { id: 680, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 16, gender: 2, additional: 2, shopType: 'masks', index: 40_152, price: 3400, isDonate: false },
    { id: 680, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 16, gender: 2, additional: 3, shopType: 'masks', index: 40_153, price: 3400, isDonate: false },
    { id: 680, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 16, gender: 2, additional: 4, shopType: 'masks', index: 40_154, price: 3400, isDonate: false },

    { id: 681, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 17, gender: 2, additional: 0, shopType: 'masks', index: 40_160, price: 3100, isDonate: false },
    { id: 681, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 17, gender: 2, additional: 1, shopType: 'masks', index: 40_161, price: 3100, isDonate: false },
    { id: 681, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 17, gender: 2, additional: 2, shopType: 'masks', index: 40_162, price: 3100, isDonate: false },
    { id: 681, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 17, gender: 2, additional: 3, shopType: 'masks', index: 40_163, price: 3100, isDonate: false },
    { id: 681, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 17, gender: 2, additional: 4, shopType: 'masks', index: 40_164, price: 3100, isDonate: false },

    { id: 682, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 18, gender: 2, additional: 0, shopType: 'masks', index: 40_170, price: 3000, isDonate: false },
    { id: 682, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 18, gender: 2, additional: 1, shopType: 'masks', index: 40_171, price: 3000, isDonate: false },
    { id: 682, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 18, gender: 2, additional: 2, shopType: 'masks', index: 40_172, price: 3000, isDonate: false },
    { id: 682, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 18, gender: 2, additional: 3, shopType: 'masks', index: 40_173, price: 3000, isDonate: false },
    { id: 682, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 18, gender: 2, additional: 4, shopType: 'masks', index: 40_174, price: 3000, isDonate: false },

    { id: 683, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 19, gender: 2, additional: 0, shopType: 'masks', index: 40_180, price: 3200, isDonate: false },
    { id: 683, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 19, gender: 2, additional: 1, shopType: 'masks', index: 40_181, price: 3200, isDonate: false },
    { id: 683, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 19, gender: 2, additional: 2, shopType: 'masks', index: 40_182, price: 3200, isDonate: false },
    { id: 683, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 19, gender: 2, additional: 3, shopType: 'masks', index: 40_183, price: 3200, isDonate: false },
    { id: 683, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 19, gender: 2, additional: 4, shopType: 'masks', index: 40_184, price: 3200, isDonate: false },

    { id: 684, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 20, gender: 2, additional: 0, shopType: 'masks', index: 40_190, price: 3000, isDonate: false },
    { id: 684, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 20, gender: 2, additional: 1, shopType: 'masks', index: 40_191, price: 3000, isDonate: false },
    { id: 684, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 20, gender: 2, additional: 2, shopType: 'masks', index: 40_192, price: 3000, isDonate: false },
    { id: 684, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 20, gender: 2, additional: 3, shopType: 'masks', index: 40_193, price: 3000, isDonate: false },
    { id: 684, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 20, gender: 2, additional: 4, shopType: 'masks', index: 40_194, price: 3000, isDonate: false },

    { id: 685, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 21, gender: 2, additional: 0, shopType: 'masks', index: 40_200, price: 200, isDonate: true },
    { id: 685, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 21, gender: 2, additional: 1, shopType: 'masks', index: 40_201, price: 200, isDonate: true },
    { id: 685, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 21, gender: 2, additional: 2, shopType: 'masks', index: 40_202, price: 200, isDonate: true },
    { id: 685, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 21, gender: 2, additional: 3, shopType: 'masks', index: 40_203, price: 200, isDonate: true },
    { id: 685, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 21, gender: 2, additional: 4, shopType: 'masks', index: 40_204, price: 200, isDonate: true },

    // { id: 686, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 22, gender: 2, additional: 0, shopType: 'masks', index: 40_210, price: 0, isDonate: false }, rezerv
    // { id: 687, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 23, gender: 2, additional: 0, shopType: 'masks', index: 40_220, price: 0, isDonate: false },
    // { id: 688, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 24, gender: 2, additional: 0, shopType: 'masks', index: 40_230, price: 0, isDonate: false },
    // { id: 689, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 25, gender: 2, additional: 0, shopType: 'masks', index: 40_240, price: 0, isDonate: false },
    // { id: 690, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 26, gender: 2, additional: 0, shopType: 'masks', index: 40_250, price: 0, isDonate: false },
    // { id: 691, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 27, gender: 2, additional: 0, shopType: 'masks', index: 40_260, price: 0, isDonate: false },
    // { id: 692, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 28, gender: 2, additional: 0, shopType: 'masks', index: 40_270, price: 0, isDonate: false },
    // { id: 693, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 29, gender: 2, additional: 0, shopType: 'masks', index: 40_280, price: 0, isDonate: false },
    // { id: 694, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 30, gender: 2, additional: 0, shopType: 'masks', index: 40_290, price: 0, isDonate: false },
    // { id: 695, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 31, gender: 2, additional: 0, shopType: 'masks', index: 40_300, price: 0, isDonate: false },
    // { id: 696, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 32, gender: 2, additional: 0, shopType: 'masks', index: 40_310, price: 0, isDonate: false },
    // { id: 697, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 33, gender: 2, additional: 0, shopType: 'masks', index: 40_320, price: 0, isDonate: false },
    // { id: 698, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 34, gender: 2, additional: 0, shopType: 'masks', index: 40_330, price: 0, isDonate: false },
    // { id: 699, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 35, gender: 2, additional: 0, shopType: 'masks', index: 40_340, price: 0, isDonate: false },
    // { id: 700, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 36, gender: 2, additional: 0, shopType: 'masks', index: 40_350, price: 0, isDonate: false },
    // { id: 701, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 37, gender: 2, additional: 0, shopType: 'masks', index: 40_360, price: 0, isDonate: false },
    // { id: 702, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 38, gender: 2, additional: 0, shopType: 'masks', index: 40_370, price: 0, isDonate: false },
    // { id: 703, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 39, gender: 2, additional: 0, shopType: 'masks', index: 40_380, price: 0, isDonate: false },
    // { id: 704, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 40, gender: 2, additional: 0, shopType: 'masks', index: 40_390, price: 0, isDonate: false },
    // { id: 705, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 41, gender: 2, additional: 0, shopType: 'masks', index: 40_400, price: 0, isDonate: false },
    // { id: 706, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 42, gender: 2, additional: 0, shopType: 'masks', index: 40_410, price: 0, isDonate: false },
    // { id: 707, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 43, gender: 2, additional: 0, shopType: 'masks', index: 40_420, price: 0, isDonate: false },
    // { id: 708, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 44, gender: 2, additional: 0, shopType: 'masks', index: 40_430, price: 0, isDonate: false },
    // { id: 709, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 45, gender: 2, additional: 0, shopType: 'masks', index: 40_440, price: 0, isDonate: false },
    // { id: 710, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 46, gender: 2, additional: 0, shopType: 'masks', index: 40_450, price: 0, isDonate: false },
    // { id: 711, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 47, gender: 2, additional: 0, shopType: 'masks', index: 40_460, price: 0, isDonate: false },
    // { id: 712, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 48, gender: 2, additional: 0, shopType: 'masks', index: 40_470, price: 0, isDonate: false },
    // { id: 713, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 49, gender: 2, additional: 0, shopType: 'masks', index: 40_480, price: 0, isDonate: false },
    // { id: 714, name: 'inventory.mask', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 7, clothesIndex: 50, gender: 2, additional: 0, shopType: 'masks', index: 40_490, price: 0, isDonate: false },

    { id: 715, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 1, gender: 2, additional: 0, shopType: 'gloves', index: 45_000, price: 250, isDonate: true },
    { id: 715, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 1, gender: 2, additional: 1, shopType: 'gloves', index: 45_001, price: 250, isDonate: true },
    { id: 715, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 1, gender: 2, additional: 2, shopType: 'gloves', index: 45_002, price: 250, isDonate: true },
    { id: 715, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 1, gender: 2, additional: 3, shopType: 'gloves', index: 45_003, price: 250, isDonate: true },
    { id: 715, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 1, gender: 2, additional: 4, shopType: 'gloves', index: 45_004, price: 250, isDonate: true },

    // { id: 716, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 2, gender: 2, additional: 0, shopType: 'gloves', index: 45_010, price: 0, isDonate: false },
    // { id: 717, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 3, gender: 2, additional: 0, shopType: 'gloves', index: 45_020, price: 0, isDonate: false }, fire dep

    // { id: 718, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 4, gender: 2, additional: 0, shopType: 'gloves', index: 45_030, price: 0, isDonate: false },
    { id: 719, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 5, gender: 2, additional: 0, shopType: 'gloves', index: 45_040, price: 150, isDonate: true },
    { id: 719, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 5, gender: 2, additional: 1, shopType: 'gloves', index: 45_041, price: 150, isDonate: true },
    { id: 719, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 5, gender: 2, additional: 2, shopType: 'gloves', index: 45_042, price: 150, isDonate: true },
    { id: 719, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 5, gender: 2, additional: 3, shopType: 'gloves', index: 45_043, price: 150, isDonate: true },
    { id: 719, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 5, gender: 2, additional: 4, shopType: 'gloves', index: 45_044, price: 150, isDonate: true },

    { id: 720, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 6, gender: 2, additional: 0, shopType: 'gloves', index: 45_050, price: 100, isDonate: true },
    { id: 720, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 6, gender: 2, additional: 1, shopType: 'gloves', index: 45_051, price: 100, isDonate: true },
    { id: 720, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 6, gender: 2, additional: 2, shopType: 'gloves', index: 45_052, price: 100, isDonate: true },
    { id: 720, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 6, gender: 2, additional: 3, shopType: 'gloves', index: 45_053, price: 100, isDonate: true },
    { id: 720, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 6, gender: 2, additional: 4, shopType: 'gloves', index: 45_054, price: 100, isDonate: true },

    { id: 721, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 7, gender: 2, additional: 0, shopType: 'gloves', index: 45_060, price: 250, isDonate: true },
    { id: 721, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 7, gender: 2, additional: 1, shopType: 'gloves', index: 45_061, price: 250, isDonate: true },
    { id: 721, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 7, gender: 2, additional: 2, shopType: 'gloves', index: 45_062, price: 250, isDonate: true },
    { id: 721, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 7, gender: 2, additional: 3, shopType: 'gloves', index: 45_063, price: 250, isDonate: true },
    { id: 721, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 7, gender: 2, additional: 4, shopType: 'gloves', index: 45_064, price: 250, isDonate: true },

    { id: 722, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 8, gender: 2, additional: 0, shopType: 'gloves', index: 45_070, price: 5000, isDonate: false },
    { id: 722, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 8, gender: 2, additional: 1, shopType: 'gloves', index: 45_071, price: 5000, isDonate: false },
    { id: 722, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 8, gender: 2, additional: 2, shopType: 'gloves', index: 45_072, price: 5000, isDonate: false },

    { id: 723, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 9, gender: 2, additional: 0, shopType: 'gloves', index: 45_080, price: 5000, isDonate: false },
    { id: 723, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 9, gender: 2, additional: 1, shopType: 'gloves', index: 45_081, price: 5000, isDonate: false },
    { id: 723, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 9, gender: 2, additional: 2, shopType: 'gloves', index: 45_082, price: 5000, isDonate: false },
    { id: 723, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 9, gender: 2, additional: 3, shopType: 'gloves', index: 45_083, price: 5000, isDonate: false },
    { id: 723, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 9, gender: 2, additional: 4, shopType: 'gloves', index: 45_084, price: 5000, isDonate: false },

    { id: 724, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 10, gender: 2, additional: 0, shopType: 'gloves', index: 45_090, price: 100, isDonate: true },
    { id: 724, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 10, gender: 2, additional: 1, shopType: 'gloves', index: 45_091, price: 100, isDonate: true },
    { id: 724, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 10, gender: 2, additional: 2, shopType: 'gloves', index: 45_092, price: 100, isDonate: true },
    { id: 724, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 10, gender: 2, additional: 3, shopType: 'gloves', index: 45_093, price: 100, isDonate: true },
    { id: 724, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 10, gender: 2, additional: 4, shopType: 'gloves', index: 45_094, price: 100, isDonate: true },

    { id: 725, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 11, gender: 2, additional: 0, shopType: 'gloves', index: 45_100, price: 5000, isDonate: false },
    { id: 725, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 11, gender: 2, additional: 1, shopType: 'gloves', index: 45_101, price: 5000, isDonate: false },
    { id: 725, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 11, gender: 2, additional: 2, shopType: 'gloves', index: 45_102, price: 5000, isDonate: false },
    { id: 725, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 11, gender: 2, additional: 3, shopType: 'gloves', index: 45_103, price: 5000, isDonate: false },
    { id: 725, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 11, gender: 2, additional: 4, shopType: 'gloves', index: 45_104, price: 5000, isDonate: false },

    { id: 726, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 12, gender: 2, additional: 0, shopType: 'gloves', index: 45_110, price: 300, isDonate: true },
    { id: 726, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 12, gender: 2, additional: 1, shopType: 'gloves', index: 45_111, price: 300, isDonate: true },
    { id: 726, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 12, gender: 2, additional: 2, shopType: 'gloves', index: 45_112, price: 300, isDonate: true },
    { id: 726, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 12, gender: 2, additional: 3, shopType: 'gloves', index: 45_113, price: 300, isDonate: true },
    { id: 726, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 12, gender: 2, additional: 4, shopType: 'gloves', index: 45_114, price: 300, isDonate: true },

    { id: 727, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 13, gender: 2, additional: 0, shopType: 'gloves', index: 45_120, price: 150, isDonate: true },
    { id: 727, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 13, gender: 2, additional: 1, shopType: 'gloves', index: 45_121, price: 150, isDonate: true },
    { id: 727, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 13, gender: 2, additional: 2, shopType: 'gloves', index: 45_122, price: 150, isDonate: true },
    { id: 727, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 13, gender: 2, additional: 3, shopType: 'gloves', index: 45_123, price: 150, isDonate: true },
    { id: 727, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 13, gender: 2, additional: 4, shopType: 'gloves', index: 45_124, price: 150, isDonate: true }
    // { id: 728, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 14, gender: 2, additional: 0, shopType: 'gloves', index: 45_130, price: 0, isDonate: false }, rezerv
    // { id: 729, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 15, gender: 2, additional: 0, shopType: 'gloves', index: 45_140, price: 0, isDonate: false },
    // { id: 730, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 16, gender: 2, additional: 0, shopType: 'gloves', index: 45_150, price: 0, isDonate: false },
    // { id: 731, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 17, gender: 2, additional: 0, shopType: 'gloves', index: 45_160, price: 0, isDonate: false },
    // { id: 732, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 18, gender: 2, additional: 0, shopType: 'gloves', index: 45_170, price: 0, isDonate: false },
    // { id: 733, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 19, gender: 2, additional: 0, shopType: 'gloves', index: 45_180, price: 0, isDonate: false },
    // { id: 734, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 20, gender: 2, additional: 0, shopType: 'gloves', index: 45_190, price: 0, isDonate: false },
    // { id: 735, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 21, gender: 2, additional: 0, shopType: 'gloves', index: 45_200, price: 0, isDonate: false },
    // { id: 736, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 22, gender: 2, additional: 0, shopType: 'gloves', index: 45_210, price: 0, isDonate: false },
    // { id: 737, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 23, gender: 2, additional: 0, shopType: 'gloves', index: 45_220, price: 0, isDonate: false },
    // { id: 738, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 24, gender: 2, additional: 0, shopType: 'gloves', index: 45_230, price: 0, isDonate: false },
    // { id: 739, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 25, gender: 2, additional: 0, shopType: 'gloves', index: 45_240, price: 0, isDonate: false },
    // { id: 740, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 26, gender: 2, additional: 0, shopType: 'gloves', index: 45_250, price: 0, isDonate: false },
    // { id: 741, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 27, gender: 2, additional: 0, shopType: 'gloves', index: 45_260, price: 0, isDonate: false },
    // { id: 742, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 28, gender: 2, additional: 0, shopType: 'gloves', index: 45_270, price: 0, isDonate: false },
    // { id: 743, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 29, gender: 2, additional: 0, shopType: 'gloves', index: 45_280, price: 0, isDonate: false },
    // { id: 744, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 30, gender: 2, additional: 0, shopType: 'gloves', index: 45_290, price: 0, isDonate: false },
    // { id: 745, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 31, gender: 2, additional: 0, shopType: 'gloves', index: 45_300, price: 0, isDonate: false },
    // { id: 746, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 32, gender: 2, additional: 0, shopType: 'gloves', index: 45_310, price: 0, isDonate: false },
    // { id: 747, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 33, gender: 2, additional: 0, shopType: 'gloves', index: 45_320, price: 0, isDonate: false },
    // { id: 748, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 34, gender: 2, additional: 0, shopType: 'gloves', index: 45_330, price: 0, isDonate: false },
    // { id: 749, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 35, gender: 2, additional: 0, shopType: 'gloves', index: 45_340, price: 0, isDonate: false },
    // { id: 750, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 36, gender: 2, additional: 0, shopType: 'gloves', index: 45_350, price: 0, isDonate: false },
    // { id: 751, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 37, gender: 2, additional: 0, shopType: 'gloves', index: 45_360, price: 0, isDonate: false },
    // { id: 752, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 38, gender: 2, additional: 0, shopType: 'gloves', index: 45_370, price: 0, isDonate: false },
    // { id: 753, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 39, gender: 2, additional: 0, shopType: 'gloves', index: 45_380, price: 0, isDonate: false },
    // { id: 754, name: 'inventory.gloves', rarity: 'ordinary', type: 2, filterType: 'clothes', desc: 'inventory.clothesDescription', img: '', clothesType: 8, clothesIndex: 40, gender: 2, additional: 0, shopType: 'gloves', index: 45_390, price: 0, isDonate: false }
];
