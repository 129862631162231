<template>
    <div class="auction__info-price">
        <div class="auction__info-price-number"><span class="auction__info-price-dollar">$</span>{{ $formatNum(price) }}</div>
        <div class="auction__info-price-text">{{ text }}</div>
    </div>
</template>
<script>
export default {
    name: 'AuctionPrice',
    props: {
        price: Number,
        text: String
    }
};
</script>
<style lang="scss">
.auction__info {
    &-price {
        margin-right: 2.55vw;
        &-number {
            font-size: 2.05vw;
            font-weight: 700;
            margin-bottom: .35vw;
            letter-spacing: -0.02em;
            white-space: nowrap;
        }
        &-dollar {
            color: var(--gold);
            margin-right: .12vw;
        }
        &-text {
            text-transform: uppercase;
            font-family: var(--font-stretch);
            font-weight: 700;
            line-height: 120%;
            opacity: .6;
            font-size: 1vw;
            letter-spacing: 0.06em;
            text-align: center;
            &--no-center {
                text-align: start;
            }
        }
    }
}
</style>
