<template>
    <div
        class="level"
        :class="{
          'level--first': level === 1,
          'level--second': level === 2,
          'level--third': level >= 3,
        }"
    :style="{width, height, minWidth: width}">
        <div class="level__number">
            {{level === 1 ? '1' : level === 2 ? '2' : level === 3 ? '3' : level}}
        </div>
        <div class="level__text">
            {{ $t('estate_level.level') }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'EstateLevel',
    props: {
        level: {
            type: Number,
            default: 1
        },
        width: {
            type: String,
            default: '8vw'
        },
        height: {
            type: String,
            default: '8vw'
        }
    }
};
</script>

<style lang="scss" scoped>
.level {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: var(--white);
    font-family: var(--font-stretch);
    font-weight: 900;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    &--first {
        background-image: url("@/assets/images/estateLevel/lvl-1.png");
    }
    &--second {
        background-image: url("@/assets/images/estateLevel/lvl-2.png");
    }
    &--third {
        background-image: url("@/assets/images/estateLevel/lvl-3.png");
    }
    &__text {
        text-shadow: 0 8px 0 rgba(0, 0, 0, 0.3), -4px 4px 0 rgba(0, 0, 0, 0.3), 4px 4px 0 rgba(0, 0, 0, 0.3);
        font-size: .75vw;
    }
    &__number {
        margin-bottom: .5vw;
        font-size: 2.5vw;
        text-shadow: 0 -6px 0 rgba(0, 0, 0, 0.5), 0px 6px 0px rgba(0, 0, 0, 0.5), -6px 6px 0 rgba(0, 0, 0, 0.5), 6px 6px 0px rgba(0, 0, 0, 0.5);
    }
}
</style>
