import Hammer from 'hammerjs';

export default {
    methods: {
        createHammerInstance (elementName, onPressEvent, onPressUpEvent, onTapEvent) {
            const element = this.$refs[elementName];
            const hammer = new Hammer(element);

            if (onPressEvent) {
                hammer.get('press').set({ time: 0 });
                hammer.on('press', (e) => {
                    onPressEvent(e);
                    element.classList.add('active');
                });
            }

            if (onPressUpEvent) {
                hammer.on('pressup', (e) => {
                    onPressUpEvent(e);
                    element.classList.remove('active');
                });

                hammer.on('panend', (e) => {
                    onPressUpEvent(e);
                    element.classList.remove('active');
                });
                hammer.on('pancancel', (e) => {
                    onPressUpEvent(e);
                    element.classList.remove('active');
                });
            }

            if (onTapEvent) {
                hammer.on('tap', (e) => {
                    onTapEvent(e);
                    element.classList.add('active');
                    setTimeout(() => {
                        element.classList.remove('active');
                    }, 300);
                });
            } else {
                hammer.get('tap').set({ enable: false });
            }
        },

        // Функция для пропуска вызовов с периодичностью
        throttle (func, timeout) {
            let lastFunc;
            let lastRan;
            return function () {
                const context = this;
                const args = arguments;
                if (!lastRan) {
                    func.apply(context, args);
                    lastRan = Date.now();
                } else {
                    clearTimeout(lastFunc);
                    lastFunc = setTimeout(function () {
                        if (Date.now() - lastRan >= timeout) {
                            func.apply(context, args);
                            lastRan = Date.now();
                        }
                    }, timeout - (Date.now() - lastRan));
                }
            };
        },
        // Функция для получения пути к картинке
        imageSource (img, folder) {
            return folder ? require(`@/assets/images/${folder}/${img}`) : require(`@/assets/images/${img}`);
        }
    }
};
