<template>
    <!--    #todo: Добавить рендеры для PremiumAcc в призы-->
    <div class="bp" :class="{
        'bp--main' : screen === 'main',
        'bp--finish' : screen === 'finish'
    }">
        <AppHeader backButton v-if="screen !== 'about'" @getBackHeader="handleBackHeader">
            <template #title>Battle Pass</template>
        </AppHeader>
        <div v-show="screen === 'main'" class="bp-content bp-main">
            <div class="bp-main__top">
                <div v-if="playerOwnBattlePass" class="bp-main__data">
                    <div class="bp-main__data-rewards">
                        <div class="bp-main__data-rewards-number">{{ rewardsGained }}</div>
                        <div class="bp-main__data-rewards-text">{{ $t('shop.awards_received') }}</div>
                    </div>
                    <div class="bp-main__data-button bp-main__data-button--levels" @click="buyLevels">
                        <IconFastForward />
                        <div class="bp-main__data-button__text">{{ $t('shop.buy') }}</div>
                        <div class="bp-main__data-button__text">{{ $t('shop.levels') }}</div>
                    </div>
                    <div class="bp-main__data-button bp-main__data-button--gift" @click="buyAsGift">
                        <IconGift />
                        <div class="bp-main__data-button__text">{{ $t('shop.gift_battlepass') }}</div>
                    </div>
                </div>
                <div v-else class="bp-main__banner">
                    <div class="bp-main__banner-col">
                        <div class="bp-main__banner-text">{{ $t('shop.pass') }}</div>
                        <div class="bp-main__banner-title">Premium</div>
                        <div class="bp-main__banner-info">
                            <div class="bp-main__banner-title">+{{ rarePrizes }}</div>
                            <div class="bp-main__banner-text">{{ $t('shop.rare_prize') }}</div>
                        </div>
                        <BaseButton @click="changeScreen('about')" class="bp-main__banner-button" yellow>{{ $t('shop.details') }}</BaseButton>
                    </div>
                    <img class="bp-main__banner-image" src="@/assets/images/battlePass/icon.png" alt="">
                </div>
                <div class="bp-main__level">
                    <div class="bp-main__level-title">
                        <!--{{ $t('shop.remaining', { daysLeft }) }}-->
                    </div>
                    <div class="bp-main__level-graph">
                        <img v-if="playerOwnBattlePass" class="bp-main__level-graph-crown" src="@/assets/images/battlePass/icon.png" alt="" />
                        <IconBattlePassLevel :currentExp="exp.current" :fullExp="exp.full" />
                        <div class="bp-main__level-graph-info">
                            <div class="bp-main__level-graph-text" :class="{'bp-main__level-graph-text--premium' : playerOwnBattlePass}">
                                {{ playerOwnBattlePass ? 'premium' : $t('shop.lvl') }}
                            </div>
                            <div class="bp-main__level-graph-level">
                                {{ level }}
                            </div>
                        </div>
                        <div class="bp-main__level-graph-progress">
                            <IconXP />
                            <div class="bp-main__level-graph-progress-exp">
                                {{ $formatNum(exp.current) }}
                            </div>
                            <div class="bp-main__level-graph-progress-amount">
                                / {{ $formatNum(exp.full) }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bp-main__bonus" @click="changeScreen('finish')">
                    <img class="bp-main__bonus-image" src="@/assets/images/battlePass/bonus.png" alt="">
                    <div class="bp-main__bonus-text">{{ $t('shop.bonus') }}</div>
                    <div class="bp-main__bonus-text">{{ $t('shop.prize') }}</div>
                    <div class="bp-main__bonus-open">{{ $t('shop.open') }}</div>
                </div>
                <div class="bp-main__tasks" @click="goTasks">
                    <IconCrown />
                    <div class="bp-main__tasks-info">
                        <div class="bp-main__bonus-text">{{ $t('shop.task') }}</div>
                        <div class="bp-main__bonus-text">{{ $t('shop.battlepass') }}</div>
                    </div>
                    <IconNextWhite />
                </div>
            </div>
            <div class="bp-main__slider">
                <div id="bp-main__slider-prev" class="bp-main__slider-prev">
                    <IconDropdownBack />
                </div>
                <swiper ref="swiperBattlePass" :options="swiperOptions">
                    <swiper-slide
                        v-for="item in sliderVirtual.slides"
                        :key="item.id"
                        :style="{ left: `${sliderVirtual.offset}px` }"
                    >
                        <BattlePassItem
                            :item="item" :level="level"
                            :openedReward="openedReward"
                            :playerOwnBattlePass="playerOwnBattlePass"
                            @get-prize="getPrize(item)"
                            @open-reward="openReward(item)"
                            @open-reward-second="openReward(item.secondReward)"
                        />
                    </swiper-slide>
                </swiper>
                <div id="bp-main__slider-next" class="bp-main__slider-next">
                    <IconDropdownBack />
                </div>
            </div>
        </div>
        <transition name="fade">
            <BattlePassAbout v-if="screen === 'about'" :price="battlePassPrice" @buyBattlePass="buyBattlePass" @changeScreen="changeScreen" />
        </transition>
        <div v-show="screen === 'finish'" class="bp-content bp-finish">
            <div class="bp-finish__top">
                <div v-if="level === slider.length" class="bp-finish__left">
                    <div class="bp-finish__number">
                        <span>{{ levelTasks - 1 }}</span> /  {{ sliderTasks.length }}
                    </div>
                    <div class="bp-finish__text">
                        {{ $t('shop.completed_tasks') }}
                    </div>
                </div>
                <div v-else class="bp-finish__left">
                    <IconInfoTransparent />
                    <div class="bp-finish__text">
                        {{ $t('shop.available_after') }}
                    </div>
                </div>
                <div v-if="level === slider.length" class="bp-finish__badge">
                    <IconCrownBonus />
                    <div class="bp-finish__badge-text">
                        {{ $t('shop.bonus_tasks') }}
                    </div>
                </div>
                <div v-else class="bp-finish__right">
                    <div class="bp-finish__quantity">
                        {{ sliderTasks.length }}
                    </div>
                    <div class="bp-finish__text">
                        {{ $t('shop.unique_prizes') }}
                    </div>
                </div>
            </div>
            <div class="bp-main__slider bp-finish__slider">
                <div id="bp-finish__slider-prev" class="bp-finish__slider-prev bp-main__slider-prev">
                    <IconDropdownBack />
                </div>
                <swiper ref="swiperBonus" :options="swiperBonusOptions">
                    <swiper-slide v-for="item in bonusSliderVirtual.slides" :key="item.id" :style="{ left: `${bonusSliderVirtual.offset}px` }">
                        <BattlePassItem
                            :item="item" :level="level"
                            :openedReward="openedReward"
                            :playerOwnBattlePass="playerOwnBattlePass"
                            @get-prize="getPrize(item)"
                            @open-reward="openReward(item)"
                            @open-reward-second="openReward(item.secondReward)"
                            :bonusSlider="true"
                        />
                    </swiper-slide>
                </swiper>
                <div id="bp-finish__slider-next" class="bp-finish__slider-next bp-main__slider-next">
                    <IconDropdownBack />
                </div>
            </div>
        </div>
        <transition name="slide-desc">
            <BattlePassItemDescription :item="openedReward" v-if="openedReward" @closeDesc="closeReward" />
        </transition>
    </div>
</template>

<script>
import AppHeader from '@/components/AppHeader/AppHeader.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import IconFastForward from '@/components/icons/IconFastForward.vue';
import IconGift from '@/components/icons/IconGift.vue';
import IconBattlePassLevel from '@/components/popups/content/battlePass/icons/IconBattlePassLevel.vue';
import IconXP from '@/components/icons/IconXP.vue';
import IconCrown from '@/components/icons/IconCrown.vue';
import IconNextWhite from '@/components/icons/IconNextWhite.vue';
import awesomeSwiperBuilder from 'vue-awesome-swiper/dist/exporter';
import SwiperConstructor from 'swiper';
import { mapGetters } from 'vuex';
import IconDropdownBack from '@/components/icons/IconDropdownBack.vue';
import BattlePassItemDescription from '@/components/popups/content/battlePass/components/BattlePassItemDescription.vue';
import BattlePassAbout from '@/components/popups/content/battlePass/components/BattlePassAbout.vue';
import IconInfoTransparent from '@/components/icons/IconInfoTransparent.vue';
import IconCrownBonus from '@/components/icons/IconCrownBonus.vue';
import BattlePassItem from '@/components/popups/content/battlePass/components/BattlePassItem.vue';
const { Swiper, SwiperSlide } = awesomeSwiperBuilder(SwiperConstructor);

export default {
    name: 'BattlePass',
    components: {
        BattlePassItem,
        IconCrownBonus,
        IconInfoTransparent,
        BattlePassAbout,
        BattlePassItemDescription,
        IconDropdownBack,
        IconNextWhite,
        IconCrown,
        IconXP,
        IconBattlePassLevel,
        IconGift,
        IconFastForward,
        BaseButton,
        AppHeader,
        Swiper,
        SwiperSlide
    },
    data () {
        return {
            screen: 'main', // main, about, finish
            rarePrizes: 34,
            daysLeft: 60,
            level: 6,
            levelTasks: 3,
            exp: {
                current: 0,
                full: 9000
            },
            openedReward: null,
            isSliderReady: false,
            slider: [
                {
                    id: 1,
                    name: this.$t('inventory.money') + ' 500 $',
                    rarity: 'ordinary',
                    isOwned: 1,
                    desc: 'inventory.desc.money',
                    img: 'other_items/money'
                },
                {
                    id: 2,
                    name: 'inventory.repair_kit',
                    rarity: 'ordinary',
                    isOwned: 1,
                    desc: 'inventory.desc.repair_kit',
                    img: 'other_items/repair-box'
                },
                {
                    id: 3,
                    name: 'inventory.glasses',
                    rarity: 'rare',
                    isOwned: 0,
                    desc: 'inventory.clothesDescription',
                    img: '2/6/5_0',
                    isPremium: 1
                },
                {
                    id: 4,
                    name: 'inventory.shoes',
                    rarity: 'epic',
                    isOwned: 0,
                    desc: 'inventory.clothesDescription',
                    img: '0/3/17_2',
                    isPremium: 1
                },
                {
                    id: 5,
                    name: this.$t('inventory.donate') + ' 2 Coin',
                    rarity: 'rare',
                    isOwned: 0,
                    desc: 'inventory.desc.donate',
                    img: 'other_items/donate'
                },
                {
                    id: 6,
                    name: 'inventory.medical_kit',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.medical_kit',
                    img: 'other_items/first_kit'
                },
                {
                    id: 7,
                    name: 'inventory.canister',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.canister',
                    img: 'other_items/canister'
                },
                {
                    id: 8,
                    name: this.$t('inventory.money') + ' $1000',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.money',
                    img: 'other_items/money'
                },
                {
                    id: 9,
                    name: 'inventory.spray_paint',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.spray_paint',
                    img: 'other_items/spraycan',
                    isPremium: 1
                },
                {
                    id: 10,
                    name: 'inventory.body_armor',
                    rarity: 'rare',
                    isOwned: 0,
                    desc: 'inventory.desc.body_armor',
                    img: '2/9/1_0',
                    isPremium: 1
                },
                {
                    id: 11,
                    name: 'inventory.pistol',
                    rarity: 'rare',
                    isOwned: 0,
                    desc: 'inventory.desc.firearms',
                    img: 'other_items/pistol'
                },
                {
                    id: 12,
                    name: this.$t('inventory.money') + ' $1000',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.money',
                    img: 'other_items/money'
                },
                {
                    id: 13,
                    name: 'inventory.glasses',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.clothesDescription',
                    img: '2/6/19_1'
                },
                {
                    id: 14,
                    name: 'inventory.mask',
                    rarity: 'unusual',
                    isOwned: 0,
                    desc: 'inventory.desc.mask',
                    img: '2/7/15_2',
                    isPremium: 1
                },
                {
                    id: 15,
                    name: this.$t('inventory.donate') + ' 2 Coin',
                    rarity: 'unusual',
                    isOwned: 0,
                    desc: 'inventory.desc.donate',
                    img: 'other_items/donate',
                    isPremium: 1
                },
                {
                    id: 16,
                    name: 'inventory.headgear',
                    rarity: 'rare',
                    isOwned: 0,
                    desc: 'inventory.clothesDescription',
                    img: '2/5/11_0'
                },
                {
                    id: 17,
                    name: 'inventory.medical_kit',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.medical_kit',
                    img: 'other_items/first_kit'
                },
                {
                    id: 18,
                    name: 'inventory.seeds',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.other_items_seeds',
                    img: 'other_items/seeds'
                },
                {
                    id: 19,
                    name: 'inventory.car',
                    rarity: 'unusual',
                    isOwned: 0,
                    desc: 'inventory.desc.car',
                    img: 'vehicles/14_Insignia',
                    isPremium: 1
                },
                {
                    id: 20,
                    name: 'inventory.outer_clothing',
                    rarity: 'epic',
                    isOwned: 0,
                    desc: 'inventory.clothesDescription',
                    img: '0/1/35_0',
                    isPremium: 1
                },
                {
                    id: 21,
                    name: 'inventory.canister',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.canister',
                    img: 'other_items/canister'
                },
                {
                    id: 22,
                    name: 'inventory.medical_kit',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.medical_kit',
                    img: 'other_items/first_kit'
                },
                {
                    id: 23,
                    name: this.$t('inventory.money') + ' $1500',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.money',
                    img: 'other_items/money'
                },
                {
                    id: 24,
                    name: 'inventory.pants',
                    rarity: 'unusual',
                    isOwned: 0,
                    desc: 'inventory.clothesDescription',
                    img: '0/2/15_0',
                    isPremium: 1
                },
                {
                    id: 25,
                    name: this.$t('inventory.donate') + ' 2 Coin',
                    rarity: 'unusual',
                    isOwned: 0,
                    desc: 'inventory.desc.donate',
                    img: 'other_items/donate',
                    isPremium: 1
                },
                {
                    id: 26,
                    name: 'inventory.medical_kit',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.medical_kit',
                    img: 'other_items/first_kit'
                },
                {
                    id: 27,
                    name: 'inventory.assault_rifle',
                    rarity: 'rare',
                    isOwned: 0,
                    desc: 'inventory.desc.weapon',
                    img: 'other_items/m4'
                },
                {
                    id: 28,
                    name: 'inventory.spray_paint',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.spray_paint',
                    img: 'other_items/spraycan'
                },
                {
                    id: 29,
                    name: 'Premium Account',
                    rarity: 'epic',
                    isOwned: 0,
                    desc: 'inventory.desc.vip_acc',
                    img: 'other_items/vip',
                    isPremium: 1
                },
                {
                    id: 30,
                    name: 'inventory.seeds',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.other_items_seeds',
                    img: 'other_items/seeds'
                },
                {
                    id: 31,
                    name: 'inventory.repair_kit',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.repair_kit',
                    img: 'other_items/repair-box'
                },
                {
                    id: 32,
                    name: this.$t('inventory.money') + ' $1500',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.money',
                    img: 'other_items/money'
                },
                {
                    id: 33,
                    name: 'inventory.body_armor',
                    rarity: 'rare',
                    isOwned: 0,
                    desc: 'inventory.desc.body_armor',
                    img: '2/9/1_0'
                },
                {
                    id: 34,
                    name: 'inventory.headgear',
                    rarity: 'rare',
                    isOwned: 0,
                    desc: 'inventory.clothesDescription',
                    img: '2/5/20_1',
                    isPremium: 1
                },
                {
                    id: 35,
                    name: this.$t('inventory.donate') + ' 3 Coin',
                    rarity: 'unusual',
                    isOwned: 0,
                    desc: 'inventory.desc.donate',
                    img: 'other_items/donate',
                    isPremium: 1
                },
                {
                    id: 36,
                    name: 'inventory.outer_clothing',
                    rarity: 'rare',
                    isOwned: 0,
                    desc: 'inventory.clothesDescription',
                    img: '0/1/5_6'
                },
                {
                    id: 37,
                    name: 'inventory.canister',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.canister',
                    img: 'other_items/canister'
                },
                {
                    id: 38,
                    name: 'inventory.seeds',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.other_items_seeds',
                    img: 'other_items/seeds'
                },
                {
                    id: 39,
                    name: this.$t('inventory.money') + ' $2000',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.money',
                    img: 'other_items/money'
                },
                {
                    id: 40,
                    name: 'inventory.car',
                    rarity: 'epic',
                    isOwned: 0,
                    desc: 'inventory.desc.car',
                    img: 'vehicles/08_Fusion',
                    isPremium: 1
                },
                {
                    id: 41,
                    name: 'inventory.spray_paint',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.spray_paint',
                    img: 'other_items/spraycan'
                },
                {
                    id: 42,
                    name: 'inventory.pants',
                    rarity: 'rare',
                    isOwned: 0,
                    desc: 'inventory.clothesDescription',
                    img: '0/2/18_4',
                    isPremium: 1
                },
                {
                    id: 43,
                    name: 'inventory.medical_kit',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.medical_kit',
                    img: 'other_items/first_kit'
                },
                {
                    id: 44,
                    name: 'inventory.body_armor',
                    rarity: 'rare',
                    isOwned: 0,
                    desc: 'inventory.desc.body_armor',
                    img: '2/9/1_0',
                    isPremium: 1
                },
                {
                    id: 45,
                    name: 'inventory.shoes',
                    rarity: 'rare',
                    isOwned: 0,
                    desc: 'inventory.clothesDescription',
                    img: '0/3/12_1'
                },
                {
                    id: 46,
                    name: 'inventory.shotgun',
                    rarity: 'rare',
                    isOwned: 0,
                    desc: 'inventory.desc.firearms',
                    img: 'other_items/shotgun'
                },
                {
                    id: 47,
                    name: this.$t('inventory.money') + ' $1500',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.money',
                    img: 'other_items/money'
                },
                {
                    id: 48,
                    name: 'inventory.repair_kit',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.repair_kit',
                    img: 'other_items/repair-box'
                },
                {
                    id: 49,
                    name: 'inventory.assault_rifle',
                    rarity: 'rare',
                    isOwned: 0,
                    desc: 'inventory.desc.weapon',
                    img: 'other_items/m4',
                    isPremium: 1
                },
                {
                    id: 50,
                    name: this.$t('inventory.donate') + ' 5 Coin',
                    rarity: 'unusual',
                    isOwned: 0,
                    desc: 'inventory.desc.donate',
                    img: 'other_items/donate',
                    isPremium: 1
                },
                {
                    id: 51,
                    name: 'inventory.outer_clothing',
                    rarity: 'epic',
                    isOwned: 0,
                    desc: 'inventory.clothesDescription',
                    img: '0/1/24_4'
                },
                {
                    id: 52,
                    name: 'inventory.medical_kit',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.medical_kit',
                    img: 'other_items/first_kit'
                },
                {
                    id: 53,
                    name: 'inventory.pants',
                    rarity: 'rare',
                    isOwned: 0,
                    desc: 'inventory.clothesDescription',
                    img: '0/2/25_2',
                    isPremium: 1
                },
                {
                    id: 54,
                    name: this.$t('inventory.money') + ' $2500',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.money',
                    img: 'other_items/money',
                    isPremium: 1
                },
                {
                    id: 55,
                    name: 'Premium Account',
                    rarity: 'epic',
                    isOwned: 0,
                    desc: 'inventory.desc.vip_acc',
                    img: 'other_items/vip'
                },
                {
                    id: 56,
                    name: 'inventory.outer_clothing',
                    rarity: 'unusual',
                    isOwned: 0,
                    desc: 'inventory.clothesDescription',
                    img: '0/1/24_0'
                },
                {
                    id: 57,
                    name: 'inventory.seeds',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.other_items_seeds',
                    img: 'other_items/seeds'
                },
                {
                    id: 58,
                    name: 'inventory.spray_paint',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.spray_paint',
                    img: 'other_items/spraycan'
                },
                {
                    id: 59,
                    name: 'inventory.glasses',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.clothesDescription',
                    img: '2/6/21_0'
                },
                {
                    id: 60,
                    name: 'inventory.mask',
                    rarity: 'epic',
                    isOwned: 0,
                    desc: 'inventory.desc.mask',
                    img: '2/7/7_0',
                    isPremium: 1
                },
                {
                    id: 61,
                    name: this.$t('inventory.money') + ' $1500',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.money',
                    img: 'other_items/money'
                },
                {
                    id: 62,
                    name: 'inventory.gloves',
                    rarity: 'unusual',
                    isOwned: 0,
                    desc: 'inventory.clothesDescription',
                    img: '2/8/F_2_0'
                },
                {
                    id: 63,
                    name: 'inventory.medical_kit',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.medical_kit',
                    img: 'other_items/first_kit'
                },
                {
                    id: 64,
                    name: 'inventory.accessories',
                    rarity: 'epic',
                    isOwned: 0,
                    desc: 'inventory.clothesDescription',
                    img: '2/4/4_0',
                    isPremium: 1
                },
                {
                    id: 65,
                    name: 'inventory.assault_rifle',
                    rarity: 'rare',
                    isOwned: 0,
                    desc: 'inventory.desc.weapon',
                    img: 'other_items/m4',
                    isPremium: 1
                },
                {
                    id: 66,
                    name: 'inventory.repair_kit',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.repair_kit',
                    img: 'other_items/repair-box'
                },
                {
                    id: 67,
                    name: 'inventory.shotgun',
                    rarity: 'rare',
                    isOwned: 0,
                    desc: 'inventory.desc.firearms',
                    img: 'other_items/shotgun'
                },
                {
                    id: 68,
                    name: this.$t('inventory.money') + ' $1000',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.money',
                    img: 'other_items/money'
                },
                {
                    id: 69,
                    name: 'inventory.headgear',
                    rarity: 'rare',
                    isOwned: 0,
                    desc: 'inventory.clothesDescription',
                    img: '2/5/32_0',
                    isPremium: 1
                },
                {
                    id: 70,
                    name: this.$t('inventory.donate') + ' 2 Coin',
                    rarity: 'unusual',
                    isOwned: 0,
                    desc: 'inventory.desc.donate',
                    img: 'other_items/donate',
                    isPremium: 1
                },
                {
                    id: 71,
                    name: 'inventory.body_armor',
                    rarity: 'rare',
                    isOwned: 0,
                    desc: 'inventory.desc.body_armor',
                    img: '2/9/1_0'
                },
                {
                    id: 72,
                    name: this.$t('inventory.money') + ' $1500',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.money',
                    img: 'other_items/money'
                },
                {
                    id: 73,
                    name: 'inventory.canister',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.canister',
                    img: 'other_items/canister'
                },
                {
                    id: 74,
                    name: 'inventory.seeds',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.other_items_seeds',
                    img: 'other_items/seeds'
                },
                {
                    id: 75,
                    name: 'inventory.mask',
                    rarity: 'unusual',
                    isOwned: 0,
                    desc: 'inventory.desc.mask',
                    img: '2/7/14_1',
                    isPremium: 1
                },
                {
                    id: 76,
                    name: 'inventory.medical_kit',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.medical_kit',
                    img: 'other_items/first_kit'
                },
                {
                    id: 77,
                    name: 'Premium Account',
                    rarity: 'epic',
                    isOwned: 0,
                    desc: 'inventory.desc.vip_acc',
                    img: 'other_items/vip',
                    isPremium: 1
                },
                {
                    id: 78,
                    name: 'inventory.canister',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.canister',
                    img: 'other_items/canister'
                },
                {
                    id: 79,
                    name: 'inventory.repair_kit',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.repair_kit',
                    img: 'other_items/repair-box'
                },
                {
                    id: 80,
                    name: 'inventory.car',
                    rarity: 'epic',
                    isOwned: 0,
                    desc: 'inventory.desc.car',
                    img: 'vehicles/19_MinF60',
                    isPremium: 1
                },
                {
                    id: 81,
                    name: 'inventory.shoes',
                    rarity: 'rare',
                    isOwned: 0,
                    desc: 'inventory.clothesDescription',
                    img: '0/3/13_4'
                },
                {
                    id: 82,
                    name: this.$t('inventory.money') + ' $1500',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.money',
                    img: 'other_items/money'
                },
                {
                    id: 83,
                    name: 'inventory.pants',
                    rarity: 'rare',
                    isOwned: 0,
                    desc: 'inventory.clothesDescription',
                    img: '0/2/14_0'
                },
                {
                    id: 84,
                    name: this.$t('inventory.money') + ' $3000',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.money',
                    img: 'other_items/money',
                    isPremium: 1
                },
                {
                    id: 85,
                    name: 'inventory.seeds',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.other_items_seeds',
                    img: 'other_items/seeds'
                },
                {
                    id: 86,
                    name: 'inventory.spray_paint',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.spray_paint',
                    img: 'other_items/spraycan'
                },
                {
                    id: 87,
                    name: this.$t('inventory.money') + ' $2000',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.money',
                    img: 'other_items/money'
                },
                {
                    id: 88,
                    name: 'inventory.repair_kit',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.repair_kit',
                    img: 'other_items/repair-box'
                },
                {
                    id: 89,
                    name: 'inventory.gloves',
                    rarity: 'rare',
                    isOwned: 0,
                    desc: 'inventory.clothesDescription',
                    img: '2/8/F_4_0',
                    isPremium: 1
                },
                {
                    id: 90,
                    name: this.$t('inventory.donate') + ' 5 Coin',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.donate',
                    img: 'other_items/donate',
                    isPremium: 1
                },
                {
                    id: 91,
                    name: 'inventory.canister',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.canister',
                    img: 'other_items/canister'
                },
                {
                    id: 92,
                    name: 'inventory.medical_kit',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.medical_kit',
                    img: 'other_items/first_kit'
                },
                {
                    id: 93,
                    name: this.$t('inventory.money') + ' $2000',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.money',
                    img: 'other_items/money'
                },
                {
                    id: 94,
                    name: 'inventory.outer_clothing',
                    rarity: 'epic',
                    isOwned: 0,
                    desc: 'inventory.clothesDescription',
                    img: '0/1/28_5',
                    isPremium: 1
                },
                {
                    id: 95,
                    name: 'inventory.shoes',
                    rarity: 'rare',
                    isOwned: 0,
                    desc: 'inventory.clothesDescription',
                    img: '0/3/15_3',
                    isPremium: 1
                },
                {
                    id: 96,
                    name: 'inventory.body_armor',
                    rarity: 'rare',
                    isOwned: 0,
                    desc: 'inventory.desc.body_armor',
                    img: '2/9/1_0'
                },
                {
                    id: 97,
                    name: 'Premium Account',
                    rarity: 'epic',
                    isOwned: 0,
                    desc: 'inventory.desc.vip_acc',
                    img: 'other_items/vip'
                },
                {
                    id: 98,
                    name: this.$t('inventory.money') + ' $3000',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'inventory.desc.money',
                    img: 'other_items/money'
                },
                {
                    id: 99,
                    name: this.$t('inventory.donate') + ' 5 Coin',
                    rarity: 'unusual',
                    isOwned: 0,
                    desc: 'inventory.desc.donate',
                    img: 'other_items/donate'
                },
                {
                    id: 100,
                    name: 'inventory.car',
                    rarity: 'legend',
                    isOwned: 0,
                    desc: 'inventory.desc.car',
                    img: 'vehicles/09_CorC7'
                }
            ],
            sliderTasks: [
                {
                    id: 1,
                    name: 'Штурмовая винтовка',
                    rarity: 'unusual',
                    isOwned: 1,
                    desc: 'Скорострельное огнестрельное оружие',
                    img: 'other_items/m4',
                    secondReward: {
                        id: 1,
                        name: 'Штурмовая винтовка',
                        rarity: 'epic',
                        desc: 'Скорострельное огнестрельное оружие',
                        img: 'other_items/m4'
                    }
                },
                {
                    id: 2,
                    name: 'Пистолет',
                    rarity: 'epic',
                    isOwned: 0,
                    desc: 'Скорострельное огнестрельное оружие',
                    img: 'other_items/pistol',
                    secondReward: {
                        id: 2,
                        name: 'Штурмовая винтовка',
                        rarity: 'rare',
                        desc: 'Скорострельное огнестрельное оружие',
                        img: 'other_items/m4'
                    }
                },
                {
                    id: 3,
                    name: 'Пистолет',
                    rarity: 'legend',
                    isOwned: 0,
                    desc: 'Скорострельное огнестрельное оружие',
                    img: 'other_items/pistol',
                    isPremium: 1,
                    secondReward: {
                        id: 3,
                        name: 'Штурмовая винтовка',
                        rarity: 'legend',
                        desc: 'Скорострельное огнестрельное оружие',
                        img: 'other_items/m4'
                    }
                },
                {
                    id: 4,
                    name: 'Пистолет',
                    rarity: 'legend',
                    isOwned: 0,
                    desc: 'Скорострельное огнестрельное оружие',
                    img: 'other_items/pistol',
                    isPremium: 1,
                    secondReward: {
                        id: 4,
                        name: 'Штурмовая винтовка',
                        rarity: 'unusual',
                        desc: 'Скорострельное огнестрельное оружие',
                        img: 'other_items/m4'
                    }
                },
                {
                    id: 5,
                    name: 'Штурмовая винтовка',
                    rarity: 'ordinary',
                    isOwned: 0,
                    desc: 'Скорострельное огнестрельное оружие',
                    img: 'other_items/m4',
                    secondReward: {
                        id: 5,
                        name: 'Штурмовая винтовка',
                        rarity: 'legend',
                        desc: 'Скорострельное огнестрельное оружие',
                        img: 'other_items/m4'
                    }
                },
                {
                    id: 6,
                    name: 'Пистолет',
                    rarity: 'epic',
                    isOwned: 0,
                    desc: 'Скорострельное огнестрельное оружие',
                    img: 'other_items/pistol',
                    secondReward: {
                        id: 6,
                        name: 'Штурмовая винтовка',
                        rarity: 'unusual',
                        desc: 'Скорострельное огнестрельное оружие',
                        img: 'other_items/m4'
                    }
                },
                {
                    id: 7,
                    name: 'Пистолет',
                    rarity: 'epic',
                    isOwned: 0,
                    desc: 'Скорострельное огнестрельное оружие',
                    img: 'other_items/pistol',
                    secondReward: {
                        id: 7,
                        name: 'Штурмовая винтовка',
                        rarity: 'ordinary',
                        desc: 'Скорострельное огнестрельное оружие',
                        img: 'other_items/m4'
                    }
                }
            ],
            battlePassPrice: 600,
            sliderVirtual: {
                slides: []
            },
            bonusSliderVirtual: {
                slides: []
            }
        };
    },
    computed: {
        ...mapGetters('inventory', ['inventoryItemsPool']),
        // swiper () {
        //     return this.$refs.swiperBattlePass.$swiper;
        // },
        // swiperBonus () {
        //     return this.$refs.swiperBonus.$swiper;
        // },
        playerOwnBattlePass () {
            // return 1;
            return this.$store.state.player.ownBattlePass;
        },
        rewardsGained () {
            return this.slider.filter(el => el.isOwned).length;
        },
        swiperOptions () {
            return {
                slidesPerView: 5,
                spaceBetween: 8,
                loop: false,
                allowTouchMove: false,
                centeredSlides: true, // fix for last elem
                centeredSlidesBounds: true, // fix for last elem
                navigation: {
                    nextEl: '#bp-main__slider-next',
                    prevEl: '#bp-main__slider-prev'
                },
                virtual: {
                    slides: this.slider,
                    renderExternal: (data) => {
                        this.sliderVirtual = data;
                    }
                }
            };
        },
        swiperBonusOptions () {
            return {
                slidesPerView: '5',
                spaceBetween: 8,
                loop: false,
                allowTouchMove: false,
                centeredSlides: true, // fix for last elem
                centeredSlidesBounds: true, // fix for last elem
                navigation: {
                    nextEl: '#bp-finish__slider-next',
                    prevEl: '#bp-finish__slider-prev'
                },
                virtual: {
                    slides: this.sliderTasks,
                    renderExternal: (data) => {
                        this.bonusSliderVirtual = data;
                    }
                }
            };
        }
    },
    methods: {
        handleBackHeader () {
            if (this.screen === 'main') {
                this.sendclient('closeDesigns');
            } else {
                this.screen = 'main';
            }
        },
        /*
        window.modifyVueComponent({
            App: {
                showPopup: true,
                popupContent: 'BattlePass',
                contentSubFolder: 'battlePass'
            },
            BattlePass: {
                initialize: [
                    10,1000,1,0
                ]
            }
        });
        */
        initialize (level, exp, prize, premiumPrize) {
            this.level = level;
            this.exp.current = exp;
            for (let i = 0; i < this.slider.length; i++) {
                if (prize >= i + 1 && !this.slider[i].isPremium) this.slider[i].isOwned = true;
                else if (premiumPrize >= i + 1 && this.slider[i].isPremium) this.slider[i].isOwned = true;
                else this.slider[i].isOwned = false;
            }
        },
        gotPrize (number) {
            this.slider[number - 1].isOwned = true;
        },
        updateLevel (number) {
            this.level = number;
        },
        buyPremium () {
            this.screen = 'main';
        },
        // initSwiper (type) {
        //     if (type === 'main') {
        //         this.slider = new Swiper(this.$refs.swiperBattlePass, this.swiperOptions);
        //     }
        //     if (type === 'finish') {
        //         this.sliderBonus = new Swiper(this.$refs.swiperBonus, this.swiperOptions);
        //     }
        // },
        getPrize (item) {
            // console.log('забираем приз, ID', item.id);
            // item.isOwned = 1;
            this.sendclient('BPGetPrize', JSON.stringify({ a: item.id }));
        },
        openReward (item) {
            this.openedReward = item;
        },
        closeReward () {
            this.openedReward = null;
        },
        changeScreen (type) {
            // if (type === 'finish') {
            //     if (this.$refs.swiperBattlePass.$swiper) {
            //         this.$refs.swiperBattlePass.$swiper.destroy();
            //     }
            //     if (!this.$refs.swiperBonus.$swiper) {
            //         this.initSwiper('main');
            //     }
            // }
            // if (type === 'main') {
            //     if (this.$refs.swiperBonus.$swiper) {
            //         this.$refs.swiperBonus.$swiper.destroy();
            //     }
            //     if (!this.$refs.swiperBattlePass.$swiper) {
            //         this.initSwiper('finish');
            //     }
            // }
            // if (type === 'about') {
            //     if (this.$refs.swiperBonus.$swiper) {
            //         this.$refs.swiperBonus.$swiper.destroy();
            //     }
            //     if (this.$refs.swiperBattlePass.$swiper) {
            //         this.$refs.swiperBattlePass.$swiper.destroy();
            //     }
            // }
            this.screen = type;
        },
        buyBattlePass () {
            this.sendclient('BPBuy');
        },
        goTasks () {
            this.sendclient('BPGoTasks');
        },
        buyLevels () {
            this.sendclient('BPBuyLevel');
        },
        buyAsGift () {
            this.sendclient('BPGiftBP');
        }
    }
};
</script>
<style lang="scss" scoped>
.bp {
    height: 100%;
    &--main {
        background: url("@/assets/images/battlePass/splashes.svg") no-repeat center/cover, radial-gradient(69.1% 149.53% at 50% 0%, #4534A8 0%, #1E1142 100%);
    }
    &--finish {
        position: relative;
        background: url("@/assets/images/battlePass/splashes-yellow.svg") no-repeat top/contain, radial-gradient(62.74% 131.28% at 50% -21.82%, #E7305C 0%, #3E3187 100%);
        &::after, &::before {
            content: "";
            position: absolute;
            background-size: contain;
            background-image: url("@/assets/images/battlePass/diamond.png");
        }
        &::after {
            width: 669px;
            height: 410px;
            transform: rotate(-31.024deg) translateX(-50%);
            top: -134px;
            left: 50%;
        }
        &::before {
            width: 232px;
            height: 142px;
            transform: rotate(28.976deg);
            right: 30px;
            top: 195px;
        }
    }
    &-content {
        height: calc(100% - 5vw);
    }
    &-main {
        padding: 70px 0 0;
        display: grid;
        grid-template-rows: 452px 498px;
        justify-items: center;
        align-content: space-between;
        position: relative;
        &__top {
            display: grid;
            padding: 0 300px;
            grid-template-columns: 680px 444px 300px 335px;
            grid-gap: 0 44px;
        }
        &__banner {
            position: relative;
            border-radius: 44px;
            background: url("@/assets/images/battlePass/fire.png") center/cover, radial-gradient(110.26% 113.85% at 30% 22.35%, #42329F 33.33%, #FFA24C 100%);
            box-shadow: -36px -36px 120px 0px rgba(255, 189, 67, 0.50) inset;
            display: grid;
            grid-template-columns: 270px 1fr;
            grid-gap: 0 15px;
            align-items: center;
            padding-left: 54px;
            overflow: hidden;
            z-index: 3;
            &-col {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                z-index: 5;
            }
            &-info {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 24px;
                .bp-main__banner-text {
                    text-align: right;
                }
            }
            &-title {
                margin: 8px 0;
                color: #FFF;
                font-size: 80px;
                font-weight: 900;
                line-height: 100%;
                font-family: var(--font-stretch);
                text-transform: uppercase;
            }
            &-text {
                color: var(--white);
                font-size: 28px;
                font-weight: 700;
                line-height: 110%;
                letter-spacing: 1.12px;
                text-transform: uppercase;
            }
            &-image {
                z-index: 3;
                transform: rotate(15deg) translateX(32px);
                width: 339px;
                height: 339px;
            }
            &-button {
                padding: 0 44px;
                height: 112px;
                font-size: 36px;
                border-radius: 4px;
            }
        }
        &__data {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 111px 1fr;
            grid-gap: 44px;
            &-rewards {
                grid-column: 2 span;
                border-radius: 44px;
                background: radial-gradient(85% 194.56% at 27.85% 18.02%, #6B58D7 0%, rgba(110, 87, 242, 0) 100%);
                display: flex;
                justify-content: center;
                align-items: center;
                color: var(--white);
                font-family: var(--font-stretch);
                text-transform: uppercase;
                &-number {
                    margin-right: 24px;
                    font-size: 64px;
                    font-weight: 900;
                    line-height: 90%;
                }
                &-text {
                    font-size: 38px;
                    font-weight: 500;
                    line-height: 90%;
                    letter-spacing: 3.04px;
                }
            }
            &-button {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: var(--white);
                font-family: var(--font-stretch);
                font-size: 38px;
                font-weight: 900;
                line-height: 90%;
                text-transform: uppercase;
                border-radius: 44px;
                svg {
                    margin-bottom: 24px;
                    width: 64px;
                    height: 64px;
                }
                &--levels {
                    background: radial-gradient(113.77% 113.77% at 66.35% 68.41%, #4BE7A1 0%, rgba(75, 231, 161, 0.00) 100%);
                    .bp-main__data-button__text:last-child {
                        margin-top: 8px;
                    }
                }
                &--gift {
                    background: radial-gradient(151.39% 151.39% at 19.81% 84.29%, #D1683A 27.08%, rgba(255, 33, 86, 0.00) 95.31%);
                    padding: 0 47px;
                    text-align: center;
                }
            }
        }
        &__level {
            display: flex;
            flex-direction: column;
            //justify-content: center;
            align-items: center;
            svg {
                width: 346px;
                height: 346px;
            }
            &-title {
                color: #856FFF;
                text-align: right;
                font-size: 28px;
                font-weight: 700;
                line-height: 100%;
                text-transform: uppercase;
            }
            &-graph {
                position: relative;
                margin: 16px 0;
                &-text {
                    margin-bottom: 16px;
                    color: var(--white);
                    text-align: center;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 100%;
                    letter-spacing: 0.96px;
                    text-transform: uppercase;
                    &--premium {
                        text-shadow: 0 7px 34px var(--bp-green), 0 0 80px var(--bp-green);
                        color: var(--bp-green);
                    }
                }
                &-level {
                    color: #FFF;
                    text-align: center;
                    font-family: var(--font-stretch);
                    font-size: 134px;
                    font-weight: 900;
                    line-height: 80%;
                    text-transform: uppercase;
                }
                &-info {
                    position: absolute;
                    top: 70px;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
                &-crown {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 62px;
                    height: 62px;
                }
                &-progress {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    svg {
                        width: 44px;
                        height: 44px;
                        margin-right: 24px;
                    }
                    &-exp {
                        color: var(--bp-green);
                        text-align: center;
                        font-size: 44px;
                        font-weight: 700;
                        line-height: 100%;
                        margin-right: 12px;
                    }
                    &-amount {
                        color: #856FFF;
                        font-size: 28px;
                        font-weight: 700;
                        line-height: 100%;
                        text-transform: uppercase;
                    }
                }
            }
        }
        &__bonus {
            border-radius: 44px;
            background: radial-gradient(162.9% 108.31% at 22.83% 18.92%, #5844C8 27.08%, #E7305C 100%);
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            padding-bottom: 24px;
            &-image {
                position: absolute;
                top: 14px;
                width: 390px;
                height: 346px;
                left: 50%;
                transform: translateX(-50%);
            }
            &-text {
                text-transform: uppercase;
                color: var(--white);
                font-family: var(--font-stretch);
                font-size: 44px;
                font-weight: 900;
                line-height: 90%;
                &:last-child {
                    margin-top: 8px;
                }
            }
            &-open {
                margin-top: 16px;
                opacity: .7;
                color: var(--white);
                text-align: center;
                font-family: var(--font-stretch);
                font-size: 20px;
                font-style: normal;
                font-weight: 900;
                line-height: 100%;
                letter-spacing: 0.8px;
                text-transform: uppercase;
                position: relative;
                display: flex;
                align-items: center;
                &::after,&::before {
                    content: '';
                    position: absolute;
                    width: 36px;
                    height: 2px;
                    background-color: var(--white);
                }
                &::before {
                    left: -48px;
                }
                &::after {
                    right: -48px;
                }
            }
        }
        &__tasks {
            border-radius: 44px;
            background: url("@/assets/images/battlePass/tasks-bg-left.svg") no-repeat left center/contain, url("@/assets/images/battlePass/tasks-bg-right.svg") no-repeat right center/contain, radial-gradient(159.7% 118.36% at 50.15% 6.08%, rgba(75, 231, 161, 0.40) 0%, rgba(75, 231, 161, 0.00) 100%);
            display: flex;
            flex-direction: column;
            padding: 74px 0;
            align-items: center;
            justify-content: space-between;
            svg {
                width: 64px;
                height: 64px;
            }
            &-info {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
        &__slider {
            max-width: 100%;
            //overflow: hidden;
            padding: 0 128px;
            position: relative;
            display: flex;
            align-items: center;
            min-width: 0;
            &-prev, &-next {
                min-width: 112px;
                width: 112px;
                height: 280px;
                border-radius: 4px;
                background: linear-gradient(224deg, rgba(241, 241, 241, 0.20) 0%, rgba(241, 241, 241, 0.00) 100%);
                display: flex;
                justify-content: center;
                align-items: center;
                svg {
                    width: 64px;
                    height: 64px;
                }
                &:active {
                    opacity: .8;
                }
                &.swiper-button-disabled {
                    pointer-events: none;
                    opacity: .3;
                }
            }
            &-next {
                svg {
                    transform: rotate(180deg);
                }
            }
            &::v-deep {
                .swiper-container {
                    margin: 0 65px;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    &-finish {
        position: relative;
        padding-top: 150px;
        display: grid;
        justify-items: center;
        align-content: space-between;
        grid-template-rows: 106px 774px;
        &::before {
            content: "";
            position: absolute;
            background-size: contain;
            background-image: url("@/assets/images/battlePass/diamond.png");
            top: 187px;
            left: -112px;
            width: 412px;
            height: 252px;
            transform: rotate(210deg);
        }
        &__top {
            width: 100%;
            padding: 0 300px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &__left {
            display: flex;
            align-items: center;
            svg {
                width: 64px;
                height: 64px;
                margin-right: 16px;
                &::v-deep {
                    path {
                        fill: var(--gold);
                    }
                }
            }
        }
        &__right {
            display: flex;
            .bp-finish__text {
                color: var(--white);
                max-width: 201px;
            }
        }
        &__quantity {
            margin-right: 12px;
            color: var(--gold);
            font-family: var(--font-stretch);
            font-size: 80px;
            font-weight: 900;
            line-height: 100%;
            text-transform: uppercase;
        }
        &__number {
            margin-right: 24px;
            display: flex;
            align-items: center;
            color: rgba(255, 255, 255, .4);
            text-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);
            font-family: var(--font-stretch);
            font-size: 44px;
            font-weight: 700;
            line-height: 100%;
            text-transform: uppercase;
            span {
                margin-right: 16px;
                color: #FFF;
                line-height: 100%;
                font-size: 80px;
                font-weight: 900;
            }
        }
        &__text {
            max-width: 480px;
            color: var(--gold);
            font-size: 28px;
            font-weight: 700;
            line-height: 130%;
            letter-spacing: 1.12px;
            text-transform: uppercase;
        }
        &__slider {
            align-items: end;
            &-prev, &-next {
                margin-bottom: 150px;
            }
            &-inv {
                margin-top: 8px;
            }
            &-item {
                padding-top: 80px;
                &-owned {
                    position: absolute;
                    top: -77px;
                }
                &.bp-main__slider-item--gained {
                    background: linear-gradient(180deg, rgba(75, 231, 161, 0.00) 35%, rgba(75, 231, 161, 0.64) 100%);
                }
            }
        }
        &__badge {
            padding: 24px 40px;
            border-radius: 4px;
            background: linear-gradient(224deg, rgba(255, 205, 76, 0.50) 0%, rgba(255, 205, 76, 0.00) 100%);
            display: flex;
            &-text {
                color: var(--white);
                font-size: 24px;
                font-weight: 700;
                line-height: 120%;
                letter-spacing: 0.96px;
                text-transform: uppercase;
                max-width: 301px;
            }
            svg {
                width: 52px;
                height: 52px;
                margin-right: 32px;
            }
        }
    }
}
.slide-desc-enter-active, .slide-desc-leave-active {
    transition: all 0.3s ease-out;
}
.slide-desc-enter, .slide-desc-leave-to {
    transform: translateX(100%);
}
</style>
<style lang="scss">
@import "swiper/swiper.scss";
</style>
