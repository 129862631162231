<template>
    <div class="inventory">
        <AppHeader back-button nick-name>
            <template #countSlot>{{ countSlot }}</template>
        </AppHeader>

        <div class="inventory__wrapper">
            <div class="inventory__tabs">
                <div class="inventory__tabs-wrapper">
                    <div v-for="tab in tabDefinitions"
                         :key="tab.type"
                         class="inventory__tabs-item"
                         :class="{ 'inventory__tabs-item--active': selectedType === tab.type }"
                         @click="changeTab(tab.type)">
                        <IconInventory :type="tab.type"/>
                        {{ tab.text }}
                    </div>
                </div>

                <div class="inventory__tabs-buttons">
                    <div class="inventory__tabs-craft" @click="openCraft">
                        <img src="./assets/images/craft-icon.svg" alt="">
                    </div>
                </div>
            </div>

            <div class="inventory__cards">
                <div class="inventory__cards-wrapper">
                    <InventoryItem
                        v-for="(item, index) in computedInventoryItems"
                        :key="index || 'empty' + index"
                        :item="item"
                        :selectedItem="selectedItem"
                        @select-item="selectItem"
                    />
                    <div class="inventory__cards-update" v-if="selectedType === 'all'">
                        <div class="inventory__cards-shop">
                            <div>
                                <h5>{{ $t('inventory.shop.not_enough_slots') }}</h5>
                                <p>{{ $t('inventory.shop.buy_bag') }}</p>
                            </div>
                            <BaseButton yellow>{{ $t('inventory.to_shop') }} <IconNext /></BaseButton>
                        </div>
                        <div class="inventory__cards-item inventory__cards-item--empty"></div>
                        <div class="inventory__cards-item inventory__cards-item--empty"></div>
                        <div class="inventory__cards-item inventory__cards-item--empty"></div>
                        <div class="inventory__cards-item inventory__cards-item--empty"></div>
                        <div class="inventory__cards-item inventory__cards-item--empty"></div>
                    </div>
                </div>
            </div>

            <div class="inventory__information">
                <div class="inventory__information-item" v-if="selectedItem">
                    <div class="inventory__information-top">
                        <div class="inventory__information-title">{{ $t(selectedItem.name) }}</div>
                        <div class="inventory__information-labels">
                            <div class="inventory__information-put-on" v-if="selectedItem.isEquipped">
                                <BaseIcon name="checkmark-bold" color="#f1f1f1" />
                            </div>
                            <div class="inventory__information-label" :class="selectedItem.rarity">{{ rarityMap[selectedItem.rarity] }}</div>
                        </div>
                        <div class="inventory__information-desc">
                            {{ $t(selectedItem.desc) }}
                        </div>
                    </div>
                    <div class="inventory__buttons">
                        <button class="inventory__buttons-item" @click = "deleteItem(selectedItem)">
                            <IconBtnAction icon="trash" />
                            {{ $t('inventory.buttons.delete') }}
                        </button>
                        <button class="inventory__buttons-item" @click = "divideItem(selectedItem)">
                            <IconBtnAction icon="divide" />
                            {{ $t('inventory.buttons.split') }}
                        </button>
                        <button class="inventory__buttons-item" @click = "transferItem(selectedItem)">
                            <IconBtnAction icon="handOver" />
                            {{ $t('inventory.buttons.give') }}
                        </button>
                        <button @click="useItem(selectedItem)"
                                class="inventory__buttons-item inventory__buttons-item--clothes"
                                v-if="selectedItem.filterType === 'clothes'">
                            <IconBtnAction icon="clothes" />
                            {{ selectedItem.isEquipped ? $t('inventory.buttons.remove') : $t('inventory.buttons.apply') }}
                        </button>
                        <button  @click="useItem(selectedItem)" class="inventory__buttons-item inventory__buttons-item--use" v-if="selectedItem.filterType !== 'clothes'">
                            <IconBtnAction icon="use" />
                            {{ $t('inventory.buttons.use') }}
                        </button>
                    </div>
                </div>

                <div v-else class="inventory__information-empty">
                    <img src="./assets/images/enter-finger.svg" alt="">
                    <p>{{ $t('inventory.info') }}</p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import AppHeader from '@/components/AppHeader/AppHeader.vue';
import IconInventory from '@/components/popups/content/inventory/icons/IconInventory.vue';
import IconBtnAction from '@/components/popups/content/inventory/icons/IconBtnAction.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import IconNext from '@/components/icons/IconNext.vue';
import InventoryItem from '@/components/popups/components/inventoryItem/InventoryItem.vue';
import { mapGetters, mapActions } from 'vuex';
import BaseIcon from '@/components/ui/BaseIcon.vue';

export default {
    name: 'MyInventory',
    components: { BaseIcon, InventoryItem, IconNext, BaseButton, IconBtnAction, IconInventory, AppHeader },
    data () {
        return {
            countSlot: 30,
            selectedType: 'all',
            selectedItem: null,

            rarityMap: {
                legend: this.$t('inventory.rarity_map.legend'),
                ordinary: this.$t('inventory.rarity_map.ordinary'),
                unusual: this.$t('inventory.rarity_map.unusual'),
                rare: this.$t('inventory.rarity_map.rare'),
                epic: this.$t('inventory.rarity_map.epic')
            },

            tabDefinitions: [
                { type: 'all', text: this.$t('inventory.tabs.all') },
                { type: 'weapon', text: this.$t('inventory.tabs.weapon') },
                { type: 'clothes', text: this.$t('inventory.tabs.clothes') },
                { type: 'food', text: this.$t('inventory.tabs.food') },
                { type: 'other', text: this.$t('inventory.tabs.other') }
            ],

            inventoryItems: [// априори пустое
            ]
        };
    },
    methods: {
        ...mapActions(['updateInventoryItemsPool']),
        changeTab (tabType) {
            this.selectedType = tabType;
            this.selectedItem = null; // reset selected item
        },
        selectItem (item) {
            if (this.selectedType === 'all' && this.selectedItem && !item.id) {
                this.sendclient('changeSlot', JSON.stringify({ a: this.selectedItem.index, b: item.index }));
            } else if (this.selectedItem && item.id && this.selectedItem.id === item.id && this.selectedItem.additional === item.additional) {
                if (this.selectedItem === item) this.selectedItem = null;
                else this.sendclient('changeSlot', JSON.stringify({ a: this.selectedItem.index, b: item.index }));
            } else if (this.selectedType !== 'all' || (!this.selectedItem && (item && item.id)) || (this.selectedItem && (this.selectedItem.id !== item.id || this.selectedItem.additional !== item.additional))) {
                this.selectedItem = item;
            }
        },
        deleteItem (item) {
            this.sendclient('deleteItem', JSON.stringify({ a: item.index }));
        },
        useItem (item) {
            // item.isEquipped = !item.isEquipped;
            this.sendclient('useItem', JSON.stringify({ a: item.index }));
        },
        divideItem (item) {
            this.sendclient('divideItem', JSON.stringify({ a: item.index }));
        },
        transferItem (item) {
            this.sendclient('transferItem', JSON.stringify({ a: item.index }));
        },
        InitializeInv (itemList, activeSlots) {
            this.inventoryItems = new Array(this.countSlot);// [];
            for (let i = 0; i < itemList.length; i++) {
                if (itemList[i].itemID) {
                    this.inventoryItems[i] = Object.assign({}, this.inventoryItemsPool[itemList[i].itemID - 1]);
                    this.inventoryItems[i].count = itemList[i].count;
                    this.inventoryItems[i].index = i + 1;
                    this.inventoryItems[i].additional = itemList[i].additional;
                    this.inventoryItems[i].isEquipped = activeSlots && activeSlots.indexOf(i + 1) !== -1;
                } else this.inventoryItems[i] = { id: null, name: '', rarity: '', filterType: '', desc: '', img: '', count: 0, index: i + 1, isEquipped: false };
            }
            console.log(this.inventoryItems);
            /*
            window.modifyVueComponent({
                App: {
                    showPopup: true,
                    popupContent: 'MyInventory',
                    contentSubFolder: 'inventory'
                },
                MyInventory: {
                    InitializeInv: [
                        [{"count":89,"additional":0,"itemID":1},{"count":9,"additional":0,"itemID":2},{"count":0,"additional":0,"itemID":0},{"count":1,"additional":0,"itemID":2},{"count":1,"additional":0,"itemID":14},{"count":2,"additional":0,"itemID":1},{"count":1,"additional":0,"itemID":15},{"count":1,"additional":0,"itemID":12},{"count":0,"additional":0,"itemID":0},{"count":0,"additional":0,"itemID":0},{"count":1,"additional":0,"itemID":8},{"count":1,"additional":0,"itemID":10},{"count":1,"additional":0,"itemID":9},{"count":0,"additional":0,"itemID":0},{"count":0,"additional":0,"itemID":0},{"count":0,"additional":0,"itemID":0},{"count":1,"additional":0,"itemID":4},{"count":0,"additional":0,"itemID":0},{"count":100,"additional":0,"itemID":3},{"count":1,"additional":0,"itemID":13},{"count":0,"additional":0,"itemID":0},{"count":0,"additional":0,"itemID":0},{"count":0,"additional":0,"itemID":0},{"count":0,"additional":0,"itemID":0},{"count":0,"additional":0,"itemID":0},{"count":0,"additional":0,"itemID":0},{"count":0,"additional":0,"itemID":0},{"count":0,"additional":0,"itemID":0},{"count":0,"additional":0,"itemID":0},{"count":0,"additional":0,"itemID":0}],
                        [],
                    ]
                }
            });
            */
        },
        updateSlotInfo (number, itemInfo) {
            if (!itemInfo || itemInfo.itemID === 0) {
                this.inventoryItems[number - 1] = { id: null, name: '', rarity: '', filterType: '', desc: '', img: '', index: number };
            } else {
                this.inventoryItems[number - 1] = Object.assign({}, this.inventoryItemsPool[itemInfo.itemID - 1]);// { ...this.inventoryItemsPool[itemInfo.itemID - 1] };
                this.inventoryItems[number - 1].count = itemInfo.count;
                this.inventoryItems[number - 1].index = number;
                this.inventoryItems[number - 1].additional = itemInfo.additional;
            }
            // console.log(this.inventoryItems[number - 1]);
            this.selectedItem = null;

            this.Rerender();
        },
        updateActiveSlot (number, state) {
            if (this.selectedItem && this.selectedItem.index === number) {
                this.selectedItem = this.$set(this.inventoryItems, number - 1, { ...this.inventoryItems[number - 1], isEquipped: state });
            } else {
                this.$set(this.inventoryItems, number - 1, { ...this.inventoryItems[number - 1], isEquipped: state });
            }
            // this.Rerender();
            // console.log(this.inventoryItems);
        },
        Rerender () {
            // tried to fix active logo
            const lastItem = this.selectedItem;
            this.selectedItem = null;
            this.selectedItem = lastItem;

            const lastType = this.selectedType;
            this.selectedType = '';
            this.selectedType = lastType;
        },
        openCraft () {
            this.sendclient('openCraftFromInv');
        }
    },
    computed: {
        ...mapGetters('inventory', ['inventoryItemsPool']),
        computedInventoryItems () {
            // console.log('проход');
            // let items = Object.assign({}, this.inventoryItems);// [...this.inventoryItems];
            //
            // if (this.selectedType !== 'all') {
            //     items = items.filter(item => item.type === this.selectedType);
            // }
            //
            // const diff = this.countSlot - items.length;
            // for (let i = 0; i < diff; i++) {
            //     items.push({ id: null, name: '', rarity: '', type: '', desc: '', img: '', index: i + 1 });
            // }
            // return items;
            //
            // let filteredItems;
            // Object.assign({}, this.inventoryItems);// this.inventoryItems.filter(item => this.selectedType === 'all' || item.type === this.selectedType);
            // filteredItems.filter(item => this.selectedType === 'all' || item.type === this.selectedType);
            // console.log(this.inventoryItems.filter(item => this.selectedType === 'all' || item.type === this.selectedType));
            //
            // console.log(this.inventoryItems.filter(item => this.selectedType === 'all' || item.type === this.selectedType).);
            return this.inventoryItems.filter(item => this.selectedType === 'all' || item.filterType === this.selectedType);
        }
    }

};
</script>

<style lang="scss">
.inventory{
    display: flex;
    height: 100%;
    flex-direction: column;

    &__wrapper{
        padding: 0 128px;
        display: grid;
        grid-template-columns: 298px 1fr 615px;
        grid-gap: 0 80px;
        height: calc(100% - 5vw);
    }

    &__tabs{
        padding-top: 60px;
        padding-bottom: 60px;
        &-wrapper{
            display: grid;
            grid-gap: 24px;
        }

        &-buttons{
            margin-top: 128px;
            border-radius: 104px;
            background: rgba(226, 255, 190, 0.40);
            display: flex;
            padding: 0px 16px;
            justify-content: center;
            align-items: center;
            align-self: stretch;
            height: 100px;

            img{
                width: 52px;
                height: 52px;
            }
        }

        &-item{
            background: linear-gradient(224.44deg, rgba(241, 241, 241, 0.2) 0%, rgba(241, 241, 241, 0) 100%);
            border-radius: 4px;
            display: flex;
            align-items: center;
            padding: 0 2vw;
            height: 116px;

            font-weight: 700;
            font-size: 1vw;
            line-height: 120%;

            letter-spacing: 0.04em;
            text-transform: uppercase;
            position: relative;
            transition: .2s ease-out;

            color: #F1F1F1;

            *{
                transition: .2s ease-out;
            }

            svg{
                height: 2vw;
                width: auto;
                margin-right: 1vw;
                opacity: .5;
            }

            &--active{
                background: linear-gradient(224.44deg, rgba(241, 241, 241, 0.5) 0%, rgba(241, 241, 241, 0) 100%);
                height: 142px;

                &::before{
                    position: absolute;
                    content: "";
                    background: url(./assets/images/tab.svg) no-repeat center/cover;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }

                svg{
                    opacity: 1;
                }
            }

        }
    }

    &__information{
        height: 100%;
        padding-top: 60px;
        padding-bottom: 60px;
        &-item{
            padding-top: 1vw;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        &-put-on{
            padding: 13px;
            width: 1.7vw;
            height: 1.7vw;
            background: #27AE60;
            margin-right: .5vw;
            display: flex;
            align-items: center;
            justify-content: center;
            svg, .base-icon {
                width: 28px;
                height: 28px;
            }
        }

        &-labels{
            display: flex;
        }

        &-label{
            font-weight: 700;
            font-size: .9vw;
            padding: 0.4vw 0.7vw;
            line-height: 100%;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: var(--white) ;
            width: max-content;

            &.legend{
                background: linear-gradient(211.69deg, #FFCD4C 33.85%, #FFA800 100%);
            }
            &.epic{
                background: linear-gradient(211.69deg, #BE6AFF 20.31%, #7A28FF 100%);
            }
            &.rare{
                background: linear-gradient(211.69deg, #48FDC7 0%, #009D6E 100%);
            }
            &.ordinary{
                background: linear-gradient(212deg, #96ABB5 43.23%, #72848B 100%);
            }
            &.unusual{
                background: linear-gradient(211.69deg, #5FCFFF 35.94%, #2780A6 100%);
            }
        }

        &-title{
            font-weight: 400;
            font-size: 3vw;
            line-height: 100%;
            margin-bottom: 1vw;

            display: flex;
            align-items: flex-end;

            color: #FFFFFF;
        }
        &-desc{
            margin-top: 1vw;
            font-weight: 500;
            font-size: 1vw;
            line-height: 140%;
            color: var(--white);

            opacity: 0.6;
        }
        &-empty{
            display: flex;
            height: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            img{
                height: 8vw;
                margin-bottom: 1vw;
            }
            p{
                font-weight: 500;
                font-size: 1.5vw;
                line-height: 140%;
                display: flex;
                align-items: flex-end;
                text-align: center;

                color: var(--white);

                opacity: 0.8;

            }
        }
    }

    &__buttons{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: .5vw;
        &-item{
            background: linear-gradient(119.36deg, rgba(241, 241, 241, 0.19) 0%, rgba(241, 241, 241, 0) 100%);
            border-radius: .1vw;
            height: 6.5vw;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            font-weight: 700;
            font-size: 1vw;
            line-height: 120%;

            text-align: center;
            letter-spacing: 0.04em;
            text-transform: uppercase;

            color: #F1F1F1;

            &--use{
                background: #FFED4C;
                color: #141A1E;
                box-shadow: inset 0px -26px 80px rgba(241, 241, 241, 0.4);
            }
            &--clothes{
                background: #F1F1F1;
                color: #141A1E;
                box-shadow: inset 0px -26px 80px rgba(241, 241, 241, 0.4);
            }

            svg{
                height: 2vw;
                width: auto;
                margin-bottom: .5vw;
            }
        }
    }
}
</style>
