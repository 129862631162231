<template>
    <svg class="icon" width="254" height="254" viewBox="0 0 254 254" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M227 86.5792C221.719 73.8161 213.892 61.8585 203.517 51.4835C193.142 41.1085 181.184 33.2807 168.421 28L167.002 31.4282C179.323 36.5257 190.869 44.0828 200.893 54.1069C210.917 64.131 218.474 75.6772 223.572 87.9976L227 86.5792ZM227 169.421L223.572 168.002C218.474 180.323 210.917 191.869 200.893 201.893C190.869 211.917 179.323 219.474 167.002 224.572L168.421 228C181.184 222.719 193.142 214.892 203.517 204.517C213.891 194.142 221.719 182.184 227 169.421ZM85.5792 228L86.9976 224.572C74.6772 219.474 63.131 211.917 53.1069 201.893C43.0828 191.869 35.5257 180.323 30.4282 168.002L27 169.421C32.2807 182.184 40.1085 194.142 50.4835 204.517C60.8585 214.891 72.8161 222.719 85.5792 228ZM27 86.5792C32.2807 73.8161 40.1085 61.8585 50.4835 51.4835C60.8585 41.1085 72.8161 33.2807 85.5792 28L86.9976 31.4282C74.6772 36.5257 63.131 44.0828 53.1069 54.1069C43.0828 64.131 35.5257 75.6772 30.4282 87.9976L27 86.5792Z" fill="#F1F1F1"/>
        <g v-if="isReloading">
            <circle cx="128" cy="127" r="40" fill="#00000033">
            </circle>
            <circle
                :style="{animationDuration: reloadingTime + 'ms'}"
                class="reload-circle"
                stroke="#fff" stroke-width="40" cx="128" cy="128" r="20" stroke-dasharray="125.663706144" stroke-dashoffset="125.663706144">
            </circle>
        </g>
        <g v-else>
            <rect x="125" y="114" width="3" height="27" fill="#F1F1F1"/>
            <rect x="150" y="126" width="3" height="46" transform="rotate(90 150 126)" fill="#F1F1F1"/>
        </g>
        <g ref="magazine" v-show="!isReloading" transform="translate(126,126)">
            <path d="M0.608935 88.7087C0.405986 88.7085 0.203007 88.7075 0 88.7059L0.0315471 84.7061C0.257034 84.7079 0.482742 84.7087 0.708671 84.7087C0.934623 84.7087 1.16035 84.7079 1.38586 84.7061L1.41741 88.7059C1.21568 88.7075 1.01392 88.7084 0.812121 88.7087C0.777642 88.7087 0.743159 88.7087 0.708671 88.7087C0.675421 88.7087 0.642176 88.7087 0.608935 88.7087Z" style="fill: white; opacity: 1;"/>
            <path d="M4.23885 88.6379C3.76979 88.6567 3.30045 88.6718 2.83088 88.6832L2.73625 84.6848C3.18549 84.6741 3.63381 84.66 4.08121 84.6423L4.23885 88.6379Z" style="fill: white; opacity: 1;"/>
            <path d="M7.05675 88.4795C6.58667 88.5135 6.11622 88.5437 5.64544 88.5702L5.42471 84.5787C5.87499 84.5538 6.32429 84.5253 6.7726 84.4934L7.05675 88.4795Z" style="fill: white; opacity: 1;"/>
            <path d="M9.86218 88.2314C9.39586 88.2802 8.92908 88.3252 8.46189 88.3665L8.11449 84.387C8.56125 84.348 9.00698 84.3055 9.45165 84.2595L9.86218 88.2314Z" style="fill: white; opacity: 1;"/>
            <path d="M12.6665 87.8925C12.2008 87.9564 11.7345 88.0165 11.2677 88.073L10.7938 84.1099C11.2401 84.0566 11.6853 83.9997 12.1294 83.9394L12.6665 87.8925Z" style="fill: white; opacity: 1;"/>
            <path d="M15.4541 87.4646C14.9895 87.5435 14.5242 87.6188 14.0582 87.6903L13.4582 83.748C13.9036 83.6802 14.3479 83.6089 14.7909 83.5341L15.4541 87.4646Z" style="fill: white; opacity: 1;"/>
            <path d="M18.2242 86.948C17.7645 87.0413 17.3041 87.131 16.843 87.217L16.117 83.2994C16.5577 83.2177 16.9973 83.1326 17.4356 83.044L18.2242 86.948Z" style="fill: white; opacity: 1;"/>
            <path d="M20.9819 86.3417C20.5242 86.45 20.0656 86.5547 19.6063 86.6557L18.7551 82.7664C19.1941 82.6703 19.6319 82.5708 20.0683 82.4678L20.9819 86.3417Z" style="fill: white; opacity: 1;"/>
            <path d="M23.7148 85.6482C23.2605 85.7713 22.8053 85.8907 22.3493 86.0064L21.3736 82.1489C21.8095 82.0386 22.244 81.925 22.6771 81.8079L23.7148 85.6482Z" style="fill: white; opacity: 1;"/>
            <path d="M26.4239 84.8677C25.9749 85.0049 25.5248 85.1385 25.0738 85.2684L23.9744 81.4461C24.4054 81.3222 24.835 81.1948 25.2632 81.0642L26.4239 84.8677Z" style="fill: white; opacity: 1;"/>
            <path d="M29.1093 83.9998C28.6639 84.1517 28.2175 84.2999 27.77 84.4445L26.5481 80.6607C26.9757 80.5226 27.4018 80.3812 27.8264 80.2364L29.1093 83.9998Z" style="fill: white; opacity: 1;"/>
            <path d="M31.7628 83.0473C31.3227 83.2133 30.8814 83.3757 30.4389 83.5345L29.0955 79.7923C29.5183 79.6406 29.9394 79.4855 30.359 79.3272L31.7628 83.0473Z" style="fill: white; opacity: 1;"/>
            <path d="M34.3852 82.01C33.9714 82.1814 33.5561 82.3497 33.1394 82.5149C33.1193 82.5229 33.0992 82.5308 33.0791 82.5388L31.6153 78.8415C32.0325 78.6764 32.448 78.5079 32.8618 78.3363L34.3852 82.01Z" style="fill: white; opacity: 1;"/>
            <path d="M36.9742 80.8886C36.5458 81.0824 36.1157 81.2728 35.6839 81.4598L34.1012 77.8106C34.5134 77.6319 34.9238 77.4499 35.3325 77.2647L36.9742 80.8886Z" style="fill: white; opacity: 1;"/>
            <path d="M39.5243 79.6856C39.1025 79.8929 38.6789 80.0969 38.2537 80.2975L36.5535 76.6997C36.9594 76.5079 37.3636 76.3129 37.766 76.1148L39.5243 79.6856Z" style="fill: white; opacity: 1;"/>
            <path d="M42.0354 78.4011C41.6207 78.6217 41.2041 78.839 40.7857 79.0531L38.9696 75.51C39.3689 75.3053 39.7664 75.0975 40.1621 74.8866L42.0354 78.4011Z" style="fill: white; opacity: 1;"/>
            <path d="M44.5047 77.0364C44.0968 77.2705 43.6869 77.5013 43.2752 77.7288L41.345 74.2438C41.738 74.0262 42.129 73.8055 42.5181 73.5817L44.5047 77.0364Z" style="fill: white; opacity: 1;"/>
            <path d="M46.9279 75.5939C46.5279 75.8408 46.1258 76.0845 45.7218 76.325L43.6795 72.9014C44.0651 72.6714 44.4486 72.4384 44.8302 72.2024L46.9279 75.5939Z" style="fill: white; opacity: 1;"/>
            <path d="M49.3044 74.074C48.9125 74.3335 48.5186 74.59 48.1226 74.8432L45.9701 71.4846C46.348 71.2424 46.7239 70.9973 47.0977 70.7492L49.3044 74.074Z" style="fill: white; opacity: 1;"/>
            <path d="M51.6311 72.4785C51.2475 72.7507 50.8618 73.0198 50.4739 73.2857L48.2136 69.9956C48.5838 69.7413 48.9519 69.4841 49.3178 69.224L51.6311 72.4785Z" style="fill: white; opacity: 1;"/>
            <path d="M53.9051 70.8097C53.5305 71.0939 53.1537 71.3752 52.7747 71.6533L50.4089 68.4352C50.7707 68.1692 51.1303 67.9005 51.4876 67.6289L53.9051 70.8097Z" style="fill: white; opacity: 1;"/>
            <path d="M56.1248 69.0685C55.7595 69.3646 55.3919 69.6577 55.0221 69.9478L52.5535 66.8052C52.9066 66.5279 53.2573 66.2478 53.6058 65.965L56.1248 69.0685Z" style="fill: white; opacity: 1;"/>
            <path d="M58.2876 67.257C57.9319 67.5647 57.5738 67.8696 57.2134 68.1715L54.6447 65.1079C54.9888 64.8194 55.3306 64.5282 55.67 64.2342L58.2876 67.257Z" style="fill: white; opacity: 1;"/>
            <path d="M60.3911 65.3772C60.0454 65.6962 59.6973 66.0123 59.3468 66.3255L56.681 63.3445C57.0157 63.0452 57.348 62.7433 57.6778 62.4387L60.3911 65.3772Z" style="fill: white; opacity: 1;"/>
            <path d="M62.4334 63.4308C62.0981 63.7608 61.7603 64.0879 61.4201 64.4122L58.6601 61.5171C58.9851 61.2073 59.3076 60.8949 59.6276 60.58L62.4334 63.4308Z" style="fill: white; opacity: 1;"/>
            <path d="M64.4122 61.4201C64.0879 61.7603 63.7608 62.0981 63.4308 62.4334L60.58 59.6276C60.8949 59.3076 61.2073 58.9851 61.5171 58.6601L64.4122 61.4201Z" style="fill: white; opacity: 1;"/>
            <path d="M66.3255 59.3469C66.0123 59.6974 65.6962 60.0454 65.3772 60.3911L62.4387 57.6778C62.7433 57.348 63.0452 57.0157 63.3445 56.681L66.3255 59.3469Z" style="fill: white; opacity: 1;"/>
            <path d="M68.1715 57.2134C67.8696 57.5738 67.5647 57.9319 67.2569 58.2876L64.2342 55.67C64.5281 55.3306 64.8194 54.9888 65.1079 54.6447L68.1715 57.2134Z" style="fill: white; opacity: 1;"/>
            <path d="M69.9478 55.0221C69.6577 55.392 69.3646 55.7595 69.0685 56.1248L65.9649 53.6058C66.2478 53.2574 66.5279 52.9066 66.8052 52.5535L69.9478 55.0221Z" style="fill: white; opacity: 1;"/>
            <path d="M71.6533 52.7747C71.3751 53.1537 71.0939 53.5305 70.8097 53.9051L67.6289 51.4876C67.9004 51.1303 68.1692 50.7707 68.4351 50.409L71.6533 52.7747Z" style="fill: white; opacity: 1;"/>
            <path d="M73.2857 50.4739C73.0197 50.8618 72.7507 51.2476 72.4785 51.6311L69.2239 49.3178C69.484 48.9519 69.7413 48.5838 69.9956 48.2136L73.2857 50.4739Z" style="fill: white; opacity: 1;"/>
            <path d="M74.8432 48.1226C74.5899 48.5186 74.3335 48.9125 74.074 49.3044L70.7492 47.0977C70.9972 46.7239 71.2424 46.3481 71.4846 45.9701L74.8432 48.1226Z" style="fill: white; opacity: 1;"/>
            <path d="M76.3249 45.7218C76.0844 46.1258 75.8407 46.5279 75.5938 46.9279L72.2024 44.8302C72.4384 44.4487 72.6714 44.0651 72.9014 43.6795L76.3249 45.7218Z" style="fill: white; opacity: 1;"/>
            <path d="M77.7288 43.2752C77.5012 43.6869 77.2704 44.0968 77.0364 44.5047L73.5817 42.5181C73.8054 42.129 74.0261 41.738 74.2438 41.345L77.7288 43.2752Z" style="fill: white; opacity: 1;"/>
            <path d="M79.053 40.7857C78.839 41.2041 78.6217 41.6207 78.401 42.0354L74.8866 40.1621C75.0975 39.7665 75.3053 39.3689 75.51 38.9696L79.053 40.7857Z" style="fill: white; opacity: 1;"/>
            <path d="M80.2975 38.2537C80.0969 38.679 79.8929 39.1025 79.6856 39.5243L76.1147 37.766C76.3129 37.3636 76.5079 36.9594 76.6997 36.5535L80.2975 38.2537Z" style="fill: white; opacity: 1;"/>
            <path d="M81.4598 35.6839C81.2728 36.1157 81.0823 36.5458 80.8886 36.9742L77.2647 35.3326C77.4499 34.9238 77.6318 34.5134 77.8106 34.1012L81.4598 35.6839Z" style="fill: white; opacity: 1;"/>
            <path d="M82.5387 33.0791C82.4093 33.4062 82.2779 33.7327 82.1445 34.0584C82.0999 34.1674 82.055 34.2764 82.0099 34.3852L78.3363 32.8618C78.5079 32.448 78.6763 32.0325 78.8415 31.6153L82.5387 33.0791Z" style="fill: white; opacity: 1;"/>
            <path d="M83.5345 30.439C83.3757 30.8814 83.2133 31.3227 83.0473 31.7628L79.3271 30.359C79.4855 29.9394 79.6405 29.5183 79.7923 29.0955L83.5345 30.439Z" style="fill: white; opacity: 1;"/>
            <path d="M84.4445 27.77C84.2999 28.2175 84.1516 28.6639 83.9998 29.1093L80.2364 27.8264C80.3811 27.4018 80.5225 26.9757 80.6606 26.5481L84.4445 27.77Z" style="fill: white; opacity: 1;"/>
            <path d="M85.2684 25.0738C85.1384 25.5248 85.0048 25.9749 84.8676 26.4239L81.0641 25.2632C81.1948 24.8351 81.3221 24.4055 81.4461 23.9744L85.2684 25.0738Z" style="fill: white; opacity: 1;" />
            <path d="M86.0063 22.3493C85.8906 22.8053 85.7712 23.2605 85.6482 23.7148L81.8079 22.6771C81.9249 22.244 82.0386 21.8095 82.1488 21.3736L86.0063 22.3493Z" style="fill: white; opacity: 1;" />
            <path d="M86.6556 19.6063C86.5546 20.0657 86.45 20.5242 86.3416 20.9819L82.4678 20.0683C82.5707 19.6319 82.6702 19.1941 82.7663 18.7551L86.6556 19.6063Z" style="fill: white; opacity: 1;"/>
            <path d="M87.217 16.843C87.131 17.3041 87.0413 17.7645 86.9479 18.2242L83.044 17.4356C83.1325 16.9973 83.2176 16.5577 83.2993 16.1169L87.217 16.843Z" style="fill: white; opacity: 1;"/>
            <path d="M87.6902 14.0582C87.6187 14.5242 87.5435 14.9895 87.4645 15.4541L83.5341 14.7909C83.6088 14.3479 83.6801 13.9036 83.7479 13.4582L87.6902 14.0582Z" style="fill: white; opacity: 1;"/>
            <path d="M88.0729 11.2677C88.0165 11.7345 87.9563 12.2008 87.8925 12.6665L83.9393 12.1294C83.9996 11.6853 84.0565 11.2401 84.1099 10.7938L88.0729 11.2677Z" style="fill: white; opacity: 1;"/>
            <path d="M88.3665 8.4619C88.3251 8.9291 88.2801 9.39588 88.2313 9.86219L84.2595 9.45167C84.3054 9.00698 84.3479 8.56125 84.3869 8.11449L88.3665 8.4619Z" style="fill: white; opacity: 1;"/>
            <path d="M88.5701 5.64544C88.5436 6.11623 88.5134 6.58668 88.4794 7.05675L84.4933 6.7726C84.5253 6.32429 84.5537 5.87499 84.5786 5.42471L88.5701 5.64544Z" style="fill: white; opacity: 1;"/>
            <path d="M88.6831 2.83087C88.6718 3.30046 88.6567 3.76979 88.6378 4.23884L84.6423 4.08121C84.6599 3.63381 84.6741 3.18549 84.6847 2.73625L88.6831 2.83087Z" style="fill: white; opacity: 1;"/>
            <path d="M88.7086 0.822296C88.7083 1.0207 88.7074 1.21907 88.7058 1.41741L84.706 1.38586C84.7078 1.16038 84.7087 0.934669 84.7087 0.70874C84.7087 0.482788 84.7078 0.257057 84.706 0.0315471L88.7058 0C88.7075 0.202518 88.7084 0.405008 88.7086 0.607464C88.7087 0.641219 88.7087 0.674977 88.7087 0.70874C88.7087 0.746597 88.7086 0.784449 88.7086 0.822296Z" style="fill: white; opacity: 1;"/>
        </g>
        <path transform="translate(95, 95)" v-if="isHit" fill-rule="evenodd" clip-rule="evenodd" d="M64 3.52458L57.3723 9.43305C56.9354 8.94297 56.4813 8.46178 56.0097 7.99025C55.5382 7.51873 55.057 7.06458 54.567 6.62768L60.4754 0C61.092 0.549708 61.6966 1.12033 62.2881 1.71188C62.8797 2.30342 63.4503 2.90796 64 3.52458ZM3.52458 2.53638e-06L9.43305 6.62767C8.94296 7.06457 8.46178 7.51873 7.99025 7.99025C7.51872 8.46178 7.06457 8.94296 6.62767 9.43305L2.5052e-06 3.52458C0.549709 2.90796 1.12033 2.30342 1.71188 1.71188C2.30342 1.12033 2.90796 0.549709 3.52458 2.53638e-06ZM0 60.4754C0.549707 61.092 1.12033 61.6966 1.71188 62.2881C2.30342 62.8797 2.90796 63.4503 3.52458 64L9.43304 57.3723C8.94296 56.9354 8.46178 56.4813 7.99025 56.0097C7.51872 55.5382 7.06457 55.057 6.62767 54.567L0 60.4754ZM60.4754 64L54.5669 57.3723C55.057 56.9354 55.5382 56.4813 56.0097 56.0097C56.4813 55.5382 56.9354 55.057 57.3723 54.567L64 60.4754C63.4503 61.092 62.8797 61.6966 62.2881 62.2881C61.6966 62.8797 61.092 63.4503 60.4754 64Z" fill="#F1F1F1"/>
    </svg>
</template>
<script>
export default {
    name: 'IconCrosshair',
    props: {
        isReloading: Boolean,
        reloadingTime: Number,
        magazineCapacity: Number,
        bullets: Number,
        isHit: Boolean
    },
    mounted () {
        this.fillBullets();
    },
    computed: {
        icons () {
            return [...this.$refs.magazine.childNodes];
        }
    },
    watch: {
        magazineCapacity: function () {
            this.fillBullets();
        },
        bullets: function () {
            this.fillBullets();
        }
    },
    methods: {
        fillBullets () {
            const bulletPercent = Math.floor((this.bullets / this.magazineCapacity * 100) / 2);
            this.icons.forEach((el, i) => {
                if (i >= bulletPercent) {
                    el.style.fill = 'rgba(0, 0, 0, 0.5)';
                } else {
                    el.style.fill = 'white';
                }
            });
        }
    }
};
</script>
<style>
.reload-circle {
    stroke-dasharray: 125.663706144;
    stroke-dashoffset: 125.663706144;
    transform: rotate(-90deg);
    transform-origin: center;
    animation: reload-animation 1s linear infinite;
}

.hit-circle {
    transform: rotate(309deg);
    transform-origin: center;
}

@keyframes reload-animation {
    0% {
        stroke-dashoffset: 125.663706144;
    }
    100% {
        stroke-dashoffset: 0;
    }
}
</style>
