var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn",class:{
      'btn--green': _vm.green,
      'btn--border': _vm.border,
      'btn--yellow': _vm.yellow,
      'btn--white': _vm.white,
      'btn--center': _vm.center,
      'btn--full': _vm.full,
      'btn--glass': _vm.glass
    },attrs:{"type":_vm.type,"disabled":_vm.disabled},on:{"click":function($event){return _vm.emit('click', $event)},"mouseover":function($event){return _vm.emit('mouseover', $event)},"mouseleave":function($event){return _vm.emit('mouseleave', $event)}}},[_vm._t("default"),(_vm.glass)?_c('svg',{staticClass:"btn-bg",attrs:{"preserveAspectRatio":"xMinYMax meet","width":"100%","height":"100%","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"x":".08vw","y":".08vw","width":"calc(100% - .16vw)","height":"calc(100% - .16vw)","rx":".05vw","stroke":"url(#paint0_linear_478_4720)","stroke-opacity":"0.8","stroke-width":".16vw"}}),_c('defs',[_c('linearGradient',{attrs:{"id":"paint0_linear_478_4720","x1":"0","y1":"0","x2":"221.842","y2":"64.075","gradientUnits":"userSpaceOnUse"}},[_c('stop',{attrs:{"stop-color":"#F1F1F1"}}),_c('stop',{attrs:{"offset":"0.489583","stop-color":"#F1F1F1","stop-opacity":"0.28125"}}),_c('stop',{attrs:{"offset":"1","stop-color":"#F1F1F1"}})],1)],1)]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }