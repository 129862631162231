<template>
    <div class="dialog dialog--center">
        <div class="dialog__wrapper">
            <div class="dialog__title-wrapper">
                <div class="dialog__title">
                    {{title}}
                </div>
            </div>

            <BaseCounter
                @input="updateValue"
                :step="step"
                :startValue="startValue"
                :maxValue="maxValue"
            />

            <BaseButton
                center
                yellow
                @click="handleClick"
            >
                {{ $t('dialog_counter.next') }}
            </BaseButton>
        </div>
    </div>
</template>
<script>
import BaseButton from '@/components/ui/BaseButton.vue';
import BaseCounter from '@/components/ui/BaseCounter.vue';

export default {
    name: 'DialogCounter',
    components: { BaseCounter, BaseButton },
    data () {
        return {
            title: 'Title',
            startValue: 1,
            maxValue: 99,
            step: 1,
            value: this.startValue
        };
    },
    created () {
        this.value = this.startValue;
    },
    methods: {
        updateValue (value) {
            this.value = value;
            console.log('Counter value:', value);
        },
        handleClick () {
            console.log('Counter value on save:', this.value);
            // console.log(`${BaseCounter.counterValue}`);
            // console.log(`${this.value}`);
            this.sendclient('DialogResponse', JSON.stringify({ a: 1, b: this.value }));
        },
        ShowDialogCounter (title, start, step, max) {
            this.title = title;
            this.startValue = start;
            this.value = start;
            this.step = step;
            this.maxValue = max;
        }
    }

};
</script>
