<template>
    <div class="input-wrapper">
        <template v-if="password">
            <p class="input-label" v-if="label">{{ label }}</p>
            <div class="input-container">
                <input
                    ref="inputRef"
                    :value="value"
                    class="input"
                    :class="{
                      'input--mini': mini,
                      'input--form': form,
                      'input--focused': focused
                    }"
                    :type="showPassword ? 'text' : 'password'"
                    :placeholder="placeholder"
                    :name="name"
                    :autocomplete="autocomplete"
                    @focus="onFocus"
                    @blur="onBlur"
                    @input="onInput"
                    @keyup.enter="onEnterPressed"
                />
                <div class="input__show-password" @click="togglePassword">
                    <IconEye :icon="showPassword ? 'close' : 'open'"/>
                </div>
            </div>
        </template>
        <template v-else>
            <p class="input-label" v-if="label">{{ label }}</p>
            <input
                ref="inputRef"
                v-if="dollar"
                v-mask="'$ ' + '############'"
                :value="value"
                class="input"
                :class="{
                  'input--mini': mini,
                  'input--form': form,
                  'input--focused': focused,
                }"
                :type="type"
                :placeholder="placeholder"
                :name="name"
                :autocomplete="autocomplete"
                :maxlength="maxLength"
                :readonly="readonly"
                @focus="onFocus"
                @blur="onBlur"
                @input="onInput"
                @keyup.enter="onEnterPressed"
                :inputmode="$props.inputMode"
            />
            <input
                ref="inputRef"
                v-else
                :value="value"
                class="input"
                :class="{
                  'input--mini': mini,
                  'input--form': form,
                  'input--focused': focused,
                }"
                :type="type"
                :placeholder="placeholder"
                :name="name"
                :autocomplete="autocomplete"
                :maxlength="maxLength"
                :readonly="readonly"
                @focus="onFocus"
                @blur="onBlur"
                @input="onInput"
                @keyup.enter="onEnterPressed"
                :inputmode="$props.inputMode"
            />
        </template>
        <slot></slot>
    </div>
</template>
<script>
import IconEye from '@/components/icons/IconEye.vue';

export default {
    name: 'BaseInput',
    components: { IconEye },
    data () {
        return {
            showPassword: false
        };
    },
    props: {
        value: {
            type: String,
            default: ''
        },
        password: {
            type: Boolean,
            default: false
        },
        mini: {
            type: Boolean,
            default: false
        },
        focused: {
            type: Boolean,
            default: false
        },
        form: {
            type: Boolean,
            default: false
        },
        autofocus: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'text'
        },
        placeholder: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        autocomplete: {
            type: String,
            default: 'off'
        },
        maxLength: {
            type: Number,
            default: null
        },
        formatNumbers: {
            type: Boolean,
            default: false
        },
        dollar: Boolean,
        inputMode: {
            type: String,
            default: 'text'
        }
    },
    mounted () {
        this.$nextTick(() => {
            if (this.autofocus) {
                this.$refs.inputRef.focus();
            }
        });
    },

    methods: {
        onInput (event) {
            let value = event.target.value;
            if (this.dollar) {
                const numericValue = value.replace(/[^0-9]/g, '');
                if (this.formatNumbers) {
                    value = '$ ' + this.$formatNum(numericValue);
                } else {
                    value = '$ ' + numericValue;
                }
                // if (!value.startsWith('$ ')) {
                //     value = '$ ' + this.$formatNum(numericValue);
                // } else {
                //     value = '$ ' + this.$formatNum(numericValue);
                // }
                this.$emit('input', numericValue);
                this.$refs.inputRef.value = value;
                if (this.$refs.inputRef.selectionStart < 2) {
                    this.$refs.inputRef.selectionStart = 2;
                    this.$refs.inputRef.selectionEnd = 2;
                }
            } else {
                this.$emit('input', value);
            }
        },
        onFocus (event) {
            this.$emit('focus', event);
        },
        onBlur (event) {
            this.$emit('blur', event);
        },
        onEnterPressed () {
            this.$emit('enter-pressed');
        },
        togglePassword (event) {
            event.preventDefault();
            this.showPassword = !this.showPassword;
            this.$nextTick(() => {
                this.$refs.inputRef.focus();
            });
        }
    }
};
</script>

<style lang="scss">
.input {
    height: 9vw;
    font-size: 4vw;
    max-width: 65vw;
    width: 100%;
    border-bottom: 4px solid #F1F1F1;
    background: linear-gradient(180deg, rgba(241, 241, 241, 0) 40.63%, rgba(241, 241, 241, 0.16) 100%);
    text-align: center;
    color: #F1F1F1;
    opacity: .4;
    transition: .2s;
    // возможный фикс закругления всех инпутов
    border-radius: 0;

    &--mini{
        max-width: 33vw;
    }
    &--focused{
        opacity: 1;
    }

    &--form{
        height: 8vw;
        text-align: left;
        padding: 2vw;
        font-size: 2.5vw;
    }

    &__show-password{
        position: absolute;
        top: 2.5vw;
        right: 2vw;
        width: 3vw;
        height: 3vw;
        z-index: 1;

        background: rgba(241, 241, 241, 0.1);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg{
            height: 2vw;
            width: 100%;
        }
    }

    &:focus{
        opacity: 1;
    }

    &::placeholder{
        color: #F1F1F1;
    }

    &-container{
        position: relative;
    }

    &-label{
        font-weight: 400;
        font-size: 1.4vw;
        line-height: 120%;

        display: flex;
        align-items: flex-end;
        letter-spacing: 0.12em;
        text-transform: uppercase;

        color: #F1F1F1;

        opacity: 0.6;

    }

}

</style>
