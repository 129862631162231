<template>
    <div class="counter">
        <div class="counter__minus" @click="decrement">
            <IconMinus />
        </div>
        <input
            class="counter__input"
            :value="counterValue"
            @keydown.prevent
            @input="validateInput"
        />
        <div class="counter__plus" @click="increment">
            <IconPlus />
        </div>
    </div>
</template>

<script>
import IconMinus from '@/components/icons/IconMinus.vue';
import IconPlus from '@/components/icons/IconPlus.vue';

export default {
    name: 'BaseCounter',
    components: { IconPlus, IconMinus },
    props: {
        step: {
            type: Number,
            default: 1
        },
        startValue: {
            type: Number,
            default: 1
        },
        maxValue: {
            type: Number
        }
    },
    data () {
        return {
            counterValue: this.startValue
        };
    },
    methods: {
        increment () {
            if (this.maxValue) {
                if (this.maxValue > this.counterValue) {
                    this.counterValue += this.step;
                    this.emitValue();
                }
            } else {
                this.counterValue += this.step;
                this.emitValue();
            }
        },
        decrement () {
            if (this.counterValue - this.step >= this.startValue) {
                this.counterValue -= this.step;
            } else {
                this.counterValue = this.startValue;
            }
            this.emitValue();
        },
        validateInput (event) {
            const value = event.target.value;
            const numberValue = parseInt(value, 10);

            if ((isNaN(numberValue) || numberValue < this.startValue)) {
                this.counterValue = this.startValue;
            } else {
                this.counterValue = numberValue;
            }
            this.emitValue();
        },
        emitValue () {
            this.$emit('input', this.counterValue);
        }
    },
    watch: {
        startValue (newValue) {
            this.counterValue = newValue;
        }
    }
};
</script>

<style lang="scss">
.counter{
    display: flex;
    margin: 0 auto;
    justify-content: center;
    &__input{
        background: url(@/assets/images/CounterInput.svg) no-repeat center/contain;
        border: none;
        height: 8vw;
        text-align: center;
        width: 20vw;

        font-weight: 500;
        font-size: 3.5vw;
        line-height: 110%;

        color: #F1F1F1;
    }

    &__minus{
        background: url(@/assets/images/CounterMinus.svg) no-repeat center/contain;
        height: 8vw;
        display: flex;
        width: 10vw;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        svg{
            height: 4vw;
        }
    }

    &__plus{
        background: url(@/assets/images/CounterPlus.svg) no-repeat center/contain;
        height: 8vw;
        display: flex;
        width: 10vw;
        align-items: center;
        justify-content: center;
        margin-left: 20px;
        svg{
            height: 4vw;
        }
    }
}
</style>
