import Vue from 'vue';

Vue.filter('formatThousands', function (value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
});

Vue.prototype.$formatNum = function (value) {
    if (typeof value === 'string') {
        value = Number(value);
    }
    if (typeof value === 'number') {
        return this.$options.filters.formatThousands(value);
    }
    return value;
};

Vue.prototype.$imageSrc = function (img, folder) {
    if (typeof img === 'string' && typeof folder === 'string') {
        return require(`@/assets/images/${folder}/${img}`);
    }
    return undefined;
};
