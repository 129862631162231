<template>
    <div class="phone" ref="phone">
        <div class="phone__heading">
            <div class="phone__heading-points">
                <IconDonate />
                <div class="phone__heading-points-text">{{ $formatNum(donate) }}</div>
            </div>
            <div class="phone__heading-money">
                <span class="phone__heading-money-text phone__heading-money-text--gold">$</span>
                <span class="phone__heading-money-text">{{ $formatNum(money) }}</span>
            </div>
            <button class="phone__heading-button" @click="getShopButton"></button>
        </div>
        <div class="phone__grid">
            <div class="phone__grid-item" v-for="(item, index) in items" :key="index" @click="handleItemClick(item)">
                <div class="phone__grid-item-title" :class="`phone__grid-item-title--${item.color}`">{{ item.title }}</div>
                <img :src="imageSource(item.image, 'hud/phone')" alt="" class="phone__grid-item-image">
                    <IconNew className="phone__grid-item-label" v-if="item.label" />
            </div>
        </div>
        <div class="phone__bottom" @click="getShopButton"
        style="background: linear-gradient(180deg, rgba(32, 36, 39, 0.078) 0%, rgba(32, 36, 39, 0.6) 100%)">
            <img :src="imageSource(ads.img, 'hud/phone')" alt="">
            <div class="phone__bottom-info">
                <div class="phone__bottom-text">{{ ads.title }}</div>
                <div class="phone__bottom-text phone__bottom-text--gold">{{ ads.description }}</div>
            </div>
            <BaseButton class="phone__bottom-btn" yellow>
                <IconBackArrow />
            </BaseButton>
        </div>
    </div>
</template>

<script>
import IconNew from '@/components/icons/IconNew.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import IconBackArrow from '@/components/icons/IconBackArrow.vue';
import IconDonate from '@/components/icons/IconDonate.vue';

export default {
    name: 'VMobile',
    components: { IconDonate, IconBackArrow, BaseButton, IconNew },
    data () {
        return {
            touchStartPos: -1,
            items: [
                { title: this.$t('phone.items.shop'), eventName: 'openShop', color: 'red', image: 'item-1.png' }, // label: true
                { title: this.$t('phone.items.family'), eventName: 'Family', color: 'green', image: 'item-2.png' },
                { title: this.$t('phone.items.organization'), eventName: 'Member', color: 'blue', image: 'item-3.png' },
                { title: this.$t('phone.items.works'), eventName: 'OpenWorks', color: 'dark-purple', image: 'item-9.png' },
                { title: this.$t('phone.items.property'), eventName: 'MyProperty', color: 'orange', image: 'item-5.png' },
                { title: this.$t('phone.items.awards'), eventName: 'Awards', color: 'purple', image: 'item-6.png' },
                { title: this.$t('phone.items.auction'), eventName: 'ClientOpenAuction', color: 'cyan', image: 'item-7.png' },
                { title: this.$t('phone.items.contacts'), eventName: 'Contacts', color: 'dark-gray', image: 'item-8.png' },
                { title: this.$t('phone.items.rating'), eventName: 'Rating', color: 'dark-red', image: 'item-4.png' }
            ],
            ads: {
                title: this.$t('phone.ads.title'),
                description: this.$t('phone.ads.description'),
                img: 'bottom-bg.png'
            }
        };
    },
    computed: {
        donate () {
            return this.$store.getters['player/formattedDonation'];
        },
        money () {
            return this.$store.getters['player/formattedCurrency'];
        }
    },
    mounted () {
        document.addEventListener('click', this.handleClickOutside);
        document.addEventListener('touchstart', this.handleTouchStart);
        document.addEventListener('touchend', this.handleTouchEnd);
    },
    beforeDestroy () {
        document.removeEventListener('click', this.handleClickOutside);
        document.removeEventListener('touchstart', this.handleTouchStart);
        document.removeEventListener('touchend', this.handleTouchEnd);
    },
    methods: {
        handleSwipeOutside (event) {
            if (this.touchStartPos < 0) return;
            const touchEndPos = event.changedTouches[0].clientX;
            if (touchEndPos - this.touchStartPos > 100) {
                this.$emit('close-mobile');
            }
        },
        handleTouchStart (event) {
            if (!this.$refs.phone.contains(event.target)) {
                this.touchStartPos = event.touches[0].clientX;
            }
        },
        handleTouchEnd (event) {
            if (!this.$refs.phone.contains(event.target)) {
                this.handleSwipeOutside(event);
                this.touchStartPos = -1;
            }
        },
        handleClickOutside (event) {
            if (!this.$refs.phone.contains(event.target)) {
                this.$emit('close-mobile');
            }
        },
        handleItemClick (item) {
            if (item.eventName) {
                this.sendclient(item.eventName);
            }
        },
        getAdsButton () {
            console.log('Нажали на рекламу');
        },
        getShopButton () {
            console.log('Нажали в магазин');
        }
    }
};
</script>

<style lang="scss" scoped>
.phone {
    position: fixed;
    right: 45px;
    bottom: 45px;
    top: 45px;
    padding: 42px 64px;
    background: linear-gradient(209.81deg, rgba(32, 36, 39, 0.81) 0%, rgba(23, 26, 29, 0.96) 60.85%);
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: var(--white);
    z-index: 25;
    &__heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-points {
            display: flex;
            align-items: center;
            &-text {
                font-size: 48px;
                color: var(--white);
                font-weight: 700;
                margin-left: 12px;
            }
            &-icon {
                svg {
                    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.25));
                    width: 48px;
                    height: auto;
                }
            }
        }
        &-money {
            &-text {
                font-size: 48px;
                font-weight: 700;
                &--gold {
                    color: var(--gold);
                }
            }
        }
        &-button {
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25), inset 0 2px 0 rgba(241, 241, 241, 0.55);
            border-radius: 0.5vw;
            border: none;
            outline: none;
            padding: 0;
            width: 2.8vw;
            height: 2.8vw;
            background: #FFCD4C url(@/assets/images/hud/phone/plus-button.svg) no-repeat center;
            background-size: 65%;
        }
    }
    &__grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 30px 34px;
        justify-items: center;
        padding: 32px 0;
        &-item {
            grid-column: 1 span;
            min-height: 218px;
            width: 100%;
            min-width: 206px;
            height: 100%;
            position: relative;
            line-height: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: 19px;
            &-title {
                width: fit-content;
                font-size: 28px;
                letter-spacing: 0.56px;
                font-family: var(--font-stretch), serif;
                z-index: 5;
                margin-top: auto;
                text-transform: uppercase;
                line-height: 100%;
                font-weight: 500;
                color: var(--white);
                padding: 6px 10px;
                border-radius: 4px;
                &--dark-red {
                    background-color: #B4253C;
                }
                &--red {
                    background-color: var(--red);
                }
                &--green {
                    background-color: var(--green);
                }
                &--blue {
                    background-color: var(--blue);
                }
                &--gray {
                    background-color: var(--gray);
                }
                &--orange {
                    background-color: var(--orange);
                }
                &--purple {
                    background-color: var(--purple);
                }
                &--cyan {
                    background-color: var(--cyan);
                }
                &--dark-gray {
                    background-color: var(--dark-gray);
                }
                &--dark-purple {
                    background-color: #372093;
                }
            }
            &-image {
                border-radius: 0.5vw;
                position: absolute;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
            &-label {
                position: absolute;
                z-index: 8;
                left: -10px;
                top: -10px;
                width: 93.5px;
                height: auto;
            }
        }
    }
    &__bottom {
        //margin-top: auto;
        min-height: 160px;
        padding: 24px;
        background-size: cover;
        border-radius: .5vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        position: relative;
        img{
            width: 100%;
            height: 100%;
            bottom: 0;
            right: 0;
            object-fit: cover;
            position: absolute;
            z-index: -1;
        }
        &-text {
            font-size: 36px;
            text-transform: uppercase;
            font-weight: 900;
            line-height: 100%;
            font-family: var(--font-stretch), serif;
            &--gold {
                color: #FFED4C;
            }
        }
        &-btn {
            transform: rotate(180deg);
            width: 128px;
            height: 112px;
            padding: 0;
            border-radius: 4px;
            svg {
                width: 40px;
                height: 40px;
            }
        }
    }
}
</style>
