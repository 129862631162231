<template>
    <div class="sim-content sim-shop">
        <div
            v-if="playerPhoneNumber"
            class="sim-sell sim-shop__card"
            :class="{'sim-sell--active' : isNumberSelling}"
        >
            <div class="sim-sell__about">
                <img class="sim-shop__image" src="@/assets/images/simcard/card.png" alt="" />
                <div class="sim-shop__about">
                    <div class="sim-sell__text">
                        {{ $t('sim_card.your_number') }}
                    </div>
                    <div class="sim-shop__number">
                        {{ formatSimNumber(playerPhoneNumber) }}
                    </div>
                </div>
            </div>
            <div class="sim-sell__control">
                <div
                    v-if="isNumberSelling"
                    class="sim-sell__info">
                    <div class="sim-sell__text">
                        {{ $t('sim_card.number_in_sale') }}
                    </div>
                    <div class="sim-button__price sim-shop__price">
                        <span class="sim-button__dollar">$</span>
                        {{ $formatNum(playerNumberPrice) }}
                    </div>
                </div>
                <BaseInput
                    v-model="currentPlayerNumberPrice"
                    :dollar="true"
                    class="sim-sell__input"
                    placeholder="$ 0"
                    inputMode="numeric"
                    v-else
                />
                <BaseButton
                    class="sim-sell__decline-btn"
                    v-if="isNumberSelling"
                    glass
                    @click="stopSellingNumber"
                >
                    {{ $t('sim_card.cancel') }}
                </BaseButton>
                <BaseButton
                    v-else
                    white
                    class="sim-sell__approve-btn"
                    @click="this.sellNumber"
                >
                    {{ $t('sim_card.sell_number') }}
                    <IconPlus />
                </BaseButton>
            </div>
        </div>
        <div
            class="sim-shop__card"
            v-for="(item, index) in simCards"
            :key="index"
            @click="selectSim(item)"
            ref="card"
        >
            <img class="sim-shop__image" src="@/assets/images/simcard/card.png" alt="" />
            <div class="sim-shop__about">
                <div class="sim-shop__number">
                    {{ formatSimNumber(item.number) }}
                </div>
                <div class="sim-button__price sim-shop__price">
                    <span class="sim-button__dollar">$</span>
                    {{ $formatNum(item.price) }}
                </div>
            </div>
        </div>
        <div v-if="showLoader" class="sim-shop__loader">
            <VLoader class="sim-shop__loader-icon" />
            <div class="sim-shop__loader-text">
                {{ $t('sim_card.loading') }}
            </div>
        </div>
    </div>
</template>

<script>
import VLoader from '@/components/ui/VLoader.vue';
import intersectionObserverMixin from '@/plugins/intersectionObserverMixin';
import BaseInput from '@/components/ui/BaseInput.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import IconPlus from '@/components/icons/IconPlus.vue';

export default {
    name: 'SimShop',
    components: { IconPlus, BaseButton, BaseInput, VLoader },
    mixins: [intersectionObserverMixin],
    props: {
        simCards: Array,
        formatSimNumber: Function,
        selectSim: Function,
        playerPhoneNumber: Number,
        isNumberSelling: Boolean,
        playerNumberPrice: String
    },
    data () {
        return {
            showLoader: false,
            currentPlayerNumberPrice: String(this.playerNumberPrice)
        };
    },
    // setVueData('SimShop','showLoader', false);
    mounted () {
        this.observer = this.createObserver(this.$refs.card[this.$refs.card.length - 1], () => {
            if (this.$refs.card.length % 30 === 0) {
                this.showLoader = true;
                this.sendclient('loadSimCards');
                this.$emit('loader-displayed', true);
            }
        });
    },
    beforeDestroy () {
        this.observer.disconnect();
    },
    methods: {
        sellNumber () {
            this.$emit('sellNumber', this.currentPlayerNumberPrice);
        },
        stopSellingNumber () {
            this.currentPlayerNumberPrice = '';
            this.$emit('stopSellingNumber', this.currentPlayerNumberPrice);
        },
        stopLoading () {
            this.showLoader = false;
        }
    }
};
</script>

<style lang="scss" scoped>
.sim-shop{
    &__image{
        max-width: 102px;
    }
}
</style>
