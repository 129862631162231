<template>
    <div class="login login--standard register" :class="{ 'login--numpad': step !== 0 }">
        <template v-if="step === 0">
            <div class="login__step">
                <StepLabel is-register />
                <div class="login__step-form">
                    <div class="login__step-form-wrapper">
                        <BaseInput
                            :label="this.$t('auth.email')"
                            type="email"
                            v-model="email"
                            form
                        />
                        <BaseInput
                            :label="this.$t('auth.password')"
                            placeholder='• • • • • •'
                            v-model="password"
                            form password
                        />
                        <BaseCheckbox v-model="isChecked"/>
                        <BaseButton
                            full yellow
                            :disabled="!isChecked"
                            @click="submitForm"
                        >
                            {{ $t('auth.next') }}
                        </BaseButton>
                        <AuthSocial />
                    </div>
                </div>
                <div class="login__step-switch login__step-switch--register">
                    <div class="login__step-support">
                        <p>{{ $t('auth.got_problems') }}</p>
                        <a href="#">{{ $t('auth.call_support') }}</a>
                    </div>

                    <div class="login__step-question">
                        <p>{{ $t('auth.have_account') }}</p>
                        <BaseButton border @click = "GotoLogin">{{ $t('auth.authorize') }}</BaseButton>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="login__step">
                <div class="dialog dialog--numpad">
                    <div class="dialog__left">
                        <div class="dialog__wrapper">
                            <div class="dialog__title-wrapper">
                                <div class="dialog__title">
                                    {{ $t('auth.type_the_code') }}
                                </div>
                                <div class="dialog__login-desc">
                                    {{ $t('auth.confirming_email') }} <span>$3000</span> {{ $t('auth.in_the_beginning') }}
                                </div>
                            </div>

                            <BaseInput
                                mini
                                focused
                                placeholder=". . . ."
                                readonly
                                v-model="value"
                            />

                            <transition name="fade">
                                <div class="login__btn-group">
                                    <BaseButton yellow @click="ConfirmMail()">
                                        {{ $t('auth.confirm') }}
                                    </BaseButton>
                                    <BaseButton border @click="SkipMail()">
                                        {{ $t('auth.skip') }}
                                    </BaseButton>
                                </div>
                            </transition>
                        </div>
                    </div>
                    <div class="dialog__right">
                        <NumPad @item-clicked="updateValue" />
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import StepLabel from './components/StepLabel.vue';
import BaseInput from '@/components/ui/BaseInput.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import BaseCheckbox from '@/components/ui/BaseCheckbox.vue';
import AuthSocial from './components/AuthSocial.vue';
import NumPad from '@/components/ui/NumPad.vue';

export default {
    name: 'RegisterStep',
    components: { NumPad, AuthSocial, BaseCheckbox, BaseButton, BaseInput, StepLabel },
    data () {
        return {
            step: 1,
            email: '',
            password: '',
            isChecked: true,
            value: ''
        };
    },
    methods: {
        submitForm () {
            if (!this.email || this.email.length < 4 || !this.email.match(/^[a-zA-Z0-9._!%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/ig)) return this.sendnotif(4, this.$t('auth.incorrect_email'));
            if (!this.password || this.password.length < 4 || !this.password.match(/^([а-яА-ЯёЁa-zA-Z0-9-?!.,()"$:/№%äöüßčćžÑđš^~çљџњђћ«»\s]+)$/ig)) return this.sendnotif(4, this.$t('auth.incorrect_password'));
            this.sendclient('ShowRegisterNextStep', JSON.stringify({ a: this.email, b: this.password }));
        },
        updateValue (item) {
            if (item === 'delete') {
                this.value = this.value.slice(0, -1);
            } else if (item === 'clear') {
                this.value = '';
            } else if (this.value.length < 4) {
                this.value = this.value + item;
            }
        },
        ConfirmMail () {
            if (!this.value || this.value === '') return this.sendnotif(4, this.$t('auth.incorrect_code'));
            const correctSum = parseInt(this.value);
            if (correctSum <= 0) return this.sendnotif(4, this.$t('auth.incorrect_code'));
            this.sendclient('RegisterLastStep', JSON.stringify({ a: correctSum }));
        },
        SkipMail () {
            this.sendclient('RegisterLastStep', JSON.stringify({ a: 0 }));
        },
        GotoLogin () {
            this.sendclient('ShowLoginScreen');
        }
    }
};
</script>
