<template>
    <div class="dialog dialog--numpad">
        <div class="dialog__left">
            <div class="dialog__wrapper">
                <div class="dialog__title-wrapper">
                    <div class="dialog__title">
                        {{title}}
                    </div>
                </div>
                <BaseInput
                    mini
                    :dollar="dollar"
                    focused
                    :placeholder="dollar ? '$' : '. . .'"
                    readonly
                    v-model="value"
                    :formatNumbers="formatNumbers"
                />
                <transition name="fade">
                    <BaseButton v-if="value.length > 0" yellow @click="handleClick">
                        {{ $t('dialog_numpad.next') }}
                    </BaseButton>
                </transition>
            </div>
        </div>
        <div class="dialog__right">
            <NumPad @item-clicked="updateValue" />
        </div>
    </div>
</template>
<script>
import BaseInput from '@/components/ui/BaseInput.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import NumPad from '@/components/ui/NumPad.vue';

export default {
    name: 'DialogNumpad',
    components: { NumPad, BaseButton, BaseInput },
    data () {
        return {
            title: '',
            value: '',
            dollar: true,
            formatNumbers: true
        };
    },
    methods: {
        updateValue (item) {
            if (item === 'delete') {
                this.value = this.value.slice(0, -1);
            } else if (item === 'clear') {
                this.value = '';
            } else if (this.value.length < 13) {
                this.value = this.value + item;
            }
        },
        handleClick () {
            const cleanValue = this.value.replace(/\$|\s/g, '');
            const correctSum = parseInt(cleanValue);
            if (isNaN(correctSum) || correctSum < 0) return;
            this.sendclient('DialogResponse', JSON.stringify({ a: 1, b: correctSum }));
        }

    }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
