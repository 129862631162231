<template>
    <div class="review">
        <div class="review-content">
            <div class="review-stars">
                <IconStarChecked />
                <IconStarChecked />
                <IconStarChecked />
                <IconStarChecked />
                <IconStarChecked />
            </div>
            <div class="review-title">
                {{ $t('review.title') }}
            </div>
            <div class="review-text">
                {{ $t('review.text') }}
            </div>
            <div class="review-buttons">
                <BaseButton class="review-button" glass @click="decline">{{ $t('review.decline') }}</BaseButton>
                <BaseButton class="review-button" yellow @click="accept">{{ $t('review.accept') }}</BaseButton>
            </div>
        </div>
    </div>
</template>

<script>
import IconStarChecked from '@/components/icons/IconStarChecked.vue';
import BaseButton from '@/components/ui/BaseButton.vue';

export default {
    name: 'DialogReview',
    components: {
        BaseButton,
        IconStarChecked
    },
    methods: {
        accept () {
            console.log('оцениваем');
        },
        decline () {
            console.log('не оцениваем');
        }
    }
};
</script>

<style lang="scss" scoped>
.review {
    height: 100%;
    background: radial-gradient(58.28% 121.96% at 50% 0%, rgba(61, 21, 112, 0.98) 0%, rgba(20, 16, 27, 0.98) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    &::before, &::after {
        content: '';
        position: absolute;
        height: 1093px;
        width: 453px;
        background: url("@/assets/images/review/triangle.svg") no-repeat center/cover;
        top: 50%;
        transform: translateY(-50%);
    }
    &::after {
        right: 0;
    }
    &::before {
        left: 0;
        transform: translateY(-50%) rotate(180deg);
    }
    &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 1158px;
    }
    &-stars {
        display: flex;
        margin-bottom: 44px;
        &::v-deep {
            svg {
                width: 84px;
                height: 84px;
                margin-right: 24px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    &-title {
        color: var(--white);
        text-align: center;
        font-size: 90px;
        font-style: normal;
        font-weight: 300;
        line-height: 120%;
        margin-bottom: 24px;
    }
    &-text {
        color: rgba(241, 241, 241, 0.70);
        text-align: center;
        font-size: 44px;
        font-weight: 400;
        line-height: 120%;
        margin-bottom: 80px;
    }
    &-buttons {
        display: flex;
        align-items: center;
    }
    &-button {
        &:last-child {
            margin-left: 24px;
        }
    }
}
</style>
