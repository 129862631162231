<template>
    <div class="event-hud" v-if="show">
        <!-- state 0 and 1 -->
        <template v-if="[0, 1].includes(state)">
            <div class="event-badge" @click="openEvent" :class="{ 'opacity-0': !isBadgeVisible }">
                <div class="event-badge__wrapper">
                    <span class="event-badge__desc">{{ $t('event_badge.event') }} <IconEventStar fill="#ffffff" class="svg" /></span>
                    <span class="event-badge__name">{{ nameEvent }}</span>
                    <img class="event-badge__image" :src="srcImage(eventImages)" alt="">

                </div>
                <p class="event-badge__open" v-if="state === 0">{{ $t('event_badge.open') }}</p>
                <p class="event-badge__timer" v-if="state === 1"><IconTimer class="svg-timer"/> {{ formatTime(timeReadyStart) }}</p>
            </div>

            <div class="event-badge-open" :class="{ 'slide-in': isBadgeOpenVisible }">
                <i class="event-badge-open__close" @click="closeEvent">
                    <svg width="70" height="71" viewBox="0 0 70 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="35" cy="35.5" r="35" transform="rotate(-180 35 35.5)" fill="url(#paint0_linear_511_13280)" fill-opacity="0.2"/>
                        <path d="M35.0005 37.5624L42.7192 45.2811L44.7816 43.2187L37.0629 35.5L44.7816 27.7813L42.7192 25.7189L35.0005 33.4376L27.2816 25.7188L25.2192 27.7811L32.9381 35.5L25.2192 43.2189L27.2816 45.2813L35.0005 37.5624Z" fill="#F1F1F1"/>
                        <defs>
                            <linearGradient id="paint0_linear_511_13280" x1="35" y1="0.408368" x2="35" y2="49.5967" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#F1F1F1"/>
                                <stop offset="1" stop-color="#F1F1F1" stop-opacity="0"/>
                            </linearGradient>
                        </defs>
                    </svg>
                </i>
                <BaseButton border class="event-badge-open__remind" @click="reminderOff">{{ $t('event.do_not_disturb') }}</BaseButton>
                <div class="event-badge-open__wrapper">
                    <span class="event-badge-open__name">{{ nameEvent }}</span>
                    <div class="event-badge-open__time">
                        <section class="event-badge-open__time-section">
                            <div class="event-badge-open__time-count">
                                <span>{{ Math.floor(timeReadyStart / 60 / 10) }}</span>
                                <span>{{ Math.floor(timeReadyStart / 60) % 10 }}</span>
                                <i>:</i>
                                <span>{{ Math.floor(timeReadyStart % 60 / 10) }}</span>
                                <span>{{ timeReadyStart % 60 % 10 }}</span>
                            </div>
                            <p>{{ $t('event.begin') }}</p>
                        </section >
                        <section class="event-badge-open__time-section member">
                            <div class="event-badge-open__time-member">
                                <span>{{ Math.floor(participants / 10) }}</span>
                                <span>{{ participants % 10 }}</span>
                            </div>
                            <p>{{ $t('event.participants') }}</p>
                        </section>
                    </div>
                    <BaseButton white @click="goEvent"><IconEventStar />
                        {{ $t('event.join') }}
                    </BaseButton>
                </div>
                <img class="event-badge-open__image" :src="srcImage(eventImages)" alt="">
            </div>
        </template>

        <!-- state 2 -->
        <template v-else>
            <div class="event-badge-start">
                <div class="event-badge-start__title">
                    <p class="event-badge-start__timer">
                        {{ $t('event.end_event') }}
                        <IconTimer class="event-badge-start__svg"/>
                        {{ formatTime(timeReadyStart) }}</p>
                </div>
                <ul class="event-badge-start__list">
                    <li v-for="(item, index) in filterList" :key="index">
                        <span>{{ $formatNum(item.score) }}</span>
                        {{ item.name }}
                    </li>
                </ul>
            </div>
        </template>

    </div>
</template>

<script>
import IconEventStar from './icons/IconEventStar.vue';
import IconTimer from '@/components/icons/IconTimer.vue';
import BaseButton from '@/components/ui/BaseButton.vue';

export default {
    name: 'EventBadge',
    components: { BaseButton, IconTimer, IconEventStar },
    data () {
        return {
            show: false,
            nameEvent: this.$t('event_badge.bank_robbery'),

            // 1: 'Ограбление банка',
            // 2: 'захват бизнеса',
            // 3: 'Битва семей',
            // 4: 'Захват завода',
            // 5: 'Граффити',
            // 6: 'ГОнки',
            // 7: 'боеприпасы в старом арабском городе',
            // 8: 'Доставка боеприпасов',
            // 9: 'Производство медикаментов',
            // 10: 'Охрана тюрьмы',
            // 11: 'МОчилово 1',
            // 12: 'МОчилово 2',
            // 13: 'Поиск клада',
            // 14: 'Перестрелка банд'
            // 15: 'Нападение на семью'

            style: 2,

            // 0 - не участвует, 1 - принял участие, 2 - евент начался (появилась стата)
            state: 0,
            timeReadyStart: 20, // state 1
            // totalSeconds: 100, // Общее время для отсчета в секундах
            participants: 10,
            timer: null,
            isBadgeVisible: true,
            isBadgeOpenVisible: false,
            teamList: [
                { name: 'Shredinger', score: 1000 },
                { name: 'Donjuans', score: 8800 },
                { name: 'Stressfam', score: 500 },
                { name: 'Californication', score: 7423 },
                { name: 'Пятая', score: 12 }
            ]
        };
    },
    computed: {
        filterList () {
            return [...this.teamList].sort((a, b) => b.score - a.score);
        },
        eventImages () {
            const images = {
                1: 'event-hud-bank_robbery',
                2: 'event-hud-business_capture',
                3: 'event-hud-families_battle',
                4: 'event-hud-factory_capture',
                5: 'event-hud-graffiti',
                6: 'event-hud-racing',
                7: 'event-hud-arabic_town',
                8: 'event-hud-ammunition_delivery',
                9: 'event-hud-production_medicines',
                10: 'event-hud-prison_security',
                11: 'event-hud-shooting_1',
                12: 'event-hud-shooting_2',
                13: 'event-hud-treasure_search',
                14: 'event-hud-gang_capt',
                15: 'event-hud-family_attack'
            };
            return images[this.style] || 'event-hud-bank_robbery';
        }
    },
    methods: {
        srcImage (img) {
            return require(`./images/${img}.png`);
        },
        /*
        window.modifyVueComponent({
            EventBadge:{
                show: true,
                initializeEvent:[
                    'asd',
                    10,
                    0
                ]
            }
        });
        */
        initializeEvent (name, seconds, participants, preview = -1) {
            if (this.state >= 1 && this.show === true) return;
            this.state = 0;
            this.nameEvent = name;
            this.timeReadyStart = seconds;
            this.participants = participants;
            this.startTimer();
            if (preview !== -1) this.style = preview;
        },
        /*
        window.modifyVueComponent({
            EventBadge:{
                show: true,
                beforeEvent:[
                    'asd',
                    10
                ]
            }
        });
        */
        beforeEvent (name, seconds, preview = -1) {
            this.state = 1;
            this.nameEvent = name;
            this.timeReadyStart = seconds;
            this.startTimer();
            if (preview !== -1) this.style = preview;
        },
        /*
        window.callVueMethod('EventBadge','startEvent', 'Новый ивент', 30, [{ name: 'Shredinger', score: 1000 },
        { name: 'Donjuans', score: 8800 },
        { name: 'Stressfam', score: 500 },
        { name: 'Californication', score: 7423 },
        { name: 'Пятая', score: 12 }]);
        */
        startEvent (name, seconds, teamList = [], preview = -1) {
            this.state = 2;
            this.nameEvent = name;
            this.timeReadyStart = seconds;
            this.teamList = teamList;
            this.startTimer();
            if (preview !== -1) this.style = preview;
        },
        openEvent () {
            this.isBadgeVisible = false;
            this.isBadgeOpenVisible = true;
        },
        goEvent () {
            this.sendclient('RouteEvent');
        },
        reminderOff () {
            this.sendclient('disableEventReminder');
        },
        closeEvent () {
            this.isBadgeVisible = true;
            this.isBadgeOpenVisible = false;
        },
        startTimer () {
            if (this.timerInterval) {
                clearInterval(this.timerInterval);
            }
            this.timerInterval = setInterval(() => {
                if (this.timeReadyStart > 0) {
                    this.timeReadyStart--;
                } else {
                    clearInterval(this.timerInterval);
                }
            }, 1000);
        },
        formatTime (seconds) {
            const minutes = Math.floor(seconds / 60);
            const remainingSeconds = seconds % 60;

            const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
            const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;

            return `${formattedMinutes}:${formattedSeconds}`;
        }
    },
    mounted () {
        this.startTimer();
    },
    beforeDestroy () {
        if (this.timerInterval) {
            clearInterval(this.timerInterval);
        }
    }

};
</script>

<style lang="scss" scoped>
.event-badge{
    max-width: 536px;
    width: 100%;
    position: absolute;
    left: 50px;

    &-start{
        max-width: 336px;
        position: absolute;
        left: 168px;

        &__list{
            display: grid;
            li{
                display: flex;
                color: #F1F1F1;
                font-size: 30px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%; /* 36px */
                text-shadow:
                    2px 0 0 #394258CC,
                    0 2px 0 #394258CC,
                    -2px 0 0 #394258CC,
                    0 -2px 0 #394258CC;

                span{
                    color: #FFED4C;
                    margin-right: 12px;

                    font-size: 30px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%; /* 36px */
                }
            }
        }
        &__timer{
            border-bottom: 4px solid rgba(241, 241, 241, 0.20);
            padding-bottom: 32px;
            color: #FFF;
            text-align: center;
            font-family: var(--font-stretch);
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%; /* 32px */
            letter-spacing: 1.28px;
            text-transform: uppercase;
            align-items: center;
            display: flex;
            margin-top: 16px;
            justify-content: center;
            margin-bottom: 24px;
        }
        &__svg{
            margin: 0 4px;
        }
    }

    &-open{
        padding: 56px 49px;
        background: url(@/assets/images/bg-open-event.svg) no-repeat center /cover;
        width: 726px;
        height: 866px;
        position: fixed;
        bottom: -40px;
        left: 106px;
        z-index: 1;
        overflow: hidden;

        &__remind{
            color: #F1F1F1;
            text-align: center;
            height: 58px;
            padding: 0;
            max-width: 299px;
            width: 100%;
            position: absolute;
            top: 56px;
            right: 49px;
            font-size: 24px;
            font-weight: 500;
            line-height: 110%; /* 26.4px */
        }

        &__image{
            max-width: 100%;
            position: absolute;
            bottom: 40px;
            margin: 0 auto;
            left: 0;
            right: 0;
            height: 26%;
            display: block;
        }

        &__close{
            position: absolute;
            top: 50px;
            left: 45px;
            svg{
                width: 70px;
                height: 70px;
            }
        }

        &__name{
            color: #FFF;
            font-family: var(--font-stretch);
            font-size: 44px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%; /* 44px */
            letter-spacing: 0.88px;
            text-transform: uppercase;
        }

        &__svg{
            width: 44px;
            height: 44px;
            margin: 0 0 12px;
        }

        &__wrapper{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding-top: 111px;
        }

        &__time{
            display: grid;
            grid-template-columns: repeat(2, max-content);
            grid-gap: 64px;
            margin: 44px auto;
            &-section{
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                &.member{
                    p{
                        color: #FFCD4C !important;
                        opacity: 1;
                    }
                    span{
                        background: #FFCD4C !important;
                        color: #141A1E !important;
                    }
                }
            }
            &-count, &-member{
                display: flex;
                margin: 14px 0;
            }
            p{
                color: #F1F1F1;
                font-size: 26px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%; /* 26px */
                letter-spacing: 1.56px;
                text-transform: uppercase;
                font-family: var(--font-stretch);
            }
            span{
                text-align: center;
                display: flex;
                padding: 8px 8px 12px 8px;
                flex-direction: column;
                align-items: flex-start;
                border-radius: 4px;
                background: #000;
                margin-right: 4px;
                color: #FFF;
                font-size: 64px;
                font-style: normal;
                font-weight: 700;
                line-height: 80%; /* 41.6px */
                letter-spacing: -1.04px;
            }
            i{
                color: #FFF;
                font-size: 52px;
                font-style: normal;
                font-weight: 700;
                line-height: 80%; /* 41.6px */
                letter-spacing: -1.04px;
                margin-right: 4px;
            }
        }
    }

    &__timer{
        color: #FFF;
        text-align: center;
        font-family: var(--font-stretch);
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 32px */
        letter-spacing: 1.28px;
        text-transform: uppercase;
        align-items: center;
        display: flex;
        margin-top: 16px;
        justify-content: center;
    }

    &__open{
        color: #FFF;
        text-align: center;
        font-family: var(--font-stretch);
        justify-content: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 900;
        line-height: 100%; /* 20px */
        letter-spacing: 0.8px;
        text-transform: uppercase;
        margin: 8px auto 0;
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        &::before{
            width: 30%;
            height: 4px;
            flex-shrink: 0;
            display: flex;
            position: relative;
            content: "";
            background: #fff;
            margin-right: 16px;
        }
        &::after{
            width: 30%;
            height: 4px;
            flex-shrink: 0;
            display: flex;
            position: relative;
            content: "";
            background: #fff;
            margin-left: 16px;
        }
    }
    &__wrapper{
        width: 536px;
        height: 213px;
        text-align: center;
        padding-top: 25px;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        border-radius: 8px;
        overflow: hidden;
        border: 2px solid rgba(104, 146, 255, 0.50);
        background: url(@/assets/images/event-badge-bg.svg) no-repeat center / cover;
        box-shadow: 0px 0px 70px 0px #4151A3, 0px 0px 120px 0px rgba(104, 146, 255, 0.50), 0px -6px 44px 0px rgba(104, 146, 255, 0.70) inset;
    }

    &__desc{
        color: #f1f1f1;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 32px */
        letter-spacing: 0.64px;
        text-transform: uppercase;
        font-family: var(--font-stretch);
    }

    &__name{
        color: #F1F1F1;
        font-family: var(--font-stretch);
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 40px */
        letter-spacing: 0.8px;
        text-transform: uppercase;
    }

    &__image{
        bottom: 0;
        max-width: 65%;
        margin: 0 auto;
        position: relative;
        display: block;
    }
}
</style>

<style>
.event-badge{
    .svg{
        width: 24px !important;
        height: 24px !important;
        path{
            fill: #ffffff !important;
        }
    }
    .svg-timer{
        width: 32px;
        height: 32px;
        margin-right: 8px;
    }
}

.event-badge.opacity-0 {
    opacity: 0;
    transition: opacity 0.3s ease;
}

.event-badge-open {
    transform: translateY(100%);
    transition: transform 0.3s ease;
}

.event-badge-open.slide-in {
    transform: translateY(0);
}

</style>
