<template>
    <div class="loader">
        <svg width="148" height="148" viewBox="0 0 148 148" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="1" fill="#F1F1F1" fill-rule="evenodd" clip-rule="evenodd" d="M68.8252 147.999V122.449L25.55 79.1739L25.5501 79.1738H1.52588e-05L0 79.1738L68.8252 147.999Z">
                <animate attributeName="opacity" values="0.1; 1; 0.1" dur="4s" repeatCount="indefinite" begin="0s" />
            </path>
            <path opacity="0.1" fill="#F1F1F1" fill-rule="evenodd" clip-rule="evenodd" d="M68.8252 25.55L68.8251 0L0 68.8251L25.55 68.8252L68.8252 25.55Z">
                <animate attributeName="opacity" values="0.1; 1; 0.1" dur="4s" repeatCount="indefinite" begin="1s" />
            </path>
            <path opacity="0.1" fill="#F1F1F1" fill-rule="evenodd" clip-rule="evenodd" d="M79.1748 0L148 68.8252H122.45L79.1748 25.55L79.1748 25.5501V0H79.1748Z">
                <animate attributeName="opacity" values="0.1; 1; 0.1" dur="4s" repeatCount="indefinite" begin="2s" />
            </path>
            <path opacity="0.1" fill="#F1F1F1" fill-rule="evenodd" clip-rule="evenodd" d="M148 79.1738L122.45 79.1738L122.45 79.1739L79.1748 122.449L79.1748 122.449L79.1748 147.999L148 79.1738Z">
                <animate attributeName="opacity" values="0.1; 1; 0.1" dur="4s" repeatCount="indefinite" begin="3s" />
            </path>
        </svg>
        <!--
        <BaseButton class="loader__btn" border @click="closeLoader">
            <svg class="loader__btn-svg" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                <path d="M20.0004 22.3579L28.8218 31.1793L31.1788 28.8223L22.3574 20.0009L31.1788 11.1794L28.8218 8.82242L20.0004 17.6438L11.1788 8.82227L8.82178 11.1793L17.6433 20.0009L8.82178 28.8224L11.1788 31.1794L20.0004 22.3579Z" fill="#F1F1F1"/>
            </svg>
        </BaseButton>
        -->
    </div>
</template>

<script>
// import BaseButton from '@/components/ui/BaseButton.vue';

export default {
    name: 'PopupLoader',
    // components: { BaseButton },
    methods: {
        closeLoader () {
            console.log('close loader');
        }
    }
};
</script>

<style lang="scss" scoped>
.loader{
    position: relative;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    &::before{
        background: url(./assets/images/loader-bg.svg) no-repeat center / cover;
        position: absolute;
        content: '';
        z-index: 0;
        display: block;
        height: 100%;
        width: 100%;
        animation: move 8s linear infinite;
    }

    @keyframes move {
        0% {
            transform: scaleX(1);
        }
        50%{
            transform: scaleX(1.05);
        }
        100% {
            transform: scaleX(1);
        }
    }

    & * {
        z-index: 1;
    }
    &__animation{
        width: 148px;
        height: 148px;
    }

    &__btn{
        position: absolute;
        bottom: 225px;
        margin: 0 auto;
        left: 0;
        right: 0;
        width: 128px;
        height: 112px;
        display: flex;

        &-svg{
            width: 40px;
            height: 40px;
            position: absolute;
        }
    }
}

@keyframes blink {
    0%, 100% { opacity: 0; }
    50% { opacity: 1; }
}
</style>
