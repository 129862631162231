<template>
    <div class="money-balance">
        <transition name="fade">
          <span v-show="displayBalance" class="dollar">
            <span class="dollar__symbol dollar__symbol--yellow">$</span>
            <CountUp ref="countUp" :endVal="balance" :startVal="balance" :options="options" :duration="0.5" />
          </span>
        </transition>
        <transition name="fade">
            <div v-if="displayChange" class="money-balance__notice">
                <span v-if="isPositiveChange"><IconPlus /></span>
                <span v-else><IconMinus class="yellow" /></span>
                <span class="dollar__symbol" :class="isPositiveChange ? 'dollar__symbol--green' : 'dollar__symbol--yellow'">
          $
        </span>
                {{ $formatNum(Math.abs(localChangeValue)) }}
            </div>
        </transition>
    </div>
</template>

<script>
import IconPlus from '@/components/icons/IconPlus.vue';
import IconMinus from '@/components/icons/IconMinus.vue';
import CountUp from 'vue-countup-v2';

export default {
    name: 'MoneyBalance',
    components: {
        IconMinus,
        IconPlus,
        CountUp
    },
    data () {
        return {
            balance: 0,
            localChangeValue: 0,
            displayChange: false,
            displayBalance: false,
            displayChangeTimeout: undefined,
            displayBalanceTimeout: undefined,
            options: {
                separator: ' '
            }
        };
    },
    computed: {
        isPositiveChange () {
            return this.localChangeValue > 0;
        },
        isNegativeChange () {
            return this.localChangeValue < 0;
        }
    },
    methods: {
        updateBalance (balance, change) {
            this.balance = balance;
            this.localChangeValue = change;

            const newBalance = this.balance + change;

            this.startVal = this.balance;
            this.balance = newBalance;

            this.displayBalance = true;
            if (this.localChangeValue !== 0) this.displayChange = true;

            this.$nextTick(() => {
                this.$refs.countUp.start();
            });

            if (this.displayChangeTimeout !== undefined) {
                clearTimeout(this.displayChangeTimeout);
                this.displayChangeTimeout = undefined;
            }

            this.displayChangeTimeout = setTimeout(() => {
                this.displayChange = false;
                this.displayChangeTimeout = undefined;
            }, 1500);

            if (this.displayBalanceTimeout !== undefined) {
                clearTimeout(this.displayBalanceTimeout);
                this.displayBalanceTimeout = undefined;
            }

            this.displayBalanceTimeout = setTimeout(() => {
                this.displayBalance = false;
                this.displayBalanceTimeout = undefined;
            }, 3000);
        }
    }
};
</script>
<style lang="scss">
.money-balance{
    position: absolute;
    top: 14vw;
    right: 3vw;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .dollar{
        font-weight: 900;
        font-size: 3vw;
        line-height: 100%;
    }

    &__notice{
        font-weight: 900;
        font-size: 1.5vw;
        line-height: 100%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        width: max-content;
        margin-top: .5vw;
        display: flex;
        align-items: center;

        background: rgba(20, 26, 30, 0.9);
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05), 0px 14px 100px rgba(0, 0, 0, 0.05);
        border-radius: .3vw;
        padding: .5vw .6vw;

        svg{
            height: 1.5vw;
            width: auto;
            margin-right: .2vw;
            path{
                fill: #9FD035;
            }
        }

        .yellow{
            path{
                fill: #FFCD4C;
            }
        }
    }
}
</style>

<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}

</style>
