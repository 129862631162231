<template>
    <div class="org" :class="type === 2 ? 'family' : ''"
         :style="type === 1 ? {background: `url(${imageSource('bg-popup-vector-dark.svg', null)}) no-repeat center/cover, radial-gradient(66.94% 140.08% at 50% 0%, ${gradient[0].startColor} 0%, ${gradient[0].finishColor} 100%)`} : {background: `${selectedType === 'about' ? `url(${imageSource('family.png', 'organization')}) no-repeat right top 6.5vw /contain, url(${imageSource('family-bg.svg', 'organization')}) no-repeat right center/contain,` : ''} url(${imageSource('bg-popup-vector-dark.svg', null)}) no-repeat center/cover, radial-gradient(66.94% 140.08% at 50% 0%, ${gradient[1].startColor} 0%, ${gradient[1].finishColor} 100%)`}"
    >
        <AppHeader back-button>
            <template #title>{{ organization }}</template>
        </AppHeader>
        <div class="org__content">
            <BaseTabMenu class="org__tabs" :items=tabDefinitions :activeTab=selectedType isScrollable :handleTabChange=changeTab />
            <div v-if="selectedType === 'about'" class="org__info org__info--about">
                <template v-if="type === 1">
                    <img :src="imageSource(`organization-${aboutOrganization.id}.png`, 'organization')" alt="" class="org__info-image">
                    <div class="org__info-content">
                        <div class="org__info-status">Босс</div>
                        <div class="org__info-name">{{ aboutOrganization.boss }}</div>
                        <div class="org__info-members">
                            <div class="org__info-members-number">{{ aboutOrganization.members }}</div>
                            <div class="org__info-members-text">{{ $t('family.organization.members_in') }}</div>
                        </div>
                        <div class="org__info-members">
                            <div class="org__info-members-number"><span class="org__info-members-dollar">$</span>{{$formatNum(aboutOrganization.savings)}}</div>
                            <div class="org__info-members-text org__info-members-text--money">
                                {{ $t('family.organization.money') }}</div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="org__info-content">
                        <div class="org__info-status">{{ aboutFamily.status }}</div>
                        <div class="org__info-name">{{ aboutFamily.name }}</div>
                        <div class="org__info-members">
                            <div class="org__info-members-number">{{ aboutFamily.members }}</div>
                            <div class="org__info-members-text">{{ $t('family.members_in') }}</div>
                        </div>
                        <div class="org__info-members">
                            <div class="org__info-members-number"><span class="org__info-members-dollar">$</span>{{ $formatNum(aboutFamily.savings) }}</div>
                            <div class="org__info-members-text org__info-members-text--money">{{ $t('family.money_have') }}</div>
                        </div>
                    </div>
                </template>
            </div>
            <div v-if="selectedType === 'team'" class="org__info org__info--team" @scroll="scrollTrigger">
                <div v-for="member in filteredTeamList" :key="member.index" class="org__info-member">
                    <div class="org__info-member-item">
                        <div
                            class="org__info-member-status"
                            :class="{'org__info-member-status--offline': member.status === 0}">
                        </div>

                        <div class="org__info-member-id">{{ member.id }}</div>
                        <div class="org__info-member-info">
                            <div class="org__info-member-name">{{ member.name }}</div>
                            <div class="org__info-member-rank">{{ member.rank }}</div>
                        </div>
                    </div>
                    <div class="org__info-member-item">
                        <!--
                        <div class="org__info-member-time">
                            <div class="org__info-member-text">Сегодня</div>
                            <div class="org__info-member-clock">{{ member.today }}</div>
                        </div>
                        <div class="org__info-member-time">
                            <div class="org__info-member-text">Неделя</div>
                            <div class="org__info-member-clock">{{ member.week }}</div>
                        </div>
                        -->
                        <div class="org__info-member-time">
                            <div class="org__info-member-text">{{ member.lastSeen }}</div>
                        </div>
                    </div>
                </div>
                <section class="org__info-search">
                    <div class="org__info-search-find">
                        <IconSearch />
                        <input type="text" v-model="search" :placeholder="this.$t('family.search')" />
                    </div>
                    <div class="org__info-search-menu">
                        <div @click="statusFilter = 'Все'" :class="['org__info-search-menu-item', statusFilter === 'Все' ? 'org__info-search-menu-item--active' : '']">
                            {{ this.$t('family.status_filter.all') }}
                        </div>
                        <div @click="statusFilter = 'Онлайн'" :class="['org__info-search-menu-item', statusFilter === 'Онлайн' ? 'org__info-search-menu-item--active' : '']">
                            {{ this.$t('family.status_filter.online') }}
                        </div>
                        <div @click="statusFilter = 'Оффлайн'" :class="['org__info-search-menu-item', statusFilter === 'Оффлайн' ? 'org__info-search-menu-item--active' : '']">
                            {{ this.$t('family.status_filter.offline') }}
                        </div>
                    </div>
                    <div class="org__info-search-members">
                        <div class="org__info-search-members-number">{{ filteredTeamList.length }}</div>
                        <div class="org__info-search-members-text">{{ $t('family.total_in') }}</div>
                    </div>
                </section>
            </div>
            <div v-if="selectedType === 'events'" class="org__info org__info--event">
                <template v-if="type === 2">
                    <div v-for="event in eventList" :key="event.id" class="org__info-event">
                    <div class="org__info-event-image" :style="{background: `url(${imageSource(event.image, 'organization')}) center/cover`}">
                        <BaseButton class="org__info-event-button" yellow v-if = "event.route" @click="eventRoute(event.route)">
                            <IconLocation />
                            {{ $t('family.route') }}
                        </BaseButton>
                    </div>
                    <div class="org__info-event-data">
                        <div class="org__info-event-heading" v-if = "event.time">
                            <div class="org__info-event-heading-time">{{ event.time }}</div>
                            <IconTimer />
                            <div class="org__info-event-heading-date">{{ event.date }}</div>
                        </div>
                        <div class="org__info-event-title">{{ event.title }}</div>
                        <div class="org__info-event-about">
                            <div class="org__info-event-item" v-if = "event.awards">
                                <div class="org__info-event-subtitle">{{ $t('family.rewards') }}</div>
                            </div>
                            <div class="org__info-event-item">
                                <div class="org__info-event-subtitle">{{ $t('family.description') }}</div>
                                <div class="org__info-event-descr">{{ event.description }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                </template>
                <template v-else>
                    <div v-for="event in currentEvents" :key="event.id" class="org__info-event">
                        <div class="org__info-event-image" :style="{background: `url(${imageSource(event.image, 'organization')}) center/cover`}">
                            <BaseButton class="org__info-event-button" yellow v-if = "event.route" @click="eventRoute(event.route)">
                                <IconLocation />
                                {{ $t('family.route') }}
                            </BaseButton>
                        </div>
                        <div class="org__info-event-data">
                            <div class="org__info-event-heading" v-if = "event.time">
                                <div class="org__info-event-heading-time">{{ event.time }}</div>
                                <IconTimer />
                                <div class="org__info-event-heading-date">{{ event.date }}</div>
                            </div>
                            <div class="org__info-event-title">{{ event.title }}</div>
                            <div class="org__info-event-about">
                                <div class="org__info-event-item" v-if = "event.awards">
                                    <div class="org__info-event-subtitle">{{ $t('family.rewards') }}</div>
                                </div>
                                <div class="org__info-event-item">
                                    <div class="org__info-event-subtitle">{{ $t('family.description') }}</div>
                                    <div class="org__info-event-descr">{{ event.description }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div v-if="selectedType === 'tasks'" class="org__info org__info--task">
                <template v-if="type === 2">
                    <div
                        v-for="(task, index) in tasksList"
                        :key="index"
                        :class="['org__info-task', task.isFinished ? 'org__info-task--finished' : '']"
                    >
                        <div class="org__info-task-data">
                            <div class="org__info-task-heading">
                                <div class="org__info-task-title">{{ task.title }}</div>
                                <div class="org__info-task-label" v-if="task.isFinished">
                                    <IconCheckmark />
                                    {{ $t('family.done') }}
                                </div>
                                <div class="org__info-task-text" v-else>{{ task.text }}</div>
                            </div>
                            <BaseButton v-if="task.isFinished" class="org__info-task-button" yellow @click="handleReward(index)">
                                {{ $t('family.take_reward') }}</BaseButton>
                            <div class="org__info-task-progress" v-else>
                                <div class="org__info-event-subtitle">{{ $t('family.progress') }}</div>
                                <div class="org__info-task-bars">
                                    <div
                                        class="org__info-task-bar"
                                        v-for="n in 5"
                                        :key="n"
                                        :class="{ 'org__info-task-bar--finished': n <= task.progress }"
                                    ></div>
                                </div>
                            </div>
                        </div>
                        <div class="org__info-task-prize">
                            <div class="org__info-event-subtitle">
                                {{ $t('family.rewards') }}
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div
                        v-for="(task, index) in currentTasks"
                        :key="index"
                        :class="['org__info-task', task.isFinished ? 'org__info-task--finished' : '']"
                    >
                        <div class="org__info-task-data">
                            <div class="org__info-task-heading">
                                <div class="org__info-task-title">{{ task.title }}</div>
                                <div class="org__info-task-label" v-if="task.isFinished">
                                    <IconCheckmark />
                                    {{ $t('family.done') }}
                                </div>
                                <div class="org__info-task-text" v-else>{{ task.text }}</div>
                            </div>
                            <BaseButton v-if="task.isFinished" class="org__info-task-button" yellow @click="handleReward(index)">
                                {{ $t('family.take_reward') }}</BaseButton>
                            <div class="org__info-task-progress" v-else>
                                <div class="org__info-event-subtitle">{{ $t('family.progress') }}</div>
                                <div class="org__info-task-bars">
                                    <div
                                        class="org__info-task-bar"
                                        v-for="n in 5"
                                        :key="n"
                                        :class="{ 'org__info-task-bar--finished': n <= task.progress }"
                                    ></div>
                                </div>
                            </div>
                        </div>
                        <div class="org__info-task-prize">
                            <div class="org__info-event-subtitle">
                                {{ $t('family.rewards') }}
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div v-if="selectedType === 'estates'" class="org__info org__info--estate">
                <div class="org__info-estates">
                    <div class="org__info-estates-number">{{ estateList.filter(estate => estate.isCaptured).length }}</div>
                    <div class="org__info-estates-text">{{ $t('organization.total_estates') }}</div>
                </div>
                <div class="org__info-estate" v-for="(estate, index) in sortedEstateList" :key="index">
                    <div
                        class="org__info-estate-image"
                        :style="{
                            background: `url(${imageSource(estate.image, 'estate')}) center/cover`,
                            filter: !estate.isCaptured ? 'grayscale(100%)' : 'none'
                            }">
                        <BaseButton class="org__info-estate-image-button" white @click="handleEstateLocation(index)">
                            <IconLocation />
                        </BaseButton>
                    </div>
                    <div
                    :class="{'org__info-event-data': estate.isCaptured, 'org__info-event-flex': !estate.isCaptured}">
                        <div :class="{'org__info-event-title': estate.isCaptured, 'org__info-event-title--uncaptured': !estate.isCaptured}">{{ estate.title }}</div>
                        <div v-if="estate.isCaptured" class="org__info-estate-grid">
                            <div class="org__info-estate-item">
                                <div class="org__info-event-subtitle">{{ $t('organization.profitHour') }}</div>
                                <div class="org__info-estate-price">
                                    <span class="org__info-estate-dollar">$</span>
                                    {{ $formatNum(estate.profit) }}
                                </div>
                                <!--<div class="org__info-event-subtitle org__info-event-subtitle--bottom">{{ $t('organization.last_time') }} {{ estate.lastTime }}</div>-->
                            </div>
                            <!--
                            <div class="org__info-estate-item">
                                <div class="org__info-event-subtitle">{{ $t('organization.capture_date') }}</div>
                                <div class="org__info-estate-price">{{ estate.captureDate }}</div>
                            </div>
                            -->
                        </div>
                        <div v-if="!estate.isCaptured" class="org__info-estate-text">{{ $t('organization.no_capture') }}</div>
                    </div>
                </div>
            </div>
            <div v-if="selectedType === 'settings'" class="org__info org__info--settings family__settings">
                <div class="family__settings-item">
                    <div class="org__info-event-subtitle family__settings-title">{{ $t('family.actions.actions') }}</div>
                    <div class="family__settings-buttons">
                        <template v-if="type === 2">
                            <BaseButton class="family__settings-button" glass v-for="(item, index) in familyButtons" :key="index" @click = "ActionButton(index+1)">
                                {{item.title}}
                                <IconRefresh v-if = "item.icon == 'refresh'" class="family__settings-button-icon" />
                                <IconRefresh v-if = "item.icon == 'plus'" class="family__settings-button-icon" />
                            </BaseButton>
                        </template>
                        <template v-else>
                            <BaseButton class="family__settings-button" glass v-for="(item, index) in currentButtons" :key="index" @click = "ActionButton(index+1)">
                                {{item.title}}
                                <IconRefresh v-if = "item.icon == 'refresh'" class="family__settings-button-icon" />
                                <IconRefresh v-if = "item.icon == 'plus'" class="family__settings-button-icon" />
                            </BaseButton>
                        </template>
                    </div>
                </div>
                <div v-if = "familySettings && familySettings.length" class="org__info-event-subtitle family__settings-title">
                    {{ $t('family.settings') }}
                </div>
                <div v-if = "familySettings && familySettings.length" class="family__settings-grid">
                    <div v-for="(item, index) in familySettings"
                         :key="index"
                         class="family__settings-param"
                         @click="activateSetting(item)"
                    >
                        <input type="checkbox" class="family__settings-slidebox" v-model="item.isChecked" />
                        <div class="family__settings-param-descr">{{ item.title }}</div>
                    </div>
                </div>
            </div>
            <div v-if="selectedType === 'ranks'" class="org__info org__info--team">
                <div class="org__info-member" v-for="member in filteredTeamList" :key="member.index">
                    <div class="org__info-member-item">
                        <div class="org__info-member-status" :class="{'org__info-member-status--offline': member.status === 0}"></div>
                        <div class="org__info-member-id">{{ member.id }}</div>
                        <div class="org__info-member-info">
                            <div class="org__info-member-name">{{ member.name }}</div>
                            <div class="org__info-member-rank">{{ member.rank }}</div>
                        </div>
                    </div>
                    <div class="org__info-member-item">
                        <BaseButton class="org__info-member-button" glass @click="giveReward(member.id)">
                            <div class="org__info-member-button-text">{{ $t('family.give_award') }}</div>
                            <IconPlus class="org__info-member-button-icon" />
                        </BaseButton>
                        <BaseButton class="org__info-member-button" glass @click="changeRank(member.id)">
                            <div class="org__info-member-button-text">{{ member.rank }}</div>
                            <IconEdit class="org__info-member-button-icon" />
                        </BaseButton>
                    </div>
                </div>
                <section class="org__info-search org__info-search--family-ranks">
                    <div class="org__info-search-find">
                        <IconSearch />
                        <input type="text" placeholder="Поиск" v-model="search" />
                    </div>
                    <div class="org__info-search-menu">
                        <div class="org__info-search-menu-item" :class="{ 'org__info-search-menu-item--active': rankFilter === 'all' }" @click="rankFilter = 'all'">
                            {{ $t('family.status_filter.all') }}
                        </div>
                        <div class="org__info-search-menu-item" :class="{ 'org__info-search-menu-item--active': rankFilter === 'online' }" @click="rankFilter = 'online'">
                            {{ $t('family.status_filter.online') }}
                        </div>
                        <div class="org__info-search-menu-item" :class="{ 'org__info-search-menu-item--active': rankFilter === 'offline' }" @click="rankFilter = 'offline'">
                            {{ $t('family.status_filter.offline') }}
                        </div>
                    </div>
                    <BaseButton class="org__info-search-members org__info-search-button" white>
                        {{ $t('family.actions.give_award') }}
                        <IconPlus />
                    </BaseButton>
                </section>
            </div>
            <div v-if="selectedType === 'blacklist'" class="org__info org__info--team">
                <div class="org__info-member" v-for="member in blacklist" :key="member.index">
                    <div class="org__info-member-item">
                        <!--<div class="org__info-member-id">{{ member.id }}</div>-->
                        <div class="org__info-member-info">
                            <div class="org__info-member-name">{{ member.name }}</div>
                            <!--<div class="org__info-member-rank">{{ member.rank }}</div>-->
                        </div>
                    </div>
                    <div class="org__info-member-item">
                        <BaseButton class="org__info-member-button" glass @click="removeMember(member.name)">
                            <IconTrash class="org__info-member-button-icon" />
                        </BaseButton>
                    </div>
                </div>
                <section class="org__info-search org__info-search--blacklist">
                    <div class="org__info-search-find">
                        <IconPlus style="opacity: .5" />
                        <input type="text" placeholder="Введите никнейм" v-model="newMemberName" />
                    </div>
                    <BaseButton class="org__info-search-members org__info-search-button" white @click="addMember">
                        {{ $t('family.add') }}
                    </BaseButton>
                </section>
            </div>
            <div v-if="selectedType === 'logs'" class="org__info org__info--team">
                <div class="org__info-member" v-for="member in filteredLogs" :key="member.index">
                    <div class="org__info-member-item">
                        <div class="org__info-member-status"></div>
                        <div class="org__info-member-id">{{ member.id }}</div>
                        <div class="org__info-member-info">
                            <div class="org__info-member-name">{{ member.name }}</div>
                            <div class="org__info-member-rank">{{ member.rank }}</div>
                        </div>
                    </div>
                    <div class="org__info-member-item">
                        <div class="org__info-member-logs">
                            <div class="org__info-member-name">{{ member.action }}</div>
                            <div class="org__info-member-rank">{{ member.timestamp }}</div>
                        </div>
                    </div>
                </div>
                <section class="org__info-search org__info-search--logs">
                    <div class="org__info-search-find">
                        <IconSearch />
                        <input type="text" :placeholder="this.$t('family.search')" v-model="searchLogs" />
                    </div>
                    <div class="org__info-search-menu">
                        <div
                            class="org__info-search-menu-item"
                            :class="{ 'org__info-search-menu-item--active': selectedAction === 'all' }"
                            @click="selectedAction = 'all'"
                        >
                            {{ $t('family.logs_filter.all') }}
                        </div>
                        <div
                            class="org__info-search-menu-item"
                            :class="{ 'org__info-search-menu-item--active': selectedAction === 'took' }"
                            @click="selectedAction = 'took'"
                        >
                            {{ $t('family.logs_filter.took') }}
                        </div>
                        <div
                            class="org__info-search-menu-item"
                            :class="{ 'org__info-search-menu-item--active': selectedAction === 'replenished' }"
                            @click="selectedAction = 'replenished'"
                        >
                            {{ $t('family.logs_filter.deposit') }}
                        </div>
                    </div>
                </section>
            </div>
            <div v-if="selectedType === 'rankEditor'" class="org__info org__info--team family__rank-editor">
                <div
                    v-for="(item,index) in settingTabs"
                    :key="item.index"
                    class="family__rank-editor-item"
                    :class="{'family__rank-editor-item--opened' : item.isOpened}"
                    @click="item.settings && openSettingsTab(item)"
                >
                    <div class="org__info-member">
                        <div class="org__info-member-item">
                            <BaseButton class="org__info-member-button" glass @click.stop="editRankName(index+1)">
                                <IconEdit class="org__info-member-button-icon" />
                            </BaseButton>
                            <div class="org__info-member-name">{{ item.title }}</div>
                        </div>
                        <IconDropdown v-if="item.index != 0" class="org__info-dropdown" />
                        <BaseHaveProperty v-else :text="$t('family.full_access')" :isConditionComplete="true" />
                    </div>
                    <div @click="e => e.stopPropagation()" v-if="item.settings" class="family__rank-editor-content">
                        <div class="family__rank-editor-grid">
                            <div v-for="(setting,index2) in item.settings" :key="setting.index" class="family__settings-param" @click="activateSetting(index+1,index2+1,setting)">
                                <input type="checkbox" class="family__settings-slidebox" :checked="setting.isChecked" />
                                <div class="family__settings-param-descr">{{ setting.title }}</div>
                            </div>
                        </div>
                        <BaseButton
                            glass
                            class="family__rank-editor-button org__info-member-button"
                            :style="!item.canBeDeleted && 'opacity: .4'"
                            @click="deleteRank()"
                        >
                            {{ $t('family.delete_rank') }}
                            <IconTrash class="family__rank-editor-button-icon" />
                        </BaseButton>
                    </div>
                </div>
                <div class="family__rank-editor-item family__rank-editor-item--add" @click="addRank()">
                    {{ $t('family.add_rank') }}
                    <IconPlus />
                </div>
            </div>
            <div v-if="selectedType === 'warehouse'" class="org__info org__info--warehouse">
                <BaseHaveProperty
                    :isConditionComplete="isWarehouseAvailable"
                    :text="isWarehouseAvailable ? $t('family.warehouse_available') : $t('family.warehouse_unavailable')"
                />
                <div class="org__info-warehouse-grid">
                    <InventoryItem
                        v-for="(item, index) in warehouseItems"
                        :key="index"
                        :item="item"
                        class="org__info-warehouse-item"
                    />
                </div>
                <BaseButton @click = "SetupWarehouse" class="org__info-warehouse-button" white>
                    <IconSettingsBlack />
                    {{ $t('family.manage') }}
                </BaseButton>
            </div>
            <BlankTab
                v-if="selectedType === 'blankTemplate-1'"
                icon="task"
                :title="$t('family.blank.task.title')"
                :text="$t('family.blank.task.text')"
                textWidth="23.75"
            />
            <BlankTab
                v-if="selectedType === 'blankTemplate-2'"
                icon="flag"
                :title="$t('family.blank.estate.title')"
                :text="$t('family.blank.estate.text')"
                textWidth="31.6"
            >
                <BaseButton class="org__info-blank-button" white>
                    <IconEventBlack />
                    {{ $t('family.blank.estate.button') }}
                </BaseButton>
            </BlankTab>
            <BlankTab
                v-if="selectedType === 'blankTemplate-3'"
                icon="event"
                :title="$t('family.blank.event.title')"
                :text="$t('family.blank.event.text')"
                textWidth="31.6"
            />
            <BlankTab
                v-if="selectedType === 'blankTemplate-4'"
                icon="team"
                :title="$t('family.blank.team.title')"
            >
                <div class="org__info-member org__info-member--blank">
                    <div class="org__info-member-item">
                        <div class="org__info-member-status"></div>
                        <div class="org__info-member-id">999999</div>
                        <div class="org__info-member-info">
                            <div class="org__info-member-name">Kevin Mackalister</div>
                            <div class="org__info-member-rank">Рядовой</div>
                        </div>
                    </div>
                    <div class="org__info-member-item">
                        <div class="org__info-member-time">
                            <div class="org__info-member-text">{{ $t('family.today') }}</div>
                            <div class="org__info-member-clock">12:00</div>
                        </div>
                        <div class="org__info-member-time">
                            <div class="org__info-member-text">{{ $t('family.week') }}</div>
                            <div class="org__info-member-clock">99:00</div>
                        </div>
                        <div class="org__info-member-time">
                            <div class="org__info-member-text">был 15 часов назад</div>
                        </div>
                    </div>
                </div>
            </BlankTab>
        </div>
    </div>
</template>
<script>
import AppHeader from '@/components/AppHeader/AppHeader.vue';
import BaseTabMenu from '@/components/ui/BaseTabMenu.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import IconCheckmark from '@/components/icons/IconCheckmark.vue';
import IconLocation from '@/components/icons/IconLocation.vue';
import IconEventBlack from '@/components/popups/content/organization/icons/IconEventBlack.vue';
import IconSearch from '@/components/icons/IconSearch.vue';
import IconTimer from '@/components/icons/IconTimer.vue';
import BlankTab from '@/components/popups/content/organization/components/BlankTab.vue';
import IconRefresh from '@/components/icons/IconRefresh.vue';
import IconPlus from '@/components/icons/IconPlus.vue';
import IconEdit from '@/components/icons/IconEdit.vue';
import IconTrash from '@/components/icons/IconTrash.vue';
import BaseHaveProperty from '@/components/ui/BaseHaveProperty.vue';
import IconDropdown from '@/components/icons/IconDropdown.vue';
import { mapGetters } from 'vuex';
import InventoryItem from '@/components/popups/components/inventoryItem/InventoryItem.vue';
import IconSettingsBlack from '@/components/icons/IconSettingsBlack.vue';

export default {
    name: 'MyFamily',
    components: {
        IconSettingsBlack,
        InventoryItem,
        IconDropdown,
        BaseHaveProperty,
        IconTrash,
        IconEdit,
        IconPlus,
        IconRefresh,
        BlankTab,
        IconTimer,
        IconSearch,
        IconEventBlack,
        IconLocation,
        IconCheckmark,
        BaseButton,
        BaseTabMenu,
        AppHeader
    },
    data () {
        return {
            type: 1, // 1 === 'MyOrganization', 2 === 'MyFamily'
            selectedType: 'about',
            organization: this.$t('family.family'),
            search: '',
            statusFilter: 'Все',
            isWarehouseAvailable: true,
            rankSearch: '',
            rankFilter: 'all',
            eventsLoaded: false,
            famBusinessLoaded: false,
            eventsInterval: null,
            aboutFamily: {
                status: 'Семья',
                name: 'Ghost Bikers',
                members: 35,
                savings: 5000900
            },
            aboutOrganization: {
                id: 4,
                status: 'Организация',
                boss: 'Cillian Cortez',
                name: 'Ghost Bikers',
                members: 35,
                savings: 5000900
            },
            teamList: [
                /*
                {
                    status: false,
                    id: 1,
                    name: 'Kevin Mackalister',
                    rank: 'Рядовой',
                    today: '12:00',
                    week: '99:00',
                    lastSeen: 'был 15 часов назад'
                },
                {
                    status: true,
                    id: 2,
                    name: 'Kevin Mackalister',
                    rank: 'Рядовой',
                    today: '12:00',
                    week: '99:00',
                    lastSeen: 'был 15 часов назад'
                },
                */
            ],

            eventListOrg: [
                [// больница
                    {
                        id: 1,
                        image: 'production_medicines.jpg',
                        // time: '16:00',
                        // date: '21 марта 2023',
                        title: this.$t('family.eventList.prodMeds'),
                        description: this.$t('family.eventList.prodMedsDescription'),
                        route: 2
                    }
                ],
                [// army
                    {
                        id: 1,
                        image: 'ammunition_delivery.jpg',
                        // time: '16:00',
                        // date: '21 марта 2023',
                        title: this.$t('family.eventList.prodAmmo'),
                        description: this.$t('family.eventList.prodAmmoDescription'),
                        route: 3
                    },
                    {
                        id: 2,
                        image: 'shooting_2.jpg',
                        // time: '16:00',
                        // date: this.$t('today'),
                        title: this.$t('family.eventList.armyRob'),
                        description: this.$t('family.eventList.armyRobDescription'),
                        route: 4
                    }
                ],
                [// police
                    {
                        id: 1,
                        image: 'bank_robbery.jpg',
                        time: '16:00',
                        date: '21 марта 2023',
                        title: 'Защита банка от ограбления',
                        description: 'Мы получили сведения, что мафия планирует ограбления банка Strowbery. Вы должны предотвратить его!'
                    },
                    {
                        id: 2,
                        image: 'bank_robbery.jpg',
                        time: '16:00',
                        date: '21 марта 2023',
                        title: 'Защита банка от ограбления',
                        description: 'Мы получили сведения, что мафия планирует ограбления банка Strowbery. Вы должны предотвратить его!'
                    }
                ],
                [// fib
                    {
                        id: 1,
                        image: 'bank_robbery.jpg',
                        time: '16:00',
                        date: '21 марта 2023',
                        title: 'Защита банка от ограбления',
                        description: 'Мы получили сведения, что мафия планирует ограбления банка Strowbery. Вы должны предотвратить его!'
                    },
                    {
                        id: 2,
                        image: 'bank_robbery.jpg',
                        time: '16:00',
                        date: '21 марта 2023',
                        title: 'Защита банка от ограбления',
                        description: 'Мы получили сведения, что мафия планирует ограбления банка Strowbery. Вы должны предотвратить его!'
                    }
                ],
                [// media
                    {
                        id: 1,
                        image: 'bank_robbery.jpg',
                        time: '16:00',
                        date: '21 марта 2023',
                        title: 'Защита банка от ограбления',
                        description: 'Мы получили сведения, что мафия планирует ограбления банка Strowbery. Вы должны предотвратить его!'
                    },
                    {
                        id: 2,
                        image: 'bank_robbery.jpg',
                        time: '16:00',
                        date: '21 марта 2023',
                        title: 'Защита банка от ограбления',
                        description: 'Мы получили сведения, что мафия планирует ограбления банка Strowbery. Вы должны предотвратить его!'
                    }
                ],
                [// gov
                    {
                        id: 1,
                        image: 'bank_robbery.jpg',
                        time: '16:00',
                        date: '21 марта 2023',
                        title: 'Защита банка от ограбления',
                        description: 'Мы получили сведения, что мафия планирует ограбления банка Strowbery. Вы должны предотвратить его!'
                    },
                    {
                        id: 2,
                        image: 'bank_robbery.jpg',
                        time: '16:00',
                        date: '21 марта 2023',
                        title: 'Защита банка от ограбления',
                        description: 'Мы получили сведения, что мафия планирует ограбления банка Strowbery. Вы должны предотвратить его!'
                    }
                ],
                [// gang1==============================================================================================================================================================================================================================
                    {
                        id: 1,
                        image: 'graffiti.jpg',
                        // time: '16:00',
                        // date: '21 марта 2023',
                        title: this.$t('family.eventList.graffiti'),
                        description: this.$t('family.eventList.graffitiDescription')
                    },
                    {
                        id: 2,
                        image: 'factory_capture.jpg',
                        time: '16:00',
                        date: 'Сегодня',
                        title: this.$t('family.eventList.paintFactory'),
                        description: this.$t('family.eventList.paintFactoryDescription'),
                        route: 1
                    },
                    {
                        id: 3,
                        image: 'shooting_2.jpg',
                        // time: '16:00',
                        // date: this.$t('today'),
                        title: this.$t('family.eventList.armyRob'),
                        description: this.$t('family.eventList.armyRobDescription'),
                        route: 4
                    },
                    {
                        id: 4,
                        image: 'gang_capt.jpg',
                        // time: '16:00',
                        // date: this.$t('today'),
                        title: this.$t('family.eventList.capture'),
                        description: this.$t('family.eventList.captureDescription')
                    }
                ],
                [// gang2
                    {
                        id: 1,
                        image: 'graffiti.jpg',
                        // time: '16:00',
                        // date: '21 марта 2023',
                        title: this.$t('family.eventList.graffiti'),
                        description: this.$t('family.eventList.graffitiDescription')
                    },
                    {
                        id: 2,
                        image: 'factory_capture.jpg',
                        time: '16:00',
                        date: 'Сегодня',
                        title: this.$t('family.eventList.paintFactory'),
                        description: this.$t('family.eventList.paintFactoryDescription'),
                        route: 1
                    },
                    {
                        id: 3,
                        image: 'shooting_2.jpg',
                        // time: '16:00',
                        // date: this.$t('today'),
                        title: this.$t('family.eventList.armyRob'),
                        description: this.$t('family.eventList.armyRobDescription'),
                        route: 4
                    },
                    {
                        id: 4,
                        image: 'gang_capt.jpg',
                        // time: '16:00',
                        // date: this.$t('today'),
                        title: this.$t('family.eventList.capture'),
                        description: this.$t('family.eventList.captureDescription')
                    }
                ],
                [// gang3
                    {
                        id: 1,
                        image: 'graffiti.jpg',
                        // time: '16:00',
                        // date: '21 марта 2023',
                        title: this.$t('family.eventList.graffiti'),
                        description: this.$t('family.eventList.graffitiDescription')
                    },
                    {
                        id: 2,
                        image: 'factory_capture.jpg',
                        time: '16:00',
                        date: 'Сегодня',
                        title: this.$t('family.eventList.paintFactory'),
                        description: this.$t('family.eventList.paintFactoryDescription'),
                        route: 1
                    },
                    {
                        id: 3,
                        image: 'shooting_2.jpg',
                        // time: '16:00',
                        // date: this.$t('today'),
                        title: this.$t('family.eventList.armyRob'),
                        description: this.$t('family.eventList.armyRobDescription'),
                        route: 4
                    },
                    {
                        id: 4,
                        image: 'gang_capt.jpg',
                        // time: '16:00',
                        // date: this.$t('today'),
                        title: this.$t('family.eventList.capture'),
                        description: this.$t('family.eventList.captureDescription')
                    }
                ],
                [// gang4
                    {
                        id: 1,
                        image: 'graffiti.jpg',
                        // time: '16:00',
                        // date: '21 марта 2023',
                        title: this.$t('family.eventList.graffiti'),
                        description: this.$t('family.eventList.graffitiDescription')
                    },
                    {
                        id: 2,
                        image: 'factory_capture.jpg',
                        time: '16:00',
                        date: 'Сегодня',
                        title: this.$t('family.eventList.paintFactory'),
                        description: this.$t('family.eventList.paintFactoryDescription'),
                        route: 1
                    },
                    {
                        id: 3,
                        image: 'shooting_2.jpg',
                        // time: '16:00',
                        // date: this.$t('today'),
                        title: this.$t('family.eventList.armyRob'),
                        description: this.$t('family.eventList.armyRobDescription'),
                        route: 4
                    },
                    {
                        id: 4,
                        image: 'gang_capt.jpg',
                        // time: '16:00',
                        // date: this.$t('today'),
                        title: this.$t('family.eventList.capture'),
                        description: this.$t('family.eventList.captureDescription')
                    }
                ]
            ],
            tasksListOrg: [
                [
                    {
                        title: 'Тест',
                        text: 'Мы получили сведения, что мафия планирует ограбления банка Strowbery. Вы должны предотвратить его!',
                        isFinished: false,
                        progress: 3 // 3 из 5 выполнено
                    },
                    {
                        title: 'Предотвратите 5 ограблений длинный текст пример',
                        text: 'Мы получили сведения, что мафия планирует ограбления банка Strowbery. Вы должны предотвратить его!',
                        isFinished: false,
                        progress: 2 // 2 из 5 выполнено
                    },
                    {
                        title: 'Предотвратите 5 ограблений длинный текст пример',
                        text: 'Мы получили сведения, что мафия планирует ограбления банка Strowbery. Вы должны предотвратить его!',
                        isFinished: false,
                        progress: 1 // 1 из 5 выполнено
                    },
                    {
                        title: 'Предотвратите 5 ограблений длинный текст пример',
                        text: 'Мы получили сведения, что мафия планирует ограбления банка Strowbery. Вы должны предотвратить его!',
                        isFinished: true,
                        progress: 5 // задание выполнено
                    },
                    {
                        title: 'Предотвратите 5 ограблений длинный текст пример',
                        text: 'Мы получили сведения, что мафия планирует ограбления банка Strowbery. Вы должны предотвратить его!',
                        isFinished: false,
                        progress: 0 // еще ничего не выполнено
                    }
                ],
                [
                    {
                        title: 'Предотвратите 5 ограблений длинный текст пример',
                        text: 'Мы получили сведения, что мафия планирует ограбления банка Strowbery. Вы должны предотвратить его!',
                        isFinished: false,
                        progress: 3 // 3 из 5 выполнено
                    },
                    {
                        title: 'Предотвратите 5 ограблений длинный текст пример',
                        text: 'Мы получили сведения, что мафия планирует ограбления банка Strowbery. Вы должны предотвратить его!',
                        isFinished: false,
                        progress: 2 // 2 из 5 выполнено
                    },
                    {
                        title: 'Предотвратите 5 ограблений длинный текст пример',
                        text: 'Мы получили сведения, что мафия планирует ограбления банка Strowbery. Вы должны предотвратить его!',
                        isFinished: false,
                        progress: 1 // 1 из 5 выполнено
                    },
                    {
                        title: 'Предотвратите 5 ограблений длинный текст пример',
                        text: 'Мы получили сведения, что мафия планирует ограбления банка Strowbery. Вы должны предотвратить его!',
                        isFinished: true,
                        progress: 5 // задание выполнено
                    },
                    {
                        title: 'Предотвратите 5 ограблений длинный текст пример',
                        text: 'Мы получили сведения, что мафия планирует ограбления банка Strowbery. Вы должны предотвратить его!',
                        isFinished: false,
                        progress: 0 // еще ничего не выполнено
                    }
                ],
                [
                    {
                        title: 'Предотвратите 5 ограблений длинный текст пример',
                        text: 'Мы получили сведения, что мафия планирует ограбления банка Strowbery. Вы должны предотвратить его!',
                        isFinished: false,
                        progress: 3 // 3 из 5 выполнено
                    },
                    {
                        title: 'Предотвратите 5 ограблений длинный текст пример',
                        text: 'Мы получили сведения, что мафия планирует ограбления банка Strowbery. Вы должны предотвратить его!',
                        isFinished: false,
                        progress: 2 // 2 из 5 выполнено
                    },
                    {
                        title: 'Предотвратите 5 ограблений длинный текст пример',
                        text: 'Мы получили сведения, что мафия планирует ограбления банка Strowbery. Вы должны предотвратить его!',
                        isFinished: false,
                        progress: 1 // 1 из 5 выполнено
                    },
                    {
                        title: 'Предотвратите 5 ограблений длинный текст пример',
                        text: 'Мы получили сведения, что мафия планирует ограбления банка Strowbery. Вы должны предотвратить его!',
                        isFinished: true,
                        progress: 5 // задание выполнено
                    },
                    {
                        title: 'Предотвратите 5 ограблений длинный текст пример',
                        text: 'Мы получили сведения, что мафия планирует ограбления банка Strowbery. Вы должны предотвратить его!',
                        isFinished: false,
                        progress: 0 // еще ничего не выполнено
                    }
                ]
            ],

            eventList: [
                {
                    id: 1,
                    image: 'arabic_town.jpg',
                    time: '16:00',
                    date: this.$t('today'),
                    title: this.$t('arabicTownTitle'),
                    description: this.$t('arabicTownDescription'),
                    route: 1
                },
                {
                    id: 2,
                    image: 'shooting_1.jpg',
                    time: '16:00',
                    date: this.$t('today'),
                    title: this.$t('bizWarTitle'),
                    description: this.$t('bizWarDescription'),
                    route: 2
                },
                {
                    id: 3,
                    image: 'family_attack.jpg',
                    // time: '16:00',
                    date: this.$t('today'),
                    title: this.$t('famRaidTitle'),
                    description: this.$t('famRaidDescription')
                }
            ],
            tasksList: [
                {
                    title: 'Предотвратите 5 ограблений длинный текст пример',
                    text: 'Мы получили сведения, что мафия планирует ограбления банка Strowbery. Вы должны предотвратить его!',
                    isFinished: false,
                    progress: 3 // 3 из 5 выполнено
                },
                {
                    title: 'Предотвратите 5 ограблений длинный текст пример',
                    text: 'Мы получили сведения, что мафия планирует ограбления банка Strowbery. Вы должны предотвратить его!',
                    isFinished: false,
                    progress: 2 // 2 из 5 выполнено
                },
                {
                    title: 'Предотвратите 5 ограблений длинный текст пример',
                    text: 'Мы получили сведения, что мафия планирует ограбления банка Strowbery. Вы должны предотвратить его!',
                    isFinished: false,
                    progress: 1 // 1 из 5 выполнено
                },
                {
                    title: 'Предотвратите 5 ограблений длинный текст пример',
                    text: 'Мы получили сведения, что мафия планирует ограбления банка Strowbery. Вы должны предотвратить его!',
                    isFinished: true,
                    progress: 5 // задание выполнено
                },
                {
                    title: 'Предотвратите 5 ограблений длинный текст пример',
                    text: 'Мы получили сведения, что мафия планирует ограбления банка Strowbery. Вы должны предотвратить его!',
                    isFinished: false,
                    progress: 0 // еще ничего не выполнено
                }
            ],
            /*
            ranksList: [
                {
                    status: false,
                    id: 1,
                    name: 'Kevin Mackalister',
                    rank: 'Рядовой'
                }
            ],
            */
            estateList: [
                {
                    ID: 1,
                    isCaptured: true,
                    title: 'familyBusinesses.casino',
                    profit: 5000,
                    // lastTime: '2 часа назад',
                    // captureDate: '25.03.2021',
                    image: 'capture-building-1.jpg'
                },
                {
                    ID: 2,
                    title: 'familyBusinesses.BizCenter',
                    profit: 5000,
                    // lastTime: '2 часа назад',
                    // captureDate: '25.03.2021',
                    image: 'capture-building-2.jpg'
                },
                {
                    ID: 3,
                    title: 'familyBusinesses.Monument',
                    profit: 5000,
                    // lastTime: '2 часа назад',
                    // captureDate: '25.03.2021',
                    image: 'capture-building-3.jpg'
                },
                {
                    ID: 4,
                    isCaptured: true,
                    title: 'familyBusinesses.RadioStation',
                    profit: 5000,
                    // lastTime: '2 часа назад',
                    // captureDate: '25.03.2021',
                    image: 'capture-building-4.jpg'
                },
                {
                    ID: 5,
                    title: 'familyBusinesses.Mosque',
                    profit: 5000,
                    // lastTime: '2 часа назад',
                    // captureDate: '25.03.2021',
                    image: 'capture-building-5.jpg'
                }
            ],
            blacklist: [
                /*
                { id: 1, name: 'Kevin Mackalister', rank: 'Рядовой' },
                { id: 2, name: 'John Doe', rank: 'Сержант' },
                { id: 3, name: 'Jane Smith', rank: 'Рядовой' }
                */
            ],
            logs: [
                /*
                {
                    id: 1,
                    name: 'Kevin Mackalister',
                    rank: 'Рядовой',
                    type: 'Took',
                    action: 'Took from the warehouse 1 Balaclava',
                    timestamp: '18:05 12.04.2023'
                },
                {
                    id: 2,
                    name: 'John Doe',
                    rank: 'Сержант',
                    type: 'Replenished',
                    action: 'Replenished 2 Medkits',
                    timestamp: '19:30 12.04.2023'
                }
                */
            ],
            searchLogs: '',
            selectedAction: 'all',
            newMemberName: '',
            tabDefinitions: [

                { type: 'about', text: this.$t('family.tabs.about'), icon: 'about' },
                { type: 'events', text: this.$t('family.tabs.events'), icon: 'event' },
                { type: 'team', text: this.$t('family.tabs.team'), icon: 'team' },
                { type: 'estates', text: this.$t('family.tabs.estates'), icon: 'flag' }, //, isNew: true
                { type: 'tasks', text: this.$t('family.tabs.tasks'), icon: 'task' },
                { type: 'warehouse', text: this.$t('family.tabs.warehouse'), icon: 'warehouse' },
                // { type: 'rating', text: this.$t('family.tabs.rating'), icon: 'rating' },
                {
                    type: 'settingsSubmenu',
                    text: this.$t('family.tabs.settings.text'),
                    icon: 'settings',
                    backButtonText: this.$t('family.tabs.settings.exit'),
                    submenu: [
                        { type: 'settings', text: this.$t('family.tabs.settings.settings'), icon: 'settings' },
                        { type: 'ranks', text: this.$t('family.tabs.settings.ranks'), icon: 'team' },
                        { type: 'blacklist', text: this.$t('family.tabs.settings.blacklist'), icon: 'blocked' },
                        { type: 'rankEditor', text: this.$t('family.tabs.settings.rank_editor'), icon: 'ranks' },
                        { type: 'logs', text: this.$t('family.tabs.settings.logs'), icon: 'list' }
                    ]
                }
                // { type: 'lorem', text: 'Тест поле для скролла и будущих полей' }

            ],
            settingTabs: [
                /*
                { title: 'Владелец', settings: null },
                {
                    title: 'Заместитель директора',
                    isOpened: false,
                    canBeDeleted: false,
                    settings: [
                        { title: 'Приглашать в семью', isChecked: true },
                        { title: 'Увольнять из семьи', isChecked: true }
                    ]
                },
                {
                    title: 'Рядовой',
                    isOpened: false,
                    canBeDeleted: true,
                    settings: [
                        { title: 'Приглашать в семью', isChecked: true },
                        { title: 'Увольнять из семьи', isChecked: true }
                    ]
                }
                */
            ],
            familyButtons: [
                { title: this.$t('deleteFam'), icon: 'refresh' }
                // { title: this.$t('family.actions.spawn_transport'), icon: 'refresh' },
                // { title: this.$t('family.actions.give_award'), icon: 'plus' }
            ],
            familySettings: [
                /*
                { title: 'Дом закрыт', isChecked: false },
                { title: 'Показывать баланс организации', isChecked: true },
                { title: 'Показывать семью на карте', isChecked: true },
                { title: 'Открыть набор', isChecked: false }
                */
            ],
            warehouseItems: [
                /*
                { id: 1, name: 'Аптечка', rarity: 'unusual', type: 3, filterType: 'food', desc: 'Восстанавливает здоровье персонажа на 50 единиц', img: 'other_items/first_kit', count: 999 },
                { id: 2, name: 'Аптечка', rarity: 'rare', type: 3, filterType: 'food', desc: 'Восстанавливает здоровье персонажа на 50 единиц', img: 'other_items/first_kit', count: 999 },
                { id: 3, name: 'Аптечка', rarity: 'rare', type: 3, filterType: 'food', desc: 'Восстанавливает здоровье персонажа на 50 единиц', img: 'other_items/first_kit', count: 9929 },
                { id: 4, name: 'Аптечка', rarity: 'unusual', type: 3, filterType: 'food', desc: 'Восстанавливает здоровье персонажа на 50 единиц', img: 'other_items/first_kit', count: 999 },
                { id: 5, name: 'Аптечка', rarity: 'rare', type: 3, filterType: 'food', desc: 'Восстанавливает здоровье персонажа на 50 единиц', img: 'other_items/first_kit', count: 999 },
                { id: 6, name: 'Аптечка', rarity: 'rare', type: 3, filterType: 'food', desc: 'Восстанавливает здоровье персонажа на 50 единиц', img: 'other_items/first_kit', count: 9929 },
                { id: 7, name: 'Аптечка', rarity: 'unusual', type: 3, filterType: 'food', desc: 'Восстанавливает здоровье персонажа на 50 единиц', img: 'other_items/first_kit', count: 999 },
                { id: 8, name: 'Аптечка', rarity: 'rare', type: 3, filterType: 'food', desc: 'Восстанавливает здоровье персонажа на 50 единиц', img: 'other_items/first_kit', count: 999 },
                { id: 9, name: 'Аптечка', rarity: 'rare', type: 3, filterType: 'food', desc: 'Восстанавливает здоровье персонажа на 50 единиц', img: 'other_items/first_kit', count: 999 },
                { id: 10, name: 'Аптечка', rarity: 'rare', type: 3, filterType: 'food', desc: 'Восстанавливает здоровье персонажа на 50 единиц', img: 'other_items/first_kit', count: 9929 }
                 */
            ],
            gradient: [
                {
                    startColor: '#3D62DB',
                    finishColor: '#2926A4'
                },
                {
                    startColor: '#35717B',
                    finishColor: '#321959'
                }
            ],
            orgButtons: [
                [// больница
                    { title: this.$t('family.actions.openKPK'), icon: 'refresh' }
                ],
                [// армия
                    { title: this.$t('family.actions.openKPK'), icon: 'refresh' },
                    { title: this.$t('orderAmmo'), icon: 'refresh' },
                    { title: this.$t('orderMedKits'), icon: 'refresh' }
                ],
                [// полиция
                    { title: this.$t('family.actions.openKPK'), icon: 'refresh' },
                    { title: this.$t('orderAmmo'), icon: 'refresh' },
                    { title: this.$t('orderMedKits'), icon: 'refresh' }
                ],
                [// фбр
                    { title: this.$t('family.actions.openKPK'), icon: 'refresh' },
                    { title: this.$t('orderAmmo'), icon: 'refresh' },
                    { title: this.$t('orderMedKits'), icon: 'refresh' }
                ],
                [// СМИ
                    { title: this.$t('family.actions.openKPK'), icon: 'refresh' }
                ],
                [// правитльество
                    { title: this.$t('orderAmmo'), icon: 'refresh' },
                    { title: this.$t('orderMedKits'), icon: 'refresh' }
                ],
                [// банды
                    { title: this.$t('ghettoMap'), icon: 'refresh' }
                ],
                [// банды
                    { title: this.$t('ghettoMap'), icon: 'refresh' }
                ],
                [// банды
                    { title: this.$t('ghettoMap'), icon: 'refresh' }
                ],
                [// банды
                    { title: this.$t('ghettoMap'), icon: 'refresh' }
                ]
            ]
        };
    },
    mounted () {
    },
    beforeDestroy () {
        if (this.eventsInterval !== null) {
            clearInterval(this.eventsInterval);
            this.eventsInterval = null;
        }
    },
    computed: {
        ...mapGetters('inventory', ['inventoryItemsPool']),
        currentEvents () {
            return this.eventListOrg[this.aboutOrganization.id - 1];
        },
        currentTasks () {
            return this.tasksListOrg[this.aboutOrganization.id - 1];
        },
        currentButtons () {
            return this.orgButtons[this.aboutOrganization.id - 1];
        },
        filteredTeamList () {
            let statusFilterInt = null;
            if (this.statusFilter === 'Онлайн' || this.rankFilter === 'online') {
                statusFilterInt = 1;
            } else if (this.statusFilter === 'Оффлайн' || this.rankFilter === 'offline') {
                statusFilterInt = 0;
            }

            return this.teamList.filter(member => {
                return member.name.toLowerCase().includes(this.search.toLowerCase()) &&
                    (statusFilterInt === null || statusFilterInt === member.status);
            });
        },
        /*
        filteredRanksList () {
            let statusFilterBool = null;
            if (this.rankFilter === 'online') {
                statusFilterBool = true;
            } else if (this.rankFilter === 'offline') {
                statusFilterBool = false;
            }

            return this.ranksList.filter(member => {
                return member.name.toLowerCase().includes(this.rankSearch.toLowerCase()) &&
                    (statusFilterBool === null || statusFilterBool === member.status);
            });
        },
        */
        filteredLogs () {
            const search = this.searchLogs.toLowerCase();
            const action = this.selectedAction;

            return this.logs.filter((member) => {
                const nameMatch = member.name.toLowerCase().includes(search);
                const actionMatch =
                    action === 'all' || member.type.toLowerCase().includes(action);

                return nameMatch && actionMatch;
            });
        },
        sortedEstateList () {
            return this.estateList.slice().sort((a, b) => {
                return a.isCaptured === b.isCaptured ? 0 : a.isCaptured ? -1 : 1;
            }).map(item => ({
                ...item,
                title: this.$t(item.title)
            }));
        }
    },
    methods: {
        /*
        window.modifyVueComponent({
            App: {
                showPopup: true,
                popupContent: 'MyFamily',
                contentSubFolder: 'organization'
            },
            MyFamily:{
                initialize:[
                    'Grand',
                    30,
                    5000000,
                ]
            }
        });
        */
        initialize (name, count, price) {
            console.log('initialize');
            this.eventsLoaded = false;
            this.type = 2;
            this.aboutFamily.name = name;
            this.aboutFamily.members = count;
            this.aboutFamily.savings = price;
            //
            this.tabDefinitions = [
                { type: 'about', text: this.$t('family.tabs.about'), icon: 'about' },
                { type: 'events', text: this.$t('family.tabs.events'), icon: 'event' },
                { type: 'team', text: this.$t('family.tabs.team'), icon: 'team' },
                { type: 'estates', text: this.$t('family.tabs.estates'), icon: 'flag' }, //, isNew: true
                { type: 'tasks', text: this.$t('family.tabs.tasks'), icon: 'task' },
                { type: 'warehouse', text: this.$t('family.tabs.warehouse'), icon: 'warehouse' },
                // { type: 'rating', text: this.$t('family.tabs.rating'), icon: 'rating' },
                {
                    type: 'settingsSubmenu',
                    text: this.$t('family.tabs.settings.text'),
                    icon: 'settings',
                    backButtonText: this.$t('family.tabs.settings.exit'),
                    submenu: [
                        { type: 'settings', text: this.$t('family.tabs.settings.settings'), icon: 'settings' },
                        { type: 'ranks', text: this.$t('family.tabs.settings.ranks'), icon: 'team' },
                        { type: 'blacklist', text: this.$t('family.tabs.settings.blacklist'), icon: 'blocked' },
                        { type: 'rankEditor', text: this.$t('family.tabs.settings.rank_editor'), icon: 'ranks' },
                        { type: 'logs', text: this.$t('family.tabs.settings.logs'), icon: 'list' }
                    ]
                }
                // { type: 'lorem', text: 'Тест поле для скролла и будущих полей' }
            ];
        },
        /*
        window.modifyVueComponent({
            App: {
                showPopup: true,
                popupContent: 'MyFamily',
                contentSubFolder: 'organization'
            },
            MyFamily:{
                initializeOrg:[
                    7,
                    'Napal',
                    20,
                    10000
                ]
            }
        });
        */
        initializeOrg (id, boss, members, savings) {
            console.log('initializeOrg');
            this.eventsLoaded = false;
            this.type = 1;
            if (boss === '')boss = this.$t('pda.absent');
            this.organization = this.$t('org.org');
            this.aboutOrganization.id = id;
            this.aboutOrganization.boss = boss;
            // this.aboutOrganization.name = name;
            this.aboutOrganization.members = members;
            this.aboutOrganization.savings = savings;
            //
            this.tabDefinitions = [
                { type: 'about', text: this.$t('org.tabs.about'), icon: 'about' },
                { type: 'events', text: this.$t('family.tabs.events'), icon: 'event' },
                { type: 'team', text: this.$t('family.tabs.team'), icon: 'team' },
                { type: 'tasks', text: this.$t('family.tabs.tasks'), icon: 'task' },
                { type: 'warehouse', text: this.$t('org.tabs.warehouse'), icon: 'warehouse' },
                // { type: 'rating', text: this.$t('family.tabs.rating'), icon: 'rating' },
                {
                    type: 'settingsSubmenu',
                    text: this.$t('family.tabs.settings.text'),
                    icon: 'settings',
                    backButtonText: this.$t('family.tabs.settings.exit'),
                    submenu: [
                        { type: 'settings', text: this.$t('org.tabs.settings'), icon: 'settings' },
                        { type: 'ranks', text: this.$t('family.tabs.settings.ranks'), icon: 'team' },
                        { type: 'blacklist', text: this.$t('family.tabs.settings.blacklist'), icon: 'blocked' },
                        { type: 'rankEditor', text: this.$t('family.tabs.settings.rank_editor'), icon: 'ranks' },
                        { type: 'logs', text: this.$t('family.tabs.settings.logs'), icon: 'list' }
                    ]
                }
                // { type: 'lorem', text: 'Тест поле для скролла и будущих полей' }
            ];
            if (this.aboutOrganization.id === 5) { // СМИ
                this.tabDefinitions.splice(4, 1);
            }
        },
        /*
        aboutOrganization: {
            id: 3,
            status: 'Семья',
            boss: 'Cillian Cortez',
            name: 'Ghost Bikers',
            members: 35,
            savings: 5000900
        },
        */
        updateMembers (members) {
            this.teamList.push(...members);
        },
        updateLogs (logs) {
            this.logs.push(...logs);
        },
        updateBlacklist (blacklist) {
            this.blacklist.push(...blacklist);
        },
        addBlacklistName (name) {
            this.blacklist.push({ name });
        },
        /*
        window.callVueMethod('MyFamily','updatefamRanks', [{"settings":[{"isChecked":true,"title":"Invite into the family"},{"isChecked":false,"title":"Dismissed from the family"}],"title":"первый ранг","canBeDeleted":true,"isOpened":false},{"settings":[{"isChecked":false,"title":"Invite into the family"},{"isChecked":true,"title":"Dismissed from the family"}],"title":"Второй ранг","canBeDeleted":true,"isOpened":false},{"settings":[{"isChecked":false,"title":"Invite into the family"},{"isChecked":false,"title":"Dismissed from the family"}],"title":"босс","canBeDeleted":true,"isOpened":false},{"settings":[{"isChecked":false,"title":"Invite into the family"},{"isChecked":false,"title":"Dismissed from the family"}],"title":"4","canBeDeleted":true,"isOpened":false},{"title":"5","canBeDeleted":true,"isOpened":false}]);
        */
        updatefamRanks (ranks) {
            this.settingTabs = ranks;
        },
        UnBlacklistName (name) {
            const index = this.blacklist.findIndex(item => item.name === name);
            if (index !== -1) {
                this.blacklist.splice(index, 1);
            }
        },
        updateRankName (id, name) {
            if (this.settingTabs[id - 1]) this.settingTabs[id - 1].title = name;
        },
        AddRankName (name) {
            const maxCount = this.settingTabs.length;
            this.settingTabs[maxCount] = Object.assign({}, this.settingTabs[maxCount - 2]);
            this.settingTabs[maxCount].title = name;
            this.settingTabs[maxCount].settings = undefined;
            this.settingTabs[maxCount - 1].settings = [];// Object.assign({}, this.settingTabs[maxCount - 2].settings);
            for (let i = 0; i < this.settingTabs[maxCount - 2].settings.length; i++) {
                this.settingTabs[maxCount - 1].settings[i] = {
                    title: this.settingTabs[maxCount - 2].settings[i].title,
                    isChecked: false
                };
                // this.settingTabs[maxCount - 1].settings[i].isChecked = false;
            }
            // rerender
            const lastType = this.selectedType;
            this.selectedType = '';
            this.selectedType = lastType;
        },
        DeleteRank () {
            this.settingTabs.pop();
            const newLength = this.settingTabs.length;
            this.settingTabs[newLength - 1].settings = undefined;
        },
        // window.callVueMethod('MyFamily','updateMembers', [{"name":"Napal Fedorov","id":2,"status":1,"lastSeen":"Active now","rank":"4"}]);
        updatePlayerRank (id, rankName) {
            const foundedIndex = this.teamList.findIndex(item => item.id === id);
            if (foundedIndex !== -1) {
                if (!rankName) this.teamList.splice(foundedIndex, 1);
                else this.teamList[foundedIndex].rank = rankName;
            }
            /*
            ranksList: [
                {
                    status: false,
                    id: 1,
                    name: 'Kevin Mackalister',
                    rank: 'Рядовой'
                }
            ],
            */
        },
        updateStatus (status) {
            this.isWarehouseAvailable = status;
        },
        updateWarehouse (state, itemList) {
            this.isWarehouseAvailable = state;
            this.warehouseItems = new Array(itemList.length);// [];
            for (let i = 0; i < itemList.length; i++) {
                if (itemList[i].itemID) {
                    this.warehouseItems[i] = Object.assign({}, this.inventoryItemsPool[itemList[i].itemID - 1]);
                    this.warehouseItems[i].count = itemList[i].count;
                    this.warehouseItems[i].index = i + 1;
                    this.warehouseItems[i].additional = itemList[i].additional;
                } else this.warehouseItems[i] = { id: null, name: '', rarity: '', filterType: '', desc: '', img: '', count: 0, index: i + 1 };
            }
        },
        changeTab (tabType) {
            // console.log(tabType);
            if ((tabType === 'team' || tabType === 'ranks') && !this.teamList.length) {
                if (this.type === 1) this.sendclient('LoadOrgMembers');
                else this.sendclient('LoadFamMembers');
            } else if (tabType === 'estates' && !this.famBusinessLoaded) {
                this.sendclient('LoadFamEstates');
                return;
            } else if (tabType === 'warehouse' && !this.warehouseItems.length) {
                if (this.type === 1) this.sendclient('LoadOrgWarehouse');
                else this.sendclient('LoadFamWarehouse');
            } else if (tabType === 'blacklist' && !this.blacklist.length) {
                if (this.type === 1) this.sendclient('LoadOrgBlackList');
                else this.sendclient('LoadFamBlackList');
            } else if (tabType === 'logs' && !this.logs.length) {
                if (this.type === 1) this.sendclient('LoadOrgLogs');
                else this.sendclient('LoadFamLogs');
            } else if (tabType === 'rankEditor' && !this.settingTabs.length) {
                if (this.type === 1) this.sendclient('LoadOrgRanks');
                else this.sendclient('LoadFamRanks');
            } else if (tabType === 'events' && !this.eventsLoaded) {
                if (this.type === 1) this.sendclient('LoadOrgEvents');
                else this.sendclient('LoadFamEvents');
                return;
            }
            this.selectedType = tabType;
        },
        updateFamBusiness (business) {
            for (let i = 0; i < this.estateList.length; i++) {
                this.estateList[i].isCaptured = business[i].own;
            }
            this.famBusinessLoaded = true;
            this.selectedType = 'estates';
        },
        InitEvents (eventList) {
            let currentEventList;
            if (this.type === 1) {
                currentEventList = this.eventListOrg[this.aboutOrganization.id - 1];
            } else currentEventList = this.eventList;

            this.eventsLoaded = true;
            if (this.eventsInterval !== null) {
                clearInterval(this.eventsInterval);
                this.eventsInterval = null;
            }
            if (eventList && eventList.length > 0) {
                this.eventsInterval = setInterval(() => {
                    for (let i = 0; i < eventList.length; i++) {
                        if (eventList[i].time) {
                            eventList[i].time--;
                            currentEventList[eventList[i].id - 1].time = this.formatTime(eventList[i].time);
                        }
                    }
                }, 1000);
            }

            this.selectedType = 'events';
        },
        formatTime (seconds) {
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            const remainingSeconds = seconds % 60;

            const formattedHours = hours < 10 ? `0${hours}` : hours;
            const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
            const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;

            if (hours > 0) return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
            else return `${formattedMinutes}:${formattedSeconds}`;
        },
        SetupWarehouse () {
            if (this.type === 1) this.sendclient('SetupOrgWarehouse');
            else this.sendclient('SetupFamWarehouse');
        },
        ActionButton (index) {
            if (this.type === 1) this.sendclient('OrgAction', JSON.stringify({ a: index }));
            else this.sendclient('FamilyAction', JSON.stringify({ a: index }));
        },
        openSettingsTab (item) {
            if (item.isOpened === undefined) return;
            item.isOpened = !item.isOpened;
        },
        activateSetting (index, index2, item) {
            item.isChecked = !item.isChecked;
            if (this.type === 1) this.sendclient('OrgRankOption', JSON.stringify({ a: index, b: index2, c: item.isChecked }));
            else this.sendclient('FamilyRankOption', JSON.stringify({ a: index, b: index2, c: item.isChecked }));
        },
        eventRoute (id) {
            if (this.type === 1) this.sendclient('OrgEventRoute', JSON.stringify({ a: id }));
            else this.sendclient('FamEventRoute', JSON.stringify({ a: id }));
        },
        handleReward (taskIndex) {
            console.log(this.tasksList[taskIndex].title);
        },
        handleEstateLocation (estateIndex) {
            // console.log(this.estateList[estateIndex].title);
            this.sendclient('familyBizFind', JSON.stringify({ a: this.estateList[estateIndex].ID }));
        },
        giveReward (id) {
            if (this.type === 1) this.sendclient('OrgPremia', JSON.stringify({ a: id }));
            else this.sendclient('FamPremia', JSON.stringify({ a: id }));
        },
        changeRank (id) {
            if (this.type === 1) this.sendclient('OrgChangeRank', JSON.stringify({ a: id }));
            else this.sendclient('FamChangeRank', JSON.stringify({ a: id }));
        },
        removeMember (name) {
            if (this.type === 1) this.sendclient('OrgUnBlackList', JSON.stringify({ a: name }));
            else this.sendclient('FamUnBlackList', JSON.stringify({ a: name }));
        },
        addMember () {
            if (!this.newMemberName || this.newMemberName.length < 3) return;
            if (this.type === 1) this.sendclient('OrgAddBlackList', JSON.stringify({ a: this.newMemberName }));
            else this.sendclient('FamAddBlackList', JSON.stringify({ a: this.newMemberName }));
            /*
            const newMember = {
                id: this.blacklist.length + 1,
                name: this.newMemberName,
                rank: 'Рядовой'
            };
            this.blacklist.push(newMember);
            */
            this.newMemberName = '';
        },
        deleteRank () {
            if (this.type === 1) this.sendclient('OrgDeleteRank');
            else this.sendclient('FamDeleteRank');
            /*
            if (rank.canBeDeleted) {
                const index = this.settingTabs.indexOf(rank);
                this.settingTabs.splice(index, 1);
            }
            */
        },
        addRank () {
            if (this.type === 1) this.sendclient('OrgAddRank');
            else this.sendclient('FamAddRank');
        },
        editRankName (index) {
            if (this.type === 1) this.sendclient('OrgChangeRankName', JSON.stringify({ a: index }));
            else this.sendclient('FamChangeRankName', JSON.stringify({ a: index }));
        },
        scrollTrigger ({ target: { scrollTop, clientHeight, scrollHeight } }) {
            if (scrollTop + clientHeight >= scrollHeight - 1) {
                console.log('Мы внизу списка');
                // #todo сделать дозагрузку участников/логов
            }
        }
    }
};
</script>
