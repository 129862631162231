<template>
    <ul>
        <li v-for="item in array" :key="item.index" :class="{ complete: item?.isComplete}">
            <h3><IconCheckmark fill="#27AE60" v-if="item.isComplete"/> {{ item.title}}
                <span v-if="item.finish > 0">{{ item.start}} / {{ item.finish }}</span>
            </h3>
            <p>{{ item.text }}</p>
            <IconBorder icon="left" :fill="item?.isComplete ? '#27AE60' : '#B784C9'"/>
        </li>
    </ul>
</template>

<script>
import IconBorder from '@/components/popups/content/works/icons/IconBorder.vue';
import IconCheckmark from '@/components/icons/IconCheckmark.vue';

export default {
    name: 'PhoneTaskList',
    components: { IconCheckmark, IconBorder },
    props: {
        array: Array
    }
};
</script>

<style lang="scss" scoped>
ul{
    padding: 90px 0;
    overflow-y: auto;
    height: calc(100vh - 5vw);

    &::-webkit-scrollbar{
        width: 0;
        height: 0;
        display: none;
    }
}

li{
    padding: 32px 0px 32px 64px;
    position: relative;
    margin-bottom: 18px;
    overflow: hidden;
    background: linear-gradient(90deg, rgba(241, 241, 241, 0.12) 0%, rgba(241, 241, 241, 0.00) 100%);
    &:last-child {
        margin-bottom: 0;
    }
    .svg{
        transform: rotate(90deg);
        left: 0;
        height: 100%;
        top: 0;
        bottom: 0;
        position: absolute;
    }

    h3{
        color: #B784C9;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        letter-spacing: 1.92px;
        text-transform: uppercase;
        margin-bottom: 24px;

        display: flex;
        align-items: center;

        svg{
            width: 38px;
            height: 38px;
            margin-right: 12px;
        }
    }

    &.complete{
        background: linear-gradient(90deg, rgba(39, 174, 96, 0.24) 0%, rgba(39, 174, 96, 0.00) 100%);
        h3{
            color: #27AE60;
        }
    }

    span{
        color: rgba(241, 241, 241, 0.60);
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        letter-spacing: 1.92px;
        text-transform: uppercase;
        position: absolute;
        right: 29px;
    }

    p{
        color: #F1F1F1;
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
    }
}
</style>
