<template>
    <section class="get-item">
        <div class="get-item__title">{{ $t('get-item.title') }}</div>
        <div class="get-item__items">
            <div class="get-item__item" v-for="(item, index) in items" :key="index" >
                <img :src="imageSource(`${item.img}.png`, 'case')" alt="">
            </div>
        </div>
        <div class="get-item__value">
            <button class="get-item__plus" @click="getPlus"><IconPlus /></button>
            <span v-if="money" class="get-item__value-item get-item__money"><span>$</span> {{ $formatNum(money) }}</span>
            <span v-if="donate" class="get-item__value-item get-item__donate"><IconDonate />{{ $formatNum(donate) }}</span>
        </div>
        <BaseButton yellow @click="getItem" class="get-item__btn">
            <IconCheckmark />
            {{ $t('get-item.take') }}
        </BaseButton>
    </section>
</template>

<script>
import BaseButton from '@/components/ui/BaseButton.vue';
import IconPlus from '@/components/icons/IconPlus.vue';
import IconDonate from '@/components/icons/IconDonate.vue';
import IconCheckmark from '@/components/icons/IconCheckmark.vue';

export default {
    name: 'GetItem',
    components: { IconCheckmark, IconDonate, IconPlus, BaseButton },
    data () {
        return {
            items: [
                { id: 1, img: '5' },
                { id: 2, img: '2' },
                { id: 3, img: '3' }
            ],
            donate: 500,
            money: 20000
        };
    },
    methods: {
        getPlus () {
            console.log('getPlus');
        },
        getItem () {
            console.log('getItem');
        }
    }
};
</script>

<style lang="scss">
.get-item{
    background: url(@/assets/images/information/bg-get-item.svg) no-repeat center / cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;

    &__title{
        color: #F1F1F1;
        text-align: center;
        font-size: 90px;
        font-weight: 300;
        margin-bottom: 64px;
        line-height: 120%;
    }

    &__items{
        display: flex;
        margin-bottom: 24px;

        img{
            width: 400px;
            height: 400px;
            margin: 0 12px;
        }
    }

    &__plus{
        background: #9FD035;
        width: 104px;
        height: 104px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg{
            path{
                fill: #141A1E;
                width: 72px;
                height: 72px;
            }
        }
    }

    &__btn{
        svg{
            width: 62px;
            height: 62px;
            margin-right: 18px;

            path{
                fill: #141A1E;
            }
        }
    }

    &__value{
        display: grid;
        grid-template-columns: repeat(3, max-content);
        grid-gap: 24px;
        margin-bottom: 64px;
        align-items: center;

        &-item{
            border-radius: 6px;
            background: #141A1E;
            padding: 16px;
            height: 104px;
            display: flex;
            align-items: center;

            color: #F1F1F1;
            font-size: 80px;
            font-weight: 700;
            line-height: 100%; /* 80px */
            letter-spacing: -1.6px;

            span{
                color: #FFCD4C;
                margin-right: 4px;
            }

            svg{
                width: 68px;
                height: 68px;
                margin-right: 10px;
            }

        }
    }
}
</style>
