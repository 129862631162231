<template>
    <div class="event-season">
        <AppHeader back-button show-event>
            <template #title>{{ eventName }}</template>
        </AppHeader>
        <div class="showroom__top">
            <div class="showroom__top-nav">
                <button class="showroom__top-button showroom__top-button--left"
                        @click="selectedScreen = 1"
                        :class="{'showroom__top-button--active': selectedScreen === 1}"
                >
                    {{ $t('season_event.info') }}
                </button>
                <div class="showroom__top-divider"></div>
                <button class="showroom__top-button showroom__top-button--right"
                        :class="{'showroom__top-button--active': selectedScreen === 2}"
                        @click="selectedScreen = 2">{{ $t('season_event.gifts') }}</button>
            </div>
        </div>
        <div class="event-info" v-if="selectedScreen === 1">
            <img src="./assets/images/bg-right-girl.png" class="event-info__girl" alt="" />
            <div v-for="(item, index) in tasks" :key="index" class="event-info__item">
                <div class="event-info__item-title">
                    {{ item.title }}
                </div>
                <div class="event-info__item-text">
                    {{ item.info }}
                </div>
                <div class="event-info__item-badge">
                    <div class="event-info__item-badge-text event-info__item-badge-text--plus">
                        +
                    </div>
                    <IconEvents class="event-info__item-badge-icon" />
                    <div class="event-info__item-badge-text">{{ item.profit }}</div>
                </div>
                <BaseButton @click="setLocation(item.index)" class="event-info__item-button" v-if="item.buttonType === 1" yellow>
                    <IconEventMap />
                    <div class="event-info__item-button-text">{{ $t('season_event.route') }}</div>
                </BaseButton>
                <BaseButton @click="sell(item)" class="event-info__item-button" v-else yellow>
                    <IconEventBuy />
                    <div class="event-info__item-button-text">{{ $t('season_event.sell') }}</div>
                </BaseButton>
            </div>
            <div class="event-info__tooltip">
                <template v-if="totalSeconds > 0">
                    <div class="event-info__tooltip-text">
                        {{ $t('season_event.tooltip.before') }} <span>{{ $t('season_event.tooltip.free') }}</span> {{ $t('season_event.tooltip.shells') }}
                    </div>
                    <div class="event-info__timer">
                        <span>{{ Math.floor(totalSeconds / 3600 / 10) }}</span>
                        <span>{{ Math.floor(totalSeconds / 3600) % 10 }}</span>
                        <i>:</i>
                        <span>{{ Math.floor((totalSeconds % 3600) / 60 / 10) }}</span>
                        <span>{{ Math.floor((totalSeconds % 3600) / 60) % 10 }}</span>
                        <i>:</i>
                        <span>{{ Math.floor(totalSeconds % 60 / 10) }}</span>
                        <span>{{ totalSeconds % 60 % 10 }}</span>
                    </div>
                </template>
                <BaseButton @click="takeFreeShells" class="event-info__tooltip-button" yellow v-else>
                    {{ $t('season_event.take_reward') }}
                </BaseButton>
                <div class="event-info__item-badge event-info__tooltip-badge">
                    <div class="event-info__item-badge-text event-info__item-badge-text--plus">
                        +
                    </div>
                    <IconEvents size="l" class="event-info__item-badge-icon" />
                    <div class="event-info__item-badge-text">{{ $formatNum(freeShells)}}</div>
                </div>
            </div>
        </div>
        <div class="event-gifts" v-if="selectedScreen === 2">
            <div class="event-gifts__list">
                <InventoryItem
                    v-for="(item, index) in shopItems"
                    :key="index"
                    :item="item"
                    :selectedItem="selectedItem"
                    @select-item="selectItem"
                />
            </div>
            <div class="event-gifts__bottom" v-if="selectedItem">
                <div class="event-gifts__info">
                    <div class="event-gifts__title">{{ selectedItem.name }}</div>
                    <div class="event-gifts__badge" :class="selectedItem.rarity">{{ rarityMap[selectedItem.rarity] }}</div>
                </div>
                <div class="event-gifts__buttons">
                    <BaseButton v-if="selectedItem.filterType === 'clothes'" class="event-gifts__check-btn" white @click="tryOnItem(selectedItem.index)">
                        <IconEventEye />
                        {{ $t('season_event.check') }}
                    </BaseButton>
                    <BaseButton class="event-gifts__buy-btn" yellow @click="buyItem(selectedItem.index)">
                        {{ $t('season_event.buy') }}
                        <div class="event-gifts__buy-btn-price">
                            <IconEvents />
                            {{ $formatNum(selectedItem.price) }}
                        </div>
                    </BaseButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import AppHeader from '@/components/AppHeader/AppHeader.vue';
import IconEvents from '@/components/icons/IconEvents.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import IconEventMap from '@/components/popups/content/event/assets/icons/IconEventMap.vue';
import IconEventBuy from '@/components/popups/content/event/assets/icons/IconEventBuy.vue';
import IconEventEye from '@/components/popups/content/event/assets/icons/IconEventEye.vue';
import InventoryItem from '@/components/popups/components/inventoryItem/InventoryItem.vue';
import { mapActions } from 'vuex';

export default {
    name: 'EventSeason',
    components: {
        InventoryItem,
        IconEventEye,
        IconEventBuy,
        IconEventMap,
        BaseButton,
        IconEvents,
        AppHeader
    },
    data () {
        return {
            eventName: 'Летний бум',
            selectedScreen: 1, // 1,2
            selectedItem: null,
            rarityMap: {
                legend: this.$t('shop.rarity_map.legend'),
                ordinary: this.$t('shop.rarity_map.ordinary'),
                unusual: this.$t('shop.rarity_map.unusual'),
                rare: this.$t('shop.rarity_map.rare'),
                epic: this.$t('shop.rarity_map.epic')
            },
            shopItems: [
                { id: 2, name: 'Штурмовая винтовка', rarity: 'legend', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/m4' },
                { id: 3, name: 'Пистолет', rarity: 'unusual', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/pistol' },
                { id: 2, name: 'Штурмовая винтовка', rarity: 'legend', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/m4' },
                { id: 3, name: 'Пистолет', rarity: 'unusual', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/pistol' },
                { id: 2, name: 'Штурмовая винтовка', rarity: 'legend', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/m4' },
                { id: 3, name: 'Пистолет', rarity: 'unusual', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/pistol' },
                { id: 2, name: 'Штурмовая винтовка', rarity: 'legend', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/m4' },
                { id: 3, name: 'Пистолет', rarity: 'unusual', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/pistol' },
                { id: 2, name: 'Штурмовая винтовка', rarity: 'legend', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/m4' },
                { id: 3, name: 'Пистолет', rarity: 'unusual', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/pistol' },
                { id: 2, name: 'Штурмовая винтовка', rarity: 'legend', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/m4' },
                { id: 3, name: 'Пистолет', rarity: 'unusual', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/pistol' },
                { id: 2, name: 'Штурмовая винтовка', rarity: 'legend', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/m4' },
                { id: 2, name: 'Штурмовая винтовка', rarity: 'legend', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/m4' },
                { id: 3, name: 'Пистолет', rarity: 'unusual', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/pistol' },
                { id: 2, name: 'Штурмовая винтовка', rarity: 'legend', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/m4' },
                { id: 3, name: 'Пистолет', rarity: 'unusual', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/pistol' },
                { id: 2, name: 'Штурмовая винтовка', rarity: 'legend', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/m4' },
                { id: 2, name: 'Штурмовая винтовка', rarity: 'legend', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/m4' },
                { id: 3, name: 'Пистолет', rarity: 'unusual', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/pistol' },
                { id: 2, name: 'Штурмовая винтовка', rarity: 'legend', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/m4' },
                { id: 3, name: 'Пистолет', rarity: 'unusual', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/pistol' },
                { id: 2, name: 'Штурмовая винтовка', rarity: 'legend', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/m4' },
                { id: 2, name: 'Штурмовая винтовка', rarity: 'legend', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/m4' },
                { id: 3, name: 'Пистолет', rarity: 'unusual', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/pistol' },
                { id: 2, name: 'Штурмовая винтовка', rarity: 'legend', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/m4' },
                { id: 3, name: 'Пистолет', rarity: 'unusual', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/pistol' },
                { id: 2, name: 'Штурмовая винтовка', rarity: 'legend', type: 1, price: 1000, filterType: 'weapon', desc: 'Скорострельное огнестрельное оружие', img: 'other_items/m4' }
            ],
            tasks: [
                {
                    title: 'Magic run',
                    info: 'He is offered a lucrative deal with Kenya. The correspondence with Africa is handled by the eighteen-year-old translator Leila. Brenninger sends the last of his money to an African bank.',
                    profit: 580,
                    buttonType: 1,
                    index: 1
                },
                {
                    title: 'Magic run',
                    info: 'He is offered a lucrative deal with Kenya. The correspondence with Africa is handled by the eighteen-year-old translator Leila. Brenninger sends the last of his money to an African bank.',
                    profit: 300,
                    buttonType: 2,
                    index: 2
                },
                {
                    title: 'Magic run',
                    info: 'He is offered a lucrative deal with Kenya. The correspondence with Africa is handled by the eighteen-year-old translator Leila. Brenninger sends the last of his money to an African bank.',
                    profit: 580,
                    buttonType: 1,
                    index: 3
                },
                {
                    title: 'Magic run',
                    info: 'He is offered a lucrative deal with Kenya. The correspondence with Africa is handled by the eighteen-year-old translator Leila. Brenninger sends the last of his money to an African bank.',
                    profit: 120,
                    buttonType: 2
                }
            ],
            totalSeconds: 220,
            freeShells: 100
        };
    },
    mounted () {
        if (this.shopItems.length > 0) {
            this.selectedItem = this.shopItems[0];
        }
        this.startTimer();
    },
    beforeDestroy () {
        this.stopTimer();
    },
    methods: {
        ...mapActions(['updateInventoryItemsPool']),
        selectItem (item) {
            this.selectedItem = item;
        },
        tryOnItem (index) {
            this.sendclient('SeasonTryItem', JSON.stringify({ a: index }));
        },
        buyItem (index) {
            this.sendclient('SeasonBuyItem', JSON.stringify({ a: index }));
        },
        takeFreeShells () {
            console.log('забираем бесплатную валюту, кол-во: ', this.freeShells);
        },
        sell (item) {
            console.log('продаем', item);
        },
        setLocation (index) {
            this.sendclient('SeasonLocateEvent', JSON.stringify({ a: index }));
        },
        startTimer () {
            if (this.timer !== null) {
                clearInterval(this.timer);
                this.timer = null;
            }
            this.timer = setInterval(() => {
                if (this.totalSeconds > 0) {
                    this.totalSeconds--;
                } else {
                    this.stopTimer();
                }
            }, 1000); // Обновлять каждую секунду
        },
        stopTimer () {
            clearInterval(this.timer);
            this.timer = null;
        }
    }
};
</script>

<style lang="scss" scoped>
.event-season {
    position: relative;
    height: 100%;
    background: url('./assets/images/bg-left-top.png') no-repeat left top/922px, radial-gradient(124.34% 59.42% at 50.00% 17.85%, #2C8676 0%, #193E41 100%);
    &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 730px;
        height: 814px;
        background: url('./assets/images/bg-right-top.png') no-repeat center/cover;
        z-index: 3;
        pointer-events: none;
    }
    &::before {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        width: 622px;
        height: 347px;
        background: url('./assets/images/bg-right-bottom.png') no-repeat center/cover;
        z-index: 3;
        pointer-events: none;
    }
}
.event-info {
    height: calc(100% - 9.8vw);
    padding: 60px 800px 80px 150px;
    display: grid;
    grid-auto-columns: 618px;
    grid-auto-flow: column;
    overflow-x: auto;
    overflow-y: hidden;
    grid-gap: 0 32px;
    &::-webkit-scrollbar {
        display: none;
    }
    &__girl {
        position: absolute;
        right: 0;
        bottom: 0;
        object-fit: cover;
        z-index: 2;
        width: 889px;
        height: 994px;
    }
    &__item {
        border-radius: 12px;
        border: 4px solid rgba(241, 241, 241, 0.80);
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.80) 0%, #FFF 100%);
        height: 100%;
        padding: 64px;
        &-title {
            color: var(--dark);
            font-size: 44px;
            font-weight: 300;
            line-height: 120%;
            margin-bottom: 24px;
        }
        &-text {
            color: var(--dark);
            font-size: 32px;
            font-weight: 500;
            line-height: 130%;
            margin-bottom: 115px;
        }
        &-badge {
            color: var(--white);
            font-size: 48px;
            font-weight: 700;
            line-height: 100%;
            display: flex;
            align-items: center;
            padding: 32px 40px;
            width: fit-content;
            border-radius: 4px;
            background: radial-gradient(228.11% 265.72% at -0.00% 0%, #2C8676 0%, #193E41 100%);
            margin-bottom: 32px;
            &-icon {
                margin: 0 11px;
            }
            &-text {
                &--plus {
                    height: 100%;
                    line-height: 100%;
                    transform: translateY(-5px);
                }
            }
        }
        &-button {
            height: 112px;
            padding: 0 44px;
            svg {
                width: 40px;
                height: 40px;
            }
            &-text {
                margin-left: 18px;
                color: var(--dark);
                font-size: 36px;
                font-weight: 500;
                line-height: 110%;
            }
        }
    }
    &__tooltip {
        position: absolute;
        z-index: 3;
        right: 130px;
        bottom: 130px;
        display: flex;
        flex-direction: column;
        width: 350px;
        border-radius: 4px;
        &-text {
            border-radius: 4px 4px 0 0;
            padding: 22px;
            background-color: var(--dark);
            color: #FFF;
            text-align: center;
            text-shadow: 0 6px 40px rgba(0, 0, 0, 0.65);
            font-size: 44px;
            font-weight: 900;
            line-height: 100%;
            text-transform: uppercase;
            span {
                color: #27CAAD;
            }
        }
        &-badge {
            border-radius: 0 0 4px 4px;
            width: 100%;
            margin-bottom: 0;
            justify-content: center;
            font-size: 64px;
            align-items: center;
            .event-info__item-badge-icon {
                margin: 0 8px;
            }
        }
        &-button {
            color: var(--dark);
            text-align: center;
            font-size: 36px;
            font-weight: 500;
            line-height: 110%;
            padding: 0 44px;
            white-space: nowrap;
        }
    }
    &__timer {
        display: flex;
        width: 100%;
        padding: 12px;
        background-color: var(--white);
        span{
            color: #FFF;
            text-align: center;
            font-size: 52px;
            font-style: normal;
            font-weight: 700;
            line-height: 80%;
            letter-spacing: -1.28px;
            display: flex;
            padding: 8px 8px 12px 8px;
            flex-direction: column;
            align-items: flex-start;
            border-radius: 4px;
            background: #000;
            margin-right: 4px;
            &:last-child {
                margin-right: 0;
            }
        }
        i{
            color: #000;
            display: flex;
            align-items: flex-end;
            padding-bottom: 10px;
            font-size: 52px;
            font-style: normal;
            font-weight: 700;
            line-height: 80%; /* 41.6px */
            letter-spacing: -1.04px;
            margin-right: 4px;
        }
    }
}
.event-gifts {
    height: calc(100% - 9.8vw);
    padding: 90px 0 80px;
    &__list {
        padding: 5px 300px;
        margin-bottom: 70px;
        position: relative;
        z-index: 5;
        display: grid;
        justify-content: safe start;
        grid-auto-columns: 220px;
        grid-template-rows: 1fr 1fr;
        grid-gap: 24px;
        grid-auto-flow: column;
        overflow-y: hidden;
        overflow-x: auto;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    &__item {
        background: cornflowerblue;
        height: 100%;
    }
    &__bottom {
        position: relative;
        z-index: 5;
        height: 146px;
        padding: 0 300px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__info {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
    }
    &__title {
        color: #FFF;
        font-size: 54px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
    }
    &__badge {
        width: fit-content;
        padding: 10px 24px;
        text-transform: uppercase;
        font-family: var(--font-stretch);
        color: var(--white);
        font-size: 34px;
        font-weight: 700;
        line-height: 100%;
        letter-spacing: 1.36px;
        &.legend{
            background: linear-gradient(211.69deg, #FFCD4C 33.85%, #FFA800 100%);
        }
        &.epic{
            background: linear-gradient(211.69deg, #BE6AFF 20.31%, #7A28FF 100%);
        }
        &.rare{
            background: linear-gradient(211.69deg, #48FDC7 0%, #009D6E 100%);
        }
        &.ordinary{
            background: linear-gradient(212deg, #96ABB5 43.23%, #72848B 100%);
        }
        &.unusual{
            background: linear-gradient(211.69deg, #5FCFFF 35.94%, #2780A6 100%);
        }
    }
    &__check-btn {
        height: 112px;
        padding: 0 44px;
        svg {
            margin-right: 18px;
        }
    }
    &__buy-btn {
        height: 146px;
        margin-left: 32px;
        font-size: 48px;
        font-weight: 500;
        &-price {
            padding: 7px 12px 7px 7px;
            margin-left: 32px;
            border-radius: 6px;
            background: var(--dark);
            color: var(--white);
            font-size: 48px;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: -0.96px;
            display: flex;
        }
    }
    &__buttons {
        display: flex;
        align-items: center;
    }
}
</style>
