<template>
    <div
        class="estate__info-badge"
        :class="{
          'estate__info-badge--standart': level === 1,
          'estate__info-badge--comfort': level === 2,
          'estate__info-badge--premium': level === 3,
        }"
    >
        {{
        level === 1 ? this.$t('estate.house_badge.standart')
        : level === 2 ? this.$t('estate.house_badge.comfort')
        : level === 3 ? this.$t('estate.house_badge.premium')
        : ''
        }}
    </div>
</template>

<script>
export default {
    name: 'EstateHouseBadge',
    props: {
        level: Number
    }
};
</script>
<style lang="scss">
.estate__info-badge {
    color: var(--dark);
    font-family: var(--font-stretch);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.35vw;
    width: fit-content;
    margin: 0 auto;
    padding: .4vw 1vw;
    &--standart {
        background: linear-gradient(211.69deg, #48FDC7 0%, #009D6E 100%);
    }
    &--comfort {
        background: linear-gradient(211.69deg, #BE6AFF 20.31%, #7A28FF 100%);
        color: var(--white);
    }
    &--premium {
        background: linear-gradient(211.69deg, #FFCD4C 33.85%, #FFA800 100%);
    }
}
</style>
