<template>
    <transition name="fade">
        <button class="connection"
                @click="emit('click', $event)"
                @mouseover="emit('mouseover', $event)"
                @mouseleave="emit('mouseleave', $event)"
            >
                {{ text }}
        </button>
    </transition>
</template>

<script>
export default {
    name: 'BaseConnection',
    props: {
        text: {
            type: String,
            default: 'Взаимодействие'
        }
    },
    methods: {
        emit (eventName, event) {
            this.$emit(eventName, event);
        }
    }
};
</script>

<style lang="scss">
.connection{
    background: url(@/assets/images/hud/connection.svg) no-repeat center/contain;
    width: 693px;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    z-index: 10;
    bottom: 2vw;
    left: 0;
    right: 0;
    margin: 0 auto;
    font-size: 1.5vw;
    overflow: hidden;

    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;

    border: none;
    color: #F1F1F1;

    opacity: 0.96;

    &::after{
        position: absolute;
        width: 36vw;
        height: 27vw;
        //background: radial-gradient(50% 50% at 50% 50%, #202427 0%, rgba(32, 36, 39, 0) 100%);
        opacity: 0.3;
        content: "";
        left: auto;
        right: auto;
        margin: 0 auto;
        bottom: -14vw;
        z-index: -1;
    }
}

</style>
