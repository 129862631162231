<template>
<!--    #todo обновить переводы-->
    <div class="server-select">
        <StepLabel is-server />
        <transition name="server-transition">
            <div class="server-select__wrapper" key="server-select">
                <div class="server-select__country">
                        <div
                            v-for="country in countries"
                            :key="country.flag"
                            class="server-select__country-item"
                            :class="{'server-select__country-item--selected': country.flag === selectedCountry }"
                            @click="selectCountry(country)"
                        >
                            <div class="server-select__country-border"></div>
                            <img :src="$imageSrc(`${country.flag}.svg`, 'flags')" alt="flag">
                        </div>
                    </div>
                <div class="server-select__servers" v-if="selectedCountry">
                    <div
                        class="server-select__servers-item"
                        v-for="server in getServersByCountry"
                        :key="server.number"
                        @click="selectServer(server)"
                        :style="{background: `url(${ $imageSrc(`${server.image}.png`, 'servers') }) no-repeat center/cover, ${server.bgColor}`}"
                    >
                        <div
                            class="server-select__servers-text"
                            :style="{color: server.textColor}"
                        >
                            {{ server.name.slice(0, 4) }}
                        </div>
                        <div class="server-select__servers-info">
                            <p class="server-select__servers-country">
                                {{ server.name }}
                            </p>
                            <div class="server-select__servers-number">{{ $t('server_select.city') }} {{ server.number }}</div>
                        </div>
                            <transition name="server-down">
                                <div v-if="!selectedServer || selectedServer.number !== server.number" class="server-select__servers-bottom">
                                    <p class="server-select__servers-online-text">{{ $t('server_select.online') }}</p>
                                    <div class="server-select__servers-online">
                                        <div v-for="(item, index) in new Array(10)"
                                             :key="index"
                                             class="server-select__servers-indicator"
                                             :style="{background: Math.round(server.online / 10) > index && '#f1f1f1'}"
                                        ></div>
                                    </div>
                                </div>
                            </transition>
                            <transition name="server-slide">
                                <BaseButton @click = "chooseServer()" v-if="selectedServer && selectedServer.number === server.number" class="server-select__servers-button" white>Войти</BaseButton>
                            </transition>
                        <img class="server-select__servers-image" :src="$imageSrc(`${server.skin}.png`, 'servers')" alt=''>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import StepLabel from './components/StepLabel.vue';
import BaseButton from '@/components/ui/BaseButton.vue';

export default {
    name: 'ServerSelect',
    components: { BaseButton, StepLabel },
    data () {
        return {
            step: 0,
            selectedServer: null,
            selectedCountry: 'russia',
            servers: [
                { number: 1, name: 'CHEREPOVETPZDC', flag: 'russia', online: 10, image: 'bg1', skin: 'skin1', textColor: '#F0439D', bgColor: '#5829DE', ip: '127.0.0.1', port: 22005, language: 'en' },
                { number: 2, name: 'CHEREPOVETPZDC', flag: 'england', online: 70, image: 'bg1', skin: 'skin1', textColor: '#F0439D', bgColor: '#5829DE', ip: '94.142.140.143', port: 22005, language: 'en' },
                { number: 3, name: 'SHERVILL', flag: 'russia', online: 30, image: 'bg2', skin: 'skin2', textColor: '#6DBBDE', bgColor: '#38B478', ip: '94.142.140.143', port: 22005, language: 'en' },
                { number: 4, name: 'GRANDSTER', flag: 'russia', online: 20, image: 'bg3', skin: 'skin3', textColor: '#FFA651', bgColor: '#FF4C41', ip: '94.142.140.143', port: 22005, language: 'en' },
                { number: 5, name: 'CHEREPOVETPZDC', flag: 'russia', online: 40, image: 'bg1', skin: 'skin1', textColor: '#F0439D', bgColor: '#5829DE', ip: '94.142.140.143', port: 22005, language: 'en' }
            ]
        };
    },
    computed: {
        getServersByCountry () {
            return this.servers.filter(server => server.flag === this.selectedCountry);
        },
        countries () {
            return [
                { name: this.$t('server_select.county_list.russia'), flag: 'russia', language: 'en' },
                { name: this.$t('server_select.county_list.england'), flag: 'england', language: 'en' },
                { name: this.$t('server_select.county_list.germany'), flag: 'germany' },
                { name: this.$t('server_select.county_list.france'), flag: 'france' },
                { name: this.$t('server_select.county_list.spain'), flag: 'spain' },
                { name: this.$t('server_select.county_list.india'), flag: 'india' }
            ];
        }
    },
    mounted () {
        this.selectCountry(this.countries[0]);
    },
    methods: {
        selectCountry (country) {
            this.selectedCountry = country.flag;
            if (country.language)window.changeLocale(country.language);
            // чтобы пропала кнопка войти при смене регионов
            this.selectedServer = null;
        },
        selectServer (server) {
            this.selectedServer = server;
            this.selectedCountry = server.flag;
        },
        chooseServer () {
            if (!this.selectedServer) return;
            this.sendclient('selectServer', JSON.stringify({ a: this.selectedServer.ip, b: this.selectedServer.port, lang: this.selectedServer.language }));
        }
    }
};
</script>

<style>
.server-transition-enter-active,
.server-transition-leave-active {
    transition: opacity 0.3s ease, height 0.3s ease, padding 0.3s ease;
    overflow: hidden;
}

.server-transition-enter,
.server-transition-leave-to {
    opacity: 0;
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
}

.server-slide-enter-active,
.server-slide-leave-active {
    transform: translate(-50%, 0);
    opacity: 1;
    transition: opacity .3s ease, transform .3s ease;
}

.server-slide-enter,
.server-slide-leave-to {
    opacity: 0;
    transform: translate(-50%, 250px);
}

.server-down-enter-active,
.server-down-leave-active {
    transform: translateY(0);
    opacity: 1;
    transition: opacity .3s ease, transform .3s ease;
}

.server-down-enter,
.server-down-leave-to {
    transform: translateY(-250px);
    opacity: 0;
}
</style>
