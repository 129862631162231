<template>
    <svg :class="className" width="94" height="48" viewBox="0 0 94 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="84" height="48" fill="#C30542"/>
        <path d="M28.8643 37H25.2428L19.0971 21.5534V37H15V11H18.6581L24.7672 26.4466V11H28.8643V37Z" fill="white"/>
        <path d="M44.9244 37H33.9866V11H44.9244V14.6882H38.0837V22.0646H43.9733V25.7528H38.0837V33.3118H44.9244V37Z" fill="white"/>
        <path d="M73 11L68.0981 37H63.8181L60.0868 17.2444L56.3921 37H52.1121L47.1736 11H51.6731L54.2338 30.7191L57.8187 11H62.3548L65.9764 30.7191L68.5005 11H73Z" fill="white"/>
        <path d="M84 0V48L93 0H84Z" fill="#C30542"/>
    </svg>
</template>
<script>
export default {
    props: {
        className: String
    }
};
</script>
