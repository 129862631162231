<template>
    <div>
        <svg v-if="icon === 'location'" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4 15.9625C4 7.04831 11.0649 0 20 0C28.7273 0 36 7.25562 36 15.9625C36 26.6694 20 40 20 40C20 40 4 26.8767 4 15.9625ZM20 22C23.3137 22 26 19.3137 26 16C26 12.6863 23.3137 10 20 10C16.6863 10 14 12.6863 14 16C14 19.3137 16.6863 22 20 22Z" fill="#F1F1F1"/>
        </svg>
        <svg v-if="icon === 'heart'" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M38 14.1812C38 24.9571 20 36.0008 20 36.0008C20 36.0008 2 24.9571 2 14.1812C1.99998 -0.0594015 18 -1.99925 20 10.1812C22 -1.99925 38 -0.0594015 38 14.1812Z" fill="#F1F1F1"/>
        </svg>
    </div>
</template>
<script>
export default {
    props: {
        icon: String
    }
};
</script>
<style lang="scss" scoped>
svg {
    width: 1.6vw;
    height: 1.6vw;
    margin-right: 1vw;
}
</style>
