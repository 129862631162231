<template>
    <svg :class="className" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.9998 3.33301C20.6654 3.33301 21.2673 3.72909 21.5305 4.34046L25.6455 13.8957L36.0047 14.8565C36.6675 14.918 37.2301 15.368 37.4358 16.001C37.6415 16.6341 37.4508 17.3289 36.9507 17.7682L29.1347 24.6345L31.4221 34.7835C31.5684 35.4329 31.3144 36.1071 30.7759 36.4983C30.2373 36.8896 29.5176 36.9229 28.9453 36.583L19.9998 31.2713L11.0543 36.583C10.4819 36.9229 9.76225 36.8896 9.22373 36.4983C8.6852 36.1071 8.43113 35.4329 8.57749 34.7835L10.8649 24.6345L3.04887 17.7682C2.54878 17.3289 2.35806 16.6341 2.56376 16.001C2.76945 15.368 3.33212 14.918 3.99493 14.8565L14.3541 13.8957L18.469 4.34046C18.7323 3.72909 19.3341 3.33301 19.9998 3.33301Z" fill="#FFCD4C"/>
    </svg>
</template>
<script>
export default {
    props: {
        className: String
    }
};
</script>
