<template>
    <div class="estate"
         :style="{background: `url(${imageSrc('bg-2.svg')}) no-repeat center/cover, ${currentGradient}, url(${currentEstate.isOwned ? currentBg : imageSrc('not-owned-image.png')}) no-repeat center/cover`}">
        <AppHeader back-button>
            <template #title>{{$t('estate.header')}}</template>
        </AppHeader>
        <div class="estate__content">
            <EstateMenu :items="menuItems" :activeItem="estateType" @change="changeEstateType" />
            <EstateBusiness
                v-if="estateType === 0 && graphProgress"
                :estate=currentEstate
                :isDone=isDone
                :graphProgress=graphProgress
                :isMiniBusiness=false
                :isOwned=currentEstate.isOwned
                @pickUp="pickUp"
                @controlClicked="handleControlClick"
            />
            <EstateBusiness
                v-if="estateType === 1 && graphProgress"
                :estate=currentEstate
                :isDone=isDone
                :graphProgress=graphProgress
                :isMiniBusiness=true
                :isOwned=currentEstate.isOwned
                @pickUp="pickUp"
                @controlClicked="handleControlClick"
            />
            <EstateHouse
                v-if="graphProgress && [2, 3, 4].includes(estateType)"
                :estate="currentEstate"
                :isOwned="currentEstate.isOwned"
                @controlClicked="handleControlClick"
            />

        </div>
    </div>
</template>

<script>
import AppHeader from '@/components/AppHeader/AppHeader.vue';
import EstateMenu from '@/components/ui/EstateMenu.vue';
import EstateBusiness from '@/components/popups/content/estate/components/EstateBusiness.vue';
import EstateHouse from '@/components/popups/content/estate/components/EstateHouse.vue';

export default {
    name: 'MyEstate',
    components: { EstateHouse, EstateBusiness, EstateMenu, AppHeader },
    data () {
        return {
            estateType: 0,
            graphProgress: null,
            menuItems: [
                { id: 0, name: this.$t('estate.business.name'), icon: 'business' },
                { id: 1, name: this.$t('estate.mini_business.name'), icon: 'miniBusiness' },
                { id: 2, name: this.$t('estate.house.name'), icon: 'house' },
                { id: 3, name: this.$t('estate.house.name'), icon: 'house' },
                { id: 4, name: this.$t('estate.house.name'), icon: 'house' }
            ],
            estate: [
                { id: 0, name: 'Man’s gas station', type: 'АЗС', level: 1, currentProfit: 4000, maxProfit: 5000, upgradeEstateMoney: 100000, pickUpMoney: 50000, isOwned: true },
                { id: 1, name: 'Flower Vinewood', type: 'Прачечная', level: 1, currentProfit: 4352, timeRemaining: 100, maxProfit: 5000, pickUpMoney: 50000, upgradeEstateMoney: 100000, isOwned: true },
                { id: 2, name: 'Helloworld 22/77', level: 1, isOwned: true },
                { id: 3, name: 'Helloworld 22/77', level: 2, isOwned: true },
                { id: 4, name: 'Vinewood 31/22', level: 3, isOwned: false }
            ],
            timerId: null
        };
    },
    computed: {
        isDone: function () {
            return this.currentEstate.currentProfit > 0 && ((this.estateType === 1 && this.currentEstate.timeRemaining === 0) || this.estateType === 0);
        },
        currentEstate: function () {
            return this.estate.find(el => el.id === this.estateType);
        },
        currentBg: function () {
            const idMap = {
                0: 'business',
                1: 'mini-business',
                2: 'house',
                3: 'house',
                4: 'house'
            };
            let level = this.currentEstate.level;
            if (this.currentEstate.id === 1 && level > 3) {
                level = 3;
            }
            return this.imageSrc(`${idMap[this.currentEstate.id]}-${level}-lvl.png`);
        },
        currentGradient: function () {
            const gradientMap = {
                0: this.currentEstate.isOwned ? '.7' : '.9',
                1: this.currentEstate.isOwned ? '.7' : '.9',
                2: this.currentEstate.isOwned ? '.3' : '.9',
                3: this.currentEstate.isOwned ? '.3' : '.9',
                4: this.currentEstate.isOwned ? '.3' : '.9'
            };
            return `linear-gradient(to right, rgba(41,41,41, ${gradientMap[this.currentEstate.id]}), rgba(41,41,41, ${gradientMap[this.currentEstate.id]}))`;
        }
    },
    mounted () {
        this.graphProgress = this.getGraphProgress(this.currentEstate.currentProfit, this.currentEstate.maxProfit);
        this.startCountdown();
    },
    methods: {
        startCountdown () {
            if (this.timerId) {
                clearInterval(this.timerId);
            }

            this.timerId = setInterval(() => {
                if (this.currentEstate.timeRemaining > 0) {
                    this.currentEstate.timeRemaining--;
                } else {
                    clearInterval(this.timerId);
                    this.timerId = null;
                }
            }, 1000);
        },
        initialize (estate) {
            this.estate = estate;
        },
        changeBizInfo (money) {
            this.estate[0].currentProfit = money;
            this.estate[0].maxProfit = money;
            this.estate[0].pickUpMoney = money;
        },
        changeMiniBizInfo (profit, level, time, moneyCost) {
            this.estate[1].currentProfit = profit;
            this.estate[1].level = level;
            this.estate[1].maxProfit = profit;
            this.estate[1].timeRemaining = time;
            this.estate[1].upgradeEstateMoney = moneyCost;
            this.estate[1].pickUpMoney = profit;
        },
        getGraphProgress (currentProfit, maxProfit) {
            return Math.floor(currentProfit / maxProfit * 100);
        },
        imageSrc (img) {
            return require(`@/assets/images/estate/${img}`);
        },
        changeEstateType (id) {
            this.estateType = id;
        },
        handleControlClick (action) {
            console.log(action);
            if (action === 'map') {
                if (this.estateType === 0) this.sendclient('ClientFindMyBiz');
                else if (this.estateType === 1) this.sendclient('ClientFindMyMiniBiz');
                else if (this.estateType >= 2 && this.estateType <= 4) this.sendclient('ClientFindMyHouse', JSON.stringify({ a: this.estateType - 1 }));
            } else if (action === 'manage') {
                if (this.estateType === 0) this.sendclient('ClientManageBiz');
                else if (this.estateType === 1) this.sendclient('ClientManageMiniBiz');
                else if (this.estateType >= 2 && this.estateType <= 4) this.sendclient('ClientManageHouse', JSON.stringify({ a: this.estateType - 1 }));
            }
        },
        pickUp () {
            if (this.estateType === 0) this.sendclient('ClientGetBizMoney');
            else if (this.estateType === 1) this.sendclient('ClientGetMiniBizMoney');
        }
    },
    watch: {
        currentEstate: {
            immediate: true,
            handler (newEstate) {
                this.startCountdown();
            }
        }
    }
};
</script>
<style lang="scss">
.estate {
    height: 100%;
    &__content {
        padding: 4.35vw 5vw;
        height: calc(100% - 5vw);
        display: grid;
        grid-template-columns: repeat(11, 1fr);
        grid-gap: 0 4vw;
    }
    &__menu {
        grid-column: 3 span;
        display: flex;
        flex-direction: column;
        &-icon-container {
            margin-right: .35vw;
        }
        &-item {
            height: 6vw;
            max-width: 19.8vw;
            position: relative;
            margin-top: 1vw;
            padding-left: 1.5vw;
            text-transform: uppercase;
            font-family: var(--font-stretch);
            font-weight: 700;
            font-size: 1.75vw;
            display: flex;
            align-items: center;
            background-color: var(--dark);
            svg {
                width: 1.75vw;
                height: auto;
            }
            &:first-child {
                margin-top: 0;
            }
            &::after {
                content:"";
                position: absolute;
                right: -1.95vw;
                bottom: 0;
                top: 0;
                background-image: url("@/assets/images/estate/menu-bg-1.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                width: 2vw;
            }
            &:nth-of-type(even) {
                &::after {
                    background-image: url("@/assets/images/estate/menu-bg-2.svg");
                }
            }
            &:nth-of-type(even).estate__menu-item--active {
                color: var(--dark);
                &::after {
                    background-image: url("@/assets/images/estate/menu-bg-2-actvie.svg");
                }
            }
            &--active {
                color: var(--dark);
                background: var(--white);
                &::after {
                    background-image: url("@/assets/images/estate/menu-bg-1-active.svg");
                }
                svg{
                    path{
                        fill: var(--dark);
                    }
                }
            }
        }
    }
    &__info {
        padding: 1.8vw 4vw;
        grid-column: 8 span;
        &--own {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
        }
        &--house {
            display: flex;
            justify-content: center;
            flex-direction: column;
            .estate__info-title {
                margin-top: .5vw;
                margin-bottom: 0;
            }
            .estate__nav-item {
                margin-top: 4.75vw;
                justify-content: center;
            }
        }
        &-house {
            text-align: center;
        }
        &-heading {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &-title {
            font-weight: 300;
            font-size: 3.15vw;
            color: var(--white);
            line-height: 120%;
            margin-bottom: .5vw;
        }
        &-name {
            font-weight: 400;
            line-height: 100%;
            color: var(--white);
            opacity: 0.66;
            font-size: 2vw;
        }
        &-stats {
            display: flex;
            align-items: center;
            padding: 3.2vw 0 6.2vw;
        }
        &-descr {
            margin-left: 1.6vw;
        }
        &-price {
            display: flex;
            &-text {
                display: flex;
                font-size: 3.2vw;
                font-weight: 700;
                letter-spacing: -0.02em;
                &:first-child {
                    margin-right: 1vw;
                }
                &--full {
                    .estate__info-price-number {
                        opacity: .6;
                    }
                    .estate__info-price-symbol {
                        margin-right: 1vw;
                    }
                }
            }
            &-symbol {
                margin-right: .2vw;
            }
            &-dollar {
                color: var(--light-green);
            }
        }
        &-data {
            margin-top: 1vw;
            display: flex;
            align-items: center;
        }
        &-status {
            padding: .25vw .4vw;
            background-color: var(--light-green);
            border-radius: .16vw;
            color: var(--dark);
            font-weight: 700;
            font-size: 1.35vw;
            width: fit-content;
            text-transform: uppercase;
            font-family: var(--font-stretch);
        }
        &-time {
            display: flex;
        }
        &-clock {
            width: 1.8vw;
            height: 1.8vw;
            background-image: url("@/assets/images/estate/timer.svg");
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            margin: 0 .5vw;
        }
        &-wait {
            color: var(--white);
            font-weight: 700;
            font-size: 1.8vw;
            margin-right: 1vw;
        }
        &-text {
            font-size: 1vw;
            font-weight: 700;
            text-transform: uppercase;
            font-family: var(--font-stretch);
            letter-spacing: 0.06em;
            line-height: 120%;
            &--business {
                max-width: 23.5vw;
            }
        }
    }
    &__nav {
        display: flex;
        justify-content: space-between;
        &-item {
            display: flex;
        }
        &-map {
            img {
                width: 1.6vw;
                height: auto;
            }
        }
        &-control {
            margin-left: 1vw;
            display: flex;
            align-items: center;
            padding: 1vw 1.75vw;
            height: auto;
            svg {
                width: 1.6vw;
                height: auto;
                margin-right: .8vw;
                path {
                    fill: var(--dark);
                }
            }
        }
        &-info {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: .8vw 0;
            text-align: right;
            &--done {
                .estate__nav-money {
                    order: 1;
                }
                .estate__nav-message {
                    order: -1;
                }
                .estate__nav-dollar {
                    color: var(--light-green);
                }
            }
        }
        &-money {
            font-size: 2.5vw;
            font-weight: 700;
            display: flex;
            justify-content: flex-end;
        }
        &-dollar {
            color: var(--yellow);
        }
        &-message {
            text-transform: uppercase;
            font-family: var(--font-stretch);
            color: var(--white);
            font-weight: 700;
            letter-spacing: 0.06em;
            font-size: 1vw;
        }
    }
    &__own {
        &-title {
            font-size: 2.5vw;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 300;
            color: var(--white);
            margin-bottom: 1.75vw;
            svg {
                opacity: .4;
                width: 2.5vw;
                height: auto;
                margin-right: 1vw;
                path {
                    fill: var(--white);
                }
            }
        }
        &-text {
            text-align: center;
            font-weight: 400;
            font-size: 1.75vw;
            max-width: 38vw;
            margin-bottom: 3.15vw;
            line-height: 140%;
            span {
                font-weight: 700;
            }
            &--house {
                font-size: 2.6vw;
                max-width: 45vw;
                font-weight: 300;
                line-height: 130%;
            }
        }
        &-button {
            svg {
                width: 1.6vw;
                height: auto;
                margin-right: .75vw;
                path {
                    fill: var(--dark);
                }
            }
        }
        &-image {
            width: 4.75vw;
            height: auto;
        }
    }
}
</style>
