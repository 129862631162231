<template>
    <div class="bp-main__slider-item" :class="{
        'bp-main__slider-item--gained' : item.isOwned,
        'bp-main__slider-item--current' : item.id === level
    }"
    >
        <div class="bp-main__slider-item-inventory">
            <div v-if="!playerOwnBattlePass && item.isPremium" class="bp-main__slider-premium">
                <div class="bp-main__slider-premium-text">
                    premium
                </div>
                <img src="@/assets/images/battlePass/premium-lock.svg" class="bp-main__slider-premium-image" alt="">
            </div>
            <div @click="!openedReward && $emit('open-reward')">
                <InventoryItem :item="item" />
            </div>
            <div v-if="bonusSlider" @click="!openedReward && $emit('open-reward-second')" class="bp-finish__slider-inv">
                <InventoryItem :item="item.secondReward" />
            </div>
            <div v-if="item.isOwned" class="bp-main__slider-item-owned">
                <IconCheckmark />
            </div>
        </div>
        <BaseButton
            class="bp-main__slider-item-button"
            v-if="!item.isOwned && level > item.id && (item.isPremium ? !!playerOwnBattlePass : true)"
            white
            @click="$emit('get-prize')"
        >
            {{ $t('shop.get') }}
        </BaseButton>
        <div class="bp-main__slider-item-level" :class="{'bp-main__slider-item-level--owned' : item.isOwned}">{{ item.id }}</div>
        <IconLevelLine
            class="bp-main__slider-item-icon"
            :class="{
            'bp-main__slider-item-icon--owned' : item.isOwned,
            'bp-main__slider-item-icon--current' : !item.isOwned && item.id === level
        }"
        />
        <IconCurrentLevel v-if="item.id === level" class="bp-main__slider-item-current" />
    </div>
</template>

<script>
import BaseButton from '@/components/ui/BaseButton.vue';
import IconCurrentLevel from '@/components/popups/content/battlePass/icons/IconCurrentLevel.vue';
import IconCheckmark from '@/components/icons/IconCheckmark.vue';
import IconLevelLine from '@/components/popups/content/battlePass/icons/IconLevelLine.vue';
import InventoryItem from '@/components/popups/components/inventoryItem/InventoryItem.vue';

export default {
    name: 'BattlePassItem',
    components: { InventoryItem, IconLevelLine, IconCheckmark, IconCurrentLevel, BaseButton },
    props: {
        item: Object,
        level: Number,
        playerOwnBattlePass: Number,
        openedReward: Object || null,
        bonusSlider: Boolean
    }
};
</script>

<style lang="scss" scoped>
.bp-main__slider {
    &-item {
        height: 100%;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.04) 100%);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 90px;
        &--gained {
            background: linear-gradient(180deg, rgba(75, 231, 161, 0.00) 0%, rgba(75, 231, 161, 0.64) 100%);
        }
        &--current {
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.03) 60.42%, rgba(255, 255, 255, 0.24) 100%);
        }
        &-level {
            margin-top: auto;
            transform: translateY(10px);
            color: var(--white);
            font-size: 44px;
            font-weight: 500;
            line-height: 100%;
            letter-spacing: -1.32px;
            &--owned {
                color: var(--bp-green);
            }
        }
        &-icon {
            width: 124px;
            height: 74px;
            &--owned {
                &::v-deep {
                    path {
                        stroke: var(--bp-green);
                    }
                }
            }
            &--current {
                &::v-deep {
                    path {
                        opacity: 1;
                    }
                }
            }
        }
        &-inventory {
            position: relative;
        }
        &-owned {
            width: 100%;
            height: 70px;
            background-color: var(--bp-green);
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 5;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                width: 54px;
                height: 54px;
                &::v-deep {
                    path {
                        fill: var(--dark);
                    }
                }
            }
        }
        &-button {
            position: absolute;
            bottom: 26px;
            z-index: 4;
            width: 243px;
            height: 112px;
            font-size: 36px;
            border-radius: 4px;
        }
        &-current {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 84px;
        }
    }
    &-premium {
        position: absolute;
        top: -52px;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        z-index: 4;
        &-text {
            padding: 8px 12px;
            width: 100%;
            background: var(--yellow);
            color: var(--dark);
            text-align: right;
            font-size: 28px;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: 1.12px;
            text-transform: uppercase;
        }
        &-image {
            align-self: flex-end;
            width: 56px;
            height: 54px;
            transform: translateY(-1.5px);
        }
    }
}
.bp-finish__slider {
    &-inv {
        margin-top: 8px;
    }
    &-item {
        padding-top: 80px;
        &-owned {
            position: absolute;
            top: -77px;
        }
        &.bp-main__slider-item--gained {
            background: linear-gradient(180deg, rgba(75, 231, 161, 0.00) 35%, rgba(75, 231, 161, 0.64) 100%);
        }
    }
}
</style>
