const state = {
    vehicles: []
};

const mutations = {
    setVehicles (state, vehicles) {
        state.vehicles = vehicles;
    }
};

const actions = {
    fetchVehicles ({ commit }) {
        // Вместо этого вызова должен быть запрос на получение данных о машинах
        const vehiclesData = [
            { model: 1, name: 'Challenger', newCar: true, color: 'red', rarity: 'legend', price: 100000, speed: 3, power: 8, controllability: 3, img: 'vehicles/1.png' },
            { model: 2, name: 'Challenger 2', newCar: false, color: 'red', rarity: 'epic', price: 40000, speed: 7, power: 4, controllability: 2, img: 'vehicles/2.png' },
            { model: 3, name: 'Challenger 3', newCar: true, color: 'red', rarity: 'free', price: 999, speed: 2, power: 5, controllability: 3, img: 'vehicles/3.png' },
            { model: 4, name: 'Challenger', newCar: false, color: 'red', rarity: 'unique', price: 100000, speed: 1, power: 2, controllability: 4, img: 'vehicles/4.png' }
        ];
        commit('setVehicles', vehiclesData);
    }
};

const getters = {
    getVehicles: state => state.vehicles
};

export default {
    state,
    mutations,
    actions,
    getters
};
