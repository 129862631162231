<template>

    <div class="shop shop-vip">
        <AppHeader back-button @getBackHeader="handleBackHeader">
            <template #title>{{ title }}</template>
        </AppHeader>
        <div class="shop-vip__wrapper">
            <div class="shop-vip__grid">
                <div class="shop-vip__row">
                    <div class="shop-vip__item">
                        <div class="shop-vip__item-bold">+20%</div>
                        <div class="shop-vip__item-text">{{ $t('shop.to_earn') }}</div>
                    </div>
                    <div class="shop-vip__item">
                        <div class="shop-vip__item-icon"></div>
                        <IconBackArrow class="shop-vip__item-arrow" />
                        <div class="shop-vip__item-icon shop-vip__item-icon--vip"></div>
                    </div>
                    <div class="shop-vip__item">
                        <div class="shop-vip__item-bold">+1</div>
                        <div class="shop-vip__item-text">{{ $t('shop.spoon') }}</div>
                    </div>
                </div>
                <div class="shop-vip__row">
                    <div class="shop-vip__item">
                        <div class="shop-vip__item-bold">+1 {{ $t('shop.point') }}</div>
                        <div class="shop-vip__item-text">{{ $t('shop.exp_in_h') }}</div>
                    </div>
                    <div class="shop-vip__item shop-vip__item--reversed">
                        <div class="shop-vip__item-bold">{{ $t('shop.quests') }}</div>
                        <div class="shop-vip__item-text">{{ $t('shop.secret') }}</div>
                    </div>
                </div>
                <div class="shop-vip__row">
                    <div class="shop-vip__item shop-vip__item--reversed">
                        <div class="shop-vip__item-bold">{{ $t('shop.every_month') }}</div>
                        <div class="shop-vip__item-text">{{ $t('shop.gifts') }}</div>
                    </div>
                    <div class="shop-vip__item">
                        <div class="shop-vip__item-bold">{{ $t('shop.rare_items') }}</div>
                    </div>
                </div>
                <div class="shop-vip__row">
                    <div class="shop-vip__item">
                        <div class="shop-vip__item-bold">+20 {{ $t('shop.slots') }}</div>
                        <div class="shop-vip__item-text">{{ $t('shop.in_inv') }}</div>
                    </div>
                </div>
            </div>
            <div class="shop-vip__checkout">
                <BaseButton class="shop-vip__button" @click="buy" yellow>
                    {{ $t('shop.buy_vip') }}
                    <div class="shop-vip__button-price">
                        <IconDonate />
                        {{ $formatNum(price) }}
                    </div>
                </BaseButton>
                <div class="shop-vip__policy">
                    {{ $t('shop.policy_know') }} <span>{{ $t('shop.policy') }}</span> {{ $t('shop.and') }} <span>{{ $t('shop.policy_second') }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppHeader from '@/components/AppHeader/AppHeader.vue';
import IconBackArrow from '@/components/icons/IconBackArrow.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import IconDonate from '@/components/icons/IconDonate.vue';

export default {
    name: 'ShopVip',
    components: {
        IconDonate,
        BaseButton,
        IconBackArrow,
        AppHeader
    },
    data () {
        return {
            title: this.$t('shop.shop_currency'),
            price: 200
        };
    },
    methods: {
        handleBackHeader () {
            this.sendclient('shopMain');
        },
        buy () {
            this.sendclient('BuyVip');
        }
    }
};
</script>

<style lang="scss" scoped>
.shop-vip {
    &__wrapper {
        background: url("@/components/popups/content/shop/assets/images/vip-bg.png") left -375px center/1740px no-repeat, radial-gradient(138.02% 65.96% at 50.00% -0.00%, #FEED56 0%, #EC6D26 100%);
        padding: 145px 95px 90px 925px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &__grid {
        display: flex;
        flex-direction: column;
        margin-bottom: 80px;
    }
    &__row {
        display: flex;
        align-items: center;
        margin-bottom: 21px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__item {
        border-radius: 12px;
        background: var(--white);
        padding: 32px 44px;
        color: var(--dark);
        font-size: 54px;
        display: flex;
        align-items: center;
        line-height: 118%;
        margin-right: 21px;
        &--reversed {
            flex-direction: row-reverse;
            .shop-vip__item-bold {
                margin-right: 0;
                margin-left: 20px;
            }
        }
        &-bold {
            margin-right: 20px;
            font-weight: 700;
        }
        &-icon {
            width: 64px;
            height: 64px;
            background-image: url("@/assets/images/level-icon-standart.png");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            &--vip {
                background-image: url("@/assets/images/level-icon.png");
            }
        }
        &-arrow {
            transform: rotate(180deg);
            margin: 0 24px;
            width: 64px;
            height: 64px;
        }
        &:last-child {
            margin-right: 0;
        }
    }
    &__checkout {
        display: flex;
        align-items: center;
    }
    &__button {
        padding: 0 48px;
        font-size: 48px;
        min-width: fit-content;
        &-price {
            padding: 7px 12px;
            background-color: var(--dark);
            color: var(--white);
            margin-left: 32px;
            display: flex;
            align-items: center;
            border-radius: 6px;
            svg {
                width: 48px;
                height: 48px;
                margin-right: 6px;
            }
        }
    }
    &__policy {
        color: var(--white);
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        margin-left: 44px;
        span {
            text-decoration-line: underline;
        }
    }
}
</style>
