<template>
    <div class="yesno" ref="container">
        <div :class="{ 'yesno__no--active': isActive === false }" class="yesno__no">
            <IconClose class="yesno__btn" />
            <span>{{ $t('yes_no.no') }}</span>
        </div>
        <IconArrow class="yesno__arrow yesno__arrow--left" />
        <div
            :style="{ transform: `translateX(${translateX}px)` }"
            ref="switch"
            class="yesno__switch"
        ></div>
        <IconArrow class="yesno__arrow yesno__arrow--right" />
        <div :class="{ 'yesno__yes--active': isActive === true }" class="yesno__yes">
            <span>{{ $t('yes_no.yes') }}</span>
            <IconCheckmark class="yesno__btn"/>
        </div>
    </div>
</template>

<script>
import Hammer from 'hammerjs';
import IconClose from '@/components/icons/IconClose.vue';
import IconCheckmark from '@/components/icons/IconCheckmark.vue';
import IconArrow from '@/components/icons/IconArrow.vue';

export default {
    name: 'YesNo',
    components: { IconArrow, IconCheckmark, IconClose },
    data () {
        return {
            isActive: null,
            translateX: 0
        };
    },
    mounted () {
        this.setupHammer();
        this.$refs.switch.style.transition = 'transform 0.3s linear';
    },
    methods: {
        setupHammer () {
            const hammer = new Hammer(this.$refs.switch);
            const containerWidth = this.$refs.container.clientWidth;
            const switchWidth = this.$refs.switch.clientWidth;
            const offset = 0.015 * containerWidth;

            hammer.get('pan').set({ direction: Hammer.DIRECTION_HORIZONTAL });

            hammer.on('panstart', () => {
                this.$refs.switch.style.transition = '';
            });

            hammer.on('pan', (event) => {
                const minTranslateX = offset - containerWidth / 2 + switchWidth / 2;
                const maxTranslateX = containerWidth / 2 - switchWidth / 2 - offset;
                this.translateX = Math.min(maxTranslateX, Math.max(event.deltaX, minTranslateX));
            });

            hammer.on('panend', (event) => {
                this.$refs.switch.style.transition = 'transform 0.3s linear';
                const minTranslateX = offset - containerWidth / 2 + switchWidth / 2;
                const maxTranslateX = containerWidth / 2 - switchWidth / 2 - offset;

                if (event.deltaX > containerWidth / 12) {
                    this.isActive = true;
                } else if (event.deltaX < -containerWidth / 12) {
                    this.isActive = false;
                } else {
                    this.isActive = null;
                }
                this.translateX = this.isActive === null ? 0 : (this.isActive ? maxTranslateX : minTranslateX);

                this.$emit('input', this.isActive);
            });
        },
        updateValue () {
            this.$emit('input', this.isActive);
        }
    }
};
</script>

<style lang="scss">
.yesno{
    display: flex;
    margin: 0 auto;
    max-width: 1600px;
    justify-content: space-between;
    align-items: center;
    padding: 1vw 0;
    position: relative;

    &__arrow{
        &--left{
            left: 16vw;
            position: absolute;
            height: 50%;
            width: auto;
        }

        &--right{
            right: 16vw;
            position: absolute;
            height: 50%;
            width: auto;
            transform: scaleX(-1);
        }
    }

    span{
        font-weight: 700;
        font-size: 2vw;
        line-height: 100%;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #F1F1F1;
        position: relative;
        transition: .1s linear;
    }

    &__no{
        width: 354px;
        height: 354px;
        display: flex;
        position: relative;
        align-items: center;
        justify-content: flex-end;
        border-radius: 358.832px;
        background: radial-gradient(59.29% 59.29% at 0% 50%, #CF4941 0%, rgba(207, 73, 65, 0) 0%);
        transition: .2s linear;
        padding-right: 1.6vw;

        svg{
            position: relative;
            z-index: 2;
            margin-right: 0.5vw;
            pointer-events: none;
        }

        &--active{
            background: radial-gradient(59.29% 59.29% at 0% 50%, #CF4941 0%, rgba(207, 73, 65, 0) 100%);

            span{
                visibility: hidden;
                opacity: 0;
            }
        }
    }

    &__yes{
        width: 354px;
        height: 354px;
        display: flex;
        position: relative;
        align-items: center;
        justify-content: flex-start;
        border-radius: 358.832px;
        background: radial-gradient(65.54% 65.54% at 100% 50%, #9FD035 0%, rgba(159, 208, 53, 0) 0%);
        transition: .2s linear;
        padding-left: 2.6vw;

        svg{
            position: relative;
            z-index: 2;
            margin-left: 0.5vw;
            pointer-events: none;
        }

        &--active{
            background: radial-gradient(65.54% 65.54% at 100% 50%, #9FD035 0%, rgba(159, 208, 53, 0) 100%);
            span{
                visibility: hidden;
                opacity: 0;
            }
        }
    }

    &__switch{
        width: 260px;
        height: 260px;
        left: 0;
        right: 0;
        margin: 0 auto;

        background: #F1F1F1;
        border-radius: 100%;
        position: absolute;
        z-index: 1;
        transition: transform 0.2s linear;

        &::after{
            content: "";
            position: absolute;
            width: 8vw;
            left: 0;
            right: 0;
            margin: 0 auto;
            height: 100%;
            border-radius: 100%;
            background: #F1F1F1;
            box-shadow: 0 0 47.8443px rgba(241, 241, 241, 0.7);
        }
    }
}
</style>

<style lang="scss">
.yesno{
    &__btn{
        width: 96px;
        height: 96px;
    }
}
</style>
