<template>
    <section class="hud">
        <div class="hud__top">
            <div class="hud__id">
                <span>ID</span> {{ $store.state.player.id }}
            </div>
            <transition name="fade">
                <PayDay />
            </transition>
            <div class="hud__map">
                <VReports v-if="isAdmin" />
                <VMap />
                <HealthPoint />
                <transition name="fade">
                    <EventBadge/>
                </transition>
                <transition name="fade">
                    <TimePrizes />
                </transition>
                    <ContactsNotification />
            </div>
            <div class="hud__chat">
                <VChat />
            </div>
            <div class="hud__main-button">
                <MainButtons
                    @open-mobile="toggleMobile"
                />
                <MoneyBalance />
            </div>
        </div>

        <SkillCheck v-if="isSkillCheck" />

        <transition name="fade">
            <IdCard v-if="isIdCard" :player-info="playerCard"/>
        </transition>

        <transition name="fade">
            <LevelUp v-if="levelUp.show" :level="levelUp.level" :is-vip="levelUp.isVip"/>
        </transition>

        <transition name="fade">
            <ProgressAction
                v-if="actionProgress.show"
                :title="actionProgress?.title"
                :timer="actionProgress.timer"
                @completed="onProgressCompleted"
            />
        </transition>

        <BaseConnection
            :text="$t('hud.interaction')"
            @click="interactAction"
            v-if="interactActive"
        />

        <VKillList v-if="isKillList" />

        <GameController />

        <transition name="fade">
            <VOffer v-if="isOffer"/>
        </transition>

        <TakeReward />

        <transition name="fade">
            <InPrison v-if="isPrison" :prison="prison"/>
        </transition>

        <transition name="slide-right">
            <VMobile v-if="isMobile" @close-mobile="toggleMobile" />
        </transition>
  </section>
</template>

<script>
import VMap from '@/components/hud/components/map/VMap.vue';
import HealthPoint from '@/components/hud/components/health/HealthPoint.vue';
import VChat from '@/components/hud/components/chat/VChat.vue';
import MainButtons from '@/components/hud/components/buttons/MainButtons.vue';
import GameController from '@/components/hud/components/control/GameController.vue';
import BaseConnection from '@/components/ui/BaseConnection.vue';
import MoneyBalance from '@/components/hud/components/balance/MoneyBalance.vue';
import VMobile from '@/components/hud/components/phone/VMobile.vue';
import VOffer from '@/components/hud/components/offer/VOffer.vue';
import InPrison from '@/components/hud/components/prison/InPrison.vue';
import TakeReward from '@/components/hud/TakeReward.vue';
import IdCard from '@/components/hud/components/IdCard.vue';
import LevelUp from '@/components/hud/components/LevelUp.vue';
import EventBadge from '@/components/hud/components/events/EventBadge.vue';
import ProgressAction from '@/components/hud/components/ProgressAction.vue';
import TimePrizes from '@/components/hud/components/prizes/TimePrizes.vue';
import PayDay from '@/components/hud/components/PayDay.vue';
import VKillList from '@/components/hud/components/killlist/VKillList.vue';
import VReports from '@/components/hud/components/reports/VReports.vue';
import ContactsNotification from '@/components/hud/components/contactsNotification/contactsNotification.vue';
import SkillCheck from '@/components/hud/components/skillcheck/SkillCheck.vue';

export default {
    name: 'VHud',
    components: {
        SkillCheck,
        ContactsNotification,
        VReports,
        VKillList,
        PayDay,
        TimePrizes,
        ProgressAction,
        EventBadge,
        LevelUp,
        IdCard,
        TakeReward,
        InPrison,
        VOffer,
        VMobile,
        MoneyBalance,
        BaseConnection,
        GameController,
        MainButtons,
        VChat,
        HealthPoint,
        VMap
    },
    data () {
        return {
            interactActive: false,
            isMobile: false,
            isSkillCheck: false,
            isOffer: false,
            isPrison: false,
            isIdCard: false,
            isEvent: true,
            isKillList: false,
            prison: {
                nickname: 'kevin mackalister',
                rules: 'Нарушение п.2 правил: ношение оружия',
                time: 0
            },
            playerCard: {},
            levelUp: {
                show: false,
                level: 3,
                isVip: false
            },
            actionProgress: {
                show: false,
                title: 'Прогресс',
                timer: 3.5
            }
        };
    },
    computed: {
        isAdmin () {
            return this.$store.state.player.isAdmin;
        }
    },
    methods: {
        interactAction () {
            this.sendclient('Interaction');
        },
        toggleMobile () {
            setTimeout(() => {
                this.isMobile = !this.isMobile;
            }, 0);
        },
        skillCheckState (state) {
            this.isSkillCheck = state;
        },
        onProgressCompleted () {
            this.actionProgress.show = false;
        },
        // Или можно так, как удобно будет
        // initializeCard (cardId) {
        //     this.playerCard.name = cardId.name;
        //     this.playerCard.level = cardId.level;
        //     this.playerCard.reg = cardId.reg;
        //     this.playerCard.place = cardId.place;
        //     this.playerCard.daysInState = cardId.daysInState;
        //     this.playerCard.work = cardId.work;
        // }
        // window.callVueMethod('VHud','initializeCard', 'Napal Fedorov', 10, 'мужской', 'отсутствует', 5);
        initializeCard (name, level, reg, spouse, orgId) {
            console.log('initializeCard');
            this.playerCard.name = name;
            this.playerCard.level = level;
            this.playerCard.reg = reg;
            this.playerCard.spouse = spouse;
            this.playerCard.orgId = orgId;

            this.isIdCard = true;
        }
    }
};
</script>

<style>
.slide-right-enter-active, .slide-right-leave-active {
    transition: all 0.3s ease;
    opacity: 1;
}
.slide-right-enter, .slide-right-leave-to {
    transform: translateX(100%);
    opacity: 0;
}
</style>
