<template>
    <div class="sim">
        <AppHeader back-button @getBackHeader="handleBackHeader">
            <template v-if="selectedScreen === 'main'" #title>
                {{ $t('sim_card.header.main') }}
            </template>
            <template v-else #title>
                {{ $t('sim_card.header.shop') }}
            </template>
        </AppHeader>
        <SimMain
            v-if="selectedScreen === 'main'"
            :randomSimPrice="randomSimPrice"
            :changeScreen="changeScreen"
            :handleBuyRandomSim="buyRandomSim"
        />
        <transition name="fade">
            <SimShop
                v-if="selectedScreen === 'shop'"
                :simCards="simCards"
                :formatSimNumber="formatSimNumber"
                :selectSim="selectSimCard"
                :playerPhoneNumber="playerPhoneNumber"
                :isNumberSelling="isPlayerNumberOnSale"
                :playerNumberPrice="playerNumberPrice"
                @loader-displayed="handleLoaderDisplayed"
                @sellNumber="sellNumber"
                @stopSellingNumber="stopSellingNumber"
            />
        </transition>
        <transition name="fade">
            <SimBuy
                v-if="selectedSimCard"
                :card="selectedSimCard"
                :handleFormatNumber="formatSimNumber"
                :handleBuyNumber="buyNumber"
                :handleClose="closeBuy"
            />
        </transition>
    </div>
</template>

<script>
import AppHeader from '@/components/AppHeader/AppHeader.vue';
import SimMain from '@/components/popups/content/shop/components/simcard/SimMain.vue';
import SimShop from '@/components/popups/content/shop/components/simcard/SimShop.vue';
import SimBuy from '@/components/popups/content/shop/components/simcard/SimBuy.vue';

export default {
    name: 'SimCard',
    components: {
        SimBuy,
        SimShop,
        SimMain,
        AppHeader
    },
    data () {
        return {
            selectedScreen: 'main', // main, shop
            randomSimPrice: 5000,
            isPlayerNumberOnSale: false,
            playerNumberPrice: '',
            simCards: [
                /*
                {
                    seller: 'Kevin Mckalister',
                    price: 5000,
                    number: 1234
                },
                {
                    seller: 'Kevin Mckalister',
                    price: 50500,
                    number: 12345
                },
                {
                    seller: 'Kevin Mckalister',
                    price: 50500,
                    number: 12345
                },
                {
                    seller: 'Kevin Mckalister',
                    price: 50500,
                    number: 12345
                }
                 */
            ],
            selectedSimCard: null,
            simLoaded: false
        };
    },
    methods: {
        handleBackHeader () {
            this.selectedScreen === 'main' ? this.sendclient('closeDesigns') : this.selectedScreen = 'main';
        },
        /*
        window.modifyVueComponent({
            App: {
                showPopup: true,
                popupContent: 'SimCard',
                contentSubFolder: 'shop'
            },
            SimCard:{
                initialize:[
                    500
                ]
            }
        });
        */
        initialize (price) {
            this.randomSimPrice = price;
            this.simLoaded = false;
        },
        /*
        window.callVueMethod('SimCard','loadSimcards', [{
            seller: 'Kevin Mckalister',
            price: 5000,
            number: 1234
        },
        {
            seller: 'Kevin Mckalister',
            price: 50500,
            number: 12345
        },]);
        */
        loadSimcards (sims) {
            this.simCards.push(...sims);
            this.simLoaded = true;
            this.selectedScreen = 'shop';
        },
        changeScreen (type) {
            if (type === 'shop' && (!this.simCards || !this.simCards.length) && this.simLoaded === false) {
                this.sendclient('loadSimCards');
                return;
            }
            this.selectedScreen = type;
        },
        formatSimNumber (number) {
            const str = String(number);
            if (str.length === 4) {
                return str.slice(0, 2) + '-' + str.slice(2);
            }
            if (str.length === 5) {
                return str.slice(0, 3) + '-' + str.slice(3);
            }
            if (str.length === 6) {
                return str.slice(0, 2) + '-' + str.slice(2, 4) + '-' + str.slice(4);
            }
            if (str.length === 7) {
                return str.slice(0, 3) + '-' + str.slice(3, 5) + '-' + str.slice(5);
            }
            if (str.length === 8) {
                return str.slice(0, 4) + '-' + str.slice(4);
            }
        },
        selectSimCard (card) {
            this.selectedSimCard = card;
        },
        buyRandomSim () {
            this.sendclient('buyRandomSim');
        },
        buyNumber (number) {
            this.sendclient('BuySim', JSON.stringify({ a: number.number }));
        },
        closeBuy () {
            this.selectedSimCard = null;
        },
        handleLoaderDisplayed (value) {
            // console.log('Loader is displayed in SimShop:', value);
        },
        loadOwnSellSim (number, sum) {
            if (number !== 0) {
                this.isPlayerNumberOnSale = true;
                this.playerNumberPrice = sum;
            } else {
                this.isPlayerNumberOnSale = false;
                this.playerNumberPrice = '';
            }
        },
        sellNumber (value) {
            this.sendclient('SetSellSim', JSON.stringify({ a: value }));
        },
        stopSellingNumber (value) {
            this.sendclient('CancelSellSim');
        }
    },
    computed: {
        playerPhoneNumber () {
            return this.$store.state.player.phone;
        }
    }
};
</script>

<style lang="scss">
.sim {
    height: 100%;
    background: radial-gradient(138.02% 65.96% at 50.00% -0.00%, #39434F 0%, #1D2124 100%);
    &-content {
        height: calc(100% - 5vw);
    }
    &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &__descr {
            margin: 24px 0 64px;
            opacity: .8;
            color: var(--white);
            font-size: 40px;
            font-weight: 500;
            line-height: 140%;
            max-width: 542px;
            text-align: center;
        }
        &__title {
            color: var(--white);
            font-family: var(--font-stretch);
            font-size: 64px;
            font-weight: 900;
            line-height: 100%;
            letter-spacing: 2.56px;
            text-transform: uppercase;
            max-width: 600px;
        }
        &__container {
            max-width: fit-content;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        &--left {
            background-image: url("@/assets/images/simcard/bg.svg");
            background-size: cover;
            .sim-item__container {
                align-items: start;
            }
            .sim-item__descr {
                text-align: left;
            }
        }
    }
    &-main {
        display: grid;
        grid-template-columns: 1594px 1fr;
    }
    &-button {
        padding: 0 44px;
        font-size: 48px;
        &__price {
            display: flex;
            align-items: center;
            margin-left: 32px;
            border-radius: 6px;
            background: var(--dark);
            padding: 7px 12px;
            color: var(--white);
        }
        &__dollar {
            margin-right: 4px;
            color: var(--gold);
        }
        &__arrow {
            width: 64px;
            height: 64px;
            transform: rotate(180deg);
            margin-left: 18px;
        }
    }
    &-shop {
        overflow-y: auto;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 24px;
        padding: 90px 250px;
        grid-auto-rows: 194px;
        &::-webkit-scrollbar {
            display: none;
        }
        &__card {
            padding: 24px 30px;
            border-radius: 12px;
            background: rgba(20, 26, 30, 0.50);
            height: 194px;
            display: flex;
        }
        &__loader {
            grid-column: 4 span;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--white);
            font-size: 52px;
            font-weight: 900;
            line-height: 100%;
            letter-spacing: 1.04px;
            text-transform: uppercase;
            font-family: var(--font-stretch);
            padding: 32px 0;
            &-text {
                margin-left: 32px;
            }
        }
        &__about {
            margin-left: 40px;
        }
        &__number {
            font-size: 64px;
            color: var(--white);
            font-weight: 900;
            letter-spacing: 2.56px;
            font-family: var(--font-stretch);
        }
        &__price {
            margin-left: 0;
            font-size: 48px;
            font-weight: 700;
            letter-spacing: -0.96px;
            margin-top: 12px;
            width: fit-content;
        }
    }
    &-buy {
        position: fixed;
        z-index: 3;
        top: calc(5vw + 50px);
        bottom: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        left: 120px;
        right: 120px;
        border-radius: 44px;
        background: radial-gradient(46.15% 100% at 50.06% 100.00%, #26343D 0%, rgba(13, 18, 21, 0.94) 100%, rgba(13, 18, 21, 0.98) 100%);
        &__close {
            position: absolute;
            right: 40px;
            top: 40px;
            border-radius: 10000px;
            width: 130px;
            height: 130px;
            background: linear-gradient(180deg, rgba(241, 241, 241, 0.20) 0%, rgba(241, 241, 241, 0.00) 100%);
            svg {
                width: 64px;
                height: 64px;
            }
        }
        &__number {
            color: var(--white);
            font-family: var(--font-stretch);
            font-size: 128px;
            font-weight: 900;
            line-height: 100%;
            letter-spacing: 5.12px;
            text-transform: uppercase;
            margin-bottom: 32px;
        }
        &__text {
            color: rgba(241, 241, 241, .5);
            font-size: 44px;
            font-weight: 500;
            line-height: 110%;
            margin-bottom: 84px;
        }
    }
    &-sell {
        grid-column: 4 span;
        justify-content: space-between;
        transition: .3s ease-out;
        &--active {
            background: linear-gradient(136deg, rgba(244, 204, 139, 0.40) 0%, rgba(255, 230, 159, 0.00) 100%);
        }
        &__about {
            display: flex;
            align-items: center;
        }
        &__text {
            color: var(--white);
            text-align: center;
            font-size: 40px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            opacity: .8;
            margin-bottom: 12px;
        }
        .input {
            margin-right: 24px;
            height: 146px;
            width: 586px;
            font-weight: 500;
            font-size: 64px;
            line-height: 110%;
            border-radius: 4px;
            border: 4px solid rgba(241, 241, 241, 0.10);
            background: linear-gradient(180deg, rgba(32, 36, 39, 0.80) 0%, rgba(32, 36, 39, 0.32) 100%);
        }
        &__control {
            display: flex;
            align-items: center;
        }
        &__approve-btn {
            height: 100%;
            padding: 0 48px;
            border-radius: 4px;
            svg {
                margin-left: 18px;
                width: 62px;
                height: 62px;
                path {
                    fill: var(--dark);
                }
            }
        }
        &__decline-btn {
            height: 100%;
        }
        &__info {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-right: 24px;
            .sim-sell__text {
                margin-bottom: 0;
            }
        }
    }
}
</style>
