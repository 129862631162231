<template>
    <div class="inventory-icon">
        <svg v-if="type === 'weapon'" width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path d="M52 9.48539H45.5658V8.43093H41.8144L41.8154 5.26381V1.57227L36.4537 4.60537V5.79283H33.7758V4.73836H32.7023V1.57318L28.951 3.7038V4.73846H10.1873V5.79292H8.57418C8.49558 3.68211 6.73012 2.10983 4.55928 2.10983C2.38551 2.10983 0.619966 3.68209 0.544394 5.79292H0.00287536V5.35341C0.00287536 5.36945 0 5.38548 0 5.40246C0 5.42038 0.00287536 5.43452 0.00287536 5.45244V15.815H7.56794L11.7948 17.9259H12.8683V18.9803C12.3306 18.9803 11.7948 19.4519 11.7948 20.0348V24.2537C11.7948 24.8375 12.3325 25.3081 12.8683 25.3081V26.8917C12.3306 26.8917 11.7948 27.3633 11.7948 27.9462V32.165C11.7948 32.7489 12.3325 33.2195 12.8683 33.2195V37.4394H15.5491V49.5723H22.5153V37.4394H23.053V24.781H33.7741C34.3645 24.781 34.309 23.7822 34.309 23.1975V17.9242H35.3824L38.5577 15.8134H41.8154V14.7589H45.5668V13.7044H52L52 9.48539ZM32.1638 21.0893C32.1638 21.6731 32.2203 22.6729 31.629 22.6729H23.0518V17.9259H32.1629L32.1638 21.0893Z" fill="#F1F1F1"/>
            </g>
        </svg>

        <svg v-if="type === 'clothes'" width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_170_18543)">
                <path d="M25.6719 12.8798C25.9454 11.1297 27.422 9.87204 29.1717 9.87204C29.3903 9.87204 29.6638 9.87204 29.8827 9.92658L38.4135 11.7858L40.4917 8.0124C40.7652 7.46539 40.6557 6.80933 40.1636 6.42672L34.0932 1.50524C32.726 0.411637 30.9759 -0.135412 29.2262 0.0286442L26.656 0.302149C26.5465 0.302149 26.4374 0.411627 26.3825 0.575653C26.109 2.16133 24.4683 3.47397 22.3905 3.69291C20.3124 3.91148 18.4532 2.87279 17.8517 1.39613C17.7971 1.28665 17.6876 1.17755 17.5236 1.17755L15.0083 1.39575C13.2582 1.55977 11.6179 2.37989 10.4694 3.74702L5.38387 9.70762C4.94634 10.1451 4.94634 10.8562 5.38387 11.3483L8.9932 15.6137C9.43073 16.1607 10.3057 16.2153 10.7978 15.7232L13.2041 13.536L15.7198 29.9416C15.8293 30.5977 16.4308 31.0352 17.0869 31.0352L22.829 30.4882L25.6719 12.8798Z" fill="#F1F1F1"/>
                <path d="M46.1241 16.0518L29.3357 12.5519C28.7887 12.4425 28.2421 12.8254 28.1871 13.3721L22.6099 47.9878C22.5004 48.4799 22.8834 48.972 23.3755 49.0814L27.2583 49.9016C27.7504 50.0111 28.2424 49.683 28.4069 49.1905L35.5706 23.8713C35.6251 23.7072 35.8441 23.7618 35.8441 23.9258L32.2344 50.0654C32.1798 50.5574 32.5079 51.0495 32.9999 51.159L36.8828 51.9791C37.3749 52.0886 37.8669 51.7605 38.0314 51.2681L46.8903 17.2538C47.0543 16.7072 46.6713 16.1602 46.1243 16.0508L46.1241 16.0518Z" fill="#F1F1F1"/>
            </g>
            <defs>
                <clipPath id="clip0_170_18543">
                    <rect width="52" height="52" fill="white"/>
                </clipPath>
            </defs>
        </svg>

        <svg v-if="type === 'food'" width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path d="M29.1561 44.8691C29.872 44.8691 30.3927 45.5851 30.1321 46.2361C29.5465 47.9934 27.9195 49.23 26.0319 49.23H4.36005C2.40751 49.23 0.7805 47.9934 0.259855 46.2361C0.0646477 45.5851 0.519979 44.8691 1.23588 44.8691H29.1555H29.1561Z" fill="#F1F1F1"/>
                <path d="M2.14766 24.4985C0.911007 24.4985 -4.39584e-06 23.3273 0.260551 22.1556C1.56205 16.1034 7.74459 11.4824 15.2291 11.4824C22.7131 11.4824 28.896 16.1034 30.1976 22.1556C30.4578 23.3923 29.5466 24.4985 28.3105 24.4985H2.14766Z" fill="#F1F1F1"/>
                <path d="M1.95242 32.5034H7.87487C8.39558 32.5034 8.91627 32.6986 9.2418 33.0891L14.5786 38.4259C14.9041 38.7514 15.4897 38.7514 15.8153 38.4259L21.1521 33.0891C21.5426 32.6986 21.9983 32.5034 22.5191 32.5034H28.4415C29.5478 32.5034 30.3941 31.6572 30.3941 30.5509V30.0951C30.3941 28.9888 29.5479 28.1426 28.4415 28.1426L1.95253 28.143C0.846222 28.143 0 28.9892 0 30.0956V30.5509C0 31.6572 0.846072 32.5034 1.95242 32.5034Z" fill="#F1F1F1"/>
                <path d="M0 38.8157V39.2715C0 40.3778 0.846187 41.224 1.95253 41.224H9.17649C9.95731 41.224 10.2828 40.3129 9.76211 39.7923L7.4192 37.4494C7.02879 37.0589 6.57302 36.8637 6.05228 36.8637L1.95255 36.8633C0.846234 36.8633 0 37.7094 0 38.8157Z" fill="#F1F1F1"/>
                <path d="M21.2162 41.1597H28.4402C29.5465 41.1597 30.3927 40.3135 30.3927 39.2071V38.7514C30.3927 37.6451 29.5465 36.7988 28.4402 36.7988H24.34C23.8193 36.7988 23.2986 36.994 22.973 37.3845L20.6301 39.7274C20.045 40.2485 20.4354 41.1597 21.2162 41.1597Z" fill="#F1F1F1"/>
                <path d="M49.9819 15.1268H48.4852C48.0947 15.1268 47.7692 14.7364 47.8342 14.346L49.7213 3.47755C49.7862 3.15205 49.5261 2.76164 49.2006 2.69672L46.2066 2.17601C45.8811 2.1111 45.4907 2.37122 45.4258 2.69672L43.343 14.5414C43.2781 14.8669 43.0175 15.0621 42.692 15.0621L34.6232 15.0616C33.5168 15.0616 32.6058 16.0377 32.6706 17.1445L32.9961 21.8952C33.061 22.9366 33.9072 23.7175 34.9487 23.7175H49.722C50.7634 23.7175 51.6091 22.9367 51.6745 21.8952L52 17.1445C51.9995 16.1026 51.0884 15.1266 49.9817 15.1266L49.9819 15.1268Z" fill="#F1F1F1"/>
                <path d="M33.4515 30.2254L34.5578 47.9924C34.6227 49.0338 35.4689 49.8147 36.5104 49.8147H48.0297C49.0711 49.8147 49.9168 49.0338 49.9823 47.9924L51.0886 30.2254C51.1535 29.1191 50.2424 28.1426 49.1361 28.1426L35.404 28.143C34.2977 28.143 33.3867 29.1191 33.4515 30.2254Z" fill="#F1F1F1"/>
            </g>
        </svg>

        <svg v-if="type === 'other'" width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path d="M11.5556 25.9941C11.5556 29.0317 8.96876 31.4941 5.77778 31.4941C2.5868 31.4941 0 29.0317 0 25.9941C0 22.9566 2.5868 20.4941 5.77778 20.4941C8.96876 20.4941 11.5556 22.9566 11.5556 25.9941Z" fill="#F1F1F1"/>
                <path d="M31.7778 25.9941C31.7778 29.0317 29.191 31.4941 26 31.4941C22.809 31.4941 20.2222 29.0317 20.2222 25.9941C20.2222 22.9566 22.809 20.4941 26 20.4941C29.191 20.4941 31.7778 22.9566 31.7778 25.9941Z" fill="#F1F1F1"/>
                <path d="M52 25.9941C52 29.0317 49.4132 31.4941 46.2222 31.4941C43.0312 31.4941 40.4444 29.0317 40.4444 25.9941C40.4444 22.9566 43.0312 20.4941 46.2222 20.4941C49.4132 20.4941 52 22.9566 52 25.9941Z" fill="#F1F1F1"/>
            </g>
        </svg>

        <svg v-if="type === 'all'" width="53" height="52" viewBox="0 0 53 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.2861 36.3996C26.2861 34.9637 27.4502 33.7996 28.8861 33.7996H47.0861C48.5221 33.7996 49.6861 34.9637 49.6861 36.3996V46.7996C49.6861 48.2356 48.5221 49.3996 47.0861 49.3996H28.8861C27.4502 49.3996 26.2861 48.2356 26.2861 46.7996V36.3996Z" fill="white"/>
            <path d="M26.2861 5.19961C26.2861 3.76367 27.4502 2.59961 28.8861 2.59961H47.0861C48.5221 2.59961 49.6861 3.76367 49.6861 5.19961V25.9996C49.6861 27.4355 48.5221 28.5996 47.0861 28.5996H28.8861C27.4502 28.5996 26.2861 27.4356 26.2861 25.9996V5.19961Z" fill="white"/>
            <path d="M2.88614 25.9996C2.88614 24.5637 4.0502 23.3996 5.48614 23.3996H18.4861C19.9221 23.3996 21.0861 24.5637 21.0861 25.9996V46.7996C21.0861 48.2356 19.9221 49.3996 18.4861 49.3996H5.48614C4.0502 49.3996 2.88614 48.2356 2.88614 46.7996V25.9996Z" fill="white"/>
            <path d="M2.88614 5.19961C2.88614 3.76367 4.0502 2.59961 5.48614 2.59961H18.4861C19.9221 2.59961 21.0861 3.76367 21.0861 5.19961V15.5996C21.0861 17.0356 19.9221 18.1996 18.4861 18.1996H5.48614C4.0502 18.1996 2.88614 17.0356 2.88614 15.5996V5.19961Z" fill="white"/>
        </svg>
    </div>
</template>

<script>
export default {
    props: {
        type: String
    }
};
</script>
