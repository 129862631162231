<template>
    <!--    #todo: Переводы-->
    <div class="bp-about">
        <div class="bp-about__grid">
            <div class="bp-about__inv">
                <img class="bp-about__img" src="@/assets/images/battlePass/icon.png" alt="" />
                <InventoryItem
                    v-for="(item, index) in craftItems"
                    :key="index"
                    :item="item"
                />
            </div>
            <div class="bp-about__data">
                <div class="bp-about__data-row">
                    <div class="bp-about__data-item bp-about__data-item--column">
                        <div class="bp-about__data-item-bold bp-about__data-item-bold--big">224</div>
                        <div class="bp-about__data-item-text">{{ $t('shop.rare_prizes') }}</div>
                    </div>
                    <div class="bp-about__data-item bp-about__data-item--column">
                        <div class="bp-about__data-item-bold bp-about__data-item-bold--big">50</div>
                        <div class="bp-about__data-item-text">{{ $t('shop.experience_points') }}</div>
                    </div>
                </div>
                <div class="bp-about__data-row">
                    <div class="bp-about__data-item">
                        <div class="bp-about__data-item-bold">2</div>
                        <div class="bp-about__data-item-text">{{ $t('shop.modern_electric_car') }}</div>
                    </div>
                </div>
                <div class="bp-about__data-row">
                    <div class="bp-about__data-item bp-about__data-item--reversed">
                        <div class="bp-about__data-item-bold">{{ $t('shop.materials') }}</div>
                        <div class="bp-about__data-item-text">{{ $t('shop.legendary_things') }}</div>
                    </div>
                </div>
                <div class="bp-about__data-row">
                    <div class="bp-about__data-item">
                        <div class="bp-about__data-item-bold">{{ $t('shop.legendary_things') }}</div>
                    </div>
                </div>
                <div class="bp-about__checkout">
                    <BaseButton class="bp-about__button" yellow @click="buy">
                        {{ $t('shop.buy_battlepass') }}
                        <div class="bp-about__button-price">
                            <IconDonate />
                            {{ $formatNum(price) }}
                        </div>
                    </BaseButton>
                    <div class="bp-about__policy">
                        {{ $t('shop.policy_know') }} <span>{{ $t('shop.policy') }}</span> {{ $t('shop.and') }} <span>{{ $t('shop.policy_second') }}</span>
                    </div>
                </div>
            </div>
        </div>
        <button class="base-popup__close-btn" @click="closePopup" style="z-index: 4;">
            <IconClose fill="#f1f1f1"/>
        </button>
    </div>
</template>

<script>
import IconDonate from '@/components/icons/IconDonate.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import InventoryItem from '@/components/popups/components/inventoryItem/InventoryItem.vue';
import { mapGetters } from 'vuex';
import IconClose from '@/components/icons/IconClose.vue';

export default {
    name: 'BattlePassAbout',
    data () {
        return {
            craftItems:
                [
                    { id: 1, name: 'inventory.medical_kit', rarity: 'ordinary', img: 'other_items/first_kit' },
                    { id: 2, name: 'inventory.outer_clothing', rarity: 'unusual', img: '0/1/35_0' },
                    { id: 3, name: 'inventory.donate', rarity: 'rare', img: 'other_items/money' },
                    { id: 4, name: 'inventory.mask', rarity: 'unusual', img: '2/7/14_1' },
                    { id: 5, name: 'inventory.pants', rarity: 'rare', img: '0/2/14_0' },
                    { id: 6, name: 'inventory.body_armor', rarity: 'unusual', img: '2/9/1_0' },
                    { id: 7, name: 'inventory.gloves', rarity: 'rare', img: '2/8/F_4_0' },
                    { id: 8, name: 'inventory.outer_clothing', rarity: 'epic', img: '0/1/28_5' },
                    { id: 9, name: 'inventory.mask', rarity: 'epic', img: '2/7/7_0' },
                    { id: 10, name: 'inventory.spray_paint', rarity: 'ordinary', img: 'other_items/spraycan' },
                    { id: 11, name: 'inventory.seeds', rarity: 'ordinary', img: 'other_items/seeds' },
                    { id: 12, name: 'inventory.glasses', rarity: 'rare', img: '2/6/19_1' },
                    { id: 13, name: 'inventory.outer_clothing', rarity: 'rare', img: '0/1/5_6' },
                    { id: 14, name: 'inventory.shoes', rarity: 'unusual', img: '0/3/15_3' },
                    { id: 15, name: 'inventory.headgear', rarity: 'rare', img: '2/5/20_1' },
                    { id: 16, name: 'inventory.car', rarity: 'legend', img: 'vehicles/09_CorC7' },
                    { id: 17, name: 'inventory.money', rarity: 'ordinary', img: 'other_items/money' },
                    { id: 18, name: 'inventory.accessories', rarity: 'epic', img: '2/4/4_0' },
                    { id: 19, name: 'inventory.pants', rarity: 'unusual', img: '0/2/15_0' },
                    { id: 20, name: 'inventory.outer_clothing', rarity: 'epic', img: '0/1/24_0' }
                ]

        };
    },
    components: {
        IconClose,
        InventoryItem,
        BaseButton,
        IconDonate
    },
    computed: {
        ...mapGetters('inventory', ['inventoryItemsPool']),
        skillPercentage () {
            return Math.round((this.currentSkillPoints / this.maxSkillPoints) * 100);
        }
    },
    props: {
        price: Number
    },
    methods: {
        buy () {
            this.$emit('buyBattlePass');
        },
        closePopup () {
            this.$emit('changeScreen', 'main');
        }
    }
};
</script>

<style lang="scss" scoped>
.bp {
    &-about {
        position: relative;
        height: 100%;
        background: radial-gradient(110.26% 113.85% at 30% 22.35%, rgba(66, 50, 159, 0.60) 33.33%, rgba(255, 162, 76, 0.60) 100%);
        box-shadow: -36px -36px 120px 0px rgba(255, 189, 67, 0.50) inset;
        padding-left: 136px;
        padding-right: 225px;
        &__img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 794px;
            z-index: 4;
            height: 784px;
        }
        &__grid {
            height: 100%;
            display: grid;
            grid-template-columns: 952px 1108px;
            grid-gap: 0 111px;
            align-items: center;
            z-index: 3;
            position: relative;
        }
        &__inv {
            position: relative;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 24px;
            transform: translateY(-140px);
            &-item {
                border-radius: 4px;
                background: grey;
                height: 280px;
            }
        }
        &__data {
            height: 100%;
            padding: 118px 0;
            &-row {
                display: flex;
                align-items: center;
                margin-bottom: 21px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            &-item {
                border-radius: 12px;
                background: var(--white);
                padding: 32px 44px;
                color: var(--dark);
                font-size: 54px;
                display: flex;
                align-items: center;
                line-height: 118%;
                margin-right: 21px;

                &--reversed {
                    flex-direction: row-reverse;

                    .bp-about__data-item-bold {
                        margin-right: 0;
                        margin-left: 20px;
                    }
                }

                &--column {
                    flex-direction: column;
                    align-items: start;
                    .bp-about__data-item-bold {
                        margin-right: 0;
                        margin-left: 0
                    }
                }

                &-bold {
                    margin-right: 20px;
                    font-weight: 700;
                    &--big {
                        font-size: 82px;
                    }
                }
            }
        }
        &__checkout {
            margin-top: 80px;
            display: flex;
            flex-direction: column;
        }
        &__button {
            padding: 0 48px;
            font-size: 48px;
            width: fit-content;
            &-price {
                padding: 7px 12px;
                background-color: var(--dark);
                color: var(--white);
                margin-left: 32px;
                display: flex;
                align-items: center;
                border-radius: 6px;
                svg {
                    width: 48px;
                    height: 48px;
                    margin-right: 6px;
                }
            }
        }
        &__policy {
            margin-top: 24px;
            color: var(--white);
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            span {
                text-decoration-line: underline;
            }
        }
    }
    &-main {
        &__banner {
            &-overflow {
                z-index: 2;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                mix-blend-mode: screen;
            }
            &-bg {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>
