<template>
    <div
        class="login__step-label"
        :class="{
            'login__step-label--register': isRegister,
            'login__step-label--auth': isAuth,
            'login__step-label--server': isServer
        }"
    >
        <span v-if='isRegister'>{{ $t('StepLabel.register') }}</span>
        <span v-if='isAuth'>{{ $t('StepLabel.authorization') }}</span>
        <span v-if='isServer'>{{ $t('StepLabel.chooseServer') }}</span>
    </div>
</template>

<script>
export default {
    name: 'StepLabel',
    props: {
        isRegister: Boolean,
        isAuth: Boolean,
        isServer: Boolean
    }
};
</script>

<style lang="scss">
.login__step-label{
    background: #fff;
    width: 269px;
    min-width: 269px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    span{
        color: var(--white);
        font-size: 64px;
        writing-mode: vertical-rl;
        position: relative;
        right: 44px;
    }

    &--register{
        background: var(--dark);
        span{
            color: var(--white);
        }
    }

    &--auth{
        background: var(--gold);
        span{
            color: var(--dark);
        }
    }

    &--server{
        background: var(--white);
        span{
            color: var(--dark);
        }
    }

}
</style>
