<template>
    <div class="checkbox">
        <input type="checkbox" id="checkbox"
               :checked="value"
               @change="$emit('input', $event.target.checked)">
        <label for="checkbox">{{ $t('base_checkbox.agreement') }} <a href="#">{{ $t('base_checkbox.policy_conf') }}</a> {{ $t('base_checkbox.and_policy') }} <a
            href="#">{{ $t('base_checkbox.personal_data') }}</a></label>
    </div>
</template>

<style lang='scss'>
.checkbox{

    display: flex;
    align-items: center;

    a{
        text-decoration: underline;
        color: #F1F1F1;

    }

    label{
        font-size: 1.3vw;
        font-weight: 400;
        line-height: 120%;
        color: #F1F1F1;
        opacity: 0.6;
    }

    input{
        width: 5vw;
        height: 3.5vw;
        display: flex;
        background: url(@/assets/images/checkbox.svg) no-repeat center/contain;
        margin-right: 1vw;
        transition: .1s ease-out;

        &:checked{
            background: url(@/assets/images/checkbox-true.svg) no-repeat center/contain;
        }
    }
}
</style>
<script>
export default {
    name: 'BaseCheckbox',
    props: {
        value: {
            type: Boolean,
            default: false
        }
    }
};
</script>
