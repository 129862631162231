<template>
    <div class="estate__nav-item">
        <button class="glass-button estate__nav-map" @click="handleClick('map')">
            <span class="glass-button__border"></span>
            <span class="glass-button__border"></span>
            <span class="glass-button__border"></span>
            <span class="glass-button__border"></span>
            <img src="@/assets/images/estate/location.svg" alt="">
        </button>
        <BaseButton white class="estate__nav-control" @click.native="handleClick('manage')">
            <IconSettingsBlack />
            {{ $t('estate.manage') }}
        </BaseButton>
    </div>
</template>

<script>
import BaseButton from '@/components/ui/BaseButton.vue';
import IconSettingsBlack from '@/components/icons/IconSettingsBlack.vue';

export default {
    name: 'EstateControls',
    components: { IconSettingsBlack, BaseButton },
    methods: {
        handleClick (action) {
            this.$emit('controlClicked', action);
        }
    }
};
</script>
