<template>
        <div class="chat__popup" @click="closeChat">
            <div class="chat__popup-wrapper" @click.stop>
                <div class="chat__popup-icon" v-if="$slots.icon">
                    <slot name="icon"></slot>
                </div>
                <BaseInput
                    mini
                    focused
                    autofocus
                    type="text"
                    :placeholder="this.placeholder"
                    class="chat__popup-input"
                    v-model="inputValue"
                    @enter-pressed="sendMessage"
                />
                <button @click="sendMessage">
                    <ChatSvg :icon="10"/>
                </button>
            </div>
            <div class="chat__popup-wrapper" @click.stop v-if="$slots.info">
                <slot name="info"></slot>
            </div>
        </div>
</template>

<script>
import ChatSvg from '@/components/hud/components/chat/icons/ChatSvg.vue';
import BaseInput from '@/components/ui/BaseInput.vue';

export default {
    name: 'BasePopupChat',
    components: {
        BaseInput, ChatSvg
    },
    props: {
        placeholder: String,
        closeChat: Function
    },
    data () {
        return {
            inputValue: ''
        };
    },
    methods: {
        sendMessage () {
            this.$emit('send-answer', this.inputValue);
            this.closeChat();
        }
    }
};
</script>

<style lang="scss" scoped>
.chat {
    &__popup{
        z-index: 5;
        width: 100%;
        background: rgba(20, 26, 30, 0.94);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        padding: 90px 128px 0;
        &-input {
            flex-grow: 1;
        }
        &-wrapper{
            display: flex;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
        &-item {
            display: flex;
            padding: 24px 32px;
            justify-content: center;
            align-items: center;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            margin-right: 18px;
            height: 92px;
            border-radius: 12px;
            transition: .2s ease-out;
            box-shadow: inset 0 0 0 0 transparent;
        }
        button {
            display: flex;
            width: 238px;
            height: 154px;
            padding: 32px 24px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            background: linear-gradient(225deg, rgba(241, 241, 241, 0.20) 0%, rgba(241, 241, 241, 0.00) 100%);
        }
        &-icon {
            width: 220px;
            height: 154px;
        }
    }
}

</style>
