<template>
    <svg width="310" height="171" viewBox="0 0 310 171" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4479_54729)">
            <path d="M270.422 109.468C280.607 99.8585 287.949 92.4299 292.434 87.1824C298.671 79.7697 303.156 72.6967 305.897 65.9398C308.638 59.1909 310 52.4498 310 45.7403C310 32.5427 305.554 21.6606 296.654 13.094C287.762 4.52744 275.514 0.244141 259.926 0.244141C251.61 0.244141 244.112 1.69035 237.416 4.55115L172.08 7.24599L228.477 9.5694C227.075 10.5731 225.705 11.6399 224.397 12.8016C219.857 16.8162 216.12 21.6843 213.169 27.3901L191.881 28.5834L212.032 29.7135C209.898 34.3209 208.24 39.4418 207.064 45.0765L172.905 46.989L206.348 48.8619C206.348 48.9015 206.332 48.941 206.324 48.9805L238.373 53.8407C240.351 43.4248 243.279 36.2254 247.156 32.2503C251.034 28.2752 255.558 26.2916 260.728 26.2916C265.898 26.2916 270.025 28.0698 273.334 31.6181C274.37 32.7324 275.226 33.9573 275.935 35.285L258.4 36.2649L276.861 37.3002C277.804 39.7342 278.294 42.4607 278.294 45.5112C278.294 46.9416 278.123 48.451 277.804 50.0236L251.283 51.5094L277.04 52.9556C276.589 54.4018 276.02 55.8954 275.327 57.4443C274.167 60.0285 272.408 62.9052 270.087 66.0584L247.499 67.3228L268.234 68.4845C267.066 69.9623 265.781 71.4876 264.38 73.076C262.932 74.6961 260.487 77.2329 257.068 80.6706L221.251 82.6779L253.261 84.4718C250.349 87.3563 246.954 90.6913 243.053 94.4925C242.944 94.5952 242.843 94.698 242.734 94.8007L158.298 98.0329L236.333 101.02C226.842 110.282 219.655 117.584 214.78 122.919L176.316 125.076L211.168 127.028C207.485 131.391 204.222 135.982 201.372 140.811L182.989 141.838L200.228 142.802C199.472 144.146 198.748 145.497 198.055 146.88C197.066 148.848 196.179 150.855 195.384 152.878L159.629 154.878L193.967 156.798C193.064 159.524 192.324 162.29 191.74 165.112L134.705 167.459L190.938 169.774C190.9 170.067 190.845 170.359 190.806 170.659H212.406L221.017 171.015V170.659H292.426L298.585 140.329H240.647C243.684 136.393 246.86 132.671 250.17 129.154C253.479 125.645 260.222 119.086 270.414 109.476L270.422 109.468Z" fill="#FFCD4C"/>
            <path d="M212.008 0.244986H179.975V0L175.591 0.244986H172.204L172.079 0.442555L133.256 2.61581L169.432 4.63892L160.906 18.1526L137.936 19.4408L159.341 20.642L150.815 34.1478L136.472 34.9539L124.481 1.97569V0.529485L123.967 0.553193L123.858 0.244986H89.0686L89.7071 1.95989L38.1689 4.08573L91.3033 6.27479L98.9107 26.8141L67.3057 28.5843L100.25 30.4256L105.95 45.8044L84.9263 46.9819L106.845 48.2069L113.121 65.1504L74.656 67.3078L114.756 69.5522L119.007 81.0191L89.676 82.6629L119.257 84.3225L112.397 95.0149L33.7229 98.0259L108.628 100.887L94.6593 122.659L51.7406 125.061L91.6849 127.298L83.2522 140.432L58.4136 141.823L81.5314 143.119L75.4502 152.602L35.0622 154.863L72.6549 156.965L67.7183 164.662L0 167.444L64.2067 170.091L63.8485 170.644H77.7005L86.3123 171V170.644H103.995L124.582 137.951L139.937 138.812L145.395 153.772L126.023 154.855L146.205 155.985L149.577 165.223L109.897 167.444L151.235 169.759L151.562 170.644H186.352L155.783 88.6532L212.008 0.244986ZM148.23 38.2336L141.526 48.8628L137.438 37.633L148.23 38.2336ZM125.431 136.607L134.105 122.833L138.855 135.856L125.423 136.607H125.431Z" fill="#FFCD4C"/>
        </g>
        <defs>
            <clipPath id="clip0_4479_54729">
                <rect width="310" height="171" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>
<script>
export default {
    name: 'DoubleCurrency'
};
</script>
<style lang="scss" scoped>
path {
    animation: colorChange 3s infinite ease-out;
}
@keyframes colorChange {
    0% {
        fill: var(--white);
    }
    25% {
        fill: var(--yellow);
    }
    50% {
        fill: var(--white);
    }
    75% {
        fill: var(--yellow);
    }
    100% {
        fill: var(--white);
    }
}
</style>
