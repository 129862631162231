<template>
    <div class="warehouse">
        <AppHeader back-button>
            <template #title>{{ organization }}</template>
        </AppHeader>
        <div class="warehouse__wrapper">
            <div class="warehouse__aside">
                <div class="warehouse__available">
                    <span
                        :class="{ 'access': isAvailable }"
                    >
                        <IconCheckmark fill="#F1F1F1" v-if="isAvailable" />
                        <IconClose fill="#F1F1F1" v-else/>
                        {{ isAvailable ? $t('family.warehouse_available') : $t('family.warehouse_unavailable')}}
                    </span>
                </div>

                <div class="warehouse__aside-description">
                    {{ $t('family.warehouse.info') }}
                </div>

                <div class="warehouse__settings-param"
                     @click="activateSetting"
                >
                    <input type="checkbox" class="warehouse__settings-slidebox" :checked="isChecked" />
                    <div class="warehouse__settings-param-descr" :class="{ 'warehouse__settings-param-descr--checked': isChecked }">
                        {{ $t('family.warehouse.put_item') }}
                    </div>
                </div>
            </div>
            <div class="warehouse__items">
                <p class="warehouse__items-title" :class="{ checked: isChecked }"><!--v-if="isAvailable"-->
                    {{ isChecked ? $t('family.warehouse.item_to_put') : $t('family.warehouse.item_to_take') }}
                </p>
                <div class="warehouse__items-wrapper">
                    <InventoryItem
                        v-for="(item, index) in inventoryItems"
                        :key="index"
                        :item="item"
                        :selectedItem="selectedItem"
                        :isChecked="isChecked"
                        :playerItems="inventoryItemsPlayer"
                        @select-item="selectItem"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppHeader from '@/components/AppHeader/AppHeader.vue';
import IconClose from '@/components/icons/IconClose.vue';
import IconCheckmark from '@/components/icons/IconCheckmark.vue';
import { mapGetters } from 'vuex';
import InventoryItem from '@/components/popups/components/inventoryItem/InventoryItem.vue';

export default {
    name: 'WareHouse',
    components: { InventoryItem, IconCheckmark, IconClose, AppHeader },
    data () {
        return {
            organization: this.$t('family.warehouse.title'),
            isChecked: false,
            isAvailable: true,
            selectedItem: null,
            inventoryItems: [],
            inventoryItemsPlayer: []
        };
    },
    computed: {
        ...mapGetters('inventory', ['inventoryItemsPool'])
    },
    methods: {
        /*
        window.modifyVueComponent({
            App: {
                showPopup: true,
                popupContent: 'WareHouse',
                contentSubFolder: 'organization'
            },
            WareHouse: {
                InitializeInv: [
                    [
                        { itemID: 1, count: 500, additional: 0 },
                        { itemID: 2, count: 600, additional: 0 },
                    ],
                ],
                InitializeInvPlayer:[
                    [
                        { itemID: 1, count: 500, additional: 0 },
                    ],
                ]
            }
        });
        */
        activateSetting () {
            this.isChecked = !this.isChecked;
            console.log(this.isChecked);
        },
        selectItem (item) {
            if (!this.selectedItem || this.selectedItem.id !== item.id) {
                // this.selectedItem = item;
                // console.log(item);
                if (this.isChecked) this.sendclient('WarehousePut', JSON.stringify({ a: item.index }));
                else this.sendclient('WarehouseTake', JSON.stringify({ a: item.index }));
            }
        },
        updateCount (index, count) {
            if (this.inventoryItems[index - 1]) this.inventoryItems[index - 1].count = count;
        },
        InitializeInv (isAvailable, itemList) {
            this.isAvailable = isAvailable;
            for (let i = 0; i < itemList.length; i++) {
                if (itemList[i].itemID) {
                    const newItem = Object.assign({}, this.inventoryItemsPool[itemList[i].itemID - 1]);
                    newItem.count = itemList[i].count;
                    newItem.index = this.inventoryItems.length + 1;
                    newItem.additional = itemList[i].additional;
                    this.inventoryItems.push(newItem);
                }
            }
        },
        InitializeInvPlayer (itemList) {
            for (let i = 0; i < itemList.length; i++) {
                if (itemList[i].itemID) {
                    const newItem = Object.assign({}, this.inventoryItemsPool[itemList[i].itemID - 1]);
                    newItem.count = itemList[i].count;
                    newItem.index = this.inventoryItemsPlayer.length + 1;
                    newItem.additional = itemList[i].additional;
                    this.inventoryItemsPlayer.push(newItem);
                }
            }
        }
    }
};
</script>

<style scoped lang="scss">
.warehouse{
    background: url(@/assets/images/organization/warehouse-bg.jpg) no-repeat center / cover;
    height: 100%;
    display: flex;
    flex-direction: column;

    &__wrapper{
        padding: 90px 256px 90px 363px;
        display: grid;
        grid-template-columns: 557px 1fr;
        grid-gap: 128px;
        align-items: center;
        height: 100%;
        flex-shrink: 1;

    }

    &__items{
        &-title{
            color: #F1F1F1;
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            letter-spacing: 1.28px;
            text-transform: uppercase;
            margin-bottom: 32px;

            &.checked{
                color: #FFCD4C;
            }
        }

        &-wrapper{
            display: flex;

            .inventory__cards-item{
                width: 220px;
                height: 280px;
                margin: 0 .5vw 0 0;
            }

        }
    }

    &__available{
        display: flex;

        span{
            padding: 20px 24px;
            border-radius: 10px;
            background: rgba(255, 255, 255, 0.30);
            color: #F1F1F1;
            text-align: right;
            font-size: 36px;
            font-weight: 900;
            line-height: 100%;
            letter-spacing: 1.44px;
            text-transform: uppercase;
            font-family: var(--font-stretch);
            margin-right: 12px;
            display: flex;
            align-items: center;

            &.access{
                background: #27AE60;
            }
            &::v-deep {
                svg{
                    width: 44px;
                    height: 44px;
                    margin-right: 10px;

                    path{
                        fill: #F1F1F1;
                    }
                }
            }
        }
    }

    &__aside{
        &-description{
            color: #F1F1F1;
            font-size: 40px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            margin: 64px 0;
        }
    }

    &__settings {
        &-title {
            margin-bottom: 32px;
        }
        &-item {
            margin-bottom: 80px;
        }
        &-button {
            color: var(--white);
            font-size: 48px;
            line-height: 130%;
            height: 146px;
            &-icon {
                margin-left: 32px;
                width: 62px;
                height: 62px;
            }
            &:last-child {
                margin-left: 32px;
            }
        }
        &-buttons {
            display: flex;
        }
        &-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 32px 120px;
        }
        &-param {
            display: flex;
            align-items: center;
            &-descr {
                color: var(--white);
                font-size: 48px;
                font-weight: 500;
                line-height: 110%;
                transition: .2s ease-out;

                &--checked{
                    color: #FFCD4C;
                }
            }
        }
        &-slidebox {
            min-width: 240px;
            width: 240px;
            height: 146px;
            margin-right: 44px;
            border-radius: 300px;
            background: linear-gradient(225deg, rgba(241, 241, 241, 0.20) 0%, rgba(241, 241, 241, 0.00) 100%);
            position: relative;
            transition: all .2s ease-out;
            &:checked {
                &::after {
                    background-image: url("@/assets/images/slidebox-on.svg");
                    box-shadow: 16px 0 64px 0 rgba(255, 205, 76, 0.32);
                    transform: translateX(100%);
                }
            }
            &::after {
                content: '';
                position: absolute;
                transition: all .2s ease-out;
                border-radius: 50%;
                width: 98px;
                height: 98px;
                left: 24px;
                top: 24px;
                bottom: 24px;
                background-image: url("@/assets/images/slidebox-off.svg");
                background-size: 100%;
                background-position: center;
            }
        }
    }
}
</style>
