export default {
    methods: {
        createObserver (target, callback) {
            const options = {
                root: null,
                rootMargin: '0px',
                threshold: 1.0
            };

            const observer = new IntersectionObserver(([entry], observer) => {
                if (entry.isIntersecting) {
                    callback();
                    observer.unobserve(entry.target);
                }
            }, options);

            observer.observe(target);

            return observer;
        }
    }
};
