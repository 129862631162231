<template>
    <div>
        <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path v-if="level === 1 || level === 2 || level === 3 || level === 4 || level === 5" d="M53.4843 8.93142C44.3277 8.93142 36.8266 4.99891 36.0757 0H35.9254C35.1744 4.99891 27.6761 8.93142 18.5199 8.93142C15.0053 8.93142 11.7392 8.34821 9 7.35171V41.2392H9.06451L36 62.3078L62.9355 41.2392H63V7.35171C60.2614 8.34882 56.9939 8.93267 53.4831 8.93267L53.4843 8.93142ZM43.5219 41.4968L36.0012 37.5503L28.4792 41.4957L29.9143 33.1402L23.8297 27.2256L32.239 26.0051L36.0012 18.4076L39.7604 26.0064L48.1727 27.2268L42.0845 33.1414L43.5219 41.4968Z" fill="#FFCD4C"/>
            <path v-if="level === 2 || level === 3 || level === 4 || level === 5" d="M36 71.9907L62.9356 50.9222H63.0001V45.2578H62.9356L36.0001 66.3264L36 71.9907Z" fill="#FFCD4C"/>
            <path v-if="level === 3 || level === 4 || level === 5" d="M9 45.2578V50.9222H9.06451L36 71.9907L62.9355 50.9222H63V45.2578H62.9355L36 66.3264L9.06451 45.2578H9Z" fill="#FFCD4C"/>
            <path v-if="level === 4 || level === 5" d="M8.99996 55.125L16.1052 60.7893L8.99996 71.9927V55.125Z" fill="#FFCD4C"/>
            <path v-if="level === 5" d="M63.0004 55.125L55.8951 60.7893L63.0004 71.9927V55.125Z" fill="#FFCD4C"/>
        </svg>
    </div>
</template>
<script>
export default {
    name: 'IconWanted',
    props: {
        level: Number
    }
};
</script>
