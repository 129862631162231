var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tabs",class:{'tabs--scrollable': _vm.isScrollable,}},[_vm._l((_vm.items),function(item){return _c('div',{key:item.type,staticClass:"tabs__item",class:{
             'tabs__item--active': _vm.activeTab === item.type,
             'tabs__item--disabled': item.requiredLevel > _vm.playerLevel,
             'tabs__item--has-icon': item.icon,
             'tabs__item--hidden': _vm.currentSubmenu
         },on:{"click":function($event){return _vm.changeTab(item)}}},[(item.icon)?_c('IconBaseTabMenu',{staticClass:"tabs__item-icon",attrs:{"iconSize":2.1,"type":item.icon}}):_vm._e(),(item.baseIcon)?_c('BaseIcon',{staticClass:"tabs__base-icon",attrs:{"name":item.baseIcon,"color":"#F1F1F1"}}):_vm._e(),_vm._v(" "+_vm._s(item.requiredLevel > _vm.playerLevel ? `available from level ${item.requiredLevel}` : item.text)+" "),(item.requiredLevel > _vm.playerLevel)?_c('BaseLevel',{staticClass:"tabs__level",attrs:{"levelIcon":item.requiredLevel < 3 ? item.requiredLevel : 3,"width":1.75}}):_vm._e(),(_vm.activeTab === item.type)?_c('IconBorder',{attrs:{"border":"tabMenuBorder"}}):_vm._e(),(item.isNew)?_c('IconNew',{staticClass:"tabs__new"}):_vm._e()],1)}),(_vm.currentSubmenu)?[_c('div',{staticClass:"tabs__item tabs__item--back tabs__item--has-icon",on:{"click":_vm.getBack}},[_c('IconBaseTabMenu',{staticClass:"tabs__item-icon",attrs:{"iconSize":2.1,"type":"back"}}),_vm._v(" "+_vm._s(_vm.backButtonText)+" ")],1),_vm._l((_vm.currentSubmenu),function(item){return _c('div',{key:item.type,staticClass:"tabs__item",class:{
                'tabs__item--active': _vm.activeTab === item.type,
                'tabs__item--disabled': item.requiredLevel > _vm.playerLevel,
                'tabs__item--has-icon': item.icon
            },on:{"click":function($event){return _vm.changeTab(item)}}},[(item.icon)?_c('IconBaseTabMenu',{staticClass:"tabs__item-icon",attrs:{"iconSize":2.1,"type":item.icon}}):_vm._e(),_vm._v(" "+_vm._s(item.requiredLevel > _vm.playerLevel ? `available from level ${item.requiredLevel}` : item.text)+" "),(item.requiredLevel > _vm.playerLevel)?_c('BaseLevel',{staticClass:"tabs__level",attrs:{"levelIcon":item.requiredLevel < 3 ? item.requiredLevel : 3,"width":1.75}}):_vm._e(),(_vm.activeTab === item.type)?_c('IconBorder',{attrs:{"border":"tabMenuBorder"}}):_vm._e(),(item.isNew)?_c('IconNew',{staticClass:"tabs__new"}):_vm._e()],1)})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }