<template>
    <div class="inventory-btn-action">
        <svg v-if="icon === 'trash'" width="44" height="45" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M35.75 39.8684V16.7105H8.24996V39.8684C8.24996 42.4264 10.302 44.5 12.8333 44.5H31.1666C33.6979 44.5 35.75 42.4264 35.75 39.8684Z" fill="white"/>
            <path d="M16.1498 3.06027L13.9791 7.44736H3.66663V12.0789H40.3333V7.44736H30.0208L27.8501 3.06027C27.0737 1.49117 25.4867 0.5 23.7506 0.5H20.2493C18.5132 0.5 16.9262 1.49117 16.1498 3.06027Z" fill="white"/>
        </svg>

        <svg v-if="icon === 'divide'" width="44" height="45" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_170_18372)">
                <path d="M29.4824 20.0193L37.2844 12.2243L40.2499 15.1871C40.9799 15.9165 42.166 15.4608 42.3029 14.5031L43.9913 1.87629C44.0826 1.05561 43.4439 0.417478 42.6225 0.508695L29.9843 2.19554C29.0264 2.33227 28.57 3.51767 29.2997 4.24677L32.2652 7.20956L24.4635 15.0049C24.0529 15.4151 24.0529 16.0535 24.4635 16.4637L28.0222 20.0193C28.4328 20.4294 29.0719 20.4295 29.4824 20.0193Z" fill="#F1F1F1"/>
                <path d="M13.103 10.5834L10.0917 13.592C9.72661 13.9567 9.72662 14.5036 10.0917 14.8683L29.6647 34.4238C30.0297 34.7885 30.5771 34.7885 30.9422 34.4238L33.9535 31.4152C34.3185 31.0505 34.3185 30.5036 33.9535 30.1388L14.3804 10.5834C14.0154 10.2186 13.468 10.2187 13.103 10.5834Z" fill="#F1F1F1"/>
                <path d="M1.33197 44.4977L13.9701 42.8108C14.9281 42.6741 15.3845 41.4887 14.6547 40.7596L11.6893 37.7968L19.4913 30.0018C19.9018 29.5916 19.9018 28.9531 19.4913 28.543L15.9325 24.9874C15.522 24.5772 14.8829 24.5772 14.4724 24.9874L6.7156 32.7372L3.75015 29.7745C3.02008 29.045 1.83399 29.5007 1.69708 30.4584L0.00871885 43.0852C-0.0826204 43.9053 0.556346 44.5437 1.33197 44.4977Z" fill="#F1F1F1"/>
            </g>
            <defs>
                <clipPath id="clip0_170_18372">
                    <rect width="44" height="44" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
            </defs>
        </svg>

        <svg v-if="icon === 'handOver'" width="44" height="45" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_170_18377)">
                <path d="M30.6309 30.5528C30.9618 30.815 31.2927 30.881 31.6896 30.881C32.0864 30.8155 32.4173 30.6187 32.6817 30.356C33.2111 29.6996 33.0786 28.8469 32.4833 28.322L26.066 23.0726C25.6691 22.7444 25.6027 22.154 25.8676 21.7604C26.132 21.3008 26.7278 21.1699 27.1906 21.4322L36.9158 26.2879C37.6435 26.6161 38.5037 26.4193 38.8341 25.6975C39.0325 25.3038 39.0985 24.9102 38.9666 24.582C38.9006 24.1884 38.6357 23.8602 38.3048 23.7289L28.9104 18.4795C28.4471 18.2172 28.3151 17.6922 28.4471 17.2326C28.6455 16.7731 29.1748 16.5108 29.6377 16.6422L42.0103 20.0539C42.6721 20.2507 43.3999 19.9884 43.7303 19.4635C44.1936 18.6103 43.7963 17.6262 42.9366 17.2981L31.0944 13.4924C30.631 13.361 30.3006 12.836 30.4326 12.3115C30.565 11.7865 30.9619 11.4584 31.4912 11.5243L42.4735 12.3115C43.2672 12.377 43.929 11.7865 43.995 10.9993C44.061 10.2121 43.4657 9.49026 42.672 9.35891L27.4557 7.784C25.5374 11.7866 21.3033 12.377 19.12 12.0488C18.6567 11.9834 18.2598 11.6552 18.1938 11.1302C18.1278 10.4739 18.7231 9.94936 19.3184 10.0148C19.5169 10.0148 24.2802 10.6052 25.8681 6.20906C25.9341 6.01224 26.0666 5.81543 26.265 5.68407C27.1912 5.09363 28.1833 4.70001 29.242 4.43724L33.1452 3.51864C33.7405 3.38728 34.2038 2.86229 34.3358 2.27181C34.4018 1.81228 34.2698 1.35321 33.9389 1.02499C33.608 0.696766 33.1451 0.5 32.6818 0.5L28.4477 0.762266C24.346 1.02453 18.1272 1.41861 14.2238 5.15855C13.9594 5.42082 13.562 5.48673 13.2316 5.42082L1.05863 1.48361C0.529312 1.2868 3.7631e-06 1.68042 3.73729e-06 2.27086L3.30706e-06 12.1133C3.29272e-06 12.4415 0.198444 12.7697 0.595321 12.9006L9.85715 15.8532C10.254 15.9845 10.5849 16.3782 10.5849 16.8372C10.5189 20.5116 15.0834 21.9553 18.7886 23.1366C20.244 23.5962 21.5011 23.9898 22.3608 24.5148C23.9487 25.3679 25.2054 26.2207 26.264 27.0738L30.6309 30.5528Z" fill="#F1F1F1"/>
                <path d="M12.6549 43.9362C12.7512 44.0419 12.8864 44.1037 13.0294 44.1073C13.1723 44.1108 13.3105 44.0557 13.4118 43.9549L19.6294 37.7669C20.1709 37.2279 20.1714 36.335 19.6304 35.7954L13.4129 29.5937C13.3117 29.4927 13.1735 29.4375 13.0306 29.4409C12.8877 29.4443 12.7523 29.5059 12.656 29.6116L11.5352 30.8402C11.3464 31.0473 11.3539 31.3662 11.5523 31.5641L15.3997 35.4017L5.63113 35.4009C5.49221 35.4009 5.35897 35.4561 5.26073 35.5543C5.16249 35.6526 5.10729 35.7858 5.10728 35.9247L5.10718 37.6227C5.10716 37.912 5.34165 38.1465 5.63093 38.1466L15.4066 38.1474L11.5533 41.9824C11.3547 42.18 11.3469 42.499 11.5355 42.7062L12.6549 43.9362Z" fill="#F1F1F1"/>
            </g>
            <defs>
                <clipPath id="clip0_170_18377">
                    <rect width="44" height="44" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
            </defs>
        </svg>

        <svg v-if="icon === 'clothes'" width="44" height="45" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5967 8.11179C21.2667 7.83427 22.0039 7.76166 22.7152 7.90314C23.4264 8.04462 24.0798 8.39384 24.5926 8.90663C25.1054 9.41943 25.4546 10.0728 25.596 10.784C25.7375 11.4953 25.6649 12.2325 25.3874 12.9025C25.1099 13.5725 24.6399 14.1452 24.0369 14.5481C23.4339 14.951 22.725 15.166 21.9998 15.166C21.5136 15.166 21.0473 15.3592 20.7034 15.703C20.3596 16.0468 20.1665 16.5131 20.1665 16.9994L20.1665 19.7973L4.64724 32.4948C1.98028 34.6769 3.52325 38.9993 6.96912 38.9993H37.0306C40.4765 38.9993 42.0195 34.6769 39.3525 32.4948L23.8332 19.7972L23.8332 18.5998C24.627 18.3949 25.385 18.0571 26.074 17.5968C27.28 16.791 28.2199 15.6457 28.7749 14.3057C29.33 12.9657 29.4752 11.4912 29.1923 10.0687C28.9093 8.64618 28.2109 7.3395 27.1853 6.31391C26.1597 5.28832 24.853 4.58989 23.4305 4.30693C22.008 4.02397 20.5335 4.16919 19.1935 4.72423C17.8535 5.27928 16.7082 6.21921 15.9024 7.42517C15.0966 8.63113 14.6665 10.049 14.6665 11.4994H18.3332C18.3332 10.7742 18.5482 10.0652 18.9511 9.46226C19.354 8.85928 19.9267 8.38931 20.5967 8.11179ZM6.96912 35.3327L21.9999 23.0348L37.0306 35.3327H6.96912Z" fill="#141A1E"/>
        </svg>

        <svg v-if="icon === 'use'" width="44" height="45" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_170_18986)">
                <path d="M14.3764 44.8088C14.4785 44.8703 14.5882 44.9184 14.7029 44.9499C14.8883 45.0009 15.0703 45.0116 15.2429 44.9882C15.5417 44.9565 15.8279 44.8292 16.0396 44.5967C16.4368 44.1601 16.834 43.7235 17.2312 43.2869C17.5987 42.883 17.9661 42.4791 18.3336 42.0752C20.1552 40.0728 21.9772 38.0703 23.7988 36.0682C25.9695 33.6823 28.1402 31.2965 30.3108 28.9107C32.113 26.93 33.9151 24.949 35.7171 22.9681L35.7441 22.9384C36.1053 22.5415 36.4665 22.1445 36.8277 21.7474C37.2101 21.327 37.5926 20.9066 37.9751 20.4863C38.3094 20.119 38.4632 19.64 38.3283 19.1497C38.1661 18.5603 37.6145 18.1412 37.0036 18.1412H24.4372C24.5922 17.6818 24.7471 17.2224 24.9021 16.7629L25.5674 14.7906C26.1789 12.978 26.7903 11.1655 27.4017 9.35293C27.7815 8.22676 28.1614 7.10057 28.5413 5.97434L28.7311 5.41173C29.0319 4.51998 29.3327 3.62835 29.6333 2.73654C29.8289 2.1569 29.5107 1.48295 29.0018 1.1851C28.488 0.884065 27.7497 0.946444 27.3371 1.39992C26.9415 1.83476 26.5458 2.26968 26.1502 2.70459C25.7811 3.11021 25.4121 3.51582 25.0431 3.92135C23.2215 5.92378 21.3996 7.9263 19.5779 9.92837C18.6326 10.9675 17.6872 12.0066 16.742 13.0456C15.5167 14.3924 14.2915 15.7392 13.0662 17.0859C11.2551 19.0765 9.44401 21.0673 7.63294 23.0582C6.88929 23.8754 6.14566 24.6927 5.40201 25.5103C5.06768 25.8776 4.91384 26.3566 5.04876 26.8468C5.21102 27.4363 5.76262 27.8554 6.37349 27.8554H18.9403C18.7359 28.4614 18.5314 29.0674 18.327 29.6733L17.81 31.206C17.4475 32.2804 17.0851 33.3548 16.7226 34.4292C16.0937 36.2935 15.4647 38.1578 14.8358 40.0222C14.6339 40.6206 14.4321 41.2188 14.2303 41.8171C14.068 42.298 13.9057 42.779 13.7434 43.26C13.6365 43.5769 13.6786 43.9099 13.8195 44.1982C13.8841 44.3411 13.9761 44.476 14.0967 44.5967C14.1801 44.68 14.2745 44.7509 14.3764 44.8088Z" fill="#141A1E"/>
            </g>
            <defs>
                <clipPath id="clip0_170_18986">
                    <rect width="44" height="44" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
            </defs>
        </svg>

    </div>
</template>

<script>
export default {
    props: {
        icon: String
    }
};
</script>
