<template>
    <div class="svg-info-craft" @click="handleClick">
        <svg v-if="name === 'info'" width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="64" height="64" rx="32" fill="#F1F1F1"/>
            <path d="M41.0113 24.28C41.0113 25.88 40.3579 27.5467 39.0513 29.28L36.0913 33.2C35.6113 33.8133 35.3713 34.4933 35.3713 35.24V36.08H30.1713V34.88C30.1713 33.44 30.6779 32.0533 31.6913 30.72L34.6513 26.92C35.4246 25.9067 35.8113 25.0133 35.8113 24.24C35.8113 23.28 35.5179 22.4933 34.9313 21.88C34.3446 21.2667 33.5579 20.96 32.5713 20.96C31.5579 20.96 30.7713 21.28 30.2113 21.92C29.6513 22.5333 29.3713 23.32 29.3713 24.28H24.1712C24.1712 21.8533 24.9713 19.92 26.5713 18.48C28.1713 17.0133 30.1713 16.28 32.5713 16.28C34.9446 16.28 36.9446 17 38.5713 18.44C40.1979 19.88 41.0113 21.8267 41.0113 24.28ZM35.5313 45H30.0113V39.68H35.5313V45Z" fill="#141A1E"/>
        </svg>
        <svg v-if="name === 'check'" width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="64" height="64" rx="32" fill="#27AE60"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M44.8745 26.0242L29.8449 41.0242C29.0115 40.1909 29.3172 40.5262 28.6663 39.8754L20.333 31.542L22.69 29.185L29.8449 36.3398L42.5175 23.6671L44.8745 26.0242Z" fill="#E2FFBE"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: {
        name: String
    },
    methods: {
        handleClick () {
            this.$emit('click');
        }
    }
};
</script>

<style lang="scss" scoped>
svg{
    width: 64px;
    height: 64px;
    position: absolute;
    right: -9px;
    top: -12px;
    z-index: 10;
}
</style>
