<template>
    <div class="gender-icon">
        <svg v-if="gender === 'male'" width="144" height="144" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M109.726 22H88.7848V34.4085H101.046L81.7164 53.9458C75.1098 48.953 67.2358 46.4445 59.3624 46.4445C49.8044 46.4445 40.2403 50.1372 32.9405 57.5164C18.3532 72.2686 18.3532 96.1824 32.9405 110.934C40.2403 118.314 49.8044 122 59.3624 122C68.9267 122 78.4939 118.314 85.788 110.934C98.7152 97.8731 100.186 77.6155 90.2191 62.9059L109.726 43.1854V55.5757H122V34.4087V22H109.726ZM77.1087 102.164C72.3632 106.955 66.0643 109.592 59.3628 109.592C52.6613 109.592 46.3624 106.955 41.62 102.164C36.8837 97.3729 34.2714 91.0009 34.2714 84.2257C34.2714 77.4508 36.8839 71.0785 41.62 66.2875C46.3624 61.4961 52.6613 58.8594 59.3628 58.8594C66.0643 58.8594 72.3636 61.4963 77.1087 66.2875C81.8449 71.0787 84.4573 77.451 84.4573 84.2257C84.4573 91.0004 81.8449 97.3727 77.1087 102.164Z" fill="white"/>
        </svg>
        <svg v-if="gender === 'female'" width="144" height="144" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M111 54.3261C111 32.6108 93.5371 15 72.0033 15C50.4627 15 33 32.6108 33 54.3268C33 73.9549 47.2545 90.2165 65.8903 93.1804V103.68H54.9615V116.6H65.8903V129H78.6961V116.6H89.625V103.68H78.6961V93.0822C97.0489 89.875 111 73.7458 111 54.3261ZM76.5089 80.3494L72.21 81.1009L67.8839 80.4117C61.7985 79.4514 56.2167 76.3062 52.1527 71.5608C50.145 69.2159 48.5786 66.5649 47.4954 63.6843C46.3777 60.6992 45.8118 57.5543 45.8118 54.3257C45.8118 47.2703 48.5371 40.6394 53.4773 35.6575C58.4317 30.6622 65.0076 27.9208 72.0033 27.9208C78.9925 27.9208 85.5749 30.6622 90.515 35.6581C95.4618 40.64 98.1873 47.271 98.1873 54.3263C98.1873 57.52 97.6285 60.6373 96.5313 63.5874C95.4759 66.4471 93.9372 69.0772 91.9635 71.4152C87.9962 76.1254 82.5042 79.2984 76.5089 80.3494Z" fill="white"/>
        </svg>
        <svg v-if="gender === 'mix'" width="144" height="144" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M123 17H94.6357V27.7551H104.627L96.3555 36.0179C91.6381 32.8014 86.0695 31.0676 80.2365 31.0676C72.578 31.0676 65.3719 34.0504 59.9548 39.4615C58.1373 41.2775 56.5934 43.2958 55.344 45.4655C53.2072 44.9557 50.9792 44.6811 48.6881 44.6811C32.8694 44.6811 20 57.5393 20 73.3471C20 87.3163 30.0537 98.9768 43.3079 101.5V106.168H32.9818V116.924H43.3079V127H54.0707V116.924H64.3979V106.168H54.0707V101.499C62.406 99.9118 69.4711 94.714 73.568 87.6037C75.7275 88.1161 77.9601 88.3855 80.2365 88.3855C87.8961 88.3855 95.1022 85.4028 100.518 79.9916C110.402 70.1156 111.519 54.7905 103.941 43.6455L112.237 35.357V45.3422H123V17ZM48.6904 91.2569C38.8047 91.2569 30.764 83.2218 30.764 73.3494C30.764 63.4746 38.8061 55.4385 48.6893 55.4385C49.7608 55.4385 50.8102 55.5425 51.8361 55.7301C51.6518 57.0422 51.5531 58.3765 51.5531 59.7289C51.5531 67.3826 54.5387 74.5827 59.956 79.9951C61.1171 81.1553 62.3622 82.2024 63.6756 83.1337C60.4685 88.02 54.9542 91.2569 48.6904 91.2569ZM66.4884 71.2147C64.2526 68.5532 62.86 65.3424 62.4453 61.9072C64.6137 64.4982 66.066 67.7 66.4884 71.2147ZM92.9098 72.3888C89.5234 75.7722 85.0271 77.6328 80.2377 77.6328C79.1748 77.6328 78.1258 77.53 77.0938 77.3431C77.2786 76.0389 77.3785 74.7068 77.3785 73.3483C77.3785 63.693 72.5741 55.1448 65.2387 49.9506C65.9085 48.9196 66.6806 47.9509 67.5653 47.0669C70.953 43.6833 75.4494 41.8227 80.2377 41.8227C85.0271 41.8227 89.5245 43.6844 92.9098 47.0667C99.895 54.0479 99.895 65.4076 92.9098 72.3888Z" fill="white"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: {
        gender: String
    }
};
</script>
