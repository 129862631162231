<template>
    <transition name="fade">
        <div class="take-reward" v-if="inventoryItems">
            <div class="take-reward__wrapper">
                <InventoryItem
                    v-for="(item, index) in inventoryItems"
                    :key="index"
                    :item="item"
                />
            </div>
            <p class="take-reward__desc" v-if="inventoryItems.length === 1">{{ $t('take_reward.single') }}</p>
            <p class="take-reward__desc" v-if="inventoryItems.length > 1">{{ $t('take_reward.multiple') }}</p>

            <svg v-if="inventoryItems.length !== 0" class="take-reward__bg" width="890" height="445" viewBox="0 0 890 445" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="890" height="890" fill="url(#paint0_radial_226_36107)" fill-opacity="0.7"/>
                <defs>
                    <radialGradient id="paint0_radial_226_36107" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(445 445) rotate(180) scale(445)">
                        <stop :stop-color="firstItemRarityColors.color1"/>
                        <stop offset="1" :stop-color="firstItemRarityColors.color2" stop-opacity="0"/>
                    </radialGradient>
                </defs>
            </svg>

        </div>
    </transition>
</template>

<script>
import InventoryItem from '@/components/popups/components/inventoryItem/InventoryItem.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'TakeReward',
    components: { InventoryItem },
    data () {
        return {
            inventoryItems: [],
            timeoutHandle: null
        };
    },
    computed: {
        ...mapGetters('inventory', ['inventoryItemsPool']),
        firstItemRarityColors () {
            if (this.inventoryItems.length > 0) {
                const firstItemRarity = this.inventoryItems[0].rarity;
                if (firstItemRarity === 'ordinary') {
                    return { color1: '#7A8489', color2: '#2E3538' };
                }
                if (firstItemRarity === 'epic') {
                    return { color1: '#9547F0', color2: '#40118C' };
                }
                if (firstItemRarity === 'legend') {
                    return { color1: '#FFC93C', color2: '#936600' };
                }
                if (firstItemRarity === 'unusual') {
                    return { color1: '#2780A6', color2: '#005174' };
                }
                if (firstItemRarity === 'rare') {
                    return { color1: '#2EBC92', color2: '#045941' };
                }
            }
            return { color1: '#7A8489', color2: '#2E3538' };
        }
    },
    methods: {
        InitializeInv (itemList) {
            // console.log(itemList);
            for (let i = 0; i < itemList.length; i++) {
                if (itemList[i].itemID) {
                    const newItem = Object.assign({}, this.inventoryItemsPool[itemList[i].itemID - 1]);
                    newItem.count = itemList[i].count;
                    newItem.index = this.inventoryItems.length + 1;
                    newItem.additional = itemList[i].additional;
                    this.inventoryItems.push(newItem);
                }
            }

            // Сделал очистку массива через 5 секунд, чтоб автоматом исчезал, если не над, то прост удалить
            if (this.timeoutHandle !== null) {
                clearTimeout(this.timeoutHandle);
                this.timeoutHandle = null;
            }
            this.timeoutHandle = setTimeout(() => {
                this.inventoryItems = [];
                this.timeoutHandle = null;
            }, 3000);
        }

        /*
        window.modifyVueComponent({
            TakeReward: {
                InitializeInv: [
                    [{"count":89,"additional":0,"itemID":1},{"count":9,"additional":0,"itemID":2}]
                ]
            }
        });
         */
    }
};
</script>

<style lang="scss" scoped>
.take-reward{
    left: 0;
    grid-gap: 1vw;
    justify-content: center;
    margin: 0 auto;
    right: 0;
    position: absolute;
    bottom: 0;
    z-index: 1;

    &__wrapper{
        left: 0;
        display: flex;
        justify-content: center;
    }

    .inventory__cards-item{
        width: 9vw;
        height: 11vw;
        margin: 0 .5vw;
    }

    &__desc{
        color: #F1F1F1;
        font-size: 1.5vw;
        font-weight: 900;
        line-height: 100%;
        justify-content: center;
        text-align: center;
        letter-spacing: 2.16px;
        font-family: var(--font-stretch);
        text-transform: uppercase;
        margin-top: 1.5vw;
        margin-bottom: 2.5vw;
    }

    &__bg{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        z-index: -1;
        width: 30vw;
        height: auto;
    }
}
p {
    font-size: 2vw;
}
</style>
