<template>
    <div class="settings">
        <div class="settings__menu">
            <div class="settings__menu-title">{{ $t('settings.header') }}</div>
            <div class="settings__menu-list">
                <div
                    v-for="(tab, index) in tabs"
                    :key="index"
                    class="settings__menu-item"
                    :class="{'settings__menu-item--active': activeTab === tab.name}"
                    @click="changeTab(tab.name)"
                >
                    {{ tab.label }}
                    <IconNew
                        v-if="tab.isNew"
                        className="settings__menu-item-new"
                    />
                </div>
            </div>
        </div>
        <div class="settings__content">
            <div
                v-for="(tab, index) in tabs"
                :key="index"
                v-show="activeTab === tab.name"
            >
                <div
                    v-if="tab.hasMainField"
                    class="settings__content-field settings__content-field--main"
                    @click="handleItemClick(tab.mainField.name)"
                >
                    <div class="settings__content-field-top">
                        <div class="settings__content-field-spec">{{ tab.mainField.spec }}</div>
                        <div class="settings__content-field-value">{{ tab.mainField.value }}</div>
                    </div>
                    <div class="settings__content-field-middle">
                        {{ tab.mainField.description }}
                    </div>
                    <img
                        v-if="tab.mainField.image"
                        :src="imageSrc(tab.mainField.image)"
                        alt=""
                        class="settings__content-field-preset"
                    />
                </div>

                <div
                    v-if="tab.hasAdvancedSettings"
                    class="settings__content-title"
                >
                    <div class="settings__content-title-text">
                        {{ $t('settings.advanced') }}
                    </div>
                    <div class="settings__content-title-divider"></div>
                </div>

                <div
                    v-for="(item, i) in tab.items"
                    :key="i"
                    class="settings__content-field"
                    @click="handleItemClick(item.name)"
                >
                    <div class="settings__content-field-spec">{{ item.spec }}</div>
                    <div class="settings__content-field-value">{{ item.value }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconNew from '@/components/icons/IconNew.vue';

export default {
    name: 'MainSettings',
    components: { IconNew },
    data () {
        return {
            activeTab: 'graphics',
            tabs: [
                {
                    name: 'graphics',
                    label: this.$t('settings.graphics'),
                    // isNew: true,
                    hasMainField: true,
                    hasAdvancedSettings: true,
                    mainField: {
                        spec: this.$t('settings.general_graphics'),
                        name: 'OpenGraphicSettings',
                        value: '',
                        description: this.$t('settings.description'),
                        image: 'preset.png'
                    },
                    items: [
                        { spec: this.$t('settings.items.resolution'), name: 'resolution', value: '100%' },
                        { spec: this.$t('settings.items.maxFPS'), name: 'fps', value: '60' },
                        { spec: this.$t('settings.items.shadow'), name: 'shadows', value: 'Высокое' },
                        { spec: this.$t('settings.items.texture'), name: 'textures', value: 'Высокое' },
                        { spec: this.$t('settings.items.drawing_radius'), name: 'stream', value: 'Высокое' }
                    ]
                },
                {
                    name: 'sound',
                    label: this.$t('settings.sound'),
                    isNew: false,
                    hasMainField: false,
                    hasAdvancedSettings: false,
                    items: [
                        { spec: this.$t('settings.general_sound'), name: 'mainsound', value: '100%' },
                        { spec: this.$t('settings.effects_sound'), name: 'effectsound', value: '100%' },
                        { spec: this.$t('settings.ambient_sound'), name: 'ambientsound', value: '100%' }
                    ]
                }
                /*
                {
                    name: 'game',
                    label: 'Игра',
                    isNew: false,
                    hasMainField: false,
                    hasAdvancedSettings: false,
                    items: [
                        { spec: 'Настройка игры 1', value: 'Значение игры 1' },
                        { spec: 'Настройка игры 2', value: 'Значение игры 2' }
                    ]
                },
                {
                    name: 'notifications',
                    label: 'Уведомления',
                    isNew: false,
                    hasMainField: false,
                    hasAdvancedSettings: false,
                    items: [
                        { spec: 'Настройка уведомлений 1', value: 'Значение уведомлений 1' },
                        { spec: 'Настройка уведомлений 2', value: 'Значение уведомлений 2' }
                    ]
                },
                {
                    name: 'account',
                    label: 'Аккаунт',
                    isNew: false,
                    hasMainField: false,
                    hasAdvancedSettings: false,
                    items: [
                        { spec: 'Настройка аккаунта 1', value: 'Значение аккаунта 1' },
                        { spec: 'Настройка аккаунта 2', value: 'Значение аккаунта 2' }
                    ]
                }
                */
            ]
        };
    },
    computed: {
        // imageSrc () {
        //     return (tab) => {
        //         return require(`@/assets/images/settings/${tab.mainField.image}`);
        //     };
        // }
    },
    methods: {
        initialize (graphicSettings, soundSettings) {
            this.tabs[0].items = graphicSettings;
            this.tabs[1].items = soundSettings;
        },
        changeTab (tabName) {
            this.activeTab = tabName;
        },
        handleItemClick (name) {
            // console.log('Выбран пункт:', spec);
            this.sendclient(name);
        },
        imageSrc (image) {
            return require(`@/assets/images/settings/${image}`);
        }
    }
};
</script>

<style lang="scss" scoped>
.settings {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(21, 1fr);
    background: var(--dark);
    position: relative;
    z-index: 0;
    &::after {
        content:"";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url(@/assets/images/bg-popup-vector.svg);
        opacity: .3;
        z-index: -1;
    }
    &__menu {
        grid-column: 5 span;
        background-color: #0F1316;
        padding-left: 5vw;
        padding-top: 3vw;
        &-title {
            font-family: var(--font-stretch);
            font-size: 1.5vw;
            color: rgba(241, 241, 241, 0.4);
            text-transform: uppercase;
            font-weight: 900;
            letter-spacing: var(--letter-spacing);
        }
        &-list {
            margin-top: 1vw;
        }
        &-item {
            padding: 1.6vw 2vw;
            font-size: 2vw;
            font-weight: 300;
            color: var(--white);
            opacity: .6;
            position: relative;
            transition: .2s;
            &--active {
                background: linear-gradient(94.2deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0) 100%);
                opacity: 1;
                padding-left: 2.8vw;
                border-radius: .16vw 0 0 .16vw;
            }
            &-new {
                position: absolute;
                left: -0.4vw;
                top: -0.4vw;
                width: 3.7vw;
                height: 2vw;
            }
        }
    }
    &__content {
        grid-column: 16 span;
        padding: 4vw 8vw 3.5vw 4vw;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
        &-field {
            box-sizing: border-box;
            padding: 1.55vw 2.55vw;
            border: .2vw solid #f1f1f14d;
            border-radius: .2vw;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: radial-gradient(100% 938.37% at 0% 50%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.06) 100%);
            margin-bottom: 1vw;
            &:first-child, &:last-child {
                margin-bottom: 1vw;
            }
            &-spec {
                font-size: 1.75vw;
                color: var(--white);
            }
            &-value {
                font-size: 2.15vw;
                font-weight: 700;
                font-family: var(--font-stretch);
                text-transform: uppercase;
                display: flex;
                align-items: center;
                &-max {
                    margin-right: .5vw;
                    color: var(--gold);
                    font-size: 1.5vw;
                }
            }
            &--main {
                display: grid;
                grid-template-columns: 1fr 1fr;
                padding: 2.55vw;
                grid-gap: 1vw .4vw;
            }
            &-top {
                grid-column: 2 span;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            &-middle {
                grid-column: 2 / 3;
                font-size: 1.3vw;
                color: #F1F1F1B2;
                text-align: right;
                line-height: 115%;
            }
            &-preset {
                grid-column: 2 span;
                width: 100%;
            }
        }
        &-title {
            color: #F1F1F166;
            font-size: 1.45vw;
            font-weight: 900;
            letter-spacing: var(--letter-spacing);
            text-transform: uppercase;
            padding: 1.75vw 0;
            display: flex;
            align-items: center;
            &-text {
                white-space: nowrap;
                margin-right: 1vw;
            }
            &-divider {
                height: .16vw;
                width: 100%;
                background-color: rgba(241, 241, 241, 0.4);
            }
        }
    }
}
</style>
