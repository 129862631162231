<template>
    <div :style="{width, height, minWidth: width}" class="loader"></div>
</template>
<script>
export default {
    name: 'VLoader',
    props: {
        width: {
            type: String,
            default: '2vw'
        },
        height: {
            type: String,
            default: '2vw'
        }
    }
};
</script>
<style lang="scss" scoped>
@keyframes loading {to{transform: rotate(-1turn)}}
.loader {
    background-image: url("@/assets/images/loader.png");
    background-position: center;
    background-size: contain;
    animation: loading 1s infinite linear;
}
</style>
