<template>
    <div class="property" :class="{'property--available' : isConditionComplete}">
        <div class="property-container property-container--shop" v-if="isShop">
            <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M36.13 20.3213L23.2967 7.48801C22.5807 6.77204 21.4199 6.77205 20.7039 7.48801L7.8706 20.3213L10.4633 22.9141L22.0003 11.3771L33.5373 22.9141L36.13 20.3213Z" fill="#F1F1F1"/>
                <path d="M36.13 31.3213L23.2967 18.488C22.5807 17.772 21.4199 17.772 20.7039 18.488L7.87061 31.3213L10.4633 33.9141L22.0003 22.3771L33.5373 33.9141L36.13 31.3213Z" fill="#F1F1F1"/>
            </svg>
            {{ text }}
        </div>
        <div class="property-container" v-else>
            <svg v-if="isConditionComplete" width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M36.1624 15.4269L19.6298 31.9269C18.7131 31.0103 19.0494 31.3792 18.3334 30.6632L9.16675 21.4965L11.7595 18.9038L19.6298 26.7741L33.5697 12.8342L36.1624 15.4269Z" fill="#F1F1F1"/>
            </svg>
            <svg v-else width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.9998 24.5928L31.7034 34.2963L34.2961 31.7036L24.5925 22.0001L34.2961 12.2965L31.7034 9.70378L21.9998 19.4073L12.2961 9.70361L9.70337 12.2963L19.4071 22.0001L9.70337 31.7038L12.2961 34.2965L21.9998 24.5928Z" fill="#F1F1F1"/>
            </svg>
            {{ text }}
        </div>
    </div>
</template>
<script>

export default {
    name: 'BaseHaveProperty',
    props: {
        isConditionComplete: Boolean,
        text: String,
        isShop: Boolean
    }
};
</script>
<style scoped lang="scss">
.property {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.30);
    color: var(--white);
    font-size: 36px;
    font-family: var(--font-stretch);
    font-weight: 900;
    line-height: 100%;
    letter-spacing: 1.44px;
    text-transform: uppercase;
    width: fit-content;
    &--available {
        background-color: #27AE60;
    }
    svg {
        margin-right: 10px;
        width: 44px;
        height: 44px;
    }
    &-container {
        display: flex;
        align-items: center;
        &--shop {
        }
    }
}
</style>
