<template>
    <div @click="selectItem" :class="getCardItemClass()" class="item">
        <div class="inventory__cards-count" v-if="item.count">{{ item.count }}</div>
        <div class="inventory__cards-put-on" v-if="item.isEquipped">
            <BaseIcon name="checkmark-bold" color="#f1f1f1" />
        </div>
        <div class="inventory__cards-recipe" v-if="this.showRecipe">
            <IconRecipe />
        </div>
        <span class="inventory__cards-gender" v-if="item.gender === 0"><IconGender gender="male"/></span>
        <span class="inventory__cards-gender" v-if="item.gender === 1"><IconGender gender="female"/></span>
        <span class="inventory__cards-gender" v-if="item.gender === 2"><IconGender gender="mix"/></span>
        <p>{{ $t(item.name) }}</p>
        <img v-if="item.id" :src="getItemImage()" alt="">
    </div>
</template>

<script>
import IconGender from '@/components/icons/IconGender.vue';
import IconRecipe from '@/components/popups/content/inventory/icons/IconRecipe.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';

export default {
    name: 'InventoryItem',
    components: { BaseIcon, IconRecipe, IconGender },
    props: {
        item: Object,
        selectedItem: Object,
        isChecked: Boolean,
        playerItems: Array
    },
    data () {
        return {
            showRecipe: null
        };
    },
    computed: {
        isPlayerItemCountZero () {
            const playerItem = this.playerItems.find(pi => pi.id === this.item.id);
            return !playerItem || (playerItem.count === 0 || playerItem.count === undefined);
        }
    },
    methods: {
        selectItem () {
            this.$emit('select-item', this.item);
        },
        getCardItemClass () {
            const classes = ['inventory__cards-item'];
            const item = this.item;
            const selectedItem = this.selectedItem;

            if (!item.id) classes.push('inventory__cards-item--empty');
            if (item.rarity) classes.push(`inventory__cards-item--${item.rarity}`);
            if (item.car) classes.push('inventory__cards-item--car');
            if (this.showRecipe) classes.push('inventory__cards-item--no-recipe');
            if (selectedItem === item) classes.push('inventory__cards-item--active');
            if (!this.$parent.isChecked && item.count === 0) classes.push('inventory__cards-item--opacity');
            if (this.isChecked && this.isPlayerItemCountZero) classes.push('inventory__cards-item--opacity');

            return classes;
        },
        getItemImage () {
            const item = this.item;
            if (item.img !== '') {
                return `https://launcher.gta5grand.com/game/mobile/images/${item.img}.png`;
            } else if (item.car) {
                return `https://launcher.gta5grand.com/game/mobile/images/${item.img}.png`;
            } else {
                return `https://launcher.gta5grand.com/game/mobile/images/${item.gender}/${item.clothesType}/${item.clothesIndex}_${item.additional}.png`;
            }
        }
    },
    mounted () {
        this.showRecipe = !this.item.recipe === undefined || this.item.recipe === false;
    }
};
</script>

<style lang="scss" scoped>
.item {
    width: 220px;
    height: 280px;
}
</style>
<style lang="scss">
.inventory{
    &__cards{
        overflow-y: auto;
        width: 100%;
        padding-top: 66px; // чтоб активный айтем сверху не обрезался
        padding-bottom: 60px;
        //margin: -5vw 0;
        //padding: 5vw 0.2vw;

        &::-webkit-scrollbar{
            width: 0;
            height: 0;
            display: none;
        }

        &-gender{
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1;
            svg{
                width: 1.6vw;
                height: auto;
            }
        }

        &-count{
            padding: 0 .7vw ;
            height: 1.7vw;
            background: var(--white);
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
            clip-path: polygon(0 0, 100% 0, 91% 100%, 0% 100%);
            font-size: 1.3vw;
            line-height: 100%;
            letter-spacing: -0.03em;
            font-weight: 700;
            color: #141A1E;

        }

        &-recipe{
            top: 20px;
            right: 20px;
            position: absolute;
            svg{
                width: 74px;
                height: 74px;
            }
        }

        &-put-on{
            padding: 13px;
            width: 1.7vw;
            height: 1.7vw;
            background: #27AE60;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 2;
            clip-path: polygon(19% 0, 100% 0, 100% 100%, 0% 100%);
            svg, .base-icon {
                width: 28px;
                height: 28px;
            }
        }

        &-wrapper{
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-gap: 1vw;
        }

        &-update{
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-gap: 1vw;
            grid-column: 1/-1;
            position: relative;

            .btn{
                svg{
                    height: 30%;
                    width: auto;
                    margin-left: .5vw;
                }
            }

            .inventory__cards-item--empty{
                opacity: .3;
            }
        }

        &-shop{
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 3vw;

            h5{
                font-weight: 700;
                font-size: 1.3vw;
                line-height: 140%;

                letter-spacing: 0.05em;
                text-transform: uppercase;

                color: var(--white);
            }
            p{
                font-size: 1.3vw;
                line-height: 140%;
                display: flex;
                align-items: flex-end;

                color: var(--white);

                opacity: 0.6;
            }
        }

        &-item {
            position: relative;
            width: 220px;
            height: 280px;
            display: flex;
            border-radius: .2vw;
            padding: .5vw;
            align-items: flex-end;
            overflow: hidden;

            &--empty{
                background: url(./assets/images/empty.svg) no-repeat center/contain;
            }

            &--legend{
                background: url(./assets/images/legend.svg) no-repeat center/cover;
            }
            &--epic{
                background: url(./assets/images/epic.svg) no-repeat center/cover;
            }
            &--rare{
                background: url(./assets/images/rare.svg) no-repeat center/cover;
            }
            &--ordinary{
                background: url(./assets/images/ordinary.svg) no-repeat center/cover;
            }
            &--unusual{
                background: url(./assets/images/unusual.svg) no-repeat center/cover;
            }

            &--opacity{
                opacity: 0.4;
            }

            &--no-recipe{
                opacity: 0.4;
            }

            &--active{
                overflow: visible;
                //box-shadow: 0 0 0 6px var(--white);
                &::before {
                    content: "";
                    position: absolute;
                    top: -6px;
                    left: -6px;
                    right: -6px;
                    bottom: -6px;
                    z-index: 3;
                    background: url(./assets/images/active.svg) no-repeat center/cover;
                }

            }

            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1;
            }

            p{
                font-weight: 700;
                font-size: 1vw;
                line-height: 100%;
                position: relative;
                z-index: 3;
            }

        }
    }
}
</style>
