<template>
    <button
        class="btn"
        :class="{
          'btn--green': green,
          'btn--border': border,
          'btn--yellow': yellow,
          'btn--white': white,
          'btn--center': center,
          'btn--full': full,
          'btn--glass': glass
        }"
        :type="type"
        @click="emit('click', $event)"
        @mouseover="emit('mouseover', $event)"
        @mouseleave="emit('mouseleave', $event)"
        :disabled="disabled"
    >
        <slot></slot>
        <svg v-if="glass" preserveAspectRatio="xMinYMax meet" width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg" class="btn-bg">
            <rect x=".08vw" y=".08vw" width="calc(100% - .16vw)" height="calc(100% - .16vw)" rx=".05vw" stroke="url(#paint0_linear_478_4720)" stroke-opacity="0.8" stroke-width=".16vw"></rect>
            <defs>
                <linearGradient id="paint0_linear_478_4720" x1="0" y1="0" x2="221.842" y2="64.075" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F1F1F1"></stop>
                    <stop offset="0.489583" stop-color="#F1F1F1" stop-opacity="0.28125"></stop>
                    <stop offset="1" stop-color="#F1F1F1"></stop>
                </linearGradient>
            </defs>
        </svg>
    </button>
</template>

<script>
export default {
    name: 'BaseButton',
    props: {
        green: {
            type: Boolean,
            default: false
        },
        center: {
            type: Boolean,
            default: false
        },
        full: {
            type: Boolean,
            default: false
        },
        border: {
            type: Boolean,
            default: false
        },
        yellow: {
            type: Boolean,
            default: false
        },
        white: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'button'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        glass: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        emit (eventName, event) {
            this.$emit(eventName, event);
        }
    }
};
</script>

<style lang="scss">
.btn{
    display: flex;
    height: 5vw;
    align-items: center;
    padding: 0 3vw;
    font-weight: 500;
    font-size: 1.6vw;
    line-height: 130%;
    text-align: center;
    color: #141A1E;
    border: none;
    transition: .2s ease-out;
    border-radius: 4px;
    justify-content: center;

    &--center{
        margin: 0 auto;
    }

    &--yellow{
        background: #FFED4C;
        box-shadow: inset 0px -26px 80px rgba(241, 241, 241, 0.4);

        &:active{
            background: #FFF742;
        }
    }

    &--green{
        background: #A6FF4C;
        box-shadow: inset 0px -26px 80px rgba(241, 241, 241, 0.4);

        &:active{
            background: #A6FF4C;
        }
    }

    &--full{
        width: 100%;
    }

    &--border{
        background: linear-gradient(119.36deg, rgba(241, 241, 241, 0.19) 0%, rgba(241, 241, 241, 0) 100%);
        color: #F1F1F1;
        border: none;
        border-image-source: linear-gradient(98.84deg, rgba(241, 241, 241, 0.8) 0%, rgba(241, 241, 241, 0.225) 48.96%, rgba(241, 241, 241, 0.8) 100%);
        border-image-slice: 1;
        border-image-width: .3vw;
        border-image-repeat: stretch;

        &:active{
            background: linear-gradient(119.36deg, rgba(241, 241, 241, 0.29) 0%, rgba(241, 241, 241, 0.05) 100%);
        }
    }

    &--white{
        background: #F1F1F1;
        box-shadow: inset 0px -26px 80px rgba(241, 241, 241, 0.4);
        color: #141A1E;
        &:active{
            background: #D1D1D1;
            box-shadow: inset 0px -26px 80px rgba(241, 241, 241, 0.4);
        }
    }
    &--glass{
        position: relative;
        background: linear-gradient(119.36deg, rgba(241, 241, 241, 0.19) 0%, rgba(241, 241, 241, 0) 100%);
        border-radius: .05vw;
        z-index: 2;
        color: var(--white);
        .btn-bg {
            position: absolute;
            border-radius: .05vw;
            width: 100%;
            height: 100%;
            left: 0;
            bottom: 0;
            z-index: -1;
        }
        &:active {
            background: linear-gradient(119.36deg, rgba(241, 241, 241, 0.29) 0%, rgba(241, 241, 241, 0.05) 100%);
        }
    }

    &:disabled{
        color: #898989;

        background: #626262;
        box-shadow: inset 0px -26px 80px rgba(241, 241, 241, 0.3);

        &:active{
            background: #626262;
        }
    }
}
</style>
