<template>
    <div class="id-card">
        <div class="id-card__top" :style="{background: `linear-gradient(to right, ${gradient.startColor} 0%, ${gradient.finishColor} 100%`}"></div>
        <div class="id-card__content">
            <div class="id-card__heading">
                <div class="id-card__icons">
                    <img class="id-card__org" v-if="playerInfo.orgId" :src="imageSource(`organization-${playerInfo.orgId}.png`, 'organization')" alt="" />
                    <div class="id-card__level">
                        <div class="id-card__level-text">{{ playerInfo.level }}</div>
                    </div>
                </div>
                <div class="id-card__info">
                    <div class="id-card__name">{{ playerInfo.name }}</div>
                    <!--<div class="id-card__work">{{ playerInfo.work }}</div>-->
                </div>
            </div>
            <div class="id-card__row">
                <div class="id-card__row-text">{{ $t('id_card.gender') }}</div>
                <div class="id-card__row-divider"></div>
                <div class="id-card__row-title">{{ playerInfo.reg }}</div>
            </div>
            <div class="id-card__row">
                <div class="id-card__row-text">{{ $t('id_card.spouse') }}</div>
                <div class="id-card__row-divider"></div>
                <div class="id-card__row-title">{{ playerInfo.spouse }}</div>
            </div>
            <!--
            <div class="id-card__row">
                <div class="id-card__row-text">{{ $t('id_card.address') }}</div>
                <div class="id-card__row-divider"></div>
                <div class="id-card__row-title">{{ playerInfo.place }}</div>
            </div>
            -->
        </div>
        <BaseButton class="id-card__button" white @click="closeId">{{ $t('id_card.close') }}</BaseButton>
    </div>
</template>

<script>

import BaseButton from '@/components/ui/BaseButton.vue';

export default {
    name: 'IdCard',
    components: {
        BaseButton
    },
    data () {
        return {
            gradient: {
                startColor: '#1A8D7F',
                finishColor: '#153429'
            }
        };
    },
    props: {
        playerInfo: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        closeId () {
            // console.log('close id');
            this.sendclient('closeIdCard');
        }
    }
};
</script>
<style lang="scss" scoped>
.id-card {
    width: 1120px;
    min-height: 606px;
    position: fixed;
    left: 0;
    top: 50%;
    transform: translateY(-50%) rotate(-4.4deg);
    right: 0;
    margin: auto;
    border-radius: 24px;
    box-shadow: 100px 100px 84px 0 rgba(0, 0, 0, 0.15), 0 0 250px 0 rgba(0, 0, 0, 0.60);
    background-image: url("@/assets/images/idcard/white-bg.png");
    z-index: 11;
    &::after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        height: 100%;
        background: url("@/assets/images/idcard/bg.png") no-repeat center/cover;
        border-radius: 24px;
    }
    &__top {
        height: 63px;
        border-radius: 24px 24px 0 0;
    }
    &__content {
        padding: 51px 80px 72px;
    }
    &__heading {
        display: flex;
        align-items: center;
    }
    &__icons {
        margin-right: 32px;
        display: flex;
        align-items: center;
        opacity: .8;
    }
    &__org {
        width: 122px;
        height: 111px;
        object-fit: cover;
        object-position: center;
        margin-right: 11px;
    }
    &__level {
        background-image: url("@/assets/images/level-icon.png");
        width: 120px;
        height: 120px;
        background-position: center;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        &-text {
            font-family: var(--font-stretch);
            color: var(--white);
            text-shadow: 0 7.523809909820557px 30.095239639282227px 0 rgba(0, 0, 0, 0.25);
            font-size: 72px;
            font-weight: 900;
            line-height: 100%;
            letter-spacing: -2.709px;
            transform: translateX(-2.709px);
        }
    }
    &__name {
        color: var(--dark);
        font-size: 60px;
        font-weight: 400;
        line-height: 100%;
        margin-bottom: 10px;
    }
    &__work {
        color: var(--dark);
        font-size: 32px;
        font-weight: 400;
        line-height: 100%;
        opacity: .6;
    }
    &__row {
        margin-top: 52px;
        display: grid;
        grid-template-columns: max-content 1fr max-content;
        grid-gap: 0 24px;
        align-items: center;
        &-text {
            width: fit-content;
            color: var(--dark);
            font-size: 32px;
            font-weight: 400;
            line-height: 100%;
            opacity: .6;
        }
        &-title {
            color: var(--dark);
            text-align: right;
            font-size: 40px;
            font-weight: 400;
            line-height: 120%;
        }
        &-divider {
            height: 2px;
            opacity: 0.20000000298023224;
            background: #141A1E;
            width: 100%;
        }
    }
    &__button {
        position: absolute;
        transform: rotate(4.4deg);
        z-index: 5;
        top: -75px;
        right: 100px;
        font-size: 36px;
        padding: 0 44px;
        height: 112px;
    }
}
</style>
