import { render, staticRenderFns } from "./ShopVip.vue?vue&type=template&id=7bc5b742&scoped=true&"
import script from "./ShopVip.vue?vue&type=script&lang=js&"
export * from "./ShopVip.vue?vue&type=script&lang=js&"
import style0 from "./ShopVip.vue?vue&type=style&index=0&id=7bc5b742&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bc5b742",
  null
  
)

export default component.exports