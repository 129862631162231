<template>
    <div class="accept-work"
         :class="{ 'accept-work--is-work': isWork }"
         :style="isMobile ? `background: radial-gradient(104.63% 50% at 50% -0.00%, rgba(107, 37, 255, 0.90) 0%, rgba(55, 32, 147, 0.90) 100%);` : ''"
    >
        <PopupLoader v-if="imagesLoading"/>
        <template v-else>
            <div class="accept-work__content">
                <div class="accept-work__top">
                    <div class="accept-work__level" @click="isOpenInfoModal = true">
                        <BaseLevel show-text :levelIcon="levelIcon" :level="level" :width="9.48"/>
                        <IconInfo class="accept-work__modal-icon"/>
                    </div>
                    <div class="accept-work__info">
                        <p>{{ title }}</p>
                        <div class="accept-work__list" v-if="acceptList.length > 0">
                        <span
                            v-for="item in acceptList"
                            :key="item.name"
                            :class="{ 'access': item.access }"
                        >
                            <IconCheckmark v-if="item.access" />
                            <IconClose v-else/>
                            {{ item.name}}
                        </span>
                        </div>
                    </div>
                </div>
                <template v-if="!isWork">
                    <div class="accept-work__text" v-if="description">
                        {{ description }}
                    </div>

                    <div class="accept-work__items" v-if = "inventoryItems.length > 0">
                        <p class="accept-work__items-title">{{ $t('accept_work.possible_rewards') }}</p>
                        <div class="accept-work__items-list">
                            <InventoryItem
                                v-for="(item, index) in inventoryItems"
                                :key="index"
                                :item="item"
                            />
                        </div>
                    </div>

                </template>
                <template v-else>
                    <div class="accept-work__change">
                        <div class="accept-work__change-item"
                             v-for="item in listWorks"
                             @click="getWork(item)"
                             :key="item.index">
                            <p>{{ item.name }}</p>
                            <p>{{ $t('accept_work.income') }} <span>${{ $formatNum(item.money) }}</span></p>
                            <p>{{ item.desc }}</p>
                            <img :src="imageSource(item.img, 'works')" >
                        </div>
                    </div>
                    <p class="accept-work__change-desc">— {{ $t('accept_work.select_route') }}</p>
                </template>
            </div>
            <transition name="fade">
                <div class="accept-work__modal" v-if="isOpenInfoModal">
                    <div class="accept-work__modal-wrapper">
                        <div class="accept-work__modal-info">
                            <BaseLevel :levelIcon="levelIcon" class="svg" :width="20.22"/>
                            <p>{{ title }}</p>
                            <span><strong>{{ count }}</strong> {{ $t('accept_work.circles') }}</span>
                            <BaseButton class="button" @click="isOpenInfoModal = false" yellow>{{ $t('accept_work.close') }}</BaseButton>
                        </div>
                        <div class="accept-work__modal-list">
                            <ul>
                               <li v-for="item in aboutWork" :key="item.level" :class="{ disable: item.level > level }">
                                   <p>{{ item.level }} LVL <i v-if="item.add">{{ item.add }}</i></p>
                                   <span>{{ item.description }}</span>
                               </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </transition>
            <div class="accept-work__aside" v-if = "!isOpenInfoModal">
                <img :src="imageSource(`work-${img}.jpg`, 'works')" v-if="!isWork" alt="">
                <BaseButton yellow @click="goWork" class="accept-work__btn" v-if="!isMobile && isOnWork === 0">
                    {{ $t('accept_work.join_job') }}
                </BaseButton>
                <BaseButton yellow @click="goWork" class="accept-work__btn" v-else-if="!isMobile && isOnWork === 1">
                    {{ $t('accept_work.exit_job') }}
                </BaseButton>
                <BaseButton white @click="getPin" class="accept-work__btn" v-else><IconPin />
                    {{ $t('accept_work.map_point') }}
                </BaseButton>
            </div>
        </template>
    </div>
</template>

<script>
import BaseButton from '@/components/ui/BaseButton.vue';
import BaseLevel from '@/components/ui/BaseLevel.vue';
import IconCheckmark from '@/components/icons/IconCheckmark.vue';
import IconClose from '@/components/icons/IconClose.vue';
import IconInfo from '@/components/icons/IconInfo.vue';
import InventoryItem from '@/components/popups/components/inventoryItem/InventoryItem.vue';
import { mapGetters } from 'vuex';
import IconPin from '@/components/icons/IconPin.vue';
import PopupLoader from '@/components/popups/content/technical/PopupLoader.vue';

export default {
    name: 'AcceptWork',
    components: { PopupLoader, IconPin, InventoryItem, IconInfo, IconClose, IconCheckmark, BaseLevel, BaseButton },
    data () {
        return {
            amountText: '',
            isOnWork: 0,
            isWork: false, // если на работу уже устроился, то true и появляется список listWorks
            isMobile: false, // если зашли с телефона, включит точку на карте и поменяет фон
            workID: 1,
            title: '',
            description: '',
            count: '121',
            level: '2',
            levelIcon: 2, // 1, 2, 3
            img: 'bus', // fermer, fishman, miner, taxi, track, woodman, loader
            isOpenInfoModal: false,
            acceptList: [],
            aboutWork: [],
            listWorks: [
                { name: 'Рейс №20', money: 23000, desc: 'mercedes BU-300-S', img: 'bus1.png' },
                { name: 'Рейс №30', money: 13000, desc: 'VOLVO XBU-M', img: 'bus2.png' },
                { name: 'Рейс №240', money: 23000, desc: 'mercedes BU-300-S', img: 'bus3.png' }
            ],
            inventoryItems: [],
            imagesLoading: false,
            imagesToLoad: 0,
            imagesLoaded: 0
        };
    },
    computed: {
        ...mapGetters('inventory', ['inventoryItemsPool'])
    },
    methods: {
        imageLoaded () {
            this.imagesLoaded += 1;
            if (this.imagesLoaded === this.imagesToLoad) {
                this.imagesLoading = false;
            }
        },
        /*
        window.modifyVueComponent({
            App: {
                showPopup: true,
                popupContent: 'AcceptWork',
                contentSubFolder: 'works'
            },
            AcceptWork: {
                initialize: [
                    1,
                    'Водитель автобуса',
                    'Перевозите пассажиров по всему штату',
                    2,
                    100,
                    'bus',
                    [
                        { name: 'В-Категория прав', access: true },
                        { name: '5 уровень', access: false }
                    ],
                    [
                        { level: '0', description: 'Доступ к обычному автопарку' },
                        { level: '1', description: 'Доступ к быстрым автобусам и новым маршрутам' },
                        { level: '2', description: 'Доступ к уникальным автобусам и редким маршрутам', add: '200+ рейсов' },
                        { level: '3', description: 'Доступ к уникальным автобусам и редким маршрутам', add: '200+ рейсов' }
                    ],
                    false,
                ]
            }
        });
        */
        initialize (workID, title, description, level, count, img, acceptList, aboutWork, isMobile, isOnWork) {
            this.isOnWork = isOnWork;
            this.workID = workID;
            if (workID === 1) this.amountText = this.$t('accept_work.amountText.bus');
            else this.amountText = this.$t('accept_work.amountText.mine');
            this.title = title;
            this.description = description;
            this.level = String(level);
            this.levelIcon = level;
            this.count = count;
            this.img = img;
            this.acceptList = acceptList;
            this.aboutWork = aboutWork;
            this.isMobile = isMobile;
        },
        goWork () {
            this.sendclient('AcceptWork', JSON.stringify({ a: this.workID }));
        },
        getWork (item) {
            console.log('взять в работу: ' + item.name);
        },
        getPin () {
            this.sendclient('RouteToWork', JSON.stringify({ a: this.workID }));
        },
        InitializeInv (itemList) {
            for (let i = 0; i < itemList.length; i++) {
                if (itemList[i].itemID) {
                    const newItem = Object.assign({}, this.inventoryItemsPool[itemList[i].itemID - 1]);
                    newItem.count = itemList[i].count;
                    newItem.index = this.inventoryItems.length + 1;
                    newItem.additional = itemList[i].additional;
                    this.inventoryItems.push(newItem);
                }
            }
        }
    },
    mounted () {
        this.$nextTick(() => {
            const images = this.$el.querySelectorAll('img');
            if (images.length > 0) {
                this.imagesLoading = true;
                this.imagesToLoad = images.length;
                this.imagesLoaded = 0;

                images.forEach((img) => {
                    img.addEventListener('load', this.imageLoaded);
                    img.addEventListener('error', this.imageLoaded);
                });
            }
        });
    }

};
</script>
<style lang="scss" scoped>
.inventory__cards-item{
    width: 220px;
    height: 280px;
    margin: 0 .5vw 0 0;
}
</style>
<style lang="scss">
.accept-work{
    height: 100%;
    background: radial-gradient(48.03% 100% at 50% 0%, #015166 0%, #0C2835 100%);
    position: relative;
    padding: 180px 228px;

    &__items{
        &-list{
            display: flex;
        }

        &-title{
            color: rgba(241, 241, 241, 0.40);
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: 0.96px;
            text-transform: uppercase;
            margin-bottom: 16px;
        }
    }

    &--is-work{
        padding: 106px 228px;
    }

    &__change{
        display: flex;

        overflow: scroll;
        margin: 0 -228px;
        padding: 0 228px;
        margin-top: 80px;

        &::-webkit-scrollbar{
            width: 0;
            height: 0;
            display: none;
        }

        &-desc{
            color: #F1F1F1;
            font-size: 50px;
            line-height: 100%;
            opacity: 0.6;
            margin-top: 80px;
        }

        &-item{
            border-radius: 12px;
            background: linear-gradient(146deg, #F1F1F1 0%, rgba(241, 241, 241, 0.60) 100%);
            width: 785px;
            height: 520px;
            flex-shrink: 0;
            padding: 50px;
            margin-right: 32px;
            position: relative;

            p{
                color: #202427;
                position: absolute;

                &:nth-child(1){
                    font-size: 40px;
                    font-weight: 700;
                    line-height: 120%;
                    text-transform: uppercase;
                    left: 50px;
                    top: 50px;
                }

                &:nth-child(2){
                    font-size: 50px;
                    line-height: 100%;
                    right: 50px;
                    top: 50px;

                    span{
                        display: block;
                        font-size: 64px;
                        font-weight: 900;
                        line-height: 100%;
                    }
                }

                &:nth-child(3){
                    text-align: right;
                    font-size: 40px;
                    font-weight: 700;
                    line-height: 120%;
                    text-transform: uppercase;
                    bottom: 50px;
                    right: 50px;
                    max-width: 309px;
                }
            }

            img{
                height: 248px;
                position: absolute;
                left: 0;
                bottom: 0;
            }
        }
    }

    &__content{
        position: relative;
        z-index: 1;
    }
    &__level{
        position: relative;
    }

    &__aside{
        position: absolute;
        z-index: 1;
        top: 180px;
        right: 228px;
        max-width: 596px;
        width: 100%;

        img{
            width: 100%;
            height: 672px;
            object-fit: cover;
            border-radius: 12px;
            margin-bottom: 32px;
        }
    }

    &__btn{
        max-width: 100%;
        width: 100%;

        svg{
            width: 62px;
            height: 62px;
            margin-right: 18px;
        }
    }

    &__modal{
        opacity: 0.9599999785423279;
        background: radial-gradient(48.03% 100.00% at 50.00% 0%, #015166 0%, #0C2835 100%);
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        display: block;
        z-index: 1;
        padding: 83px 250px;

        &-wrapper{
            display: grid;
            grid-gap: 228px;
            grid-template-columns: 1fr 1.2fr;
        }

        &-icon{
            position: absolute;
            bottom: 0;
            left: 35px;
            width: 68px;
            height: 68px;
        }

        &-info{
            text-align: center;
            display: flex;
            flex-direction: column;

            p{
                color: #F1F1F1;
                text-align: center;
                font-size: 64px;
                line-height: 120%;
                margin-bottom: 12px;
            }

            span{
                color: #F1F1F1;
                text-align: center;
                font-size: 64px;
                font-weight: 700;
                line-height: 120%;
                margin-bottom: 80px;
            }

            .button{
                max-width: 400px;
                margin: 0 auto;
            }

            .svg{
                margin: 0 auto 80px;
            }
        }

        &-list{
            position: relative;
            ul{
                display: flex;
                flex-direction: column;
                position: relative;
                overflow: scroll;
                height: 100vh;
                margin: -83px 0;
                padding: 83px 0;

                &::-webkit-scrollbar{
                    display: none;
                    width: 0;
                    height: 0;
                }
            }

            li{
                margin-bottom: 132px;

                &.disable{
                    opacity: .5;
                }

                p{
                    color: #F1F1F1;
                    font-size: 80px;
                    font-weight: 700;
                    line-height: 120%;
                    font-family: var(--font-stretch);
                    text-transform: uppercase;
                    margin-bottom: 12px;
                    display: flex;
                    align-items: center;

                    i{
                        color: #F1F1F1;
                        font-size: 64px;
                        line-height: 120%;
                        font-style: normal;
                        position: relative;
                        display: flex;
                        align-items: center;

                        &::before{
                            width: 4px;
                            height: 96px;
                            content: "";
                            opacity: 0.4000000059604645;
                            background: #F1F1F1;
                            position: relative;
                            display: flex;
                            margin: 0 32px;
                        }
                    }
                }

                span{
                    color: #F1F1F1;
                    font-size: 50px;
                    line-height: 120%;
                }
            }
        }

    }

    &__list{
        display: flex;
        margin-top: 24px;

        span{
            padding: 20px 24px;
            border-radius: 10px;
            background: rgba(255, 255, 255, 0.30);
            color: #F1F1F1;
            text-align: right;
            font-size: 36px;
            font-weight: 900;
            line-height: 100%;
            letter-spacing: 1.44px;
            text-transform: uppercase;
            font-family: var(--font-stretch);
            margin-right: 12px;
            display: flex;
            align-items: center;

            &.access{
                background: #27AE60;
            }

            svg{
                width: 44px;
                height: 44px;
                margin-right: 10px;

                path{
                    fill: #F1F1F1;
                }
            }
        }
    }

    &__top{
        display: flex;
        align-items: center;
        margin-bottom: 52px;
    }

    &__info{
        margin-left: 44px;
        p{
            color: #F1F1F1;
            font-size: 100px;
            font-weight: 300;
            line-height: 120%;
        }
    }

    &__text{
        color: #F1F1F1;
        font-size: 40px;
        line-height: 150%;
        opacity: 0.6;
        max-width: 1205px;
        margin: 52px 0;
    }

    &::after{
        background: url(@/assets/images/bg-popup-vector.svg) no-repeat center/cover;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        content: "";
        opacity: .3;
        z-index: 0;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s ease-out;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
