<template>
    <div class="spawn">
        <div class="spawn__title">{{ $t('spawn_select.header') }}</div>

        <div class="spawn__wrapper">
            <div
                v-for="item in spawnItems"
                :key="item.type"
                class="spawn__item"
                :class="`spawn__item--${item.type}`"
                @click="enterSpawn(item.type)"
            >
                <div class="spawn__enter" v-if="isEnter === item.type">
                    <img src="@/assets/images/loader.png" class="spawn__enter-loader">
                    <div class="spawn__enter-title">{{ $t('spawn_select.logging_in') }}</div>
                    <base-button white @click.stop="cancelEnter()">{{ $t('spawn_select.cancel') }}</base-button>
                </div>

                <div v-if="item.type === 'home'" class="spawn__home-items">
                    <div
                        v-for="homeItem in item.homeItems"
                        :key="homeItem.name"
                        class="spawn__home-item"
                        @click.stop="enterHomeSpawn(item.type, homeItem.correctID)"
                    >
                        <div class="spawn__home-name">{{ homeItem.name }}</div>
                        <div class="spawn__home-address">{{ homeItem.address }}</div>

                        <div class="spawn__enter" v-if="isEnter === `${item.type}.${homeItem.name}`">
                            <img src="@/assets/images/loader.png" class="spawn__enter-loader">
                            <div class="spawn__enter-title">{{ $t('spawn_select.logging_in') }}</div>
                            <base-button white @click.stop="cancelEnter()">{{ $t('spawn_select.cancel') }}</base-button>
                        </div>
                    </div>
                </div>

                <p>{{ item.description }}</p>

                <span v-if="item.additionalInfo">{{ item.additionalInfo }}</span>

                <div v-if="item.noSpawn && item.noSpawn.active" class="spawn__no-spawn">
                    <IconLock />
                    <div class="spawn__no-spawn-title">{{ item.noSpawn.title }}</div>
                    <div class="spawn__no-spawn-desc">{{ item.noSpawn.text }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseButton from '@/components/ui/BaseButton.vue';
import IconLock from '@/components/icons/IconLock.vue';

export default {
    name: 'SpawnSelect',
    components: { IconLock, BaseButton },
    data () {
        return {
            isEnter: null,
            spawnItems: [
                {
                    type: 'last',
                    description: this.$t('spawn_select.last_place'),
                    additionalInfo: this.$t('spawn_select.gun_save'),
                    homeItems: [],
                    noSpawn: {
                        active: false,
                        title: '',
                        text: ''
                    }
                },
                {
                    type: 'home',
                    description: this.$t('spawn_select.living_place'),
                    homeItems: [
                        { name: this.$t('spawn_select.home_list.house'), address: 'Vinewood Hills 12/42' },
                        { name: this.$t('spawn_select.home_list.hotel'), address: 'Central street 12' },
                        { name: this.$t('spawn_select.home_list.house_with_garage'), address: 'Vinewood Hills 232' }
                    ],
                    noSpawn: {
                        active: false,
                        title: this.$t('spawn_select.no_home'),
                        text: this.$t('spawn_select.buy_house_to_unlock')
                    }
                },
                {
                    type: 'airport',
                    description: this.$t('spawn_select.airport'),
                    homeItems: [],
                    noSpawn: {
                        active: false,
                        title: this.$t('spawn_select.no_airport'),
                        text: ''
                    }
                },
                {
                    type: 'organization',
                    description: this.$t('spawn_select.organization'),
                    noSpawn: {
                        active: true,
                        title: this.$t('spawn_select.no_organization'),
                        text: this.$t('spawn_select.no_organization_description')
                    },
                    homeItems: []
                }
            ]
        };
    },
    methods: {
        enterSpawn (type) {
            const item = this.spawnItems.find(i => i.type === type);
            if (item/* && !item.noSpawn.active */) {
                let spawnType = 1;
                if (type === 'last')spawnType = 1;
                else if (type === 'airport')spawnType = 3;
                else if (type === 'organization')spawnType = 4;
                this.sendclient('spawnChosen', JSON.stringify({ a: spawnType, b: 0 }));
                // this.isEnter = type;
            }
        },
        enterHomeSpawn (type, correctID) {
            const item = this.spawnItems.find(i => i.type === type);
            if (item/* && !item.noSpawn.active */) {
                this.sendclient('spawnChosen', JSON.stringify({ a: 2, b: correctID }));
                // this.isEnter = `${type}.${homeName}`;
            }
        },
        cancelEnter () {
            this.isEnter = null;
        }
    }
};
</script>
