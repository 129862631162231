<template>
    <div class="health">
        <div class="health__point">
            <p>{{ roundHealth }}</p>
            <IconHealth />
        </div>
        <div class="health__progress">
            <i :style="{ width: roundProgress + '%' }"></i>
        </div>
        <div class="armor__point">
            <IconArmor />
            <p>{{ roundArmor }}</p>
        </div>
    </div>
</template>
<script>
import IconHealth from '@/components/icons/IconHealth.vue';
import IconArmor from '@/components/icons/IconArmor.vue';

export default {
    name: 'HealthPoint',
    components: { IconHealth, IconArmor },
    data () {
        return {
            health: 400,
            maxHealth: 500,
            armor: 100
        };
    },
    computed: {
        roundHealth () {
            return Math.round(this.health);
        },
        roundArmor () {
            return Math.round(this.armor);
        },
        progress () {
            return (this.health / this.maxHealth) * 100;
        },
        roundProgress () {
            return Math.round(this.progress);
        }
    }
};
</script>
