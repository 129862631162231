<template>
    <div class="graph">
        <svg class="progress-ring" :style="{width,height}">
            <circle
                class="progress-ring__circle"
                :stroke="innerCircleColor"
                :stroke-width="innerCircleWidth"
                fill="transparent"
                :r="radius"
                :cx="circleCenter"
                :cy="circleCenter"
                :stroke-dasharray="innerCircleStroke"
            />
            <circle
                ref="outerCircle"
                class="progress-ring__circle"
                :stroke="outerCircleColor"
                :stroke-width="outerCircleWidth"
                fill="transparent"
                :r="outerRadius"
                :cx="circleCenter"
                :cy="circleCenter"
            />
        </svg>
        <div class="graph__text">{{ $t('base_graph.current_profit') }}</div>
    </div>
</template>

<script>
export default {
    name: 'BaseGraph',
    data () {
        return {
            radius: Number(this.width.slice(0, -2)) / 2 - 0.5 + 'vw',
            circleCenter: Number(this.width.slice(0, -2)) / 2 + 'vw',
            outerRadius: Number(this.width.slice(0, -2)) / 2 - 0.6 + 'vw'
        };
    },
    props: {
        width: {
            type: String,
            default: '9vw'
        },
        height: {
            type: String,
            default: '9vw'
        },
        innerCircleWidth: {
            type: String,
            default: '.4vw'
        },
        innerCircleStroke: {
            type: String,
            default: '.9vw .2vw'
        },
        innerCircleColor: {
            type: String,
            default: 'rgba(241, 241, 241, 0.3)'
        },
        outerCircleColor: {
            type: String,
            default: '#9FD035'
        },
        outerCircleWidth: {
            type: String,
            default: '.95vw'
        },
        progress: {
            type: Number,
            default: 100
        }
    },
    mounted () {
        this.setCircleData();
    },
    methods: {
        setCircleData () {
            const circle = this.$refs.outerCircle;
            const radius = circle.r.baseVal.value;
            const circumference = radius * 2 * Math.PI;

            circle.style.strokeDasharray = `${circumference} ${circumference}`;
            circle.style.strokeDashoffset = `${circumference}`;

            function setProgress (percent) {
                circle.style.strokeDashoffset = String(circumference - (percent / 100) * circumference);
            }

            return setProgress(this.progress);
        }
    }
};
</script>

<style lang="scss" scoped>
.graph {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &__text {
        text-align: center;
        text-transform: uppercase;
        font-family: var(--font-stretch);
        position: absolute;
        font-weight: 700;
        font-size: 1.1vw;
        max-width: 5vw;
        line-height: 110%;
        color:var(--white);
        letter-spacing: 0.06em;
    }
}
.progress-ring {
    width: 8.75vw;
    height: 8.75vw;
}
.progress-ring__circle {
    transition: 0.35s stroke-dashoffset;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
}
</style>
