<template>
    <transition name="fade">
        <div class="notification" v-if="visible" @click="openContact">
            <div class="notification-player">{{ this.player }}</div>
            <div class="notification-text">
                {{ this.text }}
            </div>
            <div class="notification-bottom">
                <div class="notification-bottom__text">{{ $t('contacts.notification.open_dialog') }}</div>
            <div class="notification-bottom__text">{{ $t('contacts.notification.now') }}</div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'contactsNotification',
    data () {
        return {
            visible: false,
            sim: 123456,
            player: 'Kevin Mckalister',
            text: 'Не следует, однако забывать, что рамки и место обучения кадров позволяет выполнять важные задания по разработке разработке разработке шарлотке'
        };
    },
    mounted () {
        this.showAndHideNotification(2000, 1000);
    },
    methods: {
        showAndHideNotification (name, text, simcard, duration, delay) {
            this.player = name;
            this.text = text;
            this.sim = simcard;
            setTimeout(() => {
                this.visible = true;
                setTimeout(() => {
                    this.visible = false;
                }, duration); // Уведомление исчезает через 2 секунды после появления
            }, delay); // Задержка между уведомлениями
        },
        openContact () {
            this.sendclient('loadContactDialog', JSON.stringify({ a: this.sim }));
        }
    }
};
</script>

<style lang="scss" scoped>
.notification {
    position: fixed;
    max-width: 1120px;
    min-width: 560px;
    width: fit-content;
    left: 630px;
    top: 115px;
    background-color: var(--dark);
    padding: 14px 24px;
    border-radius: 24px;
    margin-left: 13px;
    &::after {
        content: "";
        position: absolute;
        width: 26px;
        height: 24px;
        bottom: 0;
        left: -13px;
        background-image: url("@/assets/images/contacts/foreign-message.svg");
        background-position: center;
        background-size: contain;
    }
    &-player {
        color: var(--white);
        opacity: .4;
        font-size: 32px;
        font-weight: 500;
        line-height: 120%;
    }
    &-text {
        display: -webkit-box;
        max-width: 1059px;
        font-size: 32px;
        font-weight: 400;
        line-height: 130%;
        color: var(--white);
        margin: 8px 0;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    &-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        opacity: .4;
        &-text {
            color: var(--white);
            font-size: 22px;
            font-weight: 500;
            line-height: 130%;
        }
    }
}
.contacts-chat__message {
}
</style>
