<template>
    <div class="dialog dialog--center">
        <div class="dialog__wrapper">
            <div class="dialog__title-wrapper">
                <div class="dialog__title">
                    {{title}}
                </div>
                <div class="dialog__desc">
                   {{ desc }}
                </div>
            </div>
            <div class="dialog__btns">
                <BaseButton
                    center
                    border
                    @click="handleMap"
                >
                    {{ $t('dialog_where.point') }}
                </BaseButton>
                <BaseButton
                    center
                    white
                    @click="handleDone"
                >
                    {{ $t('dialog_where.confirm') }}
                </BaseButton>
            </div>
        </div>
    </div>
</template>
<script>
import BaseButton from '@/components/ui/BaseButton.vue';

export default {
    name: 'DialogWhere',
    components: { BaseButton },
    data () {
        return {
            title: 'Где найти Red apple?',
            desc: ' Редкий автомат с небольшим магазином и средним уроном. Подходит на ближних дистанциях и помогает быстро расправится с врагом.'
        };
    },

    methods: {
        handleMap () {
            console.log('handleMap');
        },
        handleDone () {
            console.log('handleDone');
        }
    }

};
</script>

<style lang="scss">
.dialog {
    &__btns{
        display: grid;
        grid-template-columns: repeat(2, max-content);
        justify-content: center;
        grid-gap: 36px;
        width: max-content;
        margin: 0 auto
    }

    &__desc{
        max-width: 1236px;

        margin: 0 auto;
        margin-top: 64px;
        color: #F1F1F1;
        text-align: center;
        font-size: 50px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 70px */
        opacity: 0.66;
    }
}
</style>
