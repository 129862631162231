<template>
    <section class="enter-rewards">
        <div class="enter-rewards__header">
            <div class="enter-rewards__header-title">{{ $t('information.enter_rewards.title') }}</div>
            <!--<div class="enter-rewards__header-remained">{{ timeLeft }}</div>-->
        </div>

        <div class="enter-rewards__items">
            <div
                v-for="(item, index) in enterRewards"
                :key="index" class="enter-rewards__item"
                :class="{
                    'enter-rewards__car': item.car
                }"
            >
                <div class="enter-rewards__bg" v-if="item"
                     :class="{
                        'enter-rewards__bg--take': item.take,
                        'enter-rewards__bg--today': todayItem && todayItem.index === item.index
                    }">
                    <span>{{ !item.take ? index + 1 : '' }}</span>
                </div>
                <InventoryItem :item="item" />
            </div>

        </div>

        <base-button class="enter-rewards__btn" @click="getEnterReward" white>{{ $t('information.enter_rewards.take') }}</base-button>
    </section>
</template>

<script>
import InventoryItem from '@/components/popups/components/inventoryItem/InventoryItem.vue';
import { mapGetters } from 'vuex';
import BaseButton from '@/components/ui/BaseButton.vue';

export default {
    name: 'EnterRewards',
    components: { BaseButton, InventoryItem },
    data () {
        return {
            totalHours: 190,
            enterRewards: [

                { id: 1, name: 'Штурмовая винтовка', rarity: 'unusual', type: 1, desc: 'Скорострельное огнестрельное оружие', img: 'other_items/m4', take: true },
                { id: 2, name: 'Пистолет', rarity: 'unusual', type: 1, desc: 'Скорострельное огнестрельное оружие', img: 'other_items/pistol', take: true },
                { id: 3, name: 'Верхняя одежда', rarity: 'ordinary', type: 2, desc: 'inventory.clothesDescription', img: '', take: true },
                { id: 4, name: 'Верхняя одежда', rarity: 'ordinary', type: 2, desc: 'inventory.clothesDescription', img: '' },
                { id: 5, name: 'Верхняя одежда', rarity: 'ordinary', type: 2, desc: 'inventory.clothesDescription', img: '' },
                { id: 6, name: 'Верхняя одежда', rarity: 'ordinary', type: 2, desc: 'inventory.clothesDescription', img: '' },
                { id: 7, name: 'Машина – зверь', desc: 'inventory.clothesDescription', img: 'vehicles/01_Huracan', car: true }

            ]
        };
    },
    computed: {
        ...mapGetters('inventory', ['inventoryItemsPool']),
        todayItem () {
            return this.enterRewards.find(item => item.take === undefined || item.take === false);
        },
        timeLeft () {
            const days = Math.floor(this.totalHours / 24);
            const hours = this.totalHours % 24;
            return `${this.$t('information.enter_rewards.left')} ${days} ${this.$t('information.enter_rewards.days')} ${hours} ${this.$t('information.enter_rewards.hours')}`;
        }

    },
    methods: {
        getEnterReward () {
            // console.log(this.todayItem);
            this.sendclient('dailyGetPrize');
        },
        /*
        window.modifyVueComponent({
            App: {
                showPopup: true,
                popupContent: 'EnterRewards',
                contentSubFolder: 'information'
            },
            EnterRewards:{
                initialize:[
                    [{
                    itemID: 1,
                    count: 1,
                    additional:0
                },
                {
                    itemID: 2,
                    count: 1,
                    additional:0
                },
                {
                    itemID: 3,
                    count: 1,
                    additional:0
                },
                {
                    itemID: 4,
                    count: 1,
                    additional:0
                },
                    {
                    itemID: 5,
                    count: 1,
                    additional:0
                },
                    {
                    itemID: 6,
                    count: 1,
                    additional:0
                },
                    {
                    itemID: 7,
                    count: 1,
                    additional:0
                },],3
                ]
            }
        });
        */
        initialize (itemList, day) {
            // console.log(itemList);
            this.enterRewards = new Array(7);// [];
            for (let i = 0; i < itemList.length; i++) {
                if (itemList[i].itemID) {
                    this.enterRewards[i] = Object.assign({}, this.inventoryItemsPool[itemList[i].itemID - 1]);
                    this.enterRewards[i].count = itemList[i].count;
                    this.enterRewards[i].index = i + 1;
                    this.enterRewards[i].additional = itemList[i].additional;
                    if (day > i + 1) {
                        this.enterRewards[i].take = true;
                    } else {
                        this.enterRewards[i].take = undefined;
                    }
                } else if (itemList[i].isCar) {
                    this.enterRewards[i] = { id: i + 1, name: this.$t('vehicle'), img: itemList[i].model, car: true };
                } else this.enterRewards[i] = { id: null, name: '', rarity: '', filterType: '', desc: '', img: '', count: 0, index: i + 1 };
            }
            // console.log(this.enterRewards[0] === this.enterRewards[1]);
        }
    }
};
</script>

<style lang="scss">
.enter-rewards{
    background: url(@/assets/images/information/enter-rewards.jpg) no-repeat center / cover;
    width: 100%;
    height: 100%;
    padding: 155px 0 0 284px;
    position: relative;

    &::before{
        height: 2px;
        width: 100%;
        position: absolute;
        content: "";
        background: #fff;
        opacity: .2;
        top: 386px;
        left: 0;
    }

    &::after{
        width: 2px;
        height: 100%;
        position: absolute;
        content: "";
        background: #fff;
        opacity: .2;
        top: 0;
        left: 220px;
    }

    &__car {
        width: 604px;

        .inventory__cards-item {
            background: none !important;

            &--car{
                position: relative;
                display: flex;
                justify-content: center;

                &::after {
                    border-radius: 24px 24px 0px 0px;
                    background: linear-gradient(180deg, #FF6636 45.83%, rgba(255, 102, 54, 0.00) 100%);
                    position: absolute;
                    top: 0;
                    content: '';
                    margin: 0 auto;
                    width: 436px;
                    height: 400px;
                    display: block;
                }
                img {
                    position: absolute;
                    top: 120px;
                }

                p {
                    position: absolute;
                    top: 32px;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    width: 100%;
                    text-align: center;

                    color: #F1F1F1;
                    font-size: 64px;
                    font-weight: 900;
                    line-height: 100%; /* 64px */
                    letter-spacing: 1.28px;
                    text-transform: uppercase;
                    max-width: 388px;
                }
            }
        }
    }

    &__line{
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
    }

    &__header{
        margin-bottom: 128px;
        &-title{
            color: #141A1E;
            font-size: 84px;
            font-weight: 900;
            line-height: 90%; /* 75.6px */
            letter-spacing: 1.68px;
            font-family: var(--font-stretch);
            text-transform: uppercase;
            background: #F1F1F1;
            padding: 16px;
            width: max-content;
        }
        &-remained{
            color: #141A1E;
            font-size: 32px;
            font-weight: 900;
            line-height: 90%; /* 75.6px */
            letter-spacing: 1.68px;
            text-transform: uppercase;
            background: #FFCD4C;
            padding: 16px;
            width: max-content;
        }
    }

    &__items{
        display: grid;
        grid-template-columns: 220px 220px 220px 350px;
        grid-gap: 44px;
    }

    &__btn{
        bottom: 156px;
        right: 300px;
        position: absolute;
    }

    &__bg {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        span{
            width: 96px;
            height: 96px;
            display: flex;
            border-radius: 1000px;
            background: rgba(20, 26, 30, 0.60);
            box-shadow: 0 0 0 6px #F1F1F1;
            align-items: center;
            justify-content: center;

            color: #F1F1F1;
            text-align: center;
            font-size: 44px;
            font-weight: 900;
            line-height: 100%; /* 44px */
            letter-spacing: -1.32px;
        }

        &--take{
            background: rgba(20, 26, 30, 0.78);

            span{
                background: #9FD035 url(@/assets/images/checkmark.svg) no-repeat center / 44px;
                box-shadow: 0px 0px 100px 0px #9FD035;
            }
        }

        &--today{
            span{
                box-shadow: 0 0 0 12px #F1F1F1;
            }
        }

    }

    &__item{
        position: relative;
        overflow: hidden;
        border-radius: 0.2vw;

        &:last-child{
            grid-column: 4/-1;
            grid-row: 1 / 3;

            .inventory__cards-item{
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>
