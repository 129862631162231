<template>
    <div class="icon-star">
        <svg v-if="checked" :class="className" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.9998 3.33301C20.6654 3.33301 21.2673 3.72909 21.5305 4.34046L25.6455 13.8957L36.0047 14.8565C36.6675 14.918 37.2301 15.368 37.4358 16.001C37.6415 16.6341 37.4508 17.3289 36.9507 17.7682L29.1347 24.6345L31.4221 34.7835C31.5684 35.4329 31.3144 36.1071 30.7759 36.4983C30.2373 36.8896 29.5176 36.9229 28.9453 36.583L19.9998 31.2713L11.0543 36.583C10.4819 36.9229 9.76225 36.8896 9.22373 36.4983C8.6852 36.1071 8.43113 35.4329 8.57749 34.7835L10.8649 24.6345L3.04887 17.7682C2.54878 17.3289 2.35806 16.6341 2.56376 16.001C2.76945 15.368 3.33212 14.918 3.99493 14.8565L14.3541 13.8957L18.469 4.34046C18.7323 3.72909 19.3341 3.33301 19.9998 3.33301Z" fill="#FFCD4C"/>
        </svg>
        <svg v-else :class="className" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.4">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.0002 3.33301C20.6658 3.33301 21.2676 3.72909 21.5309 4.34046L25.6458 13.8957L36.005 14.8565C36.6678 14.918 37.2305 15.368 37.4362 16.001C37.6419 16.6341 37.4512 17.3289 36.9511 17.7682L29.1351 24.6345L31.4225 34.7835C31.5688 35.4329 31.3147 36.1071 30.7762 36.4983C30.2377 36.8896 29.518 36.9229 28.9456 36.583L20.0002 31.2713L11.0547 36.583C10.4823 36.9229 9.76262 36.8896 9.2241 36.4983C8.68557 36.1071 8.4315 35.4329 8.57785 34.7835L10.8653 24.6345L3.04924 17.7682C2.54915 17.3289 2.35842 16.6341 2.56412 16.001C2.76982 15.368 3.33249 14.918 3.9953 14.8565L14.3545 13.8957L18.4694 4.34046C18.7327 3.72909 19.3345 3.33301 20.0002 3.33301ZM20.0002 9.21346L17.0246 16.1231C16.7833 16.6834 16.2552 17.0671 15.6477 17.1234L8.15676 17.8182L13.8087 22.7833C14.267 23.186 14.4687 23.8068 14.3346 24.4019L12.6805 31.7409L19.1492 27.8999C19.6738 27.5885 20.3266 27.5885 20.8511 27.8999L27.3198 31.7409L25.6657 24.4019C25.5316 23.8068 25.7333 23.186 26.1916 22.7833L31.8436 17.8182L24.3526 17.1234C23.7452 17.0671 23.217 16.6834 22.9758 16.1231L20.0002 9.21346Z" fill="#F1F1F1"/>
            </g>
        </svg>
    </div>
</template>
<script>
export default {
    props: {
        className: String,
        checked: Boolean
    }
};
</script>
