<template>
    <div class="dialog dialog--center">
        <div class="dialog__wrapper">
            <div class="dialog__title-wrapper">
                <div class="dialog__title dollar" v-if = "price > 0">
                    <span class="dollar__symbol">$</span>{{ $formatNum(price) }}
                </div>
                <div class="dialog__description">
                    {{title}}
                </div>
            </div>

            <YesNo v-model="value" @input="onValueChange"/>
        </div>
    </div>
</template>
<script>
import YesNo from '@/components/ui/YesNo.vue';

export default {
    name: 'DialogYesNo',
    components: { YesNo },
    data () {
        return {
            title: '',
            price: 0,
            value: null
        };
    },
    methods: {
        onValueChange (state) {
            if (typeof state !== 'boolean') return;
            // console.log('Выбранное значение:', value);
            this.sendclient('DialogResponse', JSON.stringify({ value: state }));
        }
    }

};
</script>
