var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bp-main__slider-item",class:{
    'bp-main__slider-item--gained' : _vm.item.isOwned,
    'bp-main__slider-item--current' : _vm.item.id === _vm.level
}},[_c('div',{staticClass:"bp-main__slider-item-inventory"},[(!_vm.playerOwnBattlePass && _vm.item.isPremium)?_c('div',{staticClass:"bp-main__slider-premium"},[_c('div',{staticClass:"bp-main__slider-premium-text"},[_vm._v(" premium ")]),_c('img',{staticClass:"bp-main__slider-premium-image",attrs:{"src":require("@/assets/images/battlePass/premium-lock.svg"),"alt":""}})]):_vm._e(),_c('div',{on:{"click":function($event){!_vm.openedReward && _vm.$emit('open-reward')}}},[_c('InventoryItem',{attrs:{"item":_vm.item}})],1),(_vm.bonusSlider)?_c('div',{staticClass:"bp-finish__slider-inv",on:{"click":function($event){!_vm.openedReward && _vm.$emit('open-reward-second')}}},[_c('InventoryItem',{attrs:{"item":_vm.item.secondReward}})],1):_vm._e(),(_vm.item.isOwned)?_c('div',{staticClass:"bp-main__slider-item-owned"},[_c('IconCheckmark')],1):_vm._e()]),(!_vm.item.isOwned && _vm.level > _vm.item.id && (_vm.item.isPremium ? !!_vm.playerOwnBattlePass : true))?_c('BaseButton',{staticClass:"bp-main__slider-item-button",attrs:{"white":""},on:{"click":function($event){return _vm.$emit('get-prize')}}},[_vm._v(" "+_vm._s(_vm.$t('shop.get'))+" ")]):_vm._e(),_c('div',{staticClass:"bp-main__slider-item-level",class:{'bp-main__slider-item-level--owned' : _vm.item.isOwned}},[_vm._v(_vm._s(_vm.item.id))]),_c('IconLevelLine',{staticClass:"bp-main__slider-item-icon",class:{
        'bp-main__slider-item-icon--owned' : _vm.item.isOwned,
        'bp-main__slider-item-icon--current' : !_vm.item.isOwned && _vm.item.id === _vm.level
    }}),(_vm.item.id === _vm.level)?_c('IconCurrentLevel',{staticClass:"bp-main__slider-item-current"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }