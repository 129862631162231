<template>
    <svg viewBox="0 0 544 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.7" d="M22.4962 0L30.5306 5.5V0H372.941L385.796 5.5L391.42 0H536.714L544 3.5V18H492.653L487.102 15H478.776V11L467.118 18H341.388L317.796 15L324.041 18H159.869L163.755 11L148.81 18H0V0H22.4962Z" :fill="this.$props.chartBackground"/>
        <mask id="mask0_2385_28363" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" :width="this.$props.percent + '%'" height="18">
            <rect :width="this.$props.percent + '%'" height="18" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_2385_28363)">
            <path d="M22.4962 0L30.5306 5.5V0H372.941L385.796 5.5L391.42 0H536.714L544 3.5V18H492.653L487.102 15H478.776V11L467.118 18H341.388L317.796 15L324.041 18H159.869L163.755 11L148.81 18H0V0H22.4962Z" :fill="this.$props.chartColor"/>
        </g>
    </svg>
</template>
<script>
export default {
    name: 'BaseChart',
    props: {
        percent: Number,
        chartColor: String,
        chartBackground: String
    }
};
</script>
