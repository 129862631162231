 <template>
    <div class="buyout" :style="{background: `linear-gradient(to right, rgba(20, 26, 30, 0.8), rgba(20, 26, 30, 0.8)), url(${imageSrc('business-3-lvl.png', 'estate')}) no-repeat center/cover`}">
        <AppHeader back-button>
            <template #title>
                {{ $t('auction.header') }}
            </template>
        </AppHeader>
        <div v-if="isFinished" class="buyout__finish">
            <div class="buyout__finish-container">
                <div class="buyout__info-title buyout__finish-title">{{estateTitle}}</div>
                <div class="buyout__info-subtitle" v-if="address">{{ address }}</div>
                <EstateHouseBadge :level="estateLevel"/>
                <div class="buyout__info-item buyout__finish-item" v-if="type !== 2">
                    <EstateLevel v-if="type === 1 && level > 0" :level=level height="7.1vw" width="7.1vw" />
                    <AuctionPrice :price="auctionPrice" :text="$t('buyout_auction.buyout_price')" class="buyout__finish-price" />
                    <button class="glass-button estate__nav-map" @click="getMap">
                        <span class="glass-button__border"></span>
                        <span class="glass-button__border"></span>
                        <span class="glass-button__border"></span>
                        <span class="glass-button__border"></span>
                        <img src="@/assets/images/estate/location.svg" alt="">
                    </button>
                </div>
                <div v-else class="buyout__info-item buyout__finish-item">
                    <AuctionPrice :price="auctionPrice" :text="$t('buyout_auction.buyout_price')" class="buyout__finish-price" />
                    <button class="glass-button estate__nav-map" @click="getMap">
                        <span class="glass-button__border"></span>
                        <span class="glass-button__border"></span>
                        <span class="glass-button__border"></span>
                        <span class="glass-button__border"></span>
                        <img src="../../../../assets/images/estate/location.svg" alt="">
                    </button>
                </div>
                <div class="buyout__finish-owner">{{ owner }}</div>
                <div class="buyout__info-subtitle">{{ $t('buyout_auction.new_owner') }}</div>
                <BaseButton white class="buyout__finish-button" @click="backAuction">
                    <IconBackArrow />
                    {{ $t('buyout_auction.back') }}
                </BaseButton>
            </div>
            <IconBuyoutFinish class="buyout__finish-circle" />
        </div>
        <div v-else class="buyout__content">
            <div class="buyout__info">
                <div class="buyout__info-title">{{ estateTitle }}</div>
                <div class="buyout__info-subtitle" v-if="address">{{ address }}</div>
                <EstateHouseBadge :level="estateLevel" v-if="estateLevel !== 0"/>
                <div class="buyout__info-item" :class="{'buyout__info-item--sm' : type !== 1 || type === 1 && level === 0}">
                    <EstateLevel :level="level" v-if="type === 1 && level > 0"/>
                    <AuctionPrice :price="auctionPrice" :text="$t('buyout_auction.start_price')" />
                    <AuctionTimer :hour = "getHours(auctionSecond)" :minute="getMinutes(auctionSecond)" :second='getSeconds(auctionSecond)' :text="$t('buyout_auction.time_for_bid')" />
                    <button class="glass-button estate__nav-map" @click="getMap">
                        <span class="glass-button__border"></span>
                        <span class="glass-button__border"></span>
                        <span class="glass-button__border"></span>
                        <span class="glass-button__border"></span>
                        <img src="../../../../assets/images/estate/location.svg" alt="">
                    </button>
                </div>
                <div v-if="!isBidMade" class="buyout__info-counter">
                    <button class="buyout__info-button buyout__info-button--decrease" @click="decreaseBidAmount"></button>
                    <div class="buyout__info-stats">
                        <div class="buyout__info-stats-title">{{ $t('buyout_auction.increase_bid') }}</div>
                        <div class="buyout__info-stats-percent">{{ bidPercent }}%</div>
                        <div class="buyout__info-stats-old"><span>$</span>{{ $formatNum(Math.round(auctionPrice + (auctionPrice * bidPercent / 100))) }}</div>
                    </div>
                    <button class="buyout__info-button buyout__info-button--increase" @click="increaseBidAmount"></button>
                </div>
                <div v-else class="buyout__info-complete">
                    <IconCheckmark />
                    <div class="buyout__info-stats-old"><span>$</span>{{ $formatNum(newBidAmount) }}</div>
                    <div class="buyout__info-complete-text">{{ $t('buyout_auction.bid_done') }}</div>
                </div>
                <BaseButton yellow class="buyout__info-submit" @click="confirmBid" v-if = "!isBidMade">
                    <IconCheckmark />
                    {{ $t('buyout_auction.confirm_bid') }}
                </BaseButton>
            </div>
            <div class="buyout__list">
                <div class="buyout__list-title">{{ $t('buyout_auction.last_bids') }}</div>
                <div v-for="(bid, index) in lastBids" :key="index" class="buyout__list-item">
                    <div class="buyout__list-info">
                        <div class="buyout__list-name">{{ bid.name }}</div>
                        <div class="buyout__list-title buyout__list-time" v-if = "bid.time">{{ bid.time }}</div>
                    </div>
                    <div class="buyout__list-price"><span>$</span>{{ $formatNum(bid.price) }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AppHeader from '@/components/AppHeader/AppHeader.vue';
import EstateHouseBadge from '@/components/popups/content/estate/components/EstateHouseBadge.vue';
import EstateLevel from '@/components/ui/EstateLevel.vue';
import AuctionPrice from '@/components/popups/content/auction/components/AuctionPrice.vue';
import AuctionTimer from '@/components/popups/content/auction/components/AuctionTimer.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import IconCheckmark from '@/components/icons/IconCheckmark.vue';
import IconBuyoutFinish from '@/components/icons/IconBuyoutFinish.vue';
import IconBackArrow from '@/components/icons/IconBackArrow.vue';
export default {
    name: 'BuyoutAuction',
    data () {
        return {
            auctionInterval: undefined,
            isFinished: false,
            lastBids: [
                { name: 'Kevin Mackalister', price: '42432' },
                { name: 'Kevin Mackalister', price: '42432' },
                { name: 'Kevin Mackalister', price: '42432' },
                { name: 'Kevin Mackalister', price: '42432' },
                { name: 'Kevin Mackalister', price: '42432' },
                { name: 'Kevin Mackalister', price: '42432' },
                { name: 'Kevin Mackalister', price: '42432' },
                { name: 'Kevin Mackalister', price: '42432' },
                { name: 'Kevin Mackalister', price: '42432' },
                { name: 'Kevin Mackalister', price: '42432' }
            ],
            type: 0, // 0, 1, 2 - как в MyAuction
            estateTitle: 'Gas station 20223 Gallon forever',
            address: 'Vinewood 233/134',
            owner: 'Kevin Mackalister',
            estateLevel: 0,
            level: 0,
            auctionPrice: 310050,
            auctionSecond: 0,
            bidPercent: 20,
            oldBidAmount: 90000,
            isBidMade: false,
            newBidAmount: 0,
            propertyID: 0
        };
    },
    components: { IconBackArrow, IconBuyoutFinish, IconCheckmark, BaseButton, AuctionTimer, AuctionPrice, EstateLevel, EstateHouseBadge, AppHeader },
    computed: {
        imageSrc () {
            return (img, folder) => {
                return require(`@/assets/images/${folder}/${img}`);
            };
        }
    },
    beforeDestroy () {
        if (this.auctionInterval !== undefined) {
            clearInterval(this.auctionInterval);
            this.auctionInterval = undefined;
        }
    },
    methods: {
        /*
        window.modifyVueComponent({
            App: {
                showPopup: true,
                popupContent: 'BuyoutAuction',
                contentSubFolder: 'auction'
            },
            BuyoutAuction: {
                initialize: [
                    1,
                    1,
                    'asd',
                    'dsa',
                    300,
                    0,
                    400,
                    [],
                ]
            }
        });
        */
        initialize (propertyID, type, name, typeName, price, level, /* estateLevel, */auctionSecond, lastBids) {
            if (this.auctionInterval !== undefined) {
                clearInterval(this.auctionInterval);
                this.auctionInterval = undefined;
            }
            this.propertyID = propertyID;
            this.type = type;
            this.estateTitle = name;
            this.address = typeName;
            this.auctionPrice = price;
            this.level = level;
            this.auctionSecond = auctionSecond;
            // this.estateLevel = estateLevel;
            this.lastBids = lastBids;
            this.auctionInterval = setInterval(() => {
                if (this.auctionSecond > 0) this.auctionSecond--;
            }, 1000);
        },
        decreaseBidAmount () {
            if (this.bidPercent >= 10) {
                this.bidPercent -= 5;
            } else if (this.bidPercent > 1) {
                this.bidPercent -= 2;
            }
            this.updateOldBidAmount();
        },
        increaseBidAmount () {
            if (this.bidPercent < 10) {
                this.bidPercent += 2;
            } else if (this.bidPercent < 50) {
                this.bidPercent += 5;
            } else if (this.bidPercent < 100) {
                this.bidPercent += 10;
            }
            this.updateOldBidAmount();
        },
        updateOldBidAmount () {
            this.oldBidAmount = Math.round(this.auctionPrice * (this.bidPercent / 100));
        },
        confirmBid () {
            const newBid = Math.round(this.auctionPrice + (this.auctionPrice * this.bidPercent / 100));
            this.sendclient('AuctionBet', JSON.stringify({ a: newBid }));
        },
        SetNewBetValue (playerName, bet, time) {
            this.auctionPrice = bet;
            this.lastBids.unshift({ name: playerName, price: bet });
            this.auctionSecond = time;
        },
        SetMyBet (bet) {
            this.isBidMade = true;
            this.newBidAmount = bet;
            setTimeout(() => {
                this.isBidMade = false;
            }, 3000);
        },
        SetAuctionFinish () {
            this.isFinished = true;
        },
        backAuction () {
            this.sendclient('ClientOpenAuction');
        },
        getHours (time) {
            const hours = Math.floor(time / 3600);
            const formattedHours = hours < 10 ? `0${hours}` : hours;
            return formattedHours.toString();
        },
        getMinutes (time) {
            const minutes = Math.floor(time / 60);
            // const remainingSeconds = seconds % 60;

            const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
            // const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
            return formattedMinutes.toString();
        },
        getSeconds (time) {
            // const minutes = Math.floor(item.time / 60);
            const remainingSeconds = time % 60;

            // const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
            const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
            return formattedSeconds.toString();
        },
        getMap () {
            if (this.type === 0) this.sendclient('ClientFindBiz', JSON.stringify({ a: this.propertyID }));
            else if (this.type === 1) this.sendclient('ClientFindMiniBiz', JSON.stringify({ a: this.propertyID }));
            else if (this.type === 2) this.sendclient('ClientFindHouse', JSON.stringify({ a: this.propertyID }));
        }
    }
};
</script>
<style lang="scss">
.estate {
    &__nav {
        &-map {
            img {
                width: 1.6vw;
                height: auto;
            }
        }
    }
}
.buyout {
    height: 100%;
    &__content {
        padding-left: 7.65vw;
        padding-right: 9.5vw;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-gap: 0 6.8vw;
        height: calc(100% - 5vw);
    }
    &__info {
        grid-column: 7 span;
        padding-top: 4.75vw;
        padding-bottom: 4.75vw;
        &-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 1.3vw 0;
        }
        &-title, &-subtitle, &-counter, &-item--sm {
            width: 75%;
            margin-left: auto;
            margin-right: auto;
        }
        &-title {
            text-align: center;
            color: var(--white);
            text-transform: uppercase;
            letter-spacing: 0.02em;
            font-family: var(--font-stretch);
            font-size: 3.15vw;
            font-weight: 900;
            margin-bottom: .65vw;
        }
        &-subtitle {
            font-weight: 700;
            line-height: 120%;
            text-align: center;
            color: var(--white);
            opacity: .6;
            font-family: var(--font-stretch);
            font-size: 1.05vw;
            text-transform: uppercase;
        }
        &-counter {
            display: flex;
        }
        &-stats {
            padding: 0 1.75vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            &-title {
                font-family: var(--font-stretch);
                font-weight: 700;
                color: var(--white);
                letter-spacing: 0.06em;
                text-transform: uppercase;
                font-size: 1.05vw;
            }
            &-percent {
                margin: .25vw 0;
                font-weight: 700;
                font-size: 3.6vw;
                line-height: 110%;
                color: var(--white);
            }
            &-old {
                color: var(--white);
                opacity: .4;
                letter-spacing: -0.02em;
                font-size: 2vw;
                font-weight: 700;
                white-space: nowrap;
                span {
                    margin-right: .16vw;
                }
            }
        }
        &-button {
            width: 10.5vw;
            height: 7.5vw;
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            &:active{
                opacity: .7;
            }
            &--decrease {
                background-image: url("@/assets/images/buyout/minus.svg");
            }
            &--increase {
                background-image: url("@/assets/images/buyout/plus.svg");
            }
        }
        &-submit {
            margin-left: auto;
            margin-right: auto;
            margin-top: 2.5vw;
            svg {
                width: 2.4vw;
                height: 2.4vw;
                margin-right: .7vw;
                path {
                    fill: var(--dark);
                }
            }
        }
        &-complete {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            &-text {
                color: #27AE60;
                text-transform: uppercase;
                font-family: var(--font-stretch);
                font-weight: 700;
                font-size: 1.05vw;
            }
            svg {
                width: 2.4vw;
                height: 2.4vw;
            }
            .buyout__info-stats-old {
                opacity: 1;
                margin: .5vw 0;
            }
        }
    }
    &__list {
        padding-top: 4.75vw;
        padding-bottom: 4.75vw;
        grid-column: 5 span;
        overflow-y: auto;
        &::-webkit-scrollbar {
            display: none;
        }
        &-title {
            text-align: right;
            font-family: var(--font-stretch);
            text-transform: uppercase;
            color: var(--white);
            opacity: .6;
            font-weight: 700;
            font-size: 1.05vw;
            letter-spacing: 0.06em;
        }
        &-item {
            padding: .95vw 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: .175vw solid rgba(241, 241, 241, 0.2);
            &:last-child {
                border-bottom: none;
            }
        }
        &-info {
            .buyout__list-title {
                text-align: left;
            }
        }
        &-name {
            font-weight: 400;
            color: var(--white);
            font-size: 1.6vw;
        }
        &-price {
            font-size: 1.75vw;
            font-weight: 700;
            color: var(--white);
            span {
                color: var(--gold);
            }
        }
    }
    &__finish {
        background: linear-gradient(270deg, rgba(47, 204, 169, 0.4) 0%, rgba(47, 204, 169, 0) 25%, rgba(47, 204, 169, 0) 75%, rgba(47, 204, 169, 0.4) 100%);
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        &-title {
            font-size: 2.5vw;
            width: 100%;
        }
        &-circle {
            position: absolute;
            top: -50%;
            width: 90%;
            left: 5%;
            height: 200%;
            z-index: 0;
        }
        &-container {
            max-width: 40vw;
            z-index: 2;
        }
        &-price {
            margin: 0;
        }
        &-item {
            padding: 1.3vw 0;
            border-bottom: .175vw solid rgba(241, 241, 241, 0.2);
            border-top: .175vw solid rgba(241, 241, 241, 0.2);
        }
        &-owner {
            text-align: center;
            font-size: 2.5vw;
            font-weight: 300;
            margin-bottom: .3vw;
        }
        &-button {
            margin: 3.3vw auto 0;
            svg {
                width: 2.4vw;
                height: auto;
                margin-right: .75vw;
            }
        }
    }
}
</style>
