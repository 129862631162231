<template>
    <div>
        <svg v-if="type === 'settings'" width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M57.0667 27.6L52 25.4667C51.7333 24.9333 51.4667 24.4 51.2 23.8667L53.3333 18.5333C53.8667 17.4667 53.6 16.4 52.8 15.6L49.0667 11.8667C48.2667 11.0667 47.2 10.8 46.1333 11.3333L41.0667 13.2C40.5333 12.9333 40 12.6667 39.4667 12.4L37.3333 7.33333C36.8 6.53333 35.7333 6 34.6667 6H29.3333C28.2667 6 27.2 6.53333 26.9333 7.6L24.8 12.6667C24 12.9333 23.4667 12.9333 22.9333 13.2L17.8667 11.3333C16.8 10.8 15.7333 11.0667 14.9333 11.8667L11.2 15.6C10.4 16.4 10.1333 17.4667 10.6667 18.5333L12.5333 23.6C12.5333 24.1333 12.2667 24.6667 12 25.4667L6.93334 27.6C5.86668 28.1333 5.33334 28.9333 5.33334 30V35.3333C5.33334 36.4 5.86668 37.4667 6.93334 37.7333L12 39.8667C12.2667 40.4 12.5333 40.9333 12.8 41.4667L10.6667 46.8C10.1333 47.8667 10.4 48.9333 11.2 49.7333L14.9333 53.4667C15.7333 54.2667 16.8 54.5333 17.8667 54L22.9333 52.1333C23.4667 52.4 24 52.6667 24.5333 52.9333L26.6667 58C27.2 59.0667 28 59.6 29.0667 59.6H34.4C35.4667 59.6 36.5333 59.0667 36.8 58L38.9333 52.9333C39.4667 52.6667 40 52.4 40.5333 52.1333L45.6 54C46.6667 54.2667 47.7333 54.2667 48.5333 53.4667L52.2667 49.7333C53.0667 48.9333 53.3333 47.8667 52.8 46.8L50.9333 41.7333C51.2 41.2 51.4667 40.6667 51.7333 40.1333L56.8 38C57.8667 37.4667 58.4 36.6667 58.4 35.6V30.2667C58.6667 28.9333 58.1333 28.1333 57.0667 27.6ZM42.6667 32.6667C42.6667 38.5333 37.8667 43.3333 32 43.3333C26.1333 43.3333 21.3333 38.5333 21.3333 32.6667C21.3333 26.8 26.1333 22 32 22C37.8667 22 42.6667 26.8 42.6667 32.6667Z" fill="#F1F1F1"/>
        </svg>
        <svg v-if="type === 'servers'" width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8 35.3333H56V56H8V35.3333ZM22.6667 45.7333C22.6667 47.9424 20.8758 49.7333 18.6667 49.7333C16.4575 49.7333 14.6667 47.9424 14.6667 45.7333C14.6667 43.5243 16.4575 41.7333 18.6667 41.7333C20.8758 41.7333 22.6667 43.5243 22.6667 45.7333Z" fill="#F1F1F1"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8 8H56V28.6667H8V8ZM18.6667 22.2667C20.8758 22.2667 22.6667 20.4758 22.6667 18.2667C22.6667 16.0575 20.8758 14.2667 18.6667 14.2667C16.4575 14.2667 14.6667 16.0575 14.6667 18.2667C14.6667 20.4758 16.4575 22.2667 18.6667 22.2667Z" fill="#F1F1F1"/>
        </svg>
        <svg v-if="type === 'profile'" width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32 29.6673C37.8909 29.6673 42.6667 24.8917 42.6667 19.0007C42.6667 13.1096 37.8909 8.33398 32 8.33398C26.1089 8.33398 21.3333 13.1096 21.3333 19.0007C21.3333 24.8917 26.1089 29.6673 32 29.6673Z" fill="#F1F1F1"/>
            <path d="M32 35.0007C40.7232 35.0007 48.468 39.1892 53.3333 45.6647V56.334H10.6667V45.6647C15.5319 39.1892 23.2768 35.0007 32 35.0007Z" fill="#F1F1F1"/>
        </svg>
        <svg v-if="type === 'inv'" width="97" height="96" viewBox="0 0 97 96" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M28.1253 17.1426V13.1426C28.1253 6.51516 33.4978 1.14258 40.1253 1.14258H56.1253C62.7527 1.14258 68.1253 6.51516 68.1253 13.1426V17.1426H88.1253V31.1426L72.1253 37.1426H24.1253L8.12524 31.1426V17.1426H28.1253ZM38.1253 13.1426C38.1253 12.038 39.0207 11.1426 40.1253 11.1426H56.1253C57.2298 11.1426 58.1253 12.038 58.1253 13.1426V17.1426H38.1253V13.1426Z" fill="#F1F1F1"/>
            <path d="M88.1253 41.8226L73.9386 47.1426H68.1253V55.1426H58.1253V47.1426H38.1253V55.1426H28.1253V47.1426H22.3119L8.12524 41.8226L8.12524 94.2854H88.1253V41.8226Z" fill="#F1F1F1"/>
        </svg>
        <svg v-if="type === 'map'" width="97" height="96" viewBox="0 0 97 96" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M61.575 20L35.175 12L4.375 16V80L35.175 76L61.575 84L92.375 80V16L61.575 20ZM35.175 22.5L61.575 30.4531V73.5L35.175 65.5468V22.5Z" fill="#F1F1F1"/>
        </svg>
        <svg v-if="type === 'shop'" width="97" height="96" viewBox="0 0 97 96" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3952_37311)">
                <path d="M25.818 51.4824L12.025 17H0.625V7H18.7962L22.3962 16H92.625L76.625 56H34.7946L29.7188 64H80.625V74H11.5312L25.818 51.4824Z" fill="#F1F1F1"/>
                <path d="M32.625 87C32.625 91.4184 29.0433 95 24.625 95C20.2067 95 16.625 91.4184 16.625 87C16.625 82.5816 20.2067 79 24.625 79C29.0433 79 32.625 82.5816 32.625 87Z" fill="#F1F1F1"/>
                <path d="M72.625 95C77.0434 95 80.625 91.4184 80.625 87C80.625 82.5816 77.0434 79 72.625 79C68.2066 79 64.625 82.5816 64.625 87C64.625 91.4184 68.2066 95 72.625 95Z" fill="#F1F1F1"/>
            </g>
            <defs>
                <clipPath id="clip0_3952_37311">
                    <rect width="96" height="96" fill="white" transform="translate(0.625)"/>
                </clipPath>
            </defs>
        </svg>
        <svg v-if="type === 'showroom'" width="97" height="96" viewBox="0 0 97 96" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3952_37315)">
                <path d="M8.01511 93.9999H17.8263V45.2746H80.4951V93.9999H90.3063V42.2794H97.1607L49.1607 12.001L1.16071 42.2794H8.01511V93.9999Z" fill="#F1F1F1"/>
                <path d="M74.3036 51.4287H24.0179V57.7144H74.3036V51.4287Z" fill="#F1F1F1"/>
                <path d="M74.3036 63.4287H24.0179V69.7144H74.3036V63.4287Z" fill="#F1F1F1"/>
                <path d="M74.3036 75.4287H24.0179V81.7144H74.3036V75.4287Z" fill="#F1F1F1"/>
                <path d="M74.3036 87.4287H24.0179V93.7144H74.3036V87.4287Z" fill="#F1F1F1"/>
            </g>
            <defs>
                <clipPath id="clip0_3952_37315">
                    <rect width="96" height="96" fill="white" transform="translate(0.875)"/>
                </clipPath>
            </defs>
        </svg>
    </div>
</template>
<script>
export default {
    name: 'MainMenuIcons',
    props: {
        type: String
    }
};
</script>
