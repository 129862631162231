<template>
    <div class="number-edit">
        <BaseInput
            focused
            autofocus
            type="text"
            :placeholder="$t('shop.plates.placeholder')"
            class="number-edit__input"
            v-model="inputValue"
            :maxLength="maxLength"
            @enter-pressed="editNumber"
        >
            <div class="number-edit__button" @click="editNumber"></div>
        </BaseInput>
        <div class="number-edit__info">
            <div class="number-edit__info-text">
                {{ $t('shop.plates.info') }}
            </div>
            <BaseButton class="number-edit__buy" yellow @click="buyNumber">
                {{ $t('shop.buy') }}
                <div class="number-edit__buy-price">
                    <IconDonate />
                    {{ $formatNum(platePrice) }}
                </div>
            </BaseButton>
        </div>
    </div>
</template>

<script>

import BaseInput from '@/components/ui/BaseInput.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import IconDonate from '@/components/icons/IconDonate.vue';

export default {
    name: 'ShopNumberEdit',
    components: {
        IconDonate,
        BaseButton,
        BaseInput
    },
    props: {
        plateID: Number,
        platePrice: Number,
        maxLength: Number
    },
    data () {
        return {
            inputValue: ''
        };
    },
    methods: {
        buyNumber () {
            this.sendclient('buyPlate', JSON.stringify({ a: this.inputValue, b: this.plateID }));
        },
        editNumber () {
            this.sendclient('changePlate', JSON.stringify({ a: this.inputValue, b: this.plateID }));
        }
    }
};
</script>

<style lang="scss" scoped>
.number-edit {
    height: calc(100% - 5vw);
    padding: 90px 128px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: radial-gradient(58.89% 127.6% at 50.06% 100%, rgba(32, 36, 39, 0.30) 0%, rgba(13, 18, 21, 0.60) 100%);
    //background: radial-gradient(58.89% 127.6% at 50.06% 100%, rgba(32, 36, 39, 0.50) 0%, rgba(13, 18, 21, 0.8) 80%);
    &__button {
        position: absolute;
        right: 64px;
        top: 50%;
        transform: translateY(-50%);
        width: 70px;
        height: 70px;
        background-image: url("@/components/popups/content/shop/assets/images/edit.svg");
        background-repeat: no-repeat;
        background-size: contain;
    }
    &__input {
        position: relative;
        &::v-deep input {
            text-align: left;
            max-width: 100%;
            width: 100%;
            padding: 0 64px;
            font-size: 64px;
            font-weight: 300;
            height: 158px;
            &::placeholder {
                opacity: .4;
            }
        }
    }
    &__info {
        display: flex;
        justify-content: space-between;
        &-text {
            max-width: 600px;
            color: var(--white);
            font-size: 32px;
            font-weight: 500;
            line-height: 140%;
            opacity: .6;
        }
    }
    &__buy {
        height: 146px;
        padding: 0 48px;
        &-price {
            margin-left: 32px;
            padding: 7px 12px 7px 7px;
            border-radius: 6px;
            background: var(--dark);
            color: var(--white);
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: -0.96px;
            display: flex;
            align-items: center;
            svg {
                width: 48px;
                height: 48px;
            }
        }
    }
}
</style>
