import Vue from 'vue';
import App from './App.vue';
import store from '../store';
import '@/assets/scss/main.scss';
import globalMixins from '@/plugins/globalMixins';
import unrealEngineMixin from '@/plugins/unrealEngineMixin';
import { sendRequestToUnrealEngine } from '@/plugins/unrealEngine.js';
import i18n from './i18n';
import './utils/filters';
import VueTheMask from 'vue-the-mask';

Vue.use(VueTheMask);

Vue.mixin(globalMixins);
Vue.mixin(unrealEngineMixin);

Vue.mixin({
    created () {
        if (this.$options.name) {
            window.vueComponents[this.$options.name] = this;
        }
    }
});

Vue.prototype.$imageLoaded = function () {
    console.log('Image loaded!');
    this.$emit('image-loaded');
};

Vue.config.productionTip = false;
window.vueComponents = {};

// const imagesContext = require.context('@/assets/images/', true, /\.(png|jpg)$/);
// imagesContext.keys().forEach(imagesContext);

const app = new Vue({
    store,
    i18n,
    render: (h) => h(App)
}).$mount('#app');

window.store = app.$store;
window.vueInstance = app;

window.changeLocale = function (locale) {
    window.vueInstance.$i18n.locale = locale;
};

sendRequestToUnrealEngine();

window.updateData = function (moduleName, mutationName, data) {
    if (moduleName) {
        window.store.commit(moduleName + '/' + mutationName, data);
    } else {
        window.store.commit(mutationName, data);
    }
};

window.updatePlayerData = function (updates) {
    for (const key in updates) {
        window.store.commit('player/' + key, updates[key]);
    }
};

function waitForComponent (componentName, callback) {
    const component = window.vueComponents[componentName];

    if (component) {
        setTimeout(() => {
            callback(component);
        }, 1);
    } else {
        setTimeout(() => {
            waitForComponent(componentName, callback);
        }, 1);
    }
};

window.setVueData = function (componentNameOrUpdates, propertyNameOrUpdates, value) {
    if (typeof componentNameOrUpdates === 'object') {
        const updates = componentNameOrUpdates;

        for (const componentName in updates) {
            const componentUpdates = updates[componentName];

            waitForComponent(componentName, () => {
                window.setVueData(componentName, componentUpdates);
            });
        }
    } else {
        const componentName = componentNameOrUpdates;
        const component = window.vueComponents[componentName];

        if (component) {
            if (typeof propertyNameOrUpdates === 'object') {
                const updates = propertyNameOrUpdates;

                for (const propertyName in updates) {
                    component[propertyName] = updates[propertyName];
                }
            } else {
                const propertyName = propertyNameOrUpdates;
                component[propertyName] = value;
            }
        } else {
            console.warn(`Component "${componentName}" not found.`);
        }
    }
};

window.getVueData = function (componentName, propertyName) {
    const component = window.vueComponents[componentName];

    if (component) {
        const value = component[propertyName];
        /*
        if (window.ue && window.ue.interface) {
            window.ue.interface.broadcast('getVueData', JSON.stringify({
                componentName,
                propertyName,
                value
            }));
        }
        */
        return value;
    } else {
        console.warn(`Component "${componentName}" not found.`);
    }
};

window.callVueMethod = function (componentName, methodName, ...args) {
    const component = window.vueComponents[componentName];

    if (component && typeof component[methodName] === 'function') {
        const result = component[methodName](...args);
        return result;
    } else {
        console.warn(`Component "${componentName}" or method "${methodName}" not found.`);
    }
};

window.modifyVueComponent = function (componentName, propertyNameOrMethodNameOrUpdates, valueOrArgs) {
    if (typeof componentName === 'object') {
        const updates = componentName;

        for (const componentName in updates) {
            const componentUpdates = updates[componentName];

            waitForComponent(componentName, () => {
                window.modifyVueComponent(componentName, componentUpdates);
            });
        }
    } else {
        const component = window.vueComponents[componentName];

        if (component) {
            if (typeof propertyNameOrMethodNameOrUpdates === 'object') {
                const updates = propertyNameOrMethodNameOrUpdates;

                for (const propertyNameOrMethodName in updates) {
                    const isMethod = typeof component[propertyNameOrMethodName] === 'function';

                    if (isMethod) {
                        const args = Array.isArray(updates[propertyNameOrMethodName]) ? updates[propertyNameOrMethodName] : [updates[propertyNameOrMethodName]];
                        component[propertyNameOrMethodName].apply(component, args);
                    } else {
                        Object.assign(component, { [propertyNameOrMethodName]: updates[propertyNameOrMethodName] });
                    }
                }
            } else {
                const propertyNameOrMethodName = propertyNameOrMethodNameOrUpdates;
                const isMethod = typeof component[propertyNameOrMethodName] === 'function';

                if (isMethod) {
                    const args = Array.isArray(valueOrArgs) ? valueOrArgs : [valueOrArgs];
                    component[propertyNameOrMethodName].apply(component, args);
                } else {
                    Object.assign(component, { [propertyNameOrMethodName]: valueOrArgs });
                }
            }
        } else {
            console.warn(`Component "${componentName}" not found.`);
        }
    }
};
