<template>
    <section class="x-modal">
        <img src="@/assets/images/information/x-man.png" class="x-modal__man">
        <img src="@/assets/images/information/right-x-donate.svg" class="x-modal__right">
        <img src="@/assets/images/information/top-donate.svg" class="x-modal__top">
        <div class="x-modal__header">
            <div class="x-modal__header-title">
                <svg width="243" height="164" viewBox="0 0 243 164" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M242.968 0.164062L133.494 31.8695L158.874 55.3359L125.2 93.8265L94.7931 54.0618L0 163.428L88.1714 108.014L120.832 153.196L196.389 90.004L219.262 111.149L242.968 0.164062Z" fill="#F1F1F1"/>
                </svg>
                {{ $t('information.double_donate.double_payment') }}</div>
            <div class="x-modal__header-desc">
                {{ $t('information.double_donate.time_limited') }}
            </div>
        </div>
        <div class="x-modal__time-count">
            <span>{{ Math.floor(totalSeconds / 3600 / 10) }}</span>
            <span>{{ Math.floor(totalSeconds / 3600) % 10 }}</span>
            <i>:</i>
            <span>{{ Math.floor((totalSeconds % 3600) / 60 / 10) }}</span>
            <span>{{ Math.floor((totalSeconds % 3600) / 60) % 10 }}</span>
            <i>:</i>
            <span>{{ Math.floor(totalSeconds % 60 / 10) }}</span>
            <span>{{ totalSeconds % 60 % 10 }}</span>
        </div>
        <base-button yellow @click="getPromo">{{ $t('information.double_donate.proceed') }}</base-button>
    </section>
</template>

<script>
import BaseButton from '@/components/ui/BaseButton.vue';

export default {
    name: 'XModal',
    components: { BaseButton },
    data () {
        return {
            totalSeconds: 3600,
            timer: null
        };
    },
    beforeDestroy () {
        this.stopTimer();
    },
    mounted () {
        this.startTimer(1, 0, 3600); // для тестов
    },
    /*
    window.modifyVueComponent({
        App: {
            showPopup: true,
            popupContent: 'X2Modal',
            contentSubFolder: 'information'
        },
    });
    */
    methods: {
        getPromo () {
            console.log('getPromo');
        },
        startTimer (type, amount, time) {
            if (this.timer) {
                this.stopTimer();
            }
            this.totalSeconds = time;
            this.timer = setInterval(() => {
                if (this.totalSeconds > 0) {
                    this.totalSeconds--;
                } else {
                    this.stopTimer();
                }
            }, 1000);
        },
        stopTimer () {
            clearInterval(this.timer);
            this.timer = null;
        }
    }
};
</script>

<style lang="scss">
.x-modal{
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url(@/assets/images/information/bg-x-modal.jpg) no-repeat center / cover;

    &__time-count{
        display: flex;
        margin-bottom: 100px;
        &-section{
            margin-right: 64px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            &.member{
                p{
                    color: #FFCD4C !important;
                    opacity: 1;
                }
                span{
                    background: #FFCD4C !important;
                    color: #141A1E !important;
                }
            }
        }
        &-count, &-member{
            display: flex;
            margin: 14px 0;
        }
        p{
            color: #F1F1F1;
            font-size: 82px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%; /* 26px */
            letter-spacing: 1.56px;
            text-transform: uppercase;
            font-family: var(--font-stretch);
        }
        span{
            text-align: center;
            display: flex;
            padding: 8px 8px 12px 8px;
            flex-direction: column;
            align-items: flex-start;
            border-radius: 4px;
            background: #000;
            margin-right: 4px;
            color: #FFF;
            font-size: 82px;
            font-style: normal;
            font-weight: 700;
            line-height: 80%; /* 41.6px */
            letter-spacing: -1.04px;
        }
        i{
            color: #FFF;
            font-size: 52px;
            font-style: normal;
            font-weight: 700;
            line-height: 80%; /* 41.6px */
            letter-spacing: -1.04px;
            margin-right: 4px;
        }
    }

    &__man{
        position: absolute;
        left: 62px;
        bottom: 0;
        height: 100%;
    }

    &__right{
        width: 290px;
        top: 0;
        position: absolute;
        right: 230px;
    }
    &__top{
        height: 185px;
        margin-bottom: 56px;
    }

    &__header{
        position: relative;
        z-index: 1;
        &-title{
            color: #141A1E;
            font-family: var(--font-stretch);
            background: #F1F1F1;
            padding: 16px;
            font-size: 100px;
            font-weight: 900;
            line-height: 90%;
            letter-spacing: 2px;
            width: max-content;
            text-transform: uppercase;
            position: relative;
            svg{
                position: absolute;
                left: 0;
                right: 0;
                margin: 0 auto;
                z-index: -1;
                bottom: 80px;
            }
        }
        &-desc{
            color: #141A1E;
            font-family: var(--font-stretch);
            font-size: 64px;
            font-weight: 900;
            line-height: 90%;
            letter-spacing: 1.28px;
            text-transform: uppercase;
            background: #FFCD4C;
            width: max-content;
            padding: 16px;
            display: flex;
            margin: 0 auto 44px;
        }
    }
}
</style>
