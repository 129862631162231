<template>
    <div class="bank">
        <AppHeader back-button @getBackHeader="handleBackHeader">
            <template #title>{{ $t('bank.header') }}</template>
        </AppHeader>
        <div class="bank__wrapper bank__wrapper--one" v-if="type === 1">
            <div class="bank__buttons">
                <div class="bank__item" @click="getTransfer">
                    <p>{{ $t('bank.transfer.title') }}</p>
                    <span>{{ $t('bank.transfer.text') }}</span>
                </div>
                <div class="bank__item" @click="getPayment">
                    <p>{{ $t('bank.payment.title') }}</p>
                    <span>{{ $t('bank.payment.text') }}</span>
                </div>

                <div class="bank__item" @click="getTake">
                    <p>{{ $t('bank.withdraw.title') }}</p>
                    <span>{{ $t('bank.withdraw.text') }}</span>
                </div>

                <div class="bank__item" @click="getCharity">
                    <IconHealth class="svg"/>
                    {{ $t('bank.charity') }}
                </div>

                <div class="bank__item" @click="getRabber">
                    <IconRobber />
                    {{ $t('bank.rob') }}
                </div>
            </div>

            <img src="@/assets/images/bank/bank-girl.png" alt="">
        </div>

        <div class="bank__wrapper bank__wrapper--two" v-if="type === 2">
            <img src="@/assets/images/bank/safe.png" alt="" class="bank__safe">
            <div class="bank__center">
                <div class="bank__center-item">
                    <IconRobber />
                    <p>{{ $t('bank.robbery') }}</p>
                </div>
                <BaseButton white class="bank__center-btn bank__center-btn--attempt" @click="tryToRob">{{ $t('bank.robbery_attempt') }}</BaseButton>
            </div>
            <div class="bank__info">
                <div class="bank__info-item">
                    <h4>{{ $t('bank.chance.title') }}</h4>
                    <p>{{ $t('bank.chance.text') }}</p>
                </div>
                <div class="bank__info-item">
                    <h4>{{ $t('bank.police.title') }}</h4>
                    <p>{{ $t('bank.police.text') }}</p>
                </div>
            </div>
        </div>

        <div class="bank__wrapper bank__wrapper--success" v-if="type === 3">
            <div></div>
            <div class="bank__center">
                <div class="bank__center-item bank__center-item--success">
                    <IconRobber />
                    <p>{{ $t('bank.robbery_success') }}</p>
                </div>
                <BaseButton white class="bank__center-btn" @click="takeMoney">
                    {{ $t('bank.take') }}
                    <span class="dollar">
                    <span class="dollar__symbol dollar__symbol--yellow">$</span>
                    {{ $formatNum(money)}}
                </span>
                </BaseButton>
            </div>
            <div></div>
        </div>

        <div class="bank__wrapper bank__wrapper--jail" v-if="type === 4">
            <div></div>
            <div class="bank__center">
                <div class="bank__center-item bank__center-item--jail">
                    <IconRobber />
                    <p>{{ $t('bank.robbery_failure') }}</p>
                </div>
                <BaseButton white class="bank__center-btn bank__center-btn--attempt" @click="goExit">{{ $t('bank.exit') }}</BaseButton>
            </div>
            <div></div>
        </div>
    </div>
</template>

<script>
import AppHeader from '@/components/AppHeader/AppHeader.vue';
import IconHealth from '@/components/icons/IconHealth.vue';
import IconRobber from '@/components/icons/IconRobber.vue';
import BaseButton from '@/components/ui/BaseButton.vue';

export default {
    name: 'MyBank',
    components: { BaseButton, IconRobber, IconHealth, AppHeader },
    data () {
        return {
            type: 1, // 1 - главное меню, 2 - ограбление, 3 - удача, 4 - неудача
            money: 99999,
            isLoading: true
        };
    },
    methods: {
        handleBackHeader () {
            if (this.type === 1) {
                this.sendclient('closeDesigns');
            } else {
                this.type = 1;
            }
        },
        getTransfer () {
            this.sendclient('bankTransfer');
        },
        getPayment () {
            this.sendclient('bankPayment');
        },
        getTake () {
            this.sendclient('bankGetMoney');
        },
        getCharity () {
            this.sendclient('bankCharity');
        },
        getRabber () {
            this.type = 2;
        },
        tryToRob () {
            this.sendclient('bankRob');
        },
        goExit () {
            this.sendclient('closeDesigns');
        },
        takeMoney () {
            this.sendclient('closeDesigns');
        }
    }
};
</script>

<style lang="scss" scoped>
.bank{
    background: url(@/assets/images/bank/bank.jpg) no-repeat center / cover;
    height: 100%;
    &__wrapper{
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;

        &::before{
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            display: block;
            z-index: -1;
        }

        &--one{
            padding: 257px 275px;
            img{
                width: 879px;
                position: absolute;
                right: 30px;
                top: 74px;
                pointer-events: none;
            }
            &::before{
                background: linear-gradient(108deg, rgba(3.63, 17.02, 21.25, 1) 0%, rgba(0, 0, 0, .2) 100%);
            }
        }
        &--two{
            display: grid;
            grid-template-columns: 1fr 600px 1fr;
            grid-gap: 97px;
            padding: 155px 194px 0;
            position: relative;
            &::before{
                background: linear-gradient(106deg, rgba(3.63, 17.02, 21.25, 0.80) 0%, rgba(0, 0, 0, 0.80) 100%)
            }
        }

        &--success{
            display: grid;
            grid-template-columns: 1fr 600px 1fr;
            grid-gap: 97px;
            padding: 155px 194px 0;
            position: relative;
            &::after{
                background: url(@/assets/images/bank/lock.svg) no-repeat center / 1200px, radial-gradient(76.13% 36.38% at 38.06% 38.99%, rgba(21.95, 71.19, 53.53, 0.80) 0%, rgba(0.11, 1.06, 0.72, 0.80) 100%);
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                display: block;
                z-index: -1;
            }
        }

        &--jail{
            display: grid;
            grid-template-columns: 1fr 600px 1fr;
            grid-gap: 97px;
            padding: 155px 194px 0;
            position: relative;
            &::after{
                background: url(@/assets/images/bank/jail.svg) no-repeat center / 1500px,radial-gradient(76.13% 36.38% at 38.06% 38.99%, rgba(110.50, 9.21, 9.21, 0.80) 0%, rgba(0.11, 1.06, 0.72, 0.80) 100%);
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                display: block;
                z-index: -1;
            }
        }
    }

    &__safe{
        width: 739px;
    }

    &__info{
        &-item{
            margin-bottom: 95px;
            h4{
                color: #F1F1F1;
                font-size: 32px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                letter-spacing: 3.84px;
                text-transform: uppercase;
                margin-bottom: 25px;
            }
            p{
                color: rgba(241, 241, 241, 0.80);
                font-size: 40px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%;
            }
        }
    }

    &__center{
        &-btn{
            max-width: 100%;
            width: 100%;
            justify-content: space-between;
            &--attempt {
                justify-content: center;
            }

            .dollar{
                border-radius: 6px;
                background: #141A1E;
                color: #F1F1F1;
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                letter-spacing: -0.96px;
                font-family: var(--font);

                padding: 7px 12px;
                align-items: center;
            }
        }
        &-item{
            border-radius: 12px;
            border: 4px solid rgba(0, 0, 0, 0.40);
            background: radial-gradient(70.44% 87.23% at 33.43% 28.63%, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.20) 100%);
            display: flex;
            height: 600px;
            padding: 32px 128px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            align-self: stretch;
            margin-bottom: 32px;

            &--success{
                border-radius: 12px;
                border: 4px solid #29BA86;
                background: radial-gradient(70.44% 87.23% at 33.43% 28.63%, rgba(41, 186, 134, 0.70) 0%, rgba(17, 102, 72, 0.20) 100%);
            }

            &--jail{
                border-radius: 12px;
                border: 4px solid #873535;
                background: radial-gradient(70.44% 87.23% at 33.43% 28.63%, rgba(207, 52, 52, 0.70) 0%, rgba(135, 53, 53, 0.20) 100%);
            }
            p{
                color: #FFF;
                font-size: 64px;
                font-family: var(--font-stretch);
                font-style: normal;
                font-weight: 900;
                line-height: 100%;
                text-transform: uppercase;
                text-align: center;
            }
            svg{
                width: 120px;
                height: 120px;
                margin-bottom: 64px;
            }
        }
    }
    &__buttons{
        display: grid;
        grid-template-columns: repeat(3, 418px);
        grid-gap: 48px;
    }
    &__item{
        border-radius: 12px;

        .svg{
            width: 64px;
            height: 64px;
        }

        &:nth-child(1), &:nth-child(2), &:nth-child(3){
            padding: 64px;
            height: 400px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            border: 4px solid rgba(241, 241, 241, 0.10);
            background: radial-gradient(70.44% 87.23% at 33.43% 28.63%, rgba(241, 241, 241, 0.40) 0%, rgba(241, 241, 241, 0.11) 100%);

            p{
                color: #FFF;
                font-size: 64px;
                font-style: normal;
                font-weight: 900;
                line-height: 100%;
                text-transform: uppercase;
                font-family: var(--font-stretch);
            }
            span{
                color: rgba(255, 255, 255, 0.80);
                font-size: 28px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
            }
        }
        &:nth-child(4){
            grid-column: 1/3;
            border: 4px solid rgba(195, 5, 66, 0.40);
            background: radial-gradient(70.44% 87.23% at 33.43% 28.63%, rgba(195, 5, 66, 0.70) 0%, rgba(195, 5, 66, 0.20) 100%);
            display: flex;
            padding: 32px 44px;
            justify-content: center;
            align-items: center;
            gap: 24px;
            flex: 1 0 0;
            color: #FFF;
            font-size: 64px;
            font-style: normal;
            font-weight: 900;
            font-family: var(--font-stretch);

            line-height: 100%;
            text-transform: uppercase;
        }

        &:nth-child(5){
            border: 4px solid rgba(0, 0, 0, 0.40);
            background: radial-gradient(70.44% 87.23% at 33.43% 28.63%, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.20) 100%);
            display: flex;
            padding: 32px 44px;
            justify-content: center;
            align-items: center;
            gap: 24px;
            flex: 1 0 0;
            color: #FFF;
            font-size: 64px;
            font-style: normal;
            font-weight: 900;
            font-family: var(--font-stretch);

            line-height: 100%;
            text-transform: uppercase;
        }
    }
}
</style>
