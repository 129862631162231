<template>
    <div class="buyer">
        <AppHeader back-button>
            <template #title>
                {{ $t('buyer.title') }}
            </template>
        </AppHeader>
        <div class="buyer-content">
            <div class="buyer-info">
                <img class="buyer-info__image" src="@/assets/images/buyer/lines.svg" alt="" />
                <div class="buyer-info__title">
                    {{ $t('buyer.buyer_metal') }}
                </div>
                <div class="buyer-info__req">
                    <BaseHaveProperty v-for="(item, index) in requirements" :key="index" :isConditionComplete="item.available" :text="item.text" />
                </div>
                <img class="buyer-info__image" src="@/assets/images/buyer/lines.svg" alt="" />
                <div class="buyer-info__text">
                    {{ $t('buyer.info') }}
                </div>
            </div>
            <div class="buyer-data">
                <div class="buyer-data__info" :class="{'buyer-data__info--active': isBonusActive}">
                    <IconDiscount class="buyer-data__discount" />
                    <div class="buyer-data__text">
                        {{ $t('buyer.info', { bonusPercent }) }}
                    </div>
                    <div class="buyer-data__state">
                        <IconCheckmarkBold class="buyer-data__checkmark" v-if="isBonusActive" />
                        <template v-else>
                            <div class="buyer-data__left">{{ $t('buyer.data_left') }}</div>
                            <div class="buyer-data__timer">
                                <span>{{ Math.floor((totalSeconds % 3600) / 60 / 10) }}</span>
                                <span>{{ Math.floor((totalSeconds % 3600) / 60) % 10 }}</span>
                                <div>:</div>
                                <span>{{ Math.floor(totalSeconds % 60 / 10) }}</span>
                                <span>{{ totalSeconds % 60 % 10 }}</span>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="buyer-data__list">
                    <div v-for="item in store" :key="item.id" class="buyer-data__item">
                        <InventoryItem :item="{...item, name: undefined}" />
                        <div class="buyer-data__item-about">
                            <div class="buyer-data__item-title">
                                {{ item.name }}
                            </div>
                            <div class="buyer-data__item-price">
                                <div class="buyer-data__item-sum">
                                    <span>$</span> {{ $formatNum(isBonusActive ? getItemPriceWithBonus(item) : item.price) }}
                                </div>
                                <div v-if="isBonusActive" class="buyer-data__item-old">
                                    {{ $formatNum(item.price) }}
                                </div>
                                <div class="buyer-data__item-text">
                                    {{ $t('buyer.item-text') }}
                                </div>
                            </div>
                        </div>
                        <BaseButton @click="sellGoods(item)" v-if="item.ownedQty" class="buyer-data__button" white>
                            {{ $t('buyer.sell') }} {{ item.ownedQty }} {{ $t('buyer.apiece') }}
                            <div class="buyer-data__button-price">
                                <span>$</span>{{ $formatNum(getFullPrice(item)) }}
                            </div>
                        </BaseButton>
                        <div class="buyer-data__item-text" v-else>{{ $t('buyer.out_of_inventory') }}</div>
                    </div>
                </div>
            </div>
            <transition name="fade">
                <div v-if="soldItem" class="buyer-confirm">
                    <div class="buyer-data__item-text">
                        {{ $t('buyer.earnings') }}
                    </div>
                    <div class="buyer-confirm__profit">
                        + <span>$</span> {{ $formatNum(this.soldItem.fullPrice) }}
                    </div>
                    <div class="buyer-data__item-text">
                        {{ $t('buyer.earnings') }}
                    </div>
                    <InventoryItem :item="soldItem" />
                    <button class="base-popup__close-btn" @click="closeConfirmModal">
                        <IconClose fill="#f1f1f1"/>
                    </button>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import AppHeader from '@/components/AppHeader/AppHeader.vue';
import BaseHaveProperty from '@/components/ui/BaseHaveProperty.vue';
import IconDiscount from '@/components/icons/IconDiscount.vue';
import IconCheckmarkBold from '@/components/icons/IconCheckmarkBold.vue';
import { mapGetters } from 'vuex';
import InventoryItem from '@/components/popups/components/inventoryItem/InventoryItem.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import IconClose from '@/components/icons/IconClose.vue';

export default {
    name: 'BuyerSystem',
    components: {
        IconClose,
        BaseButton,
        InventoryItem,
        IconCheckmarkBold,
        IconDiscount,
        BaseHaveProperty,
        AppHeader
    },
    data () {
        return {
            timer: null,
            soldItem: null, // сюда попадает вещь, которую игрок успешно продал
            requirements: [
                { text: 'официальная работа', available: true },
                { text: 'Пальто', available: true },
                { text: '5 уровень', available: false },
                { text: 'удочка', available: false },
                { text: 'владение домом', available: false }
            ],
            store: [
                {
                    id: 1,
                    name: 'Штурмовая винтовка',
                    rarity: 'unusual',
                    img: 'other_items/m4',
                    price: 1400, // стоимость за 1шт.
                    ownedQty: 22 // количество этих штук у игрока в инвентаре
                },
                {
                    id: 2,
                    name: 'Пистолет',
                    rarity: 'rare',
                    img: 'other_items/pistol',
                    price: 1810, // стоимость за 1шт.
                    ownedQty: 3 // количество этих штук у игрока в инвентаре
                },
                {
                    id: 3,
                    name: 'Верхняя одежда',
                    rarity: 'epic',
                    img: 'other_items/spraycan',
                    price: 2210, // стоимость за 1шт.
                    ownedQty: 11 // количество этих штук у игрока в инвентаре
                },
                {
                    id: 4,
                    name: 'Верхняя одежда',
                    rarity: 'ordinary',
                    img: 'other_items/ore',
                    price: 995 // стоимость за 1шт.
                }
            ],
            isBonusActive: true,
            totalSeconds: 220, // время таймера
            bonusPercent: 25 // увеличенный процент для продажи - в процентах
        };
    },
    mounted () {
        !this.isBonusActive && this.startTimer();
    },
    beforeDestroy () {
        this.stopTimer();
    },
    computed: {
        ...mapGetters('inventory', ['inventoryItemsPool']),
        playerLevel () {
            return this.$store.state.player.playerLevel;
        }
    },
    methods: {
        startTimer () {
            if (this.timer !== null) {
                clearInterval(this.timer);
                this.timer = null;
            }
            this.timer = setInterval(() => {
                if (this.totalSeconds > 0) {
                    this.totalSeconds--;
                } else {
                    this.stopTimer();
                }
            }, 1000); // Обновлять каждую секунду
        },
        stopTimer () {
            clearInterval(this.timer);
            this.timer = null;
        },
        getItemPriceWithBonus (item) {
            return Math.floor(item.price * (1 + this.bonusPercent / 100));
        },
        getFullPrice (item) {
            return this.isBonusActive ? this.getItemPriceWithBonus(item) * item.ownedQty : item.price * item.ownedQty;
        },
        sellGoods (item) {
            console.log('Продаем: ', item, 'Цена: ', this.getFullPrice(item), 'Кол-во: ', item.ownedQty);
            // тут вызываем диалог да/нет
            // Вызываем после диалога да/нет, если чел нажал да
            this.confirmSell(item);
        },
        confirmSell (item) {
            const { id, rarity, ownedQty, img } = item;
            this.soldItem = { id, rarity, ownedQty, img, count: ownedQty, fullPrice: this.getFullPrice(item) };
        },
        closeConfirmModal () {
            this.soldItem = null;
        }
    }
};
</script>

<style lang="scss" scoped>
.buyer {
    height: 100%;
    background: radial-gradient(192.67% 134% at 10.8% 100%, rgba(11, 123, 80, 0.84) 0%, #16171B 100%);
    &-content {
        height: calc(100% - 5vw);
        display: grid;
        grid-template-columns: 620px 1fr;
        grid-gap: 0 70px;
        justify-items: center;
        padding: 0 128px 0 120px;
        position: relative;
    }
    &-info {
        display: grid;
        grid-auto-flow: row;
        grid-template-rows: repeat(5, min-content);
        grid-gap: 52px 0;
        align-content: center;
        justify-items: center;
        &__image {
            width: 109px;
            height: 152px;
            &:nth-child(2n) {
                transform: rotate(180deg);
            }
        }
        &__title {
            color: var(--white);
            text-align: center;
            font-size: 80px;
            font-weight: 300;
            line-height: 100%;
        }
        &__req {
            display: flex;
            flex-wrap: wrap;
            margin-left: -12px;
            margin-top: -12px;
            justify-content: center;
            div {
                margin-left: 12px;
                margin-top: 12px;
            }
        }
        &__text {
            opacity: .6;
            color: var(--white);
            font-family: var(--font-stretch);
            font-size: 26px;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: 1.56px;
            text-transform: uppercase;
        }
    }
    &-data {
        padding: 110px 0;
        width: 100%;
        overflow-y: auto;
        &::-webkit-scrollbar {
            display: none;
        }
        &__info {
            background: radial-gradient(611.26% 198.19% at 30.3% -49%, #FF763C 0%, rgba(255, 118, 60, 0.26) 100%);
            height: 126px;
            padding-left: 40px;
            display: flex;
            align-items: center;
            &--active {
                background: radial-gradient(611.26% 198.19% at 30.3% -49%, #27AE60 0%, rgba(39, 174, 96, 0.40) 100%);
                .buyer-data__state {
                    background: #27AE60;
                    &::before {
                        border-color: transparent #27AE60 transparent transparent;
                    }
                }
            }
        }
        &__discount, &__checkmark {
            width: 62px;
            height: 62px;
        }
        &__discount {
            margin-right: 24px;
        }
        &__timer {
            display: flex;
            color: var(--white);
            font-size: 52px;
            font-weight: 700;
            line-height: 80%;
            letter-spacing: -1.04px;
            span {
                margin-left: 4px;
                padding: 8px 8px 12px;
                border-radius: 4px;
                background-color: #000;
                &:first-child {
                    margin-left: 0;
                }
            }
            div {
                margin-left: 4px;
                padding-bottom: 10px;
                color: #000;
                align-self: flex-end;
            }
        }
        &__text {
            color: var(--white);
            font-family: var(--font-stretch);
            font-size: 58px;
            font-style: normal;
            font-weight: 900;
            line-height: 100%;
            letter-spacing: 1.16px;
            text-transform: uppercase;
            flex-grow: 1;
        }
        &__left {
            max-width: 105px;
            font-family: var(--font-stretch);
            text-align: right;
            font-size: 26px;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: 1.56px;
            text-transform: uppercase;
            margin-right: 14px;
        }
        &__state {
            display: flex;
            align-items: center;
            height: 100%;
            padding: 32px;
            background: #FF7638;
            position: relative;
            &::before {
                content: "";
                position: absolute;
                left: -32px;
                top: 0;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 32px 126px 0;
                border-color: transparent #FF7638 transparent transparent;
            }
        }
        &__item {
            background: radial-gradient(384.85% 250.95% at 32.81% -16.43%, rgba(20, 26, 30, 0.50) 0%, rgba(20, 26, 30, 0.00) 100%);
            margin-top: 18px;
            padding-right: 64px;
            display: flex;
            align-items: center;
            &-about {
                margin: 0 64px;
                flex-grow: 1;
            }
            &-title {
                color: var(--white);
                font-family: var(--font-stretch);
                font-size: 52px;
                font-weight: 900;
                line-height: 100%;
                letter-spacing: 1.04px;
                text-transform: uppercase;
                margin-bottom: 24px;
            }
            &-price {
                display: flex;
                align-items: center;
            }
            &-sum {
                color: #FFF;
                font-size: 52px;
                font-weight: 700;
                line-height: 100%;
                letter-spacing: -1.04px;
                display: flex;
                align-items: center;
                span {
                    margin-right: 4px;
                    color: var(--gold);
                }
            }
            &-text {
                margin-left: 24px;
                opacity: .6;
                color: var(--white);
                font-family: var(--font-stretch);
                font-size: 26px;
                font-weight: 700;
                line-height: 120%;
                letter-spacing: 1.56px;
                text-transform: uppercase;
            }
            &-old {
                margin-left: 24px;
                color: rgba(255, 255, 255, .6);
                font-size: 52px;
                font-weight: 400;
                line-height: 100%;
                letter-spacing: -1.04px;
                text-decoration-line: line-through;
            }
        }
        &__button {
            &-price {
                margin-left: 18px;
                padding: 7px 12px;
                border-radius: 6px;
                background: var(--dark);
                color: var(--white);
                font-size: 40px;
                font-weight: 700;
                line-height: 100%;
                letter-spacing: -0.8px;
                span {
                    color: var(--gold);
                    margin-right: 4px;
                }
            }
        }
    }
    &-confirm {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 3;
        left: 120px;
        right: 120px;
        bottom: 50px;
        top: 50px;
        border-radius: 44px;
        background: radial-gradient(46.15% 100% at 50.06% 100%, #26343D 0%, rgba(13, 18, 21, 0.94) 100%, rgba(13, 18, 21, 0.98) 100%);
        &__profit {
            color: #FFF;
            font-size: 80px;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: -1.6px;
            display: flex;
            align-items: center;
            margin: 15px 0 55px;
            span {
                color: var(--gold);
                margin: 0 4px;
            }
        }
        .buyer-data__item-text {
            margin-left: 0;
            margin-bottom: 12px;
        }
    }
}
</style>
