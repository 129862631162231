<template>
    <div class="menu">
        <AppHeader nickName />
        <div class="menu__content">
            <div class="menu__main">
                <div class="menu__main-top">
                    <MainMenuBanner
                        v-for="banner in menus.banners"
                        :key="banner.key"
                        :type="banner.key"
                        :season="banner.season"
                        :isNew="banner.isNew"
                        @click.native="selectMenu(banner.key)"
                    />
                    <div class="menu__main-small">
                        <div
                            v-for="item in menus.small"
                            :key="item.key"
                            class="menu__main-small-item menu__item"
                            @click="selectMenu(item.key)"
                        >
                            <MainMenuIcons class="menu__main-small-item-icon" :type="item.key" />
                            <IconNew class="menu__main-small-item-is-new" v-if="item.isNew" />
                        </div>
                    </div>
                </div>
                <div class="menu__main-bottom">
                    <div
                        v-for="item in menus.bottom"
                        :key="item.key"
                        class="menu__main-bottom-item menu__item"
                        @click="selectMenu(item.key)"
                    >
                        <div class="menu__main-bottom-item-title"> {{ $t(item.name) }}</div>
                        <MainMenuIcons class="menu__main-bottom-item-icon" :type="item.key" />
                        <IconNew class="menu__main-bottom-item-is-new" v-if="item.isNew" />
                    </div>
                </div>
            </div>
            <div class="menu__big">
                <swiper ref="swiperMenu" :options="swiperOptions">
                    <swiper-slide v-for="(slide, index) in slider" :key="index">
                        <div class="menu__big-item">
                            <img :src="imageSrc(slide)" class="menu__big-item-image" alt="">
                            <div class="menu__big-item-info">
                                <div class="menu__big-item-title">{{ slide.title }}</div>
                                <div class="menu__big-item-name">{{ slide.name }}</div>
                            </div>
                        </div>
                    </swiper-slide>
                    <div class="swiper-pagination" slot="pagination"></div>
                </swiper>
            </div>
        </div>
    </div>
</template>

<script>
import awesomeSwiperBuilder from 'vue-awesome-swiper/dist/exporter';
import SwiperConstructor from 'swiper';
import AppHeader from '@/components/AppHeader/AppHeader.vue';
import IconNew from '@/components/icons/IconNew.vue';
import { mapState } from 'vuex';
import MainMenuBanner from '@/components/popups/content/menu/components/MainMenuBanner.vue';
import MainMenuIcons from '@/components/popups/content/menu/icons/MainMenuIcons.vue';
const { Swiper, SwiperSlide } = awesomeSwiperBuilder(SwiperConstructor);

export default {
    name: 'MainMenu',
    components: {
        MainMenuIcons,
        MainMenuBanner,
        IconNew,
        AppHeader,
        Swiper,
        SwiperSlide
    },
    /*
    window.modifyVueComponent({\
        App: {\
            showPopup: true,\
            popupContent: 'MainMenu',\
            contentSubFolder: 'menu'\
        },\
    });
    */
    data () {
        return {
            slider: [
                {
                    image: '1.png',
                    title: 'новый сет одежды',
                    name: 'Yellow destroyer'
                },
                {
                    image: '2.png',
                    title: 'редкий транспорт',
                    name: 'survival track 200'
                },
                {
                    image: '2.png',
                    title: 'редкий транспорт',
                    name: 'survival track 200'
                },
                {
                    image: '2.png',
                    title: 'редкий транспорт',
                    name: 'survival track 200'
                },
                {
                    image: '2.png',
                    title: 'редкий транспорт',
                    name: 'survival track 200'
                },
                {
                    image: '2.png',
                    title: 'редкий транспорт',
                    name: 'survival track 200'
                }
            ],
            menus: {
                banners: [
                    { key: 'battle-pass', isNew: 1, season: 13 },
                    { key: 'season-event', isNew: 1 }
                ],
                small: [
                    { key: 'settings' },
                    { key: 'servers' },
                    { key: 'profile' }
                ],
                bottom: [
                    { key: 'inv', name: 'mainMenu.backpack' },
                    { key: 'map', name: 'mainMenu.map' },
                    { key: 'shop', name: 'mainMenu.shop' },
                    { key: 'showroom', name: 'mainMenu.showroom' }
                ]
            },
            swiperOptions: {
                slidesPerView: '1',
                spaceBetween: 0,
                loop: true,
                autoplay: {
                    delay: 2000,
                    enabled: true,
                    disableOnInteraction: false
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                }
            },
            profileMenu: {
                isNew: false
            }
        };
    },
    computed: {
        swiper () {
            return this.$refs.swiperMenu.$swiper;
        },
        imageSrc () {
            return (slide) => {
                return require(`@/assets/images/menu/${slide.image}`);
            };
        },
        ...mapState('player', [
            'playerName',
            'playerLevel',
            'vip'
        ])
    },
    methods: {
        // handleClick (key) {
        //     if (key === 'inv') this.sendclient('openINV');
        //     else if (key === 'settings') this.sendclient('openSettings');
        //     else if (key === 'showroom') this.sendclient('loadShowroom');
        //     // console.log(`Клик по ${item.name}`);
        // },
        // gotoProfile () {
        //     this.sendclient('MyProfile');
        // },
        selectMenu (key) {
            if (key === 'settings') {
                this.sendclient('openSettings');
            }
            if (key === 'profile') {
                this.sendclient('MyProfile');
            }
            if (key === 'servers') {
                console.log('открываем сервера с вызовом диалога');
            }
            if (key === 'battle-pass') {
                this.sendclient('BattlePass');
            }
            if (key === 'season-event') {
                this.sendclient('openSeasonEvent');
            }
            if (key === 'inv') {
                this.sendclient('openINV');
            }
            if (key === 'map') {
                console.log('открываем карту');
            }
            if (key === 'shop') {
                this.sendclient('openShop');
            }
            if (key === 'showroom') {
                this.sendclient('loadShowroom');
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.menu {
    height: 100%;
    background: url(@/assets/images/menu/bg.png) no-repeat center/cover;
    &__item {
        border-radius: 12px;
        background: rgba(241, 241, 241, 0.12);
        position: relative;
        &:active {
            background: rgba(241, 241, 241, 0.16);
        }
    }
    &__content {
        padding: 60px 128px 0;
        height: calc(100% - 5vw);
        display: grid;
        grid-template-columns: 1fr 715px;
        grid-gap: 0 24px;
        &::v-deep .swiper-container {
            height: 100%;
        }
    }
    &__big {
        position: relative;
        z-index: 1;
        padding-bottom: 90px;
        &-item {
            border-radius: 12px;
            height: 100%;
            background: linear-gradient(180deg, rgba(32, 36, 39, 0.007) 43.75%, rgba(32, 36, 39, 0.7) 100%);
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-direction: column;
            padding-left: 1.8vw;
            padding-right: 1.8vw;
            &-info {
                padding-bottom: 5.5vw;
                text-align: center;
                color: var(--white);
            }
            &-title {
                margin-bottom: .4vw;
                text-transform: uppercase;
                font-family: var(--font-stretch);
                font-weight: 900;
                font-size: 1.8vw;
            }
            &-name {
                font-size: 3.95vw;
                text-transform: uppercase;
                font-family: var(--font-stretch);
                font-weight: 900;
            }
            &-image {
                position: absolute;
                border-radius: 12px;
                max-width: 100%;
                width: 100%;
                height: 100%;
                object-fit: cover;
                left: 0;
                right: 0;
                z-index: -1;
            }
        }
        &::v-deep .swiper-pagination {
            position: absolute;
            max-width: 100%;
            z-index: 3;
            bottom: 24px;
            left: 24px;
            right: 24px;
            display: flex;
            align-items: center;
            &-bullet {
                border-radius: 50%;
                background-color: var(--white);
                display: block;
                width: 12px;
                min-width: 12px;
                height: 12px;
                margin-left: 16px;
                &:first-child {
                    margin-left: 0;
                }
                &-active {
                    transition: width .3s linear;
                    border-radius: 300px;
                    background-color: var(--white);
                    width: 100%;
                }
            }
        }
    }
    &__main {
        display: grid;
        grid-template-rows: minmax(550px, 100%) minmax(455px, 100%);
        grid-gap: 25px 0;
        grid-auto-flow: row;
        z-index: 1;
        &-top {
            display: grid;
            grid-template-columns: 1fr 1fr 128px;
            grid-gap: 0 24px;
        }
        &-small {
            display: grid;
            grid-template-rows: repeat(3, 1fr);
            grid-auto-flow: row;
            grid-gap: 24px 0;
            &-item {
                padding: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                &::v-deep &-icon {
                    width: 64px;
                    height: 64px;
                    svg {
                        width: 64px;
                        height: 64px;
                    }
                }
                &::v-deep &-is-new {
                    width: 94px;
                    height: 48px;
                    position: absolute;
                    left: -8px;
                    top: -9.5px;
                }
            }
        }
        &-bottom {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 0 24px;
            &-item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background: linear-gradient(180deg, rgba(241, 241, 241, 0.14) 0%, rgba(241, 241, 241, 0.00) 100%);
                &:active {
                    background: linear-gradient(180deg, rgba(241, 241, 241, 0.18) 0%, rgba(241, 241, 241, 0.00) 100%);
                }
                &::v-deep &-is-new {
                    width: 94px;
                    height: 48px;
                    position: absolute;
                    left: -10px;
                    top: -10px;
                }
                &-title {
                    text-align: center;
                    color: var(--white);
                    font-family: var(--font-stretch);
                    font-size: 52px;
                    font-weight: 900;
                    line-height: 100%;
                    margin-bottom: 44px;
                    letter-spacing: 4.16px;
                    text-transform: uppercase;
                }
                &::v-deep &-icon {
                    width: 96px;
                    height: 96px;
                    svg {
                        width: 96px;
                        height: 96px;
                    }
                }
            }
        }
    }
}
</style>
<style lang="scss">
@import "swiper/swiper.scss";
</style>
