<template>
    <div class="report">
        <div @click="openMenu" class="report-nav">
            <div class="report-nav__text">
                {{ $t('reports.title') }}
            </div>
            <div class="report-nav__qty">
                {{ reports.length }}
            </div>
            <IconReportsArrow />
        </div>
        <transition name="slide">
            <div v-show="isReportsMenuActive" class="report-list">
                <div
                    v-for="(report, index) in reports"
                    :key="index"
                    class="report-item"
                    :class="{'report-item--active' : report.isActive}"
                >
                        <div class="report-item__top" @click="highlightReport(report)">
                            <div class="report-item__header">
                                <div class="report-item__name">
                                    {{ report.playerName }}
                                </div>
                                <div class="report-item__id">
                                    <span>ID</span>
                                    {{ report.playerId }}
                                </div>
                            </div>
                            <div class="report-item__text">
                                {{ report.text }}
                            </div>
                        </div>
                    <transition name="slide-up">
                        <div v-if="highlightedReport === report" class="report-item__menu">
                            <BaseButton class="report-item__button" glass @click="getInfo(report.playerId)">
                                <IconInfoTransparent />
                            </BaseButton>
                            <BaseButton class="report-item__button" white @click="teleport(report.playerId)">
                                <IconPlayerTeleport />
                            </BaseButton>
                            <BaseButton class="report-item__button" glass @click="openChat(report)">
                                <IconChat />
                            </BaseButton>
                        </div>
                    </transition>
                    <div v-if="index !== reports.length - 1" class="report-item__divider"></div>
                </div>
            </div>
        </transition>
        <transition name="fade">
            <BasePopupChat
                v-if="isChatOpen"
                :closeChat="closeChat"
                placeholder="Напишите ответ"
                @send-answer="sendAnswer"
            >
                <template #info>
                    <div class="report-info">
                        <div class="report-item__header">
                            <div class="report-item__name">
                                {{ activeReport.playerName }}
                            </div>
                            <div class="report-item__id">
                                <span>{{ $t('reports.id') }}</span>
                                {{ activeReport.playerId }}
                            </div>
                        </div>
                        <div class="report-item__text">
                            {{ activeReport.text }}
                        </div>
                    </div>
                </template>
            </BasePopupChat>
        </transition>
    </div>
</template>

<script>

import IconReportsArrow from '@/components/hud/components/reports/icons/IconReportsArrow.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import IconInfoTransparent from '@/components/icons/IconInfoTransparent.vue';
import IconChat from '@/components/icons/IconChat.vue';
import IconPlayerTeleport from '@/components/icons/IconPlayerTeleport.vue';
import BasePopupChat from '@/components/ui/BasePopupChat.vue';

export default {
    name: 'VReports',
    components: {
        BasePopupChat,
        IconPlayerTeleport,
        IconChat,
        IconInfoTransparent,
        BaseButton,
        IconReportsArrow
    },
    data () {
        return {
            isReportsMenuActive: false,
            isChatOpen: false,
            reports: [
                /*
                {
                    playerName: 'Kevin Mackalister',
                    playerId: 2042,
                    text: 'Started captureing the resture Territoiry dlfd very well chicker small',
                    isActive: false
                },
                {
                    playerName: 'Kevin Mackalister',
                    playerId: 222,
                    text: 'Stsng the resture',
                    isActive: false
                },
                {
                    playerName: 'Kevin Mackalister',
                    playerId: 1337,
                    text: 'Started captureing the resture',
                    isActive: false
                }
                 */
            ],
            activeReport: null,
            highlightedReport: null,
            answerText: ''
        };
    },
    methods: {
        highlightReport (item) {
            this.highlightedReport = item;
        },
        addReport (item) {
            this.reports.unshift(item);
        },
        deleteReports (id) {
            this.reports = this.reports.filter(item => item.playerId !== id);
        },
        openMenu () {
            this.isReportsMenuActive = !this.isReportsMenuActive;
            this.highlightedReport = null;
        },
        teleport (id) {
            this.sendclient('tpToPlayer', JSON.stringify({ a: id }));
        },
        getInfo (id) {
            this.sendclient('getAdminPlayer', JSON.stringify({ a: id }));
        },
        openChat (report) {
            this.isChatOpen = true;
            this.activeReport = report;
        },
        closeChat () {
            this.isChatOpen = false;
        },
        sendAnswer (text) {
            this.sendclient('sendReportAnswer', JSON.stringify({ a: this.activeReport.playerId, b: text }));
        }
    }
};
</script>

<style lang="scss" scoped>
.report {
    position: fixed;
    left: 354px;
    top: 0;
    flex-direction: column;
    z-index: 3;
    &-nav {
        padding: 16px 16px 16px 32px;
        align-items: center;
        display: flex;
        align-self: flex-end;
        border-radius: 0 0 12px 32px;
        background-color: var(--dark);
        width: fit-content;
        position: relative;
        z-index: 4;
        svg {
            width: 36px;
            height: 36px;
        }
        &__qty {
            margin-left: 12px;
            margin-right: 4px;
            border-radius: 4px;
            background: var(--red);
            padding: 4px;
            color: var(--white);
            font-size: 28px;
            font-weight: 700;
            line-height: 100%;
            text-transform: uppercase;
        }
        &__text {
            color: var(--white);
            font-weight: 900;
            line-height: 120%;
            font-size: 30px;
            font-family: var(--font-stretch);
            text-transform: uppercase;
        }
    }
    &-list {
        width: 472px;
        border-radius: 12px;
        margin-top: 6px;
        max-height: 708px;
        overflow-y: scroll;
        overflow-x: hidden;
        background-color: var(--dark);
        &::-webkit-scrollbar {
            display: none;
        }
    }
    &-item {
        padding: 24px 0 0 0;
        overflow: hidden;
        position: relative;
        &__top {
            padding: 0 32px;
            margin-bottom: 24px;
        }
        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 12px;
        }
        &__name {
            opacity: .6;
            color: var(--white);
            font-size: 26px;
            font-weight: 400;
            line-height: 120%;
        }
        &__id {
            color: var(--white);
            font-size: 26px;
            font-weight: 500;
            text-transform: uppercase;
            span {
                display: inline-block;
                opacity: .4;
                margin-right: 4px;
            }
        }
        &__text {
            color: var(--white);
            font-size: 28px;
            font-weight: 500;
            line-height: 120%;
        }
        &__divider {
            background: rgba(241, 241, 241, 0.12);
            height: 4px;
            margin: 0 -32px;
            z-index: 3;
            position: relative;
        }
        &__menu {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            height: 104px;
        }
        &__button {
            height: 80px;
            width: 116px;
            margin-right: 12px;
            pointer-events: all;
            svg {
                width: 40px;
                height: 40px;
                visibility: visible;
            }
            padding: 0;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    &-info {
        width: 100%;
        margin-top: 24px;
        padding: 24px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .report-item__header {
            margin-bottom: 0;
        }
        .report-item__text {
            padding-bottom: 0;
            font-size: 40px;
        }
        .report-item__name {
            margin-right: 18px;
            font-size: 40px;
        }
        .report-item__id {
            font-size: 32px;
        }
    }
}

.slide-enter-active,
.slide-leave-active {
    transition: all 0.3s ease;
}

.slide-enter,
.slide-leave-to {
    transform: translateY(-100%);
    opacity: 0;
}

//.slide-leave-active {
//    position: absolute;
//}

.slide-up-enter-active {
    animation: slide-up .3s;
}

.slide-up-leave-active {
    animation: slide-up .3s reverse;
}

@keyframes slide-up {
    from {
        height: 0;
        opacity: 0;
    }
    to {
        height: 104px;
        opacity: 1;
        //transform: translateY(0);
    }
}
</style>
