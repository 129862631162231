<template>
    <div class="hud__bottom">
        <div class="joystick" ref="joystick" v-show="!playerGetInCar">
            <div class="joystick__run-circle" ref="runWrapper">
                <div class="joystick__run-marker" ref="touchMarker"></div>
            </div>
        </div>

        <div class="car-movement" v-show="playerGetInCar && !isPassenger">
            <div class="car-movement__turn" ref="carLeft">
                <IconInteraction icon="carMovement" />
            </div>
            <div class="car-movement__divider"></div>
            <div class="car-movement__turn" ref="carRight">
                <IconInteraction icon="carMovement" />
            </div>
        </div>

        <div class="speedometer" v-show="playerGetInCar && !isPassenger">
            <div class="speedometer__km">
                km / h
            </div>
            <div class="speedometer__speed">
                {{ speed }}
            </div>
            <div class="speedometer__info">
                <IconGasTank :fuelPercent="fuel" variant="hud" />
            </div>
        </div>

        <div class="camera" ref="camera"></div>

        <div class="hud__interaction">

            <div class="interaction">
                <div v-show="!playerGetInCar" ref="sprint" class="interaction__top-item interaction__item sprint">
                    <IconInteraction icon='sprint' />
                </div>
                <div v-show="playerGetInCar && !isPassenger" ref="handBrake" class="interaction__top-item interaction__item brake">
                    <IconInteraction icon='handBrake' />
                </div>
                <div
                    class="interaction__top-item interaction__item aimback"
                    ref="aimback"
                    v-show="weaponInHand"
                >
                    <IconInteraction icon='aimState' />
                </div>
                <div
                    class="interaction__left-item interaction__item reload"
                    ref="reload"
                    v-show="weaponInHand"
                >
                    <IconInteraction icon='reload' />
                    <div class="reload__bullets">
                        <IconInteraction icon="reloadBullets" class="reload__bullets-icon" />
                        {{ allBullets }}
                    </div>
                </div>

                <div class="interaction__right-item interaction__item micro"
                     ref="micro"
                     :class="{'micro--car': playerGetInCar}"
                >
                    <IconInteraction icon='micro' />
                </div>
                <div class="interaction__right-item interaction__item jump" ref="jump" v-show="!playerGetInCar">
                    <IconInteraction icon='jump' />
                </div>

                <div
                    class="interaction__bottom-item interaction__item getcar"
                    ref="getcar"
                    :class="{'getcar--car': playerGetInCar}"
                >
                    <IconInteraction :icon="playerGetInCar ? 'getcar' : 'outcar'" />
                </div>

                <div class="interaction__center-item interaction__item fire" ref="fire" v-show="!playerGetInCar">
                    <IconInteraction :icon="weaponInHand ? 'aim' : 'fist'" />
                </div>

                <div class="interaction__cent-item pedals" v-show="playerGetInCar && !isPassenger">
                    <div class="break" ref="break" >
                        <IconInteraction icon="break" />
                    </div>
                    <div class="gas" ref="gas">
                        <IconInteraction icon="gas" />
                    </div>
                </div>

            </div>
        </div>

        <div class="crosshair" v-show="aimState">
            <IconCrosshair
                :isReloading="isReloading"
                :reloadingTime="reloadingTime"
                class="crosshair__icon"
                :magazineCapacity="maxBullets"
                :bullets="bullets"
                :isHit="isHit"
            />
            <div class="crosshair__bullets">{{ bullets }}</div>
        </div>
    </div>
</template>

<script>
import Hammer from 'hammerjs';
import IconInteraction from '@/components/hud/components/control/icons/IconInteraction.vue';
import IconGasTank from '@/components/icons/IconGasTank.vue';
import IconCrosshair from '@/components/hud/components/control/icons/IconCrosshair.vue';

export default {
    name: 'GameController',
    components: { IconCrosshair, IconGasTank, IconInteraction },
    mounted () {
        this.initJoystick();
        this.controllerJump();
        this.controllerMicro();
        this.controllerFire();
        this.controllerReload();
        this.controllerAimBack();
        this.controllerGetCar();
        this.initCameraJoystick();
        this.controllerGasPedal();
        this.controllerBreakPedal();
        this.controllerCarLeft();
        this.controllerCarRight();
        this.controllerSprint();
        this.controllerHandBrake();
    },
    data () {
        return {
            xAxis: 0,
            yAxis: 0,
            CameraDeltaY: 0,
            CameraDeltaX: 0,
            aimState: false,
            playerGetInCar: false,
            isPassenger: false,
            weaponInHand: false,
            inCar: false,
            fuel: 50,
            speed: 0,
            maxBullets: 7,
            allBullets: 298,
            bullets: 7,
            isHit: false,
            hitTimeout: null,
            isReloading: false,
            reloadingTime: 5000 // в мс
        };
    },
    computed: {

    },
    methods: {
        initJoystick () {
            const runWrapper = this.$refs.runWrapper;
            const touchMarker = this.$refs.touchMarker;

            const hammer = new Hammer(runWrapper);
            hammer.get('pan').set({ direction: Hammer.DIRECTION_ALL });

            const sendThrottledMovement = this.throttle((xAxis, yAxis) => {
                this.sendclient('moveChar', JSON.stringify({ valueForward: yAxis, valueRight: xAxis }));
            }, 100);

            hammer.on('panstart panmove', (e) => {
                runWrapper.classList.add('active');

                const rect = runWrapper.getBoundingClientRect();
                const markerRect = touchMarker.getBoundingClientRect();

                let x = e.center.x - rect.left - rect.width / 2;
                let y = rect.height / 2 - (e.center.y - rect.top);

                const distance = Math.sqrt(x * x + y * y);
                const maxDistance = (rect.width - markerRect.width) / 2 + 20;

                if (distance > maxDistance) {
                    x = (x / distance) * maxDistance;
                    y = (y / distance) * maxDistance;
                }

                touchMarker.style.transform = `translate(${x - markerRect.width / 2}px, ${-y - markerRect.height / 2}px)`;

                const xAxis = (x / maxDistance).toFixed(3);
                const yAxis = (y / maxDistance).toFixed(3);

                sendThrottledMovement(xAxis, yAxis);
            });
            hammer.on('panend', () => {
                touchMarker.style.transform = 'translate(-50%, -50%)';

                runWrapper.classList.remove('active');

                this.xAxis = 0;
                this.yAxis = 0;

                console.log('x:', this.xAxis, 'y:', this.yAxis);

                sendThrottledMovement(this.xAxis, this.yAxis);
            });
        },

        initCameraJoystick () {
            const camera = this.$refs.camera;
            const hammer = new Hammer(camera);
            hammer.get('pan').set({ direction: Hammer.DIRECTION_ALL });

            const verticalSpeedCoefficient = 2; // коэффициент скорости
            let isPanning = false;

            const handlePan = this.throttle((e) => {
                if (!isPanning) return;

                const rect = camera.getBoundingClientRect();

                const CameraDeltaX = parseFloat(
                    (
                        Math.max(-1, Math.min(e.deltaX / (rect.width / 2), 1))
                    ).toFixed(3)
                );

                const CameraDeltaY = parseFloat(
                    (
                        Math.max(-1, Math.min(e.deltaY / (rect.height / 2 * verticalSpeedCoefficient), 1))
                    ).toFixed(3)
                );

                console.log('Camera deltaX:', CameraDeltaX, 'deltaY:', CameraDeltaY);
                this.sendclient('cameraRight', JSON.stringify({ value: CameraDeltaX }));
                this.sendclient('cameraForward', JSON.stringify({ value: CameraDeltaY }));
            }, 200);

            hammer.on('panstart', (e) => {
                isPanning = true;
                handlePan(e);
            });

            hammer.on('panmove', handlePan);

            hammer.on('panend', () => {
                isPanning = false;

                this.CameraDeltaX = 0;
                this.CameraDeltaY = 0;

                console.log('Camera deltaX:', this.CameraDeltaX, 'deltaY:', this.CameraDeltaY);
                this.sendclient('cameraRight', JSON.stringify({ value: this.CameraDeltaX }));
                this.sendclient('cameraForward', JSON.stringify({ value: this.CameraDeltaY }));
            });
        },

        controllerFire () {
            const fireElement = this.$refs.fire;

            fireElement.addEventListener('touchstart', (e) => {
                e.preventDefault();
                fireElement.classList.add('active');
                this.sendclient('PlayerWeaponFire');
            }, false);

            fireElement.addEventListener('touchend', (e) => {
                e.preventDefault();
                fireElement.classList.remove('active');
                this.sendclient('PlayerWeaponUnFire');
            }, false);
        },

        controllerGasPedal () {
            const gasElement = this.$refs.gas;

            gasElement.addEventListener('touchstart', (e) => {
                e.preventDefault();
                gasElement.classList.add('active');
                this.sendclient('vehicleGas', JSON.stringify({ a: 1 }));
            }, false);

            gasElement.addEventListener('touchend', (e) => {
                e.preventDefault();
                gasElement.classList.remove('active');
                this.sendclient('vehicleGas', JSON.stringify({ a: 0 }));
            }, false);
        },

        controllerBreakPedal () {
            const breakElement = this.$refs.break;

            breakElement.addEventListener('touchstart', (e) => {
                e.preventDefault();
                breakElement.classList.add('active');
                this.sendclient('vehicleBreak', JSON.stringify({ a: 1 }));
            }, false);

            breakElement.addEventListener('touchend', (e) => {
                e.preventDefault();
                breakElement.classList.remove('active');
                this.sendclient('vehicleBreak', JSON.stringify({ a: 0 }));
            }, false);
        },

        controllerCarLeft () {
            const carLeftElement = this.$refs.carLeft;

            carLeftElement.addEventListener('touchstart', (e) => {
                e.preventDefault();
                carLeftElement.classList.add('active');
                this.sendclient('moveVehicleLeft', JSON.stringify({ a: -1 }));
            }, false);

            carLeftElement.addEventListener('touchend', (e) => {
                e.preventDefault();
                carLeftElement.classList.remove('active');
                this.sendclient('moveVehicleLeft', JSON.stringify({ a: 0 }));
            }, false);
        },

        controllerCarRight () {
            const carRightElement = this.$refs.carRight;

            carRightElement.addEventListener('touchstart', (e) => {
                e.preventDefault();
                carRightElement.classList.add('active');
                this.sendclient('moveVehicleLeft', JSON.stringify({ a: 1 }));
            }, false);

            carRightElement.addEventListener('touchend', (e) => {
                e.preventDefault();
                carRightElement.classList.remove('active');
                this.sendclient('moveVehicleLeft', JSON.stringify({ a: 0 }));
            }, false);
        },

        controllerJump () {
            const jumpElement = this.$refs.jump;

            jumpElement.addEventListener('touchstart', (e) => {
                e.preventDefault();
                jumpElement.classList.add('active');
                console.log('jump');
                this.sendclient('PlayerJump');
            }, false);

            jumpElement.addEventListener('touchend', (e) => {
                e.preventDefault();
                jumpElement.classList.remove('active');
            }, false);
        },

        controllerAimBack () {
            const aimbackElement = this.$refs.aimback;

            aimbackElement.addEventListener('touchstart', (e) => {
                e.preventDefault();
                aimbackElement.classList.add('active');
                this.aimState = !this.aimState;
                console.log('aimback');
                this.sendclient('PlayerSwitchAimState', JSON.stringify({ value: this.aimState }));
            }, false);

            aimbackElement.addEventListener('touchend', (e) => {
                e.preventDefault();
                aimbackElement.classList.remove('active');
            }, false);
        },

        controllerGetCar () {
            const getCarElement = this.$refs.getcar;

            getCarElement.addEventListener('touchstart', (e) => {
                e.preventDefault();
                getCarElement.classList.add('active');
                console.log('getcar');
                this.sendclient('PlayerGetInCar', JSON.stringify({ value: !this.playerGetInCar }));
            }, false);

            getCarElement.addEventListener('touchend', (e) => {
                e.preventDefault();
                getCarElement.classList.remove('active');
            }, false);
        },

        controllerMicro () {
            const microElement = this.$refs.micro;

            microElement.addEventListener('touchstart', (e) => {
                e.preventDefault();
                microElement.classList.add('active');
                this.sendclient('MicrophoneToggle', JSON.stringify({ a: true }));
            }, false);

            microElement.addEventListener('touchend', (e) => {
                e.preventDefault();
                microElement.classList.remove('active');
                this.sendclient('MicrophoneToggle', JSON.stringify({ a: false }));
            }, false);
        },

        controllerSprint () {
            const sprintElement = this.$refs.sprint;

            sprintElement.addEventListener('touchstart', (e) => {
                e.preventDefault();
                sprintElement.classList.add('active');
                this.sendclient('Sprint', JSON.stringify({ a: true }));
            }, false);

            sprintElement.addEventListener('touchend', (e) => {
                e.preventDefault();
                sprintElement.classList.remove('active');
                this.sendclient('Sprint', JSON.stringify({ a: false }));
            }, false);
        },

        controllerHandBrake () {
            const handBrakeElement = this.$refs.handBrake;

            handBrakeElement.addEventListener('touchstart', (e) => {
                e.preventDefault();
                handBrakeElement.classList.add('active');
                this.sendclient('Handbrake', JSON.stringify({ a: true }));
            }, false);

            handBrakeElement.addEventListener('touchend', (e) => {
                e.preventDefault();
                handBrakeElement.classList.remove('active');
                this.sendclient('Handbrake', JSON.stringify({ a: false }));
            }, false);
        },

        controllerReload () {
            const reloadElement = this.$refs.reload;

            reloadElement.addEventListener('touchstart', (e) => {
                e.preventDefault();
                reloadElement.classList.add('active');
                console.log('reload');
                this.sendclient('PlayerReloadWeapon');

                // Функция для перезарядки, если с бэка будешь все передавать, то удали
                // this.isReloading = true;
                // setTimeout(() => { this.isReloading = false; }, this.reloadingTime);
            }, false);

            reloadElement.addEventListener('touchend', (e) => {
                e.preventDefault();
                reloadElement.classList.remove('active');
                console.log('reload off');
            }, false);
        },

        // controllerGasPedal () {
        //     this.createHammerInstance('gas',
        //         () => {
        //             // console.log('Нажали на газ');
        //             this.sendclient('vehicleGas', JSON.stringify({ a: 1 }));
        //         },
        //         () => {
        //             // console.log('Отпустили газ');
        //             this.sendclient('vehicleGas', JSON.stringify({ a: 0 }));
        //         }
        //     );
        // },
        //
        // controllerBreakPedal () {
        //     this.createHammerInstance('break',
        //         () => {
        //             // console.log('Нажали на тормоз');
        //             this.sendclient('vehicleBreak', JSON.stringify({ a: 1 }));
        //         },
        //         () => {
        //             // console.log('Отпустили тормоз');
        //             this.sendclient('vehicleBreak', JSON.stringify({ a: 0 }));
        //         }
        //     );
        // },
        //
        // controllerCarLeft () {
        //     this.createHammerInstance('carLeft',
        //         () => {
        //             // console.log('Летим налево');
        //             this.sendclient('moveVehicleLeft', JSON.stringify({ a: -1 }));
        //         },
        //         () => {
        //             // console.log('Не летим налево');
        //             this.sendclient('moveVehicleLeft', JSON.stringify({ a: 0 }));
        //         }
        //     );
        // },
        //
        // controllerCarRight () {
        //     this.createHammerInstance('carRight',
        //         () => {
        //             // console.log('Летим направо');
        //             this.sendclient('moveVehicleLeft', JSON.stringify({ a: 1 }));
        //         },
        //         () => {
        //             // console.log('Не летим направо');
        //             this.sendclient('moveVehicleLeft', JSON.stringify({ a: 0 }));
        //         }
        //     );
        // },
        //
        // controllerJump () {
        //     this.createHammerInstance('jump',
        //         () => {
        //             console.log('jump');
        //             this.sendclient('PlayerJump');
        //         },
        //         () => {
        //             console.log('jump off');
        //         }
        //     );
        // },
        //
        // controllerAimBack () {
        //     this.createHammerInstance('aimback',
        //         () => {
        //             // this.aimState = !this.aimState;
        //             console.log('aimback');
        //             this.sendclient('PlayerSwitchAimState', JSON.stringify({ value: !this.aimState }));
        //         },
        //         () => {
        //             console.log('aimback off');
        //         }
        //     );
        // },

        initBullets (bullets, fullClip, allBullets) {
            this.maxBullets = fullClip;
            this.allBullets = allBullets;
            this.bullets = bullets;
        },

        startReload (milliSeconds) {
            this.reloadingTime = milliSeconds;
            this.isReloading = true;
            setTimeout(() => { this.isReloading = false; }, this.reloadingTime);
        },

        makeHit () {
            if (this.hitTimeout !== null) {
                this.isHit = false;
                clearTimeout(this.hitTimeout);
                this.hitTimeout = null;
            }
            this.isHit = true;
            this.hitTimeout = setTimeout(() => {
                this.isHit = false;
                this.hitTimeout = null;
            }, 500);
        }

        // controllerReload () {
        //     this.createHammerInstance('reload',
        //         () => {
        //             console.log('reload');
        //             this.sendclient('PlayerReloadWeapon');
        //
        //             // Функция для перезарядки, если с бэка будешь все передавать, то удали
        //
        //             // this.isReloading = true;
        //             // setTimeout(() => { this.isReloading = false; }, this.reloadingTime);
        //         },
        //         () => {
        //             console.log('reload off');
        //         }
        //     );
        // },
        //
        // controllerGetCar () {
        //     this.createHammerInstance('getcar',
        //         () => {
        //             console.log('getcar');
        //             // this.playerGetInCar = !this.playerGetInCar;
        //             this.sendclient('PlayerGetInCar', JSON.stringify({ value: !this.playerGetInCar }));
        //         },
        //         () => {
        //             console.log('Отпустил кнопку');
        //         }
        //     );
        // },
        //
        // controllerMicro () {
        //     this.createHammerInstance('micro',
        //         () => {
        //             this.sendclient('MicrophoneToggle', JSON.stringify({ a: true }));
        //         },
        //         () => {
        //             this.sendclient('MicrophoneToggle', JSON.stringify({ a: false }));
        //         }
        //     );
        // },
        // controllerSprint () {
        //     this.createHammerInstance('sprint',
        //         () => {
        //             this.sendclient('Sprint', JSON.stringify({ a: true }));
        //             // console.log('бежим');
        //         },
        //         () => {
        //             this.sendclient('Sprint', JSON.stringify({ a: false }));
        //             // console.log('прекращаем бежать');
        //         }
        //     );
        // },
        // controllerHandBrake () {
        //     this.createHammerInstance('handBrake',
        //         () => {
        //             this.sendclient('Handbrake', JSON.stringify({ a: true }));
        //             // console.log('Уходим в дрифт');
        //         },
        //         () => {
        //             this.sendclient('Handbrake', JSON.stringify({ a: false }));
        //             // console.log('Выходим из дрифта');
        //         }
        //     );
        // }
    }
};
</script>
