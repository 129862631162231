<template>
    <transition name="fade">
        <div class="pay-day" v-if="show">
            <div class="pay-day__top">
                <div
                    class="pay-day__top-level"
                    :class="{ 'pay-day__top-level--vip': $store.state.player.vip}"
                >
                    <p>{{ $store.state.player.playerLevel }}</p>
                </div>
                <div class="pay-day__top-point">{{ $store.state.player.exp }} <span>/ {{ $store.state.player.playerLevel*4 }}</span></div>
            </div>
            <ul class="pay-day__list">
                <li v-if="policeSalary">
                    <p>{{ jobName }}</p>
                    <span><span class="dollar-symbol">$</span> {{ $formatNum(policeSalary) }}</span>
                </li>
                <li v-if="onlineBonus">
                    <p>{{ $t('pay_day.bonus')}}</p>
                    <span><span class="dollar-symbol">$</span> {{ $formatNum(onlineBonus) }}</span>
                </li>
                <li class="vip" v-if="$store.state.player.vip">
                    <p>{{ $t('pay_day.vip')}}</p>
                    <span><span class="dollar-symbol">$</span> {{ $formatNum(vipBonus) }}</span>
                </li>
                <li class="tax" v-if="tax">
                    <p>{{ $t('pay_day.tax')}}</p>
                    <span>- $ {{ $formatNum(tax) }}</span>
                </li>
                <li class="tax" v-if="fines">
                    <p>{{ $t('pay_day.fine')}}</p>
                    <span>- $ {{ $formatNum(fines) }}</span>
                </li>
                <li class="pay-day__total">
                    <p>{{ $t('pay_day.total')}}</p>
                    <span><span class="dollar-symbol">$</span> {{ $formatNum(totalSalary) }}</span>
                </li>
            </ul>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'PayDay',
    data () {
        return {
            show: false,
            jobName: this.$t('pay_day.salaryword'),
            policeSalary: 0,
            onlineBonus: 0,
            vipBonus: 0,
            tax: 0,
            fines: 0,
            totalSalary: 0
        };
    },
    mounted () {
        setTimeout(() => {
            this.show = false;
        }, 5000);
    },
    computed: {
        total () {
            return this.policeSalary + this.onlineBonus + (this.vipBonus || 0) - this.tax - this.fines;
        }
    },
    methods: {
        initialize (salary, fines = 0) {
            this.policeSalary = salary;
            this.fines = fines;
            this.totalSalary = salary;
            this.show = true;
            setTimeout(() => {
                this.show = false;
            }, 5000);
        }
    }
};
</script>

<style lang="scss" scoped>
.pay-day{
    position: fixed;
    top: 128px;
    left: 128px;
    z-index: 1;
    pointer-events: none;
    width: 472px;
    padding: 32px 40px;
    flex-direction: column;
    background: linear-gradient(180deg, rgba(159, 208, 53, 0.20) 0%, rgba(63, 88, 8, 0.00) 30%), radial-gradient(107.78% 134.10% at 50.00% 22.13%, #141A1E 0%, rgba(20, 26, 30, 0.89) 100%);
    box-shadow: 100px 100px 100px rgba(0, 0, 0, 0.24);
    &::before{
        content: '';
        height: 12px;
        background: #9FD035;
        box-shadow: 0px 0px 24px 0px rgba(159, 208, 53, 0.45), 0px 12px 40px 0px rgba(159, 208, 53, 0.45), 0px 24px 100px 0px rgba(159, 208, 53, 0.45);
        width: 100%;
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
    }
    &::after{
        background: url(@/assets/images/pattern-payday.svg) no-repeat top center / cover;
        content: '';
        height: 202px;
        width: 100%;
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        opacity: .2;
    }

    &__top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 3px solid rgba(241, 241, 241, 0.16);

        &-level{
            background-image: url("@/assets/images/level-icon-def.svg");
            width: 80px;
            height: 80px;
            background-position: center;
            background-size: cover;
            display: flex;
            justify-content: center;
            align-items: center;

            &--vip{
                background-image: url("@/assets/images/level-icon.png");
            }
            p {
                color: var(--white);
                text-shadow: 0 7.523809909820557px 30.095239639282227px 0 rgba(0, 0, 0, 0.25);
                font-size: 32px;
                font-weight: 900;
                line-height: 100%;
                letter-spacing: -2.709px;
                transform: translateX(-2.709px);
            }
        }

        &-point{
            color: #F1F1F1;
            font-size: 52px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 52px */
            span{
                color: #F1F1F1;
                font-size: 32px;
                font-style: normal;
                opacity: .5;
                font-weight: 400;
                line-height: 100%; /* 32px */
            }
        }
    }

    &__list{
        display: grid;
        grid-gap: 24px;
        li{
            display: flex;
            justify-content: space-between;

            * {
                color: #F1F1F1;
                font-size: 28px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 28px */
            }

            span{
                flex-shrink: 0;
            }
            .dollar-symbol{
                color: #FFCD4C;
            }

            &.vip{
                p, .dollar-symbol{
                    color: #9FD035;
                }
            }

            &.tax{
                * {
                    color: rgba(241, 241, 241, 0.50) ;
                }
            }
        }
    }
    &__total{
        padding-top: 24px;
        border-top: 3px solid rgba(241, 241, 241, 0.16);
    }
}

</style>
