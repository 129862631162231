<template>
    <div class="place" :class="{
    'place--top': place <= 3,
    'place--gold': place === 1,
    'place--silver': place === 2,
    'place--bronze': place === 3
    }">
        {{ place }}
        <span v-if="place <= 3" class="place-text">место</span>
    </div>
</template>

<script>
export default {
    name: 'RatingPlaceBadge',
    props: {
        place: {
            type: Number,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
.place {
    padding: 12px 18px;
    border-radius: 6px 6px 6px 24px;
    background: rgba(241, 241, 241, 0.20);
    color: var(--white);
    font-size: 36px;
    font-weight: 700;
    line-height: 100%;
    text-transform: uppercase;
    min-width: 132px;
    display: flex;
    justify-content: center;
    align-items: center;
    &-text {
        margin-left: 8px;
    }
    &--top {
        color: var(--dark);
        &.place--gold {
            background: var(--yellow);
        }
        &.place--silver {
            background: var(--silver);
        }
        &.place--bronze {
            background: var(--bronze);
        }
    }
}
</style>
