<template>
    <div class="login login--standard register">
        <div class="login__step">
            <StepLabel is-auth />
            <div class="login__step-form">
                <div class="login__step-form-wrapper">
                    <BaseInput
                        :label="this.$t('auth.email')"
                        type="email"
                        v-model="email"
                        form
                    />
                    <BaseInput
                        :label="this.$t('auth.password')"
                        placeholder='• • • • • •'
                        v-model="password"
                        form password
                    />
                    <BaseButton
                        full yellow
                        @click="submitForm"
                    >
                        {{ $t('auth.next') }}
                    </BaseButton>
                    <div class="login__forgot-password">
                        <a href="#">{{ $t('auth.call_support') }}</a>
                        <a href="#">{{ $t('auth.forgot_pass') }}</a>
                    </div>
                    <AuthSocial />
                </div>
            </div>
            <div class="login__step-switch login__step-switch--auth">
                <div class="login__step-support">
                    <p>{{ $t('auth.got_problems') }}</p>
                    <a href="#">{{ $t('auth.call_support') }}</a>
                </div>

                <div class="login__step-question">
                    <p>{{ $t('auth.no_account') }}</p>
                    <BaseButton border @click = "GotoRegister()">{{ $t('auth.register') }}</BaseButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StepLabel from './components/StepLabel.vue';
import BaseInput from '@/components/ui/BaseInput.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import AuthSocial from './components/AuthSocial.vue';

export default {
    name: 'AuthStep',
    components: { AuthSocial, BaseButton, BaseInput, StepLabel },
    data () {
        return {
            step: 0,
            email: '',
            password: '',
            isChecked: true
        };
    },
    methods: {
        submitForm () {
            if (!this.email || this.email.length < 4 || !this.email.match(/^[a-zA-Z0-9._!%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/ig)) return this.sendnotif(4, this.$t('auth.incorrect_email'));
            if (!this.password || this.password.length < 4 || !this.password.match(/^([а-яА-ЯёЁa-zA-Z0-9-?!.,()"$:/№%äöüßčćžÑđš^~çљџњђћ«»\s]+)$/ig)) return this.sendnotif(4, this.$t('auth.incorrect_password'));
            this.sendclient('LoginServer', JSON.stringify({ a: this.email, b: this.password }));
        },
        GotoRegister () {
            this.sendclient('ShowRegisterScreen');
        }
    }
};
</script>
