<template>
    <div class="mini-works" :style="{background: `radial-gradient(66.94% 140.08% at 50% 0%, ${gradient.startColor} 0%, ${gradient.finishColor} 100%)`}">
        <div class="mini-works__menu">
            <img
                class="mini-works__menu-image"
                :src="imageSource(image, 'mini-works')"
                alt=""
                :class="{'mini-works__menu-image--top' : [1,2,5].includes(workType)}"
            />
            <div class="mini-works__menu-title">
                {{ title }}
            </div>
            <div class="mini-works__menu-info">
                <div class="mini-works__menu-orders">{{ orderList.length }}</div>
                <div class="mini-works__menu-text"> {{ ordersText }}</div>
            </div>
            <BaseTabMenu class="mini-works__tabs" :items=tabDefinitions :activeTab=selectedType isScrollable :handleTabChange=changeTab />
        </div>
        <div class="mini-works__content">
            <div v-for="(order, index) in filteredOrderList" :key="index" class="mini-works__order">
                <IconBorder border="gradientBorder" />
                <div v-if="order.distance" class="mini-works__order-distance">{{ getDistanceUnits(order.distance) }}</div>
                <div class="mini-works__order-info">
                    <div class="mini-works__order-name" :class="{'mini-works__order-name--news' : workType === 4}">
                        {{ workType === 4 && index === 0 ? order.name : workType !== 4 ? order.name: '' }}
                    </div>
                    <div class="mini-works__order-status">
                        <WorkIcons :icon=order.icon />
                        {{ order.status }}
                    </div>
                    <div v-if="order.phone" class="mini-works__order-phone">
                        <IconCall />
                        {{ order.phone }}
                    </div>
                </div>
                <div class="mini-works__order-control">
                    <BaseButton class="mini-works__order-delete" v-if="order.hasDeleteButton" glass @click="removeOrder(index)">
                        <IconTrash />
                    </BaseButton>
                    <BaseButton class="mini-works__order-button" white @click="takeOrder(index)">
                        {{ workType === 4 ? $t('mini_works.publish') : $t('mini_works.take_order') }}
                    <div v-if="order.price" class="mini-works__order-price">
                        <span class="mini-works__order-price-dollar">$</span>{{ $formatNum(order.price) }}
                    </div>
                    </BaseButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseTabMenu from '@/components/ui/BaseTabMenu.vue';
import IconBorder from '@/components/icons/IconBorder.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import WorkIcons from '@/components/popups/content/works/icons/WorkIcons.vue';
import IconTrash from '@/components/icons/IconTrash.vue';
import IconCall from '@/components/icons/IconCall.vue';
import { MINI_WORKS_CONFIG } from '@/components/popups/content/works/constants/miniWorksConfig';

export default {
    name: 'MiniWorks',
    components: {
        IconCall,
        IconTrash,
        WorkIcons,
        BaseButton,
        IconBorder,
        BaseTabMenu
    },
    data () {
        return {
            selectedType: 'army',
            title: this.$t('mini_works.army'),
            ordersText: this.$t('mini_works.orders'),
            image: '5.png', // 4 - news
            workType: 5,
            gradient: {
                startColor: 'rgba(26, 141, 127, 0.94)',
                finishColor: 'rgba(21, 52, 41, 0.94)'
            },
            orderList: [
                /*
                {
                    id: 1,
                    relatedType: 'gov',
                    status: 'Текст объявления оставленного человеком и его телефон',
                    name: 'текст объявления',
                    phone: '310-040',
                    hasDeleteButton: true
                },
                {
                    id: 2,
                    relatedType: 'gov',
                    status: 'Текст объявления оставленного человеком и его телефон',
                    name: 'текст объявления',
                    phone: '311-040',
                    hasDeleteButton: true
                },
                {
                    id: 3,
                    relatedType: 'gov',
                    status: 'Текст объявления оставленного человеком и его телефон',
                    name: 'текст объявления',
                    phone: '325-030',
                    hasDeleteButton: true
                },
                {
                    id: 4,
                    relatedType: 'gov',
                    status: 'Текст объявления оставленного человеком и его телефон',
                    name: 'текст объявления',
                    phone: '301-010',
                    hasDeleteButton: true
                },
                {
                    id: 5,
                    relatedType: 'gov',
                    status: 'Текст объявления оставленного человеком и его телефон',
                    name: 'текст объявления',
                    phone: '321-010',
                    hasDeleteButton: true
                }
                */
                // {
                //     id: 3,
                //     relatedType: 'clothes',
                //     status: 'Vinewood 22/12',
                //     name: 'Kevin Mackalister',
                //     distance: 1485,
                //     hasDeleteButton: false,
                //     icon: 'location',
                //     price: 2500
                // },
                // {
                //     id: 4,
                //     relatedType: 'clothes',
                //     status: 'Critical health condition critical health condition',
                //     name: 'Kevin Mackalister',
                //     distance: 4260,
                //     hasDeleteButton: true,
                //     icon: 'heart'
                // },
                // {
                //     id: 5,
                //     relatedType: 'clothes',
                //     status: 'Vinewood 22/12',
                //     name: 'Gasoline',
                //     distance: 556,
                //     hasDeleteButton: false,
                //     icon: 'location',
                //     price: 955000
                // },
                // {
                //     id: 6,
                //     relatedType: 'clothes',
                //     status: 'Vinewood 22/12',
                //     name: 'Kevin Mackalister',
                //     distance: 1485,
                //     hasDeleteButton: false,
                //     icon: 'location'
                // },
                // {
                //     id: 7,
                //     relatedType: 'dangerous',
                //     status: 'Critical health condition critical health condition',
                //     name: 'Kevin Mackalister',
                //     distance: 4260,
                //     hasDeleteButton: true,
                //     icon: 'heart'
                // },
                // {
                //     id: 8,
                //     relatedType: 'food',
                //     status: 'Vinewood 22/12',
                //     name: 'Gasoline',
                //     distance: 556,
                //     hasDeleteButton: false,
                //     icon: 'location'
                // },
                // {
                //     id: 9,
                //     relatedType: 'urban',
                //     status: 'Vinewood 22/12',
                //     name: 'Kevin Mackalister',
                //     distance: 1485,
                //     hasDeleteButton: false,
                //     icon: 'location'
                // }
            ],
            tabDefinitions: [
                // { type: 'mod', text: 'модерировать', requiredLevel: 0 }
                // { type: 'deleted', text: 'удалённые', requiredLevel: 0 }
                // { type: 'clothes', text: 'Одежда', requiredLevel: 2 },
                // { type: 'food', text: 'Еда', requiredLevel: 3 },
                // { type: 'other', text: 'Прочее', requiredLevel: 12 },
                // { type: 'canon', text: 'Area #5', requiredLevel: 25 },
                // { type: 'ipsum', text: 'Area #44', requiredLevel: 222 },
                // { type: 'lorem', text: 'Area #23', requiredLevel: 254 }
            ]
        };
    },
    computed: {
        filteredOrderList () {
            console.log(this.orderList);
            if (!this.selectedType) {
                return this.orderList;
            }
            return this.orderList.filter(item => item.relatedType === this.selectedType);
        }
    },
    methods: {
        /*
        window.modifyVueComponent({
            App: {
                showPopup: true,
                popupContent: 'MiniWorks',
                contentSubFolder: 'works'
            },
            MiniWorks:{
                initialize:[
                    4,
                    [{
                    id: 1,
                    relatedType: 'gov',
                    status: 'Текст объявления оставленного человеком и его телефон',
                    name: 'текст объявления',
                    phone: '310-040',
                    hasDeleteButton: true
                },
                {
                    id: 2,
                    relatedType: 'gov',
                    status: 'Текст объявления оставленного человеком и его телефон',
                    name: 'текст объявления',
                    phone: '311-040',
                    hasDeleteButton: true
                },
                {
                    id: 3,
                    relatedType: 'gov',
                    status: 'Текст объявления оставленного человеком и его телефон',
                    name: 'текст объявления',
                    phone: '325-030',
                    hasDeleteButton: true
                },
                {
                    id: 4,
                    relatedType: 'gov',
                    status: 'Текст объявления оставленного человеком и его телефон',
                    name: 'текст объявления',
                    phone: '301-010',
                    hasDeleteButton: true
                },]
                ]
            }
        });
        */
        initialize (type, orderList) {
            // if (type === 1) { // дальнобойщик
            //     this.image = '2.png';
            //     this.gradient.startColor = 'rgba(56, 60, 70, 0.94)';
            //     this.gradient.finishColor = 'rgba(31, 31, 43, 0.94)';
            //     this.title = this.$t('mini_works.truckerorders');
            //     this.ordersText = this.$t('mini_works.orders');
            //     this.tabDefinitions = [
            //         { type: 'gov', text: this.$t('mini_works.trucker_list.govlist'), requiredLevel: 0 }
            //
            //     ];
            // } else if (type === 2) { // такси
            //     this.image = '1.png';
            //     this.gradient.startColor = 'rgba(203, 158, 0, 0.94';
            //     this.gradient.finishColor = 'rgba(104, 75, 0, 0.94)';
            //     this.title = this.$t('mini_works.taxiorders');
            //     this.ordersText = this.$t('mini_works.calls');
            //     this.tabDefinitions = [
            //         { type: 'gov', text: this.$t('mini_works.trucker_list.govlist'), requiredLevel: 0 }
            //
            //     ];
            // } else if (type === 3) { // больница
            //     this.image = '0.png';
            //     this.gradient.startColor = 'rgba(163, 30, 54, 0.94)';
            //     this.gradient.finishColor = 'rgba(60, 8, 33, 0.94)';
            //     this.title = this.$t('mini_works.medicorders');
            //     this.ordersText = this.$t('mini_works.calls');
            //     this.tabDefinitions = [
            //         { type: 'gov', text: this.$t('mini_works.trucker_list.privatelist'), requiredLevel: 0 },
            //         { type: 'clothes', text: this.$t('mini_works.trucker_list.orglist'), requiredLevel: 0 }
            //     ];
            // } else if (type === 4) { // СМИ
            //     this.image = '4.png';
            //     this.gradient.startColor = '#F39200';
            //     this.gradient.finishColor = '#763200';
            //     this.title = this.$t('mini_works.news');
            //     this.ordersText = this.$t('mini_works.about');
            //     this.tabDefinitions = [
            //         { type: 'gov', text: this.$t('mini_works.adlist'), requiredLevel: 0 }
            //     ];
            // } else if (type === 5) { // армия
            //     this.image = '5.png';
            //     this.gradient.startColor = 'rgba(26, 141, 127, 0.94)';
            //     this.gradient.finishColor = 'rgba(21, 52, 41, 0.94)';
            //     this.title = this.$t('mini_works.army');
            //     this.ordersText = this.$t('mini_works.orders');
            //     this.tabDefinitions = [
            //         { type: 'gov', text: this.$t('mini_works.trucker_list.orglist'), requiredLevel: 0 }
            //     ];
            // }
            const config = MINI_WORKS_CONFIG[type];
            if (config) {
                this.image = config.image;
                this.gradient.startColor = config.gradient.startColor;
                this.gradient.finishColor = config.gradient.finishColor;
                this.title = this.$t(config.title);
                this.ordersText = this.$t(config.ordersText);
                this.tabDefinitions = config.tabDefinitions.map(({ text, ...rest }) => ({ ...rest, text: this.$t(text) }));
            }
            this.workType = type;
            this.orderList = orderList;
            // order2List
            this.selectedType = this.tabDefinitions ? this.tabDefinitions[0].type : null;
        },
        changeTab (tabType) {
            this.selectedType = tabType;
        },
        // Округляет до сотых, например 1565Метров = 1.5км
        getDistanceUnits (meters) {
            meters = meters.toFixed(0);
            if (meters < 1000) {
                return `${meters} M`;
            }
            const km = String(meters / 1000);
            const roundedKm = Math.floor(Number(km)).toFixed(0);
            const meter = km.split('.').length !== 1 ? Number(String(km).split('.')[1][0]) : null;
            return meter ? `${roundedKm}.${meter} KM` : `${roundedKm} KM`;
        },
        getTypeNumber () {
            if (this.selectedType === 'gov') return 1;
            else if (this.selectedType === 'clothes') return 2;
        },
        takeOrder (index) {
            const order = this.filteredOrderList[index];
            const tabType = this.getTypeNumber();
            if (this.workType === 1) this.sendclient('TruckOrder', JSON.stringify({ a: tabType, b: order.id }));
            else if (this.workType === 3) this.sendclient('HospOrderTake', JSON.stringify({ a: tabType, b: order.id }));
            else if (this.workType === 4) this.sendclient('ADOrder', JSON.stringify({ a: order.id }));
            else if (this.workType === 5) this.sendclient('AmmoOrderTake', JSON.stringify({ a: order.id }));
        },
        removeOrder (index) {
            const order = this.filteredOrderList[index];
            if (this.workType === 4) this.sendclient('ADDelete', JSON.stringify({ a: order.id }));
        },
        // window.callVueMethod('MiniWorks','deleteAD', 1);
        deleteAD (id) {
            if (this.orderList.length) {
                id = parseInt(id);
                const foundedIndex = this.orderList.findIndex(item => item.id === id);
                if (foundedIndex !== -1) {
                    this.orderList.splice(foundedIndex, 1);
                }
            }
        }
    }
};
</script>

<style lang="scss">
.mini-works {
    display: grid;
    grid-template-columns: repeat(21, 1fr);
    grid-gap: 0 1vw;
    height: 100%;
    &__menu {
        grid-column: 6 span;
        padding-left: 7.35vw;
        padding-top: 3.95vw;
        height: calc(100% - 3.95vw);
        display: grid;
        align-content: start;
        position: relative;
        .tabs__item {
            min-height: 5.6vw;
            height: max-content;
        }
        &-image {
            position: absolute;
            width: 11.05vw;
            left: -3vw;
            top: 3.95vw;
            &--top {
                top: 27px;
            }
        }
        &-title {
            font-weight: 300;
            line-height:110%;
            color: var(--white);
            font-size: 2.8vw;
        }
        &-orders {
            margin-right: 1.25vw;
            font-weight: 300;
            line-height: 80%;
            color: var(--white);
            font-size: 2.8vw;
        }
        &-text {
            color: rgba(241, 241, 241, 0.4);
            text-transform: uppercase;
            font-weight: 700;
            font-size: 1vw;
        }
        &-info {
            display: flex;
            align-items: center;
            margin: 44px 0;
        }
    }
    &__content {
        grid-column: 15 span;
        padding: 3.95vw 10vw 3.95vw 1.2vw;
        overflow-y: auto;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    &__order {
        padding: 1.75vw 2.3vw;
        position: relative;
        border-radius: .16vw;
        background: linear-gradient(119.36deg, rgba(255, 255, 255, 0.08) 0%, rgba(241, 241, 241, 0.06) 0.01%, rgba(241, 241, 241, 0.16) 100%);
        display: flex;
        align-items: center;
        z-index: 4;
        margin-top: .65vw;
        &:first-child {
            margin: 0;
        }
        &-distance {
            font-family: var(--font-stretch);
            font-weight: 700;
            font-size: 1.75vw;
            color: var(--white);
            padding: 1.1vw .95vw;
            background: rgba(241, 241, 241, 0.1);
            border-radius: .16vw;
            white-space: nowrap;
            margin-right: 44px;
        }
        &-name {
            margin-bottom: .725vw;
            font-weight: 400;
            opacity: .5;
            color: var(--white);
            font-size: 1.75vw;
            &--news {
                text-transform: uppercase;
                font-weight: 700;
                font-family: var(--font-stretch);
            }
        }
        &-info {
            margin: 0 1.75vw 0 0;
        }
        &-status {
            display: flex;
            font-size: 1.6vw;
            font-weight: 500;
            color: var(--white);
            align-items: center;
        }
        &-control {
            margin-left: auto;
            display: flex;
        }
        &-button {
            height: 4.45vw;
            padding: 0 1.75vw;
            font-size: 1.45vw;
            white-space: nowrap;
        }
        &-delete {
            height: 4.45vw;
            margin-right: 1.75vw;
            padding: 0 1.75vw;
            svg {
                width: 1.6vw;
                height: 1.6vw;
            }
        }
        &-price {
            margin-left: .725vw;
            background-color: var(--dark);
            color: var(--white);
            padding: .25vw .5vw;
            border-radius: .25vw;
            font-weight: 700;
            font-size: 1.6vw;
            &-dollar {
                display: inline-block;
                color: var(--gold);
                margin-right: .16vw;
            }
        }
        &-phone {
            margin-top: 12px;
            display: flex;
            align-items: center;
            opacity: .6;
            color: var(--white);
            font-size: 44px;
            font-weight: 700;
            line-height: 100%;
            text-transform: uppercase;
            font-family: var(--font-stretch);
            svg {
                margin-right: 12px;
                width: 32px;
                height: 32px;
            }
        }
    }
    &__tabs {
        padding-right: 1.75vw;
    }
}
</style>
