<template>
    <div class="icon-eye">
        <svg v-if="icon === 'open'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12C15 13.6569 13.6569 15 12 15ZM12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" fill="#F1F1F1"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 19C8.68497 19 5.43017 16.8301 2.20523 12.6069C1.93159 12.2486 1.93159 11.7514 2.20523 11.3931C5.43017 7.16987 8.68497 5 12 5C15.315 5 18.5698 7.16987 21.7948 11.3931C22.0684 11.7514 22.0684 12.2486 21.7948 12.6069C18.5698 16.8301 15.315 19 12 19ZM12 17C14.434 17 17.019 15.3661 19.7295 12C17.019 8.63391 14.434 7 12 7C9.56601 7 6.98096 8.63391 4.27049 12C6.98096 15.3661 9.56601 17 12 17Z" fill="#F1F1F1"/>
        </svg>

        <svg v-if="icon === 'close'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.66162 5.95452L5.70711 4L4.29289 5.41421L5.98702 7.10834C4.7207 8.12318 3.4607 9.45609 2.20523 11.1002C1.93159 11.4585 1.93159 11.9557 2.20523 12.314C5.43017 16.5372 8.68497 18.7071 12 18.7071C13.4569 18.7071 14.9022 18.288 16.3384 17.4597L18.2929 19.4142L19.7071 18L18.013 16.3059C19.2793 15.291 20.5393 13.9581 21.7948 12.314C22.0684 11.9557 22.0684 11.4585 21.7948 11.1002C18.5698 6.87697 15.315 4.70711 12 4.70711C10.5431 4.70711 9.09783 5.12621 7.66162 5.95452ZM7.4106 8.53192C6.38436 9.32605 5.33714 10.3825 4.27049 11.7071C6.98096 15.0732 9.56601 16.7071 12 16.7071C12.9321 16.7071 13.8864 16.4675 14.8614 15.9827L7.4106 8.53192ZM16.5894 14.8823L9.13861 7.43151C10.1136 6.94673 11.0679 6.70711 12 6.70711C14.434 6.70711 17.019 8.34101 19.7295 11.7071C18.6629 13.0318 17.6156 14.0882 16.5894 14.8823Z" fill="#F1F1F1"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: {
        icon: String
    }
};
</script>
