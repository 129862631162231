<template>
    <!--    #todo: Переводы-->
    <div class="desc">
        <div class="desc-bg" :class="this.$props.item.rarity"></div>
        <div class="desc-info">
            <div class="desc-info__data">
                <div class="desc-info__title">
                    {{ $t(this.$props.item.name) }}
                </div>
                <div class="desc-info__badge" :class="this.$props.item.rarity">{{ rarityMap[this.$props.item.rarity] }}</div>
                <div class="desc-info__text">
                    {{ $t(this.$props.item.desc) }}
                </div>
            </div>
            <InventoryItem :item="this.$props.item" />
        </div>
        <BaseButton @click="close" class="desc-button" glass>{{ $t('shop.close_description') }}</BaseButton>
    </div>
</template>

<script>

import InventoryItem from '@/components/popups/components/inventoryItem/InventoryItem.vue';
import BaseButton from '@/components/ui/BaseButton.vue';

export default {
    name: 'BattlePassItemDescription',
    components: {
        BaseButton,
        InventoryItem
    },
    props: {
        item: Object
    },
    data () {
        return {
            rarityMap: {
                legend: this.$t('shop.rarity_map.legend'),
                ordinary: this.$t('shop.rarity_map.ordinary'),
                unusual: this.$t('shop.rarity_map.unusual'),
                rare: this.$t('shop.rarity_map.rare'),
                epic: this.$t('shop.rarity_map.epic')
            }
        };
    },
    methods: {
        close () {
            this.$emit('closeDesc');
        }
    }
};
</script>

<style lang="scss" scoped>

.desc {
    position: absolute;
    z-index: 5;
    height: calc(100% - 5vw);
    bottom: 0;
    right: 0;
    width: 1120px;
    padding: 84px 180px 84px 84px;
    background: rgba(10, 14, 17, 0.97);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-bg, &-info__badge {
        &.legend{
            background: linear-gradient(211.69deg, #FFCD4C 33.85%, #FFA800 100%);
        }
        &.epic{
            background: linear-gradient(211.69deg, #BE6AFF 20.31%, #7A28FF 100%);
        }
        &.rare{
            background: linear-gradient(211.69deg, #48FDC7 0%, #009D6E 100%);
        }
        &.ordinary{
            background: linear-gradient(212deg, #96ABB5 43.23%, #72848B 100%);
        }
        &.unusual{
            background: linear-gradient(211.69deg, #5FCFFF 35.94%, #2780A6 100%);
        }
    }
    &-bg {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 280px;
        opacity: .4;
    }
    &-info {
        display: flex;
        &__data {
            max-width: 600px;
            margin-right: 32px;
        }
        &__title {
            color: #FFF;
            font-size: 54px;
            font-weight: 400;
            line-height: 100%;
        }
        &__badge {
            margin: 40px 0;
            width: fit-content;
            padding: 10px 24px;
            text-transform: uppercase;
            font-family: var(--font-stretch);
            color: var(--white);
            font-size: 34px;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: 1.36px;
        }
        &__text {
            color: var(--white);
            font-size: 32px;
            font-weight: 500;
            line-height: 140%;
            opacity: .6;
        }
    }
    &-button {
        max-width: fit-content;
        padding: 0 44px;
        font-size: 36px;
    }
}
</style>
