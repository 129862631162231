<template>
    <svg v-if="isActive" xmlns="http://www.w3.org/2000/svg" width="34" height="41" viewBox="0 0 34 41" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17 0.666504C9.91235 0.666504 4.16667 6.41219 4.16667 13.4998V18.9282C4.16667 21.3218 3.21581 23.6175 1.52326 25.31C0.868078 25.965 0.5 26.8537 0.5 27.7802V29.3807C0.5 30.7352 1.598 31.8332 2.95247 31.8332H8.86548C8.86548 32.5166 8.8717 34.1775 9.77153 35.8111C11.1689 38.3479 13.8786 40.0832 17 40.0832C20.1214 40.0832 22.8311 38.3479 24.2285 35.8111C25.1283 34.1775 25.1345 32.5166 25.1345 31.8332H31.0476C32.402 31.8332 33.5 30.7352 33.5 29.3807V27.7802C33.5 26.8537 33.1319 25.965 32.4768 25.31C30.7843 23.6175 29.8333 21.3218 29.8333 18.9282V13.4998C29.8333 6.41219 24.0877 0.666504 17 0.666504ZM20.5512 31.8332C20.5512 32.4418 20.5075 33.0666 20.2138 33.5998C19.59 34.7324 18.3845 35.4998 17 35.4998C15.6155 35.4998 14.41 34.7324 13.7862 33.5998C13.4925 33.0666 13.4488 32.4418 13.4488 31.8332H20.5512Z" fill="#141A1E"/>
    </svg>
    <svg v-else xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M35.2589 40.3331L30.1307 35.2051C30.112 36.0162 29.9924 37.4234 29.2281 38.8111C27.8307 41.3479 25.1211 43.0831 21.9996 43.0831C18.8782 43.0831 16.1686 41.3479 14.7712 38.8111C13.8714 37.1774 13.8652 35.5166 13.8652 34.8331H7.95214C6.59767 34.8331 5.49967 33.7351 5.49967 32.3807V30.7802C5.49967 29.8536 5.86775 28.965 6.52293 28.3099C8.21548 26.6174 9.16634 24.3217 9.16634 21.9281V16.4998C9.16634 15.7882 9.22427 15.0901 9.33564 14.41L3.66634 8.74068C3.55447 8.62881 3.45657 8.50826 3.37267 8.38118C2.78536 7.49166 2.88325 6.28285 3.66634 5.49978C4.56128 4.60482 6.01229 4.60482 6.90725 5.49978L38.4996 37.0922C39.3947 37.9872 39.3947 39.4381 38.4996 40.3331C37.6048 41.2281 36.1537 41.2281 35.2589 40.3331ZM25.5508 34.8331H18.4485C18.4485 35.4418 18.4921 36.0666 18.7858 36.5997C19.4097 37.7323 20.6151 38.4998 21.9996 38.4998C23.3842 38.4998 24.5896 37.7323 25.2135 36.5997C25.5072 36.0666 25.5508 35.4418 25.5508 34.8331Z" fill="#141A1E"/>
        <path d="M38.4954 30.6062C38.4524 29.7429 38.0904 28.924 37.4765 28.31C35.7839 26.6175 34.833 24.3218 34.833 21.9282V16.4998C34.833 9.41219 29.0873 3.6665 21.9996 3.6665C19.065 3.6665 16.3603 4.65159 14.1983 6.30916L38.4954 30.6062Z" fill="#141A1E"/>
    </svg>
</template>
<script>
export default {
    name: 'IconNotify',
    props: {
        isActive: {
            type: Boolean,
            required: true
        }
    }
};
</script>
