<template>
    <div class="level">
        <div class="level-one level__item" v-if="levelIcon === 1">
            <img src="@/assets/images/level-1.png" :style="`width: ${width}vw`">
            <p v-if="level" :style="`font-size: ${fontSize}vw`">{{ level }} <span v-if="showText">{{ $t('base_level.level') }}</span></p>
        </div>
        <div class="level-two level__item" v-if="levelIcon === 2">
            <img src="@/assets/images/level-2.png" :style="`width: ${width}vw`">
            <p v-if="level" :style="`font-size: ${fontSize}vw`">{{ level }} <span v-if="showText">{{ $t('base_level.level') }}</span></p>
        </div>
        <div class="level-three level__item" v-if="levelIcon === 3">
            <img src="@/assets/images/level-3.png" :style="`width: ${width}vw`">
            <p v-if="level" :style="`font-size: ${fontSize}vw`">{{ level }} <span v-if="showText">{{ $t('base_level.level') }}</span></p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BaseLevel',
    props: {
        levelIcon: Number,
        level: String,
        width: Number,
        fontSize: Number,
        showText: Boolean
    }
};
</script>

<style lang="scss" scoped>
.level{
    &__item{
        display: flex;
        position: relative;
        max-width: max-content;
        align-items: center;
        justify-content: center;
    }
    p{
        color: #F1F1F1;
        text-align: center;
        text-shadow: 4px 4px 0px rgba(0, 0, 0, 0.3), -4px 4px 0px rgba(0, 0, 0, 0.3), 0px 8px 0px rgba(0, 0, 0, 0.3);
        font-size: calc(2vw + 10px);
        font-weight: 900;
        font-family: var(--font-stretch);
        line-height: 100%;
        letter-spacing: 0.1vw;
        text-transform: uppercase;
        position: absolute;
        left: 0;
        right: 0;
        top: -0.3vw;
        bottom: 0;
        justify-content: center;
        display: flex;
        flex-direction: column;
        span{
            display: block;
        };
    }
}
</style>
