<template>
    <transition name="notice-transition">
        <div class="notice" v-if="visible" @click="removeNotice">
            <IconNotice :icon="type" />
            <p>{{ text }}</p>
        </div>
    </transition>
</template>

<script>
import IconNotice from '@/components/icons/IconNotice.vue';

export default {
    name: 'BaseNotice',
    components: { IconNotice },
    props: {
        id: {
            type: Number,
            required: false
        },
        type: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            visible: false
        };
    },
    mounted () {
        this.showAndHideNotice();
    },
    methods: {
        showAndHideNotice () {
            setTimeout(() => {
                this.visible = true;
                setTimeout(() => {
                    this.visible = false;
                }, 5000); // Уведомление исчезает через 5 секунд после появления
            }, this.id * 2000); // Задержка между уведомлениями
        },
        removeNotice () {
            this.visible = false;
        }
    }
};
</script>

<style lang="scss">
.notice{
    margin: 0 auto;
    width: max-content;
    max-width: 35vw;
    grid-template-columns: auto 1fr;

    display: grid;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1vw 1.2vw;
    grid-gap: 1vw;
    pointer-events: all;

    background: rgba(32, 36, 39, 0.96);
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05), 0px 14px 100px rgba(0, 0, 0, 0.05);
    border-radius: 0.5vw;

    &--small-text{
        max-width: max-content;
    }

    p{
        font-weight: 700;
        font-size: 1.4vw;
        line-height: 110%;
        color: var(--white);
        pointer-events: none;
    }
    &__small-text{
        letter-spacing: 0.06em;
        text-transform: uppercase;
        font-size: 1.5vw;
    }

    .icon-wrapper {
        width: 2.3vw;
        height: 2.1vw;
        svg {
            width: 2.3vw;
            height: 2.3vw;
            pointer-events: none;
        }
    }
}

.notice-transition-enter-active,
.notice-transition-leave-active {
    transition: opacity 0.3s ease, height 0.3s ease, padding 0.3s ease;
    overflow: hidden;
}

.notice-transition-enter,
.notice-transition-leave-to {
    opacity: 0;
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
}

</style>
