<template>
    <div class="shop-number" :class="{'shop-number--bg' : !isEditorOpened}">
        <AppHeader back-button @getBackHeader="handleBackHeader">
            <template #title>{{ isEditorOpened ? $t('shop.plates.settings') : $t('shop.plates.buying') }}</template>
        </AppHeader>
        <ShopNumberEdit :maxLength="selectedPlate.length" v-if="isEditorOpened" :plateID="selectedPlate.id" :platePrice="selectedPlate.price" />
        <div v-else class="shop-number__wrapper">
            <div class="shop-number__plates">
                <div
                    v-for="item in plates"
                    :key="item.type"
                    class="shop-number__plate"
                    @click="selectPlate(item)"
                    :style="{opacity: selectedPlate === item ? 1 : 0.5}"
                >
                    <img :src="imageSrc(item.image)" alt="">
                    <div v-if="selectedPlate === item" class="shop-number__indicator"></div>
                </div>
            </div>
            <BaseButton @click="openEditor" class="shop-number__button" yellow>
                {{ $t('shop.plates.continue') }}
                <IconBackArrow />
            </BaseButton>
            <transition name="fade">
                <div v-if="selectedPlate" class="shop-number__info">
                    <div class="shop-number__info-text">
                        {{ $t('shop.plates.number_size') }}
                    </div>
                    <div class="shop-number__info-symbols">
                        {{ selectedPlate.length }} {{ $t('shop.plates.symbols') }}
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import AppHeader from '@/components/AppHeader/AppHeader.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import IconBackArrow from '@/components/icons/IconBackArrow.vue';
import ShopNumberEdit from '@/components/popups/content/shop/components/shop/ShopNumberEdit.vue';

export default {
    name: 'ShopNumber',
    components: {
        ShopNumberEdit,
        IconBackArrow,
        BaseButton,
        AppHeader
    },
    data () {
        return {
            selectedPlate: null,
            isEditorOpened: false,
            plates: [
                { id: 1, image: 'rus', price: 40, length: 6 },
                { id: 2, image: 'sg', price: 40, length: 7 },
                { id: 3, image: 'europe', price: 40, length: 8 },
                { id: 4, image: 'uk', price: 40, length: 8 },
                { id: 5, image: 'dubai', price: 40, length: 6 },
                { id: 6, image: 'uae', price: 40, length: 6 },
                { id: 9, image: 'japan-1', price: 40, length: 4 },
                { id: 7, image: 'japan-3', price: 40, length: 4 },
                { id: 8, image: 'japan-2', price: 40, length: 4 },
                { id: 10, image: 'usa-4', price: 40, length: 7 },
                { id: 11, image: 'usa-1', price: 40, length: 7 },
                { id: 12, image: 'usa-2', price: 40, length: 7 },
                { id: 13, image: 'usa-3', price: 40, length: 7 }
            ]
        };
    },
    methods: {
        handleBackHeader () {
            this.sendclient('shopMain');
        },
        imageSrc (img) {
            return require(`@/components/popups/content/shop/assets/images/plates/${img}.svg`);
        },
        selectPlate (item) {
            this.selectedPlate = item;
            console.log('выбрали: ', item.id);
        },
        openEditor () {
            if (this.selectedPlate) {
                this.isEditorOpened = true;
                this.sendclient('initNumberPlate', JSON.stringify({ a: this.selectedPlate.id }));
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.shop {
    &-number {
        height: 100%;
        &--bg {
            background: url("../../assets/images/number-bg.svg") no-repeat center/cover, radial-gradient(138.02% 65.96% at 50.00% -0.00%, #373A3C 0%, #1C1E21 100%);
        }
        &__wrapper {
            height: calc(100% - 5vw);
            padding: 128px 168px;
        }
        &__plates {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 102px;
            justify-content: center;
            margin-bottom: 32px;
        }
        &__plate {
            height: 138px;
            margin-left: 24px;
            margin-bottom: 24px;
            position: relative;
            &:first-child {
                margin-left: 0;
            }
            img {
                height: 100%;
            }
        }
        &__indicator {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 61px;
            height: 48px;
            background: url("../../assets/images/indicator.svg") no-repeat center/cover;
        }
        &__button {
            margin: 0 auto 32px;
            svg {
                width: 62px;
                height: 62px;
                transform: rotate(180deg);
                margin-left: 18px;
            }
        }
        &__info {
            display: flex;
            justify-content: center;
            align-items: center;
            &-text {
                margin-right: 24px;
                color: var(--white);
                font-size: 36px;
                font-weight: 400;
                line-height: 100%;
            }
            &-symbols {
                padding: 12px 16px;
                border-radius: 4px;
                background: rgba(241, 241, 241, 0.16);
                color: var(--white);
                font-size: 36px;
                font-weight: 700;
                line-height: 100%;
            }
        }
    }
}
</style>
