<template>
    <div class="rating">
        <AppHeader back-button>
            <template #title>
                {{ $t('rating.title') }}
            </template>
        </AppHeader>
        <div class="rating-content">
            <BaseTabMenu class="rating-tabs" is-scrollable :items="tabDefinitions" :handleTabChange="changeTab" :activeTab="selectedType" />
            <div v-if="selectedType === 'players'" class="rating-data">
                <div class="rating-header">
                    <div class="rating-header__text">
                        {{ $t('rating.place') }}
                    </div>
                    <div class="rating-header__text">
                        {{ $t('rating.level') }}
                    </div>
                </div>
                <div class="rating-list rating-list--player">
                    <div v-for="(player, index) in players"
                         :key="player.pid"
                         class="rating-item"
                         :class="{'rating-item--own' : player.pid === playerPID}"
                    >
                        <RatingPlaceBadge :place="index + 1" />
                        <div class="rating-item__name">
                            {{ player.nickname }}
                        </div>
                        <div class="rating-item__number">
                            {{ $formatNum(player.points) }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="selectedType === 'families'" class="rating-data">
                <div class="rating-header rating-header--family">
                    <div class="rating-header__text">
                        {{ $t('rating.place') }}
                    </div>
                    <!--
                    <div class="rating-flex">
                        <div class="rating-header__text">
                            areas
                        </div>
                        <div class="rating-header__text">
                            lvl
                        </div>
                        <div class="rating-header__text">
                            common
                        </div>
                    </div>
                    -->
                </div>
                <div class="rating-list rating-list--player">
                    <div v-for="(family, index) in families"
                         :key="family.name"
                         class="rating-item"
                    >
                        <div class="rating-item__family">
                            <div class="rating-item__family-top">
                                <RatingPlaceBadge :place="index + 1" />
                                <div class="rating-item__name">
                                    {{ family.name }}
                                </div>
                            </div>
                            <div class="rating-item__family-owner">
                                {{ $t('rating.owner') }} {{ family.owner }}
                            </div>
                        </div>
                        <!--
                        <div class="rating-flex">
                            <div class="rating-item__text">
                                {{ $formatNum(family.areas) }}
                            </div>
                            <div class="rating-item__text">
                                {{ $formatNum(family.level) }}
                            </div>
                            <div class="rating-item__text">
                                {{ $formatNum(family.common) }}
                            </div>
                        </div>
                        -->
                    </div>
                </div>
            </div>
            <div v-if="selectedType === 'rich'" class="rating-data">
                <div class="rating-header">
                    <div class="rating-header__text">
                        {{ $t('rating.place') }}
                    </div>
                    <!--
                    <div class="rating-header__text">
                        уровень
                    </div>
                    -->
                </div>
                <div class="rating-list rating-list--player">
                    <div v-for="(player, index) in rich"
                         :key="player.pid"
                         class="rating-item"
                         :class="{'rating-item--own' : player.pid === playerPID}"
                    >
                        <RatingPlaceBadge :place="index + 1" />
                        <div class="rating-item__name">
                            {{ player.nickname }}
                        </div>
                        <!--
                        <div class="rating-flex">
                            <div class="rating-item__money">
                                <span class="rating-item__money-dollar">$</span>
                                {{ $formatNum(player.cash) }}
                            </div>
                            <div class="rating-item__money">
                                <IconDonate />
                                {{ $formatNum(player.donate) }}
                            </div>
                        </div>
                        -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseTabMenu from '@/components/ui/BaseTabMenu.vue';
import AppHeader from '@/components/AppHeader/AppHeader.vue';
import RatingPlaceBadge from '@/components/popups/content/rating/components/RatingPlaceBadge.vue';
// import IconDonate from '@/components/icons/IconDonate.vue';

export default {
    name: 'RatingSystem',
    components: {
        // IconDonate,
        RatingPlaceBadge,
        AppHeader,
        BaseTabMenu
    },
    data () {
        return {
            famLoaded: false,
            richLoaded: false,
            selectedType: 'players',
            tabDefinitions: [
                { type: 'players', text: this.$t('rating.top_players'), icon: 'user' },
                { type: 'families', text: this.$t('rating.top_families'), icon: 'family' },
                { type: 'rich', text: this.$t('rating.top_rich'), icon: 'star' }
            ],
            players: [
                /*
               {
                   nickname: 'Kevin Mackalister',
                   points: 4500,
                   pid: 1
               },
               {
                   nickname: 'Kevin Mackalister',
                   points: 1201,
                   pid: 1334
               }
                 */
            ],
            rich: [
                /*
               {
                   nickname: 'Kevin Mackalister',
                   cash: 414,
                   donate: 22,
                   pid: 2323
               },
               {
                   nickname: 'Kevin Mackalister',
                   cash: 123222445,
                   donate: 1111,
                   pid: 124124
               },
               */
            ],
            families: [
                /*
                {
                    name: 'NLCfam',
                    owner: 'Kevin Mckalister',
                    areas: 24,
                    level: 4,
                    common: 1330
                },
                {
                    name: 'faqq23q',
                    owner: 'Kevin Mckalister',
                    areas: 24,
                    level: 4,
                    common: 1330
                },
                */
            ]
        };
    },
    computed: {
        playerPID () {
            return this.$store.state.player.pid;
        }
    },
    methods: {
        /*
        window.modifyVueComponent({
            App: {
                showPopup: true,
                popupContent: 'RatingSystem',
                contentSubFolder: 'rating'
            },
            RatingSystem: {
                initialize: [
                    [{nickname: 'Kevin Mackalister',
                        points: 4500,
                        pid: 1},{
                        nickname: 'Kevin Mackalister',
                        points: 1201,
                        pid: 1334
                    },],
                ]
            }
        });
        */
        initialize (playerArray) {
            this.players = playerArray;
            this.selectedType = 'players';
        },
        loadFam (famArray) {
            this.families = famArray;
            this.selectedType = 'families';
            this.famLoaded = true;
        },
        loadRich (richArray) {
            this.rich = richArray;
            this.selectedType = 'rich';
            this.richLoaded = true;
        },
        changeTab (tabType) {
            if (tabType === 'families' && !this.famLoaded) return this.sendclient('RatingFamLoad');
            else if (tabType === 'rich' && !this.richLoaded) return this.sendclient('RatingRichLoad');
            this.selectedType = tabType;
        }
    }
};
</script>

<style lang="scss" scoped>
.rating {
    height: 100%;
    &-content {
        height: calc(100% - 5vw);
        padding: 0 178px 0 120px;
        display: grid;
        grid-template-columns: 480px 1fr;
        grid-gap: 0 100px;
        background: url("@/assets/images/rating/rating-bg.svg") no-repeat center/cover, radial-gradient(214.64% 141.42% at 0% 0%, #FD9B41 0%, #C4533A 25%, #B53856 50%, #7B349D 75%, #23F2A8 100%);
    }
    &-data {
        padding: 90px 0;
        overflow-y: auto;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    &-tabs {
        padding: 90px 0;
    }
    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 24px;
        margin-bottom: 26px;
        &--family {
            .rating-header__text {
                min-width: 200px;
                text-align: center;
                &:last-child {
                    text-align: right;
                }
                &:first-child {
                    text-align: left;
                }
            }
            .rating-flex {
                .rating-header__text {
                    &:first-child {
                        text-align: center;
                    }
                }
            }
        }
        &__text {
            text-transform: uppercase;
            color: var(--white);
            font-family: var(--font-stretch);
            font-size: 32px;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: 3.2px;
        }
    }
    &-list {
        &--player {
            .rating-item__name {
                margin: 0 24px;
            }
        }
    }
    &-item {
        padding: 32px 24px;
        border-bottom: 4px solid rgba(241, 241, 241, 0.12);
        display: flex;
        align-items: center;
        &:last-child {
            border-bottom: none;
        }
        &--own {
            background: linear-gradient(90deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%);
        }
        &__name {
            flex-grow: 1;
            color: var(--white);
            font-size: 48px;
            font-weight: 400;
            line-height: 100%;
        }
        &__number {
            color: var(--white);
            text-align: right;
            font-size: 64px;
            font-weight: 500;
            line-height: 110%;
        }
        &__money {
            border-radius: 6px;
            background: var(--dark);
            padding: 7px 12px;
            color: var(--white);
            font-size: 48px;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: -0.96px;
            display: flex;
            align-items: center;
            &-dollar {
                color: var(--gold);
                margin-right: 4px;
            }
            &:last-child {
                margin-left: 12px;
            }
            &::v-deep {
                svg {
                    path {
                        transform: translateY(1px);
                    }
                }
            }
        }
        &__family {
            flex-grow: 1;
            &-top {
                display: flex;
                margin-bottom: 12px;
                align-items: center;
            }
            &-owner {
                color: var(--white);
                font-size: 32px;
                font-weight: 500;
                line-height: 100%;
                opacity: .4;
            }
        }
        &__text {
            margin-left: 24px;
            font-size: 64px;
            min-width: 200px;
            font-weight: 500;
            line-height: 110%;
            text-align: center;
            color: var(--white);
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                text-align: right;
            }
        }
    }
    &-flex {
        display: flex;
        align-items: center;
        .rating-header__text {
            margin-left: 24px;
            &:first-child {
                margin-left: 0;
            }
        }
    }
}
</style>
