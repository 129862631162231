var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-wrapper"},[(_vm.password)?[(_vm.label)?_c('p',{staticClass:"input-label"},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',{staticClass:"input-container"},[_c('input',{ref:"inputRef",staticClass:"input",class:{
                  'input--mini': _vm.mini,
                  'input--form': _vm.form,
                  'input--focused': _vm.focused
                },attrs:{"type":_vm.showPassword ? 'text' : 'password',"placeholder":_vm.placeholder,"name":_vm.name,"autocomplete":_vm.autocomplete},domProps:{"value":_vm.value},on:{"focus":_vm.onFocus,"blur":_vm.onBlur,"input":_vm.onInput,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onEnterPressed.apply(null, arguments)}}}),_c('div',{staticClass:"input__show-password",on:{"click":_vm.togglePassword}},[_c('IconEye',{attrs:{"icon":_vm.showPassword ? 'close' : 'open'}})],1)])]:[(_vm.label)?_c('p',{staticClass:"input-label"},[_vm._v(_vm._s(_vm.label))]):_vm._e(),(_vm.dollar)?_c('input',{directives:[{name:"mask",rawName:"v-mask",value:('$ ' + '############'),expression:"'$ ' + '############'"}],ref:"inputRef",staticClass:"input",class:{
              'input--mini': _vm.mini,
              'input--form': _vm.form,
              'input--focused': _vm.focused,
            },attrs:{"type":_vm.type,"placeholder":_vm.placeholder,"name":_vm.name,"autocomplete":_vm.autocomplete,"maxlength":_vm.maxLength,"readonly":_vm.readonly,"inputmode":_vm.$props.inputMode},domProps:{"value":_vm.value},on:{"focus":_vm.onFocus,"blur":_vm.onBlur,"input":_vm.onInput,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onEnterPressed.apply(null, arguments)}}}):_c('input',{ref:"inputRef",staticClass:"input",class:{
              'input--mini': _vm.mini,
              'input--form': _vm.form,
              'input--focused': _vm.focused,
            },attrs:{"type":_vm.type,"placeholder":_vm.placeholder,"name":_vm.name,"autocomplete":_vm.autocomplete,"maxlength":_vm.maxLength,"readonly":_vm.readonly,"inputmode":_vm.$props.inputMode},domProps:{"value":_vm.value},on:{"focus":_vm.onFocus,"blur":_vm.onBlur,"input":_vm.onInput,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onEnterPressed.apply(null, arguments)}}})],_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }