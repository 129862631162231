<template>
    <div class="auction">
        <AppHeader back-button>
            <template #title>
                {{ $t('auction.header') }}
            </template>
        </AppHeader>
        <div class="auction__content">
            <div class="auction__menu">
                <EstateMenu :items="menuItems" :activeItem="selectedItem.id" @change="selectItem" />
                <div class="auction__menu-info">
                    <div class="auction__menu-text">
                        {{ $t('auction.about') }}
                    </div>
                    <BaseButton yellow class="auction__menu-button" @click="exposeEstate">
                        <IconPlus />
                        {{ $t('auction.put_up') }}
                    </BaseButton>
                </div>
            </div>
            <div class="auction__info auction__info--house">
                <div v-for="(item, index) in filteredAuctionList" @click = "SelectLot(item)" :key="index" class="auction__info-item" :class="{'auction__info-item--has-bg': item.hasBackground}"
                     :style="item.hasBackground ? {background: `linear-gradient(to right, rgba(20, 26, 30, 0.7), rgba(20, 26, 30, 0.7)), url(${imageSrc(item.img, 'estate')}) no-repeat center/cover`} : {}">
                    <div v-if="item.type === 'miniBusiness'" class="auction__info-item--has-level">
                        <EstateLevel :level="item.level" height="7.1vw" width="7.1vw" />
                    </div>
                    <div class="auction__info-left">
                        <div class="auction__info-title">{{item.title}}</div>
                        <div v-if="item.type === 'house'" class="auction__info-badge">
                            <EstateHouseBadge :level="item.level" />
                        </div>
                        <div v-if="item.location" class="auction__info-price-text auction__info-price-text--no-center">{{item.location}}</div>
                    </div>
                    <div class="auction__info-right">
                        <AuctionPrice :price="item.price" />
                        <AuctionTimer :hour = "getHours(item)" :minute="getMinutes(item)" :second='getSeconds(item)' :text="$t('auction.time_for_bid')" />
                    </div>
                </div>
                <div class="auction__info-item auction__info-loading" v-if = "isLoading">
                    <VLoader />
                    <div class="auction__info-loading-text">{{ $t('auction.loading') }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import AppHeader from '@/components/AppHeader/AppHeader.vue';
import EstateMenu from '@/components/ui/EstateMenu.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import IconPlus from '@/components/icons/IconPlus.vue';
import EstateHouseBadge from '@/components/popups/content/estate/components/EstateHouseBadge.vue';
import EstateLevel from '@/components/ui/EstateLevel.vue';
import VLoader from '@/components/ui/VLoader.vue';
import AuctionPrice from '@/components/popups/content/auction/components/AuctionPrice.vue';
import AuctionTimer from '@/components/popups/content/auction/components/AuctionTimer.vue';

export default {
    name: 'MyAuction',
    components: { AuctionTimer, AuctionPrice, VLoader, EstateLevel, EstateHouseBadge, IconPlus, BaseButton, EstateMenu, AppHeader },
    data () {
        return {
            auctionIntervals: [undefined, undefined, undefined],
            estateType: 0,
            graphProgress: null,
            selectedType: 'business',
            selectedItem: null,
            miniBizLoaded: false,
            housesLoaded: false,
            isLoading: false,
            menuItems: [
                { id: 0, name: this.$t('auction.menu_items.business'), icon: 'business' },
                { id: 1, name: this.$t('auction.menu_items.mini_business'), icon: 'miniBusiness' },
                { id: 2, name: this.$t('auction.menu_items.houses'), icon: 'house' }
            ],
            auctionList: [
                /*
                {
                    id: 1,
                    type: 'house',
                    title: 'Helloworld 22/77',
                    level: 1,
                    price: 9310000,
                    img: 'house-2-lvl.png',
                    hasBackground: true
                },
                {
                    id: 2,
                    type: 'house',
                    title: 'Helloworld 22/77',
                    level: 1,
                    price: 9310000,
                    img: 'house-1-lvl.png',
                    hasBackground: true
                },
                {
                    id: 3,
                    type: 'house',
                    title: 'Helloworld 22/77',
                    level: 1,
                    price: 9310000,
                    img: 'house-3-lvl.png',
                    hasBackground: true
                },
                {
                    id: 1,
                    type: 'miniBusiness',
                    title: 'Gas station 20223 Gallon forever',
                    location: 'Vinewood 3/2',
                    price: 9310000,
                    img: 'mini-business-1-lvl.png',
                    hasBackground: true
                },
                {
                    id: 2,
                    type: 'miniBusiness',
                    title: 'Helloworld 22/77',
                    location: 'Vinewood 3/2',
                    price: 9310000,
                    img: 'mini-business-1-lvl.png',
                    hasBackground: true
                },
                {
                    id: 1,
                    type: 'business',
                    title: 'Gas station 20223 Gallon forever',
                    location: 'Vinewood 3/2',
                    level: 1,
                    price: 15239310000,
                    img: '',
                    hasBackground: false
                },
                {
                    id: 2,
                    type: 'business',
                    title: 'Gas station 20223 Gallon forever',
                    location: 'Vinewood 3/2',
                    level: 20,
                    price: 99910000,
                    img: 'business-3-lvl.png',
                    hasBackground: true
                }
                */
            ]
        };
    },

    created () {
        this.selectedItem = this.menuItems[0];
    },

    computed: {
        imageSrc () {
            return (img, folder) => {
                return require(`@/assets/images/${folder}/${img}`);
            };
        },
        filteredAuctionList () {
            if (!this.selectedType) {
                return this.auctionList;
            }
            return this.auctionList.filter(item => item.type === this.selectedType);
        }
    },
    beforeDestroy () {
        for (let i = 0; i < this.auctionIntervals.length; i++) {
            if (this.auctionIntervals[i] !== undefined) {
                clearInterval(this.auctionIntervals[i]);
                this.auctionIntervals[i] = undefined;
            }
        }
    },
    methods: {
        /*
        window.modifyVueComponent({
            App: {
                showPopup: true,
                popupContent: 'MyAuction',
                contentSubFolder: 'auction'
            },
            MyAuction: {
                initialize: [
                    [
                        {
                id: 1,
                type: 'business',
                title: 'Gas station 20223 Gallon forever',
                location: 'Vinewood 3/2',
                time:70,
                price: 15239310000,
                img: 'business-2-lvl.png',
                hasBackground: true
            },
            {
                id: 2,
                type: 'business',
                title: 'Gas station 20223 Gallon forever',
                location: 'Vinewood 3/2',
                price: 99910000,
                time:400,
                img: 'business-3-lvl.png',
                hasBackground: true
            }
                        ],
                ]
            }
        });
        */
        initialize (bizArray) {
            for (let i = 0; i < this.auctionIntervals.length; i++) {
                if (this.auctionIntervals[i] !== undefined) {
                    clearInterval(this.auctionIntervals[i]);
                    this.auctionIntervals[i] = undefined;
                }
            }
            this.auctionIntervals = [undefined, undefined, undefined];
            this.isLoading = false;
            this.auctionList = bizArray;
            this.miniBizLoaded = false;
            this.housesLoaded = false;
            this.auctionIntervals[0] = setInterval(() => {
                this.auctionList.forEach(item => {
                    if (item.time > 0)item.time--;
                    console.log(item.time);
                });
            }, 1000);
        },
        /*
        window.callVueMethod('MyAuction','updateAuctionList', [{
            id: 1,
            type: 'miniBusiness',
            title: 'Gas station 20223 Gallon forever',
            location: 'Vinewood 3/2',
            price: 9310000,
            img: 'mini-business-1-lvl.png',
            hasBackground: true
        },
        {
            id: 2,
            type: 'miniBusiness',
            title: 'Helloworld 22/77',
            location: 'Vinewood 3/2',
            price: 9310000,
            img: 'mini-business-1-lvl.png',
            hasBackground: true
        },],2);
        */
        updateAuctionList (array, type) {
            this.isLoading = false;
            if (type === 2) this.miniBizLoaded = true;
            else if (type === 3) this.housesLoaded = true;
            for (let i = 0; i < array.length; i++) {
                // console.log(array[i]);
                this.auctionList.push(array[i]);
            }
        },
        SelectLot (item) {
            let typeLot = 1;
            if (item.type === 'business') typeLot = 1;
            else if (item.type === 'miniBusiness') typeLot = 2;
            else if (item.type === 'house') typeLot = 3;
            this.sendclient('ClientOpenBusinessLot', JSON.stringify({ a: typeLot, b: item.id }));
        },
        selectItem (itemId) {
            if (this.isLoading) return;
            this.selectedItem = this.menuItems.find(item => item.id === itemId);
            if (this.selectedItem) {
                this.selectedType = this.selectedItem.icon;
            }
            if (!this.miniBizLoaded && itemId === 1) {
                this.sendclient('ClientAuctionLoadMiniBizes');
                this.isLoading = true;
            } else if (!this.housesLoaded && itemId === 2) {
                this.sendclient('ClientAuctionLoadHouses');
                this.isLoading = true;
            }
        },
        exposeEstate () {
            let typeLot = 1;
            if (this.selectedType === 'business') typeLot = 1;
            else if (this.selectedType === 'miniBusiness') typeLot = 2;
            else if (this.selectedType === 'house') typeLot = 3;
            this.sendclient('ClientExposeEstate', JSON.stringify({ a: typeLot }));
        },
        getHours (item) {
            const hours = Math.floor(item.time / 3600);
            const formattedHours = hours < 10 ? `0${hours}` : hours;
            return formattedHours.toString();
        },
        getMinutes (item) {
            const minutes = Math.floor(item.time / 60);
            // const remainingSeconds = seconds % 60;

            const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
            // const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
            return formattedMinutes.toString();
        },
        getSeconds (item) {
            // const minutes = Math.floor(item.time / 60);
            const remainingSeconds = item.time % 60;

            // const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
            const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
            return formattedSeconds.toString();
        }
    }
};
</script>
<style lang="scss">
.auction {
    height: 100%;
    background-image: url("@/assets/images/auction/bg.svg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    &__content {
        padding: 0 5vw 0;
        height: calc(100% - 5vw);
        display: grid;
        grid-template-columns: repeat(11, 1fr);
        grid-gap: 0 4vw;
    }
    &__info {
        grid-column: 8 span;
        padding-top: 4.35vw;
        &::-webkit-scrollbar {
            display: none;
        }
        &-item {
            height: 10.35vw;
            margin-bottom: .725vw;
            background: rgba(20, 26, 30, 0.5);
            border-radius: .5vw;
            display: flex;
            align-items: center;
            padding: 0 2.4vw;
            &:last-child {
                margin-bottom: 4.35vw;
            }
            &--has-bg {
                border: .2vw solid rgba(241, 241, 241, 0.4);
            }
            &--has-level {
                margin-right: 1.75vw;
                .auction__info-left {
                    margin-left: 1.75vw;
                    max-width: 22.3vw;
                }
                .auction__info-right {
                    margin-left: 1.75vw;
                }
            }
        }
        &-left {
            margin-right: auto;
            max-width: 31.2vw;
        }
        &-right {
            display: flex;
            margin-left: 1.75vw;
        }
        &-loading {
            height: 4.6vw;
            background: none;
            display: flex;
            justify-content: center;
            &-text {
                text-transform: uppercase;
                color: var(--white);
                margin-left: 1.25vw;
                font-weight: 900;
                font-size: 2.05vw;
                letter-spacing: 0.02em;
                line-height: 100%;
                font-family: var(--font-stretch);
            }
        }
        &-title {
            font-family: var(--font-stretch);
            font-weight: 900;
            font-size: 2.05vw;
            color: var(--white);
            text-transform: uppercase;
            letter-spacing: 0.02em;
            margin-bottom: .635vw;
        }
        &-badge {
            margin: 0;
            .estate__info-badge {
                margin: 0;
            }
        }
        &--house {
            overflow-y: scroll;
        }
    }
    &__menu {
        grid-column: 3 span;
        display: flex;
        flex-direction: column;
        padding-bottom: 4.35vw;
        padding-top: 4.35vw;
        &-info {
            display: flex;
            flex-direction: column;
            margin-top: auto;
        }
        &-text {
            margin-bottom: 1.75vw;
            color: var(--white);
            opacity: .6;
            font-size: 1.3vw;
            font-weight: 400;
            line-height: 140%;
        }
        &-button {
            font-size: 1.4vw;
            padding: 0 2vw;
            width: fit-content;
            height: 4.5vw;
            svg  {
                width: 1.6vw;
                height: auto;
                margin-right: .725vw;
                path {
                    fill: var(--dark);
                }
            }
        }
    }
}
</style>
