<template>
    <div class="skill-check">
        <div class="skill-check__wrapper" ref="wrapper">
            <div class="skill-check__green-zone" ref="greenZone"></div>
            <div class="skill-check__progress-line"></div>
            <div class="skill-check__progress"></div>
            <div class="skill-check__progress-runner" ref="runner">
                <img src="./assets/images/runner.svg" alt="">
            </div>

        </div>
        <button @click="checkProgress">TAP <br> when  <span>green line</span> in active</button>
    </div>
</template>
<script>
export default {
    name: 'SkillCheck',
    data () {
        return {
            direction: 1,
            animationFrameId: null,
            position: 0,
            successZoneStart: 600,
            successZoneEnd: 980
        };
    },
    mounted () {
        this.$nextTick(() => {
            this.animate();
            this.setSuccessZone();
        });
    },
    methods: {
        animate () {
            this.animationFrameId = requestAnimationFrame(this.updateProgress);
        },
        updateProgress () {
            if (!this.$refs.runner || !this.$refs.wrapper) {
                console.warn('One or both refs are not available');
                return;
            }
            const runnerWidth = this.$refs.runner.offsetWidth;
            const wrapperWidth = this.$refs.wrapper.offsetWidth;

            if (this.position > wrapperWidth - runnerWidth || this.position < 0) {
                this.direction *= -1;
            }

            this.position += this.direction * 5;
            this.$refs.runner.style.transform = `translateX(${this.position}px)`;
            this.animate();
        },
        setSuccessZone () {
            const greenZone = this.$refs.greenZone.getBoundingClientRect();
            const wrapper = this.$refs.wrapper.getBoundingClientRect();

            this.successZoneStart = greenZone.left - wrapper.left;
            this.successZoneEnd = this.successZoneStart + greenZone.width;
        },
        checkProgress () {
            const runnerWidth = this.$refs.runner.offsetWidth;
            // удалить после тестов
            console.log('Позиция:', this.position);
            console.log('Ширина раннера:', runnerWidth);
            if (this.position >= this.successZoneStart && this.position + runnerWidth <= this.successZoneEnd) {
                this.sendclient('skillCheckProcess', JSON.stringify({ a: 1 }));
            } else {
                this.sendclient('skillCheckProcess', JSON.stringify({ a: 0 }));
            }
        }
    },
    beforeDestroy () {
        if (this.animationFrameId) {
            cancelAnimationFrame(this.animationFrameId);
        }
    }

};
</script>

<style lang="scss">
.skill-check {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: radial-gradient(46.15% 100% at 50.06% 100%, rgba(32, 47, 58, 0.38) 0%, rgba(20, 24, 28, 0.48) 100%);
    &__wrapper {
        position: relative;
        width: 1800px;
        height: 62px;
    }
    &__green-zone {
        position: absolute;
        background: url(./assets/images/green-zone.svg) no-repeat center / contain;
        width: 380px;
        height: 50px;
        left: 600px;
        z-index: 1;
    }
    &__progress-line {
        position: absolute;
        width: 1800px;
        height: 62px;
        top: 50%;
    }
    &__progress {
        background: url(./assets/images/progress.svg) no-repeat center/contain;
        position: absolute;
        width: 1800px;
        height: 62px;
        top: 0;
        left: 0;
    }
    &__progress-runner {
        position: absolute;

        transition: transform 0.016s ease-out;
        z-index: 2;
        top: -50%;
        img{
            height: 117px;
            width: 31px;
        }
    }
    button {
        width: 370px;
        height: 446.005px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 30px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: url(./assets/images/button.svg) no-repeat center/cover;

        color: #F1F1F1;
        text-align: center;
        font-size: 46px;
        font-weight: 700;
        line-height: 120%; /* 55.2px */
        letter-spacing: 1.84px;
        text-transform: uppercase;

        span {
            color: #9FD035;
        }
    }
}
</style>
