<template>
    <div class="clothes">
        <AppHeader back-button>
            <template #title>{{title}}</template>
        </AppHeader>
        <div class="clothes__content">
            <div class="clothes__list">
                <transition-group name="slide" tag="ul" class="clothes__menu">
                    <li
                        class="clothes__menu-item"
                        v-for="item in menuItems"
                        :key="item.id"
                        v-show="!showSubmenu"
                        :class="{ 'clothes__menu-item--active': activeItem === item.name }"
                        @click="selectMenuItem(item.id, item.name, item.submenu, item)"
                    >
                        <span>{{ item.name }} <IconDots v-if="item.submenu" /></span>
                    </li>

                    <li
                        class="clothes__menu-item clothes__menu-item--sub"
                        v-if="showSubmenu"
                        :key="submenuParent"
                    >
            <span
                class="clothes__menu-item clothes__menu-item--sub-active"
                @click="CloseSubMenu()"
            >{{ submenuParent }}</span>
                        <ul class="clothes__menu">
                            <li
                                class="clothes__menu-item"
                                v-for="item in showSubmenu"
                                :key="'submenu-' + item.id"
                                :class="{ 'clothes__menu-item--active': activeItem === item.name }"
                                @click="selectSubmenuItem(item)"
                            >{{ item.name }}</li>
                        </ul>
                    </li>
                </transition-group>
            </div>
            <div class="clothes__nav">
                <transition name="slide-reverse">
                    <div class="clothes__buttons" v-if="!colorsShowed.type">
                        <button
                            class="clothes__button clothes__button--left"
                            @click="selectPreviousItem"
                        >
                            <IconPrevWhite />
                        </button>
                        <button
                            class="clothes__button clothes__button--right"
                            @click="selectNextItem"
                        >
                            <IconNextWhite />
                        </button>
                    </div>
                </transition>
                <transition name="slide-reverse">
                    <div class="clothes__items" v-if="colorsShowed.type === 2">
                        <div
                            v-for="(item, index) in buttons"
                            class="clothes__items-el"
                            :class="{'clothes__items-el--active' : selectedButton === item}"
                            :key="index"
                            @click="selectButton(item)"
                        >
                            {{ item.text }}
                        </div>
                    </div>
                </transition>
                <transition name="slide-reverse">
                    <div class="clothes__palette" v-if="colorsShowed.type === 1">
                        <div
                            v-for="(item, index) in activePalette ? activePalette : colors"
                            :key="item.id"
                            class="clothes__palette-item"
                            :style="{
                            borderColor: activeColorId === item.id ? 'white' : 'transparent',
                            background: item.hex,
                            transform: [4,5,6,7].includes(index) ?
                            'translateX(1.972vw)' :
                            [8,9,10,11].includes(index) &&
                            'translateX(3.943vw)'
                        }"
                            @click="!item.palette ? selectColor(item) : openPalette(item)"
                        >
                            <IconSelectedColor v-if="!activePalette && rootColor === item.id" />
                        </div>
                        <transition name="slide">
                            <button v-if="this.activePalette" class="clothes__palette-button" @click="closePalette">
                                <IconClosePalette />
                            </button>
                        </transition>
                    </div>
                </transition>
                <div class="clothes__info">
                    <transition name="fade">
                        <div class="clothes__about" v-if="activeItemPrice">
                            <div class="clothes__text">{{ $t('clothes.price') }}</div>
                            <div class="clothes__price">
                                <span class="clothes__price-dollar">$</span>{{ $formatNum(activeItemPrice) }}
                            </div>
                        </div>
                    </transition>
                    <button class="clothes__submit clothes__submit--save" v-if="!isShop" @click="handleItemsSave">{{ $t('clothes.save') }}</button>
                    <button class="clothes__submit clothes__submit--buy" v-if="isShop" @click="handleItemsBuy">{{ $t('clothes.buy') }}</button>
                </div>
            </div>
            <div class="camera" ref="camera"></div>

        </div>
    </div>
</template>

<script>
import AppHeader from '@/components/AppHeader/AppHeader.vue';
import IconNextWhite from '@/components/icons/IconNextWhite.vue';
import IconPrevWhite from '@/components/icons/IconPrevWhite.vue';
import IconDots from '@/components/icons/IconDots.vue';
import IconSelectedColor from '@/components/popups/content/character/icons/IconSelectedColor.vue';
import IconClosePalette from '@/components/popups/content/character/icons/IconClosePalette.vue';
import Hammer from 'hammerjs';

export default {
    name: 'MyClothes',
    components: { IconClosePalette, IconSelectedColor, IconDots, IconPrevWhite, IconNextWhite, AppHeader },
    mounted () {
        this.initCameraJoystick();
    },
    data () {
        return {
            isShop: false,
            // stablePrice: 0,

            title: '',
            type: 1,
            typeSelected: 0,
            isUnisex: false,

            xAxis: 0,
            yAxis: 0,
            CameraDeltaY: 0,
            CameraDeltaX: 0,

            colorsShowed: {
                type: 2 // 1 - палитра, 2 - новые кнопки, можно передать 0 - ничего
            },
            showSubmenu: null,
            activeItem: null,
            activeItemPrice: null,
            menuItems: [
                /*
                { id: 1, name: 'Headwear', price: 500, submenu: null },
                { id: 2, name: 'Accessories', price: 500, submenu: null },
                { id: 3, name: 'Shoes', submenu: [{ name: 'Овощи', price: 300 }] },
                { id: 4, name: 'Favorite', submenu: [{ id: 4.1, name: 'Тяги', price: 250 }, { id: 4.2, name: 'Кросы', price: 300 }] },
                { id: 5, name: 'HeadwearS', price: 10000, submenu: null }
                 */
            ],
            colors: [
                {
                    id: 1,
                    hex: 'rgba(241, 241, 241, 1)',
                    isMain: 1
                },
                {
                    id: 2,
                    hex: 'rgba(0, 0, 0, 1)',
                    isMain: 1
                },
                {
                    id: 3,
                    hex: 'rgba(140, 140, 140, 1)',
                    isMain: 1
                },
                {
                    id: 14,
                    hex: 'rgba(235, 255, 0, 1)',
                    isMain: 1,
                    palette: [
                        {
                            id: 4,
                            hex: 'rgba(137, 133, 55, 1)'
                        },
                        {
                            id: 5,
                            hex: 'rgba(255, 252, 198, 1)'
                        },
                        {
                            id: 6,
                            hex: 'rgba(255, 249, 141, 1)'
                        },
                        {
                            id: 14,
                            hex: 'rgba(235, 255, 0, 1)'
                        },
                        {
                            id: 7,
                            hex: 'rgba(226, 217, 49, 1)'
                        },
                        {
                            id: 8,
                            hex: 'rgba(119, 113, 0, 1)'
                        },
                        {
                            id: 9,
                            hex: 'rgba(117, 136, 0, 1)'
                        },
                        {
                            id: 10,
                            hex: 'rgba(184, 214, 0, 1)'
                        },
                        {
                            id: 11,
                            hex: 'rgba(255, 190, 21, 1)'
                        },
                        {
                            id: 12,
                            hex: 'rgba(53, 51, 6, 1)'
                        },
                        {
                            id: 13,
                            hex: 'rgba(37, 36, 21, 1)'
                        },
                        {
                            id: 15,
                            hex: 'rgba(255, 199, 0, 1)'
                        }
                    ]
                },
                {
                    id: 20,
                    hex: 'rgba(236, 150, 50, 1)',
                    isMain: 1,
                    palette: [
                        {
                            id: 16,
                            hex: 'rgba(255, 189, 18, 1)'
                        },
                        {
                            id: 17,
                            hex: 'rgba(241, 176, 99, 1)'
                        },
                        {
                            id: 18,
                            hex: 'rgba(204, 111, 0, 1)'
                        },
                        {
                            id: 19,
                            hex: 'rgba(92, 50, 0, 1)'
                        },
                        {
                            id: 20,
                            hex: 'rgba(236, 150, 50, 1)'
                        },
                        {
                            id: 21,
                            hex: 'rgba(174, 153, 128, 1)'
                        },
                        {
                            id: 22,
                            hex: 'rgba(126, 102, 74, 1)'
                        },
                        {
                            id: 23,
                            hex: 'rgba(137, 85, 24, 1)'
                        },
                        {
                            id: 24,
                            hex: 'rgba(255, 92, 0, 1)'
                        },
                        {
                            id: 25,
                            hex: 'rgba(255, 124, 50, 1)'
                        },
                        {
                            id: 26,
                            hex: 'rgba(255, 155, 99, 1)'
                        },
                        {
                            id: 27,
                            hex: 'rgba(255, 197, 165, 1)'
                        }
                    ]
                },
                {
                    id: 33,
                    hex: 'rgba(192, 62, 59, 1)',
                    isMain: 1,
                    palette: [
                        {
                            id: 28,
                            hex: 'rgba(255, 193, 191, 1)'
                        },
                        {
                            id: 29,
                            hex: 'rgba(255, 121, 118, 1)'
                        },
                        {
                            id: 30,
                            hex: 'rgba(255, 72, 72, 1)'
                        },
                        {
                            id: 31,
                            hex: 'rgba(255, 32, 28, 1)'
                        },
                        {
                            id: 32,
                            hex: 'rgba(252, 64, 60, 1)'
                        },
                        {
                            id: 33,
                            hex: 'rgba(192, 62, 59, 1)'
                        },
                        {
                            id: 34,
                            hex: 'rgba(201, 36, 33, 1)'
                        },
                        {
                            id: 35,
                            hex: 'rgba(122, 15, 13, 1)'
                        },
                        {
                            id: 36,
                            hex: 'rgba(107, 70, 69, 1)'
                        },
                        {
                            id: 37,
                            hex: 'rgba(83, 31, 30, 1)'
                        },
                        {
                            id: 38,
                            hex: 'rgba(53, 24, 23, 1)'
                        },
                        {
                            id: 39,
                            hex: 'rgba(38, 7, 6, 1)'
                        }
                    ]
                },
                {
                    id: 46,
                    hex: 'rgba(200, 53, 146, 1)',
                    isMain: 1,
                    palette: [
                        {
                            id: 40,
                            hex: 'rgba(255, 193, 232, 1)'
                        },
                        {
                            id: 41,
                            hex: 'rgba(255, 144, 214, 1)'
                        },
                        {
                            id: 42,
                            hex: 'rgba(255, 124, 207, 1)'
                        },
                        {
                            id: 43,
                            hex: 'rgba(255, 72, 188, 1)'
                        },
                        {
                            id: 44,
                            hex: 'rgba(255, 0, 168, 1)'
                        },
                        {
                            id: 45,
                            hex: 'rgba(171, 0, 108, 1)'
                        },
                        {
                            id: 46,
                            hex: 'rgba(200, 53, 146, 1)'
                        },
                        {
                            id: 47,
                            hex: 'rgba(103, 25, 74, 1)'
                        },
                        {
                            id: 48,
                            hex: 'rgba(89, 0, 56, 1)'
                        },
                        {
                            id: 49,
                            hex: 'rgba(55, 0, 35, 1)'
                        },
                        {
                            id: 50,
                            hex: 'rgba(61, 29, 49, 1)'
                        },
                        {
                            id: 51,
                            hex: 'rgba(26, 6, 18, 1)'
                        }
                    ]
                },
                {
                    id: 59,
                    hex: 'rgba(129, 63, 182, 1)',
                    isMain: 1,
                    palette: [
                        {
                            id: 52,
                            hex: 'rgba(41, 6, 98, 1)'
                        },
                        {
                            id: 53,
                            hex: 'rgba(56, 23, 111, 1)'
                        },
                        {
                            id: 54,
                            hex: 'rgba(57, 0, 149, 1)'
                        },
                        {
                            id: 55,
                            hex: 'rgba(89, 23, 228, 1)'
                        },
                        {
                            id: 56,
                            hex: 'rgba(141, 0, 255, 1)'
                        },
                        {
                            id: 57,
                            hex: 'rgba(193, 115, 255, 1)'
                        },
                        {
                            id: 58,
                            hex: 'rgba(160, 43, 255, 1)'
                        },
                        {
                            id: 59,
                            hex: 'rgba(129, 63, 182, 1)'
                        },
                        {
                            id: 60,
                            hex: 'rgba(90, 36, 133, 1)'
                        },
                        {
                            id: 61,
                            hex: 'rgba(61, 24, 90, 1)'
                        },
                        {
                            id: 62,
                            hex: 'rgba(49, 18, 73, 1)'
                        },
                        {
                            id: 63,
                            hex: 'rgba(23, 5, 38, 1)'
                        }
                    ]
                },
                {
                    id: 72,
                    hex: 'rgba(67, 92, 219, 1)',
                    isMain: 1,
                    palette: [
                        {
                            id: 64,
                            hex: 'rgba(129, 142, 207, 1)'
                        },
                        {
                            id: 65,
                            hex: 'rgba(101, 109, 151, 1)'
                        },
                        {
                            id: 66,
                            hex: 'rgba(71, 84, 150, 1)'
                        },
                        {
                            id: 67,
                            hex: 'rgba(40, 56, 142, 1)'
                        },
                        {
                            id: 68,
                            hex: 'rgba(190, 201, 255, 1)'
                        },
                        {
                            id: 69,
                            hex: 'rgba(120, 142, 255, 1)'
                        },
                        {
                            id: 70,
                            hex: 'rgba(52, 85, 255, 1)'
                        },
                        {
                            id: 71,
                            hex: 'rgba(0, 41, 255, 1)'
                        },
                        {
                            id: 72,
                            hex: 'rgba(67, 92, 219, 1)'
                        },
                        {
                            id: 73,
                            hex: 'rgba(38, 54, 134, 1)'
                        },
                        {
                            id: 74,
                            hex: 'rgba(16, 24, 64, 1)'
                        },
                        {
                            id: 75,
                            hex: 'rgba(6, 10, 35, 1)'
                        }
                    ]
                },
                {
                    id: 85,
                    hex: 'rgba(53, 179, 207, 1)',
                    isMain: 1,
                    palette: [
                        {
                            id: 76,
                            hex: 'rgba(0, 52, 40, 1)'
                        },
                        {
                            id: 77,
                            hex: 'rgba(27, 53, 47, 1)'
                        },
                        {
                            id: 78,
                            hex: 'rgba(17, 112, 89, 1)'
                        },
                        {
                            id: 79,
                            hex: 'rgba(53, 207, 170, 1)'
                        },
                        {
                            id: 80,
                            hex: 'rgba(5, 33, 39, 1)'
                        },
                        {
                            id: 81,
                            hex: 'rgba(46, 61, 64, 1)'
                        },
                        {
                            id: 82,
                            hex: 'rgba(16, 69, 81, 1)'
                        },
                        {
                            id: 83,
                            hex: 'rgba(68, 138, 153, 1)'
                        },
                        {
                            id: 84,
                            hex: 'rgba(25, 115, 135, 1)'
                        },
                        {
                            id: 85,
                            hex: 'rgba(53, 179, 207, 1)'
                        },
                        {
                            id: 86,
                            hex: 'rgba(0, 209, 255, 1)'
                        },
                        {
                            id: 87,
                            hex: 'rgba(118, 230, 255, 1)'
                        }
                    ]
                },
                {
                    id: 98,
                    hex: 'rgba(76, 180, 59, 1)',
                    isMain: 1,
                    palette: [
                        {
                            id: 88,
                            hex: 'rgba(53, 86, 0, 1)'
                        },
                        {
                            id: 89,
                            hex: 'rgba(0, 72, 55, 1)'
                        },
                        {
                            id: 90,
                            hex: 'rgba(43, 208, 99, 1)'
                        },
                        {
                            id: 91,
                            hex: 'rgba(39, 255, 139, 1)'
                        },
                        {
                            id: 92,
                            hex: 'rgba(184, 255, 32, 1)'
                        },
                        {
                            id: 93,
                            hex: 'rgba(32, 149, 71, 1)'
                        },
                        {
                            id: 94,
                            hex: 'rgba(51, 84, 46, 1)'
                        },
                        {
                            id: 95,
                            hex: 'rgba(14, 21, 12, 1)'
                        },
                        {
                            id: 96,
                            hex: 'rgba(151, 255, 134, 1)'
                        },
                        {
                            id: 97,
                            hex: 'rgba(75, 255, 46, 1)'
                        },
                        {
                            id: 98,
                            hex: 'rgba(76, 180, 59, 1)'
                        },
                        {
                            id: 99,
                            hex: 'rgba(10, 69, 0, 1)'
                        }
                    ]
                },
                {
                    id: 111,
                    hex: 'rgba(140, 102, 74, 1)',
                    isMain: 1,
                    palette: [
                        {
                            id: 100,
                            hex: 'rgba(107, 45, 0, 1)'
                        },
                        {
                            id: 101,
                            hex: 'rgba(61, 25, 0, 1)'
                        },
                        {
                            id: 102,
                            hex: 'rgba(151, 89, 43, 1)'
                        },
                        {
                            id: 103,
                            hex: 'rgba(98, 57, 28, 1)'
                        },
                        {
                            id: 104,
                            hex: 'rgba(74, 71, 68, 1)'
                        },
                        {
                            id: 105,
                            hex: 'rgba(69, 58, 49, 1)'
                        },
                        {
                            id: 106,
                            hex: 'rgba(129, 110, 96, 1)'
                        },
                        {
                            id: 107,
                            hex: 'rgba(192, 138, 99, 1)'
                        },
                        {
                            id: 108,
                            hex: 'rgba(22, 17, 14, 1)'
                        },
                        {
                            id: 109,
                            hex: 'rgba(35, 22, 13, 1)'
                        },
                        {
                            id: 110,
                            hex: 'rgba(74, 54, 38, 1)'
                        },
                        {
                            id: 111,
                            hex: 'rgba(140, 102, 74, 1)'
                        }
                    ]
                }
            ],
            buttons: [
                {
                    text: 'матовый'
                },
                {
                    text: 'хром'
                },
                {
                    text: 'глянец'
                },
                {
                    text: 'металлик'
                }
            ],
            activeColorId: null,
            activePalette: null,
            selectedColorId: null, // для отправки на бэк
            rootColor: null,
            selectedButton: null
        };
    },
    /*
    computed: {
        isFirstMenuItem () {
            const currentIndex = this.menuItems.findIndex(item => item.name === this.activeItem);
            return currentIndex === 0;
        },
        isLastMenuItem () {
            const currentIndex = this.menuItems.findIndex(item => item.name === this.activeItem);
            return currentIndex === this.menuItems.length - 1;
        }
    },
    */
    methods: {
        initCameraJoystick () {
            const camera = this.$refs.camera;
            const hammer = new Hammer(camera);
            hammer.get('pan').set({ direction: Hammer.DIRECTION_ALL });

            const verticalSpeedCoefficient = 2; // коэффициент скорости
            let isPanning = false;

            const handlePan = this.throttle((e) => {
                if (!isPanning) return;

                const rect = camera.getBoundingClientRect();

                const CameraDeltaX = parseFloat(
                    (
                        Math.max(-1, Math.min(e.deltaX / (rect.width / 2), 1))
                    ).toFixed(3)
                );

                const CameraDeltaY = parseFloat(
                    (
                        Math.max(-1, Math.min(e.deltaY / (rect.height / 2 * verticalSpeedCoefficient), 1))
                    ).toFixed(3)
                );

                console.log('Camera deltaX:', CameraDeltaX, 'deltaY:', CameraDeltaY);
                this.sendclient('cameraRight', JSON.stringify({ value: CameraDeltaX }));
                this.sendclient('cameraForward', JSON.stringify({ value: CameraDeltaY }));
            }, 200);

            hammer.on('panstart', (e) => {
                isPanning = true;
                handlePan(e);
            });

            hammer.on('panmove', handlePan);

            hammer.on('panend', () => {
                isPanning = false;

                this.CameraDeltaX = 0;
                this.CameraDeltaY = 0;

                console.log('Camera deltaX:', this.CameraDeltaX, 'deltaY:', this.CameraDeltaY);
                this.sendclient('cameraRight', JSON.stringify({ value: this.CameraDeltaX }));
                this.sendclient('cameraForward', JSON.stringify({ value: this.CameraDeltaY }));
            });
        },
        initialize (menuItems, title, type, isShop) {
            this.menuItems = menuItems;
            this.title = title;
            this.type = type;
            this.isShop = isShop;// price !== 0;

            this.showSubmenu = null;
            this.activeItem = null;
            this.activeItemPrice = null;

            this.SelectFirstMenu();
            // if (price > 0) this.stablePrice = price;
        },
        /*
        window.modifyVueComponent({
            App: {
                showPopup: true,
                popupContent: 'MyClothes',
                contentSubFolder: 'character'
            },
            MyClothes: {
                initialize: [
                    [
                        { id: 1, name: 'Headwear', price: 500 },
                        { id: 2, name: 'Accessories', price: 600 },
                    ],
                    'Магазин одежды',
                    1,
                    true
                ]
            }
        });
        */
        /*
        window.modifyVueComponent({
            App: {
                showPopup: true,
                popupContent: 'MyClothes',
                contentSubFolder: 'character'
            },
            MyClothes: {
                initialize: [
                    [
                        {"id":4,"name":"Ширина щек"},
                        {"id":100,"name":"Нос","submenu":[{"id":1,"name":"Кончик носа"},{"id":2,"name":"Горбинка носа"},{"id":3,"name":"Ширина носа"},{"id":15,"name":"Кривость носа"}]},
                        {"id":5,"name":"Уши"},
                        {"id":6,"name":"Подбородок"},
                        {"id":7,"name":"Лоб"},
                        {"id":101,"name":"Губы","submenu":[{"id":8,"name":"Верхняя губа"},{"id":9,"name":"Нижняя губа"}]},
                        {"id":10,"name":"Скулы"},
                        {"id":102,"name":"Глаза","submenu":[{"id":11,"name":"Ширина глаз"},{"id":12,"name":"Высота глаз"},{"id":13,"name":"Прищур глаз"},{"id":17,"name":"Разрез глаз"},{"id":50,"name":"Цвет глаз",isColor:true}]},
                        {"id":14,"name":"Высота бровей"},
                        {"id":16,"name":"Рот"},
                        {"id":18,"name":"Улыбка"},
                        ],
                    "Внешность",
                    3,
                    false
                ]
            }
        });
        */
        selectMenuItem (id, name, submenu, item) {
            if (this.type === 1) this.sendclient('BarberType', JSON.stringify({ a: id }));
            else if (this.type !== 1) {
                if (this.type === 4) this.sendclient('TuningType', JSON.stringify({ a: id }));
                this.typeSelected = id;
            }

            if (item.isColor) this.colorsShowed.type = 1;
            else if (item.uniquePoolItems) {
                for (let i = 0; i < item.uniquePoolItems.length; i++) {
                    this.buttons[i].text = item.uniquePoolItems[i];
                    this.buttons[i].index = i + 1;
                }
                this.selectedButton = null;
                this.colorsShowed.type = 2;
            } else this.colorsShowed.type = 0;

            if (item.unisex) this.isUnisex = true;
            else this.isUnisex = false;

            this.activeItem = name;
            this.activeItemPrice = submenu ? null : this.getItemPrice(name);
            if (submenu) {
                this.showSubmenu = submenu;
                this.submenuParent = name;
            }

            if (submenu) {
                this.selectMenuItem(submenu[0].id, submenu[0].name, submenu[0].submenu, submenu[0]);
            }
        },
        selectSubmenuItem (item) {
            if (this.type === 1) this.sendclient('BarberType', JSON.stringify({ a: item.id }));
            else if (this.type !== 1) {
                if (this.type === 4) this.sendclient('TuningType', JSON.stringify({ a: item.id }));
                this.typeSelected = item.id;
            }
            if (item.isColor) this.colorsShowed.type = 1;
            else if (item.uniquePoolItems) {
                for (let i = 0; i < item.uniquePoolItems.length; i++) {
                    this.buttons[i].text = item.uniquePoolItems[i];
                    this.buttons[i].index = i + 1;
                }
                this.selectedButton = null;
                this.colorsShowed.type = 2;
            } else this.colorsShowed.type = 0;

            this.typeSelected = item.id;
            this.activeItem = item.name;
            this.activeItemPrice = item.price;
        },
        SelectFirstMenu () {
            if (this.menuItems && this.menuItems.length) {
                this.selectMenuItem(this.menuItems[0].id, this.menuItems[0].name, this.menuItems[0].submenu, this.menuItems[0]);
            }
        },
        CloseSubMenu () {
            this.showSubmenu = null;
            this.SelectFirstMenu();
        },
        getItemPrice (name) {
            return this.menuItems.find(item => item.name === name)?.price;
        },
        getSubmenuItemPrice (submenuName, itemName) {
            return this.menuItems.find(item => item.name === submenuName)?.submenu?.find(subitem => subitem.name === itemName)?.price;
        },
        handleItemsSave () {
            if (this.type === 2) this.sendclient('memberWardrobeFinish');
            else if (this.type === 3) this.sendclient('CharAppearenceFinish');
        },
        handleItemsBuy () {
            if (this.type === 1) this.sendclient('BarberBuy');
            else if (this.type === 4) this.sendclient('TuningBuy');
            else if (this.type === 5) this.sendclient('SurgeryFinish');
        },
        selectPreviousItem () {
            if (this.type === 1) this.sendclient('BarberList', JSON.stringify({ a: 0 }));
            else if (this.type === 2) {
                this.sendclient('MemberList', JSON.stringify({ a: this.typeSelected, b: 0, c: this.isUnisex }));
            } else if (this.type === 3 || this.type === 5) {
                this.sendclient('CharAppearenceList', JSON.stringify({ a: this.typeSelected, b: 0 }));
            } else if (this.type === 4) {
                this.sendclient('TuningList', JSON.stringify({ a: this.typeSelected, b: 0 }));
            }
            /*
            if (this.showSubmenu) {
                const currentIndex = this.showSubmenu.findIndex(item => item.name === this.activeItem);
                if (currentIndex > 0) {
                    this.activeItem = this.showSubmenu[currentIndex - 1].name;
                    this.activeItemPrice = this.showSubmenu[currentIndex - 1].price;
                }
            } else {
                const currentIndex = this.menuItems.findIndex(item => item.name === this.activeItem);
                if (currentIndex > 0) {
                    this.activeItem = this.menuItems[currentIndex - 1].name;
                    this.activeItemPrice = this.menuItems[currentIndex - 1].price;
                }
            }
            */
        },
        selectNextItem () {
            if (this.type === 1) this.sendclient('BarberList', JSON.stringify({ a: 1 }));
            else if (this.type === 2) {
                this.sendclient('MemberList', JSON.stringify({ a: this.typeSelected, b: 1, c: this.isUnisex }));
            } else if (this.type === 3 || this.type === 5) {
                this.sendclient('CharAppearenceList', JSON.stringify({ a: this.typeSelected, b: 1 }));
            } else if (this.type === 4) {
                this.sendclient('TuningList', JSON.stringify({ a: this.typeSelected, b: 1 }));
            }
            /*
            if (this.showSubmenu) {
                const currentIndex = this.showSubmenu.findIndex(item => item.name === this.activeItem);
                if (currentIndex < this.showSubmenu.length - 1) {
                    this.activeItem = this.showSubmenu[currentIndex + 1].name;
                    this.activeItemPrice = this.showSubmenu[currentIndex + 1].price;
                }
            } else {
                const currentIndex = this.menuItems.findIndex(item => item.name === this.activeItem);
                if (currentIndex < this.menuItems.length - 1) {
                    this.activeItem = this.menuItems[currentIndex + 1].name;
                    this.activeItemPrice = this.menuItems[currentIndex + 1].price;
                }
            }
            */
        },
        selectColor (item) {
            this.activeColorId = item.id;
            if (item.isMain === 1) {
                this.rootColor = item.id;
                this.selectedColorId = item.id;
            }
            this.selectedColorId = item.id;
            if (this.type === 1) this.sendclient('BarberColor', JSON.stringify({ a: this.selectedColorId }));
            else if (this.type === 3 || this.type === 5) {
                this.sendclient('CharAppearenceColor', JSON.stringify({ a: this.typeSelected, b: this.selectedColorId }));
            } else if (this.type === 4) {
                this.sendclient('TuningColor', JSON.stringify({ a: this.typeSelected, b: this.selectedColorId }));
            }
            // console.log('Выбрали цвет', this.selectedColorId);
        },
        openPalette (item) {
            this.selectColor(item);
            this.activePalette = item.palette;
        },
        closePalette () {
            this.activePalette = null;
            this.activeColorId = this.rootColor;
        },
        selectButton (item) {
            if (this.type === 4) {
                this.sendclient('TuningColorType', JSON.stringify({ a: this.typeSelected, b: item.index }));
            }
            this.selectedButton = item;
        }
    }
};
</script>

<style lang="scss" scoped>
.clothes {
    height: 100%;
    background: radial-gradient(50% 104.63% at 50% 50%, rgba(20, 26, 30, 0) 0%, rgba(20, 26, 30, 0.3) 100%), linear-gradient(180deg, rgba(20, 26, 30, 0) 76.04%, rgba(20, 26, 30, 0.45) 100%);;
    &__content {
        height: calc(100% - 5vw);
        padding-left: 7.1vw;
        padding-right: 6.35vw;
        display: flex;
        justify-content: space-between;
    }
    &__list {
        overflow-y: auto;
        margin: auto 0;
        max-height: 100%;
        position: relative;
        z-index: 1;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    &__menu {
        width: 21.7vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &-item {
            color: var(--white);
            font-weight: 700;
            font-size: 1.9vw;
            padding: 1.35vw;
            letter-spacing: .1vw;
            text-transform: uppercase;
            opacity: 0.7;
            position: relative;
            width: 90%;
            transition: width .2s ease-out;
            span{
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                svg{
                    height: 2vw;
                    width: auto;
                }
            }
            &::before {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
                height: .16vw;
                background-color: var(--white);
            }
            &--active {
                width: 100%;
                opacity: 1;
                &::before {
                    height: .48vw;
                }
            }
            &--sub {
                width: 100%;
                opacity: 1;
                padding: 0;
                &::before {
                    display: none;
                }
                &-active {
                    opacity: 1;
                    width: 100%;
                    padding-left: 3.55vw;
                    background-color: var(--white);
                    color: var(--dark);
                    background-image: url("@/assets/images/character/black-arrow.svg");
                    background-position: left 1.35vw center;
                    background-repeat: no-repeat;
                    background-size: 1.9vw;
                }
            }
        }
    }
    &__nav {
        padding: 5vw 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        z-index: 1;
    }
    &__buttons {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0 1.74vw;
        max-width: fit-content;
        align-self: flex-end;
    }
    &__button {
        opacity: 1;
        width: 10.6vw;
        background-color: transparent;
        height: 7.7vw;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        &:active {
            opacity: .7;
        }
        svg {
            width: 4.2vw;
            height: auto;
        }
        &--left {
            background-image: url("@/assets/images/character/left-arrow-bg.svg");
        }
        &--right {
            background-image: url("@/assets/images/character/right-arrow-bg.svg");
        }
    }
    &__info {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        margin-top: auto;
    }
    &__about {
        margin-right: 2.55vw;
    }
    &__text {
        font-size: 2vw;
        font-weight: 400;
        color: var(--white);
        opacity: 0.66;
        margin-bottom: .65vw;
    }
    &__price {
        color: var(--white);
        font-size: 2.55vw;
        font-weight: 700;
        text-align: right;
        &-dollar {
            color: var(--gold);
        }
    }
    &__submit {
        color: var(--dark);
        font-size: 1.9vw;
        padding: 1.75vw 2.55vw 1.75vw 5.7vw;
        background-repeat: no-repeat;
        background-position: left 2.5vw center;
        font-weight: 500;
        background-size: 2.5vw;
        &:active {
            background-color: #D1D1D1;
        }
        &--save {
            background-image: url("@/assets/images/character/button-save.svg");
        }
        &--buy {
            background-image: url("@/assets/images/character/button-buy.svg");
        }
    }
    &__items {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        position: absolute;
        bottom: 257px;
        right: 0;
        width: 500px;
        margin-bottom: 82px;
        &-el {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 18px;
            color: var(--white);
            font-size: 32px;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: 1.28px;
            text-transform: uppercase;
            border-radius: 300px;
            border: 4px solid var(--white);
            margin-left: 18px;
            padding: 0 32px;
            height: 78px;
            &:first-child {
                margin-left: 0;
            }
            &--active {
                background-color: var(--white);
                color: var(--dark);
            }
        }
    }
    &__palette {
        display: grid;
        grid-template-columns: repeat(4, 78px);
        position: absolute;
        grid-gap: 24px;
        margin: 0 0 100px;
        bottom: 257px;
        right: 100px;
        &-button {
            position: absolute;
            top: 0;
            right: -105px;
            z-index: 4;
            background: transparent;
            svg {
                width: 78px;
                height: 78px;
            }
        }
        &-item {
            width: 78px;
            height: 78px;
            border-radius: 50%;
            border: 6px solid transparent;
            position: relative;
            svg {
                width: 59px;
                height: 78px;
                position: absolute;
                left: -6px;
                top: -6px;
            }
        }
    }
}

.slide-enter-active,
.slide-leave-active {
    transition: all 0.3s ease;
}

.slide-enter,
.slide-leave-to {
    transform: translateX(-100%);
    opacity: 0;
}

.slide-leave-active {
    position: absolute;
}

.slide-reverse-enter-active,
.slide-reverse-leave-active {
    transition: all 0.3s ease;
}

.slide-reverse-enter,
.slide-reverse-leave-to {
    transform: translateX(100%);
    opacity: 0;
}

.slide-reverse-leave-active {
    position: absolute;
}

.clothes__menu--sub {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
}
</style>
